import React from 'react';
import placeStyles from './Map.module.scss';

class Carousel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isDown: false,
      startX: 0,
      transLeftOffset: null,
      dragSpeed: props.dragSpeed,
      direction: 'right',
      index: this.props.carouselIndex || 0,
    };
    this.cRef = React.createRef();
  }

  componentDidMount() {
    this.cRef.current.addEventListener('touchstart', this.handleMouseDown);
    this.cRef.current.addEventListener('touchend', this.handleMouseUp);
    this.cRef.current.addEventListener('touchcancel', this.handleMouseUp);
    this.cRef.current.addEventListener('touchmove', this.handleMouseMove);
  }

  componentDidUpdate(prevProps) {
    if (this.props.carouselIndex !== prevProps.carouselIndex) {
      this.goTo(this.props.carouselIndex);
    }
  }

  getDirection = (tempTransLeftOffset) => {
    if (this.state.transLeftOffset > tempTransLeftOffset) {
      return 'right';
    } else if (this.state.transLeftOffset < tempTransLeftOffset) {
      return 'left';
    }

    return this.state.direction;
  };

  // mouse Down
  handleMouseDown = (e) => {
    const carousel = this.cRef.current;
    carousel.classList.add('active');

    const _startX = (e.pageX || e?.touches[0]?.pageX || 0) - carousel.offsetLeft;
    const _transLeftOffset = this.giveMeIntValOf(carousel.firstChild.style.transform);
    const direction = this.getDirection(_transLeftOffset);

    this.setState(
      {
        isDown: true,
        startX: _startX,
        transLeftOffset: _transLeftOffset,
        direction: direction,
      },
      () => {
        // handeling reset the transition
        const { startX, transLeftOffset, dragSpeed } = this.state;

        const x = (e.pageX || e?.touches[0]?.pageX || 0) - carousel.offsetLeft;
        const walk = (x - startX) * dragSpeed;

        carousel.firstChild.style.cssText = `
          transform: translateX(${transLeftOffset - walk}px);
          transition: transform 0.0s ease-in-out;
        `;
      },
    );
  };

  goTo = (index) => {
    const carousel = this.cRef.current;
    const { itemWidth, itemSideOffsets, _data } = this.props;
    if (index < 0) {
      index = 0;
    } else if (index >= _data.length) {
      index = _data.length - 1;
    }
    let marginOffset = 15;

    // const { direction } = this.state;
    // let marginOffset = direction == 'right' ? 30 : 0;
    // if(index === 0)
    // {
    //     marginOffset = 30;
    // }
    // if(index === _data.length - 1)
    // {
    //     marginOffset = 0;
    // }

    const x = (index + 1) * (itemWidth + 2 * itemSideOffsets) + marginOffset - carousel.offsetWidth;
    this.setState({ isDown: false, index: index });

    if (index !== this.props.carouselIndex) {
      this.props.setCarouselIndex(index);
    }

    carousel.firstChild.style.cssText = `
          transform: translateX(${-x}px);
          transition: transform 0.5s cubic-bezier(.25,.72,.51,.96);
        `;
  };

  finishTransition = (e) => {
    this.setState({ isDown: false });
    const { direction, index } = this.state;
    if (direction === 'left') {
      return this.goTo(index - 1);
    }
    return this.goTo(index + 1);
  };

  // mouse Leave
  handleMouseLeave = (e) => {
    this.handleSnap();
  };

  // mouse Up
  handleMouseUp = (e) => {
    this.finishTransition(e);
  };

  // mouse Move
  handleMouseMove = (e) => {
    const { isDown, startX, transLeftOffset, dragSpeed } = this.state;
    const carousel = this.cRef.current;

    if (!isDown) return;
    e.preventDefault();

    const x = (e.pageX || e?.touches[0]?.pageX || 0) - carousel.offsetLeft;
    const walk = (x - startX) * dragSpeed;
    const translateX = transLeftOffset + walk;
    carousel.firstChild.style.transform = `translateX(${translateX}px)`;
    const direction = this.getDirection(translateX);
    this.setState({
      direction: direction,
    });
  };

  // handle Snap To Sides
  handleSnap = (type) => {
    // const { isDown, startX, transLeftOffset } = this.state
    const { _data, itemWidth, itemSideOffsets } = this.props;
    const carousel = this.cRef.current;
    // Resetting
    this.setState({ isDown: false });
    carousel.classList.remove('active');

    // handeling Threshold
    // (1) getting transValue
    const tempThresholdOffset = this.giveMeIntValOf(carousel.firstChild.style.transform);
    // (2) items width - 30(first & last item removed margins) - containerWidth(b/c of ending part)
    const end = _data.length * (itemWidth + 2 * itemSideOffsets) - 30 - carousel.offsetWidth;

    // (3) check if we passing from threshold ( handeling Snap To Sides )
    if (tempThresholdOffset < 0 || tempThresholdOffset > -end) {
      this.setState({ isDown: false });
      // default 15 px to be in middle
      carousel.firstChild.style.cssText = `
          transform: translateX(${tempThresholdOffset < 0 ? tempThresholdOffset : 15}px);
          transition: transform 0.5s cubic-bezier(.25,.72,.51,.96);
        `;
    }

    const _transLeftOffset = this.giveMeIntValOf(carousel.firstChild.style.transform);
    const direction = this.getDirection(_transLeftOffset);

    this.setState({
      direction: direction,
    });
  };

  // helper Function
  giveMeIntValOf = (el) => {
    // extracting 20 from translateX(20px) and converting it to integer with parsInt
    return parseInt(el.replace('translateX(', '').replace('px)', ''), 10);
  };

  render() {
    const { _data, itemWidth, itemSideOffsets } = this.props;

    const cWrapperStyle = {
      width: `${_data.length * (itemWidth + 2 * itemSideOffsets)}px`,
    };

    return (
      <div className={placeStyles.carousel} ref={this.cRef} style={{ width: `${itemWidth}px` }}>
        <div
          className={placeStyles.cWrapper + ' cWrapper'}
          style={{
            ...cWrapperStyle,
            transform: 'translateX(15px)', // to be in middle
          }}>
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default Carousel;
