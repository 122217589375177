import { bookActions } from '@/actions';
import PageViewLayout from '@/components/layouts/PageViewLayout/PageViewLayout';
import CardWrapper from '@/components/modules/CardWrapper';
import CheckoutDetails, { CheckoutDetailsProps } from '@/components/modules/checkout/CheckoutDetails/CheckoutDetails';
import QliroCheckout from '@/components/modules/checkout/QliroCheckout/QliroCheckout';
import GoBack from '@/components/modules/pages/bokningar/GoBack';
import { CHECKOUT_PAYMENT_METHOD } from '@/constants/checkout';
import { url } from '@/helpers';
import {
  mapConfirmedBookingInfoGiftcards,
  mapConfirmedBookingInfoServices,
  mapConfirmedBookingToCheckoutDetailsProps,
} from '@/helpers/confirmation';
import { useAppSelector } from '@/hooks';
import useMobileView from '@/hooks/useMobileView';
import useTrackScreenView from '@/hooks/useTrackScreenView';
import { _s } from '@/locale';
import { ConfirmedBooking, InitQliroResponse, initQliroResponseSchema } from '@/types/api/services/booking';
import { bookingSchema } from '@/types/api/services/booking/schema';
import { BookState } from '@/types/state/book';
import { Helmet } from 'react-helmet';
import { useHistory, useLocation } from 'react-router-dom';

const baseTranslationKey = 'pages.booking.checkout.QliroBookingCheckout';

const QliroPrepayCheckout = ({ order, booking }: { order: InitQliroResponse } & { booking: ConfirmedBooking }) => {
  const history = useHistory();
  const { isMobileView } = useMobileView();
  const bookState = useAppSelector((state) => state.book) as BookState;
  const addedGiftcards = bookState.usageReqId?.giftcards;
  const pageUrl = `${url.getBaseUrl()}/booking/checkout/${booking.id}/qliro`;
  const pageTitle = _s(`seo.activateOnlinePaymentQliroTitle`);
  const pageDescription = _s(`seo.activateOnlinePaymentQliroDescription`);

  const paymentTrackingProps = bookActions.getTrackingProps();
  const { ...infoProps } = mapConfirmedBookingToCheckoutDetailsProps({
    booking,
    selectedPaymentMethod: { type: CHECKOUT_PAYMENT_METHOD.QLIRO },
    addedGiftcards,
  });
  const services = mapConfirmedBookingInfoServices(booking);
  const giftcards = mapConfirmedBookingInfoGiftcards(booking, addedGiftcards);

  const checkoutDetailsProps: CheckoutDetailsProps = {
    ...infoProps,
    services,
    giftcards,
  };

  const handleOnBackButtonClick = () => {
    history.replace({
      pathname: `/booking/checkout/${booking.id}`,
    });
  };

  useTrackScreenView({
    name: 'screen_view_checkout_booking_qliro_update_payment_method',
    properties: paymentTrackingProps,
  });

  return (
    <PageViewLayout
      type="subView"
      title={_s(`${baseTranslationKey}.page-title`)}
      back
      onBackButtonClick={handleOnBackButtonClick}
      wrapperClass={isMobileView ? 'bg-brown-50' : 'bg-gradient'}>
      <div className="bg-brown-50 lg:bg-transparent">
        <div className="lg:py-xxl lg:container">
          <div className="gap-xxl flex items-start">
            <div className="gap-lg flex grow-[9999] basis-[600px] flex-col">
              <CardWrapper className="w-full lg:hidden">
                <CheckoutDetails {...checkoutDetailsProps} />
              </CardWrapper>
              <CardWrapper>
                <div className="p-lg">
                  <QliroCheckout snippet={order.snippet} />
                </div>
              </CardWrapper>
            </div>
            <div className="sticky top-20 hidden lg:flex lg:grow-[100] lg:basis-[390px]">
              <CardWrapper className="w-full">
                <CheckoutDetails key={2} {...checkoutDetailsProps} />
              </CardWrapper>
            </div>
          </div>
        </div>
      </div>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <link rel="canonical" href={pageUrl} />

        <meta property="og:url" content={pageUrl} />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        <meta name="robots" content="noindex" />
      </Helmet>
    </PageViewLayout>
  );
};

export default function Wrapper() {
  const location = useLocation();

  const parsedQliroOrder = initQliroResponseSchema.safeParse(location?.state?.qliroOrder);
  const parsedQliroBooking = bookingSchema.safeParse(location?.state?.booking);

  if (!parsedQliroOrder.success || !parsedQliroBooking.success) return <GoBack />;
  return <QliroPrepayCheckout order={parsedQliroOrder.data} booking={parsedQliroBooking.data} />;
}
