import ProgressTracker, { ProgressTrackerProps } from '@/components/elements/ProgressTracker';

type Props = ProgressTrackerProps & {
  title: string;
};

const StepNavBar = ({ steps, currentStep, title, backSteps, backAction }: Props) => (
  <div className="w-fill flex flex-col items-center">
    <p className="border-black-100 w-full border-b-[1px] border-solid pb-4 text-center">{title}</p>
    <div className="-mt-[10px]">
      <ProgressTracker steps={steps} currentStep={currentStep} backSteps={backSteps} backAction={backAction} />
    </div>
  </div>
);

export default StepNavBar;
