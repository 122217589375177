import { FromFooterDisplayOption, ToFooterDisplayOption } from './types';

/**
 * helper function to specify on which breakpoints to show the footer component
 */
export const getFooterDisplayOption = ({ from, to }: { from: FromFooterDisplayOption; to: ToFooterDisplayOption }) => {
  // return tailwind breakpoint classes for footer display
  const fromClass = from === 'initial' ? 'block' : `hidden ${from}:block`;
  const toClass = to === 'all' ? '' : `${to}:block`;
  return `${fromClass} ${toClass}`;
};
