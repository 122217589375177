import Header from '@/components/elements/Header/Header';
import { ListItem, ListItemContent } from '@/components/elements/lists';
import Icon from '@/components/icons/Icon';
import { IconProps } from '@/components/icons/types';
import { CHECKOUT_PAYMENT_METHOD } from '@/constants/checkout';
import { classnames } from '@/helpers';
import { getSelectedPaymentDetails } from '@/helpers/checkout';
import { _s } from '@/locale';
import { SelectedPaymentMethod } from '@/types/checkout';

const baseTranslationKey = 'components.templates.checkout.SelectPaymentOptions';

type SelectPaymentOptionsProps = {
  method: SelectedPaymentMethod & { label?: string };
  disabled?: boolean;
  error?: boolean;
  onClick: () => void;
};

const SelectPaymentOptions = ({ method, disabled, error, onClick }: SelectPaymentOptionsProps) => {
  const { icon, label } = getSelectedPaymentDetails(disabled ? { type: CHECKOUT_PAYMENT_METHOD.NONE } : method);
  const iconProps: IconProps =
    icon === 'wallet' || icon === 'pen'
      ? { variant: icon }
      : {
          noFilter: true,
          style: { width: 48, height: 48 },
          variant: icon,
        };

  return (
    <button
      data-cy="selectPaymentButton"
      onClick={onClick}
      disabled={disabled}
      className={classnames(
        'pb-lg w-full border-none bg-transparent text-left outline-none',
        disabled && 'pointer-events-none opacity-40',
      )}>
      <div className="pl-lg py-md">
        <Header size="md" label={_s('choosePaymentMethod')} />
      </div>
      <ListItem
        {...(error && { underline: true, className: 'border-danger-500' })}
        underline
        leftSlot={<Icon {...iconProps} />}
        rightSlot={<Icon variant="chevron-s-right" />}>
        <ListItemContent title={method.label ?? label} />
      </ListItem>
      {error && <p className="text-danger-500 pl-lg py-xxs text-xxs">{_s(`${baseTranslationKey}.error`)}</p>}
      <div className="py-xxs px-lg space-x-xxs flex">
        <Icon variant="payment-method-credit-card" noFilter />
        <Icon variant="payment-method-visa" noFilter />
        <Icon variant="payment-method-mastercard" noFilter />
        <Icon variant="payment-method-apple-pay" noFilter />
        <Icon variant="payment-method-google-pay" noFilter />
        <Icon variant="payment-method-klarna" noFilter />
        <Icon variant="payment-method-qliro" noFilter />
        <Icon variant="payment-method-gift-card" noFilter />
      </div>
    </button>
  );
};

export default SelectPaymentOptions;
