import { bookActions, userActions } from '@/actions';
import Snackbar from '@/components/elements/notifications/Snackbar/Snackbar';
import { APPOINTMENT_STATUS } from '@/constants/appointmentConstants';
import { getPlaceTimezone, hasNotPasted, promiseWrapper, setBookingStartingPoint, trackMpEvent } from '@/helpers';
import * as appointmentHelper from '@/helpers/appointment';
import { _s } from '@/locale';
import { bookServices } from '@/services/bookServicesTs';
import { ConfirmedBooking, cancelAppointmentResponseSchema } from '@/types/api/services/booking';
import { errorResponseSchema } from '@/types/api/services/schema';
import { User } from '@/types/user';
import * as Sentry from '@sentry/react';
import { Dispatch } from 'react';
import { toast } from 'react-toastify';
import { AppointmentDetailsAction, GroupBookingParticipantInfo } from './AppointmentDetails.types';

function handleActionError(error?: unknown) {
  const errorData = errorResponseSchema.safeParse(error);

  const displayClientError = (errorMessage?: string) => {
    toast(({ closeToast }) => (
      <Snackbar label={errorMessage ?? _s('serverError')} type="danger" onClose={closeToast} />
    ));
  };

  if (!errorData.success) {
    displayClientError();
    Sentry.captureException(error);
    return;
  }

  displayClientError(errorData.data.clientError);
}

export async function handleCancelAppointment(
  appointment: ConfirmedBooking,
  user: User,
  appDispatch: Dispatch<any>,
  dispatch: Dispatch<AppointmentDetailsAction>,
) {
  dispatch({ type: 'SUBMITTING', payload: true });
  const { data, error } = await promiseWrapper(bookServices.cancelAppointment(appointment.id));

  const response = cancelAppointmentResponseSchema.safeParse(data);

  if (error || response.success === false) {
    console.log('ERROR: ', error);
    handleActionError(error);
    dispatch({ type: 'SUBMITTING', payload: false });
    return;
  }

  const { status } = response.data;

  if (status !== APPOINTMENT_STATUS.CANCELED) {
    handleActionError();
    dispatch({ type: 'SUBMITTING', payload: false });
    return;
  }

  trackMpEvent('booking_canceled', {
    screen_name: 'my_bookings_booking_detail',
    number_of_services: appointment.services.length,
    company_id: appointment.place.id,
    booking_id: appointment.id,
  });

  dispatch({ type: 'SET_APPOINTMENT', payload: response.data });

  if (user?.activeBookings) {
    user.activeBookings = user.activeBookings.filter((bookingDate) => bookingDate !== appointment.start);
    appDispatch(userActions.updateUser(user, true));
  }
}

export function handleBookAgain(
  appointment: ConfirmedBooking,
  dispatch: Dispatch<any>,
): {
  bookAgainUrl: string | undefined;
  handleClick: (cancelCurrent?: boolean) => void;
} {
  if (appointment?.place?.about?.active !== true) {
    return {
      bookAgainUrl: undefined,
      handleClick: () => null,
    };
  }

  const processedIds = new Set();
  const services = appointment.services
    .filter((service) => {
      if (!processedIds.has(service.service.id)) {
        processedIds.add(service.service.id);
        return true;
      }
      return false;
    })
    .map((service) => service.service);

  const bookAgainUrl =
    '/boka-tjanst/' +
    (appointment.place.about.slug ? appointment.place.about.slug + '-' : '') +
    appointment.place.id +
    '/' +
    (services[0].about.slug ? services[0].about.slug + '-' : '') +
    services[0].id;

  return {
    bookAgainUrl,
    handleClick: (changeBookingTime = false) => {
      const hasCancelTime = appointment.cancelMargin || 0;
      const timezone = getPlaceTimezone(appointment.place);
      const deadline = appointment.start * 1000 - hasCancelTime * 60 * 1000;
      const canCancel = hasNotPasted(deadline, timezone);
      if (changeBookingTime && !canCancel) {
        handleActionError();
        return;
      }

      sessionStorage.setItem('bookSPurl', `/bokning/${appointment.id}`);
      const employee = appointmentHelper.getEmployee(appointment, true);

      dispatch(bookActions.bookAgain(appointment.place, services, employee));

      if (changeBookingTime) {
        dispatch(bookActions.changeBookingTime(appointment.id));
      } else {
        setBookingStartingPoint('my_bookings_book_again');
      }
    },
  };
}

export function getVisibleGroupBookingParticipantFields(
  participantInfo: GroupBookingParticipantInfo,
  exclude?: string,
) {
  const { givenName, familyName, email, mobile, phone, postalCode, streetAddress } = participantInfo;
  let toReturn = null;
  if (givenName || familyName) {
    toReturn = [givenName, familyName]
      .filter((a) => a)
      .join(' ')
      .trim();
    if (toReturn !== exclude) {
      return toReturn;
    }
  }
  if (email && email !== exclude) {
    return email;
  }
  if (mobile || phone) {
    toReturn = mobile || phone || '';
    if (toReturn !== exclude) {
      return toReturn;
    }
  }

  if (postalCode || streetAddress) {
    return [postalCode, streetAddress]
      .filter((a) => a)
      .join(' ')
      .trim();
  }

  return null;
}
