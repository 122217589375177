export const bookConstants = {
  SELECT_CAPACITY: 'SELECT_CAPACITY',
  ADD_SERVICE: 'ADD_SERVICE',
  APPLY_BUNDLE: 'APPLY_BUNDLE',
  REMOVE_BUNDLE: 'REMOVE_BUNDLE',
  BOOK_AGAIN: 'BOOK_AGAIN',
  CHANGE_BOOKING_TIME: 'CHANGE_BOOKING_TIME',
  FIRST_DAY: 'FIRST_DAY',
  APPEND_SERVICE: 'APPEND_SERVICE',
  POP_SERVICE: 'POP_SERVICE§',
  REMOVE_SERVICE: 'REMOVE_SERVICE',
  ERROR_SERVICE: 'ERROR_SERVICE',
  SERVICE_PROGRAM: 'SERVICE_PROGRAM',
  REMOVE_EMPLOYEE: 'REMOVE_EMPLOYEE',
  PICK_DATE: 'PICK_DATE',
  ADD_PLACE: 'ADD_PLACE',
  REFETCH_PLACE: 'REFETCH_PLACE',
  SAVING: 'SAVING',
  SAVED: 'SAVED',
  FAILURE: 'FAILURE',
  SET_DATE: 'SET_DATE',
  CLEAR_BOOK: 'CLEAR_BOOK',
  PICK_EMPLOYEE: 'PICK_EMPLOYEE',
  KEEP_EMPLOYEE: 'KEEP_EMPLOYEE',
  ADD_EMPLOYEE: 'ADD_EMPLOYEE',
  REMOVE_HOUR: 'REMOVE_HOUR',
  GIFTCARD_FIELD: 'giftCard',
  BOOKING_NOTES_FIELD: 'bookingNotes',
  CAPACITY_FIELD: 'capacity',
  ADD_UUID: 'ADD_UUID',
  SET_GIFTCARD: 'SET_GIFTCARD',
  CLEAR_GIFTCARD_MSG: 'CLEAR_GIFTCARD_MSG',
  ERROR_GIFTCARD: 'ERROR_GIFTCARD',
  VALIDATE_GIFTCARD: 'VALIDATE_GIFTCARD',
  VALIDATE_FIELD: 'VALIDATE_FIELD',
  TRACKING_PROPS: 'TRACKING_PROPS',
  REMOVE_TRACKING_PROPS: 'REMOVE_TRACKING_PROPS',
  ERROR_REQUIRED: 'requiredField',
  ERROR_INVALID_EMAIL: 'invalidEmail',
  ERROR_PROTECTED_EMAIL: 'protectedEmail',
  ERROR_INVALID_PHONE: 'invalidPhone',
  ERROR_INVALID_NATIONAL_ID: 'invalidNationalId',
  ERROR_INVALID_ZIPCODE: 'invalidZipcode',
  SET_CAMPAIGN: 'SET_CAMPAIGN',
  DYNAMIC_PRICE_KEY_DEFAULT: 'none',
  DYNAMIC_PRICE_KEY_WEEKEND: 'weekend',
  DYNAMIC_PRICE_KEY_EVENING: 'evening',
  SET_TIME: 'SET_TIME',
  RESTORE_BOOK_STATE: 'RESTORE_BOOK_STATE',
};
