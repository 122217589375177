import { shareServices } from '../services';

function share(params) {
  return shareServices.share(params).then((response) => {
    return response;
  });
}

export const shareActions = {
  share,
};
