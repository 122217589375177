import { SVGProps } from 'react';
import SolidFillSvg from './SolidFillSvg';

const BeautyLabSmallIcon = (props: SVGProps<SVGSVGElement>) => (
  <SolidFillSvg viewBox="0 0 941.45 304.38" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fill="currentColor"
      d="M19.46,101.62V10.51h31.35c8.96,0,14.62,2.47,18.97,6.37,4.74,4.3,7.64,10.54,7.64,17.31,0,6.24-2.9,12.23-7.24,16.27,8.69,4.3,15.15,13.66,15.15,23.95,0,8.45-3.95,16.14-10.14,21.08-4.74,3.91-10.8,6.12-20.15,6.12H19.46Zm28.98-55.31c7.38,0,10.54-1.43,12.64-3.77,1.97-2.09,3.16-4.82,3.16-7.94s-1.19-5.85-3.16-7.94c-2.11-2.34-5.27-3.77-12.64-3.77h-15.81v23.43h15.81Zm4.21,42.95c8.3,0,12.25-1.82,15.15-4.69,2.63-2.73,4.35-6.51,4.35-10.67s-1.71-7.81-4.35-10.54c-2.9-2.86-6.85-4.69-15.15-4.69h-20.02v30.59h20.02Z"
    />
    <path
      fill="currentColor"
      d="M235.13,46.31v12.36h-30.29v30.59h40.83v12.36h-54V10.51h52.68v12.36h-39.51v23.43h30.29Z"
    />
    <path
      fill="currentColor"
      d="M402.67,78.19h-38.85l-9.62,23.43h-13.7L378.7,10.51h9.22l38.2,91.1h-13.7l-9.75-23.43Zm-33.58-12.36h28.45l-14.23-33.84-14.22,33.84Z"
    />
    <path
      fill="currentColor"
      d="M520.02,90.95c-5.92-6.38-9.61-14.84-9.61-27.08V10.51h13.17v54.01c0,8.59,2.11,13.93,5.8,17.96,4.61,5.07,11.46,8.07,19.23,8.07s14.62-2.99,19.23-8.07c3.69-4.03,5.79-9.37,5.79-17.96V10.51h13.17V63.87c0,12.23-3.69,20.69-9.62,27.08-6.98,7.41-17.25,11.97-28.58,11.97s-21.6-4.56-28.58-11.97Z"
    />
    <path fill="currentColor" d="M685.57,10.51h63.22v12.36h-25.02V101.62h-13.17V22.88h-25.03V10.51Z" />
    <path
      fill="currentColor"
      d="M890.39,59.97v41.65h-13.17V59.97l-31.61-49.46h14.75l23.45,36.57,23.45-36.57h14.75l-31.61,49.46Z"
    />
    <path fill="currentColor" d="M39.54,281.5h40.83v12.36H26.37v-91.1h13.17v78.74Z" />
    <path
      fill="currentColor"
      d="M236.05,270.44h-38.85l-9.62,23.43h-13.7l38.2-91.1h9.22l38.2,91.1h-13.7l-9.75-23.43Zm-33.58-12.36h28.45l-14.23-33.84-14.22,33.84Z"
    />
    <path
      fill="currentColor"
      d="M352.68,293.87v-91.1h31.35c8.96,0,14.62,2.47,18.97,6.37,4.74,4.3,7.64,10.54,7.64,17.31,0,6.24-2.9,12.23-7.24,16.27,8.69,4.3,15.15,13.66,15.15,23.95,0,8.45-3.95,16.14-10.14,21.08-4.74,3.91-10.8,6.12-20.15,6.12h-35.56Zm28.98-55.31c7.38,0,10.54-1.43,12.64-3.77,1.97-2.09,3.16-4.82,3.16-7.94s-1.19-5.85-3.16-7.94c-2.11-2.34-5.27-3.77-12.64-3.77h-15.81v23.43h15.81Zm4.21,42.95c8.3,0,12.25-1.82,15.15-4.69,2.63-2.73,4.35-6.51,4.35-10.67s-1.71-7.81-4.35-10.54c-2.9-2.86-6.85-4.69-15.15-4.69h-20.02v30.59h20.02Z"
    />
    <path
      fill="currentColor"
      d="M519.29,293.87v-91.1h31.35c8.96,0,14.62,2.47,18.97,6.37,4.74,4.3,7.64,10.54,7.64,17.31,0,6.24-2.9,12.23-7.24,16.27,8.69,4.3,15.15,13.66,15.15,23.95,0,8.45-3.95,16.14-10.14,21.08-4.74,3.91-10.8,6.12-20.15,6.12h-35.56Zm28.98-55.31c7.38,0,10.54-1.43,12.64-3.77,1.97-2.09,3.16-4.82,3.16-7.94s-1.19-5.85-3.16-7.94c-2.11-2.34-5.27-3.77-12.64-3.77h-15.81v23.43h15.81Zm4.21,42.95c8.3,0,12.25-1.82,15.15-4.69,2.63-2.73,4.35-6.51,4.35-10.67s-1.71-7.81-4.35-10.54c-2.9-2.86-6.85-4.69-15.15-4.69h-20.02v30.59h20.02Z"
    />
    <path
      fill="currentColor"
      d="M734.97,238.55v12.36h-30.29v30.59h40.83v12.36h-54v-91.1h52.68v12.36h-39.51v23.43h30.29Z"
    />
    <path fill="currentColor" d="M852.19,202.76h63.22v12.36h-25.02v78.74h-13.17v-78.74h-25.03v-12.36Z" />
  </SolidFillSvg>
);

export default BeautyLabSmallIcon;
