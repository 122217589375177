import { isServer } from '../helpers';

export { addStylesheet, saveBodyScroll, restoreBodyScroll };

function addStylesheet(url) {
  if (isServer) {
    return;
  }
  const styleSheets = document.styleSheets;
  let alreadyAdded = false;
  if (styleSheets) {
    for (var key in styleSheets) {
      if (styleSheets[key].href === url) {
        alreadyAdded = true;
      }
    }
  }
  if (!alreadyAdded) {
    const link = document.createElement('link');
    link.href = url;
    link.rel = 'stylesheet';
    document.head.appendChild(link);
  }
}

function restoreBodyScroll() {
  const scrollY = document.body.style.top;
  document.body.style.top = '';
  window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
}

function saveBodyScroll() {
  document.body.style.top = `-${window.scrollY}px`;
}
