import useMobileView from '@/hooks/useMobileView';

function withMobileView<P>(WrappedComponent: React.ComponentType<P & ReturnType<typeof useMobileView>>) {
  const WithMobileView = (props: P) => {
    const { isMobileView, windowSize } = useMobileView();
    return <WrappedComponent {...props} isMobileView={isMobileView} windowSize={windowSize} />;
  };
  return WithMobileView;
}

export default withMobileView;
