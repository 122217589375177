import { searchActions } from '@/actions';
import Label from '@/components/elements/Label/Label';
import { BeautyCategory } from '@/constants/beautyCategories';
import {
  getCookie,
  getGeoLocation,
  isServer,
  isSistaminuten,
  setCookie,
  setLocationInCookies,
  trackMpEvent,
  url as urlHelper,
} from '@/helpers';
import { themed } from '@/helpers/theme';
import { __ } from '@/locale';
import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Link, useHistory, withRouter } from 'react-router-dom';
import { useGetAmplitudeExperimentVariant } from '@/hooks/useAmplitudeExperiment';

const getImageName = (imageName: string) =>
  imageName ? (isSistaminuten() ? `${imageName}-sistaminutentiderse` : imageName) : null;
const shouldRequestLocationOnCategoryClick = () => !isServer && (window as any).shouldRequestLocationOnCategoryClick;

type Props = { dataset: number; category: BeautyCategory; source: string; search?: any };

const goToListWithLocationData = (e: MouseEvent, to, dispatch, link, history) => {
  e.preventDefault();

  const setLatAndLong = (lat, lon) => {
    const params = {
      location: __('currentLocation'),
      locationId: undefined,
      position: lat && lon ? `${lat}-${lon}` : '',
    };

    setLocationInCookies(params);
    dispatch(searchActions.setParameter(params));
  };

  const toList = () => (link && !isServer ? history.push(link) : null);

  if (!navigator.geolocation) {
    return toList();
  }

  getGeoLocation(
    setLatAndLong,
    () => {}, // track
    () => {},
    () => {
      setCookie('SkipLocationData', true, { path: '/', expires: new Date(Date.now() + 24 * 60 * 60 * 1000) });
    },
  );

  toList();
};

const goToList = (askForLocation, source, category, dispatch, history) => (e, to) => {
  if (askForLocation && !getCookie('SkipLocationData')) goToListWithLocationData(e, to, dispatch, to, history);
  const screenName = source ? source : 'home';
  trackMpEvent('service_category_clicked', {
    screen_name: screenName,
    selected_category: __(category.name).toLowerCase(),
  });
};

const DCCategory = (props: Props) => {
  const { category, source, search } = props;
  const { img, name, q, description, cta, onClick, url, overlay, hideName = false } = category;
  const { location: urlLocation } = urlHelper.getUrlParameters(props);
  const to = url ? url : `/${encodeURI(`${q.toLowerCase()}/var`)}`;
  const showHoverState = description && cta && !category.overlay;

  const imageName = getImageName(img);

  const dispatch = useDispatch();
  const history = useHistory();
  const [askForLocation, setAskForLocation] = useState<boolean>(false);
  const [location, setLocation] = useState(urlLocation);

  const getVariant = useGetAmplitudeExperimentVariant();
  const startingScoreVersion = getVariant('search-results-start-score').value === 'new_start_score' ? 2 : 1;

  const toList = (e, to) => {
    // @ts-ignore
    dispatch(searchActions.setParameter({ shouldUpdate: true, startingScoreVersion: startingScoreVersion }));
    goToList(askForLocation, source, category, dispatch, history)(e, to);
  };

  useEffect(() => {
    if (shouldRequestLocationOnCategoryClick()) {
      return setAskForLocation(true);
    }
  }, []);

  useEffect(() => {
    if (!search || search?.location === location) return;
    setLocation(search.location);
  }, [search, location]);
  const flexStyles = 'basis-full sm:basis-1/2 md:basis-1/3 ';

  return (
    <div className={`text-black-900 p-1 sm:p-2 lg:p-4 ${source !== 'home' ? '' : flexStyles}`}>
      <div className="relative">
        <Link to={to} onClick={onClick ? onClick : (e) => toList(e, to)} className="text-black-900">
          {imageName && (
            <div className="group relative">
              <picture>
                <source srcSet={`/images/${imageName}.webp`} type="image/webp" />
                <source srcSet={`/images/${imageName}.jpg`} type="image/jpeg" />
                <img
                  src={`/images/${imageName}.jpg`}
                  alt=""
                  className={`${themed(
                    'md:h-30 h-52 lg:h-48',
                    'h-52 sm:h-36 md:h-32 lg:h-44 xl:h-48',
                  )}  w-full rounded-md object-cover`}
                />
              </picture>
              {showHoverState && (
                <div className="bg-black-900 absolute left-0 top-0 z-10 hidden h-full w-full flex-col justify-start gap-2 rounded-md bg-opacity-90 p-4 text-white group-hover:flex">
                  <div className="flex-shrink overflow-hidden overflow-ellipsis text-sm text-white">{description}</div>
                  <div
                    className={`font-semibold ${themed(
                      'text-[#51A492]',
                      'text-sm_primary',
                    )} flex-shrink-0 flex-grow-0`}>
                    {cta}
                  </div>
                </div>
              )}
            </div>
          )}
          {!hideName && (
            <h3
              className={`text-base font-semibold ${
                source !== 'home' ? 'border-black-100 -mt-2 border-b pb-4' : 'mt-2'
              }`}>
              {name}
            </h3>
          )}
        </Link>
        {overlay && (
          <Link
            to={overlay.cta.to}
            className="bg-scrim-60 space-x-xs absolute left-0 top-0 flex w-full flex-row items-start rounded-tl-md rounded-tr-md p-3 text-white">
            <span className="tracking-content text-m flex-1">
              {overlay.text}
              {overlay.cta && (
                <>
                  &nbsp;
                  <span className="text-information-500 whitespace-nowrap underline">{overlay.cta.label}</span>
                </>
              )}
            </span>
            <Label label={overlay.label.label} className={overlay.label.className} />
          </Link>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({ search: state.search });
const Category = connect(mapStateToProps)(DCCategory);
export default withRouter(Category);
