import { server } from '@/helpers';

const submitSupportForm = (data) =>
  server.request.post(`/support-form`, data).then(server.handleSuccess).catch(server.handleError);

const uploadSupportFile = (data) =>
  server.request
    .post(`/support-form/upload-file`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(server.handleSuccess)
    .catch(server.handleError);

const deleteSupportFile = (data) =>
  server.request.post(`/support-form/delete-file`, data).then(server.handleSuccess).catch(server.handleError);

const getReviews = (query) =>
  server.request
    .get(`/reviews/support` + query)
    .then(server.handleSuccess)
    .catch(server.handleError);

const deleteReview = (id) =>
  server.request
    .get(`/reviews/` + id + `/delete`)
    .then(server.handleSuccess)
    .catch(server.handleError);

const deleteReviewText = (id) =>
  server.request
    .get(`/reviews/support/remove-text/${id}`)
    .then(server.handleSuccess)
    .catch(server.handleError);

const getSupportUsers = (email) =>
  server.request
    .get(`/reviews/support/users?email=${email}`)
    .then(server.handleSuccess)
    .catch(server.handleError);

const setSupportUserRole = (data) =>
  server.request
    .post(`/reviews/support/set-user-role`, data)
    .then(server.handleSuccess)
    .catch(server.handleError);

const getRemindersLog = (query) =>
    server.request
      .get(`/reviews/support/reminders-log` + query)
      .then(server.handleSuccess)
      .catch(server.handleError);

export const supportServices = {
  submitSupportForm,
  uploadSupportFile,
  deleteSupportFile,
  getReviews,
  deleteReview,
  deleteReviewText,
  getSupportUsers,
  setSupportUserRole,
  getRemindersLog,
};
