import { ContentBlock, ContentObjectBlock, isHeadingBlock, parseBlock } from '@/helpers/content';
import { _a } from '@/locale';

const translationKey = 'injectionBanner';

const bottomMargin = (block: ContentBlock): string =>
  isHeadingBlock(block) && (block as ContentObjectBlock).type !== 'h1' ? 'mb-1' : 'mb-8';

const InjectionNoticeContent = () => {
  return (
    <>
      {(_a(`${translationKey}.modal.blocks`) as ContentBlock[]).map((block, i) => (
        <div key={i} className={bottomMargin(block)}>
          {parseBlock(block, i)}
        </div>
      ))}
    </>
  );
};

export default InjectionNoticeContent;
