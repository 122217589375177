import PageViewLayout from '@/components/layouts/PageViewLayout/PageViewLayout';
import useBundleConfirmation from './BundleConfirmation.hooks';

import { bookActions } from '@/actions';
import { LoginSuccess } from '@/components/animations';
import Header from '@/components/elements/Header/Header';
import LoadingPlaceHolder from '@/components/elements/LoadingPlaceholder';
import { LinkButton } from '@/components/elements/forms/buttons';
import Icon from '@/components/icons/Icon';
import CardWrapper from '@/components/modules/CardWrapper';
import CheckoutDetails from '@/components/modules/checkout/CheckoutDetails/CheckoutDetails';
import EmptyState from '@/components/templates/EmptyState';
import { SCREEN_NAME } from '@/constants/analytics';
import { SMART_LINKS } from '@/constants/smartLinkConstants';
import { trackMpEvent } from '@/helpers';
import { mapConfirmedBundleToCheckoutDetailsProps } from '@/helpers/confirmation';
import { useAppSelector } from '@/hooks';
import { _s } from '@/locale';
import { BundleActionIdentifier } from '@/types/bundle';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';

const baseTranslationKey = 'pages.bundles.confirmation.BundleConfirmation';

const BundleConfirmation = () => {
  const dispatch = useDispatch();
  const { bundle, error, loading, selectedPaymentMethod } = useBundleConfirmation();
  const user = useAppSelector((state) => state.users)?.user;
  const checkoutStartingPoint = sessionStorage.getItem('paymentSPurl') ?? '/';

  if (error) {
    return <Redirect to="/klippkort/giltig" />;
  }

  if (loading) return <LoadingPlaceHolder text={_s(`${baseTranslationKey}.confirmation-loader`)} />;

  const clientEmail = user?.contact?.email || '';

  const checkoutDetailsProps = mapConfirmedBundleToCheckoutDetailsProps({ bundle, selectedPaymentMethod });
  const bookWithBundlePathname = `/boka-tjanst/${bundle.place.about.slug}-${bundle.place.id}/${bundle.service.slug}-${bundle.service.serviceId}`;

  const handleUseBookingBundle = () => {
    trackMpEvent(`${BundleActionIdentifier.BOOK_TIME}_click`, {
      trigger_source: SCREEN_NAME.CHECKOUT_BUNDLE_CONFIRMATION,
    });
    dispatch(bookActions.applyBundle(bundle));
  };

  return (
    <PageViewLayout
      type="subView"
      wrapperClass="lg:bg-gradient"
      rightSlot={
        <Link to={checkoutStartingPoint}>
          <Icon variant="close-alt" alt={_s('close')} size="sm" />
        </Link>
      }>
      <div className="bg-brown-50 lg:bg-transparent">
        <div className="lg:py-xxl lg:container">
          <div className="gap-xxl flex items-start">
            <div className="gap-lg flex grow-[9999] basis-[600px] flex-col">
              <CardWrapper className="bg-white">
                <EmptyState
                  icon={<LoginSuccess style={{ width: 196, height: 164 }} />}
                  title={_s(`${baseTranslationKey}.header.title`)}
                  body={
                    clientEmail
                      ? _s(`${baseTranslationKey}.header.body`, { email: clientEmail })
                      : _s(`${baseTranslationKey}.header.body.error`)
                  }
                />
              </CardWrapper>
              {checkoutDetailsProps && (
                <div className="lg:hidden">
                  {
                    <CardWrapper className="bg-white">
                      <CheckoutDetails {...checkoutDetailsProps} />
                    </CardWrapper>
                  }
                </div>
              )}
              <CardWrapper className="bg-information-50">
                <div className="p-lg">
                  <div className="py-sm">
                    <Header label={_s(`${baseTranslationKey}.appDownload.title`)} size="md" />
                  </div>
                  <p className="text-black-600 text-md">{_s(`${baseTranslationKey}.appDownload.body`)}</p>
                  <div className="pt-lg flex items-center justify-center">
                    <a className="align-center justify-left flex flex-wrap" href={SMART_LINKS.CONFIRMED}>
                      <img
                        src="/images/download-app-store.svg"
                        className="mr-3 h-[40px]"
                        alt={_s('availableAppStore')}
                      />
                      <img src="/images/download-google-play.svg" className="h-[40px]" alt={_s('availablePlayStore')} />
                    </a>
                  </div>
                </div>
              </CardWrapper>
              <CardWrapper className="bg-white">
                <div className="p-lg gap-lg flex flex-col">
                  <LinkButton
                    block
                    variant="primary"
                    leftIcon={<Icon variant="calendar-plus" />}
                    label={_s(`${baseTranslationKey}.footer.cta.bookTime`)}
                    onClick={handleUseBookingBundle}
                    to={bookWithBundlePathname}
                    size="md"
                  />
                  <LinkButton
                    block
                    variant="secondary"
                    leftIcon={<Icon variant="bundle" />}
                    label={_s(`${baseTranslationKey}.footer.cta.showBundle`)}
                    to={`/klippkortdetaljer/${bundle.id}`}
                    size="md"
                  />
                  <LinkButton
                    block
                    variant="link"
                    label={_s(`${baseTranslationKey}.footer.cta.close`)}
                    to={checkoutStartingPoint}
                    size="md"
                  />
                </div>
              </CardWrapper>
            </div>
            <div className="hidden lg:flex lg:grow-[100] lg:basis-[390px]">
              <CardWrapper className="w-full bg-white">
                {checkoutDetailsProps && <CheckoutDetails {...checkoutDetailsProps} />}
              </CardWrapper>
            </div>
          </div>
        </div>
      </div>

      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
    </PageViewLayout>
  );
};

export default BundleConfirmation;
