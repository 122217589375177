import useBlogPageContent from '@/hooks/blog/useBlogPage';
import useScrollToTop from '@/hooks/useScrollToTop';
import { useEffect } from 'react';

import PageViewLayout from '@/components/layouts/PageViewLayout/PageViewLayout';
import useMobileView from '@/hooks/useMobileView';
import css from './BlogPage.module.scss';
import BlogPageHeading from './BlogPageHeading';

const BlogPage = ({ pageId, pageTitle, trackPage, pageClassName, seo }) => {
  const content = useBlogPageContent(pageId);
  const { isMobileView } = useMobileView();
  useScrollToTop();
  useEffect(trackPage, []);

  return (
    <PageViewLayout
      type={isMobileView ? 'subView' : 'mainView'}
      {...(isMobileView && { back: true })}
      title={pageTitle}
      footerDisplayOption={{ from: 'sm', to: 'all' }}>
      <BlogPageHeading title={pageTitle} />
      <div
        className={`${css.blogPageContent} ${css[pageClassName]} pb-safe container`}
        dangerouslySetInnerHTML={{ __html: content }}></div>
      {seo}
    </PageViewLayout>
  );
};

export default BlogPage;
