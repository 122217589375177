import { ReactNode, useEffect, useRef, useState } from 'react';
import css from './HeightExpandableArea.module.scss';

type Props = {
  expanded: boolean;
  className?: string;
  children: ReactNode;
};

const HeightExpandableArea = ({ expanded, className = '', children }: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const [maxHeight, setMaxHeight] = useState(0);

  useEffect(() => {
    if (!ref.current) return;

    setTimeout(() => setMaxHeight(ref.current?.scrollHeight), 1);
  });
  return (
    <div
      ref={ref}
      className={`${css.container} ${expanded ? css.expanded : ''} ${className}`}
      style={{ maxHeight: expanded ? maxHeight : 0 }}>
      {children}
    </div>
  );
};

export default HeightExpandableArea;
