import { ColorNames } from '@/colors';
import { ButtonVariant } from '@/components/elements/forms/buttons/types';
import { IconVariant } from '@/components/icons/types';

export enum BundleActionIdentifier {
  BOOK_TIME = 'book_time',
  BUY_AGAIN = 'buy_again',
}

export type BundleActionItem = {
  variant: ButtonVariant;
  icon: IconVariant;
  iconColor?: ColorNames;
  to?: string;
  label: string;
  identifier?: BundleActionIdentifier;
};
