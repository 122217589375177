export type IpCoordinates = {
  lat: number;
  lon: number;
};

export function getIpLatLon(): Promise<IpCoordinates> {
  return fetch('https://pro.ip-api.com/json?key=Mx8lRNPZnMdoiji&fields=lat,lon').then((response) => response.json());
}

export function getIpData() {
  fetch('https://pro.ip-api.com/json?key=Mx8lRNPZnMdoiji').then((response) => response.json());
}
