import { themed } from '@/helpers/theme';

interface BreadcrumbsProps {
  className?: string;
  items: { title: string; url: string }[];
}

const Breadcrumbs = ({ items, className = '' }: BreadcrumbsProps) => {
  return (
    <div className={className}>
      <ol className="list-none">
        {items.map((item, index) => {
          const isCurrentLink = items.length === index + 1;
          return (
            <li
              key={index + 1}
              className="before:text-black-600 inline-block before:px-2 before:content-['/'] first:before:content-none">
              <a
                className={`cursor-pointer ${themed(
                  isCurrentLink ? 'text-black-900' : 'text-information-700 underline',
                  isCurrentLink ? 'text-black-800' : 'text-information-700 underline hover:underline',
                )} `}
                href={item.url}>
                <span>{item.title}</span>
              </a>
            </li>
          );
        })}
      </ol>
    </div>
  );
};

export default Breadcrumbs;
