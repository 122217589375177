import { PaymentCardBrand } from '@/types/paymentcards';

export const ONLINE_PAYMENT_STATUS = {
  AUTHORISATION: 'AUTHORISATION',
  CAPTURE: 'CAPTURE',
  REFUND: 'REFUND',
  CANCELLATION: 'CANCELLATION',
  CANCEL_OR_REFUND: 'CANCEL_OR_REFUND',
  REFUND_FAILED: 'REFUND_FAILED',
  CAPTURE_FAILED: 'CAPTURE_FAILED',
} as const;

export const PAYMENT_CARD_BRAND: { [brand in PaymentCardBrand]: { title: string; abbreviation: string } } = {
  visa: {
    title: 'Visa',
    abbreviation: 'VISA',
  },
  mc: {
    title: 'Master-Card',
    abbreviation: 'MC',
  },
  amex: {
    title: 'American Express',
    abbreviation: 'AMEX',
  },
  diners: {
    title: 'Diners Club',
    abbreviation: 'DNRS',
  },
  discover: {
    title: 'Discover',
    abbreviation: 'DCVR',
  },
  maestro: {
    title: 'Maestro',
    abbreviation: 'MAESTRO',
  },
  mc_googlepay: {
    title: 'Google Pay - MC',
    abbreviation: 'Google Pay - MC',
  },
  amex_googlepay: {
    title: 'Google Pay - AMEX',
    abbreviation: 'Google Pay - AMEX',
  },
  discover_googlepay: {
    title: 'Google Pay - DCVR',
    abbreviation: 'Google Pay - DCVR',
  },
  visa_googlepay: {
    title: 'Google Pay - VISA',
    abbreviation: 'Google Pay - VISA',
  },
  maestro_googlepay: {
    title: 'Google Pay - Maestro',
    abbreviation: 'Google Pay - MAESTRO',
  },
  mc_applepay: {
    title: 'Apple Pay - MC',
    abbreviation: 'Apple Pay - MC',
  },
  amex_applepay: {
    title: 'Apple Pay - AMEX',
    abbreviation: 'Apple Pay - AMEX',
  },
  discover_applepay: {
    title: 'Apple Pay - DCVR',
    abbreviation: 'Apple Pay - DCVR',
  },
  visa_applepay: {
    title: 'Apple Pay - VISA',
    abbreviation: 'Apple Pay - VISA',
  },
  maestro_applepay: {
    title: 'Apple Pay - Maestro',
    abbreviation: 'Apple Pay - MAESTRO',
  },
  unknown: {
    title: 'Unknown',
    abbreviation: '?',
  },
} as const;

export const CARD_BRAND_MAP: { [key in string]: PaymentCardBrand } = {
  visa: 'visa',
  mc: 'mc',
  amex: 'amex',
  diners: 'diners',
  discover: 'discover',
  maestro: 'maestro',
  visa_googlepay: 'visa_googlepay',
  mc_googlepay: 'mc_googlepay',
  amex_googlepay: 'amex_googlepay',
  discover_googlepay: 'discover_googlepay',
  maestro_googlepay: 'maestro_googlepay',
  visa_applepay: 'visa_applepay',
  amex_applepay: 'amex_applepay',
  discover_applepay: 'discover_applepay',
  maestro_applepay: 'maestro_applepay',
  mc_applepay: 'mc_applepay',
} as const;

export const MINIMUM_SWISH_AMOUNT_THRESHOLD = 3000;
