import ArrowLongIcon from '@/components/icons/ArrowLongIcon';
import { BlogLayout } from '@/components/layouts/blog';
import { BackButton, PostContent } from '@/components/modules/pages/blog';
import useBlogPost from '@/hooks/blog/useBlogPost';
import useScrollToTop from '@/hooks/useScrollToTop';
import { _s } from '@/locale';
import { Link, useParams } from 'react-router-dom';

const SinglePost = () => {
  const { slugId } = useParams();
  const post = useBlogPost(slugId);
  useScrollToTop();

  return (
    <BlogLayout>
      <div className="container my-10">
        <BackButton />
        <PostContent post={post} />
        <Link
          to="/blogg"
          className="text-secondary-500 hover:text-secondary-500 mb-8 mt-10 inline-flex items-center justify-start py-2">
          <ArrowLongIcon className="mr-2 w-8 rotate-180" /> {_s('blogPage.backToStart')}
        </Link>
      </div>
    </BlogLayout>
  );
};

export default SinglePost;
