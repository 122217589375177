import { Button, LinkButton } from '@/components/elements/forms/buttons';
import { CloseIcon } from '@/components/icons';
import { _s } from '@/locale';
import { useState } from 'react';
import { ShadowModal } from '../../modals';
import SearchNoticeContent from '../articles/SearchNoticeContent';
const translationKey = 'searchInfoBanner';

const SearchInfoWithPopup = () => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);

  return (
    <>
      <div className="z-100 flex items-end justify-between">
        <div className="relative flex items-center justify-start text-sm text-gray-900">
          <LinkButton onClick={openModal} label={_s(`${translationKey}.link`)} className="!px-0" />
        </div>
      </div>
      <ShadowModal
        isOpen={showModal}
        onRequestClose={closeModal}
        contentClass="!rounded-none p-10 relative w-full h-full md:!pt-16 overflow-scroll no-scrollbar"
        className="no-scrollbar !m-0 h-full w-full !max-w-full self-start overflow-scroll md:!m-4 md:!h-[inherit] md:!max-w-xl">
        <div className="pb-[112px] text-left md:pb-0">
          <button className="absolute right-8 top-8 hidden md:block" onClick={closeModal}>
            <CloseIcon className="h-5 w-5" />
          </button>
          <SearchNoticeContent />
          <div className="fixed bottom-0 left-0 w-full bg-white px-6 pb-12 pt-4 shadow-sm md:hidden">
            <Button variant="primary" size="lg" block onClick={closeModal}>
              {_s('close')}
            </Button>
          </div>
        </div>
      </ShadowModal>
    </>
  );
};

export default SearchInfoWithPopup;
