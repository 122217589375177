import { Card } from '@/components/elements/cards';
import HeightExpandableArea from '@/components/elements/forms/HeightExpandableArea/HeightExpandableArea';
import { LinkButton } from '@/components/elements/forms/buttons';
import { ChevronIcon, SearchIcon } from '@/components/icons';
import { Question, highlightedFaq } from '@/constants/faq';
import { isMobile } from '@/helpers';
import { _s } from '@/locale';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

export const SupportFaqItem = ({
  question,
  active,
  className,
  btnClassName,
  qClassName,
  aClassName,
  setActive,
  closeInactive,
  section,
}: {
  question: Question;
  active: boolean;
  className?: string;
  qClassName?: string;
  btnClassName?: string;
  aClassName?: string;
  setActive: any;
  closeInactive?: boolean;
  section?: string;
}) => {
  const [expanded, setExpanded] = useState<boolean>(active);

  useEffect(() => {
    if (!active && closeInactive) {
      setExpanded(false);
    }
  }, [active]);

  const toggleExpanded = () => {
    setExpanded(!expanded);
    if (!expanded) {
      setActive(question.id);
    } else {
      setActive(0);
    }
  };

  return (
    <div className={className}>
      <button
        className={`${
          btnClassName ? btnClassName : ''
        } flex w-full items-baseline justify-between gap-4 focus:outline-none focus-visible:outline-1`}
        onClick={toggleExpanded}>
        <span className={`${qClassName ? qClassName : ''} text-left`}>{question.question}</span>
        <ChevronIcon
          className={`text-primary inline-flex h-3 w-3 shrink-0 items-center justify-center transition-transform ${
            expanded ? 'rotate-180' : ''
          }`}
        />
      </button>
      <HeightExpandableArea className={aClassName ? aClassName : ''} {...{ expanded }}>
        <div className="h-4"></div>
        <div dangerouslySetInnerHTML={{ __html: question.answer }}></div>
        {(question.supportFormLinks || []).map((link, i) => {
          return (
            <LinkButton
              key={i}
              to={{ pathname: '/support/form', state: { selectedSection: section, active: link.questionId } }}
              className="mt-2 !px-0 font-bold underline">
              {_s(link.text)}
            </LinkButton>
          );
        })}
        <div className="h-4"></div>
      </HeightExpandableArea>
    </div>
  );
};

export const RandomSupportFaq = () => {
  const [active, setActive] = useState<number>(0);

  return (
    <Card className="mx-auto mb-0 bg-white">
      <h2 className="mb-2 flex items-center justify-start gap-4 text-lg font-bold lg:text-2xl">
        <SearchIcon className="h-7 w-7" />
        {_s('supportPage.faq')}
      </h2>
      {highlightedFaq.questions.map((question, i) => {
        const isActive = question.id === active;
        return (
          <SupportFaqItem
            btnClassName="mt-6"
            qClassName={`${isActive ? 'font-bold underline' : ''} text-base lg:text-lg`}
            section={question.section}
            question={question}
            active={isActive}
            setActive={setActive}
            closeInactive={isMobile()}
            key={i}
          />
        );
      })}
      <div className="h-8 sm:h-12"></div>
      <div className="text-sm sm:text-base">
        <h4 className="sm:text-h-m text-base font-bold">{_s('supportPage.beautyFaq')}</h4>
        <span className="tracking-content my-1 block">{_s('supportPage.beautyFaq2')}</span>
        <Link className="tracking-content text-link font-bold" to="/faq-vanliga-fragor">
          {_s('readMore')}
        </Link>
      </div>
    </Card>
  );
};
