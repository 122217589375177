import { Card, CardImageHeader, CardShadow } from '@/components/elements/cards';
import HeightExpandableArea from '@/components/elements/forms/HeightExpandableArea/HeightExpandableArea';
import { LinkButton } from '@/components/elements/forms/buttons';
import { ChevronIcon } from '@/components/icons';
import { getWellnessCardExpiryDateFromToday, indexedArrayFromLength } from '@/helpers';
import { __ } from '@/locale';
import { useState } from 'react';
import WellnessFaq from '../WellnessFaq';

const numChecks = 3;
const numHowTo = 3;

const replaceExpire = (str) => str.replace('EXPIRE', getWellnessCardExpiryDateFromToday());

const InfoColumnWellnessCard = ({ showPaymentScreen }) => {
  const [faqExpanded, setFaqExpanded] = useState(false);
  return (
    <CardShadow>
      <CardImageHeader src={'/images/product-wellnesscard.png'} />
      <Card noShadow attachedTop>
        {!showPaymentScreen && (
          <div className="mb-3 text-center text-sm">
            {__('buyGiftcard.use.friskvårdskort')}{' '}
            <LinkButton to="/giftcards" label={__('buyGiftcard.useLink')} className="!p-0" />
          </div>
        )}
        {indexedArrayFromLength(numChecks).map((i) => (
          <div className="mb-2 flex items-center justify-start text-sm" key={i}>
            <img src={'/images/checked.svg'} width={15} height={15} alt={''} className="mr-2 " />{' '}
            {__(`buyGiftcard.wellness.infoColumn.check${i + 1}`)}
          </div>
        ))}
        <h3 className="text-h3 mb-4 mt-6 font-semibold">{__('buyGiftcard.wellness.infoColumn.howToTitle')}</h3>
        <ol className="pd-0 mg-0" style={{ listStyleType: 'none' }}>
          {indexedArrayFromLength(numHowTo).map((i) => (
            <li className="mb-3 flex items-start justify-start" key={i}>
              <span className="text-md mr-2 w-3 font-semibold">{i + 1}.</span>
              <span className="text-md">
                {i === 1 && (
                  <>
                    <LinkButton to={'/vad/var?wellness=true'} size="md" className="!p-0">
                      {__('buyGiftcard.wellness.infoColumn.link')}
                    </LinkButton>{' '}
                  </>
                )}
                {replaceExpire(__(`buyGiftcard.wellness.infoColumn.howTo${i + 1}`))}
              </span>
            </li>
          ))}
        </ol>
        <button
          className="unstyled-button mt-6 flex w-full items-center justify-between outline-none"
          onClick={() => setFaqExpanded(!faqExpanded)}>
          <h3 className="color-primary text-h3 font-semibold">
            {__(
              faqExpanded
                ? 'buyGiftcard.wellness.infoColumn.faqTitleHide'
                : 'buyGiftcard.wellness.infoColumn.faqTitleShow',
            )}
          </h3>
          <ChevronIcon className={`h-3 w-3 ${faqExpanded ? 'rot-z-180' : ''}`} />
        </button>
        <HeightExpandableArea expanded={faqExpanded}>
          <WellnessFaq />
        </HeightExpandableArea>
      </Card>
    </CardShadow>
  );
};

export default InfoColumnWellnessCard;
