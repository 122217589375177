import Header from '@/components/elements/Header/Header';
import Input from '@/components/elements/forms/Input/Input';
import { Button } from '@/components/elements/forms/buttons';
import DataGridCell from '@/components/elements/lists/DataGrid/DataGridCell/DataGridCell';
import DataGridRow from '@/components/elements/lists/DataGrid/DataGridRow/DataGridRow';
import Icon from '@/components/icons/Icon';
import CardWrapper from '@/components/modules/CardWrapper';
import { promiseWrapper } from '@/helpers';
import { useGiftcardCheckoutFormData } from '@/hooks/useGiftcardCheckoutFormData';
import { _s } from '@/locale';
import { PHYSICAL_FEE } from '@/pages/presentkort/GiftcardCheckout.hooks';
import { giftCardServices } from '@/services';
import { checkDiscountCodeResponseSchema } from '@/types/api/services/giftcard';
import React from 'react';

const baseTranslationKey = 'components.modules.checkout.giftcards.DiscountCode';

const DiscountCode = () => {
  const { register, setValue, formdata } = useGiftcardCheckoutFormData();
  const [error, setError] = React.useState<string | undefined>(undefined);
  const [discountAmount, setDiscountAmount] = React.useState<number | undefined>();
  const [discountCode, setDiscountCode] = React.useState<string>('');

  const handleChangeCode = async (discountCode: string) => {
    setDiscountCode(discountCode);
  };

  const handleAddCode = async () => {
    const orderTotal =
      (isNaN(formdata.customAmount) ? formdata.amount : formdata.customAmount) * formdata.quantity +
      (formdata.type === 'physical' ? PHYSICAL_FEE : 0);

    const { data, error } = await promiseWrapper(giftCardServices.checkDiscountCode(discountCode, orderTotal));

    const validatedResponse = checkDiscountCodeResponseSchema.safeParse(data);

    if (!validatedResponse.success || error) {
      setError(_s('pages.giftcard.checkout.invalid_discount'));
      return;
    }

    const { discount, code } = validatedResponse.data;

    setDiscountAmount(discount / 100);
    setValue('discountCode', code);
    setError(undefined);
    setDiscountCode('');
  };

  const handleRemoveCode = () => {
    setValue('discountCode', '');
    setDiscountAmount(0);
  };

  return (
    <CardWrapper>
      <div className="p-lg gap-sm flex flex-col">
        <Header size="md" label={_s(`${baseTranslationKey}.title`)} />
        <div className="gap-md flex">
          <div className="flex-1">
            <Input
              value={discountCode}
              leftSlot={<Icon variant="deal" />}
              onChange={(e) => handleChangeCode(e.currentTarget.value)}
              placeholder={_s(`${baseTranslationKey}.input.placeholder`)}
              error={error}
            />
          </div>
          <div>
            <Button
              size="md"
              disabled={Boolean(formdata.discountCode)}
              label={_s(`${baseTranslationKey}.button.label`)}
              onClick={handleAddCode}
              type="button"
            />
          </div>
        </div>
        <div>
          {formdata.discountCode && (
            <DataGridRow hasPaddingTop key={formdata.discountCode}>
              <React.Fragment key=".0">
                <DataGridCell title={formdata.discountCode} type="muted" />
                <DataGridCell
                  align="right"
                  title={`-${discountAmount} kr`}
                  type="discount"
                  rightIcon={
                    <button onClick={handleRemoveCode} className="flex">
                      <Icon variant="trash" />
                    </button>
                  }
                />
              </React.Fragment>
            </DataGridRow>
          )}
        </div>
      </div>
      <input type="hidden" {...register('discountCode')} />
    </CardWrapper>
  );
};

export default DiscountCode;
