import { Button } from '@/components/elements/forms/buttons';
import { CloseIcon, CloseIconThin, InformationAltIcon } from '@/components/icons';
import { ShadowModal } from '@/components/modules/modals';
import { getCookie, isMobile, setCookie } from '@/helpers';
import { twTextPrimary } from '@/helpers/theme';
import useLocalStorage from '@/hooks/useLocalStorage';
import { _s } from '@/locale';
import * as moment from 'moment';
import { useEffect, useState } from 'react';
import InjectionNoticeContent from '../articles/InjectionNoticeContent';
import SearchNoticeContent from '../articles/SearchNoticeContent';

type TopBannerVariant = 'searchInfo' | 'injectionInfo';

const bannerTopMargin = () => (isMobile() ? '' : 'mt-[46px]');

const translationKey = (variant: TopBannerVariant) => {
  switch (variant) {
    case 'searchInfo':
      return 'searchInfoBanner';
    case 'injectionInfo':
      return 'injectionBanner';
  }
};

const bannerBaseClass = (variant: TopBannerVariant) => {
  switch (variant) {
    case 'searchInfo':
      return '';
    case 'injectionInfo':
      return 'bg-information-50';
  }
};

const canCloseBanners: { [K in TopBannerVariant]: boolean } = {
  searchInfo: false,
  injectionInfo: true,
};

const SERPTopInfoBanner = ({ variant, important = false }: { variant: TopBannerVariant; important?: boolean }) => {
  const localStorage = useLocalStorage(`closed-SERPTopBanner-${variant}`, 0);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showBanner, setShowBanner] = useState(false);
  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);

  useEffect(() => {
    const { storedValue: closedBannerAmount } = localStorage;
    if (closedBannerAmount < 3) return;
    if (canCloseBanners[variant]) {
      setCookie(`show-SERPTopBanner-${variant}`, 'false', { path: '/', expires: moment().add(30, 'days').toDate() });
      localStorage.removeValue(`closed-SERPTopBanner-${variant}`);
      setShowBanner(false);
    }
  }, [variant, localStorage]);

  useEffect(() => {
    getCookie(`show-SERPTopBanner-${variant}`) !== 'false' ? setShowBanner(true) : setShowBanner(false);
  }, [variant]);

  const handleOnCloseBanner = () => {
    localStorage.setValue(localStorage.storedValue + 1);
    setShowBanner(false);
  };

  return showBanner ? (
    <>
      <div
        className={`serp-banner z-100 flex items-center justify-between ${bannerBaseClass(
          variant,
        )} ${bannerTopMargin()}`}>
        <div className="text-black-900 text-m relative flex items-center justify-start px-4 py-4 md:px-12 md:text-sm">
          {important && (
            <InformationAltIcon className="text-black-900 mr-4 h-5 w-5 flex-shrink-0 flex-grow-0 md:mr-6" />
          )}
          <div>
            {_s(`${translationKey(variant)}.text`)}{' '}
            <button className={`text-link ${twTextPrimary()}`} onClick={openModal}>
              {_s(`${translationKey(variant)}.link`)}
            </button>
          </div>
        </div>
        {canCloseBanners[variant] && (
          <button className="mr-4 p-2" aria-label={`${_s('close')} banner`} onClick={handleOnCloseBanner}>
            <CloseIconThin className="text-black-600 h-3 w-3" />
          </button>
        )}
      </div>
      <ShadowModal
        isOpen={showModal}
        onRequestClose={closeModal}
        contentClass="!rounded-none p-10 relative w-full h-full md:!pt-16 overflow-scroll no-scrollbar"
        className="no-scrollbar !m-0 h-full w-full !max-w-full self-start overflow-scroll md:!m-4 md:!h-[inherit] md:!max-w-xl">
        <div className="pb-[112px] text-left md:pb-0">
          <>
            <button className="absolute right-8 top-8 hidden md:block" onClick={closeModal}>
              <CloseIcon className="h-5 w-5" />
            </button>
            {variant === 'searchInfo' && <SearchNoticeContent />}
            {variant === 'injectionInfo' && <InjectionNoticeContent />}
            <div className="fixed bottom-0 left-0 w-full bg-white px-6 pb-12 pt-4 shadow-sm md:hidden">
              <Button variant="primary" size="lg" block onClick={closeModal}>
                {_s('close')}
              </Button>
            </div>
          </>
        </div>
      </ShadowModal>
    </>
  ) : null;
};

export default SERPTopInfoBanner;
