import { z } from 'zod';

export const checkDiscountCodeResponseSchema = z.object({
  code: z.string(),
  discount: z.number(),
});

export type CheckDiscountCodeResponse = z.infer<typeof checkDiscountCodeResponseSchema>;

export const klarnaResponseSchema = z.object({
  sessionID: z.string(),
  clientToken: z.string().optional(),
  paymentMethodCategories: z
    .array(
      z.object({
        identifier: z.string(),
        name: z.string(),
        assetURLs: z.object({
          descriptive: z.string(),
          standard: z.string(),
        }),
      }),
    )
    .optional(),
  orderLines: z.any(),
  success: z.boolean().optional(),
});

export type KlarnaResponse = z.infer<typeof klarnaResponseSchema>;
