import { TERMS_NAVIGATION_LINKS, TermLinkIdentifier } from '@/constants/terms';
import { _s } from '@/locale';

const GiftCardsTerms = () => {
  const { label, to } = TERMS_NAVIGATION_LINKS.find((link) => link.identifier === TermLinkIdentifier.COUPON);
  return (
    <div className="text-xxs text-center">
      <p className="text-inherit">
        {`${_s('checkoutTerms.description.bundle.payment', { buttonLabel: _s('buy') })}`}{' '}
        <a href={to} target="_blank" rel="noreferrer">
          {label}
        </a>
      </p>
    </div>
  );
};

export default GiftCardsTerms;
