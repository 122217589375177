import { BundleListContentProps } from '@/components/elements/lists/BundleCardListItem/BundleCardListItem';
import { _s } from '@/locale';
import { ClientBundle } from '@/types/api/services/users/schema';
import * as moment from 'moment';

const formatDate = (validity: string): string => {
  return moment(validity).tz('Europe/Stockholm').format('D MMM, YYYY');
};

export const getBundleUsagesLeftString = (bundle: ClientBundle): string => {
  return _s('bundleUsagesLeft', {
    qunatityMinusUsages: bundle.service.quantity - bundle.service.usages,
    quantity: bundle.service.quantity,
  });
};

export const getBundleListContentProps = (bundle: ClientBundle): BundleListContentProps => {
  const profileImage = bundle?.place?.about?.profileImage;
  const isDiscounted = Boolean(bundle.finalPrice);
  return {
    expiry: _s('validUntil', { dateString: formatDate(bundle.validity) }),
    placeName: bundle.place?.about?.name,
    price: isDiscounted ? (
      <>
        {bundle.finalPriceLabel + ' '}
        <span className="line-through">({bundle.priceLabel})</span>
      </>
    ) : (
      bundle.priceLabel
    ),
    service: bundle.name,
    usages: getBundleUsagesLeftString(bundle),
    image: Boolean(profileImage) ? { src: profileImage, alt: bundle.place?.about?.name ?? '' } : null,
  };
};

export const isBundleExpired = (bundle: ClientBundle): boolean => {
  return moment().isAfter(moment(bundle.validity));
};

export const isBundleConsumed = (bundle: ClientBundle): boolean => {
  return bundle.service.usages >= bundle.service.quantity;
};

export const isBundleValid = (bundle: ClientBundle): boolean => {
  return !isBundleConsumed(bundle) && !isBundleExpired(bundle) && bundle.place?.about?.active;
};

/**
 * @returns {boolean} true if place still sells bundle, false if place has removed it
 */
export const isBundleActive = (bundle: ClientBundle): boolean => {
  return !bundle.isArchived && bundle.place?.about?.active;
};
