import { deployConstants } from '../constants';

export function deploy(state = null, action) {
  switch (action.type) {
    case deployConstants.lastDeployTimestamp:
      return action.data;
    case deployConstants.clearDeployTimestamp:
      return null;
    default:
      return state;
  }
}
