import { isSistaminuten } from '@/helpers';
import SolidFillSvg from './SolidFillSvg';

const CheckIcon = (props) => (
  <SolidFillSvg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx="12" cy="12" r="12" fill={isSistaminuten() ? '#ffcb2e' : 'currentColor'} />
    <path
      d="M6.96572 12.8355C6.85997 12.7298 6.80056 12.5863 6.80056 12.4368C6.80056 12.2872 6.85997 12.1438 6.96572 12.0381C7.07147 11.9323 7.21489 11.8729 7.36444 11.8729C7.51399 11.8729 7.65742 11.9323 7.76317 12.0381L10.5004 14.7753L16.3934 8.88227C16.4457 8.82991 16.5079 8.78837 16.5763 8.76004C16.6447 8.7317 16.7181 8.71711 16.7921 8.71711C16.8662 8.71711 16.9395 8.7317 17.0079 8.76004C17.0763 8.78838 17.1385 8.82991 17.1908 8.88227C17.2432 8.93463 17.2847 8.99679 17.3131 9.06521C17.3414 9.13362 17.356 9.20695 17.356 9.281C17.356 9.35505 17.3414 9.42837 17.3131 9.49678C17.2847 9.5652 17.2432 9.62736 17.1908 9.67972L11.0021 15.8685C10.9588 15.9433 10.8989 16.0072 10.827 16.0552C10.7551 16.1033 10.6731 16.1341 10.5873 16.1455C10.5016 16.1568 10.4144 16.1483 10.3325 16.1205C10.2506 16.0928 10.1761 16.0467 10.1149 15.9856L6.96572 12.8355Z"
      fill={isSistaminuten() ? '#2a2e43' : '#ffffff'}
    />
  </SolidFillSvg>
);

export default CheckIcon;
