import { Card, CardHeader } from '@/components/elements/cards';
import { Button } from '@/components/elements/forms/buttons';
import KlarnaPaymentMethodCategories from '@/components/modules/klarna/KlarnaPaymentMethodCategories';
import { ShippingMethod } from '@/constants/giftcardConstants';
import { __ } from '@/locale';
import GiftCardsTerms from './GiftCardsTerms';
import { GiftCardDiscount, SelectShippingMethodCard, SelectValueCard, ShippingInfoCard } from './cards';

/**
 * @typedef {object} GiftCardsBuyStandardProps
 * @prop {ShippingMethod} shippingMethod
 * @prop {Function} setShippingMethod
 * @prop {object} css
 * @prop {Function} valueUpdate
 * @prop {number} value
 * @prop {number} minValue
 * @prop {number} quantity
 * @prop {Function} setQuantity
 * @prop {import('@/services/giftCardServices').Shipping} shipping
 * @prop {Function} setShipping
 * @prop {boolean} shouldValidate
 * @prop {Function} setShouldValidate
 * @prop {Function} validationCompleted
 * @prop {import('@/services/giftCardServices').Greeting} greeting
 * @prop {Function} setGreeting
 * @prop {number[]} pricingValues
 *
 * @param {GiftCardsBuyStandardProps} param0
 * @returns
 */
const GiftCardsBuyStandard = ({
  shippingMethod,
  setShippingMethod,
  css,
  setDiscount,
  setDiscountCode,
  valueUpdate,
  value,
  minValue,
  quantity,
  setQuantity,
  shipping,
  setShipping,
  shouldValidate,
  setShouldValidate,
  validationCompleted,
  greeting,
  discount,
  code,
  setGreeting,
  sessionID,
  authorize,
  paymentMethodCategories,
  pricingValues,
}) => {
  return (
    <>
      <SelectShippingMethodCard {...{ shippingMethod, setShippingMethod, css }} />{' '}
      <SelectValueCard {...{ valueUpdate, value, minValue, quantity, setQuantity, pricingValues }} />
      <GiftCardDiscount {...{ setDiscount, setDiscountCode, value, discount, code, isDiscount: true, quantity }} />
      {shippingMethod === ShippingMethod.SHIPPING && (
        <>
          <ShippingInfoCard
            {...{
              shipping,
              setShipping,
              shouldValidate,
              validationCompleted,
              greeting,
              setGreeting,
              css,
            }}
          />
          <div className="mb-5 text-center">
            <Button size="lg" onClick={() => setShouldValidate(true)}>
              {__('buyGiftcard.goToPay')}
            </Button>
          </div>
        </>
      )}
      {shippingMethod === ShippingMethod.DIGITAL && (
        <Card>
          <CardHeader>{__('buyGiftcard.payment')}</CardHeader>
          <KlarnaPaymentMethodCategories {...{ paymentMethodCategories }} />
          <div id="klarna-payment"></div>
          {sessionID && (
            <div className="gap-lg pt-md flex flex-col">
              <Button size="lg" block onClick={authorize}>
                {__('buy')}
              </Button>
              <GiftCardsTerms />
            </div>
          )}
        </Card>
      )}
    </>
  );
};

export default GiftCardsBuyStandard;
