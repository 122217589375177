import { ReactNode } from 'react';

type IconRowProps = {
  icon: ReactNode;
  children: ReactNode;
  className?: string;
  iconPosition: 'center' | 'top' | 'bottom';
  tag: keyof JSX.IntrinsicElements;
};

const IconRow = ({ tag, icon, iconPosition, children, className = '' }: IconRowProps) => {
  const CustomTag = tag;
  return (
    <CustomTag className={`flex ${className}`}>
      <div
        className={`flex
          ${iconPosition === 'top' && ' items-top'}
          ${iconPosition === 'bottom' && 'items-end'}
          ${iconPosition === 'center' && 'items-center'}
        `}>
        <div className="flex h-[24px] items-center">
          <div className="mr-4 h-4 w-4">{icon}</div>
        </div>
      </div>
      {children}
    </CustomTag>
  );
};

export default IconRow;
