import LoadingPlaceHolder from '@/components/elements/LoadingPlaceholder';
import { useAppSelector } from '@/hooks';
import { BookState } from '@/types/state/book';
import { createContext, ReactNode, useContext, useEffect, useState } from 'react';

type View = 'pickEmployee' | 'summary' | 'pickServices' | 'pickService';

export type ModalView = {
  current: View;
  previous?: View;
};

export function getScreenView(bookState: BookState, previousView?: View): () => ModalView {
  return () => {
    const hideEmployee = bookState.place?.about?.settings?.hideEmployees ?? false;
    const hasPickedEmployee = bookState.employee ?? false;
    const hasPickedService = (bookState.services ?? []).length > 0;

    const current = (() => {
      if (!hasPickedService && !hasPickedEmployee) {
        return undefined;
      }

      if (!hasPickedService) {
        return 'pickServices';
      }

      if (!hideEmployee && !hasPickedEmployee) {
        return 'pickEmployee';
      }

      return 'summary';
    })();

    return {
      current,
      previous: previousView,
    };
  };
}

const useBookAppointmentManager = (): {
  view: ModalView;
  isOpen: boolean;
  open: () => void;
  close: () => void;
  updateView: (view: View) => void;
} => {
  /**
   * Initial screen depends on if the user has picked a service or an employee
   * - if the user picks a service, and there is more than one employee && the place does not have hideEmployee setting, show the pickEmployee screen
   * - if the user picks a employee, show the pickServices screen
   */
  const bookstate = useAppSelector((state) => state.book) as BookState;

  const [view, setView] = useState<ModalView>(getScreenView(bookstate));
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const updateView = (view: View) => setView((prev) => ({ current: view, previous: prev.current }));

  const open = () => {
    if (view.current === undefined) {
      setView(getScreenView(bookstate, view.current));
    }
    setIsOpen(true);
  };
  const close = () => setIsOpen(false);

  useEffect(() => {
    if (view.current === undefined) {
      setView(getScreenView(bookstate, view.current));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookstate.services, bookstate.employee]);

  return {
    view,
    isOpen,
    open,
    close,
    updateView,
  };
};

export type BookAppointmentContextType = ReturnType<typeof useBookAppointmentManager>;

export const BookAppointmentContext = createContext<BookAppointmentContextType>({
  view: { current: 'summary' },
  isOpen: false,
  open: () => null,
  close: () => null,
  updateView: () => null,
});

export const useBookAppointmentContext = () => {
  const context = useContext(BookAppointmentContext);

  if (!context) {
    throw new Error('useCheckout must be used within a CheckoutProvider');
  }

  return context;
};

export const BookAppointmentProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { show, loadSource } = useAppSelector((state) => state.loading);
  return (
    <BookAppointmentContext.Provider value={useBookAppointmentManager()}>
      {show && loadSource === 'fetchPlaceDetails' && (
        <div className="fixed inset-0 z-10">
          <LoadingPlaceHolder className="flex items-center justify-center" />
        </div>
      )}
      {children}
    </BookAppointmentContext.Provider>
  );
};
