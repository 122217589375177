import { blogCategories } from '@/constants';
import { _s } from '@/locale';
import { Link, useParams } from 'react-router-dom';

const ArchiveCategoryNav = () => {
  const { slug = 'all' } = useParams();

  return (
    <nav className="-mx-4 my-8 flex flex-wrap justify-center md:-mx-8">
      {blogCategories.map((category, i) => (
        <Link
          to={category.to}
          className={`hover:text-black-900 text-base font-semibold md:text-2xl md:font-normal ${
            slug === category.slug ? 'text-secondary-200 hover:text-secondary-500 !font-semibold' : 'text-black-900'
          } mx-4 md:mx-8`}>
          {_s(`blogPage.categoryNav.${category.title}`)}
        </Link>
      ))}
    </nav>
  );
};

export default ArchiveCategoryNav;
