/* eslint-disable */
import PageViewLayout from '@/components/layouts/PageViewLayout/PageViewLayout';
import { NewsletterBanner } from '@/components/modules/pages/blog';
import { isSistaminuten, trackPage, url } from '@/helpers';
import useMobileView from '@/hooks/useMobileView';
import useScrollToTop from '@/hooks/useScrollToTop';
import { __ } from '@/locale';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';

const getSEO = () => {
  const title = __('seo.newsletterTitle');
  const description = __('seo.newsletterDescription');
  const pageUrl = `${url.getBaseUrl()}newsletter`;
  const imageUrl = url.getBaseImageUrl();
  const isNoIndex = isSistaminuten();

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <link rel="canonical" href={pageUrl} />

      <meta property="og:url" content={pageUrl} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={imageUrl} />
      {isNoIndex && <meta name="robots" content="noindex" />}
    </Helmet>
  );
};

const trackPageVisit = () => {
  trackPage();
  // trackMpEvent('screen_shown', { screen_name: 'newsletter' });
};

const Newsletter = () => {
  const { isMobileView } = useMobileView();
  useScrollToTop();
  useEffect(trackPageVisit, []);

  return (
    <PageViewLayout
      type={isMobileView ? 'subView' : 'mainView'}
      {...(isMobileView && { back: true })}
      title={__('newsletter')}
      footerDisplayOption={{ from: 'sm', to: 'all' }}>
      <NewsletterBanner noLogo />
      {getSEO()}
    </PageViewLayout>
  );
};

export default Newsletter;
