/* eslint-disable */
import { Button } from '@/components/elements/forms/buttons';
import { LoadingIcon, LogoIcon, SistaMinutenLogoIcon } from '@/components/icons';
import PageViewLayout from '@/components/layouts/PageViewLayout/PageViewLayout';

import { AndroidTopBanner, IPhoneTopBanner } from '@/components/modules/mobile';
import {
  getHomeDescription,
  isSistaminuten,
  showAndroidTopBanner,
  showIphoneTopBanner,
  trackMpEvent,
  trackPage,
  url,
  server,
} from '@/helpers';
import useScrollToTop from '@/hooks/useScrollToTop';
import { _s } from '@/locale';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

const useUnsubscribeForm = (hash, setUnsubscribe) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [message, setMessage] = useState(null);

  const submit = async (unsubscribe) => {
    setLoading(true);
    const sBase = !!unsubscribe ? 'unsubscribe.unsubscribe' : 'unsubscribe.subscribe';
    server.request
      .get('/reviews/unsubscribe/' + hash + '?subscribe=' + (unsubscribe ? 0 : 1))
      .then(({ data }) => {
        const success = data.status === 'success';
        if (success) {
          setUnsubscribe(!unsubscribe);
        }
        setError(!success);
        setLoading(false);
        return setMessage(_s(`${sBase}${success ? 'Success' : 'Error'}`));
      })
      .catch((error) => {
        setError(true);
        setMessage(_s(`${sBase}Error`));
        setLoading(false);
      });
  };

  return [loading, message, error, submit];
};

function UnsubscribeFromReviews(props) {
  useScrollToTop();
  useEffect(() => {
    trackPageVisit;
  }, []);

  const seo = () => {
    const title = _s('seo.unsubscribeTitle');
    const description = getHomeDescription();
    const pageUrl = `${url.getBaseUrl()}/reviews/unsubscribe`;
    const imageUrl = url.getBaseImageUrl();

    return (
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <link rel="canonical" href={pageUrl} />

        <meta property="og:url" content={pageUrl} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={imageUrl} />
        <meta name="robots" content="noindex" />
      </Helmet>
    );
  };

  const trackPageVisit = () => {
    trackPage();
    // trackMpEvent('screen_shown', { screen_name: 'unsubscribeReviews' });
  };

  const [unsubscribe, setUnsubscribe] = useState(true);
  const { emailHash } = props.match.params;

  const onSubmit = (e) => {
    e.preventDefault();
    submitForm(unsubscribe);
  };

  const [loading, subscribeMessage, subscribeError, submitForm] = useUnsubscribeForm(emailHash, setUnsubscribe);

  const domain = isSistaminuten() ? 'sistaminutentider.se' : 'bokadirekt.se';
  const description = !!unsubscribe
    ? _s('unsubscribe.confirmUnsubscribe', { domain })
    : _s('unsubscribe.unsubscribedSuccessReviews', { domain });
  const subdescription = !!unsubscribe ? null : <p className="mb-4">{_s(`unsubscribe.unsubscribeByMistake`)}</p>;
  const button = !!unsubscribe ? _s('unsubscribe.cancelSubscription') : _s('unsubscribe.subscribe');

  const prepend = !isSistaminuten() ? (
    showIphoneTopBanner() ? (
      <IPhoneTopBanner source="company_details" />
    ) : showAndroidTopBanner() ? (
      <AndroidTopBanner source="company_details" />
    ) : null
  ) : null;

  return (
    <PageViewLayout
      type="mainView"
      prepend={prepend}
      footerDisplayOption={{ from: 'md', to: 'all' }}
      source="unsubscribe">
      <div className={`pb-safe container relative`}>
        <h1 className={`block py-6 text-2xl font-semibold`}>{_s('unsubscribe.reviews')}</h1>
        <p className="mb-4">{_s(`${description}`)}</p>
        <form className={`${loading ? 'opacity-0' : ''} transition-opacity`} onSubmit={onSubmit}>
          {subdescription}
          <Button className="mb-6" disabled={loading}>
            {button}
          </Button>
        </form>
        {loading && <LoadingIcon className="absolute left-1/2 top-1/2 h-10 w-10 -translate-x-1/2 -translate-y-1/2" />}
        {subscribeError && <div className="text-danger">{subscribeMessage}</div>}
      </div>
      {seo()}
    </PageViewLayout>
  );
}

export default UnsubscribeFromReviews;
