import { Button } from '@/components/elements/forms/buttons';
import { trackMpEvent } from '@/helpers';
import { _s } from '@/locale';
import { authorizeKlarnaPurchase, getInitializeKlarnaSDKOnce, loadKlarnaWidget } from '@/services/klarnaServices';
import { KPAuthorizationResponse } from '@/types/klarnaPayments';
import { useEffect } from 'react';

const baseTranslationKey = 'components.modules.checkout.KlarnaCheckout';

const KlarnaCheckout = ({
  clientToken,
  orderLines,
  submitLabel,
  handleCreateOrder,
}: {
  clientToken: string;
  orderLines: any[];
  submitLabel?: string;
  handleCreateOrder: (res: KPAuthorizationResponse) => void;
}) => {
  useEffect(() => {
    const initializeKlarnaSDK = getInitializeKlarnaSDKOnce();
    initializeKlarnaSDK();
    loadKlarnaWidget('#klarna-payment', clientToken);
  }, []);

  const payWithKlarna = () => {
    authorizeKlarnaPurchase(orderLines, 'pay_later', (res) => {
      if (res.authorization_token) {
        handleCreateOrder(res);
      }

      if (!res.show_form && !res.approved) {
        // Disable Klarna as a payment option
        trackMpEvent('screen_shown', { screen_name: 'booking_step_klarna_payment_widget_error' });
      } else if (res.show_form && !res.approved) {
        /**
         * It's possible that the response is approved: false and show_form: true,
         * but the callback doesn't include error. This means the customer has terminated
         * a required interaction in the widget, such as authentication or sign-up flows.
         * In this case, you should keep Klarna visible so that the customer can make another purchase attempt.
         */
        if (!res.error) {
          trackMpEvent('screen_shown', { screen_name: 'booking_step_klarna_payment_widget_close' });
        } else {
          trackMpEvent('screen_shown', { screen_name: 'booking_step_klarna_payment_widget_error_fixable' });
        }
      }
    });
  };

  return (
    <>
      <div id="klarna-payment"></div>
      <Button variant="primary" block size="md" onClick={payWithKlarna}>
        {submitLabel ?? _s(`${baseTranslationKey}.payWithKlarna`)}
      </Button>
    </>
  );
};

export default KlarnaCheckout;
