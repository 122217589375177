import Header from '@/components/elements/Header/Header';
import Input from '@/components/elements/forms/Input/Input';
import { Switch } from '@/components/elements/forms/Switch';
import { ListItem, ListItemContent } from '@/components/elements/lists';
import Icon from '@/components/icons/Icon';
import CardWrapper from '@/components/modules/CardWrapper';
import { isNationalId } from '@/helpers/utilsTS';
import { useGiftcardCheckoutFormData } from '@/hooks/useGiftcardCheckoutFormData';
import { __, _s } from '@/locale';
import { useState } from 'react';

const baseTranslation = 'components.modules.giftCards';

export const SERVICE_TYPES = {
  massage: { label: 'Massage', tax: 2500 },
  pt: { label: 'PT', tax: 600 },
  yoga: { label: 'Yoga', tax: 600 },
} as const;

const SSNFormCard = () => {
  const { register, formdata, setValue, errors } = useGiftcardCheckoutFormData();
  const [isConnectedToCompanies, setIsConnectedToCompanies] = useState(false);
  const title = __('buyGiftcard.wellness.ssn.title');
  const description = __('buyGiftcard.wellness.ssn.description');
  const serviceDescription = __('buyGiftCardWellness.servicePicker.serviceDescription');
  const companyDescription = __('buyGiftCardWellness.servicePicker.companyDescription');
  const companyDescriptionAfterCompanies = __('buyGiftCardWellness.servicePicker.companyDescriptionAfterCompanies');
  const companies = Array.isArray(__('buyGiftCardWellness.servicePicker.companies'))
    ? (__('buyGiftCardWellness.servicePicker.companies') as string[])
    : [];

  const handleOnChangeServiceType = (serviceType: 'massage' | 'pt' | 'yoga') => {
    setValue('serviceType', serviceType);
  };

  const handleIsConnectedToCompaniesToggle = () => {
    if (isConnectedToCompanies) {
      setValue('serviceType', undefined);
    } else {
      setValue('serviceType', 'massage');
    }
    setIsConnectedToCompanies((prevState) => !prevState);
  };

  return (
    <CardWrapper>
      <div className="px-lg">
        <div className="py-sm">
          <Header label={title} size="md" />
        </div>
        <p className="pb-2">{description}</p>
        <div className="pt-md">
          <Input
            {...register('ssn', {
              required: _s(`${baseTranslation}.ssnInput.error.required`),
              validate: (value) => isNationalId(value) || _s(`${baseTranslation}.ssnInput.error.invalid`),
            })}
            placeholder={__(`${baseTranslation}.ssnInput.placeHolder`)}
            title={__(`${baseTranslation}.ssnInput.title`)}
            error={errors.ssn ? errors.ssn.message : ''}
          />
        </div>
        <ul className="pt-md">
          <ListItem
            leftPadding={false}
            rightSlot={<Switch switched={isConnectedToCompanies} onChange={handleIsConnectedToCompaniesToggle} />}
            underline={isConnectedToCompanies}>
            {companyDescription}{' '}
            {companies.map((company, index) => {
              return (
                <span key={company} className="font-bold">
                  {company}
                  {__('buyGiftCardWellness.servicePicker.companies').length - 1 !== index ? ', ' : ''}
                </span>
              );
            })}{' '}
            {companyDescriptionAfterCompanies}
          </ListItem>
          {isConnectedToCompanies && (
            <>
              <ListItem leftPadding={false}>
                <ListItemContent subTitle={serviceDescription} />
              </ListItem>
              {(Object.keys(SERVICE_TYPES) as Array<keyof typeof SERVICE_TYPES>).map((service) => {
                return (
                  <ListItem
                    leftPadding={false}
                    key={service}
                    onClick={() => handleOnChangeServiceType(service)}
                    leftSlot={
                      formdata.serviceType === service ? (
                        <Icon variant="radio-checked" />
                      ) : (
                        <Icon variant="radio-unchecked" />
                      )
                    }>
                    <ListItemContent content={SERVICE_TYPES[service].label} />
                  </ListItem>
                );
              })}
              <input type="hidden" {...register('serviceType')} value={formdata.serviceType} />
            </>
          )}
        </ul>
      </div>
    </CardWrapper>
  );
};

export default SSNFormCard;
