import { userAboutEditableFields, userAboutSchema, userContactEditableFields, userContactSchema } from '@/types/user';
import { z } from 'zod';

export const updateProfileRequestSchema = z.object({
  about: userAboutEditableFields.optional(),
  contact: userContactEditableFields.optional(),
});

export type UpdateProfileRequest = z.infer<typeof updateProfileRequestSchema>;

export const updateProfileResponseSchema = z.object({
  about: userAboutSchema,
  contact: userContactSchema,
});

export type UpdateProfileResponse = z.infer<typeof updateProfileResponseSchema>;
