import { ChevronIcon } from '@/components/icons';
import { isMobile, scrollAccordionContentInView, trackMpEvent } from '@/helpers';
import { themed } from '@/helpers/theme';
import { __ } from '@/locale';
import React from 'react';
import Service from './Service';

export default class ServicesCategory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: props.isOpen ?? false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isOpen !== this.props.isOpen) {
      this.setState({ expanded: this.props.isOpen });
    }
  }

  handleCollapse = (name) => (e) => {
    if (!this.state.expanded) {
      this.setState({ expanded: !this.state.expanded }, () => scrollAccordionContentInView(e.target));
    } else {
      this.setState({ expanded: !this.state.expanded });
    }
  };

  render() {
    const { servicesCategory, place, className, employee, append, selectedServices, source, filterQuery } = this.props;

    const { expanded } = this.state;
    if (servicesCategory && servicesCategory.services) {
      const servicesIds =
        selectedServices && selectedServices.length ? selectedServices.map((service) => service.id) : [];

      let availableServices = servicesCategory.services.filter((service) => {
        const employeeCheck = employee
          ? employee.services
            ? employee.services.indexOf(service.id) !== -1
            : false
          : true;
        const skip = servicesIds && servicesIds.indexOf(service.id) !== -1;

        let filterByName = true;
        if (filterQuery) {
          const words = filterQuery.split(' ');
          filterByName = words.every((word) => service.name.toLowerCase().indexOf(word) !== -1);
        }

        return employeeCheck && !skip && filterByName;
      });

      if (availableServices.length === 0) {
        return null;
      }

      const servicesHtml = availableServices.map((service) => {
        return (
          <Service
            place={place}
            service={service}
            key={service.id}
            append={append}
            fromEmployee={employee}
            source={source}
            section="all_services"
            hidePopupCallback={this.props.hidePopupCallback}
            filterQuery={filterQuery}
          />
        );
      });

      const collapseClass = expanded ? 'block' : 'hidden';

      const categoryName = servicesCategory
        ? servicesCategory.own === true
          ? servicesCategory.name
          : __(servicesCategory.name)
        : '';

      const html = servicesCategory ? (
        <div className={`item ${expanded && !isMobile() ? 'mb-4' : ''}`}>
          {servicesCategory.name && (
            <div
              onClick={this.handleCollapse(categoryName)}
              className={`mb-[2px] flex cursor-pointer items-center justify-between p-3 ${themed('bg-black-50', '')}`}>
              <h3 className="pointer-events-none font-semibold">{categoryName}</h3>
              <span
                className={`pointer-events-none flex items-center justify-center rounded-full p-2 ${
                  expanded ? 'bg-[#ADB4C4]' : themed('bg-primary', 'bg-sm_primary')
                }`}>
                <ChevronIcon className={`h-[8px] w-[8px] text-white ${expanded ? 'rotate-180' : ''}`} />
              </span>
            </div>
          )}
          <div
            id={className ? className + servicesCategory.id : 'services' + servicesCategory.id}
            className={'accordion-content ' + collapseClass}
            role="tabpanel">
            <ul style={className ? {} : {}}>{servicesHtml}</ul>
          </div>
        </div>
      ) : (
        ''
      );
      return html;
    }

    return null;
  }
}
