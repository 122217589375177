import { useEffect, useRef } from 'react';

const importQliroSDK = (toImport: HTMLScriptElement, parentNode: Node) => {
  const importScript = document.createElement('script');
  importScript.src = toImport.src;
  parentNode.removeChild(toImport);
  parentNode.appendChild(importScript);
};

const createAndAppendScriptTag = (parentNode: Node, scriptText: string) => {
  const newScriptTag = document.createElement('script');
  newScriptTag.type = 'text/javascript';
  newScriptTag.text = scriptText;
  parentNode.appendChild(newScriptTag);
};

const loadQliroSDK = (checkoutContainer: HTMLDivElement, snippet: string) => {
  let initialized = false;

  return () => {
    if (initialized) return;
    initialized = true;
    checkoutContainer.innerHTML = snippet;
    let scriptsTags = checkoutContainer.getElementsByTagName('script');
    const toImport = scriptsTags[1];
    const parentNode = toImport.parentNode;

    if (toImport && parentNode) {
      importQliroSDK(toImport, parentNode);

      const scriptText = scriptsTags[0].text;
      createAndAppendScriptTag(parentNode, scriptText);
    }
  };
};

const QliroCheckout = ({ snippet }: { snippet: string }) => {
  const checkoutContainer = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const load = loadQliroSDK(checkoutContainer.current, snippet);
    load();
  }, [snippet]);

  return <div ref={checkoutContainer} id="qliro-container"></div>;
};

export default QliroCheckout;
