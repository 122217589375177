import * as Sentry from '@sentry/react';
import { applyMiddleware, compose, createStore } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { userConstants } from './constants';
import reducers from './reducers';

declare global {
  interface Window {
    __PRELOADED_STATE__: any;
    gtag?: any;
  }
}
// Grab the state from a global variable injected into the server-generated HTML
const preloadedState = window.__PRELOADED_STATE__;

// Allow the passed state to be garbage-collected
delete window.__PRELOADED_STATE__;

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  actionTransformer: (action) => {
    if (
      action.type === userConstants.LOGIN_SUCCESS ||
      action.type === userConstants.UPDATE_USER_SUCCESS ||
      action.user
    ) {
      // Return null to not log the action to Sentry
      return null;
    }
    return action;
  },
  stateTransformer: (state) => {
    try {
      const transformedState = JSON.parse(JSON.stringify(state));

      if (state.users && state.users.user) {
        transformedState.users.user = state.users.user.emailHash;
      }

      return transformedState;
    } catch (e) {
      return {};
    }
  },
});

const store = createStore(reducers, preloadedState, compose(applyMiddleware(thunkMiddleware), sentryReduxEnhancer));
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
