import { searchActions } from '@/actions';
import { LoadingIcon, SearchIcon } from '@/components/icons';
import Icon from '@/components/icons/Icon';
import { twBorderPrimary } from '@/helpers/theme';
import withAmplitudeExperiment from '@/hoc/withAmplitudeExperiment';
import withUserLocationPermission from '@/hoc/withUserLocationPermission';
import { __ } from '@/locale';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import SearchFunctions from './Functions';

class FakeSearch extends SearchFunctions {
  clearSearch = (e) => {
    const { dispatch } = this.props;
    this.setState({ q: '', location: '' }, () => {
      dispatch(searchActions.setParameter({ shouldUpdate: false }));
      dispatch(searchActions.removeParameter(['q']));
      dispatch(searchActions.stopLocationLoader());
      this.showSearchForm();
    });
  };

  getSearchText() {
    const { q, location } = this.state;
    if (q && location) {
      return q + ' ' + (location !== __('currentLocation') ? location : __('fakeSearchNear'));
    }
    if (q) {
      return q + ' ' + __('allSweden');
    }
    if (location) {
      return __('Everything') + ' ' + (location !== __('currentLocation') ? location : __('fakeSearchNear'));
    }
    return __('Everything') + ' ' + __('allSweden');
  }

  isPlaceholder = (value) => {
    if (value === __('Everything') + ' ' + __('allSweden') || value === __('Everything') + ' ' + __('fakeSearchNear')) {
      return true;
    }
    return false;
  };

  render() {
    let searchText = this.getSearchText();
    const clear =
      !this.isPlaceholder(searchText) && searchText.length > 0 ? (
        <button aria-label={__('Reset') + ' ' + __('search')} className="ml-auto block p-4" onClick={this.clearSearch}>
          <Icon variant="close-circle" />
        </button>
      ) : (
        ''
      );
    return (
      <div
        className={`flex-2 my-2 flex h-[44px] w-full items-center rounded-full border-[1px] border-solid md:my-4 ${
          !!clear ? twBorderPrimary() : 'border-black-600'
        }`}>
        <div className="p-4">
          {this.props.loading ? <LoadingIcon className="h-4 w-4" /> : <SearchIcon className="h-4 w-4" />}
        </div>
        <button
          aria-label={__('search')}
          onClick={this.showSearchForm}
          className={`flex-1 overflow-hidden text-ellipsis whitespace-nowrap text-left text-base leading-[44px] ${
            Boolean(clear) ? 'q' : ' text-black-600'
          }`}>
          {searchText}
        </button>
        {clear}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { search } = state;
  const { listType } = state.listType;

  return {
    search,
    listType,
    flags: state.flags,
  };
}

export default withUserLocationPermission(withRouter(connect(mapStateToProps)(withAmplitudeExperiment(FakeSearch))));
