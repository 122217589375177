import { __ } from '@/locale';
import Employee from './Employee';

function Staff(props) {
  const { employees, place = {}, isMpReviews } = props;

  if (place.about && place.about.settings && place.about.settings.hideEmployees) {
    return null;
  }
  const { employeeStats = null } = place.reviews || {};
  let list = <span className="text-black-600">{__('notListed')}</span>;
  if (employees.length > 0) {
    list = employees.map((employee) => {
      return (
        <Employee
          employee={employee}
          key={employee.id}
          rating={employeeStats && employeeStats[employee.id] && employeeStats[employee.id].score ? employeeStats[employee.id] : undefined}
          place={place}
          isMpReviews={isMpReviews}
        />
      );
    });
  }
  const label = place && place.about && place.about.employeesAlias ? place.about.employeesAlias : __('staff');
  return (
    <div id="staff" className="details-section scroll-mt-[120px]">
      <h2 className="mb-2 text-2xl font-semibold capitalize">{label}</h2>
      {list}
    </div>
  );
}

export default Staff;
