import { searchConstants } from '../constants';

let initialState = {
  extendedPlaces: null,
  extendedTotal: 0,
  extendedFetch: false,
  extendedError: null,
  extendedPage: 0,
};

export function extendedSearch(state = initialState, action) {
  switch (action.type) {
    case searchConstants.EXTENDED_SEARCH_START:
      return {
        ...state,
        extendedPlaces: null,
        extendedTotal: 0,
        extendedError: null,
        extendedFetch: true,
      };
    case searchConstants.EXTENDED_SEARCH_SUCCESS:
      return {
        ...state,
        extendedPlaces: action.action.places || [],
        extendedTotal: (action.action.hits && action.action.hits.value) || 0,
        extendedError: null,
        extendedFetch: false,
      };
    case searchConstants.EXTENDED_SET_PAGE:
      return {
        ...state,
        extendedPage: parseInt(action.page) || 0,
      };
    case searchConstants.EXTENDED_SEARCH_RESET:
      return initialState;
    default:
      return state;
  }
}
