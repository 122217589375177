import { LinkButton } from '@/components/elements/forms/buttons';
import Label, { LabelVariant } from '@/components/elements/Label/Label';
import Icon from '@/components/icons/Icon';
import { APP_ROUTES } from '@/constants/pages';
import { isSistaminuten } from '@/helpers';
import { _s } from '@/locale';
import { SearchPlace } from '@/types/api/services/search';
import { useRef, useState } from 'react';
import { Fragment } from 'react/jsx-runtime';
import { TooltipPortal } from '../Tooltip';

type CardTagsProps = {
  place: SearchPlace;
  source?: string;
  hideTag?: { [key in LabelVariant]?: boolean };
  tooltipContainer?: React.RefObject<HTMLDivElement>;
};

const CardTags = ({ place, hideTag, tooltipContainer, source }: CardTagsProps) => {
  const tooltipTriggerRef = useRef<HTMLButtonElement>(null);
  const [showTooltip, setShowTooltip] = useState(false);
  const { about, topSearch, distance, associations } = place;
  const { campaign: hideCampaign = false, wellness: hideWellness = false } = hideTag || {};

  const hasDiscount =
    !isSistaminuten() && !topSearch && about.settings && about.settings.topOfSearch && source === 'serp-page-top';
  const hasRocket = !isSistaminuten() && topSearch;
  const hasCampaign = !isSistaminuten() && about?.settings?.hasCampaigns && !hideCampaign;
  const hasSistaminutenDiscount = isSistaminuten() && about?.sistaminutenDiscount > 0;
  const hasKlarna = !isSistaminuten() && about?.settings?.hasKlarna;
  const hasQliro = !isSistaminuten() && about?.settings?.hasQliro;
  const hasGiftCard = !isSistaminuten() && about?.settings?.sellsGiftCard;
  const hasWellnessCard = !isSistaminuten() && about?.settings?.wellness && !hideWellness;
  const hasAssociation = associations?.length;
  // dont show certificate and association at the same time
  const hasCertificate = !isSistaminuten() && about?.settings?.displayCertificates && !hasAssociation;

  /**
   * do not allow labels to shrink when no available space, instead allow them to overflow
   * with scroll
   */
  const labelClasses = 'flex-shrink-0';

  const handleToggleTooltip = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setShowTooltip((prev) => !prev);
  };

  const handleCloseTooltip = () => {
    setShowTooltip(false);
  };

  const associationCard = (association, isCompact = false) => {
    return (
      <div className="flex flex-col items-start space-y-lg">
        <img src={association.imageUrl} className="h-[28px] w-auto" height={28} alt={association.name} />
        <h2 className="text-lg font-semibold">{association.name}</h2>
        {association.summary && !isCompact && <p className="text-black-100 text-sm">{association.summary}</p>}
        {association.slug && (
          <div className="flex items-end justify-end w-full">
            <LinkButton
              to={`${APP_ROUTES.ASSOCIATION_PAGES}/${association.slug}`}
              rightIcon={<Icon variant="external-link" color="white" />}
              variant="link"
              className="text-white">
              {_s('readMore')}
            </LinkButton>
          </div>
        )}
      </div>
    );
  };

  if (
    hasCampaign ||
    hasRocket ||
    hasDiscount ||
    hasSistaminutenDiscount ||
    hasKlarna ||
    hasQliro ||
    hasCertificate ||
    hasGiftCard ||
    hasWellnessCard ||
    associations?.length ||
    hasAssociation ||
    distance
  ) {
    return (
      <Fragment>
        {showTooltip && (
          <TooltipPortal
            offsetTop={40}
            targetRef={tooltipContainer}
            triggerRef={tooltipTriggerRef}
            onClose={handleCloseTooltip}
            onClickOutside={handleCloseTooltip}>
            <div className='p-lg space-y-lg text-white' onClick={(e) => e.stopPropagation()}>
              <div className="flex items-start justify-between relative">
                <div className="flex flex-col space-y-6 w-full">
                  {associations.length === 1
                    ? associationCard(associations[0], false) // Render in full mode if only 1
                    : associations.map((association, key) => (
                      // Render in compact mode if > 1
                      <div key={key} className="w-full">
                        {associationCard(association, true)} 
                      </div>
                      ))
                  }
                </div>
                <button className='absolute right-0' onClick={handleToggleTooltip}>
                  <Icon variant="close" color="white" size="md" />
                </button>
              </div>
            </div>
          </TooltipPortal>
        )}

        <div className="gap-xs no-scrollbar rounded-xs flex flex-row overflow-x-scroll sm:flex-wrap">
          {hasDiscount && <Label className={labelClasses} variant="discount" label={_s('20discount')} />}
          {hasRocket && <Label className={labelClasses} variant="rocket" />}
          {hasCampaign && <Label className={labelClasses} variant="campaign" />}
          {hasSistaminutenDiscount && (
            <Label className={labelClasses} variant="discount" label={'-' + about?.sistaminutenDiscount + '%'} />
          )}
          {hasKlarna && <Label className={labelClasses} variant="klarna" />}
          {hasQliro && <Label className={labelClasses} variant="qliro" />}
          {hasCertificate && <Label className={labelClasses} variant="certificate" />}
          {hasGiftCard && <Label className={labelClasses} variant="giftcard" />}
          {hasWellnessCard && <Label className={labelClasses} variant="wellness" />}
          {hasAssociation && (
            <button ref={tooltipTriggerRef} onClick={handleToggleTooltip} className="inline-flex outline-none">
              <Label className={labelClasses} variant="org" />
            </button>
          )}
          {distance && <Label className={labelClasses} label={`${distance}`} />}
        </div>
      </Fragment>
    );
  }
  return null;
};

export default CardTags;
