import { SuccessIcon } from '@/components/icons';
import '@/styles/modules/progressTracker.scss';

export type ProgressTrackerProps = {
  steps: number;
  currentStep: number;
  backAction?: () => void;
  abortAction?: () => void;
  backSteps?: number[]; // steps the backAction is triggered on
  abortSteps?: number[]; // steps the abortAction is triggered on
};

const ProgressTracker = ({
  steps,
  currentStep,
  backAction = null,
  abortAction = null,
  abortSteps = [],
  backSteps = [],
}: ProgressTrackerProps) => {
  return (
    <div className="steps" style={{ width: `${100 + 20 * steps}px` }}>
      {[...Array(steps)].map((_, index) => {
        const handleGoBack = backSteps.includes(index + 1) && backAction;
        const handleAbort = abortSteps.includes(index + 1) && abortAction;
        const clickProps = {
          onClick: handleGoBack || handleAbort || null,
        };
        return (
          <div className="flex items-center justify-center" key={index}>
            <span
              className={`step ${index + 1 < currentStep ? 'active' : ''} ${
                clickProps.onClick ? 'cursor-pointer' : ''
              }`}
              {...clickProps}>
              <span
                className={`number ${index + 1 > currentStep ? 'disabled' : ''} ${index < steps - 1 ? 'done' : ''} ${
                  index + 1 === currentStep ? 'active' : ''
                }`}>
                {index === steps - 1 ? <SuccessIcon className="h-full w-full p-[6px]" /> : index + 1}
              </span>
            </span>
            {index + 1 !== steps && (
              <span
                className={`divider bg-black-700 inline-block h-[2px] w-4 ${
                  index + 1 > currentStep - 1 ? 'bg-black-400' : ''
                }`}></span>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default ProgressTracker;
