type EmployeeBlockCancel = { about?: { settings?: { blockCancelBooking?: boolean } } };

export function bookingsAllowedToBeCancelled(employee: EmployeeBlockCancel): boolean {
  return !(employee && employee.about && employee.about.settings && employee.about.settings.blockCancelBooking);
}

type EmployeeBookingCancelThreshold = { about?: { settings?: { bookCancel?: number } } };
type PlaceBookingCancelThreshold = { about?: { book?: { cancel?: number } } };

export function getEmployeeCancelMargin(
  employee: EmployeeBookingCancelThreshold,
  place: PlaceBookingCancelThreshold,
): number {
  if (
    employee &&
    employee.about &&
    employee.about.settings &&
    (employee.about.settings.bookCancel || employee.about.settings.bookCancel === 0)
  ) {
    return employee.about.settings.bookCancel;
  }
  return place?.about?.book?.cancel ? place.about.book.cancel : 0;
}

export type EmployeeOnlinePaymentRequired = {
  about?: { settings?: { onlinePaymentRequired?: boolean; onlinePaymentRequiredOnlyForNewCustomers?: boolean } };
};
export type PlaceOnlinePaymentRequired = { id?: number; about?: { settings?: { onlinePaymentRequired?: boolean } } };
export type ServicesOnlinePaymentRequired = {
  service?: {
    about?: { settings?: { onlinePaymentRequired?: boolean; onlinePaymentRequiredOnlyForNewCustomers?: boolean } };
  };
  about?: { settings?: { onlinePaymentRequired?: boolean; onlinePaymentRequiredOnlyForNewCustomers?: boolean } };
};

export const isOnlinePaymentRequired = (
  employee: EmployeeOnlinePaymentRequired,
  place: PlaceOnlinePaymentRequired,
  services: ServicesOnlinePaymentRequired[],
  customerPlaces?: number[],
) => {
  const placeOnlinePaymentRequired = !!place?.about?.settings?.onlinePaymentRequired;
  const employeeOnlinePaymentRequired = employee?.about?.settings?.onlinePaymentRequired;
  const servicesOnlinePaymentRequired = (services || []).some((service) =>
    service?.service
      ? !!service.service?.about?.settings?.onlinePaymentRequired
      : !!service?.about?.settings?.onlinePaymentRequired,
  );

  const customerPlacesIncludesPlaceId = !!customerPlaces?.some((customerPlaceId) => customerPlaceId === place?.id);

  const servicesOnlinePaymentRequiredForNewCustomer = services.some((service) => {
    const hasOnlinePaymentRequired =
      !!service?.service?.about?.settings?.onlinePaymentRequiredOnlyForNewCustomers ||
      !!service?.about?.settings?.onlinePaymentRequiredOnlyForNewCustomers;
    return hasOnlinePaymentRequired;
  });

  const employeeOnlinePaymentRequiredOnlyForNewCustomers =
    !!employee?.about?.settings?.onlinePaymentRequiredOnlyForNewCustomers;
  if (
    servicesOnlinePaymentRequired ||
    (servicesOnlinePaymentRequiredForNewCustomer && !customerPlacesIncludesPlaceId) ||
    (employeeOnlinePaymentRequiredOnlyForNewCustomers && !customerPlacesIncludesPlaceId)
  ) {
    return true;
  }
  if (employeeOnlinePaymentRequired !== undefined) {
    return employeeOnlinePaymentRequired;
  }
  return placeOnlinePaymentRequired;
};
