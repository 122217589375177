import { KPPaymentMethodCategory } from '@/types/klarnaPayments';

type Props = {
  paymentMethodCategories: KPPaymentMethodCategory[];
};

const KlarnaPaymentMethodCategories = ({ paymentMethodCategories }: Props) => {
  if (!paymentMethodCategories.length) return <></>;

  return (
    <div className="gap-xxs flex flex-col">
      {paymentMethodCategories.map((cat, i) => (
        <div className="flex items-center justify-between" key={i}>
          <span className="font-bold">{cat.name}</span>
          <img alt="klarna badge" src={cat.assetURLs.descriptive} />
        </div>
      ))}
    </div>
  );
};

export default KlarnaPaymentMethodCategories;
