import { useLocationPermissionContext } from '@/hooks/useLocationPermission';

function withUserLocationPermission<P>(
  WrappedComponent: React.ComponentType<P & ReturnType<typeof useLocationPermissionContext>>,
) {
  const WithUserLocationPermission = (props: P) => {
    return <WrappedComponent {...props} {...useLocationPermissionContext()} />;
  };
  return WithUserLocationPermission;
}

export default withUserLocationPermission;
