import { LoadingIcon } from '@/components/icons';
import { HTMLAttributes } from 'react';
import { twMerge } from 'tailwind-merge';

type Props = HTMLAttributes<HTMLDivElement> & {
  text?: string;
  className?: string;
};

const LoadingPlaceHolder = ({ text = null, className = '', ...props }: Props) => {
  return (
    <div
      className={twMerge(
        `absolute bottom-0 left-0 right-0 top-0 z-[100] flex w-full justify-center overflow-hidden outline-none`,
        className,
      )}
      {...props}>
      <div className="mt-8 flex flex-col items-center">
        <LoadingIcon className="h-8 w-8" />
        {text && <p className="font-semibold">{text}</p>}
      </div>
      <div className="absolute -z-[1] h-full w-full bg-white opacity-75"></div>
    </div>
  );
};

export default LoadingPlaceHolder;
