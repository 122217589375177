import '@/styles/modules/banner.scss';
import { BannerLinkWrapper } from '../helpers';
import { BannerConfig } from '../types';

type BannerType4Props = Pick<
  BannerConfig,
  'background_image_sm' | 'background_image_md' | 'background_image_lg' | 'background_alt_text' | 'cta'
> & {
  position?: string; // only used for analytics
};

const BannerType4 = ({
  cta,
  background_image_lg,
  background_image_md,
  background_image_sm,
  background_alt_text,
  position,
}: BannerType4Props) => {
  return (
    <BannerLinkWrapper cta={cta} type="type_4" position={position}>
      <div className="relative text-center">
        <picture>
          <source media="(max-width: 340px)" srcSet={background_image_sm} />
          <source media="(max-width: 768px)" srcSet={background_image_md} />
          <source media="(min-width: 769px)" srcSet={background_image_lg} />
          <img className="h-full w-full" src={background_image_sm} alt={background_alt_text ?? ''} />
        </picture>
      </div>
    </BannerLinkWrapper>
  );
};

export default BannerType4;
