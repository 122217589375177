import CardWrapper from '@/components/modules/CardWrapper';
import { Link } from 'react-router-dom';

const ActionSteps = ({
  insideCard,
  title,
  steps,
}: {
  insideCard?: boolean;
  title: string;
  steps: { text: string; link?: string }[];
}) => {
  return (
    <>
      {insideCard && steps && (
        <CardWrapper className="w-[390px]">
          <div className="p-lg">
            <h2 className="text-black">{title}</h2>
            <br />
            <ol>
              {steps.map((step, index) => (
                <li className="mb-md pl-xs text-black-700" key={index}>
                  {`${index + 1}.`}{' '}
                  {step.link && (
                    <>
                      {' '}
                      <Link className="underline" to="/vad/var?wellness=true">
                        {step.link}
                      </Link>{' '}
                    </>
                  )}
                  {`${step.text}`}
                </li>
              ))}
            </ol>
          </div>
        </CardWrapper>
      )}
      {!insideCard && steps && (
        <div className="p-lg">
          <h2 className="text-black">{title}</h2>
          <br />
          <ol>
            {steps.map((step, index) => (
              <li className="mb-md pl-xs text-black-700" key={index}>
                {`${index + 1}.`}{' '}
                {step.link && (
                  <>
                    {' '}
                    <Link className="underline" to="/vad/var?wellness=true">
                      {step.link}
                    </Link>{' '}
                  </>
                )}
                {`${step.text}`}
              </li>
            ))}
          </ol>
        </div>
      )}
    </>
  );
};

export default ActionSteps;
