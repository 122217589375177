import { LinkButton } from '@/components/elements/forms/buttons';
import { getWellnessCardExpiryDateFromToday } from '@/helpers';
import { indexedArrayFromLength } from '@/helpers/arrays';
import { __, translationExists } from '@/locale';

const numFaq = 6;
const base = 'buyGiftcard.wellness.infoColumn.faq';

const WellnessFaq = () => (
  <>
    {indexedArrayFromLength(numFaq).map((i) => (
      <div key={i} className="my-8">
        <h3 className="my-0 font-semibold">{__(`${base}${i + 1}.title`)}</h3>
        <p className="my-0">
          {translationExists(`${base}${i + 1}.link`) && (
            <LinkButton to="/vad/var?wellness=true" label={__(`${base}${i + 1}.link`)} className="!p-0" />
          )}{' '}
          {__(`${base}${i + 1}.body`).replace('EXPIRE', getWellnessCardExpiryDateFromToday())}
          {translationExists(`${base}${i + 1}.linkAfterBody`) && (
            <>
              {' '}
              <LinkButton to="/vad/var?wellness=true" label={__(`${base}${i + 1}.linkAfterBody`)} className="!p-0" />
              {'.'}
            </>
          )}
        </p>
      </div>
    ))}
  </>
);

export default WellnessFaq;
