import { loadingConstants } from '../constants';

export const loadingActions = {
  show,
  hide,
};

function show(loadSource?: string) {
  return { type: loadingConstants.SHOW, payload: loadSource };
}

function hide() {
  return { type: loadingConstants.HIDE };
}
