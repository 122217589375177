import { loadingActions } from '@/actions';
import { server } from '@/helpers';
import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

class PlaceRedirectPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { url: null };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(loadingActions.show());
    const group = this.props.match.params.group ? '/' + this.props.match.params.group : '';
    server.request
      .get('/gotoold/' + this.props.match.params.id + group)
      .then((response) => {
        const url = response.data.url || '';
        let searchQuery = this.props.location.search || '';
        if (url.indexOf('?') !== -1 && this.props.location.search) {
          searchQuery = '&' + this.props.location.search.substring(1);
        }
        this.setState({
          url: `${url}${searchQuery}`,
        });
        dispatch(loadingActions.hide());
      })
      .catch((error) => {
        dispatch(loadingActions.hide());
      });
  }

  render() {
    if (this.state.url) {
      return <Redirect to={this.state.url} />;
    }

    return null;
  }
}

function mapStateToProps(state) {
  return {};
}

const PlaceRedirect = connect(mapStateToProps)(PlaceRedirectPage);
export default PlaceRedirect;
