import { Button } from '@/components/elements/forms/buttons';
import { ListItem } from '@/components/elements/lists';
import { WarningIcon } from '@/components/icons';
import Icon from '@/components/icons/Icon';
import PageViewLayout from '@/components/layouts/PageViewLayout/PageViewLayout';
import { history, url } from '@/helpers';
import { _s } from '@/locale';
import { supportServices } from '@/services';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';

type ReminderUser = {
  id: string;
  name: string;
  email: string;
};

type ReminderPlace = {
  id: string;
  name: string;
  erpId: string;
};

type ReminderEmployee = {
  id: string;
  name: string;
  erpId: string;
};

type ReminderBooking = {
  id: number;
  cancellationCode: string;
};

type ReminderItem = {
  id: number;
  user: ReminderUser;
  place: ReminderPlace;
  employee: ReminderEmployee;
  booking: ReminderBooking;
  text: string;
  createdAt: string;
};

const SupportRemindersLog = () => {
  const [formData, setFormData] = useState<any>({ value: url.returnGetParameter('value') || '' });
  const [list, setFormList] = useState<ReminderItem[]>([]);
  const [error, setError] = useState('');

  const searchRemindersLog = () => {    
    supportServices.getRemindersLog(window.location.search)
      .then((data) => {
        setFormList(data);
        if (data && data.length) {
          setError('');
        } else {
          setError('not found');
        }
      })
      .catch((e) => {
        setFormList([]);
        setError(e);
      });
  };

  useEffect(() => {
    searchRemindersLog();
  }, []);

  return (
    <PageViewLayout type="mainView" title={'Reminders Log'}>
      <div className="container mb-6">
        <div className={`gap-xxs flex w-full flex-col p-5`}>
          <label htmlFor="search-input" className="text-md text-black-900">
            Search reminders
          </label>
          <div className="flex w-full">
            <div className="border-black-200 gap-sm relative w-full flex rounded-l-lg border bg-white pl-3">
              <Icon variant="search" size="sm" className="mt-3" />
              <input
                id="search-input"
                placeholder="enter booking cancellation code"
                className="w-full"
                value={formData?.value || ''}
                onChange={(e) => {
                  setFormData({ value: e.target.value });
                  history.push({ search: url.changeGetParameter('value', e.target.value) });
                }}
                onKeyDown={(e)=>{
                  if (e.key === 'Enter') {
                    searchRemindersLog();
                  }
                }}
              />
              {!!formData?.value && (
                <button
                  onClick={() => {
                    setFormData({ value: '' });
                    history.push({ search: url.changeGetParameter('value', '') });
                  }}
                  className="min-h-[24px] min-w-[24px] outline-none">
                  <Icon size="sm" variant="close-circle" />
                </button>
              )}
            </div>
            <Button
                disabled={!formData?.value}
                size="lg"
                className="!h-[52px] !w-[74px] !rounded-bl-none !rounded-tl-none sm:!w-28 rounded-r-lg"
                onMouseDown={() => {
                  searchRemindersLog();
                }}>
                {_s('search')}
            </Button>
          </div>
          <ul>
            <li>
              <strong>Legend: </strong>
            </li>
            <li>
              <strong>By booking cancelation code:</strong> eg: YSDF6ZDER
            </li>
            <li>
              <strong>By email:</strong> test@bokadirekt.se
            </li>
          </ul>
        </div>
        {!!error && (
          <div>
            <ListItem>
              <div className="bg-black-50 block w-full rounded-lg p-6">
                <div className="flex flex-row">
                  <WarningIcon className="relative right-3 z-10 h-5 w-5" />
                  <span>{error}</span>
                </div>
              </div>
            </ListItem>
          </div>
        )}
        {!!list && !!list.length && (
          <div>
            {list.map((item, key) => (
              <ListItem
                key={key}
                className="relative"
                rightSlot={
                  <div className="absolute right-[50px] top-[30px] flex">
                    <div className={`px-5 py-3 ${item?.booking?.cancellationCode ? 'border-dashed border-2' : ''}`}>
                      {item?.booking?.cancellationCode ?? '-'}
                    </div>
                  </div>
                }
                >
                <div className='bg-black-50 block w-full rounded-lg p-6'>
                  <div className="flex flex-col">
                    <div className='flex'>
                      <img src={'/images/review-default-avatar.svg'} alt={item.user.name} className="mr-3" width="36" height="36" />
                      <div className='flex flex-col'>
                        <span className='font-semibold'>
                            {item.user.name} - {item.user.email}
                        </span>
                        <span className="text-sm">
                          {item.createdAt}
                        </span>
                      </div>
                    </div>

                    <div className="py-4">
                        {item.employee && item.employee.name && (
                          <span className="text-black-600">{_s('to')}&nbsp;
                            <span className="text-primary">{item.employee.name}</span>
                          </span>
                        )}
                        {item.place && item.place.name && (
                          <span className='font-semibold'>
                            <span>@ {item.place.name}</span>
                          </span>
                        )}
                    </div>

                    <div className="text-black-600">{item.text}</div>
                  </div>
                </div>
              </ListItem>
            ))}
          </div>
        )}
      </div>
    </PageViewLayout>
  );
};

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps)(SupportRemindersLog);
