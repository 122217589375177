import { ListItem, ListItemContent } from '@/components/elements/lists';
import Icon from '@/components/icons/Icon';
import { hasFeatureSetting, isServiceBookable } from '@/helpers';
import { useAppSelector } from '@/hooks';
import { _s } from '@/locale';
import { BookState } from '@/types/state/book';
import { useState } from 'react';
import {
  filterBookPlaceServicesByEmptyCategories,
  filterBookPlaceServicesByPickedServices,
  filterBookPlaceServicesBySelectedEmployee,
  getPickedEmployee,
} from '../BookAppointment.helpers';
import ServiceItem from './ServiceItem';

const ServiceCategoryList = () => {
  const { place, employee, services: pickedServices = [] } = useAppSelector((state) => state.book) as BookState;

  /**
   * Filter shown service categories by:
   * - empty categories
   * - selected employee
   * - already picked services
   */
  const serviceCategories = (() => {
    const filteredServiceCategories = filterBookPlaceServicesByEmptyCategories(
      filterBookPlaceServicesByPickedServices(place?.services || [], pickedServices),
    );

    const pickedEmployee = getPickedEmployee(employee, place.employees);

    if (!pickedEmployee) return filteredServiceCategories;

    return filterBookPlaceServicesBySelectedEmployee(filteredServiceCategories, pickedEmployee);
  })();

  const hasCollapsedSetting = hasFeatureSetting(place, 'collapsed_services');

  return (
    <ul>
      {serviceCategories.map((category, key) => {
        const isCategoryExpaned = (() => {
          if (serviceCategories.length === 1) {
            return true;
          }

          if (hasCollapsedSetting) return false;

          if (key === 0) {
            return true;
          }

          return false;
        })();

        return <ServiceCategoryItem key={key} category={category} isExpanded={isCategoryExpaned} />;
      })}
    </ul>
  );
};

export const ServiceCategoryItem = ({
  category,
  isExpanded,
}: {
  category: BookState['place']['services'][0];
  isExpanded: boolean;
}) => {
  const { place } = useAppSelector((state) => state.book) as BookState;
  const [expanded, setExpanded] = useState(isExpanded);

  const collapseClass = expanded ? 'block' : 'hidden';
  const categoryName = category ? (category.own === true ? category.name : _s(category.name)) : '';

  const handleToggleExpanded = () => setExpanded((prev) => !prev);

  const categoryServices = category.services.filter((service) => isServiceBookable(service, place));

  return (
    <>
      <ListItem
        underline
        leftPadding={false}
        rightPadding={false}
        onClick={handleToggleExpanded}
        rightSlot={<Icon variant={expanded ? 'chevron-s-up' : 'chevron-s-down'} />}
        verticalAlign="top">
        <ListItemContent content={categoryName} contentClassName="text-black-900 text-sm font-semibold" />
      </ListItem>
      <li>
        <ul className={'accordion-content ' + collapseClass} role="tabpanel">
          {categoryServices.map((service, idx) => (
            <ServiceItem
              key={service.id}
              service={service}
              isRecommended={false}
              underline={categoryServices.length - 1 !== idx}
            />
          ))}
        </ul>
      </li>
    </>
  );
};

export default ServiceCategoryList;
