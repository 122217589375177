import { Button } from '@/components/elements/forms/buttons';
import { __ } from '@/locale';
import React from 'react';

class BookWithCapacity extends React.Component {
  render() {
    return (
      <div className="action">
        <Button variant="primary">{__('book')}</Button>
      </div>
    );
  }
}

export default BookWithCapacity;
