import { ListItem, ListItemContent } from '@/components/elements/lists';
import DataGrid from '@/components/elements/lists/DataGrid';
import { CompanyAvatar } from '@/components/elements/redesign/Avatar';
import Icon from '@/components/icons/Icon';
import SelectedPaymentMethod from '@/components/templates/checkout/SelectedPaymentMethod/SelectedPaymentMethod';
import { _s } from '@/locale';
import { SelectedPaymentMethod as PaymentMethod } from '@/types/checkout';
import { Fragment } from 'react';

export type CheckoutDetailsGiftcard = {
  code: string;
  amount: string;
  label: string;
  expiryDate?: string;
  removeGiftcard?: (code: string) => void;
};

export type CheckoutDetailsService = {
  name: string;
  price: string;
  duration: string;
  discount?: string;
  amount?: number;
};

export type CheckoutDetailsBundle = {
  name: string;
  price: string;
  quantity: string;
  validity: string;
  discount?: string;
};

export type CheckoutDetailsProps = {
  logo: string;
  placeName: string;
  dateTime: string;
  performer: string;
  priceToPay: { label: string; price: string };
  information: string;
  payLater?: boolean;
  paymentMethod?: PaymentMethod;
  services?: CheckoutDetailsService[];
  bundles?: CheckoutDetailsBundle[];
  giftcards?: CheckoutDetailsGiftcard[];
};

const baseTranslationKey = 'components.modules.checkout.CheckoutDetails';

const CheckoutDetails = ({
  logo,
  placeName,
  dateTime,
  performer,
  priceToPay,
  information,
  payLater,
  paymentMethod,
  services,
  giftcards,
  bundles,
}: CheckoutDetailsProps) => {
  return (
    <div>
      <ListItem
        type="div"
        verticalAlign="top"
        leftSlot={<CompanyAvatar alt="" size="md" variant="default" imgUrl={logo} />}
        underline>
        <div className="space-y-xs">
          <p className="text-md text-black-900">{placeName}</p>
          <p className="text-black-600 text-m">{dateTime}</p>
          <p className="text-black-900 text-sm">{performer}</p>
        </div>
      </ListItem>
      {information && (
        <ListItem type="div" underline leftSlot={<Icon variant="info-circle" />}>
          <ListItemContent content={information} />
        </ListItem>
      )}
      <div className="px-lg">
        <DataGrid ariaLabel={_s(`${baseTranslationKey}.ariaLabel.title`)}>
          {services?.map((service, index) => {
            const isLastService = index === services.length - 1;
            const discount = service.discount;
            return (
              <Fragment key={service.name + index}>
                <DataGrid.Row key={index} hasPaddingTop>
                  <DataGrid.Cell
                    title={service.name}
                    type="muted"
                    ariaLabel={_s(`${baseTranslationKey}.ariaLabel.service.name`)}
                  />
                  <DataGrid.Cell
                    title={
                      discount ? (
                        <span className="text-highlight-700">
                          {discount} <span className="text-black-600 line-through">({service.price})</span>
                        </span>
                      ) : (
                        service.price
                      )
                    }
                    align="right"
                    ariaLabel={_s(`${baseTranslationKey}.ariaLabel.service.price`)}
                  />
                </DataGrid.Row>
                {service.duration && (
                  <DataGrid.Row {...(isLastService && { undeline: true })}>
                    <DataGrid.Cell
                      title={service.duration}
                      type="description"
                      ariaLabel={_s(`${baseTranslationKey}.ariaLabel.service.duration`)}
                    />
                  </DataGrid.Row>
                )}
              </Fragment>
            );
          })}
          {giftcards?.map(({ code, amount, expiryDate, label, removeGiftcard }) => (
            <Fragment key={code}>
              <DataGrid.Row hasPaddingTop>
                <DataGrid.Cell title={label} type="muted" />
                <DataGrid.Cell
                  title={amount}
                  align="right"
                  type="discount"
                  ariaLabel={_s(`${baseTranslationKey}.ariaLabel.giftcard.discount`)}
                  {...(removeGiftcard && {
                    rightIcon: (
                      <button
                        className="flex items-center justify-center rounded-full duration-75 focus:outline-none focus-visible:ring-2 focus-visible:ring-[#51a49266]"
                        onClick={() => removeGiftcard(code)}>
                        <Icon variant="trash" />
                        <span className="sr-only">{_s(`${baseTranslationKey}.giftcard.removeGiftcard`)}</span>
                      </button>
                    ),
                  })}
                />
              </DataGrid.Row>
              <DataGrid.Row undeline>
                <DataGrid.Cell
                  title={`${code}${expiryDate ? ` ${_s('expires')}: ${expiryDate}` : ''}`}
                  type="description"
                  ariaLabel={_s(`${baseTranslationKey}.ariaLabel.giftcard.code`)}
                />
              </DataGrid.Row>
            </Fragment>
          ))}
          {bundles?.map(({ name, price, quantity, validity, discount }, key) => {
            const isLastBundle = key === bundles.length - 1;
            return (
              <Fragment key={name + key}>
                <DataGrid.Row key={key} hasPaddingTop>
                  <DataGrid.Cell
                    title={name}
                    type="muted"
                    ariaLabel={_s(`${baseTranslationKey}.ariaLabel.service.name`)}
                  />
                  <DataGrid.Cell
                    title={
                      discount ? (
                        <span className="text-highlight-700">
                          {discount} <span className="text-black-600 line-through">({price})</span>
                        </span>
                      ) : (
                        price
                      )
                    }
                    align="right"
                    ariaLabel={_s(`${baseTranslationKey}.ariaLabel.service.price`)}
                  />
                </DataGrid.Row>
                <DataGrid.Row>
                  <DataGrid.Cell
                    title={quantity}
                    type="description"
                    ariaLabel={_s(`${baseTranslationKey}.ariaLabel.service.price`)}
                  />
                </DataGrid.Row>
                {validity && (
                  <DataGrid.Row {...(isLastBundle && { undeline: true })}>
                    <DataGrid.Cell
                      title={validity}
                      type="description"
                      ariaLabel={_s(`${baseTranslationKey}.ariaLabel.service.duration`)}
                    />
                  </DataGrid.Row>
                )}
              </Fragment>
            );
          })}
          {!payLater && (
            <DataGrid.Row hasPaddingTop>
              <DataGrid.Cell title={priceToPay.label} type="header" />
              <DataGrid.Cell
                title={priceToPay.price}
                type="header"
                align="right"
                ariaLabel={_s(`${baseTranslationKey}.ariaLabel.subtotal.amount`)}
              />
            </DataGrid.Row>
          )}
          {payLater && (
            <>
              <DataGrid.Row hasPaddingTop>
                <DataGrid.Cell title={priceToPay.label} type="muted" />
                <DataGrid.Cell
                  title={priceToPay.price}
                  align="right"
                  ariaLabel={_s(`${baseTranslationKey}.ariaLabel.subtotal.amount`)}
                />
              </DataGrid.Row>
              <DataGrid.Row>
                <DataGrid.Cell title={_s(`${baseTranslationKey}.subtotal.payTodayLabel`)} type="header" />
                <DataGrid.Cell
                  title="0 kr"
                  align="right"
                  type="header"
                  ariaLabel={_s(`${baseTranslationKey}.ariaLabel.subtotal.amount`)}
                />
              </DataGrid.Row>
            </>
          )}
        </DataGrid>
      </div>
      {paymentMethod && <SelectedPaymentMethod method={paymentMethod} />}
    </div>
  );
};

export default CheckoutDetails;
