import LoadingPlaceHolder from '@/components/elements/LoadingPlaceholder';
import type { TopTabBarItem as TopTabBarItemType } from '@/components/elements/tabs/types';
import TopTabBar from '@/components/modules/navigation/TopTabBar/TopTabBar';
import { promiseWrapper } from '@/helpers';
import { BundleTab, userService } from '@/services';
import {
  ClientBundle,
  GetBundlesByTabResponse,
  getBundlesByTabResponseSchema,
} from '@/types/api/services/users/schema';
import { Dispatch, useEffect, useReducer } from 'react';
import { useLocation } from 'react-router-dom';
import BundlesList from './BundlesList';

async function fetchAndSetBundles(selectedTab: BundleTab, dispatch: Dispatch<BundleAction>): Promise<void> {
  dispatch({ type: 'FETCH_BUNDLES' });
  const { data, error } = await promiseWrapper(userService.getBundlesByTab(selectedTab));

  const validated = getBundlesByTabResponseSchema.safeParse(data);

  if (!validated.success || error) {
    dispatch({ type: 'FETCH_BUNDLES_ERROR' });
    return;
  }

  dispatch({
    type: 'FETCH_BUNDLES_SUCCESS',
    payload: { bundles: validated.data.bundles ?? [], othersExist: validated.data.othersExist },
  });
}

type BundleState = {
  bundles: ClientBundle[];
  loading: boolean;
  hideEmptyStateBody: boolean;
};

type BundleAction =
  | { type: 'FETCH_BUNDLES' }
  | { type: 'FETCH_BUNDLES_SUCCESS'; payload: GetBundlesByTabResponse }
  | { type: 'FETCH_BUNDLES_ERROR' }
  | { type: 'CLEAR_BUNDLES' };

const bundlesReducer = (state: BundleState, action: BundleAction) => {
  switch (action.type) {
    case 'FETCH_BUNDLES':
      return {
        ...state,
        loading: true,
      };
    case 'FETCH_BUNDLES_SUCCESS':
      return {
        ...state,
        loading: false,
        bundles: action.payload.bundles,
        hideEmptyStateBody: Boolean(action.payload.othersExist),
      };
    case 'FETCH_BUNDLES_ERROR':
      return {
        ...state,
        loading: false,
        bundles: [],
      };
    case 'CLEAR_BUNDLES':
      return {
        ...state,
        bundles: [],
      };

    default:
      return state;
  }
};

const getBundlesSelectedTab = (filter: string = ''): BundleTab => {
  switch (filter) {
    case 'uFilterBundleValid':
      return 'valid';
    case 'uFilterBundleUsed':
      return 'used';
    case 'uFilterBundleExpired':
      return 'expired';
    default:
      return 'valid';
  }
};

type BundlesTabProps = {
  filter: string;
  menu?: TopTabBarItemType[];
};

const BundlesTab = ({ filter, menu }: BundlesTabProps) => {
  const location = useLocation();
  const [state, dispatch] = useReducer(bundlesReducer, {
    bundles: [],
    loading: true,
    hideEmptyStateBody: true,
  });

  const selectedTab = getBundlesSelectedTab(filter);

  useEffect(() => {
    fetchAndSetBundles(selectedTab, dispatch);
    return () => {
      dispatch({ type: 'CLEAR_BUNDLES' });
    };
  }, [selectedTab]);

  return (
    <div className="pb-lg">
      <TopTabBar
        isSubMenu
        count={state.bundles.length}
        items={menu}
        onTabClick={(menuItemIndex) => menu[menuItemIndex]?.onClick?.()}
        defaultSelected={menu.findIndex((item) => item.to === location.pathname)}
      />
      <div className="relative min-h-[100px] w-full">
        {state.loading ? (
          <LoadingPlaceHolder />
        ) : (
          <BundlesList
            bundles={state.bundles}
            hideEmptyStateBody={state.hideEmptyStateBody}
            selectedTab={selectedTab}
          />
        )}
      </div>
    </div>
  );
};

export default BundlesTab;
