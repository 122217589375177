import { userActions } from '@/actions';
import Avatar from '@/components/elements/Avatar';
import { Card } from '@/components/elements/cards';
import { Button, LinkButton } from '@/components/elements/forms/buttons';
import LoadingPlaceHolder from '@/components/elements/LoadingPlaceholder';
import PageViewLayout from '@/components/layouts/PageViewLayout/PageViewLayout';

import { isServer, isSistaminuten, url } from '@/helpers';
import { __ } from '@/locale';
import { bookServices } from '@/services';
import { getScreenName, handleLoginClick } from '@/services/navigationServices';
import { NavItemIdentifier } from '@/types/navigation';
import queryString from 'query-string';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

class FinishAccount extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      booking: null,
    };
  }

  async componentDidMount() {
    if (!isServer) {
      window.scrollTo(0, 0);

      const parsed = !isServer ? url.arrayToObj(queryString.parse(window.location.search)) : {};
      this.setState({ email: parsed.email || '' });
      this.setState({ loading: true });
      const booking = await bookServices.getByOrderId(this.props.match.params.bookingId, parsed.placeId).catch((e) => {
        return false;
      });

      this.setState({ booking });

      if (
        (isSistaminuten() && booking && booking.id) ||
        (this.props.user?.contact?.email === parsed.email && booking?.id)
      ) {
        this.props.history.push('/bokning/' + booking.id);
      } else if (isSistaminuten()) {
        this.props.history.push('/');
      } else if (this.props.user) {
        this.props.dispatch(userActions.logout());
      }

      this.setState({ loading: false });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.user && this.props.user !== prevProps.user) {
      this.state.booking?.id
        ? this.props.history.push('/bokning/' + this.state.booking.id)
        : this.props.history.push('/bokningar');
    }
  }

  render() {
    const { loading, email } = this.state;

    return (
      <PageViewLayout type="mainView" footerDisplayOption={{ from: 'sm', to: 'all' }}>
        <div className="bg-black-100 mx-auto pt-4 sm:bg-white sm:bg-none">
          <div className="container mx-auto sm:!max-w-[480px]">
            <Card className="my-0 flex flex-col bg-white text-center sm:shadow">
              <div className="mb-2 flex justify-center">
                <Avatar src={null} size="lg" />
              </div>
              <h2 className="mb-2 font-semibold">{__('viewBookingWithBokadirektAccount')}</h2>
              <p className="text-black-600 mb-4 pb-3">
                {__('completeAccountText1')} <span className="text-black-900 font-semibold">{email}</span>{' '}
                {__('completeAccountText2')}
              </p>
              {loading && <LoadingPlaceHolder />}
              {!loading && (
                <Button
                  block
                  variant="primary"
                  onClick={() => {
                    handleLoginClick(this.props.dispatch, NavItemIdentifier.LogIn, getScreenName());
                  }}
                  className="text-primary"
                  style={{ color: '#fff' }}>
                  {__('completeAccountShowBooking')}
                </Button>
              )}
              <span className="text-black-600 mt-3 text-sm">{__('itTakesAMinute')}</span>
            </Card>
            <div className="py-5 text-center">
              <p className="pb-3">{__('cancelWithoutAccount')}</p>
              <LinkButton block to="/cancel" className="font-semibold" style={{ fontSize: 13 }}>
                {__('enterCancellationCode')}
              </LinkButton>
            </div>
          </div>
        </div>
      </PageViewLayout>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.users;
  return {
    user,
  };
}

export default withRouter(connect(mapStateToProps)(FinishAccount));
