import { getPlaceTimezone, isEmpty, secondsToHour } from '@/helpers';
import { __ } from '@/locale';
import moment from 'moment';
import 'moment-timezone';
import React from 'react';

export default class Program extends React.Component {
  constructor(props) {
    super(props);

    const { place } = this.props;
    const salonTime = moment.tz(getPlaceTimezone(place));
    const startOfDay = salonTime.clone().startOf('day');

    this.state = {
      currentDay: salonTime.isoWeekday(), // 1-7
      currentSeconds: salonTime.diff(startOfDay, 'seconds'),
    };
  }

  renderDay(index = 1, from = 0, to = 0) {
    const dayNames = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

    const currentClass = index === this.state.currentDay ? '!text-black-900' : '!text-black-600';

    let status = '';
    let validHours = from >= 0 && to > from;
    let hours = validHours ? secondsToHour(from) + ' - ' + secondsToHour(to) : __('closed');

    if (index === this.state.currentDay) {
      status =
        this.state.currentSeconds > from && this.state.currentSeconds < to
          ? __('openNow')
          : validHours && this.state.currentSeconds + 30 * 60 >= from
          ? __('openSoon')
          : __('closeNow');
    }

    return {
      status: status,
      liHtml: (
        <li key={index} className={`${currentClass} relative flex w-full list-none flex-row leading-[28px]`}>
          <span className="basis-[60px]">{__(dayNames[index - 1].toLowerCase())}</span>
          <span className="z-auto flex-1">{hours}</span>
        </li>
      ),
    };
  }

  render() {
    const { program } = this.props;

    let renderedProgram = null;
    if (!isEmpty(program) && typeof program === 'object') {
      let ul = [];
      let status = '';

      [1, 2, 3, 4, 5, 6].forEach((i) => {
        const currentProgram = program[i] || {};
        let dayItem;

        if (currentProgram.length) {
          let t = 0;
          let f = 60 * 60 * 24;
          currentProgram.forEach((item) => {
            if (item.from < f) {
              f = item.from;
            }

            if (item.to > t) {
              t = item.to;
            }
          });
          dayItem = this.renderDay(i, f, t);
        } else {
          dayItem = this.renderDay(i, currentProgram.from, currentProgram.to);
        }

        ul.push(dayItem.liHtml);
        if (dayItem.status) {
          status = dayItem.status;
        }
      });

      // Sunday program
      const currentProgram = program[0] || {};
      let dayItem;
      if (currentProgram.length) {
        let t = 0;
        let f = 60 * 60 * 24;
        currentProgram.forEach((item) => {
          if (item.from < f) {
            f = item.from;
          }

          if (item.to > t) {
            t = item.to;
          }
        });
        dayItem = this.renderDay(7, f, t);
      } else {
        dayItem = this.renderDay(7, currentProgram.from, currentProgram.to);
      }

      ul.push(dayItem.liHtml);
      if (dayItem.status) {
        status = dayItem.status;
      }
      //END Sunday program
      if (status) {
        ul.unshift(
          <li key="8" className="!text-black-900 font-bold">
            {status}
          </li>,
        );
      }
      renderedProgram = <ul>{ul}</ul>;
    } else {
      if (!isEmpty(program)) {
        renderedProgram = program.trim();
      }
    }

    return <div className="text-black-600 min-h-[20px] w-full whitespace-pre-wrap">{renderedProgram}</div>;
  }
}
