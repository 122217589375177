import { promiseWrapper } from '@/helpers';
import { adyenServices } from '@/services';
import { PaymentCard } from '@/types/paymentcards';
import { ReactNode, createContext, useContext, useEffect, useState } from 'react';

export type UseCardsContextType = ReturnType<typeof useCardsManager>;

export const useCardsManager = (
  initialState: PaymentCard[],
  fetch: boolean = true,
): {
  cards: PaymentCard[];
  loading: boolean;
  loadCards: () => void;
  error: boolean;
  removeCard: (id: string) => Promise<boolean>;
  waitForCard: (cardId: string) => Promise<PaymentCard>;
} => {
  const [cards, setCards] = useState<PaymentCard[]>(initialState);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(fetch);
  const [fetchCards, setFetchCards] = useState(fetch);

  useEffect(() => {
    setFetchCards(fetch);
  }, [fetch]);

  useEffect(() => {
    if (fetchCards) {
      (async () => {
        setLoading(true);
        setError(false);
        const { data, error } = await promiseWrapper(adyenServices.getCards());
        if (error) {
          setError(true);
        } else {
          setCards(data);
        }
        setLoading(false);
      })();
    }
    setFetchCards(false);
  }, [fetchCards]);

  const loadCards = () => setFetchCards(true);

  const waitForCard = async (cardId: string) => {
    setLoading(true);
    setError(false);
    for (let i = 1; i <= 3; i++) {
      const { data, error } = await promiseWrapper(adyenServices.getCards());

      if (error || i === 3) {
        setError(true);
        setLoading(false);
        return;
      }

      const newCard = data.find((card) => card.id === cardId);

      if (newCard) {
        setCards(data);
        setLoading(false);
        return newCard;
      }

      await new Promise((resolve) => setTimeout(resolve, 500 * Math.pow(2, i)));
    }
  };

  /**
   * @param id - stored payment method id
   * @returns  boolean - true if card was removed successfully
   */
  const removeCard = async (id: string) => {
    const { error } = await promiseWrapper(await adyenServices.removeCard(id));
    return !error;
  };

  return { cards, error, loading, loadCards, removeCard, waitForCard };
};

const UseCardsContext = createContext<UseCardsContextType>({
  cards: [],
  loading: true,
  loadCards: () => {},
  error: false,
  removeCard: () => Promise.resolve(false),
  waitForCard: () => Promise.resolve(undefined),
});

export const useCardsContext = (): UseCardsContextType => {
  const context = useContext(UseCardsContext);
  if (!context.cards) {
    throw new Error('useCardsContext must be used within a UseCardsProvider');
  }
  return context;
};

export const UseCardsProvider: React.FC<{
  children: ReactNode;
  fetchOnMount?: boolean;
  initialCards?: PaymentCard[];
}> = ({ children, initialCards = [], fetchOnMount = false }) => {
  return (
    <UseCardsContext.Provider value={useCardsManager(initialCards, fetchOnMount)}>{children}</UseCardsContext.Provider>
  );
};
