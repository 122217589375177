import { TabItem } from '@/components/elements/tabs/types';
import { IconProps } from '@/components/icons/types';

export enum NavItemIdentifier {
  Search = 'search',
  Logout = 'user_log_out',
  Profile = 'profile',
  Deals = 'deals',
  Bookings = 'bookings',
  Favorites = 'favorites',
  PaymentMethods = 'payment_methods',
  LogIn = 'user_log_in',
  GiftCardBuy = 'gift_card_buy',
  GiftCardBuyNew = 'giftcard_buy_new',
  GiftCardUse = 'gift_card_use',
  WellnessCardBuy = 'wellness_card_buy',
  WellnessCardUse = 'wellness_card_use',
  ConnectCompany = 'b2b',
  CompanyLogin = 'merchant',
  AccountSettings = 'account_settings',
  Cancel = 'booking_cancel',
  Support = 'support',
  Hamburger = 'hamburger',
  More = 'more',
  Home = 'home',
  DownloadApps = 'download_apps',
  Bundles = 'bundles',
  Sistaminutentider = 'sistaminutentider',
}

export type BottomNavBarItem = TabItem & { identifier?: NavItemIdentifier };

export type NavItem = {
  id?: string;
  label?: string;
  icon: IconProps['variant'];
  to?: string;
  identifier: NavItemIdentifier;
  selected?: boolean;
  breakpoint?: string;
  externalLink?: boolean;
};
