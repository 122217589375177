import { css, StyleSheet } from 'aphrodite/no-important';
import PropTypes from 'prop-types';

import { ChevronIcon } from '@/components/icons';
import defaults from '../theme';
import deepMerge from '../utils/deepMerge';
// import Icon from './Icon';

function Arrow({ direction, icon, onClick, size, ...props }, { theme }) {
  const classes = StyleSheet.create(deepMerge(defaultStyles, theme));

  return (
    <button
      type="button"
      className={`flex items-center justify-center outline-none ${css(
        classes.arrow,
        classes['arrow__direction__' + direction],
        size && classes['arrow__size__' + size],
      )}`}
      aria-label={direction === 'left' ? 'Previous' : 'Next'}
      onClick={onClick}
      onTouchEnd={onClick}
      {...props}>
      <span className="bg-black-900 rounded-full p-2">
        <ChevronIcon className={`h-5 w-5 text-white ${direction === 'right' ? '-rotate-90' : 'rotate-90'}`} />
      </span>
    </button>
  );
}

// Button icon <Icon fill={!!theme.arrow && theme.arrow.fill || defaults.arrow.fill} type={icon} />

Arrow.propTypes = {
  direction: PropTypes.oneOf(['left', 'right']),
  icon: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  size: PropTypes.oneOf(['medium', 'small']).isRequired,
};
Arrow.defaultProps = {
  size: 'medium',
};
Arrow.contextTypes = {
  theme: PropTypes.object.isRequired,
};

const defaultStyles = {
  arrow: {
    background: 'none',
    border: 'none',
    borderRadius: 4,
    cursor: 'pointer',
    outline: 'none',
    position: 'absolute',
    top: '50%',

    // disable user select
    WebkitTouchCallout: 'none',
    userSelect: 'none',
  },

  // sizes
  arrow__size__medium: {
    height: defaults.arrow.height,
    marginTop: defaults.footer.height * -1,
    width: 40,

    '@media (min-width: 768px)': {
      width: 100,
      height: defaults.arrowDesktop.height,
    },
  },
  arrow__size__small: {
    height: defaults.thumbnails.height,
    marginTop: defaults.thumbnails.height / -2,
    width: 30,
    '@media (min-width: 500px)': {
      width: 40,
    },
  },

  // direction
  arrow__direction__right: {
    right: defaults.containerGutter.horizontal,
  },
  arrow__direction__left: {
    left: defaults.containerGutter.horizontal,
  },
};

export default Arrow;
