import Avatar from '@/components/elements/Avatar';
import { ListItem, ListItemContent } from '@/components/elements/lists';
import { ReadMoreModal } from '@/components/modules/modals';
import { getBundleValidity } from '@/helpers';
import { _s } from '@/locale';
import { ReactNode } from 'react';
import BundleNoticeBanner from '../BundleNoticeBanner';
import { PlaceBundle } from './Bundle';

type BundleReadMoreProps = {
  place: any;
  bundle: PlaceBundle;
  actionButton: ReactNode;
  bundlePrice: ReactNode;
  showMoreInfo: boolean;
  handleOnCloseMoreInfo?: Function;
  handleOnOpenMoreInfo?: Function;
};

const BundleReadMore = ({
  bundle,
  place,
  actionButton,
  bundlePrice,
  showMoreInfo,
  handleOnOpenMoreInfo,
  handleOnCloseMoreInfo,
}: BundleReadMoreProps) => {
  const placeEmployees = {};
  place.employees.forEach((employee) => {
    placeEmployees[employee.id] = employee;
  });

  const modalContent = (
    <>
      <div className="pb-lg">
        <BundleNoticeBanner />
      </div>
      <div className="mb-2 text-lg font-semibold">{bundle.name}</div>
      <div className="text-md mb-2 font-semibold">{`(${_s('buyBundles.quantity', {
        count: bundle.service.quantity,
      })})`}</div>
      {bundle.validMonths && <div className="text-black-600 text-m mb-2">{getBundleValidity(bundle.validMonths)}</div>}
      <div className="mb-2 flex flex-col md:flex-row">
        <span className="text-black-600 text-m">
          {bundle.durationLabel}
          {bundle.durationLabel ? ', ' : ''}
          {bundlePrice}
        </span>
      </div>
      <div className="mb-8 mt-4 whitespace-pre-wrap">{bundle.description}</div>
      <div className="text-h-m mb-2 font-semibold">{_s('buyBundles.availablePractitioners')}</div>
      <div className="pb-lg">
        {bundle.employees.map(
          (id, key) =>
            placeEmployees[id]?.id && (
              <ListItem
                leftSlot={<Avatar className="mr-2" src={placeEmployees[id].about.avatar} size="md" />}
                underline
                key={key}>
                <ListItemContent
                  content={''}
                  subTitle={placeEmployees[id].about.jobTitle}
                  title={placeEmployees[id].about.name}
                />
              </ListItem>
            ),
        )}
      </div>
    </>
  );

  const moreInfoProps = {
    actionText: _s('readMore'),
    title: _s('readMore'),
    info: modalContent,
    button: actionButton,
    isOpen: showMoreInfo,
    handleOnClose: handleOnCloseMoreInfo,
    handleOnOpen: handleOnOpenMoreInfo,
  };

  return <ReadMoreModal className="text-sm" modalProps={{ ...moreInfoProps }} />;
};

export default BundleReadMore;
