import { bookActions } from '@/actions';
import Avatar from '@/components/elements/Avatar';
import LoadingPlaceHolder from '@/components/elements/LoadingPlaceholder';
import { Button, Fab } from '@/components/elements/forms/buttons';
import { CheckIcon } from '@/components/icons';
import Icon from '@/components/icons/Icon';
import { ModalContent, ModalDialog } from '@/components/modules/modals';
import { activeUsersCount, findEmployee, hoursFromSeconds, isMobile, startOfDay } from '@/helpers';
import { __ } from '@/locale';
import React from 'react';
import { connect } from 'react-redux';
import { TextInput } from '../booking-checkout/EmployeeForm';

class EmployeePicker extends React.Component {
  constructor(props) {
    super(props);
    this.handleSelect = this.handleSelect.bind(this);
    this.pickEmployee = this.pickEmployee.bind(this);
    this.state = { showSelect: false };

    if (props.employee && props.place) {
      this.state.employee = findEmployee(props.employee, props.place.employees);
    }
  }

  componentDidUpdate() {
    if (
      this.state.employee &&
      this.props.selectedEmployee &&
      this.state.employee.id !== this.props.selectedEmployee.id
    ) {
      this.setState({ employee: findEmployee(this.props.selectedEmployee.id, this.props.place.employees) });
    }
  }

  checkEmployeeList(employees) {
    const active = activeUsersCount(employees, this.props);
    return active.employeeCount;
  }

  isAvailable(id) {
    const { dispatch, time, availability } = this.props;
    if (time && time.selected) {
      if (!availability || !availability.fromErpOverview || !availability.fromErpOverview.employees[id]) {
        dispatch(bookActions.removeHour());
      }
    }
  }

  pickEmployee(id, silent = false) {
    const { place, dispatch, services, time } = this.props;
    const selectedEmployee = findEmployee(id, place.employees);

    let day = startOfDay();
    if (time && time.timestamp) {
      day = time.timestamp;
    }

    dispatch(bookActions.getAvailability(services, day, selectedEmployee.id || 0, 'pickEmployee'));

    this.setState({ employee: selectedEmployee });
    if (id && selectedEmployee && selectedEmployee.about) {
      dispatch(bookActions.pickEmployee(id, selectedEmployee.about.priceListId || 0));
      this.isAvailable(id);
    } else {
      dispatch(bookActions.removeEmployee());
    }

    if (!silent) {
      this.handleSelect();
    }
  }

  handleSelect() {
    if (this.state.showSelect) {
      this.setState({ showSelect: false });
    } else {
      this.setState({ showSelect: true });
    }
    if (this.props.close) {
      this.props.close();
    }
  }

  checkAvailability(list, time) {
    let selected = false;
    let available = false;
    let firstInterval = false;

    if (!time) {
      return {};
    }

    list.forEach((item) => {
      if (parseInt(item.from, 10) === parseInt(time, 10)) {
        selected = { status: 'available', extra: null };
        available = true;
      }

      if (item.from > time && !available && !firstInterval) {
        selected = { status: 'unavailable', extra: __('available') + hoursFromSeconds(item.from) };
        firstInterval = true;
      }
    });

    return selected ? selected : { status: 'unavailable' };
  }

  getEmployeeRow(employee, callback, employeeNumber, skip, forceButtons, showCheckbox) {
    const { availability, time, /*maxDisplayed,*/ noButtons } = this.props;
    // const displayed = maxDisplayed || 11;
    const employeeStatus = {};
    if (
      (time && time.selected) ||
      !availability ||
      !availability.fromErpOverview ||
      !availability.fromErpOverview.employees[employee.id]
    ) {
      employeeStatus.type = 'unavailable';
      employeeStatus.extra = {};
    }

    const checked = this.state.employee && employee.id === this.state.employee.id;
    return (
      <div
        className={`${isMobile() ? 'border-black-100 border-b-[1px] last:border-none' : ''} w-full cursor-pointer`}
        key={employee.id}
        data-id={employee.id}
        onClick={callback}>
        <div className="flex items-center py-3">
          {showCheckbox && (
            <span
              className={`text-primary border-black-300 mr-3 flex h-6 w-6 flex-none items-center justify-center rounded-full border-[1px] transition-all duration-300 ${
                checked ? `!border-primary bg-primary` : ''
              }`}>
              {checked && <CheckIcon className="h-6 w-6" />}
            </span>
          )}
          <Avatar src={employee.about.avatar} alt="avatar" size="64" />
          <div className="my-auto flex w-full flex-row pl-3 pr-2">
            <div className="flex flex-col">
              <span>{employee.about.name}</span>
              <span className="text-black-600 text-sm">{employee.about.jobTitle}</span>
            </div>
          </div>
          {(!noButtons || forceButtons) && <Button variant="secondary">{__('select')}</Button>}
        </div>
      </div>
    );
  }

  addService = (e) => {
    const { dispatch, employee, place } = this.props;
    const selectedEmployee = findEmployee(employee, place.employees);

    if (employee) {
      dispatch(bookActions.pickEmployee(selectedEmployee.id, selectedEmployee.about.priceListId));
      dispatch(bookActions.keepEmployee());
    }

    this.setState({ showServices: true });
  };

  getEmployeeNumber() {
    const { place } = this.props;
    let employeeNumber = 0;
    place.employees.forEach((employee) => {
      if (!this.isEmployeeAvailable(employee)) {
        return null;
      }
      employeeNumber++;
    });
    return employeeNumber;
  }

  getLoader() {
    const { loadSource, show } = this.props;

    let loader = null;
    if (show && loadSource === 'pickService') {
      loader = <LoadingPlaceHolder />;
    }
    return loader;
  }

  isEmployeeAvailable(employee) {
    const { availability } = this.props;
    if (!availability || !availability.fromErpOverview || !availability.fromErpOverview.employees[employee.id]) {
      return null;
    }

    return true;
  }

  getEmployees(employees, lastEmployeeId, display, filter, showButtons, showCheckbox) {
    return employees
      .filter((employee) => this.isEmployeeAvailable(employee))
      .map((employee, i) => {
        if (
          i >= display &&
          (!this.state.employee || (this.state.employee && employee && employee.id !== this.state.employee.id))
        ) {
          return null;
        }

        if (filter && employee && employee.about.name.toLowerCase().indexOf(filter.toLowerCase()) === -1) {
          return null;
        }

        lastEmployeeId = employee.id;
        return this.getEmployeeRow(
          employee,
          () => {
            this.pickEmployee(employee.id, false);
            if (this.state.showPopup) {
              this.setState({ showPopup: false, searchValue: undefined });
            }
          },
          i + 1,
          true,
          showButtons,
          showCheckbox,
        );
      })
      .filter((a) => a);
  }
  handleInputChange = (e) => {
    const { value } = e.target;
    this.setState({ searchValue: value });
  };

  render() {
    const { place, availability, time, displayMax, hide, noButtons } = this.props;
    const { showPopup } = this.state;
    const loader = this.getLoader();
    if ((!availability || !availability.fromErpOverview || !availability.fromErpOverview.employees) && loader) {
      return loader;
    }

    let activeUsers = this.checkEmployeeList(place.employees);

    if (!time) {
      return null;
    }
    // how many employees we have
    // Get loader
    const employeeNumber = this.getEmployeeNumber();
    // const modal           = this.getList(employeeNumber);
    const maxDisplay = displayMax || 4;
    const display = isMobile() ? employeeNumber : employeeNumber - maxDisplay <= 3 ? employeeNumber : maxDisplay;
    const displayMoreButton = employeeNumber > display;
    let lastEmployeeId = null;

    let showCheckbox = !isMobile();
    const employees = this.getEmployees(place.employees, lastEmployeeId, display, false, false, showCheckbox);

    if (activeUsers === 1 && !this.props.employee) {
      this.pickEmployee(lastEmployeeId, true);
    }

    const checked = !this.state.employee;
    let employeeList =
      !!availability.fromErpOverview && place.employees.length > 0 ? (
        <div className="flex flex-col justify-center">
          {employees.length !== 1 && (
            <div
              className="w-full cursor-pointer"
              onClick={() => {
                this.pickEmployee(0);
              }}>
              <div className={`${isMobile() ? 'border-black-100 border-b-[1px]' : ''} flex items-center py-3`}>
                {showCheckbox && (
                  <span
                    className={`text-primary border-black-300 mr-3 flex h-6 w-6 flex-none items-center justify-center rounded-full border-[1px] transition-all duration-300 ${
                      checked ? `!border-primary bg-primary` : ''
                    }`}>
                    {checked && <CheckIcon className="h-6 w-6" />}
                  </span>
                )}
                <Avatar alt="avatar" size="64" />
                <div className="my-auto flex w-full flex-row pl-3 pr-2">
                  <div className="flex flex-col">
                    <span>{__('anyEmployee')}</span>
                    <span className="text-black-600 text-sm">{__('availableAllEmployees')}</span>
                  </div>
                </div>
                {!noButtons && <Button variant="secondary">{__('select')}</Button>}
              </div>
            </div>
          )}
          {employees}
          {displayMoreButton && (
            <Button onClick={() => this.setState({ showPopup: true })} variant="secondary" className="mx-auto mt-3">
              {__('moreEmployees') + ' (' + parseInt(employeeNumber - display) + ')'}
            </Button>
          )}
        </div>
      ) : null;

    return (
      <div className="select-employee">
        {loader}
        {showPopup && (
          <ModalDialog
            isOpen={true}
            appElement={document.getElementById('root')}
            onRequestClose={() => this.setState({ showPopup: false })}>
            <ModalContent className="p-4 sm:w-[450px]">
              <div className="mb-4 flex w-full items-center justify-between">
                <h2 className="text-2xl font-semibold">{__('pickEmployee')}</h2>
                <Fab
                  icon={<Icon variant="close" />}
                  onClick={() => this.setState({ showPopup: false })}
                  size="md"
                  variant="link"
                />
              </div>
              {employeeNumber > 7 && (
                <TextInput
                  data={{ visible: true, value: this.state.searchValue, label: __('searchEmployee') }}
                  visible={true}
                  changeInput={this.handleInputChange}
                />
              )}
              {this.getEmployees(place.employees, lastEmployeeId, employeeNumber, this.state.searchValue, true, false)}
            </ModalContent>
          </ModalDialog>
        )}
        <div className="border-black-100 flex items-center justify-between border-b-[1px] pb-4">
          {activeUsers !== 1 && <h3 className="hidden font-semibold sm:block">{__('selectProfessional')}</h3>}
          {activeUsers !== 1 && hide && (
            <Button
              variant="link"
              className="text-primary text-m cursor-pointer font-semibold uppercase"
              onClick={hide}>
              {__('hideEmployees')}
            </Button>
          )}
        </div>
        <div id="staff" className="employee-list">
          {employeeList}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { services, place, availability, time, employee } = state.book;
  const { show, loadSource } = state.loading;
  return {
    place,
    services,
    availability,
    time,
    employee,
    show,
    loadSource,
  };
}

const connectedEmployeePicker = connect(mapStateToProps)(EmployeePicker);
export { connectedEmployeePicker as EmployeePicker };
