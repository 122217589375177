export const serpSeo = [
  {
    keywords: ['frisör', 'frisor'],
    description: 'seo.haircutDescription',
  },
  {
    keywords: ['träning', 'traning'],
    description: 'seo.gymDescription',
  },
  {
    keywords: ['skönhet', 'skonhet'],
    description: 'seo.beautyDescription',
  },
  {
    keywords: ['hudvård', 'hudvard'],
    description: 'seo.facialsDescription',
  },
  {
    keywords: ['massage'],
    description: 'seo.massageDescription',
  },
  {
    keywords: ['naglar'],
    description: 'seo.nailsDescription',
  },
  {
    keywords: ['fillers'],
    description: 'seo.fillersDescription',
  },
  {
    keywords: ['friskvård', 'friskvard'],
    description: 'seo.wellnessDescription',
  },
  {
    keywords: ['fransar'],
    description: 'seo.lashesDescription',
  },
  {
    keywords: ['akupunktur'],
    description: 'seo.acupunctureDescription',
  },
  {
    keywords: ['coaching'],
    description: 'seo.coachingDescription',
  },
  {
    keywords: ['däck', 'dack'],
    description: 'seo.tireDescription',
  },
  {
    keywords: ['floating'],
    description: 'seo.floatingDescription',
  },
  {
    keywords: ['fotvård', 'fotvard'],
    description: 'seo.pedicureDescription',
  },
  {
    keywords: ['hårvård', 'hardvard'],
    description: 'seo.hairCareDescription',
  },
  {
    keywords: ['healing'],
    description: 'seo.healingDescription',
  },
  {
    keywords: ['kiropraktik'],
    description: 'seo.chiropracticDescription',
  },
  {
    keywords: ['kosmetisk tatuering', 'tatuering'],
    description: 'seo.tattooDescription',
  },
  {
    keywords: ['kroppsscanning'],
    description: 'seo.bodyScanDescription',
  },
  {
    keywords: ['laserbehandling'],
    description: 'seo.laserDescription',
  },
  {
    keywords: ['makeup'],
    description: 'seo.makeupDescription',
  },
  {
    keywords: ['nagelvård', 'nagelvard'],
    description: 'seo.nailCareDescription',
  },
  {
    keywords: ['naprapat'],
    description: 'seo.naprapatDescription',
  },
  {
    keywords: ['osteopati'],
    description: 'seo.osteopathyDescription',
  },
  {
    keywords: ['städning', 'stadning'],
    description: 'seo.cleaningDescription',
  },
  {
    keywords: ['tandblekning'],
    description: 'seo.teethWhiteningDescription',
  },
  {
    keywords: ['tandvård', 'tandvard'],
    description: 'seo.dentalDescription',
  },
  {
    keywords: ['utbildningar'],
    description: 'seo.educationDescription',
  },
  {
    keywords: ['veterinärer', 'veterinarer'],
    description: 'seo.vetDescription',
  },
  {
    keywords: ['yoga'],
    description: 'seo.yogaDescription',
  },
  {
    keywords: ['zumba'],
    description: 'seo.zumbaDescription',
  },
];
