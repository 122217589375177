import { filterEmptyValues } from '@/helpers';
import { __ } from '@/locale';
import { useRef, useState } from 'react';
import css from './Greeting.module.scss';

const canvas = undefined;
const getCanvas = () => {
  if (canvas) return canvas;
  return document && document.createElement('canvas');
};
const MAX_TITLE_FONT_SIZE = 32;
const MAX_BODY_FONT_SIZE = 16;
const MAX_TITLE_CHARS = 32;
const MAX_BODY_CHARS = 70;
const CAPPED_TEXT_PADDING_PX = 10;

/**
 * Uses canvas2d to calculate width of given text & font size with Gibson font.
 * @param {string} text
 * @param {number} fontSize Fontsize in px
 * @returns {number} The width of the text in px.
 */
const getTextWidth = (text, fontSize) => {
  const context = getCanvas().getContext('2d');
  context.font = `${fontSize}px Gibson`;
  const metrics = context.measureText(text);
  return metrics.width;
};

/**
 * Finds the largest font size <= maxFontSize which will fit the text within the maxWidth,
 * uses getTextWidth. Could be optimized because if prevText.length <= text.length, maxFontSize should be prevtext.fontSize probably, although "MMMM".length < "llll".length.
 * @param {string} text
 * @param {number} maxFontSize The largest font size allowed.
 * @param {number} maxWidth Bounding box width.
 * @returns {number} 1 <= font size <= maxFontSize where width <= maxWidth
 */
const findFontSize = (text, maxFontSize, maxWidth) => {
  for (let fontSize = maxFontSize; fontSize >= 0; fontSize--) {
    if (getTextWidth(text, fontSize) <= maxWidth) {
      return fontSize;
    }
  }
  return 1;
};

/**
 * Component with inputMirror class, meant to be placed behind input element to measure width, for font size calculations.
 * @typedef {Object} InputMirrorProps
 * @property {string} value
 * @property {string} placeholder
 * @property {boolean} focused
 * @property {boolean} isTitle
 * @property {number} fontSize
 *
 * @param {InputMirrorprops} param0
 * @returns
 */
const InputMirror = ({ value, placeholder, focused, isTitle = false, fontSize }) => {
  return (
    <div
      className={`${css.inputMirror} ${isTitle ? css.titleInput : ''} ${value ? '' : css.placeholder} ${
        focused ? css.focus : ''
      }`}
      style={{ fontSize }}>
      {value ? value : placeholder}
    </div>
  );
};

/**
 * Component with an InputMirror and matching input element.
 * @typedef {Object} InputMirrorPairProps
 * @property {string} fieldName
 * @property {string} value
 * @property {string} placeholder
 * @property {number} fontSize
 * @property {boolean} focused
 * @property {Object} inputRef
 * @property {Function} onChange
 * @property {Function} setFocused
 * @property {boolean} isTitle
 *
 * @param {InputMirrorPairProps} param0
 * @returns
 */
const InputMirrorPair = ({
  fieldName,
  value,
  placeholder,
  fontSize = 16,
  focused,
  inputRef,
  onChange,
  setFocused,
  isTitle,
}) => {
  return (
    <>
      <InputMirror
        value={value}
        placeholder={placeholder}
        focused={focused === fieldName}
        isTitle={isTitle}
        fontSize={fontSize}
      />
      <input
        type="text"
        style={{ fontSize }}
        className={`${isTitle ? css.titleInput : ''} ${focused === fieldName ? css.focus : ''}`}
        placeholder={placeholder}
        value={value}
        ref={inputRef}
        onChange={onChange}
        onFocus={() => setFocused(fieldName)}
        onBlur={() => setFocused(null)}
      />
    </>
  );
};

/**
 * @param {number} maxChars
 * @param {Function} setValue
 * @returns {Function} Fn that calls setValue on sliced value to maxChars length.
 */
const setSlicedValue = (maxChars, setValue) => (value) => setValue(value.slice(0, maxChars));

/**
 * Greeting Card component. Has three editable fields with multiple states.
 *
 * @typedef {Object} GreetingCardProps
 * @property {import('@/services/giftCardServices').Greeting} values
 * @property {Function} setValues
 *
 * @param {GreetingCardProps} param0
 * @returns
 */
const GreetingCard = ({ values: { title = '', giver = '', message = '' }, setValues }) => {
  // Sets a single value via setValues
  const setVal = (name) => (val) => setValues(filterEmptyValues({ title, giver, message, [name]: val }));
  const setTitle = setVal('title');
  const setMessage = setVal('message');
  const setGiver = setVal('giver');

  // Currently focused input. Will be fieldName | null.
  const [focused, setFocused] = useState(null);

  // Font sizes in pixels of the different
  const [titleFontSize, setTitleFontSize] = useState(MAX_TITLE_FONT_SIZE);
  const [messageFontSize, setMessageFontSize] = useState(MAX_BODY_FONT_SIZE);
  const [giverFontSize, setGiverFontSize] = useState(MAX_BODY_FONT_SIZE);

  const cardRef = useRef(null);
  const titleRef = useRef(null);
  const messageRef = useRef(null);
  const giverRef = useRef(null);

  const setSlicedTitle = setSlicedValue(MAX_TITLE_CHARS, setTitle);
  const setSlicedMessage = setSlicedValue(MAX_BODY_CHARS, setMessage);
  const setSlicedGiver = setSlicedValue(MAX_BODY_CHARS, setGiver);

  const onInputChange = (ref, setValue, setFont, maxFont) => () => {
    if (!cardRef.current.clientWidth) return;
    setValue(ref.current?.value);
    setFont(findFontSize(ref.current?.value, maxFont, cardRef.current.clientWidth - CAPPED_TEXT_PADDING_PX));
  };

  return (
    <div className={css.card} ref={cardRef}>
      <div className={`${css.inputContainer} mb-6`}>
        <InputMirrorPair
          fieldName="title"
          value={title}
          placeholder={__('buyGiftcard.greetingTitlePlaceholder')}
          focused={focused}
          fontSize={titleFontSize}
          inputRef={titleRef}
          onChange={onInputChange(titleRef, setSlicedTitle, setTitleFontSize, MAX_TITLE_FONT_SIZE)}
          setFocused={setFocused}
          isTitle
        />
      </div>
      <div className={`${css.inputContainer} mb-4`}>
        <InputMirrorPair
          fieldName="message"
          value={message}
          placeholder={__('buyGiftcard.greetingMessagePlaceholder')}
          focused={focused}
          fontSize={messageFontSize}
          inputRef={messageRef}
          onChange={onInputChange(messageRef, setSlicedMessage, setMessageFontSize, MAX_BODY_FONT_SIZE)}
          setFocused={setFocused}
        />
      </div>
      <div className={css.inputContainer}>
        <InputMirrorPair
          fieldName="giver"
          value={giver}
          placeholder={__('buyGiftcard.greetingGiverPlaceholder')}
          focused={focused}
          fontSize={giverFontSize}
          inputRef={giverRef}
          onChange={onInputChange(giverRef, setSlicedGiver, setGiverFontSize, MAX_BODY_FONT_SIZE)}
          setFocused={setFocused}
        />
      </div>
    </div>
  );
};

export default GreetingCard;
