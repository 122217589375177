import { classnames } from '@/helpers';

type TableListItem = {
  text: string;
  className?: string;
};

type TableListItemContentProps = {
  title?: TableListItem;
  subTitle?: TableListItem;
  value?: TableListItem;
  subValue?: TableListItem;
};
const TableListItemContent = ({ title, subTitle, value, subValue }: TableListItemContentProps) => {
  return (
    <div className="space-x-lg flex w-full items-start justify-between">
      <div className="space-y-xxs flex flex-col">
        {title?.text && <p className={classnames(title?.className ?? 'text-md text-black-600')}>{title.text}</p>}
        {subTitle?.text && (
          <p className={classnames(subTitle?.className ?? 'text-black-600 text-sm')}>{subTitle.text}</p>
        )}
      </div>
      <div className="space-y-xxs flex flex-col">
        {value?.text && (
          <p className={classnames('text-right', value?.className ?? 'text-md text-black-900')}>{value.text}</p>
        )}
        {subValue?.text && (
          <p className={classnames('text-right', subValue?.className ?? 'text-black-900 text-sm')}>{subValue.text}</p>
        )}
      </div>
    </div>
  );
};

export default TableListItemContent;
