import { ListItem } from '@/components/elements/lists';
import { CloseIcon, LoadingIcon, PlusAltIcon, WarningIcon } from '@/components/icons';
import Icon from '@/components/icons/Icon';
import PageViewLayout from '@/components/layouts/PageViewLayout/PageViewLayout';
import { ReviewUserCard } from '@/components/modules/review';
import { supportServices } from '@/services';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';

type ReviewUserItem = {
  id: string;
  name: string;
  email: string;
  isAdmin: number;
};

const SupportReviewsUsers = () => {
  const [formEmail, setFormEmail] = useState('');
  const [searchEmail, setSearchEmail] = useState('');
  const [user, setFormUser] = useState<ReviewUserItem>();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    supportServices
      .getSupportUsers('')
      .then((user) => {
        if (user) {
          setFormUser(user);
          setError('');
        }
      })
      .catch((e) => {
        setError(e);
      });
  }, []);

  const handleInputKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();

      setSearchEmail(formEmail);

      supportServices
        .getSupportUsers(formEmail)
        .then((user) => {
          if (user) {
            setFormUser(user);
            setError('');
          }
        })
        .catch((e) => {
          setError(e);
        });
    }
  };

  const handleResetEmail = (e) => {
    e.preventDefault();
    setFormEmail('');
    setSearchEmail('');
    setFormUser(null);
  };

  const toggleAdminStatus = (user) => {
    if (user.isAdmin === 2) {
      // Do nothing if isAdmin === 2 (Manager)
      return;
    }

    const data = {
      id: user.id,
      isAdmin: user.isAdmin === 1 ? 0 : 1,
    };

    setLoading(true);

    supportServices
      .setSupportUserRole(data)
      .then((user) => {
        if (user) {
          setFormUser(user);
          setLoading(false);
          setError('');
        }
      })
      .catch((e) => {
        setError(e);
      });
  };

  return (
    <PageViewLayout type="mainView" title={'Reviews'}>
      <div className="container">
        <div className={`gap-xxs flex w-full flex-col p-5`}>
          <form>
            <label htmlFor="search-input" className="text-md text-black-900">
              Search users admin reviews
            </label>
            <div className={`border-black-200 gap-sm px-lg py-md relative mx-3 flex rounded-full border bg-white`}>
              <Icon variant="search" size="sm" />
              <input
                id="search-input"
                placeholder="enter user email"
                className="w-full"
                value={formEmail}
                onChange={(e) => setFormEmail(e.target.value)}
                onKeyDown={handleInputKeyDown}
              />

              {!!formEmail && (
                <button onClick={handleResetEmail} className="min-h-[24px] min-w-[24px] outline-none">
                  <Icon size="sm" variant="close-circle" />
                </button>
              )}
            </div>
            <ul>
              <li>
                <strong>Legend: </strong>
              </li>
              <li>
                <strong>By email:</strong> test@bokadirekt.se
              </li>
            </ul>
          </form>
        </div>
        {!!error && (
          <div>
            <ListItem>
              <div className="bg-black-50 block w-full rounded-lg p-6">
                <div className="flex flex-row">
                  <WarningIcon className="relative right-3 z-10 h-5 w-5" />
                  <span>{error}</span>
                </div>
              </div>
            </ListItem>
          </div>
        )}
        {!!user && !!searchEmail && (
          <div>
            <ListItem
              key={user.id}
              className="relative"
              rightSlot={
                !!user.email &&
                user.isAdmin !== 2 && (
                  <button
                    className="!outline-primary-500 absolute right-[50px] top-[30px] flex flex-row overflow-hidden rounded-full p-2 duration-200 group-focus-within:block group-hover:block"
                    onClick={() => toggleAdminStatus(user)}>
                    <span className="p-1 font-bold">
                      {user.isAdmin === 1 ? 'Revoke Admin Role' : 'Promote to Admin'}
                    </span>
                    <div className="!outline-primary-500 h-8 w-8 overflow-hidden rounded-full p-2 duration-200 group-focus-within:block group-hover:block">
                      {loading && <LoadingIcon className="relative z-10 h-full w-full text-white" />}
                      {user.isAdmin === 1 ? (
                        <CloseIcon className={`relative z-10 h-full w-full text-white ${loading ? 'invisible' : ''}`} />
                      ) : (
                        <PlusAltIcon
                          className={`relative z-10 h-full w-full text-white ${loading ? 'invisible' : ''}`}
                        />
                      )}
                      <div className="bg-black-900 absolute inset-0 h-full w-full opacity-60"></div>
                    </div>
                  </button>
                )
              }>
              <ReviewUserCard
                user={{
                  name: user.name + ' - ' + user.id,
                  email: user.email,
                  isAdmin: user.isAdmin,
                }}
                key={user.id}
                searchEmail={searchEmail}
              />
            </ListItem>
          </div>
        )}
      </div>
    </PageViewLayout>
  );
};

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps)(SupportReviewsUsers);
