import Icon from '@/components/icons/Icon';
import { APP_ROUTES } from '@/constants/pages';
import { _s } from '@/locale';
import { Link } from 'react-router-dom';

const baseTranslationKey = 'pages.bransch-organisationer.shared.Breadcrumbs';

const Breadcrumbs = ({ title }: { title: string }) => {
  return (
    <div className="flex flex-wrap items-center">
      <Link to={'/'} className="text-information-700">
        {_s('home')}
      </Link>
      <Icon variant="chevron-right" color="information-700" />
      <Link to={APP_ROUTES.ASSOCIATION_PAGES} className="text-information-700">
        {_s(`${baseTranslationKey}.associationArchive`)}
      </Link>
      <Icon variant="chevron-right" color="information-700" />
      <span className="text-black-900">{title}</span>
    </div>
  );
};

export default Breadcrumbs;
