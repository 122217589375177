import Icon from '@/components/icons';

const TextInput = ({ value, onChange, onBlur, error = null, icon = null, placeholder, className = '' }) => {
  return (
    <div className={`${className} relative`}>
      <div className="relative">
        {icon && <Icon icon={icon} className="text-black-600 absolute left-3 top-1/2 h-4 w-4 -translate-y-1/2" />}
        <input
          type="text"
          className={`border-black-300 h-12 w-full rounded-lg border ${icon ? 'pl-10' : 'pl-4'}`}
          value={value}
          onChange={(event) => onChange(event.target.value)}
          onBlur={(event) => onBlur(event.target.value)}
          placeholder={placeholder}
        />
      </div>
      {error && (
        <div className="text-danger flex items-center justify-start text-sm">
          <div className="bg-danger mr-2 h-1 w-1 rounded-full"></div>
          {error}
        </div>
      )}
    </div>
  );
};

export default TextInput;
