import { __ } from '@/locale';

function Actionsheet(props) {
  const { show, hide, menus } = props;

  return (
    <div className={`actionsheet${show ? ' actionsheet-show' : ''}`} onClick={hide()}>
      <div className="actionsheet-mask"></div>
      <div className="actionsheet-wrap">
        <div className="actionsheet-menu">
          {menus.map((menu, i) => {
            const { text, action } = menu;
            return (
              <span key={i} className="actionsheet-menu-item" onClick={action}>
                {text}
              </span>
            );
          })}
        </div>
        <div className="actionsheet-action">
          <div className="actionsheet-action-item" onClick={hide()}>
            {__('actionsheetCancel')}
          </div>
        </div>
      </div>
    </div>
  );
}
export default Actionsheet;
