import { trackMpEvent } from '@/helpers';
import { ScreenViewEvent } from '@/types/analytics';
import { useEffect } from 'react';

export function trackScreenView(event: ScreenViewEvent) {
  trackMpEvent(event.name, event.properties);
}

const useTrackScreenView = (event: ScreenViewEvent, deps: unknown[] = [], conditions: boolean[] = []) => {
  useEffect(() => {
    conditions.every((condition) => condition) && trackScreenView(event);
  }, deps);
};

export default useTrackScreenView;
