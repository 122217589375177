import Icon from '@/components/icons/Icon';
import { classnames, roundRating } from '@/helpers';
import { themed } from '@/helpers/theme';
import { __ } from '@/locale';

const getStars = (rating: number) => {
  let empty = false;
  return [1, 2, 3, 4, 5].map((i, key) => {
    let fill = 100;
    if (i > rating) {
      fill = !empty ? (rating % 1) * 100 : 0;
      empty = true;
    }
    return (
      <div key={key} className="relative w-4">
        <Icon variant="star-filled" color="black-200" style={{ width: 20, height: 20 }} />
        <Icon
          className="absolute inset-0"
          variant="star-filled"
          color="warning-500"
          style={{ width: 20, height: 20, clipPath: `inset(0 ${100 - fill}% 0 0)` }}
        />
      </div>
    );
  });
};

type Props = {
  rating: number;
  count?: number;
  showRatings?: boolean;
  link?: boolean;
  showScore?: boolean;
  size: 'sm' | 'md' | 'lg';
};

const Rating = ({ rating, count, showRatings, showScore = true, size, link = false }: Props) => {
  const ratingVisible = showRatings ?? size !== 'sm';

  return (
    <div className="inline-flex flex-row flex-wrap items-center justify-start">
      <div className="gap-xxs flex items-center">
        <div className="flex select-none items-center">
          {showScore && <span className="text-black-900 pr-1 text-sm">{roundRating(rating, 1)}</span>}
          {size === 'lg' ? (
            getStars(rating)
          ) : (
            <Icon variant="star-filled" color="warning-500" style={{ width: 20, height: 20 }} />
          )}
        </div>

        {count && ratingVisible && (
          <span
            className={classnames(
              /** dot styles */
              'before:w-xxs before:h-xxs before:mr-xs before:bg-black-600 before:inline-block before:rounded-full before:content-[""]',
              /** rest */
              `text-xxs flex items-center ${
                link ? `${themed('text-black-900 underline', '!text-black-800 underline')}` : 'text-black-600'
              }`,
            )}>
            {` ${count} ${__('Ratings')}`}
          </span>
        )}
      </div>
    </div>
  );
};

export default Rating;
