// ==============================
// THEME
// ==============================

const theme = {};

// container
theme.container = {
  background: 'rgba(0, 0, 0, 0.8)',
  zIndex: 10051,
};

theme.containerGutter = {
  horizontal: 0,
  vertical: 0,
};

// header
theme.header = {
  height: 56,
};

theme.headerDesktop = {
  height: 64,
};

theme.close = {
  fill: 'white',
};

// footer
theme.footer = {
  color: 'white',
  height: 28,
};

theme.footerCount = {
  color: 'rgba(255, 255, 255, 0.75)',
  fontSize: '0.85em',
};

theme.footerGutter = {
  horizontal: 5,
  vertical: 5,
};

// thumbnails
theme.thumbnails = {
  height: 60,
};
theme.thumbnail = {
  activeBorderColor: 'white',
  size: 50,
  gutter: 2,
};

// arrow
theme.arrow = {
  // background: 'none',
  fill: 'white',
  height: 40,
};

theme.arrowDesktop = {
  height: 100,
};

export default theme;
