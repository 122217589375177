import { useEffect, useRef } from 'react';

const useWindowOnUnload = ({ fn, callOnCleanup }: { fn: () => void; callOnCleanup?: boolean }) => {
  const callback = useRef(fn);

  useEffect(() => {
    const handler = () => callback.current();

    window.addEventListener('beforeunload', handler);

    return () => {
      if (callOnCleanup) handler();

      window.removeEventListener('beforeunload', handler);
    };
  }, [callOnCleanup]);
};

export default useWindowOnUnload;
