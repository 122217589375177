import { LoadingIcon } from '@/components/icons';
import Icon from '@/components/icons/Icon';
import { IconVariant } from '@/components/icons/types';
import { twBorderPrimary } from '@/helpers/theme';

type FakeSearchInputProps = {
  onClick: () => void;
  valid: boolean;
  content: string;
  icon: IconVariant;
  className?: string;
  onClear: () => void;
  size?: 'sm' | 'md';
  isLoading: boolean;
};

const FakeSearchInput = ({
  onClick,
  valid,
  content,
  icon,
  className = '',
  onClear,
  size = 'sm',
  isLoading = false,
}: FakeSearchInputProps) => (
  <div
    className={`flex rounded-full border bg-white ${
      valid ? twBorderPrimary() : 'border-black-600'
    } relative my-3 h-11 ${className}`}>
    <div className="pl-md py-sm pr-sm flex h-full items-center justify-center">
      {isLoading && <LoadingIcon className="h-xl w-xl" />}
      {!isLoading && <Icon variant={icon} size="sm" />}
    </div>
    <div
      className={`h-full flex-grow cursor-pointer whitespace-nowrap ${
        valid ? 'text-black-900 max-w-full overflow-hidden text-ellipsis' : 'text-black-600'
      } ${size === 'sm' ? 'leading-[44px]' : 'leading-[52px]'} pr-2`}
      onClick={onClick}>
      {content}
    </div>
    {valid && (
      <button
        className="!mr-2 flex h-full w-8 flex-shrink-0 flex-grow-0 items-center justify-center outline-none"
        onClick={onClear}>
        <Icon variant="close-circle" />
      </button>
    )}
  </div>
);

export default FakeSearchInput;
