import Header from '@/components/elements/Header/Header';
import { NpsContextType } from '@/hooks/useNps';
import { _s } from '@/locale';

const translationKey = `npsModal.step1`;

type NpsLikelihoodToRecommendProps = Pick<NpsContextType, 'nps' | 'handleScoreSubmit'> & {};

const NpsLikelihoodToRecommend = ({ nps, handleScoreSubmit }: NpsLikelihoodToRecommendProps) => {
  const handleScoreClick = (score) => () => {
    handleScoreSubmit(score);
  };

  return (
    <>
      <Header className="pt-xl sm:pt-lg" size="xl" label={_s(`${translationKey}.subtitle`)} />
      <Header size="md" className="text-black-600 font-normal" label={_s(`${translationKey}.question`)} />
      <div className="gap-sm flex flex-col pt-[90px] sm:pt-[35px]">
        <ul className="border-black-200 text-md flex items-center justify-center rounded-md border-[1px]">
          {new Array(11).fill(undefined).map((item, i) => (
            <li
              key={i}
              onClick={handleScoreClick(i)}
              className={`${
                nps.score === i
                  ? 'bg-black-900 text-white'
                  : 'hover:bg-black-50 hover:text-black-900 text-black-900 bg-white'
              } focus:bg-black-900 border-black-200 p-sm flex-1 flex-grow cursor-pointer border-r-[1px] text-center first:rounded-bl-md first:rounded-tl-md last:rounded-br-md last:rounded-tr-md last:border-none focus:text-white `}>
              {i}
            </li>
          ))}
        </ul>
        <div className="mb-xxl text-black-600 text-xxs flex justify-between">
          <span>{_s(`${translationKey}.notLikely`)}</span>
          <span>{_s(`${translationKey}.likely`)}</span>
        </div>
      </div>
    </>
  );
};

export default NpsLikelihoodToRecommend;
