import { server } from '@/helpers';
import { NpsScore } from '@/hooks/useNps';

export const npsServices = {
  npsCompleted,
  save,
};

function save({
  comment,
  code,
  likelihoodToRecommend,
}: {
  likelihoodToRecommend: NpsScore;
  comment?: string;
  code?: string;
}) {
  return server.request.post('/nps/', { likelihoodToRecommend, comment, code }).then((response) => response.data);
}

function npsCompleted({ code }: { code: string }) {
  return server.request.post('/nps-completed/', { code }).then((response) => response.data);
}
