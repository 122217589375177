import Header from '@/components/elements/Header/Header';
import { Card } from '@/components/elements/cards';
import { Button } from '@/components/elements/forms/buttons';
import ListInput from '@/components/elements/forms/listInput/ListInput/ListInput';
import Snackbar from '@/components/elements/notifications/Snackbar/Snackbar';
import { InitialsAvatar } from '@/components/elements/redesign/Avatar';
import PageViewLayout from '@/components/layouts/PageViewLayout/PageViewLayout';
import UpdateUserInfo from '@/components/modules/UpdateUserInfo/UpdateUserInfo';
import useUpdateUserInfo, {
  UseUpdateUserInfoCallbackProps,
} from '@/components/modules/UpdateUserInfo/UpdateUserInfo.hooks';
import EmptyState from '@/components/templates/EmptyState';
import { useAppSelector } from '@/hooks';
import useMobileView from '@/hooks/useMobileView';
import useTrackScreenView from '@/hooks/useTrackScreenView';
import { _s } from '@/locale';
import { handleLoginClick } from '@/services/navigationServices';
import { NavItemIdentifier } from '@/types/navigation';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

const baseTranslationKey = `pages.my-profile`;

const LoggedOutView = () => {
  const dispatch = useDispatch();
  return (
    <EmptyState
      title={_s(`${baseTranslationKey}.emptyState.title`)}
      body={_s(`${baseTranslationKey}.emptyState.body`)}
      cta={
        <Button
          label={_s(`${baseTranslationKey}.emptyState.cta`)}
          size="sm"
          onClick={() => handleLoginClick(dispatch, NavItemIdentifier.LogIn, 'my_profile')}
        />
      }
      icon={<img src="/images/illustrations/profile2.svg" alt="" />}
      className="max-w-[40ch]"
    />
  );
};

function displayToast({ success, retry }: UseUpdateUserInfoCallbackProps) {
  toast(
    ({ closeToast }) => (
      <Snackbar
        label={_s(`${baseTranslationKey}.snackbar.label.${success ? 'success' : 'error'}`)}
        type={success ? 'success' : 'danger'}
        action={
          <button
            onClick={
              success
                ? closeToast
                : () => {
                    closeToast();
                    retry();
                  }
            }>
            {_s(`${baseTranslationKey}.snackbar.action.${success ? 'success' : 'error'}`)}
          </button>
        }
      />
    ),
    { autoClose: 3000 },
  );
}

const LoggedInView = () => {
  const { isMobileView } = useMobileView();
  const updateUserInfoProps = useUpdateUserInfo({ callback: displayToast, triggerSource: 'my_profile' });
  const { user } = useAppSelector((state) => state.users);

  const renderContent = () => {
    if (isMobileView) {
      return (
        <div className="pb-xxl">
          <div className="p-lg">
            <Header label={_s('Email')} size="lg" />
          </div>
          <ListInput id="email" disabled value={user.contact.email} />
          <UpdateUserInfo
            {...updateUserInfoProps}
            givenName
            familyName
            gender
            dob
            streetAddress
            postalCode
            locality
            mobile
          />
        </div>
      );
    }

    const userInitials = getUserInitials(user?.about?.givenName || '', user?.about?.familyName || '');
    const name = `${user.about?.givenName ?? ''} ${user.about?.familyName ?? ''}`;

    return (
      <div className="py-4xl gap-xl lg:gap-4xl container flex w-full grid-cols-[1fr_2fr] flex-col lg:grid lg:items-start">
        <Card className="!my-0 bg-white">
          <div className="space-x-md flex items-center">
            <InitialsAvatar initials={userInitials} size="xl" variant="default" />
            <div className="mt-sm flex justify-center">
              <h2 className="text-lg font-bold">{name}</h2>
            </div>
          </div>
        </Card>
        <Card className="!m-0 bg-white">
          <div className="p-lg">
            <Header label={_s('Email')} size="lg" />
          </div>
          <ListInput id="email" disabled value={user.contact.email} />
          <UpdateUserInfo
            {...updateUserInfoProps}
            givenName
            familyName
            gender
            dob
            streetAddress
            postalCode
            locality
            mobile
          />
        </Card>
      </div>
    );
  };

  return <div>{renderContent()}</div>;
};
const views = {
  loggedOut: LoggedOutView,
  loggedIn: LoggedInView,
};

const MyProfile = () => {
  const { user } = useAppSelector((state) => state.users);

  useTrackScreenView({ name: 'screen_view_my_profile' });

  const CurrentView = views[user ? 'loggedIn' : 'loggedOut'];

  return (
    <PageViewLayout wrapperClass="bg-gradient" type="subView" back title={_s(`${baseTranslationKey}.title`)}>
      <CurrentView />
    </PageViewLayout>
  );
};

const getUserInitials = (firstname: string, lastname: string) => {
  return firstname && lastname ? firstname.charAt(0) + lastname.charAt(0) : '';
};

export default MyProfile;
