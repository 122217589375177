import Icon from '@/components/icons/Icon';
import PageViewLayout from '@/components/layouts/PageViewLayout/PageViewLayout';
import { EVENT_NAME } from '@/constants/analytics';
import { trackMpEvent } from '@/helpers';
import { useAppSelector } from '@/hooks';
import useMobileView from '@/hooks/useMobileView';
import useTrackScreenView from '@/hooks/useTrackScreenView';
import { Widget } from '@typeform/embed-react';
import * as moment from 'moment';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import NotFound from '../404';

export const DEALS_FEATURE_FLAG = 'deals_smoke_test';

function calculateAge(dobString: string): string | null {
  const birthDate = moment(dobString, moment.ISO_8601, true);

  if (!birthDate.isValid()) {
    return null;
  }

  const today = moment();
  const age = today.diff(birthDate, 'years');

  return age.toString();
}

const handleStarted = () => {
  trackMpEvent(EVENT_NAME.TYPEFORM_FIRST_STEP_CLICK, { typeform_project: 'deals' });
};

const Deals = () => {
  const { isMobileView } = useMobileView();
  const history = useHistory();
  const user = useAppSelector((state) => state.users.user);
  const { about, contact } = user || {};
  const { gender, dob } = about || {};
  const { locality } = contact || {};
  const age = calculateAge(dob) || undefined;
  const flags = useAppSelector((state) => state.flags);
  const dealsSurveyId = flags[DEALS_FEATURE_FLAG]?.payload?.['code'];

  useTrackScreenView({ name: 'typeform_screen_view', properties: { typeform_project: 'deals' } });

  const handleSubmit = useCallback(() => {
    trackMpEvent(EVENT_NAME.TYPEFORM_SUBMIT_CLICK, { typeform_project: 'deals' });
    if (isMobileView) {
      setTimeout(() => {
        history.push('/');
      }, 5000);
    }
  }, [history, isMobileView]);

  const handleClose = useCallback(() => {
    history.push('/');
  }, [history]);

  return dealsSurveyId ? (
    <>
      {!isMobileView && (
        <PageViewLayout type="mainView" wrapperClass="bg-gradient" footerDisplayOption={{ from: 'md', to: 'all' }}>
          <Widget
            onSubmit={handleSubmit}
            onStarted={handleStarted}
            hidden={{ gender, city: locality, age }}
            id={dealsSurveyId}
            inlineOnMobile={true}
            disableScroll={true}
            style={{ width: '100%', height: `calc(100vh - 64px)` }}
            className="my-form"
          />
        </PageViewLayout>
      )}
      {isMobileView && (
        <>
          <div className="z-10002 fixed right-2 top-2 cursor-pointer" onClick={handleClose}>
            <Icon variant="close" />
          </div>
          <Widget
            onSubmit={handleSubmit}
            onStarted={handleStarted}
            hidden={{ gender, city: locality, age }}
            id={dealsSurveyId}
            fullScreen={true}
            inlineOnMobile={true}
            disableScroll={true}
            className="my-form"
          />
        </>
      )}
    </>
  ) : (
    <NotFound />
  );
};

export default Deals;
