import { ReactNode, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { CloseIcon, InformationIcon, SuccessIcon, WarningIcon } from '../icons';
import styles from './StatusMessage.module.scss';

export type StatusType = 'default' | 'information' | 'success' | 'warning' | 'error';

export interface IStatusMessage {
  type?: StatusType;
  icon?: boolean;
  message: string;
  title?: string;
  link?: {
    to: string;
    label: string;
  };
  children?: ReactNode;
}

export const StatusMessage: React.FC<IStatusMessage> = ({ type = 'default', link, message, title, icon, children }) => {
  const StatusMessageClasses = useMemo(() => [styles.statusMessage, styles[type]].join(' '), [type]);

  return (
    <div className={StatusMessageClasses}>
      <span className="m-0 flex items-center gap-3">
        {icon && (
          <>
            {type === 'information' && (
              <span className="bg-information flex h-8 w-8 items-center justify-center rounded-full p-2">
                <InformationIcon className="h-full w-full text-white" />
              </span>
            )}
            {type === 'success' && (
              <span className="bg-success flex h-8 w-8 items-center justify-center rounded-full p-2">
                <SuccessIcon className="h-full w-full text-white" />
              </span>
            )}
            {type === 'warning' && (
              <span className="bg-warning flex h-8 w-8 items-center justify-center rounded-full p-2">
                <WarningIcon className="text-black-900 h-full w-full" />
              </span>
            )}
            {type === 'error' && (
              <span className="bg-danger flex h-8 w-8 items-center justify-center rounded-full p-2">
                <CloseIcon className="h-full w-full text-white" />
              </span>
            )}
          </>
        )}
        <span className="flex-1">
          {title && <h3 className="font-semibold">{title}</h3>}
          {message}{' '}
          {link && (
            <Link className="text-black-900 font-semibold underline" to={link.to}>
              {link.label}
            </Link>
          )}
        </span>
      </span>
      {children}
    </div>
  );
};
