import { PaymentMethod } from '@/constants/paymentMethodConstants';
import * as Sentry from '@sentry/react';
import * as moment from 'moment';
import { z } from 'zod';
import { CARD_BRAND_MAP, ONLINE_PAYMENT_STATUS, PAYMENT_CARD_BRAND } from '../constants/onlinePaymentConstants';
import { __ } from '../locale';
import {
  EmployeeOnlinePaymentRequired,
  PlaceOnlinePaymentRequired,
  ServicesOnlinePaymentRequired,
  isOnlinePaymentRequired,
} from './employee';

export const getCardName = (brand: string): string => {
  const cardBrandName = CARD_BRAND_MAP[brand] ? PAYMENT_CARD_BRAND[brand] : PAYMENT_CARD_BRAND.unknown;
  return cardBrandName.title;
};

export const hasCardExpired = (month: string, year: string): boolean => {
  const now = moment();
  const expiry = moment().month(month).year(+year);
  return expiry.isBefore(now);
};

export const isCardAboutToExpire = (month: string, year: string, timestamp: string): boolean => {
  const now = moment();
  const expiry = moment().month(month).year(+year);
  const bookingTreatmentTime = moment(timestamp);
  return expiry.isAfter(now) && expiry.isBefore(bookingTreatmentTime);
};

export const getOnlinePaymentStatus = (
  status: keyof typeof ONLINE_PAYMENT_STATUS,
  method: typeof PaymentMethod.GOOGLE_PAY | typeof PaymentMethod.STORED_ONLINE_CARD | typeof PaymentMethod.APPLE_PAY,
): string => {
  switch (status) {
    case ONLINE_PAYMENT_STATUS.CAPTURE:
      return __('payWithSavedCard.captured');
    case ONLINE_PAYMENT_STATUS.REFUND:
      return __('payWithSavedCard.refunded');
    case ONLINE_PAYMENT_STATUS.CANCEL_OR_REFUND:
    case ONLINE_PAYMENT_STATUS.CANCELLATION:
      return __('payWithSavedCard.cancelled');
    case ONLINE_PAYMENT_STATUS.CAPTURE_FAILED:
      return __('payWithSavedCard.captureFailed');
    case ONLINE_PAYMENT_STATUS.REFUND_FAILED:
      return __('payWithSavedCard.refundFailed');
    default:
      break;
  }

  switch (method) {
    case PaymentMethod.GOOGLE_PAY:
      return __('payWithSavedCard.GOOGLE_PAY');
    case PaymentMethod.APPLE_PAY:
      return __('payWithSavedCard.APPLE_PAY');
    case PaymentMethod.STORED_ONLINE_CARD:
      return __('payWithSavedCard.STORED_ONLINE_CARD');
    default:
      Sentry.captureMessage('Missing translation for online payment');
  }
};

export const getIsOnlinePaymentRequired = (
  employee: EmployeeOnlinePaymentRequired,
  place: PlaceOnlinePaymentRequired,
  services: ServicesOnlinePaymentRequired[],
) => {
  const mpClientPlaceBookingsSchema = z.union([z.undefined(), z.array(z.number())]);
  const validateLocalstorageMpPlaceBookings = mpClientPlaceBookingsSchema.safeParse(
    JSON.parse(localStorage.getItem('mpClientPlaceBookings') || '[]'),
  );

  const clientPlaceBookings = validateLocalstorageMpPlaceBookings.success
    ? validateLocalstorageMpPlaceBookings.data
    : [];

  return isOnlinePaymentRequired(employee, place, services || [], clientPlaceBookings);
};
