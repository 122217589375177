import { ListItem, ListItemContent } from '@/components/elements/lists';
import Icon from '@/components/icons/Icon';
import { getSelectedPaymentDetails } from '@/helpers/checkout';
import { _s } from '@/locale';
import { SelectedPaymentMethod as TSelectPaymentMethod } from '@/types/checkout';

type SelectedPaymentMethodProps = {
  method: TSelectPaymentMethod & { label?: string };
};

const baseTranslationKey = 'components.modules.checkout.SelectedPaymentMethod';

const SelectedPaymentMethod = ({ method }: SelectedPaymentMethodProps) => {
  const { icon, label } = getSelectedPaymentDetails(method);
  return (
    <ListItem
      data-cy={`selectedPaymentMethod-${method.type}`}
      leftSlot={<Icon variant={icon} style={{ width: 48, height: 48 }} noFilter />}>
      <ListItemContent title={_s(`${baseTranslationKey}.title`)} subTitle={method.label ?? label} />
    </ListItem>
  );
};

export default SelectedPaymentMethod;
