import Icon from '@/components/icons/Icon';
import { classnames, roundRating } from '@/helpers';
import { __ } from '@/locale';
import styles from './Stats.module.scss';

const getStars = (rating, ratingFilter) => {
  let empty = false;
  return [1, 2, 3, 4, 5].map((i, key) => {
    let fill = 100;
    if (i > rating) {
      fill = !empty ? (rating % 1) * 100 : 0;
      empty = true;
    }
    return (
      <div key={key} className="relative">
        <Icon variant="star-filled" color="black-50" style={{ width: 24, height: 24 }} />
        <Icon
          className="absolute inset-0"
          variant="star-filled"
          color={ratingFilter === 0 || ratingFilter === rating ? 'warning-500' : 'black-200'}
          style={{ width: 24, height: 24, clipPath: `inset(0 ${100 - fill}% 0 0)` }}
        />
      </div>
    );
  });
};

function getTotalCount(counts) {
  return Object.values(counts).reduce((total, value) => total + value, 0);
}

function Stats(props) {
  const {
    stats: { score, count },
    ratingCounts,
    reviewCounts,
    ratingFilter = 0,
    onRatingClick,
    showScore = true,
  } = props;
  if (!score || !count || !ratingCounts || count < 5) {
    return null;
  }

  const getStyle = (rating) => {
    if (ratingFilter === 0 || ratingFilter === rating) {
      return classnames(styles.active);
    } else {
      return classnames(styles.disabled);
    }
  };

  const handleRatingClick = (rating) => {
    onRatingClick(rating);
  };

  const spread = Object.keys(ratingCounts)
    .sort((a, b) => b - a)
    .map((key, i) => (
      <div
        key={i}
        className={classnames('flex cursor-pointer items-center justify-end rounded-sm border-4 p-2', getStyle(key))}
        onClick={() => handleRatingClick(key)}>
        <span className="pr-3">{key}</span>
        <div className="flex w-[70px] md:w-[120px]">{getStars(key, ratingFilter)}</div>
        <div className={classnames(styles.pgbar, 'mx-3 h-[8px] flex-1 overflow-hidden rounded-sm md:min-w-[200px]')}>
          <div
            className={classnames(styles.pgbarval, 'h-[8px] rounded-l-sm')}
            role="progressbar"
            style={{ width: (ratingCounts[key] / count) * 100 + '%' }}
            aria-valuemin="0"
            aria-valuemax="100"></div>
        </div>
        <span className="text-link text-m w-[30px] text-right underline-offset-4 md:w-[50px]">
          ({ratingCounts[key] < 1000 ? ratingCounts[key] : '+999'})
        </span>
      </div>
    ));
  return (
    <div className="gap-3 py-6">
      {showScore && (
        <div className="flex pb-3">
          <Icon variant="star-filled" color="warning-500" style={{ width: 72, height: 72 }} />
          <div className="pl-2">
            <span className="text-3xl font-semibold text-black">{roundRating(score, 1)} av 5</span>
            <span className="text-black-600 block">
              {__('BasedOn')} <span>{count}</span> {__('ratings')}{' '}
            </span>
          </div>
        </div>
      )}
      <div className="flex w-full flex-1 flex-col">{spread}</div>
      {ratingFilter ? (
        <div className="mt-6 flex flex-col gap-1">
          <span className="font-semibold text-black">{__('ReviewsWithStarRating', { rating: ratingFilter })}</span>
          <span className="text-black-600 text-m">
            {__('reviewsFromRatingsCounts', {
              reviews: reviewCounts[ratingFilter],
              ratings: ratingCounts[ratingFilter],
            })}
          </span>
        </div>
      ) : (
        <div className="mt-6 flex flex-col gap-1">
          <span className="font-semibold text-black">{__('LatestReviews')}</span>
          <span className="text-black-600 text-m">
            {__('reviewsFromRatingsCounts', {
              reviews: getTotalCount(reviewCounts),
              ratings: getTotalCount(ratingCounts),
            })}
          </span>
        </div>
      )}
    </div>
  );
}
export default Stats;
