import { server } from '../helpers';

export const favoriteServices = {
  add,
  remove,
  favorites,
};

function favorites() {
  return server.request
    .get('/favorites')
    .then((data) => {
      return server.handleSuccess(data);
    })
    .catch(server.handleError);
}

function add(placeId) {
  return server.request
    .post('/favorites/add', { placeId })
    .then((response) => response)
    .catch(server.handleError);
}

function remove(placeId) {
  return server.request
    .post('/favorites/remove', { placeId })
    .then((response) => response)
    .catch(server.handleError);
}
