import * as animationData from '@/assets/animations/loadingDotsBlack.json';
import AnimationWrapper from '../AnimationWrapper';
import { AnimationProps } from '../types';

const LoadingDotsBlack = (props: AnimationProps) => {
  const animationProps = { ...props, animationData };
  return <AnimationWrapper {...animationProps} />;
};

export default LoadingDotsBlack;
