import { config } from '@/config';
import { getLang } from '@/locale';

const locale = getLang() === 'en' ? 'en_US' : 'sv_SE';
const environment = config.env === 'production' || config.env === 'staging' ? 'LIVE' : 'TEST';
const clientKey = config.adyenClientKey;

export type AdyenCheckoutConfigutation = typeof checkoutConfig;

export const checkoutConfig = (amount: number) => ({
  paymentMethodsConfiguration: {
    card: {
      hasHolderName: true,
      holderNameRequired: true,
      name: 'Credit or debit card',
      amount: { value: amount, currency: 'SEK' },
    },
  },
  locale,
  environment,
  clientKey,
});

export const threeDSConfig = {
  locale,
  environment,
  clientKey,
};
