export const PaymentMethod = {
  UNSELECTED: -1,
  SALON: 0,
  KLARNA: 1,
  QLIRO: 3,
  STORED_ONLINE_CARD: 4,
  NEW_ONLINE_CARD: 5,
  GOOGLE_PAY: 6,
  APPLE_PAY: 7,
  SWISH: 8,
} as const;
