import { bookActions } from '@/actions';
import { ModalHeader } from '@/components/modules/mobile';
import { Campaigns, Employee, FilterServicesInput, ServicesCategory } from '@/components/modules/salon';
import { hasFeatureSetting, isMobile, startOfDay } from '@/helpers';
import { __ } from '@/locale';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { ModalContent, ModalDialog } from '../../modals';

class ServicePicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showServices: props.showServices || false,
      filterQuery: '',
    };

    this.close = this.close.bind(this);
    this.filterServices = this.filterServices.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.showServices !== prevProps.showServices) {
      this.setState({ showServices: this.props.showServices });
    }
  }

  filterServices = (query) => {
    this.setState({ filterQuery: query });
  };

  close() {
    if (this.props.hidePopup) {
      return this.props.hidePopup();
    }

    this.setState({ showServices: false });
    const { dispatch, append } = this.props;
    if (!append) {
      dispatch(bookActions.removeService(1));
      dispatch(bookActions.removeEmployee());
    }
  }

  render() {
    const { employeeData, placeData, services, source, append, time } = this.props;

    const showingRecommendedServices = new URLSearchParams(this.props.location.search).has('rs');

    if (!employeeData || !placeData) {
      return null;
    }
    const { showServices } = this.state;

    const { isBookable } = employeeData.about || {};
    if (!isBookable) {
      return null;
    }

    let item = null;
    if (showServices && isBookable) {
      let isOpen = isMobile() ? false : true;

      if (this.state.filterQuery) {
        isOpen = true;
      }

      const hasCollapsedSetting = hasFeatureSetting(placeData, 'collapsed_services');

      let index = -1;
      const servicesData = placeData.services
        ? placeData.services.map((service, i) => {
            let performing = 0;
            service.services.forEach((serviceItem) => {
              if (employeeData.services.indexOf(serviceItem.id) !== -1) {
                performing = performing + 1;
              }
            });

            if (performing > 0) {
              index++;

              return (
                <ServicesCategory
                  append={append}
                  className="modalServices"
                  place={placeData}
                  servicesCategory={service}
                  key={i}
                  isOpen={!hasCollapsedSetting && (index === 0 || isOpen)}
                  employee={employeeData}
                  selectedServices={services}
                  source={source}
                  hidePopupCallback={this.props.hidePopup}
                  filterQuery={this.state.filterQuery}
                />
              );
            }

            return null;
          })
        : null;

      const date = time && time.timestamp ? time.timestamp : startOfDay();

      item = (
        <ModalDialog
          isOpen={showServices && !showingRecommendedServices}
          appElement={document.getElementById('root')}
          onRequestClose={this.close}>
          <ModalContent size="lg">
            <ModalHeader
              action={this.close}
              cancel
              positionFixed={false}
              top={<Employee employee={employeeData} place={placeData} inModal forMobile />}
            />
            <h2 style={{ textAlign: 'center' }} className="!m-0 px-6 py-6 text-lg font-semibold">
              {__('chooseServices')}
            </h2>
            <div className="px-3">
              <FilterServicesInput setFilterServicesQuery={this.filterServices} source={source} />
            </div>
            {!this.state.filterQuery && (
              <Campaigns
                place={placeData}
                fromEmployee={employeeData}
                append={append}
                selectedServices={services}
                source={source}
                hidePopupCallback={this.props.hidePopup}
                date={date}
              />
            )}
            <div id="modalServices" className="services">
              {servicesData}
            </div>
          </ModalContent>
        </ModalDialog>
      );
    }

    return item;
  }
}

function mapStateToProps(state) {
  const { availability, time, services, campaigns } = state.book;
  return {
    availability,
    time,
    services,
    campaigns,
  };
}

const connectedServicePicker = withRouter(connect(mapStateToProps)(ServicePicker));
export { connectedServicePicker as ServicePicker };
