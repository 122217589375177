export const blogConstants = {
  list: 'list',
  category: 'category',
  author: 'author',
  latest: 'latest',
  featured: 'featured',
};

export type Category = {
  title: string;
  to: string;
  slug: string;
};
export const blogCategories: Category[] = [
  {
    title: 'all',
    slug: 'all',
    to: '/blogg/list',
  },
  {
    title: 'blog',
    slug: 'blogg',
    to: '/blogg/c/blogg',
  },
  {
    title: 'tips',
    slug: 'tips',
    to: '/blogg/c/tips',
  },
  {
    title: 'news',
    slug: 'nyheter',
    to: '/blogg/c/nyheter',
  },
  {
    title: 'interviews',
    slug: 'intervjuer',
    to: '/blogg/c/intervjuer',
  },
];
