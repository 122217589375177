import { SCREEN_NAME } from '@/constants/screenConstants';
import queryString from 'query-string';
import { config } from '../config';
import { loadingActions } from './../actions';
import { isServer, isSistaminuten } from './general';

export const url = {
  serialize,
  arrayToObj,
  fakeLoading,
  slug,
  getParamsToObj,
  getScreenNameFromUrl,
  getFilters,
  updateFilters,
  getUrlParameters,
  decodeURIComponentWithPercent,
  editProfileUrl,
  getParamsFromGET,
  decodeURIComponentSafe,
  replaceInvalidSinglePercent,
  changePage,
  changeGetParameter,
  returnGetParameter,
  getHashFreeUrl,
  clearDateTimeFilters,
  addSortingFiltersWithValue,
  clearSortingFilters,
  getBaseUrl,
  getServerUrl,
  getBaseImageUrl,
  getDomain,
  addDateTimeFiltersWithValue,
  addPaginationFiltersWithValue,
};

function getFilters(unSerialize = false) {
  const queryParams = getParamsFromGET();

  const filters = { k: 1, d: 2, gc: 3, kq: 4, dc: 5, cp: 6, fb: 7, pc: 8, wellness: 9, bundles: 10 };
  const prefs = [];
  Object.keys(filters).forEach((filter) => {
    if (queryParams[filter]) {
      prefs.push(filters[filter]);
    }
  });

  if (prefs.length > 0) {
    return unSerialize ? prefs : prefs.join(',');
  }
  return null;
}

const allowedPaginationFilters = ['page', 'extend', 'extendPage', 'offset', 'extendSearchIteration'];
const allowedDateTimeFilters = ['startDate', 'endDate', 'timeOfDay'];
const allowedPositionFilters = ['lat', 'lon', 'sort'];

function addPaginationFiltersWithValue(filters = {}) {
  const currentQueryString = getParamsFromGET();

  allowedPaginationFilters.forEach((allowedFilter) => {
    if (filters[allowedFilter]) {
      currentQueryString[allowedFilter] = filters[allowedFilter];
    } else if (Object.keys(filters).includes(allowedFilter)) {
      delete currentQueryString[allowedFilter];
    }
  });

  return queryString.stringify(currentQueryString);
}

function addDateTimeFiltersWithValue(filters = {}) {
  let currentQueryString = getParamsFromGET();

  allowedDateTimeFilters.forEach((allowedFilter) => {
    if (filters[allowedFilter]) {
      currentQueryString[allowedFilter] = filters[allowedFilter];
    } else if (Object.keys(filters).includes(allowedFilter)) {
      delete currentQueryString[allowedFilter];
    }
  });

  // clear pagination
  currentQueryString = clearPaginationFilters(currentQueryString);
  return queryString.stringify(currentQueryString);
}

function addSortingFiltersWithValue(filters = {}) {
  const currentQueryString = getParamsFromGET();

  allowedPositionFilters.forEach((allowedFilter) => {
    if (filters[allowedFilter]) {
      currentQueryString[allowedFilter] = filters[allowedFilter];
    } else if (Object.keys(filters).includes(allowedFilter)) {
      delete currentQueryString[allowedFilter];
    }
  });

  return queryString.stringify(currentQueryString);
}

function clearDateTimeFilters() {
  let currentQueryString = getParamsFromGET();
  allowedDateTimeFilters.forEach((filterKey) => delete currentQueryString[filterKey]);
  // clear pagination
  currentQueryString = clearPaginationFilters(currentQueryString);
  return queryString.stringify(currentQueryString);
}

function clearSortingFilters() {
  const currentQueryString = getParamsFromGET();
  allowedPositionFilters.forEach((filterKey) => delete currentQueryString[filterKey]);
  return queryString.stringify(currentQueryString);
}

function clearPaginationFilters(currentQueryString) {
  allowedPaginationFilters.forEach((filterKey) => delete currentQueryString[filterKey]);
  return currentQueryString;
}

function updateFilters(existings) {
  existings = existings || [];

  const existingFilters = {};
  let currentQueryString = getParamsFromGET();
  const filters = {
    1: 'k',
    2: 'd',
    3: 'gc',
    4: 'kq',
    5: 'dc',
    6: 'cp',
    7: 'fb',
    8: 'pc',
    9: 'wellness',
    10: 'bundles',
  };

  // reset fields
  Object.keys(filters).forEach((key) => {
    currentQueryString[filters[key]] = undefined;
  });
  // clear pagination
  currentQueryString = clearPaginationFilters(currentQueryString);

  existings.forEach((option) => {
    existingFilters[filters[option]] = 1;
  });

  return queryString.stringify({ ...currentQueryString, ...existingFilters });
}

function changePage(page) {
  const currentQueryString = getParamsFromGET();
  if (page === 0) {
    delete currentQueryString['page'];
    return queryString.stringify(currentQueryString);
  }
  return queryString.stringify({ ...currentQueryString, page });
}

function getParamsFromGET() {
  return !isServer ? arrayToObj(queryString.parse(window.location.search)) : {};
}

// function objFromStr(str, delimiter) {
//   const parts = str.split(delimiter).filter(Boolean);

//   const result = {};
//   for(var k in parts) {
//     const index     = parts[k].indexOf("=");
//     const key   = parts[k].substr(0, index);
//     const value = parts[k].substr(index + 1, parts[k].length - 1);
//     result[key] = value;
//   }
//   return result;
// }

function serialize(obj, prefix) {
  let str = [],
    p;
  for (p in obj) {
    if (obj.hasOwnProperty(p)) {
      let k = prefix ? prefix + '[' + p + ']' : p,
        v = obj[p];
      str.push(
        v !== null && typeof v === 'object' ? serialize(v, k) : encodeURIComponent(k) + '=' + encodeURIComponent(v),
      );
    }
  }
  return str.join('&');
}

function arrayToObj(urlParts, prefix) {
  let obj = Object.create(null);
  for (const urlKey in urlParts) {
    const parts = urlKey.split('[');
    if (parts[1]) {
      if (!obj[parts[0]]) {
        obj[parts[0]] = {};
      }
      const a = parts[1].substr(0, parts[1].length - 1);
      obj[parts[0]][a] = urlParts[urlKey];
    } else {
      obj[urlKey] = urlParts[urlKey];
    }
  }
  return obj;
}

function fakeLoading(dispatch, source, timeout = 200) {
  dispatch(loadingActions.show(source));
  setTimeout(() => {
    dispatch(loadingActions.hide());
  }, timeout);
}

function slug(str) {
  str = str.replace(/^\s+|\s+$/g, ''); // trim
  str = str.toLowerCase();

  // remove accents, swap ñ for n, etc
  var from = 'åàáãäâèéëêìíïîòóöôùúüûñç·/_,:;';
  var to = 'aaaaaaeeeeiiiioooouuuunc------';

  for (var i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
  }

  str = str
    .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
    .replace(/\s+/g, '-') // collapse whitespace and replace by -
    .replace(/-+/g, '-'); // collapse dashes

  return str;
}

function getParamsToObj(get) {
  if (get.length === 0) return {};
  if (get[0] === '?') {
    get = get.substring(1, get.length);
  }
  let pairs = get.split('&');
  let params = {};
  for (let i in pairs) {
    var split = pairs[i].split('=');
    params[decodeURIComponent(split[0])] = decodeURIComponent(split[1]);
  }
  return params;
}

function getScreenNameFromUrl(sourceUrl) {
  try {
    const parser = document.createElement('a');
    parser.href = sourceUrl;
    const pathname = decodeURIComponent(parser.pathname);
    switch (true) {
      case pathname.includes('places/'):
        return SCREEN_NAME.COMPANY_DETAILS;
      case pathname.includes('boka-tjanst'):
        return SCREEN_NAME.BOOKING_CHOOSE_TIME;
      case pathname.includes('booking/checkout'):
        return SCREEN_NAME.BOOKING_CHECKOUT;
      case pathname.includes('confirmed'):
        return SCREEN_NAME.BOOKING_CONFIRMATION;
      case pathname.includes('favoriter'):
        return SCREEN_NAME.FAVORITES;
      case pathname.includes('bokningar/tidigare'):
        return SCREEN_NAME.MY_BOOKINGS_FINISHED;
      case pathname.includes('bokningar'):
        return SCREEN_NAME.MY_BOOKINGS_UPCOMING;
      case pathname.includes('bokning/'):
        return SCREEN_NAME.MY_BOOKINGS_DETAILS;
      case pathname.includes('klarna'):
        return SCREEN_NAME.BOOKING_KLARNA_CHECKOUT;
      case pathname.includes('qliro'):
        return SCREEN_NAME.BOOKING_QLIRO_CHECKOUT;
      case pathname.includes('portal/'):
        return SCREEN_NAME.PORTAL;
      case pathname.includes('goto/'):
        return SCREEN_NAME.GOTO;
      case pathname.includes('terms'):
        return SCREEN_NAME.TERMS;
      case pathname.includes('faq-vanliga-fragor'):
        return SCREEN_NAME.FAQ;
      case pathname.includes('betalningsmetoder'):
        return SCREEN_NAME.PAYMENT_METHODS;
      case pathname.includes('account-settings'):
        return SCREEN_NAME.ACCOUNT_SETTINGS;
      case pathname.includes('my-profile'):
        return SCREEN_NAME.MY_PROFILE;
      case pathname.includes('blogg'):
        return SCREEN_NAME.BLOG;
      case pathname.includes('support/form'):
        return SCREEN_NAME.SUPPORT_FORM;
      case pathname.includes('support'):
        return SCREEN_NAME.SUPPORT;
      case pathname.includes('fakta-och-rad'):
        if (pathname === '/fakta-och-rad') return SCREEN_NAME.FACTS;
        else return pathname.replace('/fakta-och-rad/', '').replaceAll('-', '_');
      case pathname === '/':
        return SCREEN_NAME.HOME;
      default:
        return pathname;
    }
  } catch (e) {
    return 'home';
  }
}

function getBaseUrl() {
  if (isSistaminuten()) {
    return config.lastMinuteBaseUrl;
  } else {
    return config.baseUrl;
  }
}

function getServerUrl() {
  if (isSistaminuten()) {
    return config.lastMinuteServerUrl;
  } else {
    return config.serverUrl;
  }
}

function getDomain() {
  if (isServer) return null;
  if (window.location.hostname === 'localhost') return 'localhost';
  return '.' + window.location.hostname.split('.').slice(-2).join('.');
}

function getBaseImageUrl() {
  if (isSistaminuten()) {
    return config.lastMinuteBaseUrl + 'images/sistaminuten-home-background.jpeg';
  } else {
    return config.baseUrl + 'images/home-background.jpeg';
  }
}

function editProfileUrl() {
  let url = config.authUrl + 'account/edit?return_url=';
  if (isServer) {
    return url;
  }

  url += encodeURIComponent(getHashFreeUrl() + '#ZmV0Y2h1c2Vy');
  return url;
}

function replaceInvalidSinglePercent(s) {
  return s ? s.replace(/%(?![0-9][0-9a-fA-F]+)/g, '%25') : s;
}

function decodeURIComponentSafe(s) {
  if (!s) {
    return s;
  }
  return s;

  // return replaceInvalidSinglePercent(s);
  // return decodeURI(decodeURIComponent(s.replace(/%(?![0-9][0-9a-fA-F]+)/g, '%25')));
  // return decodeURI(decodeURIComponent(s.replace(/%(?![0-9][0-9a-fA-F]+)/g, '%25')));
}

const fixPercentage = (txt) => {
  let parts = txt.split('%');
  parts = parts.map((part, index) => {
    let worked = true;
    // if % is not first then we don;t have to decode anything
    if (index === 0) {
      return part;
    }

    // Try decodeing each part that has an encoded char
    try {
      decodeURIComponent('%' + part);
    } catch (e) {
      worked = false;
    }
    if (!worked) {
      // FIX percent, this will generate %25 instead of %, so it can be decoded
      return '25' + part;
    } else {
      return part;
    }
  });

  return parts.join('%');
};

function decodeURIComponentWithPercent(txt) {
  try {
    decodeURIComponent(txt);
  } catch (e) {
    txt = fixPercentage(txt);
  }
  return decodeURIComponent(txt);
}

function getUrlParameters(props) {
  let { q = '', location = '' } = props.match.params;
  if (!q && props.search && props.search.q) {
    q = props.search.q || '';
  }

  if (!location && props.search && props.search.location) {
    location = props.search.location || '';
  }
  q = q.toString();
  location = location.toString();

  return {
    q: q.toLowerCase() !== 'vad' ? decodeURIComponentWithPercent(q) : '',
    location: location.toLowerCase() !== 'var' ? decodeURIComponentWithPercent(location) : '',
    prefs: getFilters() || undefined,
    page: getParamsFromGET()['page'],
    startDate: getParamsFromGET()['startDate'],
    endDate: getParamsFromGET()['endDate'],
    extendPage: getParamsFromGET()['extendPage'],
    extend: getParamsFromGET()['extend'],
    offset: getParamsFromGET()['offset'],
    extendSearchIteration: getParamsFromGET()['extendSearchIteration'],
    timeOfDay: getParamsFromGET()['timeOfDay'],
    sort: getParamsFromGET()['sort'],
    lat: getParamsFromGET()['lat'],
    lon: getParamsFromGET()['lon'],
    explain: getParamsFromGET()['explain'],
    startingScoreVersion: getParamsFromGET()['startingScoreVersion'],
  };
}

function getHashFreeUrl() {
  const hash = window.location.hash;
  let returnUrl = window.location.href;
  returnUrl = returnUrl.replace(hash, '');
  // Remove # if any
  if (returnUrl[returnUrl.length - 1] === '#') {
    returnUrl = returnUrl.slice(0, -1);
  }

  return returnUrl;
}

function changeGetParameter(name, value = null) {
  if (isServer) return;
  const currentQueryString = getParamsFromGET();

  if (value) {
    currentQueryString[name] = value;
  } else {
    delete currentQueryString[name];
  }

  return queryString.stringify(currentQueryString);
}

function returnGetParameter(name) {
  return getParamsFromGET()[name];
  // if (isServer) return;
  // let currentUrl = new URL(window.location.href);
  // let searchParams = currentUrl.searchParams;
  // return searchParams.get(name);
}
