import LoadingPlaceHolder from '@/components/elements/LoadingPlaceholder';
import PageViewLayout from '@/components/layouts/PageViewLayout/PageViewLayout';
import { _s } from '@/locale';
import { Redirect } from 'react-router-dom';
import useValidateQliroPayment from './ValidateQliroPayment.hooks';

export const baseTranslationKey = 'pages.validate-qliro-payment.ValidateQliroPayment';

const ValidateQliroPayment = () => {
  const { redirect } = useValidateQliroPayment();

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  return (
    <PageViewLayout type="subView">
      <LoadingPlaceHolder text={_s(`${baseTranslationKey}.loadingLabel`)} />
    </PageViewLayout>
  );
};

export default ValidateQliroPayment;
