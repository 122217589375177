import Snackbar from '@/components/elements/notifications/Snackbar/Snackbar';
import { CHECKOUT_PAYMENT_METHOD } from '@/constants/checkout';
import { APP_ROUTES } from '@/constants/pages';
import { exportIdSlug, isEmpty, promiseWrapper } from '@/helpers';
import { getGiftcardCheckoutSummary } from '@/helpers/checkout';
import { useAppSelector } from '@/hooks';
import { GiftcardFormData, useGiftcardCheckoutFormData } from '@/hooks/useGiftcardCheckoutFormData';
import { usePlaceGiftCardData } from '@/hooks/usePlaceData';
import { _s } from '@/locale';
import { giftCardServices } from '@/services';
import { initiateOrUpdatePGCPayment } from '@/services/pgcPaymentServices';
import { KlarnaResponse, klarnaResponseSchema } from '@/types/api/services/giftcard';
import { ErrorResponse, errorResponseSchema } from '@/types/api/services/schema';
import {
  CheckoutAction,
  CheckoutSummary,
  baseCheckoutStateSchema,
  giftcardCheckoutSummarySchema,
} from '@/types/checkout';
import * as Sentry from '@sentry/react';
import { Dispatch, Reducer, createContext, useContext, useEffect, useReducer } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { z } from 'zod';

export const PHYSICAL_FEE = 45;

export const giftcardCheckoutStateSchema = baseCheckoutStateSchema.merge(
  z.object({
    summary: giftcardCheckoutSummarySchema,
    klarna: klarnaResponseSchema.optional(),
    loading: z.boolean().optional(),
    employee: z.string().optional(),
  }),
);

export type GiftcardCheckoutState = z.infer<typeof giftcardCheckoutStateSchema>;

type SubmitKlarnaGiftcardPaymentContext = {
  errorCallback: (error: ErrorResponse) => void;
  formData: GiftcardFormData;
  productType: 'giftcard' | 'wellnesscard';
  user: any;
  klarna: KlarnaResponse;
};

type SubmitKlarnaPlacePaymentContext = {
  errorCallback: (error: ErrorResponse) => void;
  formData: GiftcardFormData;
  user: any;
  klarna: KlarnaResponse;
  placeId: number;
  slugId: string;
  slug: string;
  employees: any;
};

const initialState: GiftcardCheckoutState = {
  summary: {
    acceptsGiftcard: false,
    acceptsValuecard: false,
    acceptsWellnesscard: false,
    availablePaymentMethods: [],
    isOnlinePaymentRequired: true,
    preSelectedPaymentMethod: {},
    canPayOnline: true,
  },
  selectedPaymentMethod: { type: CHECKOUT_PAYMENT_METHOD.NONE },
  missingActions: [],
  submitting: false,
};

type GiftcardCheckoutAction =
  | CheckoutAction<CheckoutSummary>
  | { type: 'INITIALIZE_KLARNA'; payload: KlarnaResponse }
  | { type: 'LOADING'; payload: boolean };

const giftcardCheckoutReducer: Reducer<GiftcardCheckoutState, GiftcardCheckoutAction> = (state, action) => {
  switch (action.type) {
    case 'SUBMITTING': {
      return { ...state, submitting: action.payload };
    }
    case 'INITIALIZE_KLARNA': {
      return {
        ...state,
        klarna: action.payload,
      };
    }
    case 'ERROR': {
      return {
        ...state,
        submitting: false,
        error: action.payload,
      };
    }
    case 'UPDATE_SUMMARY':
      return {
        ...state,
        summary: action.payload,
      };
    case 'LOADING': {
      return {
        ...state,
        loading: action.payload,
      };
    }
    default:
      return state;
  }
};

export type UseGiftcardCheckoutManagerResult = ReturnType<typeof useGiftcardCheckoutManager>;

async function handleKlarnaPayment(
  context: SubmitKlarnaGiftcardPaymentContext,
  dispatch: Dispatch<GiftcardCheckoutAction>,
) {
  const { formData, productType, user, klarna } = context;

  const address = {
    address: formData.address ? formData.address : user?.contact?.streetAddress,
    city: formData.city ? formData.city : user?.contact?.locality,
    name: formData.name ? formData.name : user?.about?.name,
    zipcode: formData.postalCode ? formData.postalCode : user?.contact?.postalCode,
  };

  const email = formData.email ? formData.email : user?.contact?.email;

  if (klarna) {
    dispatch({ type: 'SUBMITTING', payload: true });
  }

  const { data, error } = await promiseWrapper(
    giftCardServices.createOrUpdateOrder({
      code: formData.discountCode,
      discount: 0,
      isWellness: productType === 'wellnesscard',
      quantity: formData.quantity,
      ...(formData.serviceType
        ? { serviceType: { name: formData.serviceType, taxRate: formData.serviceType === 'massage' ? 2500 : 600 } }
        : {}),
      ...(formData.type === 'physical' ? { shipping: address } : { shipping: null }),
      shippingMethod: formData.type === 'digital' ? 'digital' : 'shipping',
      greeting:
        formData.giverName || formData.message || formData.recipientName
          ? { title: formData.recipientName, message: formData.message, giver: formData.giverName }
          : null,
      sessionID: klarna?.sessionID ?? null,
      ssn: formData.ssn,
      value: formData.customAmount || formData.amount,
      email,
      newConfirmationPage: true,
    }),
  );

  const validateResponse = klarnaResponseSchema.safeParse(data);

  if (!validateResponse.success || error) {
    context.errorCallback(error);
    return;
  }

  if (!klarna) {
    dispatch({
      type: 'INITIALIZE_KLARNA',
      payload: validateResponse.data,
    });
  }

  dispatch({ type: 'SUBMITTING', payload: false });

  return validateResponse.data;
}

async function handlePlaceKlarnaPayment(
  context: SubmitKlarnaPlacePaymentContext,
  dispatch: Dispatch<GiftcardCheckoutAction>,
) {
  const { formData, user, klarna, placeId, slug, slugId, employees } = context;

  const employee = employees && formData.employee ? employees.find((e) => e.id === formData.employee) : null;

  const email = formData.email ? formData.email : user?.contact?.email;

  if (klarna) {
    dispatch({ type: 'SUBMITTING', payload: true });
  }

  const { data, error } = await promiseWrapper(
    initiateOrUpdatePGCPayment(klarna?.sessionID ?? null, {
      quantity: formData.quantity,
      value: formData.customAmount || formData.amount,
      placeId,
      employee,
      slug,
      slugId,
      email,
      newConfirmationPage: true,
    }),
  );

  const validateResponse = klarnaResponseSchema.safeParse(data);

  if (!validateResponse.success || error) {
    context.errorCallback(error);
    return;
  }

  if (!klarna) {
    dispatch({
      type: 'INITIALIZE_KLARNA',
      payload: validateResponse.data,
    });
  }

  dispatch({ type: 'SUBMITTING', payload: false });

  return validateResponse.data;
}

const useGiftcardCheckoutManager = (formData: GiftcardFormData) => {
  const { setValue, getValues } = useGiftcardCheckoutFormData();
  const location = useLocation();
  const user = useAppSelector((state) => state.users.user);
  const ssrPlace = useAppSelector((state) => state.place);
  const { slugId } = useParams();
  const { id, slug } = exportIdSlug(slugId);
  const { loading: loadingPlace, place } = usePlaceGiftCardData(ssrPlace, parseInt(id), slug);
  const employees = place && !isEmpty(place) && place.employees;
  const placeName = (place && !isEmpty(place) && place.name) || '...';
  const placeSellsGiftcard = Boolean(place && !isEmpty(place) && place.sellsGiftCard);

  const [state, dispatch] = useReducer(giftcardCheckoutReducer, initialState);
  const productType = location.pathname.startsWith(APP_ROUTES.WELLNESSCARD_CHECKOUT) ? 'wellnesscard' : 'giftcard';

  useEffect(() => {
    if (!place?.employees?.length) return;
    place.redirect && (window.location.href = `${place.redirect}/giftcard/checkout`);
    setValue('employee', place.employees[0].id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [place]);

  useEffect(() => {
    dispatch({ type: 'LOADING', payload: loadingPlace || place?.dataType !== 'giftCard' });
  }, [loadingPlace, place?.dataType]);

  useEffect(() => {
    const giftcardValue = formData.customAmount || formData.amount;

    const updateSummary = async () => {
      const orderTotal = giftcardValue * formData.quantity + (formData.type === 'physical' ? PHYSICAL_FEE : 0);
      const { data } = formData.discountCode
        ? await promiseWrapper(giftCardServices.checkDiscountCode(formData.discountCode, orderTotal))
        : { data: null };

      const summary = getGiftcardCheckoutSummary({
        orderTotal,
        discountAmount: data?.discount,
      });

      dispatch({ type: 'UPDATE_SUMMARY', payload: summary });
    };
    updateSummary();
  }, [
    formData.type,
    formData.amount,
    formData.discountCode,
    formData.customAmount,
    formData.quantity,
    state.selectedPaymentMethod,
    location.state?.selectedPaymentMethod,
  ]);

  const handleError = (error: unknown) => {
    const errorData = errorResponseSchema.safeParse(error);
    dispatch({ type: 'ERROR', payload: true });

    const displayClientError = (errorMessage?: string) => {
      toast(
        ({ closeToast }) => (
          <Snackbar
            label={errorMessage ?? _s('serverError')}
            type="danger"
            onClose={() => {
              dispatch({ type: 'ERROR', payload: false });
              closeToast();
            }}
          />
        ),
        {
          autoClose: 5000,
        },
      );
    };

    if (!errorData.success) {
      displayClientError();
      Sentry.captureException(error);
      return;
    }

    const { clientError } = errorData.data;

    displayClientError(clientError);
    Sentry.captureException(error);
  };

  return {
    ...state,
    placeSellsGiftcard,
    placeName,
    employees,
    handleKlarnaPayment: () =>
      handleKlarnaPayment({ errorCallback: handleError, formData, productType, user, klarna: state.klarna }, dispatch),
    handlePlaceKlarnaPayment: () =>
      handlePlaceKlarnaPayment(
        {
          errorCallback: handleError,
          formData: getValues(),
          user,
          klarna: state.klarna,
          placeId: id,
          slugId,
          slug,
          employees,
        },
        dispatch,
      ),
  };
};

export default useGiftcardCheckoutManager;

const GiftcardCheckoutContext = createContext<UseGiftcardCheckoutManagerResult>({
  handleKlarnaPayment: async () => {
    return {};
  },
  handlePlaceKlarnaPayment: async () => {
    return {};
  },
  submitting: false,
  placeSellsGiftcard: false,
  placeName: '',
  loading: true,
  employees: [],
});

export const useGiftcardCheckout = () => {
  const context = useContext(GiftcardCheckoutContext);

  if (!context) {
    throw new Error('useGiftcardCheckout must be used within GiftcardCheckoutContext');
  }

  return context;
};

export const GiftcardCheckoutProvider = ({ children }: { children: React.ReactNode }) => {
  const { formdata } = useGiftcardCheckoutFormData();
  return (
    <GiftcardCheckoutContext.Provider value={useGiftcardCheckoutManager(formdata)}>
      {children}
    </GiftcardCheckoutContext.Provider>
  );
};
