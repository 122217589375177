import { deployConstants } from '../constants';

export const deployActions = {
  lastDeployTimestamp,
  clearDeployTimestamp,
};

function lastDeployTimestamp(timestamp) {
  return { type: deployConstants.lastDeployTimestamp, timestamp };
}

function clearDeployTimestamp() {
  return { type: deployConstants.clearDeployTimestamp };
}
