type RadioButtonProps = { onClick?: () => void; checked?: boolean; alt?: string };

const RadioButton = ({ onClick, checked, alt = '' }: RadioButtonProps) => {
  const commonProps = {
    role: 'radio',
    'aria-checked': checked,
    'aria-label': alt,
    className: `${
      checked ? 'border-black-900 bg-black-800' : 'border-black-300 bg-black-200'
    } relative inline-flex h-[32px] w-[32px] items-center justify-center rounded-full border-[1px] outline-none`,
  };
  return (
    <>
      {onClick && (
        <button onClick={onClick} {...commonProps}>
          {checked && (
            <span
              className={`border-black-900 relative
    inline-flex h-[12px] w-[12px] items-center rounded-full border-[0.5px] bg-white outline-none`}
            />
          )}
        </button>
      )}
      {!onClick && (
        <span {...commonProps}>
          {checked && (
            <span
              className={`border-black-900 relative
  inline-flex h-[12px] w-[12px] items-center rounded-full border-[0.5px] bg-white outline-none`}
            />
          )}
        </span>
      )}
    </>
  );
};

export default RadioButton;
