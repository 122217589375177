import { PlaceEmployee, PlaceService, PlaceServiceGroup } from '@/types/state/shared';
import { useEffect, useRef } from 'react';

export const ScrollToTopElement = () => {
  const elementRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleScrollToDiv() {
      if (!elementRef.current) {
        setTimeout(handleScrollToDiv, 10);
        return;
      }
      elementRef.current.scrollIntoView({ behavior: 'auto', block: 'start' });
    }
    handleScrollToDiv();
  }, []);

  return <div className="absolute left-0 top-0" ref={elementRef} />;
};

export function getPickedEmployee(employeeId: number, employees: PlaceEmployee[]): PlaceEmployee | undefined {
  return employees.find((employee) => employee.id === employeeId);
}

export function getRecommendedServices(pickedServices: PlaceService[]) {
  // finds the last service from pickedServices that is not marked as an add-on
  const mainService =
    pickedServices
      .slice()
      .reverse()
      .find((service) => !service?.about?.settings?.isAddOn) || {};

  // all add-on services for the main service
  const addOnServices = mainService?.extra?.addOnServices || [];

  return (
    addOnServices
      // filter out services that are already picked
      .filter((service) => !pickedServices.some((pickedService) => pickedService.id === service.id))
      // sort by name alphabetically
      .sort((a, b) => ('' + a.name).localeCompare(b.name))
  );
}

export function filterBookPlaceServicesBySelectedEmployee(
  serviceCategories: PlaceServiceGroup[],
  pickedEmployee: PlaceEmployee,
): PlaceServiceGroup[] {
  return serviceCategories.reduce((acc, category) => {
    const servicesInCategoryPerformedByEmployee = category.services.filter((service) => {
      return pickedEmployee.services?.includes(service.id);
    });

    if (servicesInCategoryPerformedByEmployee.length) {
      acc.push({
        ...category,
        services: servicesInCategoryPerformedByEmployee,
      });
    }
    return acc;
  }, []);
}

export function filterBookPlaceServicesByEmptyCategories(serviceCategories: PlaceServiceGroup[]): PlaceServiceGroup[] {
  return serviceCategories.filter((category) => category.services?.length);
}

export function filterBookPlaceServicesByPickedServices(
  serviceCategories: PlaceServiceGroup[],
  pickedServices: PlaceService[],
): PlaceServiceGroup[] {
  return serviceCategories.map((category) => ({
    ...category,
    services: category.services.filter((service) =>
      pickedServices.every((pickedService) => pickedService.id !== service.id),
    ),
  }));
}
