import { deployActions } from '@/actions';
import PageViewLayout from '@/components/layouts/PageViewLayout/PageViewLayout';
import BannerSlot from '@/components/modules/banner/BannerSlot';
import CategoriesContainer from '@/components/modules/pages/home/CategoriesContainer';
import Hero from '@/components/modules/pages/home/Hero';
import { PlaceCardContainer } from '@/components/modules/place';
import { BANNER_SLOT_LOCATION } from '@/constants/banner';
import {
  getHomeDescription,
  getTrackingPlace,
  isServer,
  isSistaminuten,
  server,
  setBookingStartingPoint,
  trackMpEvent,
  trackPage,
  updateDeployTimestamp,
  url,
} from '@/helpers';
import { useAppSelector } from '@/hooks';
import useMobileView from '@/hooks/useMobileView';
import { __ } from '@/locale';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { connect, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';

const setRecentVisited = async (setVisited, user, isMounted = true) => {
  if (!user || !user.id) return setVisited([]);

  server.request
    .get('/places/recent')
    .then((response) => isMounted && setVisited(response.data))
    .catch((_) => {});
};

const trackHomePage = () => {
  trackMpEvent('screen_shown', { screen_name: 'home' });
  trackPage();
};

const trackVisitedPlace = (place) => () => {
  trackMpEvent('recently_visited_company_clicked', getTrackingPlace(place, 'home'));
  setBookingStartingPoint('home_recently_viewed');
};

const hasLastVisited = (visited) => {
  return !!(visited && visited.length);
};

const getLastVisitedPlaces = (user = null, visited) => {
  const lastVisitedPlace = !isServer ? JSON.parse(localStorage.getItem('mpLastVisited')) || undefined : undefined;
  const isLogged = Boolean(user && user.id);
  let placesList = [];

  if (hasLastVisited(visited)) {
    placesList = visited.map((place, k) => {
      return (
        <PlaceCardContainer onClickPlace={trackVisitedPlace(place)} key={k} source={'home'} place={place} SerpIdx={k} />
      );
    });
  } else {
    if (lastVisitedPlace) {
      placesList.push(
        <PlaceCardContainer
          onClickPlace={
            isLogged
              ? trackVisitedPlace(lastVisitedPlace)
              : (e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }
          }
          key={0}
          source={'home'}
          place={lastVisitedPlace}
          SerpIdx={0}
          hasLoginForLastVisited={!isLogged}
        />,
      );
    } else {
      if (!isLogged) {
        const fakePlace = {
          id: 0,
          about: {
            name: 'Pinkstore',
            rating: { count: 87, score: 4.9 },
            settings: {},
            images: [url.getBaseUrl() + 'images/pinkstore-logo.png'],
          },
          contact: {
            address: { city: 'Stockholm', street: 'Jungfruvägen 89B' },
          },
        };

        placesList.push(
          <PlaceCardContainer
            onClickPlace={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            key={0}
            source={'home'}
            place={fakePlace}
            SerpIdx={0}
            hasLoginForLastVisited={true}
          />,
        );
      }
    }
  }

  return placesList.length ? (
    <div>
      <h2 className="mb-6 ml-4 mt-6 text-2xl font-semibold sm:ml-0 sm:mt-8">{__('recentlyShowed')}</h2>
      <div className="flex w-full flex-col gap-6">{placesList}</div>
    </div>
  ) : null;
};

const SEO = () => {
  const title = __(isSistaminuten() ? 'seo.sistaminuten.homepageTitle' : 'seo.homepageTitle');
  const description = getHomeDescription();
  const keywords = __(isSistaminuten() ? 'seo.sistaminuten.homepageKeywords' : 'seo.homepageKeywords');
  const homeUrl = url.getBaseUrl();
  const imageUrl = url.getBaseImageUrl();
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      <link rel="canonical" href={homeUrl} />

      <meta property="og:url" content={homeUrl} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={imageUrl} />
    </Helmet>
  );
};

const DCHome = ({ user, deploy = null }) => {
  const [visited, setVisited] = useState([]);
  const [isMounted, setIsMounted] = useState(false);
  const dispatch = useDispatch();
  const { isMobileView } = useMobileView();
  const flags = useAppSelector((state) => state.flags);
  const userId = user?.id || 0;

  useEffect(() => {
    setIsMounted(true);

    if (!isServer) window.scrollTo(0, 0);

    localStorage.removeItem('isLanding');
    localStorage.removeItem('slug');
    setRecentVisited(setVisited, user);
    trackHomePage();
  }, []);

  useEffect(() => {
    if (deploy) {
      updateDeployTimestamp(deploy);
      dispatch(deployActions.clearDeployTimestamp());
    }
  });

  useEffect(() => {
    setRecentVisited(setVisited, user, isMounted);
  }, [userId]);

  return (
    <PageViewLayout
      type="mainView"
      source="home"
      wrapperClass="home bg-gradient"
      {...(!isSistaminuten() && {
        prepend: (
          <BannerSlot
            config={flags[BANNER_SLOT_LOCATION.ABOVE_ALL_BANNER_HOME_SCREEN]?.payload}
            locationId={BANNER_SLOT_LOCATION.ABOVE_ALL_BANNER_HOME_SCREEN}
          />
        ),
      })}>
      <Hero />
      {!isSistaminuten() && (
        <BannerSlot
          config={flags[BANNER_SLOT_LOCATION.TOP_BANNER_HOME_WEB]?.payload}
          locationId={BANNER_SLOT_LOCATION.TOP_BANNER_HOME_WEB}
        />
      )}
      <div className="bg-gradient">
        <div className="container">
          {!isSistaminuten() && (
            <BannerSlot
              config={flags[BANNER_SLOT_LOCATION.MIDDLE_BANNER_HOME]?.payload}
              locationId={BANNER_SLOT_LOCATION.MIDDLE_BANNER_HOME}
              wrapperClass="py-lg"
            />
          )}
          <CategoriesContainer />
        </div>

        {!isSistaminuten() && (
          <>
            {isMobileView ? (
              <div className="py-lg">
                <BannerSlot
                  config={flags[BANNER_SLOT_LOCATION.BOTTOM_BANNER_HOME]?.payload}
                  locationId={BANNER_SLOT_LOCATION.BOTTOM_BANNER_HOME}
                />
              </div>
            ) : (
              <div className="py-lg container">
                <BannerSlot
                  config={flags[BANNER_SLOT_LOCATION.BOTTOM_BANNER_HOME]?.payload}
                  locationId={BANNER_SLOT_LOCATION.BOTTOM_BANNER_HOME}
                />
              </div>
            )}
            <div className="mb-3xl sm:container sm:mx-auto">{getLastVisitedPlaces(user, visited)}</div>
          </>
        )}
      </div>
      <SEO />
    </PageViewLayout>
  );
};

const mapStateToProps = (state) => {
  const { search, deploy, users } = state;
  const { user } = users || {};
  return { deploy, search, user };
};

const Home = withRouter(connect(mapStateToProps)(DCHome));
export default Home;
