import { h2Class } from '@/components/elements/content';
import Label from '@/components/elements/Label/Label';

const Header = ({ title, tag, tagText }) => (
  <>
    <h1 className={h2Class}>{title}</h1>
    <div className="flex items-center justify-start">
      <Label variant="novelty" label={tag} />
      <span className="ml-3">{tagText}</span>
    </div>
  </>
);

export default Header;
