import moment from 'moment';
import 'moment-timezone';
import 'moment/locale/sv'; // without this line it didn't work
import { config } from './../config';
export {
  addTimezone,
  firstWeekDay,
  fromPlaceTs,
  getCalendarWeek,
  getCurrentDayName,
  getCurrentTimeAsSeconds,
  getDateFormattedForFirstAvailableTime,
  getDayHours,
  getDayInfo,
  getDaysUntil,
  getDaysUntilDateTime,
  getFirstDayOfMonth,
  getGiftCardExpiryDateFromToday,
  getHoursInterval,
  getLastDayOfMonth,
  getMondayOfCurrentWeek,
  getMonthsFromFirstDay,
  getPlaceTsOffset,
  getSalonDate,
  getSundayOfCurrentWeek,
  getWeeksFromFirstDay,
  getWellnessCardExpiryDateFromToday,
  getYYYYMMDD,
  hasNotPasted,
  hoursFromSeconds,
  isToday,
  isTomorrow,
  lastWeekDay,
  leftPad,
  makeReadableDay,
  makeReadableDayShort,
  makeToUTC,
  rightPad,
  secondsToHour,
  startOfDay,
  toPlaceTs,
};

function isToday(time) {
  return startOfDay() === startOfDay(time);
}
function isTomorrow(time) {
  return startOfDay() === startOfDay(time - 8640000);
}

function getDayHours(time) {
  const offset = new Date(time).getTimezoneOffset() * 60 * 1000;
  const utcTime = time + offset;
  return parseInt((time - startOfDay(utcTime)) / 1000, 10);
}

function hoursFromSeconds(seconds) {
  const hour = Math.floor(parseInt(seconds / 3600, 10));
  const minutes = Math.floor((seconds - hour * 3600) / 60);

  return leftPad(hour, 2) + ':' + leftPad(minutes, 2, 10);
}
function secondsToHour(seconds) {
  let date = new Date(null);
  date.setSeconds(seconds);
  return date.toISOString().substr(11, 5);
}
function getCurrentTimeAsSeconds() {
  const current = new Date();
  return current.getSeconds() + 60 * current.getMinutes() + 60 * 60 * current.getHours();
}
function getCurrentDayName() {
  const current = new Date();
  return current.toDateString().substring(0, 3);
}
function getWeeksFromFirstDay(day) {
  const fromDay = startOfDay(firstWeekDay());
  const difference = day - fromDay;
  const week = difference / (86400 * 1000 * 7);
  return parseInt(week, 10);
}

function getMonthsFromFirstDay(day) {
  try {
    const months = Number(moment.utc(day).diff(moment.utc(), 'months', true));
    if (isNaN(months) || months <= 0) {
      return 1;
    }

    return Math.ceil(months) + 1;
  } catch (e) {
    return 1;
  }
}

function startOfDay(day = false) {
  return (day ? moment.utc(day) : moment.utc()).startOf('day').valueOf();
}

function getDayInfo(day) {
  const theDay = moment.utc(day); //makeToUTC(day);
  return {
    dd: theDay.date(),
    mm: theDay.month(),
    realMM: theDay.month() + 1,
    yyyy: theDay.year(),
    weekDay: theDay.day(),
    timestamp: moment(day).valueOf(),
  };
}

function addTimezone(timestamp) {
  var offset = new Date().getTimezoneOffset();
  return timestamp - offset * 60;
}

function makeToUTC(day) {
  const theDay = new Date(day);

  return new Date(
    Date.UTC(theDay.getFullYear(), theDay.getMonth(), theDay.getDate(), theDay.getHours(), theDay.getMinutes()),
  );
}

function getFirstDayOfMonth(timestamp) {
  if (!timestamp) {
    timestamp = moment().valueOf();
  }
  return moment.utc(timestamp).clone().startOf('month').startOf('day').valueOf();
}

function getLastDayOfMonth(timestamp) {
  if (!timestamp) {
    timestamp = moment().valueOf();
  }
  return moment.utc(timestamp).clone().endOf('month').startOf('day').valueOf();
}

function getYYYYMMDD(day) {
  const theDay = makeToUTC(day);
  return theDay.toISOString().explode('T')[0];
}

function firstWeekDay(timestamp = false) {
  return getMondayOfCurrentWeek(timestamp).valueOf();
}

function lastWeekDay(timestamp = false) {
  return getSundayOfCurrentWeek(timestamp).valueOf();
}

function leftPad(number, targetLength) {
  var output = number + '';
  while (output.length < targetLength) {
    output = '0' + output;
  }
  return output;
}

function rightPad(number, targetLength) {
  var output = number + '';
  while (output.length < targetLength) {
    output = output + '0';
  }
  return output;
}

function getMondayOfCurrentWeek(timestamp) {
  if (!timestamp) {
    timestamp = moment().valueOf();
  }
  return moment.utc(parseInt(timestamp, 10)).clone().startOf('isoWeek').startOf('day');
}

function getSundayOfCurrentWeek(timestamp) {
  if (!timestamp) {
    timestamp = moment().valueOf();
  }
  return moment.utc(parseInt(timestamp, 10)).clone().endOf('isoWeek').startOf('day');
}

function getCalendarWeek(timestamp) {
  const firstDay = getMondayOfCurrentWeek(timestamp);
  const lastDay = getSundayOfCurrentWeek(timestamp);
  let firstYear = '';
  if (firstDay.year() !== moment().year()) {
    firstYear = ' ' + firstDay.year();
  }

  if (firstDay.month() === lastDay.month() && firstDay.year() === lastDay.year()) {
    return (
      leftPad(firstDay.date()) +
      ' - ' +
      leftPad(lastDay.date()) +
      ' ' +
      config.monthsShort[firstDay.month()].toLowerCase() +
      firstYear
    );
  } else {
    let lastYear = '';
    if (lastDay.year() !== moment().year()) {
      lastYear = ' ' + lastDay.year();
    }

    if (firstYear !== lastYear && firstYear === '') {
      firstYear = ' ' + firstDay.year();
    }

    return (
      leftPad(firstDay.date()) +
      ' ' +
      config.monthsShort[firstDay.month()].toLowerCase() +
      firstYear +
      ' - ' +
      leftPad(lastDay.date()) +
      ' ' +
      config.monthsShort[lastDay.month()].toLowerCase() +
      lastYear
    );
  }
}

/**
 * Get integer number of days until the supplied Date
 * @param {Date} dateTime
 * @returns {string} number of days as string
 */
function getDaysUntilDateTime(dateTime) {
  const startOfToday = moment().startOf('day');
  const startOfDateTimeDay = moment(dateTime).startOf('day');
  return startOfDateTimeDay.diff(startOfToday, 'days').toFixed(0);
}

function getDaysUntil(timestamp) {
  var date = moment(timestamp);
  var now = moment();
  return date.diff(now, 'days').toFixed(0);
}

function getPlaceTsOffset(ts, placeTz) {
  const d = new Date(ts);
  const utc = d.toLocaleString('en-US', { timeZone: 'UTC' });
  const salonTime = d.toLocaleString('en-US', { timeZone: placeTz });

  return new Date(utc).getTime() - new Date(salonTime).getTime();
}

function fromPlaceTs(ts, placeTz) {
  return new Date(ts + getPlaceTsOffset(ts, placeTz)).getTime();
}

function toPlaceTs(ts, placeTz) {
  return ts - getPlaceTsOffset(ts, placeTz);
}

function getSalonDate(start, timezone) {
  const salonTime = moment.unix(start).tz(timezone || 'Europe/Stockholm');
  return moment.unix(start - salonTime.utcOffset() * 60).tz(timezone || 'Europe/Stockholm'); // utc->stockholm
}

function makeReadableDay(timestamp, timezone) {
  const date = getSalonDate(parseInt(timestamp / 1000, 10), timezone);
  if (date.isValid()) {
    const weekDay = config.weekDaysLong[date.day()];
    const day = date.date();
    const month = config.monthsLong[date.month()];
    const year = date.year() !== moment().year() ? ' ' + date.year() : '';

    return weekDay + ', ' + day + ' ' + month.toLowerCase() + year;
  }

  return '';
}

function makeReadableDayShort(timestamp, timezone) {
  const date = getSalonDate(parseInt(timestamp / 1000, 10), timezone);
  if (date.isValid()) {
    const day = date.date();
    const month = config.monthsShort[date.month()];
    const year = date.year() !== moment().year() ? ' ' + date.year() : '';
    return day + ' ' + month.toLowerCase() + year;
  }

  return '';
}

function hasNotPasted(timestamp, timezone) {
  const salonTime = moment.tz(timezone || 'Europe/Stockholm');
  const deadline = moment.unix(parseInt(timestamp / 1000, 10) - salonTime.utcOffset() * 60);
  return deadline.isValid() && salonTime.isBefore(deadline);
}

/* duration is in seconds */
function getHoursInterval(timestamp, duration, timezone) {
  const date = getSalonDate(parseInt(timestamp / 1000, 10), timezone);

  const getHours = (d) => {
    return d.format('HH:mm');
  };

  const hours = getHours(date);
  if (duration && duration > 0) {
    return hours + ' - ' + getHours(date.clone().add(duration, 'seconds'));
  }

  return hours;
}

/*
    if today - “Tid fr. 09:00, idag“
    if tomorrow - “Tid fr. 09:00, imorgon“
    otherwise -  “Tid fr. 18:15, sön 7 juni“ (use the same date formatting as in the time filter selector)
    date Fre 9 aug.
    jan. feb. mars apr. maj juni juli aug. sep. okt. nov. dec.
  */
function getDateFormattedForFirstAvailableTime(availability, timezone) {
  moment.locale('sv');
  moment.updateLocale('sv', {
    monthsShort: ['jan.', 'feb.', 'mars', 'apr.', 'maj', 'juni', 'juli', 'aug.', 'sep.', 'okt.', 'nov.', 'dec.'],
  });
  const referenceDate = moment(availability).tz(timezone || 'Europe/Stockholm');
  if (moment(referenceDate).isSame(moment(), 'day')) {
    return referenceDate.format('[Tid fr.] HH.mm, [idag]');
  } else if (moment(referenceDate).isSame(moment().add(1, 'day'), 'day')) {
    return referenceDate.format('[Tid fr.] HH.mm, [imorgon]');
  } else {
    return referenceDate.format('[Tid fr.] HH.mm, ddd D MMM');
  }
}
function getWellnessCardExpiryDateFromToday(format = 'YYYY-MM-DD') {
  const julyNextYear = moment.utc().set({ year: moment.utc().year() + 1, month: 5, date: 30 }); //30 June
  const oneYear = getGeneralRuleGiftCardExpiryDate();
  return (oneYear < julyNextYear ? oneYear : julyNextYear).format(format);
}

function getGeneralRuleGiftCardExpiryDate() {
  return moment.utc().add(1, 'Y').add(2, 'd');
}

function getGiftCardExpiryDateFromToday(days) {
  if (!days || !parseInt(days, 10)) {
    return getGeneralRuleGiftCardExpiryDate().format('YYYY-MM-DD');
  }

  return moment.utc().add(parseInt(days, 10), 'd').format('YYYY-MM-DD');
}
