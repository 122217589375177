import { bookActions } from '@/actions';
import LoadingPlaceHolder from '@/components/elements/LoadingPlaceholder';
import PageViewLayout from '@/components/layouts/PageViewLayout/PageViewLayout';
import CardWrapper from '@/components/modules/CardWrapper';
import SEO, { seoPropsFromBaseString } from '@/components/modules/SEO';
import BookingInfo, { CheckoutDetailsProps } from '@/components/modules/checkout/CheckoutDetails/CheckoutDetails';
import KlarnaCheckout from '@/components/modules/checkout/KlarnaCheckout/KlarnaCheckout';
import GoBack from '@/components/modules/pages/bokningar/GoBack';
import { CHECKOUT_PAYMENT_METHOD } from '@/constants/checkout';
import { isSistaminuten, url } from '@/helpers';
import {
  getBookingCheckoutEmployee,
  getBookingCheckoutSummary,
  mapBookStateToCheckoutDetailsServices,
  mapBookingCheckoutToCheckoutDetailsProps,
} from '@/helpers/checkout';
import withPlaceDetailsFallback from '@/hoc/withPlaceDetailsFallback';
import { useAppSelector } from '@/hooks';
import useMobileView from '@/hooks/useMobileView';
import useTrackScreenView from '@/hooks/useTrackScreenView';
import { _s } from '@/locale';
import { InitKlarnaResponse, initKlarnaResponseSchema } from '@/types/api/services/booking';
import { BookState, bookStateSchema } from '@/types/state/book';
import { useHistory, useLocation } from 'react-router-dom';
import useKlarnaBookingCheckoutManager from './KlarnaBookingCheckout.hooks';

const baseTranslationKey = 'pages.booking.checkout.KlarnaBookingCheckout';

const KlarnaBookingCheckout = ({ booking, klarnaOrder }: { booking: BookState; klarnaOrder: InitKlarnaResponse }) => {
  const location = useLocation();
  const history = useHistory();
  const employee = getBookingCheckoutEmployee(location.state, booking);
  const selectedCapacity = booking.time?.selectedCapacity || 1;
  const summary = getBookingCheckoutSummary({ booking, employee, selectedCapacity });
  const { klarnaBookingCheckoutAPI, status } = useKlarnaBookingCheckoutManager({
    employeeId: employee.actual.id,
    klarnaOrder,
  });
  const { isMobileView } = useMobileView();
  const bookingTrackingProps = bookActions.getTrackingProps();

  const services = mapBookStateToCheckoutDetailsServices(booking, employee.shown);
  const { giftcards, payLater, ...rest } = mapBookingCheckoutToCheckoutDetailsProps(
    summary,
    { type: CHECKOUT_PAYMENT_METHOD.KLARNA },
    employee.shown,
    booking,
  );

  const bookingInfoProps: CheckoutDetailsProps = {
    ...rest,
    payLater,
    services,
    giftcards: giftcards.map((giftcard) => ({
      ...giftcard,
    })),
  };

  const handleOnBackButtonClick = () => {
    history.replace({
      pathname: '/booking/checkout',
      state: { savedCheckoutState: location.state?.savedCheckoutState, employee: employee.actual },
    });
  };

  useTrackScreenView({
    name: 'screen_view_checkout_booking_klarna_summary',
    properties: {
      ...bookingTrackingProps.bookingProps,
      ...bookingTrackingProps.paymentMethodsProps,
      ...bookingTrackingProps.extraProps,
    },
  });

  return (
    <PageViewLayout
      type="subView"
      title={_s(`${baseTranslationKey}.page-title`)}
      back
      onBackButtonClick={handleOnBackButtonClick}
      wrapperClass={isMobileView ? 'bg-brown-50' : 'bg-gradient'}>
      {status === 'SUBMITTING' && <LoadingPlaceHolder className="fixed h-screen" />}
      <div className="bg-brown-50 lg:bg-transparent">
        <div className="lg:py-xxl lg:container">
          <div className="gap-xxl flex items-start">
            <div className="gap-lg flex grow-[9999] basis-[600px] flex-col">
              <CardWrapper className="w-full lg:hidden">
                <BookingInfo {...bookingInfoProps} />
              </CardWrapper>
              <CardWrapper>
                <div className="p-lg">
                  <KlarnaCheckout
                    clientToken={klarnaOrder.clientToken}
                    orderLines={klarnaOrder.orderLines}
                    handleCreateOrder={klarnaBookingCheckoutAPI().onSubmitKlarna}
                  />
                </div>
              </CardWrapper>
            </div>
            <div className="sticky top-20 hidden lg:flex lg:grow-[100] lg:basis-[390px]">
              <CardWrapper className="w-full">
                <BookingInfo key={2} {...bookingInfoProps} />
              </CardWrapper>
            </div>
          </div>
        </div>
      </div>

      <SEO
        {...seoPropsFromBaseString('klarna', `${url.getBaseUrl()}booking/checkout/klarna`)}
        noindex={isSistaminuten()}
        image={url.getBaseImageUrl()}
      />
    </PageViewLayout>
  );
};

export default withPlaceDetailsFallback(() => {
  const location = useLocation();
  const bookState = useAppSelector((state) => state.book);
  const parsedBookState = bookStateSchema.safeParse(bookState);
  const parsedKlarnaOrder = initKlarnaResponseSchema.safeParse(location?.state?.klarnaOrder);

  if (!parsedBookState.success) return <GoBack />;

  const { saved, services, time, place } = parsedBookState.data;
  const invalidState =
    !parsedKlarnaOrder.success || saved || !services || !services.length || !place || !time || !time.timestamp;

  if (invalidState) return <GoBack />;

  return <KlarnaBookingCheckout booking={parsedBookState.data} klarnaOrder={parsedKlarnaOrder.data} />;
});
