import { countdownSchema } from '@/components/elements/Countdown/Countdown';
import { z } from 'zod';

const bannerTypeSchema = z.enum(['type_1', 'type_2', 'type_3', 'type_4', 'type_5']);

export type BannerType = z.infer<typeof bannerTypeSchema>;

export const bannerCountdownSchema = countdownSchema.extend({
  countdown_color: z.string().optional(),
  countdown_description_color: z.string().optional(),
});

export const bannerConfigSchema = z.object({
  type: bannerTypeSchema,
  active: z.boolean().optional(),
  title: z.string().optional(),
  message: z.string().optional(),
  image_url: z.string().optional(),
  image_url_xs: z.string().optional(),
  image_url_large: z.string().optional(),
  background_color: z.string().optional(),
  background_image_sm: z.string().optional(),
  background_image_md: z.string().optional(),
  background_image_lg: z.string().optional(),
  background_image_height_reference: z.number().optional(),
  background_image_width_reference: z.number().optional(),
  text_color: z.string().optional(),
  text_link_color: z.string().optional(),
  countdownData: bannerCountdownSchema.optional(),
  background_alt_text: z.string().optional(),
  cta: z
    .object({
      title: z.string(),
      url: z.string(),
      amplitude_event: z.string().optional(),
      variant: z.enum(['primary', 'secondary', 'tertiary', 'link']).optional(),
    })
    .optional(),
});

export type BannerConfig = z.infer<typeof bannerConfigSchema>;
