import { h2Class } from '@/components/elements/content';
import { blog, isMobile } from '@/helpers';
import LoadingPosts from './LoadingPosts';
import css from './PostContent.module.css';

const PostContent = ({ post }) => {
  if (!post) return <LoadingPosts />;

  const content = post.content.rendered.replace(/srcset=".*?\/>/g, '').replace(/(width|height)="\d+"/g, '');

  return (
    <>
      <h1
        className={`${h2Class} text-secondary-500 !my-4`}
        dangerouslySetInnerHTML={{ __html: post.title.rendered }}></h1>
      <div className="my-6">{blog.getPostDateTime(post)}</div>
      <img
        src={blog.getImage(post.featured_media.source_url, isMobile() ? '375x' : '1200x')}
        alt={post.featured_media.alt_text}
        className="mb-5 max-h-96 w-full object-cover"
      />
      <div dangerouslySetInnerHTML={{ __html: content }} className={css.blogContent}></div>
    </>
  );
};

export default PostContent;
