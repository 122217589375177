import { HTMLAttributes } from 'react';

type Props = HTMLAttributes<HTMLDivElement>;

const SideColumnLayout = ({ children, ...props }: Props) => (
  <div className="container">
    <div className="flex flex-wrap justify-between gap-6 lg:flex-nowrap" {...props}>
      {children}
    </div>
  </div>
);

export const MainColumn = ({ children, ...props }: Props) => <div className="flex-1">{children}</div>;
export const SideColumn = ({ children, ...props }: Props) => (
  <div className="w-full lg:max-w-[330px] xl:max-w-[430px]" {...props}>
    {children}
  </div>
);

export default SideColumnLayout;
