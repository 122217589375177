import { server } from '@/helpers';
import { Association, Associations } from '@/types/api/services/associationPages';

export async function getAssociations(): Promise<Associations> {
  return server.request.get(`/associations`).then(server.handleSuccess).catch(server.handleErrorObject);
}

export async function getAssociation(slug: string): Promise<Association> {
  return server.request.get(`/associations/${slug}`).then(server.handleSuccess).catch(server.handleErrorObject);
}
