import { LogoIcon } from '@/components/icons';
import SistaMinutenLogoIcon from '@/components/icons/SistaMinutenLogoIcon';
import { isMobile, isSistaminuten } from '@/helpers';
import { ReactNode } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { FooterBottom } from './FooterBottom';

const FooterNavigationContainer = ({ children }: { children: ReactNode }) => {
  const match = useRouteMatch();
  const isListPage = match.path === '/:q/:location';
  return (
    <footer
      className={`${isListPage ? 'list' : ''} bg-brown-50 px-4 2xl:px-10 ${
        isSistaminuten() ? 'py-12' : 'py-6 sm:py-10'
      }`}>
      <div className={`${!isListPage && isSistaminuten() ? 'container' : 'container-fluid'}`}>
        <div
          className={`flex flex-col gap-4 ${isListPage && !isMobile() ? 'w-7/12 pr-6' : ''} ${
            isListPage && isMobile() ? 'lg:w-7/12 lg:pr-6' : ''
          }`}>
          <div className="flex-1 pb-4">
            <Link to="/">
              {isSistaminuten() ? <SistaMinutenLogoIcon className="h-6" /> : <LogoIcon className="h-[22px]" />}
            </Link>
          </div>
          <div className={`${isListPage && !isMobile() ? 'flex flex-col flex-wrap' : ''} `}>
            <div
              className={`flex flex-col gap-8 md:flex-row md:flex-wrap ${isListPage ? '' : 'md:gap-0'} ${
                isSistaminuten() ? 'md:gap-x-20' : 'md:justify-between'
              }`}>
              {children}
            </div>
          </div>
          <FooterBottom isListPage={isListPage} />
        </div>
      </div>
    </footer>
  );
};

export default FooterNavigationContainer;
