import { loadingActions } from '@/actions';
import { server } from '@/helpers';
import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

class ReviewRedirectPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = { url: null };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(loadingActions.show());

    const queryParams = new URLSearchParams(this.props.location.search);
    const token = queryParams.get('token');

    server.request
      .get('/reviews-redirect/' + token)
      .then((response) => {
        this.setState({ url: response.data.url });
        dispatch(loadingActions.hide());
      })
      .catch((error) => {
        dispatch(loadingActions.hide());
      });
  }

  render() {
    if (this.state.url) {
      return <Redirect to={this.state.url} />;
    }

    return null;
  }
}

function mapStateToProps(state) {
  return {};
}

const ReviewRedirect = connect(mapStateToProps)(ReviewRedirectPage);
export default ReviewRedirect;
