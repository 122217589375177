import { useEffect, useRef, useState } from 'react';
import { server } from '.';

function getLatestPosts() {
  return server.request.get('/blog/latest-posts').then(server.handleSuccess).catch(server.handleError);
}
function getFeaturedPost() {
  return server.request.get('/blog/featured-post').then(server.handleSuccess).catch(server.handleError);
}
function getPost(id, slug) {
  return server.request
    .get('/blog/get-post', { params: { id, slug } })
    .then(server.handleSuccess)
    .catch(server.handleError);
}
function getPage(id) {
  return server.request.get('/blog/get-page', { params: { id } }).then(server.handleSuccess).catch(server.handleError);
}
function getList(page) {
  return server.request
    .get('/blog/get-list', { params: { page } })
    .then(server.handleSuccess)
    .catch(server.handleError);
}

function getCategoryList(slug, page) {
  return server.request
    .get('/blog/get-category-list', { params: { page, slug } })
    .then(server.handleSuccess)
    .catch(server.handleError);
}

function getAuthorList(slug, page) {
  return server.request
    .get('/blog/get-author-list', { params: { page, slug } })
    .then(server.handleSuccess)
    .catch(server.handleError);
}

function getImage(url, size = '375x') {
  if (!url) return null; // return default image ?

  if (url.indexOf('cdn.bokadirekt.se') === -1) {
    return `https://2396acc5f7f7db6b1764.ucr.io/-/resize/${size}/${url}`;
  } else {
    return `${url}/-/resize/${size}`;
  }
}

const dateOptions: Intl.DateTimeFormatOptions = {
  weekday: 'long',
  year: 'numeric',
  month: 'long',
  day: 'numeric',
};

function getPostDate(post, options: Intl.DateTimeFormatOptions = dateOptions) {
  const formattedDate = new Date(post.date).toLocaleString('sv-SE', options);
  return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);
}

function getPostDateTime(post) {
  return getPostDate(post, { ...dateOptions, hour: 'numeric', minute: 'numeric' });
}

export const useBlogData = (preloadedData, fetchData) => {
  const [data, setData] = useState(preloadedData || null);
  const mounted = useRef(false);

  useEffect(() => {
    if (mounted.current) {
      return data !== preloadedData && setData(preloadedData);
    }

    if (!preloadedData) {
      fetchData();
    } else {
      setData(preloadedData);
    }
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, [preloadedData, fetchData]);

  return data;
};

export const usePaginatedBlogData = (preloadedData, fetchData, fetchParams = null) => {
  // const [data, setData] = useState(preloadedData || null);
};

export const extractSlugAndPage = (url: string) => {
  const parts = url
    .split('/')
    .filter((part) => part)
    .slice(-2);
  if (isNaN(parseInt(parts[parts.length - 1]))) return { category: parts[parts.length - 1], page: 1 };

  return {
    category: parts[parts.length - 2],
    page: parts[parts.length - 1],
  };
};

export const blog = {
  getLatestPosts,
  getPost,
  getList,
  getCategoryList,
  getAuthorList,
  getFeaturedPost,
  getImage,
  getPage,
  getPostDate,
  getPostDateTime,
};
