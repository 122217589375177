import { isSistaminuten, server } from '../helpers';
// import { config } from '../config/index';

/**
 * @deprecated - use bookServicesTs instead
 */

export const bookServices = {
  getAvailability,
  cancelByCode,
  appointment,
  confirmKlarna,
  appointments,
  qliroPayment,
  klarnaPayment,
  getWeekDays,
  getIntervalDays,
  cancelAppointment,
  validateGiftCard,
  confirmQliro,
  removeGiftCard,
  getByOrderId,
  finishPayment,
  getBookingCategory,
  getListViewSchedule,
  cofPayment,
  confirmCofPayment,
  getSistaminutenBooking,
  getBookingByCode,
  hasDynamicPrice,
};

/**
 * @param {string[]} services - array of servive ids
 * @param {string} date - date in unix timestamp
 * @param {string} employee  - employee id
 * @param {number} [bookingId] - bookingId of booking that user wants to "change time" on
 * @returns
 */
function getAvailability(services, date, employee, bookingId) {
  const serviceIds = services.map((service) => service.id);
  return server.request
    .get(
      '/book/' +
        serviceIds.join('-') +
        '/' +
        services[0].place +
        '/' +
        date +
        '/' +
        employee +
        (bookingId ? `/${bookingId}` : '') +
        '?reborn=true' +
        (isSistaminuten() ? '&isSistaminutentider=true' : ''),
      {},
    )
    .then(server.handleSuccess)
    .catch(server.handleError);
}
function getIntervalDays(services, start, end, employee) {
  const serviceIds = services.map((service) => service.id);
  return server.request
    .get(
      '/interval-availability/' +
        serviceIds.join('-') +
        '/' +
        services[0].place +
        '/' +
        start +
        '/' +
        end +
        '/' +
        (employee || 0) +
        '?reborn=true',
      {},
    )
    .then(server.handleSuccess)
    .catch(server.handleError);
}
function getWeekDays(services, date, employee, firstAvailableDate, bookingId) {
  const serviceIds = services.map((service) => service.id);
  return server.request
    .get(
      '/availability/' +
        serviceIds.join('-') +
        '/' +
        services[0].place +
        '/' +
        date +
        '/' +
        (employee || 0) +
        (firstAvailableDate ? `/${firstAvailableDate}` : '') +
        '?reborn=true' +
        (bookingId ? `&bookingId=${bookingId}` : ''),
      {},
    )
    .then(server.handleSuccess)
    .catch(server.handleError);
}
function getByOrderId(orderId, salonId) {
  return server.request
    .get('/orderid/' + orderId + '/' + salonId)
    .then(server.handleSuccess)
    .catch(server.handleError);
}
function cancelByCode(code, placeId) {
  const place = placeId ? `/${placeId}` : '';
  return server.request.get(`/cancel-by-code/${code}${place}`, {}).then(server.handleSuccess).catch(server.handleError);
}

/**
 *
 * @param {import('@/types/api/services/booking').InitKlarnaPaymentRequest} params
 * @returns {import('@/types/api/services/booking').InitKlarnaPaymentResponse}
 * @throws {import('@/types/api/services/schema').ErrorResponse}
 */
function klarnaPayment(params) {
  return server.request.post('/klarna/payments', params).then(server.handleSuccess).catch(server.handleError);
}

/**
 *
 * @param {import('@/types/api/services/booking').InitQliroPaymentRequest} params
 * @returns  {import('@/types/api/services/booking').InitQliroPaymentResponse}
 * @throws {import('@/types/api/services/schema').ErrorResponse}
 */
function qliroPayment(params) {
  return server.request.post('/qliro/create', params).then(server.handleSuccess).catch(server.handleError);
}

function appointments(params) {
  return server.request.get('/appointments', { params }).then(server.handleSuccess).catch(server.handleError);
}

/**
 *
 * @param {number} id
 * @returns {Promise<import('@/types/api/services/booking').AppointmentResponse>}
 */
function appointment(id) {
  return server.request
    .get('/appointment/' + id, {})
    .then(server.handleSuccess)
    .catch(server.handleError);
}
function getBookingByCode(code) {
  return server.request
    .get('/booking-by-code/' + code, {})
    .then(server.handleSuccess)
    .catch(server.handleError);
}
function getSistaminutenBooking(id) {
  return server.request
    .get('/last-minute-booking/' + id, {})
    .then(server.handleSuccess)
    .catch(server.handleError);
}
function confirmKlarna(klarnaID, sessionID) {
  return server.request
    .post('/klarna/payments/complete', { klarnaID, sessionID })
    .then(server.handleSuccess)
    .catch(server.handleError);
}

function confirmQliro(id) {
  return server.request
    .get('/qliro/confirmed?qliro_ref=' + id, {})
    .then(server.handleSuccess)
    .catch(server.handleError);
}
function cancelAppointment(id) {
  return server.request
    .get('/appointments/cancel/' + id, {})
    .then(server.handleSuccess)
    .catch(server.handleError);
}

function finishPayment(id) {
  return server.request
    .post('/klarnapayments/init/' + id)
    .then(server.handleSuccess)
    .catch(server.handleError);
}

function validateGiftCard(params) {
  const validateEndpoint = params.hasOwnProperty('amount') ? '/giftcards/itsperfect/validate' : '/giftcards/validate';
  return server.request.post(validateEndpoint, params).then(server.handleSuccess).catch(server.handleError);
}

function removeGiftCard(params) {
  return server.request.post('/giftcards/remove', params).then(server.handleSuccess).catch(server.handleError);
}

function getBookingCategory(productId) {
  return server.request
    .get('/product/' + productId, {})
    .then(server.handleSuccess)
    .catch(server.handleError);
}

function getListViewSchedule(placeId, from) {
  return server.request
    .get('/listview/' + placeId + '/' + from, {})
    .then(server.handleSuccess)
    .catch(server.handleError);
}

/**
 * @deprecated
 */
function cofPayment(body) {
  return server.request.post('/v1/adyen/orders', body).then(server.handleSuccess).catch(server.handleError);
}

/**
 * @deprecated
 */
function confirmCofPayment(id) {
  return server.request
    .get('/v1/adyen/confirmed?bookingId=' + id, {})
    .then(server.handleSuccess)
    .catch(server.handleError);
}

function hasDynamicPrice(placeId, date) {
  return server.request
    .get(`/book/dynamicPrice/${placeId}/${date}`, {})
    .then(server.handleSuccess)
    .catch(server.handleError);
}
