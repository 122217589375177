import { config } from '@/config';
import { useEffect, useState } from 'react';
import useExternalScript from './useExternalScript';

const environment = config.env === 'production' || config.env === 'staging' ? 'PRODUCTION' : 'TEST';

const TIMEOUT_TIMER = 5000;

const useWalletPayment = () => {
  const [hasActiveApplePayPaymentMethod, setHasActiveApplePayPaymentMethod] = useState(false);
  const [hasActiveGooglePayPaymentMethod, setHasActiveGooglePayPaymentMethod] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [scriptLoaded] = useExternalScript({ src: 'https://pay.google.com/gp/p/js/pay.js', cleanup: false });

  useEffect(() => {
    if (!scriptLoaded) return;
    const promises = [];
    if (window?.google?.payments) {
      promises.push(setupGooglePay());
    }
    if (window?.ApplePaySession) {
      // throws an error when not in https
      try {
        if (ApplePaySession.canMakePayments()) {
          promises.push(setupApplePay());
        }
      } catch (error) {}
    }

    Promise.all(promises)
      .catch((error) => console.error(error))
      .finally(() => setIsLoading(false));
  }, [scriptLoaded]);

  function setupApplePay(): Promise<void> {
    return new Promise((resolve, reject) => {
      const timeout = setTimeout(() => {
        reject(new Error('Apple Pay setup timed out.'));
      }, TIMEOUT_TIMER);
      const cachedCanMakeApplePayment = sessionStorage.getItem('canMakeApplePayment');
      if (cachedCanMakeApplePayment) {
        clearTimeout(timeout);
        setHasActiveApplePayPaymentMethod(cachedCanMakeApplePayment === 'true');
        resolve();
        return;
      }

      ApplePaySession.canMakePaymentsWithActiveCard(config.applepay.merchantIdentifier)
        .then((canMakePaymentsWithActiveCard) => {
          clearTimeout(timeout);
          setHasActiveApplePayPaymentMethod(canMakePaymentsWithActiveCard);
          sessionStorage.setItem('canMakeApplePayment', `${canMakePaymentsWithActiveCard}`);
          resolve();
        })
        .catch((error) => {
          clearTimeout(timeout);
          reject(error);
        });
    });
  }

  function setupGooglePay(): Promise<void> {
    return new Promise((resolve, reject) => {
      const timeout = setTimeout(() => {
        reject(new Error('Google Pay setup timed out.'));
      }, TIMEOUT_TIMER);

      const cachedCanMakeGooglePayment = sessionStorage.getItem('canMakeGooglePayment');
      if (cachedCanMakeGooglePayment) {
        clearTimeout(timeout);
        setHasActiveGooglePayPaymentMethod(cachedCanMakeGooglePayment === 'true');
        resolve();
        return;
      }

      const paymentsClient = new window.google.payments.api.PaymentsClient({
        environment,
        merchantInfo: { merchantId: config.googlepay.merchantId },
      });

      paymentsClient
        .isReadyToPay({
          apiVersion: 2,
          apiVersionMinor: 0,
          allowedPaymentMethods: [
            {
              type: 'CARD',
              parameters: {
                allowedAuthMethods: ['PAN_ONLY'],
                allowedCardNetworks: ['AMEX', 'DISCOVER', 'MASTERCARD', 'VISA'],
              },
            },
          ],
          existingPaymentMethodRequired: true,
        })
        .then((response) => {
          clearTimeout(timeout);
          setHasActiveGooglePayPaymentMethod(response.paymentMethodPresent);
          sessionStorage.setItem('canMakeGooglePayment', `${response.paymentMethodPresent}`);
          resolve();
        })
        .catch((error) => {
          clearTimeout(timeout);
          reject(error);
        });
    });
  }

  return { hasActiveApplePayPaymentMethod, hasActiveGooglePayPaymentMethod, isLoading };
};

export default useWalletPayment;
