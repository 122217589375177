import { Card, CardImageHeader, CardShadow } from '@/components/elements/cards';
import { Link, h4Class } from '@/components/elements/content';
import { CheckIcon } from '@/components/icons';
import { _a, _s } from '@/locale';
import { _sTitle } from '@/locale/i18n';

/**
 * @typedef {object} InfoColumnProps
 * @prop {string} [description]
 * @prop {string} [placeName]
 * @prop {string} base
 *
 * @param {InfoColumnProps} param0
 */
const InfoColumn = ({ description, placeName, base }) => (
  <div className="sticky mb-6">
    <CardShadow>
      <CardImageHeader src="/images/giftcardsbuy-standard.png" />
      <Card noShadow attachedTop>
        {_a('buyValueCard.info.checks').map((check, i) => (
          <div key={i}>
            <div className="flex items-center justify-start">
              <CheckIcon className="color-primary mr-2 w-3" /> <strong>{check.title}</strong>
            </div>
            <div className="color-black-600 mb-3 ml-6">{check.desc}</div>
          </div>
        ))}
        <p className="color-black-600 my-3 text-sm">{_s(`${base}.smallText`)}</p>
        <h3 className={h4Class}>{_sTitle(`${base}.aboutPlace`, { name: placeName })}</h3>
        <p>{description}</p>
      </Card>
    </CardShadow>
    <div className="flex justify-center">
      <div>
        <span>{_s(`${base}.use`)}</span>{' '}
        <Link to="/giftcards" className="!no-underline">
          {' '}
          {_s(`${base}.useLink`)}
        </Link>
      </div>
    </div>
  </div>
);

export default InfoColumn;
