import LoadingSpinner from '@/components/animations/LoadingSpinner/LoadingSpinner';
import { Button } from '@/components/elements/forms/buttons';
import PinInput, { UsePinInputResult } from '@/components/elements/forms/PinInput/PinInput';
import HR from '@/components/elements/HR/HR';
import { LoginContextType } from '@/hooks/useLogin';
import { _s } from '@/locale';
import { LoginTriggerSource } from '@/types/analytics';
import { useEffect, useState } from 'react';

type VerifyOTPProps = UsePinInputResult &
  Pick<LoginContextType, 'email' | 'handleEmailResendClicked'> & { source: LoginTriggerSource };

const translationKey = 'VerifyOTP';

let abortLoadingSpinner: boolean = false;

const VerifyOTP = ({
  email,
  pin,
  activePinIndex,
  error,
  clipError,
  submitting,
  pinContainerRef,
  inputRef,
  handleFocus,
  handlePaste,
  handleOnChange,
  handlePinChange,
  handleEmailResendClicked,
}: VerifyOTPProps) => {
  const [showLoadingSpinner, setShowLoadingSpinner] = useState(false);

  useEffect(() => {
    let timeout: NodeJS.Timeout | null = null;
    if (submitting) {
      abortLoadingSpinner = false;
      timeout = setTimeout(() => {
        if (abortLoadingSpinner) return;
        setShowLoadingSpinner(true);
      }, 300);
    } else {
      abortLoadingSpinner = true;
      timeout && clearTimeout(timeout);
      setShowLoadingSpinner(false);
    }

    return () => {
      timeout && clearTimeout(timeout);
    };
  }, [submitting]);

  return (
    <>
      <p className="text-black-600">
        {_s(`${translationKey}.checkInbox`)}
        <br /> {_s(`${translationKey}.instructions`)}
        <span className="text-black-900 break-words font-semibold">{email.value}</span>
      </p>
      {showLoadingSpinner && (
        <div className="py-lg relative flex flex-col items-center justify-center">
          <LoadingSpinner loop style={{ width: 80, height: 80 }} />
        </div>
      )}
      {!showLoadingSpinner && (
        <>
          <PinInput
            inputRef={inputRef}
            pinContainerRef={pinContainerRef}
            pin={pin}
            activePinIndex={activePinIndex}
            error={error}
            clipError={clipError}
            submitting={submitting}
            handleFocus={handleFocus}
            handlePaste={handlePaste}
            handlePinChange={handlePinChange}
            handleOnChange={handleOnChange}
          />
          <div className="space-y-lg flex flex-col">
            <HR verticalSpacing="0" />
            <div className="flex items-center justify-between">
              <p className="space-y-xxs flex flex-col">
                <span className="text-black-900 text-m">{_s(`${translationKey}.noEmail`)}</span>
                <span className="text-xxs text-black-600">{_s(`${translationKey}.delay`)}</span>
              </p>
              <Button className="flex-shrink-0" variant="secondary" onClick={handleEmailResendClicked}>
                {_s(`${translationKey}.resend`)}
              </Button>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default VerifyOTP;
