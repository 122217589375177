import BeautyLabIcon from '@/components/icons/BeautyLabIcon';

const bgImageStyles = {
  backgroundImage: 'url("/images/blogghero.jpg")',
};

const Hero = () => (
  <div className="h-[346px] bg-cover bg-bottom pb-2 pt-10 md:h-[734px] md:pb-9 md:pt-32" style={bgImageStyles}>
    <div className="container flex h-full flex-col justify-between">
      <div className="flex flex-col items-start justify-start">
        <BeautyLabIcon className="h-24 text-white md:h-52 lg:h-72" />
      </div>
    </div>
  </div>
);

export default Hero;
