import HeightExpandableArea from '@/components/elements/forms/HeightExpandableArea/HeightExpandableArea';
import { ChevronIcon } from '@/components/icons';
import { Question, Section } from '@/constants/faq';
import { useState } from 'react';
import { Link } from 'react-router-dom';

const itemHeaderClass =
  'h-16 flex justify-between items-center text-left w-full focus:outline-none focus-visible:outline-1 text-black-900';

const Item = ({ question, first }: { question: Question; first: boolean }) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const toggleExpanded = () => setExpanded(!expanded);
  return (
    <div className={`border-black-200 border-b  ${first ? 'border-t' : ''}`}>
      <button className={itemHeaderClass} onClick={toggleExpanded}>
        <h3 className="text-base font-semibold">{question.question}</h3>
        <div
          className={`inline-flex h-6 w-6 shrink-0 items-center justify-center ${
            expanded ? 'bg-black-300' : 'bg-primary'
          } rounded-full`}>
          <ChevronIcon className={`h-3 w-3 text-white transition-transform ${expanded ? 'rotate-180' : ''}`} />
        </div>
      </button>
      <HeightExpandableArea {...{ expanded }}>
        <div dangerouslySetInnerHTML={{ __html: question.answer }}></div>
        <div className="h-4"></div>
      </HeightExpandableArea>
    </div>
  );
};

const NavItem = ({ section, first }: { section: Section; first: boolean }) => (
  <div className={`border-black-200 border-b ${first ? 'border-t' : ''}`}>
    <Link className={itemHeaderClass} to={section.path}>
      <h3 className="text-base font-semibold">{section.title}</h3>
      <div className={`bg-primary inline-flex h-6 w-6 items-center justify-center rounded-full`}>
        <ChevronIcon className={`h-3 w-3 -rotate-90 text-white transition-transform`} />
      </div>
    </Link>
  </div>
);

const SectionItems = ({ section }: { section: Section }) => (
  <div className="mb-10 mt-6">
    {section.questions.map((question, i) => (
      <Item question={question} key={`${section.path}-${i}`} first={i === 0} />
    ))}
  </div>
);

export const SectionNav = ({ sections }: { sections: Section[] }) => (
  <div className="mb-10 mt-6">
    {sections.map((section, i) => (
      <NavItem section={section} first={i === 0} key={section.path} />
    ))}
  </div>
);

export default SectionItems;
