import Select from '@/components/elements/forms/Select/Select';
import Icon from '@/components/icons/Icon';
import { ReactHookFormRegister } from '@/components/modules/checkout/CheckoutFormFields/CheckoutFormFields';
import { classnames } from '@/helpers';
import { LegacyRef, SelectHTMLAttributes, forwardRef } from 'react';

type GenericListInputProps = {
  error?: string;
  label?: string;
  leftIcon?: React.ReactNode;
  children: React.ReactNode;
  leftPadding?: boolean;
};

type ListInputProps = SelectHTMLAttributes<HTMLSelectElement> & GenericListInputProps & ReactHookFormRegister<any>;

const ListInputSelect = forwardRef(
  (
    { error, label, leftIcon, id, children, disabled, leftPadding = true, ...props }: ListInputProps,
    ref: LegacyRef<HTMLSelectElement>,
  ) => {
    return (
      <div className={classnames(`${leftPadding ? 'pl-lg' : ''} flex flex-col`, disabled && 'opacity-40')}>
        {label && (
          <label htmlFor={id} className="text-black-900 pt-md text-m">
            {label}
          </label>
        )}
        <div className={`pr-lg relative flex border-b ${error ? 'border-danger-500' : 'border-black-100'}`}>
          {leftIcon && <div className="pr-md flex shrink-0 grow-0">{leftIcon}</div>}
          <Select id={id} {...props} disabled={disabled} innerRef={ref}>
            {children}
          </Select>
          {error && (
            <div className="flex shrink-0 grow-0">
              <Icon variant="warning" color="danger-500" />
            </div>
          )}
        </div>
        {error && <div className="text-danger text-xxs mt-xxs flex justify-start">{error}</div>}
      </div>
    );
  },
);

export default ListInputSelect;
