import { capitalizeFirstLetter } from '@/helpers';
import { __ } from '@/locale';
import Parent from './Parent';

class Dropdown extends Parent {
  constructor(props) {
    super(props);
    this.state = { changed: false };
  }

  handleChange = (e) => {
    this.props.changeInput(e);
    this.setState({ changed: true });
  };

  handleBlur = (e) => {
    this.setState({ changed: true });
  };

  render() {
    const { data, submitted } = this.props;
    if (!data || !data.visible) {
      return null;
    }

    const hasError = (submitted || this.state.changed) && this.props.getError && this.props.getError(data.id);
    const placeholder = data.label ? data.label : __(capitalizeFirstLetter(data.id));

    return (
      <div className={`form-group field-${data.id} ${this.props.className} ${hasError ? ' has-error' : ''}`}>
        <label htmlFor={data.id}>{`${data.required ? '* ' : ''}${capitalizeFirstLetter(placeholder)}`}</label>
        <select
          value={data.value || ''}
          name={data.id}
          className={`form-control field-${data.id} ${hasError ? 'border-danger focus:border-danger' : ''}`}
          onChange={this.handleChange}
          onBlur={this.handleBlur}>
          {data.values && data.values[0] && (
            <option key={0} value={''}>
              {placeholder}
            </option>
          )}
          {data.values &&
            data.values.map((item, key) => {
              if (item.value || item.label) {
                return (
                  <option key={key} value={item.value}>
                    {item.label}
                  </option>
                );
              } else {
                if (item) {
                  return (
                    <option key={key} value={item}>
                      {item}
                    </option>
                  );
                } else {
                  // first option is empty show placeholder
                  return (
                    <option key={key} value={item}>
                      {placeholder}
                    </option>
                  );
                }
              }
            })}
        </select>
        {hasError && <div className="text-danger text-m">{__(hasError)}</div>}
      </div>
    );
  }
}

export default Dropdown;
