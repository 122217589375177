import { FilterTemplate } from '@/components/modules/pages/search';
import { SearchForm } from '@/components/modules/search';
import { trackPage } from '@/helpers';
import { _s } from '@/locale';
import { useEffect } from 'react';
import { connect } from 'react-redux';

const SearchContent = () => {
  return (
    <div className="container mx-auto">
      <div className="mt-2 p-0">
        <SearchForm source="mobile" />
      </div>
    </div>
  );
};

const SearchPage = () => {
  useEffect(() => {
    trackPage();
  }, []);

  return (
    <FilterTemplate rightButtonData={null} title={_s('search')}>
      <SearchContent />
    </FilterTemplate>
  );
};

function mapStateToProps(state) {
  const { places, params } = state.place;
  return {
    places,
    params,
  };
}

const MobileSearch = connect(mapStateToProps)(SearchPage);
export default MobileSearch;
