import { z } from 'zod';

export const associationSchema = z.object({
  m3Id: z.number(),
  slug: z.string(),
  name: z.string(),
  summary: z.string(),
  content: z.string(),
  readMoreUrl: z.string().optional(),
});

export type Association = z.infer<typeof associationSchema>;

export const associationsSchema = z.array(associationSchema);

export type Associations = z.infer<typeof associationsSchema>;
