import { bookActions } from '@/actions';
import { useAppSelector } from '@/hooks';
import { createContext, useContext, useReducer } from 'react';
import { useDispatch } from 'react-redux';

export type CheckoutModalScreen = 'list' | 'cof' | 'applyGiftcard' | 'applyValuecard' | 'applyWellnesscard';

export type CheckoutModalState = {
  isOpen: boolean;
  screen: CheckoutModalScreen;
};

type CheckoutModalAction =
  | {
      type: 'SHOW_MODAL';
      payload: CheckoutModalScreen | null;
    }
  | {
      type: 'CLOSE_MODAL';
    };

function checkoutModalReducer(state: CheckoutModalState, action: CheckoutModalAction) {
  switch (action.type) {
    case 'CLOSE_MODAL':
      return {
        ...state,
        isOpen: false,
      };

    case 'SHOW_MODAL': {
      return {
        isOpen: true,
        screen: action.payload,
      };
    }
    default:
      return state;
  }
}

export type UseCheckoutModalManagerResult = ReturnType<typeof useCheckoutModalManager>;

const useCheckoutModalManager = () => {
  const [state, dispatch] = useReducer(checkoutModalReducer, {
    isOpen: false,
    screen: 'list',
  });

  const globalDispatch = useDispatch();
  const { errorGiftCard } = useAppSelector((state) => state.book);

  const onModalBack = () => {
    const { screen } = state;
    if (screen === 'list') {
      onModalClose();
      return;
    }
    return dispatch({ type: 'SHOW_MODAL', payload: 'list' });
  };

  const onModalClose = () => {
    if (errorGiftCard) globalDispatch(bookActions.clearGiftCardMessages());
    dispatch({ type: 'CLOSE_MODAL' });
  };

  const onModalShow = (screen?: CheckoutModalScreen) => {
    dispatch({ type: 'SHOW_MODAL', payload: screen ?? 'list' });
  };

  return {
    isOpen: state.isOpen,
    screen: state.screen,
    onModalBack,
    onModalClose,
    onModalShow,
  };
};

const CheckoutModalContext = createContext<UseCheckoutModalManagerResult>({
  isOpen: false,
  screen: 'list',
  onModalBack: () => {},
  onModalClose: () => {},
  onModalShow: () => {},
});

export const useCheckoutModal = () => {
  const context = useContext(CheckoutModalContext);

  if (!context) {
    throw new Error('useCheckoutModal must be used within CheckoutModalProvider');
  }

  return context;
};

export const CheckoutModalProvider = ({ children }: { children: React.ReactNode }) => {
  return <CheckoutModalContext.Provider value={useCheckoutModalManager()}>{children}</CheckoutModalContext.Provider>;
};
