import { ReactNode } from 'react';
import BookingListItem, { BookingListItemProps } from '../../lists/BookingListItem/BookingListItem';
import Card from '../Card';

const BookingCard = ({ bottomSlot, ...listItemProps }: BookingListItemProps & { bottomSlot: ReactNode }) => {
  return (
    <Card>
      <BookingListItem {...listItemProps} leftPadding={false} rightPadding={false} />
      {bottomSlot && <div className="pt-md w-full">{bottomSlot}</div>}
    </Card>
  );
};

export default BookingCard;
