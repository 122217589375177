import { server } from '@/helpers';

const getReviewData = (userId, bookingId, reviewId) =>
  server.request
    .get(`/reviews/booking-review/${userId}/${bookingId}/` + (reviewId ? '?reviewId=' + reviewId : '' ))
    .then(server.handleSuccess)
    .catch(server.handleError);

const save = (data) =>
  server.request
    .post(`/reviews/save`, data)
    .then(server.handleSuccess)
    .catch(server.handleError);

const uploadFile = (data) =>
  server.request
    .post(`/reviews/upload-file`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then(server.handleSuccess)
    .catch(server.handleError);

const deleteFile = (data) =>
  server.request
    .post(`/support-form/delete-file`, data)
    .then(server.handleSuccess)
    .catch(server.handleError);

export const reviewsServices = {
  save,
  uploadFile,
  deleteFile,
  getReviewData
}