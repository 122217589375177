import Select from '@/components/elements/forms/Select';
import { APPOINTMENT_STATUS_ACTIVE, APPOINTMENT_STATUS_CANCELLED } from '@/constants/appointmentConstants';
import { _s } from '@/locale';

import { BsCircle, BsFunnel, BsRecordCircle } from 'react-icons/bs';
import { components } from 'react-select';

const { Option, Control } = components;
const SelectedIcon = (
  <BsRecordCircle
    style={{
      marginRight: 7,
      fontSize: '18px',
      color: '#23a994',
      display: 'inline-block',
      height: 20,
    }}
  />
);
const DefaultIcon = (
  <BsCircle
    style={{
      marginRight: 7,
      display: 'inline-block',
      fontSize: '18px',
    }}
  />
);
const FilterIcon = <BsFunnel style={{ marginLeft: 10 }} />;
const IconOption = (props) => {
  const { isSelected } = props;
  return (
    <Option {...props}>
      {isSelected ? SelectedIcon : DefaultIcon}
      {props.data.label}
    </Option>
  );
};

type BookingFilterProps = {
  onChange: any;
  value: any;
};

export const filterOptions = [
  {
    value: '',
    label: _s('viewAllBookings'),
    trackingValue: 'all',
  },
  {
    value: APPOINTMENT_STATUS_ACTIVE,
    label: _s('viewAllBookingsActive'),
    trackingValue: 'active',
  },
  {
    value: APPOINTMENT_STATUS_CANCELLED,
    label: _s('viewAllBookingsCanceled'),
    trackingValue: 'canceled',
  },
];

const customStyles = {
  control: (styles) => ({
    ...styles,
    borderColor: '#23a994',
    borderRadius: 7,
    cursor: 'pointer',
    boxShadow: 'none',
    '&:hover': {
      borderColor: '#23a994',
    },
    '&:focus': {
      borderColor: '#23a994',
    },
  }),
  option: (styles) => {
    return {
      ...styles,
      backgroundColor: '#FFF',
      color: '#000',
      cursor: 'pointer',
      ':active': {
        backgroundColor: '#FFF',
      },
    };
  },
};

const BookingFilter = (props: BookingFilterProps) => {
  return (
    <Select
      {...props}
      options={filterOptions}
      styles={customStyles}
      components={{
        Option: IconOption,
        Control: ({ children, ...props }) => (
          <Control {...props}>
            {FilterIcon}
            {children}
          </Control>
        ),
      }}
    />
  );
};

export default BookingFilter;
