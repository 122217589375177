import { giftcardSchema } from '@/types/state/book';
import { z } from 'zod';

const createBookingPaymentRequestSchema = z.object({
  type: z.literal('booking'),
  id: z.number(),
  giftcards: z.array(giftcardSchema).optional(),
});

const createBundlePaymentRequestSchema = z.object({
  type: z.literal('bundle'),
  id: z.number(),
  placeId: z.number(),
  formValues: z.object({ 1: z.object({ nationalId: z.string() }) }).optional(),
});

export const createPaymentRequestSchema = z.union([
  createBookingPaymentRequestSchema,
  createBundlePaymentRequestSchema,
]);

export type CreatePaymentRequest = z.infer<typeof createPaymentRequestSchema>;

export const createPaymentResponseSchema = z.object({
  id: z.string(),
  snippet: z.string(),
});

export type CreatePaymentResponse = z.infer<typeof createPaymentResponseSchema>;
