import { userActions } from '@/actions';
import { Dispatch } from 'react';
import { favoriteServices } from '../services';

const add = (placeId: number, user: any, successCallback?: () => void) => (dispatch: Dispatch<any>) =>
  favoriteServices.add(placeId).then(() => {
    if (user && !user.favorites) user.favorites = [];
    user.favorites.push(placeId);
    dispatch(userActions.updateUser(user, true));
    successCallback?.();
  });

const remove = (placeId: number, user: any, successCallback?: () => void) => (dispatch: Dispatch<any>) =>
  favoriteServices.remove(placeId).then(() => {
    if (user && user.favorites) {
      user.favorites = user.favorites.filter((id) => id !== placeId);
    }
    dispatch(userActions.updateUser(user, true));
    successCallback?.();
  });

export const favoriteActions = {
  add,
  remove,
};
