import { bookActions, loadingActions } from '@/actions';
import { FakeButton } from '@/components/elements/forms/buttons/Button/Button';
import Label from '@/components/elements/Label/Label';
import { ListItem } from '@/components/elements/lists';
import Snackbar from '@/components/elements/notifications/Snackbar/Snackbar';
import { getDateFormattedForFirstAvailableTime, promiseWrapper, server, truncateStringIfExceeding } from '@/helpers';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { _s } from '@/locale';
import { SearchPlace, SearchPlaceMatchedService } from '@/types/api/services/search';
import { Place, PlaceService } from '@/types/state/shared';
import { captureMessage } from '@sentry/react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useBookAppointmentContext } from '../modals/BookAppointment/BookAppointment.hooks';

const baseTranslationKey = 'components.modules.place.MatchedServiceItem';

async function getPlaceDetails(placeId: number, placeSlug: string): Promise<Place> {
  const { data, error } = await promiseWrapper(
    server.request.get(`/findPlace/${placeId}/${encodeURIComponent(placeSlug)}?slug=${encodeURIComponent(placeSlug)}`),
  );

  if (error) {
    captureMessage('Error: fetch place details', error);
    return null;
  }

  return data.data.place;
}

function getServiceDetails(place: Place, matchedService: SearchPlaceMatchedService): PlaceService | undefined {
  return place.services.flatMap((service) => service.services).find((service) => service.id === matchedService.id);
}

function handleError() {
  toast(({ closeToast }) => (
    <Snackbar label={_s(`${baseTranslationKey}.snackbar.label.error`)} type="danger" onClose={closeToast} />
  ));
}

const MatchedServiceItem = ({
  service: matchedService,
  place: searchPlace,
  underline,
}: {
  service: SearchPlaceMatchedService;
  place: SearchPlace;
  underline: boolean;
}) => {
  const context = useBookAppointmentContext();
  const bookstate = useAppSelector((state) => state.book);
  const history = useHistory();

  const dispatch = useAppDispatch();

  const firstAvailableTime = matchedService.firstAvailableTime
    ? getDateFormattedForFirstAvailableTime(matchedService.firstAvailableTime, searchPlace.timezone)
    : null;

  const priceAndDuration = (() => {
    if (matchedService.finalPriceLabel) {
      return [matchedService.durationLabel, matchedService.finalPriceLabel].filter(Boolean).join(', ') + ' ';
    }
    return [matchedService.durationLabel, matchedService.priceLabel].filter(Boolean).join(', ');
  })();
  const ctaLabel = matchedService.isBundle ? _s(`${baseTranslationKey}.cta.buy`) : _s(`${baseTranslationKey}.cta.book`);

  const handleMatchedServiceClick = async (event: React.MouseEvent) => {
    event.preventDefault();

    if (matchedService.isBundle) {
      history.push(`/bundles/checkout/${searchPlace.id}/${matchedService.id}`);
      return;
    }
    // if we already have the place in state, use it, otherwise fetch it
    const place =
      bookstate.place?.id === searchPlace.id
        ? bookstate.place
        : await (async () => {
            dispatch(loadingActions.show('fetchPlaceDetails'));
            const details = await getPlaceDetails(searchPlace.id, searchPlace.slug);
            dispatch(loadingActions.hide());
            return details;
          })();

    if (!place) {
      handleError();
      return;
    }

    const service = getServiceDetails(place, matchedService);

    if (!service) {
      handleError();
      return;
    }

    dispatch(bookActions.removeEmployee());
    dispatch(bookActions.clearBook());
    // @ts-ignore
    dispatch(bookActions.addService(service, place));
    dispatch(bookActions.addPlace(place));
    context.open();
  };

  return (
    <ListItem
      key={matchedService.id}
      leftPadding={false}
      rightPadding={false}
      onClick={handleMatchedServiceClick}
      rightSlot={<FakeButton label={ctaLabel} size="sm" />}
      verticalAlign="top"
      underline={underline}>
      <div className="gap-xxs flex flex-col">
        <div className="space-x-xs flex">
          {matchedService.finalPriceLabel && <Label variant="campaign" icon={false} />}
          {matchedService.supportsWellnessCard && <Label variant="wellness" label={_s('wellness')} icon={false} />}
        </div>
        <p className="text-black-900 truncate text-sm">
          {truncateStringIfExceeding(matchedService.name, 30, 30, '...')}
        </p>
        <span className="text-black-900 text-m">
          {priceAndDuration}
          {matchedService.finalPriceLabel && (
            <span className="text-black-600 line-through">({matchedService.priceLabel})</span>
          )}
        </span>
        {firstAvailableTime && <span className="text-brand-700 text-m">{firstAvailableTime}</span>}
      </div>
    </ListItem>
  );
};

export default MatchedServiceItem;
