import { themed } from '@/helpers/theme';
import { _s } from '@/locale';
import { MouseEventHandler } from 'react';

const CategoryAllButton = ({ onClick }: { onClick: MouseEventHandler<HTMLButtonElement> }) => (
  <div className="basis-full p-1 sm:basis-1/2 sm:p-2 md:basis-1/3 lg:p-4">
    <button
      className={`md:h-30 flex h-52 w-full items-center justify-center rounded-md font-semibold transition-colors duration-300 lg:h-48 ${themed(
        'hover:bg-brown-100 text-black-900 bg-brown-50',
        'text-black-900 bg-sm_primary hover:bg-sm_primary',
      )}`}
      onClick={onClick}>
      {_s('viewAllCategories')}
    </button>
  </div>
);

export default CategoryAllButton;
