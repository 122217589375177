import { h5Class } from '@/components/elements/content';
import { ValueCard as ValueCardType } from '@/types/valuecards';
import { MouseEventHandler } from 'react';
import ValueCard from './ValueCard';

type Props = {
  id: number;
  header: string;
  valueCards: ValueCardType[];
  onSelect: (valueCard: ValueCardType, id: number) => MouseEventHandler<HTMLButtonElement>;
  showInfo: (valueCard: ValueCardType, id: number) => MouseEventHandler<HTMLButtonElement>;
};

const ValueCards = ({ id, header, valueCards, onSelect, showInfo }: Props) => (
  <div key={id} className="mb-8">
    <h2 className={`${h5Class} !mb-0`}>{header}</h2>
    {valueCards?.map((valueCard, i) => (
      <div key={i}>
        <ValueCard
          {...valueCard}
          onSelect={onSelect(valueCard, id)}
          hasInfo={!!valueCard.description}
          showInfo={showInfo(valueCard, id)}
        />
      </div>
    ))}
  </div>
);

export default ValueCards;
