import colors from '@/colors';
import useMobileView from '@/hooks/useMobileView';
import { getSvgPath } from 'figma-squircle';
import { ButtonHTMLAttributes, ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

type TooltipProps = {
  onClose: (event?: ButtonHTMLAttributes<HTMLButtonElement>['onClick']) => void;
  onClickOutside?: (event?: MouseEvent) => void;
  // element that triggers the tooltip
  triggerRef: React.RefObject<HTMLButtonElement>;
  /**
   * Optional target that specifies where the tooltip should be rendered in relation to.
   * - If not provided, the tooltip will be rendered in relation to the trigger element.
   */
  targetRef?: React.RefObject<HTMLDivElement>;
  children: ReactNode;
  offsetTop?: number;
  offsetLeft?: number;
};

/**
 * Render the tooltip anywhere in the DOM
 */
export const TooltipPortal = (props: TooltipProps) => {
  if (!props?.targetRef?.current) return null;

  return createPortal(<Tooltip {...props} />, props.targetRef.current);
};

const Tooltip = ({
  onClose,
  onClickOutside,
  triggerRef,
  targetRef,
  offsetLeft = 0,
  offsetTop = 0,
  children,
}: TooltipProps) => {
  const popoverRef = useRef<HTMLDivElement>(null);
  const [style, setStyle] = useState<React.CSSProperties>({});
  const { isMobileView } = useMobileView();
  const wrapperRef = useRef(null);

  const handleClickOutside = (e: MouseEvent) => {
    if (wrapperRef && !wrapperRef?.current?.contains(e.target)) {
      onClickOutside?.(e);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  /**
   * Use squircle to create a rounded corner
   */
  useEffect(() => {
    if (!popoverRef.current) return;
    const { width, height } = popoverRef.current.getBoundingClientRect();
    const path = getSvgPath({ width, height, cornerRadius: 18, cornerSmoothing: 1 });
    setStyle({ backgroundColor: colors.black[900], width, height, clipPath: `path('${path}')` });
  }, []);

  /**
   * Calculate the position of the tooltip and
   * make it point to the trigger element
   */
  const pos = useMemo(() => {
    if (!triggerRef?.current || !popoverRef?.current) return { top: 0, left: 0 };
    const trigger = triggerRef.current.getBoundingClientRect();
    const target = targetRef?.current?.getBoundingClientRect();
    const popover = popoverRef.current.getBoundingClientRect();

    return {
      top: trigger.bottom - popover.height - (target?.bottom ?? trigger.bottom) - offsetTop,
      left:
        popover.left > trigger.left
          ? popover.left - trigger.left + offsetLeft
          : trigger.left - popover.left + offsetLeft,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerRef?.current, popoverRef?.current, isMobileView]);

  useEffect(() => {
    const handleOnResize = () => onClose();

    window.addEventListener('resize', handleOnResize);

    return () => window.removeEventListener('resize', handleOnResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Hide the tooltip until position has been calculated
  const isVisible = pos.top !== 0 && pos.left !== 0;

  return (
    <div
      className="px-md"
      style={{
        position: 'absolute',
        zIndex: 11,
        top: pos.top,
        visibility: isVisible ? 'visible' : 'hidden',
        // Make sure the tooltip does not break the layout with horizontal scroll on mobile
        ...(isMobileView ? { right: 0 } : { left: pos.left }),
      }}
      ref={wrapperRef}>
      <div
        ref={popoverRef}
        className="bg-black-900 w-[320px] drop-shadow-lg"
        style={style}
        id="popover"
        role="dialog"
        aria-modal="true"
        aria-labelledby="popover-title"
        aria-describedby="popover-content">
        {children}
      </div>
      <div className="relative">
        <div
          style={isMobileView ? { left: pos.left } : { left: 30 }}
          className="w-lg h-lg bg-black-900 absolute -top-[9px] -z-10 rotate-45"></div>
      </div>
    </div>
  );
};

export default Tooltip;
