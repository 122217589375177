import { classnames } from '@/helpers';
import { ReactNode } from 'react';

const TITLE_CLASSNAMES: {
  [key in DataGridCellType]: string;
} = {
  default: 'text-md text-black-900',
  muted: 'text-md text-black-600',
  description: 'text-sm text-black-600',
  header: 'font-semibold text-md text-black-900',
  discount: 'text-highlight-700 text-md',
  positive: 'text-success text-md',
} as const;

type DataGridCellType = 'default' | 'muted' | 'description' | 'header' | 'discount' | 'positive';

const DataGridCell = ({
  leftIcon,
  title,
  type = 'default',
  rightIcon,
  align = 'left',
  ariaLabel = '',
}: {
  leftIcon?: ReactNode;
  title: ReactNode | string;
  type?: DataGridCellType;
  rightIcon?: ReactNode;
  align?: 'left' | 'right';
  ariaLabel?: string;
}) => {
  return (
    <div className="space-x-sm flex flex-1 items-center" role="gridcell" aria-label={ariaLabel}>
      {leftIcon && <div className="min-w-[24px] flex-shrink-0">{leftIcon}</div>}
      <span className={classnames('flex-1 overflow-hidden', TITLE_CLASSNAMES[type], align !== 'left' && 'text-right')}>
        {title}
      </span>
      {rightIcon && <div className="flex-shrink-0">{rightIcon}</div>}
    </div>
  );
};

export default DataGridCell;
