import { CHECKOUT_PAYMENT_METHOD } from '@/constants/checkout';
import { promiseWrapper } from '@/helpers';
import { bookServices } from '@/services/bookServicesTs';
import { PaymentHistoryResponse, paymentHistoryResponseSchema } from '@/types/api/services/booking';
import { captureMessage } from '@sentry/react';
import { Dispatch, useEffect, useState } from 'react';

const initialPaymentHistoryObject = {
  history: undefined,
  previousPaymentMethod: undefined,
  popularOrder: [
    CHECKOUT_PAYMENT_METHOD.STORED_COF,
    CHECKOUT_PAYMENT_METHOD.SWISH,
    CHECKOUT_PAYMENT_METHOD.APPLE_PAY,
    CHECKOUT_PAYMENT_METHOD.GOOGLE_PAY,
    CHECKOUT_PAYMENT_METHOD.QLIRO,
    CHECKOUT_PAYMENT_METHOD.KLARNA,
  ],
};

export const usePaymentsHistory = (placeId?: number) => {
  const [paymentHistory, setPaymentHistory] = useState<PaymentHistoryResponse>({ ...initialPaymentHistoryObject });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchPaymentHistory(placeId, setLoading, setPaymentHistory);
  }, []);

  return { paymentHistory, loading };
};

const fetchPaymentHistory = async (
  placeId: number | undefined,
  setLoading: Dispatch<boolean>,
  setPaymentHistory: Dispatch<PaymentHistoryResponse>,
) => {
  const { data, error } = await promiseWrapper(bookServices.getPaymentsHistory(placeId));

  const validated = paymentHistoryResponseSchema.safeParse(data);

  if (error || !validated.success) {
    captureMessage('Failed to fetch payment history');
    setPaymentHistory({ ...initialPaymentHistoryObject });
  } else {
    setPaymentHistory(validated.data);
  }

  setLoading(false);
};
