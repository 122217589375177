import { isServer } from './';
export function updateDeployTimestamp(ts) {
  if (isServer && isNaN(ts)) return;

  const lastSavedDeployTimestamp = localStorage.getItem('last-deploy-timestamp');

  if (lastSavedDeployTimestamp !== ts) {
    localStorage.setItem('last-deploy-timestamp', ts);
  }
}
