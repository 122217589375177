import Cookies from 'universal-cookie';
const cookies = new Cookies();

/**
 * @typedef {object} CookieOptions
 * @property {string} [path]
 * @property {Date} [expires]
 * @property {number} [maxAge]
 * @property {string} [domain]
 * @property {boolean} [secure]
 */
/**
 *
 * @param {string} name
 * @param {string|object} value
 * @param {CookieOptions} options
 */
const setCookie = (name, value, options = { path: '/' }) => {
  return cookies.set(name, value, options);
};

const getCookie = (name, options) => {
  return cookies.get(name, options);
};

const removeCookie = (name, options = { path: '/' }) => {
  return cookies.remove(name, options);
};

export { getCookie, removeCookie, setCookie };
