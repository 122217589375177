import { h4Class } from '@/components/elements/content';
import { ArrowLongIcon } from '@/components/icons';
import { blog, classnames } from '@/helpers';
import { _s } from '@/locale';
import { Link } from 'react-router-dom';

type Props = {
  post: any;
};

const ArchivePost = ({ post }: Props) => {
  const postUrl = `/blogg/p/${post.slug}-${post.id}`;
  return (
    <div className={classnames('mb-16 w-full px-5 md:mb-10 md:w-1/3')}>
      <Link to={postUrl}>
        <div className="before:bg-secondary-50 relative py-4 pl-4 before:absolute before:left-0 before:right-12 before:top-0 before:h-full">
          <img
            src={blog.getImage(post.featured_media.source_url)}
            alt={post.featured_media.alt_text}
            className="relative aspect-[12/8] w-full max-w-full object-cover object-center"
          />
        </div>
      </Link>
      <div className="pl-4 md:pl-0">
        <Link
          to={`/blogg/c/${post.categories[0].slug}`}
          className="text-secondary-200 hover:text-secondary-200 mt-3 block text-sm font-semibold uppercase">
          {post.categories[0].name}
        </Link>
        <div className="text-secondary-200 text-m mb-2">{blog.getPostDate(post)}</div>
        <h3>
          <Link to={postUrl} className={h4Class}>
            <span dangerouslySetInnerHTML={{ __html: post.title.rendered }}></span>
          </Link>
        </h3>

        <p dangerouslySetInnerHTML={{ __html: post.excerpt.rendered }} className="my-3"></p>
        <Link
          to={postUrl}
          className="text-secondary-500 hover:text-secondary-500 inline-flex items-center justify-start py-3">
          {_s('blogPage.readWholePost')} <ArrowLongIcon className="ml-2 w-8" />
        </Link>
      </div>
    </div>
  );
};

export default ArchivePost;
