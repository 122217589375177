import { MouseEvent } from 'react';
import { CloseIcon as CloseIconComponent } from '../icons';

type ChipProps = {
  label: string;
  onClose?: (event: MouseEvent) => void;
};

const Chip = ({ label, onClose }: ChipProps) => (
  <span className="bg-black-100 text-m inline-flex !h-8 flex-row items-center rounded-lg px-4 text-gray-900">
    <span>{label}</span>
    {onClose && (
      <span className="ml-2" onClick={onClose}>
        <CloseIconComponent className="h-2 w-2 cursor-pointer text-gray-900" />
      </span>
    )}
  </span>
);

export default Chip;
