import { featurePromoConstants } from '@/constants/featurePromoConstants';

const initialState = {
  featurePromoTrigger: {},
};

export function featurePromos(state = initialState, action) {
  switch (action.type) {
    case featurePromoConstants.UPDATE_FEATURE_PROMO:
      return {
        ...state,
        featurePromoTrigger: {
          ...state.featurePromoTrigger,
          ...{
            [action.id]: {
              triggered: true,
            },
          },
        },
      };
    case featurePromoConstants.UPDATE_FEATURE_PROMO_EVENT:
      return {
        ...state,
        featurePromoTrigger: {
          ...state.featurePromoTrigger,
          ...{
            [action.id]: {
              ...state.featurePromoTrigger[action.id],
              event: action.event,
            },
          },
        },
      };
    case featurePromoConstants.UPDATE_FEATURE_PROMO_FINISHED:
      return {
        ...state,
        featurePromoTrigger: {
          ...state.featurePromoTrigger,
          ...{
            [action.id]: {
              ...state.featurePromoTrigger[action.id],
              finished: action.finished,
            },
          },
        },
      };
    default:
      return state;
  }
}
