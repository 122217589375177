import { searchConstants } from '../constants';

function loadExtendedSearch() {
  return { type: searchConstants.EXTENDED_SEARCH_START };
}

function setExtendedSearch(action) {
  return { type: searchConstants.EXTENDED_SEARCH_SUCCESS, action };
}

function resetExtendedSearch() {
  return { type: searchConstants.EXTENDED_SEARCH_RESET };
}

function setExtendedPage(page) {
  return { type: searchConstants.EXTENDED_SET_PAGE, page };
}

export const extendedSearchActions = {
  loadExtendedSearch,
  setExtendedSearch,
  resetExtendedSearch,
  setExtendedPage,
};
