import { Card, CardHeader } from '@/components/elements/cards';
import { __ } from '@/locale';
import ShippingForm from '../ShippingForm';
import { GreetingCard } from '../cards';

/**
 * @typedef {object} ShippingInfoCardProps
 * @prop {import('../../../../services/giftCardServices').Shipping} shipping
 * @prop {Function} setShipping
 * @prop {boolean} shouldValidate
 * @prop {Function} validationCompleted
 * @prop {import('../../../../services/giftCardServices').Greeting} greeting
 * @prop {Function} setGreeting
 * @prop {object} css
 *
 * @param {ShippingInfoCardProps} param0
 * @returns
 */
const ShippingInfoCard = ({
  shipping,
  setShipping,
  shouldValidate,
  validationCompleted,
  greeting,
  setGreeting,
  css,
}) => (
  <Card>
    <CardHeader>{__('buyGiftcard.shippingInfo')}</CardHeader>
    <ShippingForm
      values={shipping}
      setValues={setShipping}
      shouldValidate={shouldValidate}
      validationCompleted={validationCompleted}
    />
    <hr className={css.separator} />
    <CardHeader>{__('buyGiftcard.universal.greetingSummary.title')}</CardHeader>
    <GreetingCard values={greeting} setValues={setGreeting} />
  </Card>
);

export default ShippingInfoCard;
