import { IAlert } from '@/components/modules/alert/Alert';
import * as React from 'react';
import { createContext, useCallback, useContext, useEffect, useReducer, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

type UseAlertManagerResult = ReturnType<typeof useAlertManager>;

const AlertContext = createContext<UseAlertManagerResult>({
  alerts: [],
  addAlert: () => {},
  removeAlert: () => {},
  loaded: false,
  portalId: '',
});

type ActionType = { type: 'ADD'; alert: IAlert } | { type: 'REMOVE'; id: string };

const useAlertManager = (
  initialAlerts: IAlert[],
): {
  alerts: IAlert[];
  addAlert: (alert: IAlert) => void;
  removeAlert: (id: string) => void;
  loaded: boolean;
  portalId: string;
} => {
  const [loaded, setLoaded] = useState(false);
  const [portalId] = useState(`alert-portal-${uuidv4()}`);
  const [autoCloseAlert, setAutoCloseAlert] = useState<{ id: string; timeout: number }>();

  const [alerts, dispatch] = useReducer((state: IAlert[], action: ActionType) => {
    switch (action.type) {
      case 'ADD': {
        const { alert } = action;
        return [...state, { ...alert, id: uuidv4() }];
      }
      case 'REMOVE': {
        const updatedAlerts = state.filter((alert) => alert.id !== action.id);
        return [...updatedAlerts];
      }
      default:
        return state;
    }
  }, initialAlerts);

  const addAlert = (newAlert: IAlert) => {
    dispatch({ type: 'ADD', alert: newAlert });
  };

  const removeAlert = useCallback((id: string) => {
    dispatch({ type: 'REMOVE', id });
  }, []);

  useEffect(() => {
    const alert = alerts.filter((alert) => alert.autoClose).pop();
    if (alert) {
      setAutoCloseAlert({ id: alert.id, timeout: alert.autoCloseTimer });
    }
  }, [alerts]);

  useEffect(() => {
    if (autoCloseAlert) {
      const timer = setTimeout(() => {
        removeAlert(autoCloseAlert.id);
      }, autoCloseAlert.timeout || 3000);
      return () => clearTimeout(timer);
    }
  }, [autoCloseAlert, removeAlert]);

  useEffect(() => {
    const div = document.createElement('div');
    div.id = portalId;
    div.setAttribute('class', 'fixed !z-[100000] w-full pointer-events-none');
    const body = document.querySelector('body');
    if (body.prepend) {
      body.prepend(div);
    } else {
      const firstChild = body.firstChild;
      body.insertBefore(div, firstChild);
    }
    setLoaded(true);

    return (): void => {
      document.querySelector('body').removeChild(div);
    };
  }, [portalId]);

  return { alerts, addAlert, removeAlert, loaded, portalId };
};

export const AlertProvider: React.FC<{
  initialAlerts: IAlert[];
  children: React.ReactNode;
}> = ({ initialAlerts, children }) => (
  <AlertContext.Provider value={useAlertManager(initialAlerts)}>{children}</AlertContext.Provider>
);

export const useAddAlert = (): UseAlertManagerResult['addAlert'] => {
  const { addAlert } = useContext(AlertContext);
  return addAlert;
};

export const useRemoveAlert = (): UseAlertManagerResult['removeAlert'] => {
  const { removeAlert } = useContext(AlertContext);
  return removeAlert;
};

export const useAlerts = (): IAlert[] => {
  const { alerts } = useContext(AlertContext);
  return alerts;
};

export const useAlertPortal = (): UseAlertManagerResult['portalId'] => {
  const { portalId } = useContext(AlertContext);
  return portalId;
};
