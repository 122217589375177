import { server } from '../helpers';

export const placeService = {
  storeImpressions,
  topOfSearchClicked,
};

function storeImpressions(bidIds) {
  return server.request.post('/storeImpressions', { bidIds }).then(server.handleSuccess).catch(server.handleError);
}
function topOfSearchClicked(bidId) {
  return server.request
    .get('/top-of-search-clicked/' + bidId)
    .then(server.handleSuccess)
    .catch(server.handleError);
}
