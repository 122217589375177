import { Card } from '@/components/elements/cards';
import BorderLabelTextInput from '@/components/elements/forms/BorderLabelTextInput';
import { Button, LinkButton } from '@/components/elements/forms/buttons';
import { LoadingIcon, SearchIcon } from '@/components/icons';
import PageViewLayout from '@/components/layouts/PageViewLayout/PageViewLayout';
import SEO, { seoPropsFromBaseString } from '@/components/modules/SEO';
import GiftCardDisplay from '@/components/modules/pages/giftCards/GiftCardDisplay';
import { SupportFaqItem } from '@/components/modules/pages/support/RandomSupportFaq';
import { GIFTCARD_PATH, supportFaq } from '@/constants/faq';
import { url } from '@/helpers';
import useMobileView from '@/hooks/useMobileView';
import { __, _s } from '@/locale';
import { getGiftcardCode } from '@/services';
import { getGiftCard } from '@/services/useGiftCardsServices';
import * as Sentry from '@sentry/react';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const base = 'useGiftCard';

type GiftCardType = 'wellness' | 'universal' | 'value' | 'place';

type GiftCard = {
  expiryDate: string;
  numPaymentsMade: number;
  type: GiftCardType;
  value: number;
  slug?: string;
  subscriberResourceId?: number;
  practitioners?: any[];
};

const giftCardTitle = (type: GiftCardType, practitioners?: any[]) => {
  if (!practitioners) return __(`${base}.giftCard.${type === 'universal' ? 'place' : type}Title`);
  const withPractitioner = practitioners.length > 1 ? '.multiple' : '.single';
  const [name, salon = null] = practitioners;
  const names = { name, ...(salon && { salon }) };
  return __(`${base}.giftCard.name${withPractitioner}`, names).replace(/&amp;/g, '&');
};

const getUseGiftCardUrl = (type: GiftCardType, slug: string = null, subscriberResourceId: number = 0) => {
  const searchquery = type === 'universal' ? 'gc=1' : 'wellness=1';
  if (!slug) return `/vad/var?${searchquery}`;
  return `/places/${slug}${subscriberResourceId ? `?sr=${subscriberResourceId}` : ''}`;
};

const getGiftCardButtonText = (slug: string) => __(`${base}.book.${slug ? 'place' : 'universal'}`);

const GiftCardUseButton = ({ giftCard, sticky }: { giftCard: GiftCard; sticky: boolean }) => {
  return (
    <div
      className={`${
        sticky ? 'container fixed bottom-0 left-0 right-0 flex justify-center bg-white py-4 pb-7 shadow' : ''
      } flex justify-center`}>
      <LinkButton
        variant="primary"
        className="!px-20 text-center hover:text-white"
        size="lg"
        to={getUseGiftCardUrl(giftCard.type, giftCard.slug, giftCard.subscriberResourceId)}>
        {getGiftCardButtonText(giftCard.slug)}
      </LinkButton>
    </div>
  );
};

const GiftCardsUse = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location?.search);
  const [error, setError] = useState<string | null>(null);
  const [code, setCode] = useState('');
  const giftCardFaq = supportFaq.find((section) => section.path === GIFTCARD_PATH);
  const [active, setActive] = useState<number>(0);

  const [giftCard, setGiftCard] = useState<GiftCard | null>(null);
  const [loading, setLoading] = useState(false);
  const { isMobileView } = useMobileView();

  useEffect(() => {
    if (queryParams.has('token')) {
      getGiftcardCode(queryParams.get('token'))
        .then((res) => {
          setCode(res.code);
          setError(null);
        })
        .catch((error) => {
          Sentry.captureException(error);
        });
    }

    if (queryParams.has('code')) {
      setCode(queryParams.get('code'));
    }
  }, []);

  const onSubmitCode = (e) => {
    e.preventDefault();
    setError(null);

    if (!code) return setError(__(`${base}.emptyInputError`));
    setLoading(true);

    (async () => {
      const res = await getGiftCard(code);
      setLoading(false);
      if (res.error) {
        setError(_s(`${base}.fetchError`));
        setGiftCard(null);
        return;
      }

      setGiftCard(res);
      setError(null);
    })();
  };

  return (
    <PageViewLayout
      title=""
      type="mainView"
      wrapperClass={`pt-6 min-h-screen bg-gradient`}
      source={base}
      footerDisplayOption={{ from: 'md', to: 'all' }}>
      <div className="container-fixed lg:container">
        <h1 className="h1-responsive mb-2">{_s(`${base}.header`)}</h1>
        <h2 className="text-black-900 max-w-lg text-sm sm:text-base">{_s(`${base}.subHeader`)}</h2>
        <div className="flex flex-col gap-4 pb-6 lg:grid lg:grid-cols-2">
          <section>
            <Card className="bg-white">
              <h2 className="text-black-900 mb-4 max-w-lg">{_s(`${base}.description`)}</h2>
              <form className="flex items-start gap-4">
                <BorderLabelTextInput
                  label={__(`${base}.input.label`)}
                  className="max-w-sm"
                  name={'code'}
                  error={error}
                  placeholder={__(`${base}.input.placeholder`)}
                  format={(value) => value.toUpperCase()}
                  value={code}
                  setValue={setCode}
                  half
                />
                <Button className="py-[6px]" onClick={onSubmitCode} variant="secondary">
                  {_s(`${base}.fetchButton`)}
                </Button>
              </form>

              <div className={loading || giftCard ? 'my-4 max-w-lg' : ''}>
                {loading ? (
                  <LoadingIcon className="mx-auto mt-10 block h-8 w-8" />
                ) : (
                  giftCard && (
                    <GiftCardDisplay
                      {...{ ...giftCard, title: giftCardTitle(giftCard.type, giftCard.practitioners) }}
                    />
                  )
                )}
              </div>
            </Card>

            {!loading && giftCard && <GiftCardUseButton giftCard={giftCard} sticky={isMobileView} />}
          </section>
          <section>
            <Card className="bg-white">
              <h2 className="text-md mb-8 flex items-center justify-start gap-4 font-bold lg:text-lg">
                <SearchIcon className="h-7 w-7" />
                {_s(`${base}.faq.title`)}
              </h2>
              {giftCardFaq.questions.map((question, i) => {
                const isActive = question.id === active;
                return (
                  <SupportFaqItem
                    className={`${i < giftCardFaq.questions.length - 1 ? 'splitter' : 'mb-8'}`}
                    qClassName="font-bold lg:underline"
                    btnClassName="hover:text-primary"
                    question={question}
                    active={isActive}
                    setActive={setActive}
                    closeInactive={isMobileView}
                    section={giftCardFaq.path}
                    key={i}
                  />
                );
              })}
            </Card>
          </section>
          {isMobileView && <div className="py-6"></div>}
        </div>
      </div>
      <SEO {...seoPropsFromBaseString(base, url.getBaseUrl() + 'giftcards')} />
    </PageViewLayout>
  );
};

export default GiftCardsUse;
