import { bookActions, favoriteActions } from '@/actions';
import Snackbar from '@/components/elements/notifications/Snackbar/Snackbar';
import { isEmpty, isPlaceFavorite, promiseWrapper, setBookingStartingPoint, trackMpEvent } from '@/helpers';
import { clearGroupBookingParticipantsSession } from '@/helpers/checkout';
import {
  getBookingEmployee,
  getBookingTrackingProps,
  sendBookingConfirmationSuccessEvent,
} from '@/helpers/confirmation';
import { useAppSelector } from '@/hooks';
import { trackScreenView } from '@/hooks/useTrackScreenView';
import useWindowOnUnload from '@/hooks/useWindowOnUnload';
import { _s } from '@/locale';
import { bookServices } from '@/services/bookServicesTs';
import { CheckoutTracking } from '@/types/analytics';
import {
  ConfirmedBooking,
  RetryResponse,
  bookingByCodeResponseSchema,
  confirmCofResponseSchema,
  confirmKlarnaResponseSchema,
  confirmQliroResponseSchema,
  confirmSwishPaymentResponseSchema,
  retryResponseSchema,
} from '@/types/api/services/booking';
import { SelectedPaymentMethod } from '@/types/checkout';
import * as Sentry from '@sentry/react';
import { Dispatch, useEffect, useReducer } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { z } from 'zod';

const baseTranslationKey = 'pages.booking.confirmation.BookingConfirmation';

const activatePaymentParamSchema = z.object({
  type: z.union([
    z.literal('klarnaActivatePayment'),
    z.literal('qliroActivatePayment'),
    z.literal('cofActivatePayment'),
    z.literal('swishActivatePayment'),
    z.literal('giftcardActivatePayment'),
  ]),
});

const klarnaParamSchema = z.object({
  klarna_order_id: z.string(),
  session_id: z.string(),
  type: z.union([z.literal('klarna'), z.literal('klarnaActivatePayment')]),
  bookingCode: z.string().optional(),
  bookingId: z.string().optional(),
});

const qliroParamSchema = z.object({
  paymentRef: z.string(),
  type: z.literal('qliroActivatePayment').optional(),
});

const cofParamSchema = z.object({
  bookingId: z.string(),
  type: z.union([z.literal('cof'), z.literal('cofActivatePayment')]),
});

const payAtPlaceParamSchema = z.object({
  b: z.string(),
  type: z.literal('giftcardActivatePayment').optional(),
});

const swishParamSchema = z.object({
  bookingId: z.string(),
  type: z.union([z.literal('swish'), z.literal('swishActivatePayment')]),
});

const searchParamsSchema = z.union([
  klarnaParamSchema,
  qliroParamSchema,
  cofParamSchema,
  payAtPlaceParamSchema,
  swishParamSchema,
]);

type SearchParams = z.infer<typeof searchParamsSchema>;

type CheckoutState = typeof initialState;

type Redirect = RetryResponse['redirect'];

type CheckoutAction =
  | { type: 'SET_BOOKING'; data: { booking: ConfirmedBooking; isActivatePaymentFlow?: boolean } }
  | { type: 'SET_LOADING'; loading: boolean }
  | { type: 'SET_REDIRECT'; redirect: Redirect }
  | { type: 'SET_ERROR' }
  | { type: 'SET_TRACKING_PROPS'; bookingTrackingProps: CheckoutTracking.Booking };

const initialState: {
  booking: ConfirmedBooking | null;
  loading: boolean;
  error: boolean;
  redirect?: Redirect;
  selectedPaymentMethod?: SelectedPaymentMethod;
  bookingTrackingProps: CheckoutTracking.Booking;
  isActivatePaymentFlow: boolean;
} = {
  booking: null,
  loading: true,
  error: false,
  selectedPaymentMethod: undefined,
  bookingTrackingProps: null,
  isActivatePaymentFlow: false,
};

async function handleConfirmationError(dispatch: Dispatch<CheckoutAction>, error: unknown) {
  const checkRetry = retryResponseSchema.safeParse(error);

  Sentry.captureException(error);

  if (checkRetry.success) {
    dispatch({ type: 'SET_REDIRECT', redirect: checkRetry.data.redirect });
    return;
  }

  dispatch({ type: 'SET_ERROR' });
}

async function getAndSetBookingDetails(
  b: SearchParams,
  isActivatePaymentFlow: boolean,
  dispatch: Dispatch<CheckoutAction>,
) {
  clearGroupBookingParticipantsSession();

  if (klarnaParamSchema.safeParse(b).success) {
    const { klarna_order_id, session_id, bookingId } = klarnaParamSchema.parse(b);

    const serviceRequest = isActivatePaymentFlow
      ? () => bookServices.completeKlarnaAfterBooking(klarna_order_id, session_id, +bookingId)
      : () => bookServices.confirmKlarna(klarna_order_id, session_id);

    const { data, error } = await promiseWrapper(serviceRequest());

    const response = confirmKlarnaResponseSchema.safeParse(data);

    if (!response.success) {
      handleConfirmationError(dispatch, error ?? data);
      return;
    }

    dispatch({ type: 'SET_BOOKING', data: { booking: response.data.booking, isActivatePaymentFlow } });
  }

  if (qliroParamSchema.safeParse(b).success) {
    const { paymentRef } = qliroParamSchema.parse(b);

    const { data, error } = await promiseWrapper(bookServices.confirmQliro(paymentRef));

    const response = confirmQliroResponseSchema.safeParse(data);

    if (!response.success) {
      handleConfirmationError(dispatch, error ?? data);
      return;
    }

    dispatch({
      type: 'SET_BOOKING',
      data: { booking: response.data.booking, isActivatePaymentFlow },
    });
  }

  if (cofParamSchema.safeParse(b).success) {
    const { bookingId } = cofParamSchema.parse(b);

    const { data, error } = await promiseWrapper(bookServices.confirmCof(bookingId));

    const response = confirmCofResponseSchema.safeParse(data);

    if (!response.success) {
      handleConfirmationError(dispatch, error ?? data);
      return;
    }

    dispatch({
      type: 'SET_BOOKING',
      data: { booking: response.data.booking, isActivatePaymentFlow },
    });
  }

  if (payAtPlaceParamSchema.safeParse(b).success) {
    const { b: bookingCode } = payAtPlaceParamSchema.parse(b);

    const { data, error } = await promiseWrapper(bookServices.getBookingByCode(bookingCode));

    const response = bookingByCodeResponseSchema.safeParse(data);

    if (!response.success) {
      handleConfirmationError(dispatch, error ?? data);
      return;
    }

    dispatch({ type: 'SET_BOOKING', data: { booking: response.data.booking, isActivatePaymentFlow } });
  }

  if (swishParamSchema.safeParse(b).success) {
    const { bookingId } = swishParamSchema.parse(b);

    const { data, error } = await promiseWrapper(bookServices.confirmSwishPayment(bookingId));

    const response = confirmSwishPaymentResponseSchema.safeParse(data);

    if (!response.success) {
      handleConfirmationError(dispatch, error ?? data);
      return;
    }

    dispatch({ type: 'SET_BOOKING', data: { booking: response.data.booking, isActivatePaymentFlow } });
  }
}

function updateClientPlaceBookings(placeId: number) {
  const clientPlaceBookings = z.array(z.number()).safeParse(localStorage.getItem('mpClientPlaceBookings'));
  if (!clientPlaceBookings.success) return;
  clientPlaceBookings.data.push(placeId);
  localStorage.setItem('mpClientPlaceBookings', JSON.stringify(clientPlaceBookings.data));
}

/**
 * make sure the clearBook function is not called on component unmount when booking again
 * since this will mess up the sessionStorage actions
 */
let bookSameServiceAgain = false;

function clearBookState(state: CheckoutState, appDispatch: Dispatch<any>) {
  // we keep bookState if qliro or klarna payment fails due to time no longer being available
  if (state.redirect?.state?.retry || bookSameServiceAgain) {
    bookSameServiceAgain = false;
    return;
  }
  appDispatch(bookActions.clearBook());
  bookActions.clearTrackingProps();
}

function handleAddPlaceToFavorites(user: any, placeId: number, dispatch: Dispatch<any>) {
  if (!user) return;
  const isFavorite = isPlaceFavorite(user, placeId);

  const showToast = () =>
    toast(({ closeToast }) => (
      <Snackbar
        action={<button onClick={closeToast}>OK</button>}
        label={
          isFavorite
            ? _s(`${baseTranslationKey}.snackbar.removedFromFavorites`)
            : _s(`${baseTranslationKey}.snackbar.addedToFavorites`)
        }
      />
    ));

  dispatch(
    isFavorite ? favoriteActions.remove(placeId, user, showToast) : favoriteActions.add(placeId, user, showToast),
  );
}

function reducer(state: CheckoutState, action: CheckoutAction): CheckoutState {
  switch (action.type) {
    case 'SET_BOOKING': {
      const booking = action.data.booking;
      const isActivatePaymentFlow = Boolean(action.data.isActivatePaymentFlow);

      if (!isActivatePaymentFlow) {
        updateClientPlaceBookings(booking.place.id);
      }

      return {
        ...state,
        booking: booking,
        loading: false,
        error: false,
        isActivatePaymentFlow,
      };
    }
    case 'SET_LOADING':
      return { ...state, loading: action.loading, error: false };
    case 'SET_ERROR':
      return { ...state, error: true, loading: false };
    case 'SET_REDIRECT':
      return { ...state, redirect: action.redirect, loading: false };
    case 'SET_TRACKING_PROPS':
      return { ...state, bookingTrackingProps: action.bookingTrackingProps };
    default:
      return state;
  }
}

type NextStepsAction = {
  onAddPlaceToFavorites: () => void;
  onBookSameService: () => void;
};

const useConfirmation = (): {
  booking: ConfirmedBooking | null;
  error: boolean;
  redirect?: Redirect;
  loading: boolean;
  selectedPaymentMethod?: SelectedPaymentMethod;
  bookingTrackingProps?: CheckoutTracking.Booking | null;
  isActivatePaymentFlow: boolean;
  nextStepsAction: () => NextStepsAction;
} => {
  const location = useLocation();
  const selectedPaymentMethod = location.state?.selectedPaymentMethod;
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    selectedPaymentMethod,
    isActivatePaymentFlow: Boolean(location.state?.isActivatePaymentFlow),
  });
  const history = useHistory();
  const searchParams = Object.fromEntries(new URLSearchParams(location.search));
  const validatedSearchParams = searchParamsSchema.safeParse(searchParams);
  const user = useAppSelector((state) => state.users)?.user;
  const appDispatch = useDispatch();
  const isActivatePaymentFlow =
    (validatedSearchParams.success && activatePaymentParamSchema.safeParse(validatedSearchParams.data).success) ||
    state.isActivatePaymentFlow;

  useEffect(() => {
    if (!state?.booking) return;

    const sendBookingTrackingEvents = async () => {
      const bookingTrackingProps = await getBookingTrackingProps(state.booking);
      if (!bookingTrackingProps) return;
      dispatch({ type: 'SET_TRACKING_PROPS', bookingTrackingProps });
      sendBookingConfirmationSuccessEvent(bookingTrackingProps);
    };

    const sendActivatePaymentTrackingEvents = async () => {
      const paymentTrackingProps = bookActions.getTrackingProps();
      bookActions.clearTrackingProps();

      if (isEmpty(paymentTrackingProps)) {
        return;
      }
      dispatch({ type: 'SET_TRACKING_PROPS', bookingTrackingProps: paymentTrackingProps });

      trackScreenView({
        name: 'screen_view_checkout_booking_update_payment_method_confirmation',
        properties: paymentTrackingProps,
      });

      trackMpEvent('checkout_success', { ...paymentTrackingProps, checkout_type: 'payment_update' });
    };

    if (state?.booking?.bookingCode) {
      if (validatedSearchParams.success === false) {
        history.replace({
          pathname: `/booking/confirmation`,
          search: `?b=${state?.booking?.bookingCode}`,
          state: {
            selectedPaymentMethod,
          },
        });
        sendBookingTrackingEvents();
        return;
      }

      if (isActivatePaymentFlow) {
        sendActivatePaymentTrackingEvents();
      } else {
        sendBookingTrackingEvents();
      }

      history.replace({
        pathname: `/booking/confirmation`,
        search: `?b=${state?.booking?.bookingCode}`,
        state: { selectedPaymentMethod, isActivatePaymentFlow },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.booking]);

  useEffect(() => {
    if (validatedSearchParams.success === false) {
      Sentry.captureException(validatedSearchParams.error);
      dispatch({ type: 'SET_ERROR' });
      return;
    }

    getAndSetBookingDetails(validatedSearchParams.data, isActivatePaymentFlow, dispatch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useWindowOnUnload({ fn: () => (() => clearBookState(state, appDispatch))(), callOnCleanup: true });

  const nextStepsAction = () => {
    const services =
      state.booking.services
        .map((appointment) =>
          appointment && appointment.service && appointment.service.id ? appointment.service : null,
        )
        .filter((s) => s) || [];

    const employee = getBookingEmployee(state.booking, true);
    const place = state.booking?.place;

    return {
      onAddPlaceToFavorites: () => handleAddPlaceToFavorites(user, place?.id, appDispatch),
      onBookSameService: () => {
        bookSameServiceAgain = true;
        bookActions.clearTrackingProps();
        setBookingStartingPoint('book_again_booking_completed');

        const ids = services.map((service) => service.id);
        const filteredServices = services.filter(({ id }, index) => !ids.includes(id, index + 1));

        // @ts-ignore
        appDispatch(bookActions.bookAgain(place, filteredServices, employee));

        const pathname = `/boka-tjanst/${place.about.slug ? place.about.slug + '-' : ''}${place.id}/${
          services[0].about.slug ? services[0].about.slug + '-' : ''
        }${services[0].id}`;

        history.push({ pathname });
      },
    };
  };

  return {
    error: state.error,
    redirect: state.redirect,
    booking: state.booking,
    loading: state.loading,
    selectedPaymentMethod: state.selectedPaymentMethod,
    bookingTrackingProps: state.bookingTrackingProps,
    isActivatePaymentFlow: state.isActivatePaymentFlow,
    nextStepsAction,
  };
};

export default useConfirmation;
