import { CHECKOUT_PAYMENT_METHOD } from '@/constants/checkout';
import { cofPaymentDataSchema } from '@/types/adyen';
import { giftcardSchema } from '@/types/state/book';
import { z } from 'zod';

export const submitAdditionalDetailsResponseSchema = z.object({
  id: z.number(),
  start: z.number(),
  end: z.number(),
});

export const adyenPaymentMethodSchema = z.union([
  z.literal(CHECKOUT_PAYMENT_METHOD.APPLE_PAY),
  z.literal(CHECKOUT_PAYMENT_METHOD.GOOGLE_PAY),
  z.literal(CHECKOUT_PAYMENT_METHOD.STORED_COF),
]);

export type AdyenPaymentMethod = z.infer<typeof adyenPaymentMethodSchema>;

export type SubmitAdditionalDetailsResponse = z.infer<typeof submitAdditionalDetailsResponseSchema>;

export const createPaymentAcceptedResponseSchema = z.object({
  type: z.literal('Accepted'),
  id: z.number(),
});

export type CreatePaymentAcceptedResponse = z.infer<typeof createPaymentAcceptedResponseSchema>;

export const createPaymentActionRequiredResponseSchema = z.object({
  type: z.literal('ActionRequired'),
  adyenActionJson: z.string(),
  adyenPaymentData: z.string().optional(),
});

export type CreatePaymentActionRequiredResponse = z.infer<typeof createPaymentActionRequiredResponseSchema>;

export const createPaymentRefusedResponseSchema = z.object({
  type: z.literal('Refused'),
  adyenError: z.object({
    message: z.string(),
    resultCode: z.union([z.string(), z.number()]).optional(),
    refusalReason: z.string().optional(),
    refusalReasonCode: z.string().optional(),
  }),
  userError: z.string().optional(),
});

export const createPaymentResponseSchema = z.union([
  createPaymentAcceptedResponseSchema,
  createPaymentActionRequiredResponseSchema,
  createPaymentRefusedResponseSchema,
]);

export type CreatePaymentResponse = z.infer<typeof createPaymentResponseSchema>;

const createBookingPaymentRequestSchema = z.object({
  type: z.literal('booking'),
  id: z.number(),
  paymentData: cofPaymentDataSchema,
  paymentMethod: adyenPaymentMethodSchema.optional(),
  giftcards: z.array(giftcardSchema).optional(),
});

const createBundlePaymentRequestSchema = z.object({
  type: z.literal('bundle'),
  id: z.number(),
  placeId: z.number(),
  paymentData: cofPaymentDataSchema,
  formValues: z.object({ 1: z.object({ nationalId: z.string() }) }).optional(),
});

export const createPaymentRequestSchema = z.union([
  createBookingPaymentRequestSchema,
  createBundlePaymentRequestSchema,
]);

export type CreatePaymentRequest = z.infer<typeof createPaymentRequestSchema>;

export const submitAdditionalDetailRequestSchema = z.object({
  type: z.union([z.literal('booking'), z.literal('bundle')]),
  details: z.object({
    MD: z.string(),
    PaReq: z.string(),
    PaRes: z.string(),
    billingToken: z.string(),
    cupsecureplus_smscode: z.string(),
    facilitatorAccessToken: z.string(),
    oneTimePasscode: z.string(),
    orderID: z.string(),
    payerID: z.string(),
    payload: z.string(),
    paymentID: z.string(),
    paymentStatus: z.string(),
    redirectResult: z.string(),
    threeDSResult: z.string(),
    threeds2_challengeResult: z.string(),
    threeds2_fingerprint: z.string(),
  }),
  paymentData: z.string().optional(),
  paymentMethod: adyenPaymentMethodSchema,
});

export type SubmitAdditionalDetailsRequest = z.infer<typeof submitAdditionalDetailRequestSchema>;
