import Icon from '@/components/icons/Icon';
import { classnames } from '@/helpers';
import { twMerge } from 'tailwind-merge';
import HR from '../../HR/HR';
import Header, { HeaderProps } from '../../Header/Header';

type AccordionSize = 'lg' | 'md' | 'sm';

type AccordionProps = {
  size?: AccordionSize;
  className?: string;
  expanded?: boolean;
} & Omit<HeaderProps, 'size'>;

const Accordion = ({ size = 'lg', expanded, label, className = '', ...headerProps }: AccordionProps) => {
  return (
    <div className={twMerge(classnames('pl-lg pt-sm space-y-sm flex flex-col ', className))}>
      <div className="pr-lg gap-md flex justify-between">
        {label && (
          <Header
            size={size}
            label={label}
            rightIcon={<Icon variant={expanded ? 'chevron-s-up' : 'chevron-s-down'} />}
            {...headerProps}
          />
        )}
      </div>
      <HR />
    </div>
  );
};

export default Accordion;
