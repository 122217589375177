import { AmplitudeExperimentContext, AmplitudeExperimentContextType } from '@/hooks/useAmplitudeExperiment';
import { useContext } from 'react';

function withAmplitudeExperiment<P>(WrappedComponent: React.ComponentType<P & AmplitudeExperimentContextType>) {
  const WithAmplitudeExperiment = (props: P) => {
    const { getVariant, initialize } = useContext(AmplitudeExperimentContext);
    return <WrappedComponent {...props} getVariant={getVariant} initialize={initialize} />;
  };
  return WithAmplitudeExperiment;
}

export default withAmplitudeExperiment;
