import { isServer } from '@/helpers';
import * as Modal from 'react-modal';

import css from './ShadowModal.module.scss';

const getModalProps = () => ({
  overlayClassName: css.overlay,
  appElement: isServer ? null : document.getElementById('root'),
});

const ShadowModal = ({ isOpen, onRequestClose, className = '', contentClass = '', children }) => (
  <Modal {...{ isOpen, onRequestClose, ...getModalProps(), className: `${css.modal} ${className}` }}>
    <div className={`${css.content} ${contentClass}`}>{children}</div>
  </Modal>
);

export default ShadowModal;
