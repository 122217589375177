import { Div, XS } from '@/components/elements/content';
import { _s } from '@/locale';
import css from './GiftCardDisplay.module.scss';

const base = 'useGiftCard';

const GiftCardDisplay = ({ type, title, value, expiryDate }) => (
  <div className={`${css.card} bg-gradient-radial relative max-w-screen-md rounded-lg p-8 text-center shadow sm:p-10`}>
    <h3 className="text-h-m mb-4 font-semibold sm:text-2xl">{title}</h3>
    <XS className="mb-1">{_s(`${base}.giftCard.value`)}</XS>
    <div className="text-secondary text-3xl font-semibold sm:text-4xl">{value} kr</div>
    <div className="mt-4 inline-flex items-center justify-center gap-2">
      <XS className="!text-black-600">{_s(`${base}.giftCard.${type}Title`)}</XS>
      <Div className="bg-black-600 h-1 w-1 rounded-full"></Div>
      <XS className="!text-black-600">
        {_s(`${base}.giftCard.validUntil`)} {expiryDate}
      </XS>
    </div>
  </div>
);

export default GiftCardDisplay;
