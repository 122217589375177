import LoadingPlaceHolder from '@/components/elements/LoadingPlaceholder';
import { SearchIcon } from '@/components/icons';
import { getDateFormattedForFirstAvailableTime } from '@/helpers';
import { __ } from '@/locale';
import Bundle from './Bundle';
import Service from './Service';

function MatchedServices(props) {
  const { place, matchedServices, searchKeyword } = props;
  if (!Array.isArray(matchedServices)) return null;
  const allServicesHash = {};
  place.services.forEach((category) => {
    if (category.services) {
      category.services.forEach((s) => {
        allServicesHash[s.id] = s;
      });
    }
  });

  const services = matchedServices
    .map((ms) => {
      if (ms.isBundle) {
        return ms;
      } else {
        return {
          ...ms,
          ...allServicesHash[ms.id],
        };
      }
    })
    .filter((s) => !!s);

  const servicesHtml = services.map((service, key) => {
    const firstAvailableTime = service.firstAvailableTime
      ? getDateFormattedForFirstAvailableTime(service.firstAvailableTime, place.timezone)
      : null;
    return service.isBundle ? (
      <Bundle key={key} bundle={service} place={place} />
    ) : (
      <Service
        section="based_on_your_search"
        place={place}
        service={service}
        key={key}
        firstAvailableTime={firstAvailableTime}
      />
    );
  });

  const showLoader = services.length === 0;

  return (
    <div id="book" className="details-section">
      <div className="book">
        <h2 className="mb-4 text-2xl font-semibold">{__('basedOnYourSearch')}</h2>
        <span className="mb-4 flex items-center">
          <SearchIcon className="mr-2 h-4 w-4" />
          {searchKeyword}
        </span>
        <div className="services relative bg-white">
          {showLoader && <LoadingPlaceHolder />}
          <ul>{servicesHtml}</ul>
        </div>
        <span className="divider"></span>
      </div>
    </div>
  );
}

export default MatchedServices;
