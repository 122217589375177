import { Card } from '@/components/elements/cards';
import { LogoIcon, SistaMinutenLogoIcon } from '@/components/icons';
import PageViewLayout from '@/components/layouts/PageViewLayout/PageViewLayout';

import SEO from '@/components/modules/SEO';
import Breadcrumbs from '@/components/modules/breadcrumbs/Breadcrumbs';
import { getHomeDescription, isServer, isSistaminuten, trackPage, url } from '@/helpers';
import { __, _s } from '@/locale';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';

const SupportFormSuccess = () => {
  useEffect(() => {
    if (isServer) return;
    window.scrollTo(0, 0);
    trackPage();
    // trackMpEvent('screen_shown', { screen_name: 'support_form_submitted' });
  }, []);

  return (
    <PageViewLayout type="mainView" wrapperClass="bg-gradient">
      <div className="container-fixed">
        <div className="flex flex-wrap justify-between gap-8 lg:flex-nowrap">
          <div className="flex-1 pb-12">
            <Breadcrumbs
              className="my-7 text-sm"
              items={[
                { title: __('Start'), url: '/' },
                { title: __('support'), url: '/support' },
                { title: _s('supportPage.supportForm'), url: '/support/form' },
              ]}
            />
            <div
              className="mx-auto h-[170px] w-auto bg-cover bg-center bg-no-repeat md:h-[213px] lg:max-w-[730px]"
              style={{ backgroundImage: 'url(/images/hero-support-form-submitted.png)' }}>
              <div className="container mx-auto flex h-full items-center justify-center">
                <h1 className="text-lg font-bold text-white lg:text-4xl">{_s('supportPage.questionSubmitted')}</h1>
              </div>
            </div>
            <Card
              style={{ margin: '0 auto' }}
              className="text-black-900 tracking-content flex flex-col rounded-b-lg rounded-t-none bg-white lg:max-w-[730px]">
              <h3 className="flex items-center justify-center text-lg font-bold tracking-normal lg:text-2xl">
                {_s('supportPage.thanks')}
              </h3>
              <span className="my-8 whitespace-pre-wrap text-sm tracking-normal lg:text-base">
                {_s(`supportPage.submittedWithSuccess`)}
              </span>
              <Link
                className="mb-4 flex justify-center"
                to="/"
                aria-label={isSistaminuten() ? 'Sistaminutentider' : 'Bokadirekt'}>
                {isSistaminuten() ? <SistaMinutenLogoIcon className="h-6" /> : <LogoIcon className="h-[22px]" />}
              </Link>
            </Card>
          </div>
        </div>
      </div>
      <SEO
        title={__('seo.helpTitle')}
        description={getHomeDescription()}
        url={`${url.getBaseUrl()}support/form-submitted`}
        image={url.getBaseImageUrl()}>
        {isSistaminuten() && <meta name="robots" content="noindex" />}
      </SEO>
    </PageViewLayout>
  );
};

export default SupportFormSuccess;
