import { Anchor, H1, H2, H3, H4, H5, H6, HStyle, LI, Link, P, UL } from '@/components/elements/content';
import { isString } from './strings';

export type ContentObjectBlock = ParentBlock | LinkBlock | HeadingBlock;
export type ContentBlock = ContentObjectBlock | string;

type ParentBlock = {
  type: 'paragraph' | 'list';
  children: ContentBlock[];
};

type LinkBlock = {
  type: 'link';
  text: string;
  to: string;
};

type HeadingBlock = {
  type: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  text: string;
  styleAs?: HStyle['styleAs'];
};

export const isHeadingBlock = (block: ContentBlock) =>
  !isString(block) && (block as ContentObjectBlock).type.match(/h[1-6]/g);

const parseHeading = (block: HeadingBlock, key: number) => {
  switch (block.type) {
    case 'h1':
      return (
        <H1 {...keyProp(key)} styleAs={block.styleAs}>
          {block.text}
        </H1>
      );
    case 'h2':
      return (
        <H2 {...keyProp(key)} styleAs={block.styleAs}>
          {block.text}
        </H2>
      );
    case 'h3':
      return (
        <H3 {...keyProp(key)} styleAs={block.styleAs}>
          {block.text}
        </H3>
      );
    case 'h4':
      return (
        <H4 {...keyProp(key)} styleAs={block.styleAs}>
          {block.text}
        </H4>
      );
    case 'h5':
      return (
        <H5 {...keyProp(key)} styleAs={block.styleAs}>
          {block.text}
        </H5>
      );
    default:
      return (
        <H6 {...keyProp(key)} styleAs={block.styleAs}>
          {block.text}
        </H6>
      );
  }
};

const keyProp = (key: number) => (key === -1 ? {} : { key });

const parseLink = (block: LinkBlock, key) => {
  return block.to.startsWith('/') ? (
    <Link to={block.to} {...keyProp(key)}>
      {block.text}
    </Link>
  ) : (
    <Anchor href={block.to} target="_blank" {...keyProp(key)}>
      {block.text}
    </Anchor>
  );
};

const parseList = (block: ParentBlock, key: number) => (
  <UL {...keyProp(key)}>
    {block.children.map((child, i) => (
      <LI key={i}>{parseBlock(child, i)}</LI>
    ))}
  </UL>
);

const parseParagraph = (block: ParentBlock, key: number) => (
  <P {...keyProp(key)}>
    {block.children.map((child, i) => (
      <>{parseBlock(child, i)} </>
    ))}
  </P>
);

export const parseBlock = (block: ContentBlock, key: number): JSX.Element => {
  if (isString(block)) return <span>{block as string}</span>;

  if (isHeadingBlock(block)) return parseHeading(block as HeadingBlock, key);

  if ((block as ContentObjectBlock).type === 'link') return parseLink(block as LinkBlock, key);

  if ((block as ContentObjectBlock).type === 'list') return parseList(block as ParentBlock, key);

  if ((block as ContentObjectBlock).type === 'paragraph') return parseParagraph(block as ParentBlock, key);
};

export const parseContent = (blocks: ContentBlock[]) => {
  return <>{blocks.map(parseBlock)}</>;
};
