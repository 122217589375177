import { blog, exportIdSlug } from '@/helpers';
import { useEffect, useState } from 'react';

const useBlogPost = (slugId): Record<string, any> => {
  const { id, slug } = exportIdSlug(slugId);

  const [post, setPost] = useState(null);

  useEffect(() => {
    (async () => {
      const data = await blog.getPost(id, slug);
      setPost(data);
    })();
  }, []);

  return post;
};

export default useBlogPost;
