import { Button } from '@/components/elements/forms/buttons';
import { classnames } from '@/helpers';
import { UserLocationPermissionManager } from '@/hooks/useLocationPermission';
import useTrackScreenView from '@/hooks/useTrackScreenView';
import { _s } from '@/locale';
import Modal from './redesign/Modal/Modal';
import { isGoogleCrawler } from '@/helpers';
export const LOCATION_PERMISSION_LOCAL_STORAGE_KEY = 'userLocationOnboarded';

const baseTranslationKey = 'components.modules.modals.UserLocationOnboarding';

export function getIsUserLocationOnboarded() {
  return localStorage.getItem(LOCATION_PERMISSION_LOCAL_STORAGE_KEY) === 'true';
}

export function setIsUserLocationOnboarded() {
  localStorage.setItem(LOCATION_PERMISSION_LOCAL_STORAGE_KEY, 'true');
}

type UserLocationPermissionProps = {
  isOpen: boolean;
  onClose: () => void;
  onRequestUserLocation: UserLocationPermissionManager['onRequestUserLocationPermission'];
};

export const UserLocationOnboarding = ({ isOpen, onClose, onRequestUserLocation }: UserLocationPermissionProps) => {
  const handleOnClose = () => {
    setIsUserLocationOnboarded();
    onClose();
  };

  const handleOnRequestUserLocation = () =>
    ((cb) => {
      setIsUserLocationOnboarded();
      onRequestUserLocation(cb);
    })();

  useTrackScreenView({ name: 'screen_view_activate_location' }, []);

  if (isGoogleCrawler()) {
    return <></>;
  }
  return (
    <Modal isOpen={isOpen}>
      <Modal.Content floating contentPadding={false} style={{ paddingTop: 0 }}>
        <img src="/images/illustrations/location.png" alt="" />
        <div className={classnames('space-y-lg px-lg text-center')}>
          <div className="space-y-md py-lg">
            <span className="text-lg font-semibold">{_s(`${baseTranslationKey}.title`)}</span>
            <p className="text-black-600 text-sm">{_s(`${baseTranslationKey}.description`)}</p>
          </div>
          <div className="space-y-lg pb-lg">
            <Button variant="primary" block size="sm" onClick={handleOnRequestUserLocation}>
              {_s(`${baseTranslationKey}.cta.activate`)}
            </Button>
            <Button variant="link" block size="sm" onClick={handleOnClose}>
              {_s(`${baseTranslationKey}.cta.skip`)}
            </Button>
          </div>
        </div>
      </Modal.Content>
    </Modal>
  );
};

export default UserLocationOnboarding;
