import { server } from '@/helpers';
import { LandingPage } from '@/types/landingPages';
import { Dispatch, useEffect, useState } from 'react';

export const useLandingPage = (ssrLandingPage: LandingPage | null, slug: string) => {
  const [landingPage, setLandingPage] = useState<LandingPage>(ssrLandingPage ? ssrLandingPage : null);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (ssrLandingPage) return;
    fetchLandingPage(slug, setError, setLandingPage);
  }, []);

  if (ssrLandingPage)
    return {
      landingPage: ssrLandingPage,
      loading: false,
      error: null,
    };

  return { landingPage, error };
};

const fetchLandingPage = async (slug: string, setError: Dispatch<any>, setLandingPage: Dispatch<LandingPage>) => {
  const res = await server.request.get(`/landing-pages/${slug}`).then(server.handleSuccess).catch(server.handleError);

  if ('code' in res) return setError(res);

  setLandingPage(res);
};
