import { __ } from '@/locale';

export const searchConstants = {
  SET_PARAMS: 'SET_PARAMS',
  REMOVE_PARAMS: 'REMOVE_PARAMS',
  RESET: 'RESET',
  START_LOCATION_LOADER: 'START_LOCATION_LOADER',
  STOP_LOCATION_LOADER: 'STOP_LOCATION_LOADER',
  LOCATION_ERROR: 'LOCATION_ERROR',
  STORE_LIST: 'STORE_LIST',
  EXTENDED_SEARCH_START: 'EXTENDED_SEARCH_START',
  EXTENDED_SEARCH_SUCCESS: 'EXTENDED_SEARCH_SUCCESS',
  EXTENDED_SEARCH_ERROR: 'EXTENDED_SEARCH_ERROR',
  EXTENDED_SEARCH_RESET: 'EXTENDED_SEARCH_RESET',
  EXTENDED_SET_PAGE: 'EXTENDED_SET_PAGE',
};

export const LOCATION_PLACEHOLDER = __('Enter city or area');
export const DATE_TIME_PLACEHOLDER = __('when');
