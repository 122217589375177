import { serpSeo } from '../config/serpSeo';
import { isMobile, isSistaminuten, url } from '../helpers';
import { __ } from '../locale';

const getSERPBreadcrumbs = (what, where) => {
  const items = [
    {
      title: __('home'),
      url: '/',
    },
    {
      title: what !== '' || where !== '' ? (what || 'Vad') + ' ' + (where || __('allSweden')) : 'Sök',
      url:
        '/' +
        encodeURIComponent((what || 'vad').toLowerCase()) +
        '/' +
        encodeURIComponent((where || 'var').toLowerCase()),
    },
  ];
  return items;
};

const getDetailsBreadcrumbs = (place, raw = false) => {
  const {
    about: { industries },
    contact: {
      address: { city },
    },
  } = place;
  const what = industries && industries.length > 0 ? industries[0] : 'Vad';
  const where = city || 'var';
  const items = [
    {
      title: __('home'),
      url: '/',
    },
    {
      title: what + ' ' + where,
      url: '/' + encodeURIComponent(what.toLowerCase()) + '/' + encodeURIComponent(where.toLowerCase()),
    },
    {
      title: place.about.name,
      url: encodeURI('/places/' + place.about.slug + '-' + place.id),
    },
  ];
  if (isMobile) {
    items.pop();
  }
  if (raw) {
    return items;
  }
  return items;
};

const getServiceBreadcrumbs = (place, service) => {
  const items = getDetailsBreadcrumbs(place, true);
  items.push({
    title: service.name,
    url:
      url.getBaseUrl() +
      'boka-tjanst/' +
      place?.about?.slug +
      '-' +
      place.id +
      '/' +
      service?.about?.slug +
      '-' +
      service.id,
  });
  return items;
};

const limitString = (str, length) => {
  return str ? str.replace(/(\n|\r)/gm, '').substring(0, length) : '';
};

const getCategoryDescription = (keyword, location = __('allSweden')) => {
  if (!keyword) return null;
  let categoryDescription = null;
  serpSeo.some((data) => {
    if (data.keywords.indexOf(keyword.toLowerCase()) !== -1) {
      categoryDescription = __(data.description + (isSistaminuten() ? '.sistaminuten' : ''))
        .replace('$$preposition$$', getLocationPreposition(location))
        .replace('$$city$$', location !== __('currentLocation') ? location : __('fakeSearchNearWithoutPreposition'));
      return true;
    }
    return false;
  });
  return categoryDescription;
};

const getLocationPreposition = (location) => {
  const defaultPreposition = 'i';
  const citiesWithPaPreposition = [
    'adelsö',
    'alnö',
    'asperö',
    'björkö',
    'blidö',
    'bohus-björkö',
    'bohus-malmön',
    'bolmsö',
    'brännö',
    'dalarö',
    'donsö',
    'ekerö',
    'fotö',
    'frösön',
    'fårö',
    'granö',
    'gräddö',
    'gränö',
    'gräsö',
    'grötö',
    'gålö',
    'gällö',
    'hammarö',
    'harö',
    'holmön',
    'husarö',
    'hälsö',
    'hölö',
    'hönö',
    'ingarö',
    'ingmarsö',
    'käringön',
    'köpstadsö',
    'klädesholmen',
    'lidingö',
    'ljusterö',
    'mörkö',
    'munsö',
    'muskö',
    'nämdö',
    'ornö',
    'resö',
    'rossön',
    'runmarö',
    'rörö',
    'seskarö',
    'smögen',
    'sollenkroka ö',
    'sollerön',
    'sturkö',
    'styrsö',
    'särö',
    'tjurkö',
    'utö',
    'vikbolandet',
    'visingsö',
    'vrångö',
    'väddö',
    'värmdö',
    'vätö',
    'öckerö',
    'öland',
    'kinnekulle',
  ];
  const areaWithPaPrepositionEnding = [
    'holmen',
    'gärdet',
    'hisingen',
    'heden',
    'malm',
    'ön',
    'berget',
    'kulle',
    'gården',
  ];
  const areaWithVidPrepositionEnding = ['torget', 'station', 'platsen', 'plan'];
  if (location) {
    const trimmedLowercasedLocation = location.trim().toLowerCase();
    if (
      citiesWithPaPreposition.includes(trimmedLowercasedLocation) ||
      (trimmedLowercasedLocation.endsWith('ö') && !trimmedLowercasedLocation.endsWith('sjö')) ||
      areaWithPaPrepositionEnding.some((ending) => trimmedLowercasedLocation.endsWith(ending))
    ) {
      return 'på';
    }
    if (areaWithVidPrepositionEnding.some((ending) => trimmedLowercasedLocation.endsWith(ending))) {
      return 'vid';
    }
    return 'i';
  } else {
    return defaultPreposition;
  }
};

const getHomeDescription = () => {
  return __(isSistaminuten() ? 'seo.sistaminuten.homepageDescription' : 'seo.homepageDescription');
};

export {
  getSERPBreadcrumbs,
  getDetailsBreadcrumbs,
  getServiceBreadcrumbs,
  limitString,
  getCategoryDescription,
  getLocationPreposition,
  getHomeDescription,
};
