import { Button } from '@/components/elements/forms/buttons';
import { TopTabBarItem } from '@/components/elements/tabs/types';
import PageViewLayout from '@/components/layouts/PageViewLayout/PageViewLayout';
import BundlesTab from '@/components/modules/pages/klippkort/BundlesTab';
import SEO, { seoPropsFromBaseString } from '@/components/modules/SEO';
import EmptyState from '@/components/templates/EmptyState';
import { SCREEN_NAME } from '@/constants/analytics';
import { trackMpEvent } from '@/helpers';
import { useAppSelector } from '@/hooks';
import useTrackScreenView from '@/hooks/useTrackScreenView';
import { _s } from '@/locale';
import { getScreenName, handleLoginClick } from '@/services/navigationServices';
import { NavItemIdentifier } from '@/types/navigation';
import { Fragment, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';

const baseTranslationKey = 'pages.klippkort.Bundles';

function onTrackMenuNavigationClick(bundle_status: 'valid' | 'consumed' | 'expired') {
  trackMpEvent(`${SCREEN_NAME.MY_BUNDLE_OVERVIEW}_navigation_click`, { bundle_status });
}

const menu: TopTabBarItem[] = [
  {
    label: _s('bundle.valid'),
    to: '/klippkort/giltig',
    onClick: () => onTrackMenuNavigationClick('valid'),
  },
  {
    label: _s('bundle.used'),
    to: '/klippkort/anvand',
    onClick: () => onTrackMenuNavigationClick('consumed'),
  },
  {
    label: _s('bundle.expired'),
    to: '/klippkort/forfallen',
    onClick: () => onTrackMenuNavigationClick('expired'),
  },
];

const Bundles = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const { user } = useAppSelector((state) => state.users);

  const isLogged = Boolean(user && user.token);

  const handleOnBackButtonClick = () => {
    /**
     * if user navigated to this page from details page, avoid going back to details page
     * on header navigation back click
     */
    if (location.state?.fromDetails) {
      history.push('/');
      return;
    }

    /**
     * do not navigate between tabs when clicking header navigation back click
     */
    if (menu.some((menuItem) => menuItem.to.includes(location?.state?.from))) {
      history.push('/');
    } else {
      history.goBack();
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useTrackScreenView({ name: 'screen_view_my_bundles_overview' });

  if (!isLogged) {
    return (
      <Fragment key="no-user">
        <PageViewLayout
          type="subView"
          wrapperClass="bg-gradient"
          onBackButtonClick={handleOnBackButtonClick}
          title={_s(`${baseTranslationKey}.title`)}>
          <EmptyState
            title={_s(`${baseTranslationKey}.emptystate.title`)}
            body={_s(`${baseTranslationKey}.emptystate.body`)}
            icon={<img src="/images/illustrations/bundles.svg" alt="" />}
            cta={
              <Button
                label={_s(`${baseTranslationKey}.emptystate.cta`)}
                size="sm"
                onClick={() => handleLoginClick(dispatch, NavItemIdentifier.LogIn, getScreenName(undefined))}
              />
            }
          />
        </PageViewLayout>
      </Fragment>
    );
  }

  return (
    <Fragment key="user">
      <PageViewLayout
        type="subView"
        back
        onBackButtonClick={handleOnBackButtonClick}
        title={_s(`${baseTranslationKey}.title`)}>
        <div className="pt-lg container max-w-screen-md">
          <Switch>
            <Route
              path="/klippkort/giltig"
              children={<BundlesTab key="valid" filter="uFilterBundleValid" menu={menu} />}
            />
            <Route
              path="/klippkort/anvand"
              children={<BundlesTab key="used" filter="uFilterBundleUsed" menu={menu} />}
            />
            <Route
              path="/klippkort/forfallen"
              children={<BundlesTab key="expired" filter="uFilterBundleExpired" menu={menu} />}
            />
          </Switch>
        </div>
        <SEO {...seoPropsFromBaseString('appointments', '/klippkort')} image={'/images/home-background.jpeg'} />
      </PageViewLayout>
    </Fragment>
  );
};

export default Bundles;
