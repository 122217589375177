import ApplyGiftcard from '@/components/modules/checkout/ApplyGiftcard/ApplyGiftcard';
import PickPaymentMethod from '@/components/modules/checkout/PickPaymentMethod/PickPaymentMethod';
import Modal from '@/components/modules/modals/redesign/Modal/Modal';
import { bookConstants } from '@/constants';
import { CHECKOUT_PAYMENT_METHOD } from '@/constants/checkout';
import { classnames } from '@/helpers';
import { useAppDispatch, useAppSelector } from '@/hooks';
import useMobileView from '@/hooks/useMobileView';
import { _s } from '@/locale';
import { TriggerSource } from '@/types/analytics';
import { CheckoutSummary, CouponMethod, SelectedPaymentMethod } from '@/types/checkout';
import { UseCheckoutModalManagerResult } from './CheckoutModal.hooks';

const baseTranslationKey = 'components.modules.checkout.CheckoutModal';

export type CheckoutModalScreen = 'list' | 'cof' | 'applyGiftcard';

export type CheckoutModalProps = {
  summary: CheckoutSummary;
  selectedPaymentMethod: SelectedPaymentMethod;
  onChangePaymentMethod: (method: SelectedPaymentMethod) => void;
  onApplyGiftcard?: (code: string) => void;
} & UseCheckoutModalManagerResult & { triggerSource: TriggerSource };

const CheckoutModal = ({
  isOpen,
  screen,
  summary,
  onModalBack,
  onModalClose,
  onModalShow,
  onChangePaymentMethod,
  onApplyGiftcard,
  triggerSource,
}: CheckoutModalProps) => {
  const bookState = useAppSelector((state) => state.book);
  const appDispatch = useAppDispatch();
  const { errorGiftCard, validateGiftCard } = bookState;
  const { isMobileView } = useMobileView();

  const showBack = screen === 'applyGiftcard' || screen === 'applyValuecard' || screen === 'applyWellnesscard';

  const handleChangePaymentMethod = (method: SelectedPaymentMethod | CouponMethod) => {
    if (method.type === CHECKOUT_PAYMENT_METHOD.NEW_COF) {
      onModalShow('cof');
    }
    if (method.type === 'GIFTCARD') {
      onModalShow('applyGiftcard');
      return;
    }
    if (method.type === 'VALUECARD') {
      onModalShow('applyValuecard');
      return;
    }
    if (method.type === 'WELLNESSCARD') {
      onModalShow('applyWellnesscard');
      return;
    }

    onChangePaymentMethod(method);
  };

  const handleOnAppyGiftcard = (code: string) => {
    if (screen === 'applyGiftcard') {
      if (code.toLowerCase().startsWith('f-')) {
        appDispatch({ type: bookConstants.ERROR_GIFTCARD, error: _s('invalidGiftcardCode') });
        return;
      }
    }

    if (screen === 'applyWellnesscard') {
      if (!code.toLowerCase().startsWith('f-')) {
        appDispatch({ type: bookConstants.ERROR_GIFTCARD, error: _s('invalidWellnesscardCode') });
        return;
      }
    }

    onApplyGiftcard(code);
  };

  return (
    <Modal isOpen={isOpen}>
      <Modal.Content floating={!isMobileView}>
        <Modal.Header
          title={_s(`${baseTranslationKey}.title.${screen}`)}
          {...(showBack && { onBack: onModalBack })}
          onClose={onModalClose}
        />
        <div className={classnames(isMobileView && 'px-lg')}>
          {screen === 'list' && (
            <PickPaymentMethod
              triggerSource={triggerSource}
              onPaymentSelected={handleChangePaymentMethod}
              summary={summary}
            />
          )}
          {screen === 'applyGiftcard' && (
            <ApplyGiftcard
              type="giftcard"
              onApplyGiftcard={handleOnAppyGiftcard}
              error={errorGiftCard}
              validating={validateGiftCard}
            />
          )}
          {screen === 'applyValuecard' && (
            <ApplyGiftcard
              type="valuecard"
              onApplyGiftcard={handleOnAppyGiftcard}
              error={errorGiftCard}
              validating={validateGiftCard}
            />
          )}
          {screen === 'applyWellnesscard' && (
            <ApplyGiftcard
              type="wellnesscard"
              onApplyGiftcard={handleOnAppyGiftcard}
              error={errorGiftCard}
              validating={validateGiftCard}
            />
          )}
        </div>
      </Modal.Content>
    </Modal>
  );
};

export default CheckoutModal;
