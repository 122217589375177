import useMobileView from '@/hooks/useMobileView';
import { _s } from '@/locale';
import QRCode from 'react-qr-code';
import Modal, { ModalProps } from './redesign/Modal/Modal';

import { Button } from '@/components/elements/forms/buttons';
import { config } from '@/config';

const kochavaLink = config.waitlistModalKochavaLink;
const baseTranslationKey = () => `waitlistModal`;

const WaitlistModal = ({ isOpen, onRequestClose }: ModalProps) => {
  const { isMobileView } = useMobileView();
  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose}>
      <Modal.Content floating={true}>
        <Modal.Header title={_s(`${baseTranslationKey()}.title`)} onClose={onRequestClose} />
        <div className={`flex flex-col `}>
          <div className="flex-col items-center justify-center">
            <p className="text-sm">{_s(`${baseTranslationKey()}.description`)}</p>
          </div>
        </div>
        <div className={`flex flex-col `}>
          <div className="flex flex-row items-center justify-center">
            <div className="pb-md  pr-2.5">
              <span className="tag-warning m-auto flex-1 rounded-md px-2.5 py-1.5 font-normal">{_s('NOVELTY!')}</span>
            </div>
            <div className="pb-md flex-1 ">
              <p className="flex-1 text-sm">{_s(`${baseTranslationKey()}.availability`)}</p>
            </div>
          </div>

          <div className="pb-md flex-col items-center justify-center">
            <p className="text-sm font-bold">{_s(`${baseTranslationKey()}.info`)}</p>
          </div>

          {!isMobileView && (
            <div className="flex flex-row ">
              <div className="flex-2 h-[110px] w-[110px]">
                <QRCode
                  size={110}
                  style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
                  value={kochavaLink}
                  viewBox={`0 0 110 110`}
                />
              </div>
              <div className="pl-md flex flex-1 text-left">
                <span className="flex-1 whitespace-pre-wrap text-[15px] italic leading-[22px]">
                  {_s(`${baseTranslationKey()}.howTo`)}
                </span>
              </div>
            </div>
          )}
        </div>

        {isMobileView && (
          <Modal.Footer>
            <Button variant="link" size="sm" onClick={onRequestClose}>
              {_s(`${baseTranslationKey()}.later`)}
            </Button>
            <Button
              variant="primary"
              size="sm"
              onClick={() => {
                window.open(kochavaLink, '_blank');
              }}>
              {_s(`${baseTranslationKey()}.download`)}
            </Button>
          </Modal.Footer>
        )}
      </Modal.Content>
    </Modal>
  );
};

export default WaitlistModal;
