import * as analyticsConstants from '@/constants/analytics';
import { CHECKOUT_PAYMENT_METHOD } from '@/constants/checkout';
import { CheckoutPaymentMethod } from '@/types/checkout';
import { captureMessage } from '@sentry/react';

export function getPaymentMethodLabel(paymentMethod: CheckoutPaymentMethod) {
  switch (paymentMethod) {
    case CHECKOUT_PAYMENT_METHOD.NONE:
      return analyticsConstants.BLANK_VALUE;
    case CHECKOUT_PAYMENT_METHOD.PAY_AT_PLACE:
      return analyticsConstants.PAYMENT_METHOD.PAY_IN_SALON;
    case CHECKOUT_PAYMENT_METHOD.KLARNA:
      return analyticsConstants.PAYMENT_METHOD.KLARNA;
    case CHECKOUT_PAYMENT_METHOD.QLIRO:
      return analyticsConstants.PAYMENT_METHOD.QLIRO;
    case CHECKOUT_PAYMENT_METHOD.STORED_COF:
    case CHECKOUT_PAYMENT_METHOD.NEW_COF:
      return analyticsConstants.PAYMENT_METHOD.CARD;
    case CHECKOUT_PAYMENT_METHOD.GOOGLE_PAY:
      return analyticsConstants.PAYMENT_METHOD.GOOGLE_PAY;
    case CHECKOUT_PAYMENT_METHOD.APPLE_PAY:
      return analyticsConstants.PAYMENT_METHOD.APPLE_PAY;
    case CHECKOUT_PAYMENT_METHOD.SWISH:
      return analyticsConstants.PAYMENT_METHOD.SWISH;
    default:
      let never: never = paymentMethod;
      console.error(`Unknown payment method: ${never}`);
      captureMessage(`[getPaymentMethodLabel] Unknown payment method: ${never}`);
      return analyticsConstants.PAYMENT_METHOD.PAY_IN_SALON;
  }
}
