import { LoadingIcon } from '@/components/icons';
import PageViewLayout from '@/components/layouts/PageViewLayout/PageViewLayout';
import { KlarnaPaymentsPurchaseConfirmation } from '@/components/modules/klarna/KlarnaPaymentsConfirmation';
import { exportIdSlug, trackMpAnalyticsEvent, trackMpEvent, trackMpRevenueGiftcards, trackPage } from '@/helpers';
import { __ } from '@/locale';
import { useConfirmValueCardOrder } from '@/services/vcPaymentServices';
import * as queryString from 'query-string';
import { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';

const base = 'buyValueCardConfirmation';

const trackValueCardPurchase = (response, klarnaId, placeId, slugId) => {
  if (response.valueCard[0]?.greeting === 'order_created') return;

  trackMpAnalyticsEvent('gift_card_purchase_success', {
    card_type: 'individual',
    amount: response.amount,
  });
  trackMpAnalyticsEvent('gc_purchase_success', {
    gc_type: 'individual',
  });
  trackMpEvent('value_card_purchase', {
    klarna_order_id: klarnaId,
    place_id: placeId,
    order_value: response.amount,
  });

  trackPage();

  trackMpEvent('screen_shown', {
    screen_name: 'value_card_confirmation',
  });

  trackMpRevenueGiftcards({
    value: response.amount || 0,
    currency: 'SEK',
    contentName: 'VC',
    contentType: 'product',
    description: 'value_card',
    orderId: klarnaId,
  });
};

const ValueCardsBuyConfirmation = () => {
  const location = useLocation();
  const query = location.search ? queryString.parse(location.search) : {};

  const { slugId } = useParams();
  const { id } = exportIdSlug(slugId);
  const klarnaId = query.klarna_order_id ?? '';
  const [response, loading, error] = useConfirmValueCardOrder(id, klarnaId);
  const email = response?.valueCard ? response.valueCard[0].email : '';

  useEffect(() => {
    if (!response || response.error) return;

    trackValueCardPurchase(response, klarnaId, id, slugId);
  }, [response]);

  return (
    <PageViewLayout
      back
      backSrc={`/places/${slugId}`}
      backIcon="close-alt"
      type="subView"
      source={base}
      title={__(`${base}.title`)}>
      <div className="container-fixed py-lg">
        {loading && <LoadingIcon className="mx-auto block h-10 w-10" />}
        {error && <p className="text-center">{__(`${base}.error`)}</p>}
        {response && (
          <KlarnaPaymentsPurchaseConfirmation
            email={email}
            tax={response.tax}
            paymentMethod={response.paymentMethod}
            orderLines={response.orderLines}
            totalAmount={response.totalAmount}
            ctaButtonText={__(`${base}.ctaButtonText`)}
            ctaButtonLink={`/places/${slugId}/valuecards/buy`}
          />
        )}
      </div>
    </PageViewLayout>
  );
};

export default ValueCardsBuyConfirmation;
