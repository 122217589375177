import FocusLock from '@/components/FocusLock';
import HeightExpandableArea from '@/components/elements/forms/HeightExpandableArea/HeightExpandableArea';
import { ChevronIcon } from '@/components/icons';
import { Dispatch, SetStateAction, useState } from 'react';
import css from './BorderLabelDropdown.module.scss';

type Props = {
  selected: string;
  setSelected: Dispatch<SetStateAction<string>>;
  options: DropdownOption[];
  label?: string;
};

type DropdownOption = {
  value: string;
  label: string;
};

const BorderLabelDropdown = ({ selected = null, setSelected, options = [], label = '' }: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  const changeSelected = (value: string) => () => {
    setIsOpen(false);
    setSelected(value);
  };

  const closeSoon = () => setTimeout(() => setIsOpen(false), 50);

  return (
    <FocusLock className={css.container}>
      <button className={css.header} onClick={() => setIsOpen(!isOpen)} onBlur={closeSoon}>
        <label className={`mb-0 ${css.label} ${selected !== null ? css.populated : ''}`}>{label}</label>
        {options.find((opt) => opt.value === selected)?.label}
        <ChevronIcon className={`color-primary h-6 w-4 ${isOpen ? 'rot-z-180' : ''}`} />
      </button>
      <HeightExpandableArea expanded={isOpen} className={`${css.listContainer} ${isOpen ? css.expanded : ''}`}>
        <ul className={css.list}>
          {options.map((opt, i) => (
            <li key={i} tabIndex={0} onClick={changeSelected(opt.value)}>
              {opt.label}
            </li>
          ))}
        </ul>
      </HeightExpandableArea>
    </FocusLock>
  );
};

export default BorderLabelDropdown;
