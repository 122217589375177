import { server } from '@/helpers';

export const blogServices = {
  list,
  category,
  author,
  latest,
  featured,
};

function list(page) {
  return server.request
    .get('/blog/get-list', { params: { page } })
    .then((response) => {
      const { data } = response;
      return {
        page: { current: page, total: data.totalPages },
        posts: data.posts,
        loading: false,
      };
    })
    .catch(server.handleError);
}

function category(slug, page) {
  return server.request
    .get('/blog/get-category-list', { params: { page, slug } })
    .then((response) => {
      const { data } = response;
      return {
        page: { current: page, total: data.totalPages },
        posts: data.posts,
        loading: false,
        category: data.category,
      };
    })
    .catch(server.handleError);
}

function author(slug, page) {
  return server.request
    .get('/blog/get-author-list', { params: { page, slug } })
    .then((response) => {
      const { data } = response;
      return {
        page: { current: page, total: data.totalPages },
        posts: data.posts,
        loading: false,
        author: data.author,
      };
    })
    .catch(server.handleError);
}

function latest() {
  return server.request
    .get('/blog/latest-posts')
    .then((response) => {
      const { data } = response;
      return {
        latest: data.posts,
      };
    })
    .catch(server.handleError);
}

function featured() {
  return server.request
    .get('/blog/featured-post')
    .then((response) => {
      const { data } = response;
      return { featured: data };
    })
    .catch(server.handleError);
}
