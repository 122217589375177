function scrollAccordionContentInView(target) {
  const rect = target.getBoundingClientRect();
  const modal = document.body.classList.contains('ReactModal__Body--open')
    ? document.querySelector('.modal-overlay')
    : null;

  if (!(rect.bottom + 24 <= (window.innerHeight || document.documentElement.clientHeight))) {
    if (modal) {
      const currentPositionOfPage = modal.scrollTop;
      modal.scrollTo({
        top: currentPositionOfPage + rect.height,
        behavior: 'smooth',
      });
    } else {
      const currentPositionOfPage = window.scrollY || document.documentElement.scrollTop;
      window.scrollTo({
        top: currentPositionOfPage + rect.height,
        behavior: 'smooth',
      });
    }
  }
}

export { scrollAccordionContentInView };
