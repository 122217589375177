import { blogCategories } from '@/constants';
import { _s } from '@/locale';
import { Link } from 'react-router-dom';

const CategoryNav = () => (
  <nav className="-mx-3.5 my-4 flex flex-wrap justify-center md:-mx-6">
    {blogCategories.map((category, i) => (
      <Link
        to={category.to}
        key={i}
        className={`!text-secondary-500 mx-3.5 p-2 text-sm font-semibold md:mx-6 md:p-6 md:text-lg md:font-normal`}>
        {_s(`blogPage.categoryNav.${category.title}`)}
      </Link>
    ))}
  </nav>
);

export default CategoryNav;
