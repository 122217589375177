import { useCallback, useState } from 'react';

const useLocalStorage = <TValue>(key: string, initialValue: TValue) => {
  const stored = useCallback((): TValue => {
    if (typeof window === 'undefined') {
      return initialValue;
    }
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      return initialValue;
    }
  }, [initialValue, key]);

  const [storedValue, setStoredValue] = useState<TValue>(stored);

  const setValue = (value: TValue) => {
    try {
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      if (typeof window !== 'undefined') {
        window.localStorage.setItem(key, JSON.stringify(valueToStore));
      }
    } catch (error) {
      console.error(`Error setting localStorage key “${key}”:`, error);
    }
  };
  const removeValue = (key: string) => {
    window.localStorage.removeItem(key);
  };
  return { storedValue, setValue, removeValue };
};

export default useLocalStorage;
