import { h4Class } from '@/components/elements/content';
import TextInput from '@/components/elements/forms/TextInput';
import { Button } from '@/components/elements/forms/buttons';
import { BeautyLabSmallIcon, LoadingIcon, MailIcon } from '@/components/icons';
import { isNotEmptyStr, useNewsLetter, validate } from '@/helpers';
import { _s } from '@/locale';
import { useEffect, useState } from 'react';
import isEmail from 'validator/lib/isEmail';

const base = 'blogPage.banner';

const validations = {
  firstName: validate([isNotEmptyStr], _s(`${base}.form.firstName.error`)),
  lastName: validate([isNotEmptyStr], _s(`${base}.form.lastName.error`)),
  email: validate([isNotEmptyStr, isEmail], _s(`${base}.form.email.error`)),
};

const NewsletterBanner = ({ noLogo = false }) => {
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(null);
  const [firstName, setFirstName] = useState('');
  const [firstNameError, setFirstNameError] = useState(null);
  const [lastName, setLastName] = useState('');
  const [lastNameError, setLastNameError] = useState(null);
  const [submitDisabled, setSubmitDisabled] = useState(false);

  const [loading, subscribeMessage, subscribeError, submitNewsletter] = useNewsLetter(firstName, lastName, email);

  const validateFirstName = (value) => setFirstNameError(validations.firstName(value));
  const validateLastName = (value) => setLastNameError(validations.lastName(value));
  const validateEmail = (value) => setEmailError(validations.email(value));

  useEffect(
    () => setSubmitDisabled(firstNameError || lastNameError || emailError),
    [firstNameError, lastNameError, emailError],
  );

  const onSubmit = (event) => {
    event.preventDefault();
    const firstNameValidation = validations.firstName(firstName);
    const lastNameValidation = validations.lastName(lastName);
    const emailValidation = validations.email(email);
    setFirstNameError(firstNameValidation);
    setLastNameError(lastNameValidation);
    setEmailError(emailValidation);

    if (firstNameValidation || lastNameValidation || emailValidation) return;

    submitNewsletter();
  };

  return (
    <div className="bg-secondary-50 w-full">
      <div className="mx-auto w-full max-w-2xl flex-col-reverse flex-wrap px-8 py-10 text-center md:flex-row md:flex-nowrap md:px-4">
        {!noLogo && (
          <BeautyLabSmallIcon className="text-secondary-200 md:text-secondary-500 mx-auto mb-6 w-20 md:w-40" />
        )}
        <h3 className={h4Class}>{_s(`${base}.title`)}</h3>
        <p className="mb-4">{_s(`${base}.desc`)}</p>
        <div className="relative">
          {(subscribeError || !subscribeMessage) && (
            <form className={`${loading ? 'opacity-0' : ''} transition-opacity`} onSubmit={onSubmit}>
              <div className="mb-2 md:flex">
                <TextInput
                  value={firstName}
                  className="mb-2 mr-1 flex-1 md:mb-0"
                  onChange={setFirstName}
                  error={firstNameError}
                  placeholder={_s(`${base}.form.firstName.label`)}
                  onBlur={validateFirstName}
                />
                <TextInput
                  value={lastName}
                  className="mr-1 flex-1"
                  onChange={setLastName}
                  error={lastNameError}
                  placeholder={_s(`${base}.form.firstName.label`)}
                  onBlur={validateLastName}
                />
              </div>
              <div className="mb-2">
                <TextInput
                  value={email}
                  className="mr-1 flex-1"
                  onChange={setEmail}
                  error={emailError}
                  placeholder={_s(`${base}.form.email.label`)}
                  onBlur={validateEmail}
                  icon={MailIcon}
                />
              </div>
              <Button block size="lg" disabled={submitDisabled}>
                {_s(`${base}.form.subscribe`)}
              </Button>
            </form>
          )}
          {loading && <LoadingIcon className="absolute left-1/2 top-1/2 h-10 w-10 -translate-x-1/2 -translate-y-1/2" />}
          {subscribeError ? (
            <div className="text-danger">{subscribeMessage}</div>
          ) : (
            <div className="text-primary">{subscribeMessage}</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default NewsletterBanner;
