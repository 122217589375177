import { Card, CardHeader } from '@/components/elements/cards';
import Input from '@/components/elements/forms/Input/Input';
import { Switch } from '@/components/elements/forms/Switch';
import { ListItem, ListItemContent } from '@/components/elements/lists';
import Icon from '@/components/icons/Icon';
import { __ } from '@/locale';
import { useState } from 'react';

const baseTranslation = 'components.modules.giftCards';

const SSNFormCard = ({ ssn, setSSN, setSelectedServiceType }) => {
  const [selectedService, setSelectedService] = useState('yoga');
  const [isConnectedToCompanies, setIsConnectedToCompanies] = useState(false);
  const title = __('buyGiftcard.wellness.ssn.title');
  const description = __('buyGiftcard.wellness.ssn.description');
  const serviceDescription = __('buyGiftCardWellness.servicePicker.serviceDescription');
  const companyDescription = __('buyGiftCardWellness.servicePicker.companyDescription');
  const companyDescriptionAfterCompanies = __('buyGiftCardWellness.servicePicker.companyDescriptionAfterCompanies');
  const companies = Array.isArray(__('buyGiftCardWellness.servicePicker.companies'))
    ? (__('buyGiftCardWellness.servicePicker.companies') as string[])
    : [];

  const services = {
    yoga: { label: 'Yoga', tax: 600 },
    pt: { label: 'PT', tax: 600 },
    massage: { label: 'Massage', tax: 2500 },
  };

  return (
    <Card>
      <CardHeader>{title}</CardHeader>
      <p className="pb-2">{description}</p>
      <div className="pt-md">
        <Input
          onChange={(e) => {
            setSSN(e.target.value);
          }}
          placeholder=""
          value={ssn ?? ''}
          title={__(`${baseTranslation}.ssnInput.title`)}
        />
      </div>
      <ul className="pt-md">
        <ListItem
          rightSlot={
            <Switch
              switched={isConnectedToCompanies}
              onChange={() => {
                if (isConnectedToCompanies) {
                  setSelectedServiceType({ taxRate: 0 });
                } else {
                  setSelectedServiceType({ taxRate: services[selectedService].tax, name: selectedService });
                }
                setIsConnectedToCompanies(!isConnectedToCompanies);
              }}
            />
          }
          underline={isConnectedToCompanies}>
          {companyDescription}{' '}
          {companies.map((company, index) => {
            return (
              <span key={company} className="font-bold">
                {company}
                {__('buyGiftCardWellness.servicePicker.companies').length - 1 !== index ? ', ' : ''}
              </span>
            );
          })}{' '}
          {companyDescriptionAfterCompanies}
        </ListItem>
        {isConnectedToCompanies && (
          <>
            <ListItem>
              <ListItemContent subTitle={serviceDescription} />
            </ListItem>
            {Object.keys(services).map((service) => {
              return (
                <ListItem
                  key={service}
                  onClick={() => {
                    setSelectedService(service);
                    setSelectedServiceType({ taxRate: services[service].tax, name: service });
                  }}
                  leftSlot={
                    selectedService === service ? <Icon variant="radio-checked" /> : <Icon variant="radio-unchecked" />
                  }>
                  <ListItemContent content={services[service].label} />
                </ListItem>
              );
            })}
          </>
        )}
      </ul>
    </Card>
  );
};

export default SSNFormCard;
