import { placeConstants } from '../constants';

export function place(state = {}, action) {
  switch (action.type) {
    case placeConstants.SET_HOVER:
      return {
        ...state,
        placeHover: action.placeId,
      };
    case placeConstants.REMOVE_HOVER:
      return {
        ...state,
        placeHover: -1,
      };
    default:
      return state;
  }
}
