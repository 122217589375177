import { cofPaymentDataSchema } from '@/types/adyen';
import { z } from 'zod';
import {
  bookingByCodeSchema,
  bookingSchema,
  couponMethodSchema,
  paymentMethodKlarnaSchema,
  paymentMethodPayAtPlaceSchema,
  paymentMethodQliroSchema,
  paymentMethodSchema,
  paymentMethodSwishSchema,
  usedPaymentMethodSchema,
} from './schema';

export const appointmentResponseSchema = z.object({
  booking: bookingSchema,
});

export type AppointmentResponse = z.infer<typeof appointmentResponseSchema>;

/**
 *  bookingByCode service response
 */
export const bookingByCodeResponseSchema = z.object({
  booking: bookingByCodeSchema,
});

export type BookingByCodeResponse = z.infer<typeof bookingByCodeResponseSchema>;

export const cancelAppointmentResponseSchema = bookingSchema.extend({});

export type CancelAppointmentResponse = z.infer<typeof cancelAppointmentResponseSchema>;

export const changeBookingTimeResponseSchema = z.object({
  id: z.number(),
});

export type ChangeBookingTimeResponse = z.infer<typeof changeBookingTimeResponseSchema>;

/**
 * confirmCofPayment service response
 */
export const confirmCofResponseSchema = z.object({
  booking: bookingSchema,
});

export type ConfirmCofResponse = z.infer<typeof confirmCofResponseSchema>;

/**
 * confirmKlarna service response
 */
export const confirmKlarnaResponseSchema = z.object({
  booking: bookingSchema,
});

export type ConfirmKlarnaResponse = z.infer<typeof confirmKlarnaResponseSchema>;

/**
 * Klarna and qliro confirm service response can sometimes be a redirect if not successful booking
 */
export const retryResponseSchema = z.object({
  redirect: z.object({
    pathname: z.string(),
    state: z.object({
      retry: z.boolean(),
      retryKlarna: z.boolean().nullable().optional(),
      retryQliro: z.boolean().nullable().optional(),
    }),
  }),
});

export type RetryResponse = z.infer<typeof retryResponseSchema>;

/**
 *  confirmSwishPayment service response
 */
export const confirmSwishPaymentResponseSchema = z.object({
  booking: bookingSchema,
});

export type ConfirmSwishPaymentResponse = z.infer<typeof confirmSwishPaymentResponseSchema>;

/**
 * confirmQliro service response
 */
export const confirmQliroResponseSchema = z.object({
  booking: bookingSchema,
});

export type ConfirmQliroResponse = z.infer<typeof confirmQliroResponseSchema>;

export type ConfirmedBooking = z.infer<typeof bookingSchema> & {
  bookingCode?: string;
};

/**
 * validateGiftCard service request
 */

export const validateGiftCardRequestSchema = z.object({
  code: z.string(),
  amount: z.number(),
  formId: z.number(),
  serviceIds: z.array(z.number()),
  startTime: z.string(),
  place: z.number(),
  usageRequestId: z.string(),
  personId: z.number(),
});

export type ValidateGiftCardRequest = z.infer<typeof validateGiftCardRequestSchema>;

/**
 * validateGiftCard service response
 */

/**
 * save-booking service request
 */

const giftcardSchema = z.object({
  giftCardType: z
    .union([
      z.literal('UGC'),
      z.literal('EGC'),
      z.literal('IGC'),
      z.literal('VCIGC'),
      z.literal('VCEGC'),
      z.literal('UGCW'),
    ])
    .optional(),
  giftCardExpiryDate: z.string().optional(),
  amount: z.number(),
  code: z.string(),
  formId: z.number().optional(),
  ownerCompanyId: z.number().or(z.string()).optional().nullable(),
});

export const bookingRequestSchema = z.object({
  observation: z.string(),
  place: z.number(),
  service: z.string(),
  isMPBooking: z.union([z.boolean(), z.string()]),
  bookingInitiator: z.object({ initiator: z.string(), searchKeyword: z.string() }),
  time: z.any(),
  discount: z.union([z.literal(20), z.boolean()]),
  bidId: z.string().optional(),
  random: z.boolean(),
  anyEmployeeSelected: z.boolean(),
  employee: z.number(),
  capacity: z.number(),
  paymentMethod: paymentMethodPayAtPlaceSchema,
  paymentMethods: z.array(z.union([paymentMethodSchema, couponMethodSchema])),
  defaultPaymentMethod: paymentMethodSchema.optional(),
  formfields: z.object({}),
  giftcards: z.array(giftcardSchema),
  cancelBooking: z.string().optional(),
  campaigns: z.any(),
  feeApplied: z.boolean(),
  guestId: z.string().optional(),
  bookingBundleId: z.number().optional(),
  queryParameters: z.object({ gclid: z.string().optional() }).optional(),
});

export const saveBookingRequestSchema = bookingRequestSchema.extend({
  paymentMethod: paymentMethodPayAtPlaceSchema,
  fullyPaid: z.boolean().optional(),
  turnstileToken: z.string().optional(),
});

export type SaveBookingRequest = z.infer<typeof saveBookingRequestSchema>;

/**
 * save-booking service response
 */

export const saveBookingResponseSchema = z.object({
  id: z.number(),
  bookingCode: z.string(),
  start: z.number(),
  end: z.number(),
});

export type SaveBookingResponse = z.infer<typeof saveBookingResponseSchema>;

export const swishPaymentBookingRequestSchema = bookingRequestSchema.extend({
  paymentMethod: paymentMethodSwishSchema,
  bookingId: z.number().optional(),
  callbackUrl: z.string().optional(),
});

export type SwishPaymentBookingRequest = z.infer<typeof swishPaymentBookingRequestSchema>;

export const swishPaymentBookingResponseSchema = z.object({
  url: z.string(),
  qrCode: z.string(),
  paymentId: z.string(),
  channel: z.string(),
  bookingId: z.number(),
});

export type SwishPaymentBookingResponse = z.infer<typeof swishPaymentBookingResponseSchema>;

export const initKlarnaRequestSchema = saveBookingRequestSchema.extend({
  paymentMethod: paymentMethodKlarnaSchema,
});

export type InitKlarnaRequest = z.infer<typeof initKlarnaRequestSchema>;

export const initKlarnaResponseSchema = z.object({
  sessionID: z.string(),
  clientToken: z.string(),
  paymentMethodCategories: z.array(
    z.object({
      identifier: z.string(),
      name: z.string(),
      assetURLs: z.object({
        descriptive: z.string(),
        standard: z.string(),
      }),
    }),
  ),
  orderLines: z.any(),
});

export type InitKlarnaResponse = z.infer<typeof initKlarnaResponseSchema>;

export const initQliroRequestSchema = saveBookingRequestSchema.extend({
  paymentMethod: paymentMethodQliroSchema,
});

export type InitQliroRequest = z.infer<typeof initQliroRequestSchema>;

export const initQliroResponseSchema = z.object({
  id: z.string(),
  status: z.string().optional(),
  snippet: z.string(),
});

export type InitQliroResponse = z.infer<typeof initQliroResponseSchema>;

export const cofPaymentRequestSchema = bookingRequestSchema.extend({
  paymentMethod: paymentMethodSchema,
  paymentData: cofPaymentDataSchema,
});

export type CofPaymentRequest = z.infer<typeof cofPaymentRequestSchema>;

export const cofPaymentResponseSuccessSchema = z.object({
  id: z.number(),
  start: z.number(),
  end: z.number(),
});

export type CofPaymentResponseSuccess = z.infer<typeof cofPaymentResponseSuccessSchema>;

export const cofPaymentResponseThreeDSSchema = z.object({
  requiresAction: z.boolean(),
  adyenActionJson: z.string(),
  adyenPaymentData: z.string().optional(),
  reference: z.string(),
  bookingId: z.number(),
});

export type CofPaymentResponseThreeDS = z.infer<typeof cofPaymentResponseThreeDSSchema>;

export const cofPaymentResponseSchema = cofPaymentResponseSuccessSchema.or(cofPaymentResponseThreeDSSchema);

export type CofPaymentResponse = z.infer<typeof cofPaymentResponseSchema>;

export const initKlarnaAfterBookingResponseSchema = z.object({
  booking: bookingSchema,
  order: z.object({
    ...initKlarnaResponseSchema.shape,
  }),
});

export type InitKlarnaAfterBookingResponse = z.infer<typeof initKlarnaAfterBookingResponseSchema>;

export const addBookingGiftcardsResponseSchema = z.object({
  bookingCode: z.string(),
});

export type AddBookingGiftcardsResponse = z.infer<typeof addBookingGiftcardsResponseSchema>;

export const paymentHistoryResponseSchema = z.object({
  popularOrder: z.array(paymentMethodSchema),
  previousPaymentMethod: usedPaymentMethodSchema.optional(),
  history: z.array(usedPaymentMethodSchema).optional(),
});

export type PaymentHistoryResponse = z.infer<typeof paymentHistoryResponseSchema>;

export const cancelByCodeResponseSchema = z.object({
  success: z.literal(true),
  booking: bookingSchema,
});

export type CancelByCodeResponse = z.infer<typeof cancelByCodeResponseSchema>;
