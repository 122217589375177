import PageViewLayout from '@/components/layouts/PageViewLayout/PageViewLayout';
import SideColumnLayout, { MainColumn, SideColumn } from '@/components/layouts/SideColumnLayout';
import SEO from '@/components/modules/SEO';
import Header from '@/components/modules/pages/giftCards/Header';
import InfoColumn from '@/components/templates/giftCards/InfoColumn';
import CheckoutColumn from '@/components/templates/giftCardsPlace/CheckoutColumn';
import { NEW_IGC_CHECKOUT_FEATURE_FLAG } from '@/constants/experimentConstants';
import { GIFTCARD_DEFAULT_VALUE_SELECTED, MIN_AMT_DIGITAL } from '@/constants/giftcardConstants';
import { exportIdSlug, isEmpty, trackMpEvent, trackPage, url } from '@/helpers';
import { useAppSelector } from '@/hooks';
import { usePlaceGiftCardData } from '@/hooks/usePlaceData';
import { __ } from '@/locale';
import { _sTitle } from '@/locale/i18n';
import { NotFound } from '@/pages/_exports';
import { authorizeKlarnaPurchase, getInitializeKlarnaSDKOnce, loadKlarnaWidget } from '@/services/klarnaServices';
import {
  createPlaceGiftCardOrder,
  getPlaceGiftCardOrderLines,
  initiateOrUpdatePGCPayment,
} from '@/services/pgcPaymentServices';
import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Redirect, useLocation, useParams } from 'react-router-dom';

const createOrUpdateKlarnaOrderOnDataUpdate = (value, quantity, employee, place, createKlarnaOrder) => {
  if (value < MIN_AMT_DIGITAL || quantity < 1 || employee === undefined || !(place?.employees?.length > 0)) return;
  createKlarnaOrder();
};

const onPlaceLoad = (place, setEmployee) => {
  if ((!place?.employees?.length ?? 0) > 0) return;

  place.redirect && (window.location.href = `${place.redirect}/giftcards/buy`);
  setEmployee(place.employees[0].id);
};

const findEmployee = (employees, employee) =>
  (employees || []).find((e) => {
    return e.id === employee;
  });

const trackScreenShown = (placeId) => () => {
  trackPage();
  trackMpEvent('screen_shown', {
    screen_name: 'place_gift_card_landing_page',
    place_id: placeId,
  });
};

const base = 'buyPlaceGiftCard';

const GiftCardsBuyPlace = ({ ssrPlace = null, is404 = false }) => {
  const { slugId } = useParams();
  const { id, slug } = exportIdSlug(slugId);

  const { loading, place } = usePlaceGiftCardData(ssrPlace, parseInt(id), slug);
  const employees = place && !isEmpty(place) && place.employees;
  const description = place && !isEmpty(place) && place.description;
  const placeName = (place && !isEmpty(place) && place.name) || '...';
  const placeSellsGiftcard = Boolean(place && !isEmpty(place) && place.sellsGiftCard);

  if (!placeSellsGiftcard && !loading) {
    is404 = true;
  }

  const [value, setValue] = useState(GIFTCARD_DEFAULT_VALUE_SELECTED);
  const [quantity, setQuantity] = useState(1);
  const [employee, setEmployee] = useState(null);
  const [orderId, setOrderId] = useState(null);
  const [sessionID, setSessionID] = useState(null);
  const [clientToken, setClientToken] = useState(null);
  const initializeKlarnaSDK = getInitializeKlarnaSDKOnce();
  const [orderLines, setOrderLines] = useState([]);
  const [paymentMethodCategories, setPaymentMethodCategories] = useState([]);

  const createKlarnaOrder = async () => {
    const updateResponse = await initiateOrUpdatePGCPayment(sessionID, {
      placeId: id,
      slug,
      slugId,
      value,
      quantity,
      employee: findEmployee(employees, employee),
    });

    const token = updateResponse.clientToken ?? clientToken;
    initializeKlarnaSDK(token);
    loadKlarnaWidget('#klarna-payment', token);
    setOrderLines(updateResponse.orderLines);
    if (!sessionID) {
      setPaymentMethodCategories(updateResponse.paymentMethodCategories);
      setSessionID(updateResponse.sessionID);
      setClientToken(updateResponse.clientToken);
    }
  };

  useEffect(trackScreenShown(id), []);

  // if redirect, switch to place w correct slug.
  useEffect(() => onPlaceLoad(place, setEmployee), [place]);

  useEffect(
    () =>
      createOrUpdateKlarnaOrderOnDataUpdate(
        value,
        quantity,
        findEmployee(employees, employee),
        place,
        createKlarnaOrder,
      ),
    [value, quantity, employee, place],
  );

  const valueUpdate = (newValue) => {
    if (newValue < MIN_AMT_DIGITAL) return;

    setValue(newValue);
  };

  const getCreateData = () => ({
    quantity,
    value,
    placeId: id,
    employee: findEmployee(employees, employee),
    slug,
    slugId,
  });

  const createOrder = async (res) => {
    const createRes = await createPlaceGiftCardOrder(sessionID, res.authorization_token);

    window.location = createRes.redirectURL;
  };

  const authorize = () => {
    const orderLines = getPlaceGiftCardOrderLines(getCreateData());
    authorizeKlarnaPurchase(orderLines, 'pay_later', createOrder);
  };

  const location = useLocation();
  const query = location.search ? queryString.parse(location.search) : {};
  const hideHeader = query && query.utm_source && ['mp_android_app', 'mp_ios_app'].indexOf(query.utm_source) !== -1;
  const newGiftcardCheckoutVariant =
    useAppSelector((state) => state.flags)[NEW_IGC_CHECKOUT_FEATURE_FLAG]?.value === 'on';

  return newGiftcardCheckoutVariant ? (
    <Redirect
      to={{
        pathname: `/places/${slugId}/giftcard/checkout`,
        search: location.search,
      }}
    />
  ) : is404 ? (
    <NotFound />
  ) : (
    <PageViewLayout
      type="subView"
      title={_sTitle(`${base}.checkout.title`, { name: placeName || '' })}
      backSrc={`/places/${slugId}`}
      back
      headerNavigationContainerClassName={hideHeader ? 'hidden' : ''}>
      <div className="mt-10">
        <div className="container mx-auto">
          <Header
            title={_sTitle(`${base}.checkout.title`, { name: placeName || '' })}
            tag={__(`${base}.checkout.tag`)}
            tagText={__(`${base}.checkout.tagText`)}
          />
        </div>
        <SideColumnLayout>
          <MainColumn>
            <CheckoutColumn
              {...{
                valueUpdate,
                value,
                quantity,
                setQuantity,
                minValue: MIN_AMT_DIGITAL,
                employees,
                employee,
                setEmployee,
                placeName: place?.name,
                authorize,
                paymentMethodCategories,
              }}
            />
          </MainColumn>
          <SideColumn>
            <div className="sticky top-20">
              <InfoColumn {...{ description, placeName, base: 'buyPlaceGiftCard.info' }} />
            </div>
          </SideColumn>
        </SideColumnLayout>
      </div>
      <SEO
        title={_sTitle(`seo.buyPlaceGiftCardTitle`, { name: placeName })}
        description={_sTitle(`seo.buyPlaceGiftCardDescription`, { name: placeName })}
        url={`${url.getBaseUrl()}places/${slugId}/giftcards/buy`}
      />
    </PageViewLayout>
  );
};

const mapStateToProps = (state) => ({ ssrPlace: state.place, is404: state.loading?.is404 ?? false });

export default connect(mapStateToProps)(GiftCardsBuyPlace);
