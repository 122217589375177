import { StarRating } from '@/components/modules/rating';
import { ReadMore } from '@/components/modules/readmore';
import { themed } from '@/helpers/theme';
import { __ } from '@/locale';
import { withRouter } from 'react-router-dom';
import TimeAgo from 'react-timeago';
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter';
import svStrings from 'react-timeago/lib/language-strings/sv';

const formatter = buildFormatter(svStrings);

function ReviewCard(props) {
  const { hideStaff, showReplies, showPlainDate, showCompany } = props;
  const {
    author,
    companyName,
    createdAt,
    review: { score, text },
    subject,
    reply,
  } = props.review;
  const employeeName = subject && subject.employee && subject.employee.name;
  const { full = false } = props;

  const authorCard = ({ avatar, name, createdAt }, isReply) => {
    return (
      <div className="flex">
        <img src={avatar || '/images/review-default-avatar.svg'} className="mr-3" alt={name} width="36" height="36" />
        <div className={isReply ? 'flex flex-col overflow-hidden' : 'flex flex-col'}>
          <span className={isReply ? 'overflow-hidden text-ellipsis font-semibold' : 'font-semibold'}>
            <span className={isReply ? 'whitespace-nowrap' : ''}>{name || __('Anonymous')}</span>
          </span>
          {createdAt && (
            <span className="text-black-600 text-sm">
              {showPlainDate ? createdAt : <TimeAgo date={createdAt} formatter={formatter} />}
            </span>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className={`block w-full rounded-lg bg-[#fff7f5] p-6 ${full ? 'col-span-2' : ''}`}>
      {authorCard({ ...author, createdAt })}
      <div className="flex py-4">
        <StarRating rating={score} noNumber />
        {!hideStaff && (
          <span className="text-black-600 ml-2">
            {employeeName && __('to')}&nbsp;
            <span className={`cursor-pointer ${themed('text-primary', 'text-black-800 underline')}`}>
              {employeeName}
            </span>
          </span>
        )}
        {showCompany && (
          <span className={'font-semibold'}>
            <span>@ {companyName}</span>
          </span>
        )}
      </div>
      {!full ? <ReadMore maxLines={3} text={text} /> : <div className="text-black-600">{text}</div>}
      {text && reply && showReplies && (
        <div className="border-black-200 mt-4 border-l-2 pl-4">
          {authorCard({ ...reply.author, createdAt: reply.createdAt }, true)}
          {!full ? <ReadMore maxLines={3} text={reply.text} /> : <div className="text-black-600">{reply.text}</div>}
        </div>
      )}
    </div>
  );
}
const ReviewCardWithRouter = withRouter(ReviewCard);
export default ReviewCardWithRouter;
