import { isServer } from '@/helpers';
import { useAlertPortal, useAlerts } from '@/hooks/useAlert';
import * as ReactDOM from 'react-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Alert } from './Alert';

export const AlertPortal = () => {
  const portalId = useAlertPortal();
  const alerts = useAlerts();
  if (isServer) return null;
  const el = document.getElementById(portalId);
  const duration = 175;
  const styles = `
      .alert-transition-enter { transform: scale(.8); opacity: 0; }
      .alert-transition-enter-active { transform: scale(1); opacity: 1; transition: all ${duration}ms; }
      .alert-transition-enter-done { transform: scale(1); }
      .alert-transition-exit { transform: scale(1); }
      .alert-transition-exit-active { transform: scale(.8); opacity:0; transition: all ${duration}ms;}
  `;

  return portalId && el
    ? ReactDOM.createPortal(
        <div className="mt-24 h-full w-full !px-4 sm:!px-10">
          <style>{styles}</style>
          <div className="relative flex w-full justify-end">
            <TransitionGroup component={null}>
              {alerts.length > 0 ? (
                alerts.map((alert) => (
                  <CSSTransition
                    key={alert.id}
                    timeout={{ enter: duration, exit: duration }}
                    classNames="alert-transition">
                    <Alert key={alert.id} {...alert} />
                  </CSSTransition>
                ))
              ) : (
                <></>
              )}
            </TransitionGroup>
          </div>
        </div>,
        document.getElementById(portalId),
      )
    : null;
};
