import { Button } from '@/components/elements/forms/buttons';
import { SMART_LINKS } from '@/constants/smartLinkConstants';
import { _s } from '@/locale';
import { useState } from 'react';
import QRCode from 'react-qr-code';
import Modal from './redesign/Modal/Modal';

const baseTranslation = 'components.modules.modals.AppPromotion';

const AppPromotionModal = () => {
  const [isOpen, setIsOpen] = useState(true);

  const handleCloseModal = () => {
    setIsOpen(false);
    sessionStorage.removeItem('savedGiftcard');
  };

  return (
    <Modal isOpen={isOpen}>
      <Modal.Content floating>
        <Modal.Header title={_s(`${baseTranslation}.title`)} />
        <div className="gap-xxl flex flex-col">
          <div className="py-lg flex justify-center">
            <QRCode
              size={110}
              style={{ height: 'auto', maxWidth: '125px', width: '125px' }}
              value={SMART_LINKS.HOME}
              viewBox={`0 0 110 110`}
            />
          </div>
          <div className="text-center">
            <p>{_s(`${baseTranslation}.content`)}</p>
          </div>
          <div className="text-center">
            <Button label={_s(`${baseTranslation}.cta`)} variant="link" onClick={handleCloseModal} />
          </div>
        </div>
      </Modal.Content>
    </Modal>
  );
};

export default AppPromotionModal;
