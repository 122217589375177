import { modalActions } from '@/actions';
import Snackbar from '@/components/elements/notifications/Snackbar/Snackbar';
import { loggedUser } from '@/helpers';
import { getGiftcardErrorMessage } from '@/helpers/giftcards';
import { _s } from '@/locale';
import { saveGiftcard } from '@/services';
import { AppDispatch } from '@/store';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAppSelector } from '.';

const handleSaveGifcardFromLink = ({
  giftcardToken,
  user,
  dispatch,
  history,
  location,
}: {
  giftcardToken?: string;
  user?: any;
  dispatch: AppDispatch;
  history: any;
  location: any;
}) => {
  if (Boolean(giftcardToken) && !user && !loggedUser()) {
    dispatch(modalActions.login({ show: true }));
  }

  if (user && Boolean(giftcardToken)) {
    saveGiftcard(giftcardToken)
      .then(() => {
        sessionStorage.setItem('savedGiftcard', 'true');
        history.replace({
          pathname: location.pathname,
        });
        toast(({ closeToast }) => (
          <Snackbar
            type="info"
            label={_s(`saveGiftcard.success`)}
            action={<button onClick={closeToast}>{_s('Alright')}</button>}
          />
        ));
      })
      .catch((error) => {
        history.replace({
          pathname: location.pathname,
        });
        const errorMessage = getGiftcardErrorMessage(error?.message);
        toast(({ closeToast }) => (
          <Snackbar type="danger" label={errorMessage} action={<button onClick={closeToast}>{_s('Alright')}</button>} />
        ));
      });
  }
};

const useSaveGiftcardFromLink = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const user = useAppSelector((state) => state.users.user);
  const query = new URLSearchParams(location.search);
  const giftcardToken = query.get('giftcardToken');

  useEffect(
    () => handleSaveGifcardFromLink({ giftcardToken, dispatch, history, location, user }),
    [giftcardToken, user],
  );
};

export default useSaveGiftcardFromLink;
