import { Button } from '@/components/elements/forms/buttons';
import { LoadingIcon } from '@/components/icons';
import { ReviewCard, ReviewStats } from '@/components/modules/review';
import Employee from '@/components/modules/salon/Employee';
import { capitalizeFirstLetter, server } from '@/helpers';
import { __ } from '@/locale';
import { useEffect, useState } from 'react';
import { ModalHeader } from '../mobile';
import { ModalContent, ModalDialog } from '../modals';
import { ModalActionbar } from '../modals/Modal';

function EmployeeReviews(props) {
  const { isOpen, close, employee, place, rating, pickService, showMoreInfo, isMpReviews } = props;

  const {score, count, ratingCounts, reviewCounts} = rating;
  const stats = { score, count };
  const { about: { settings: { subscriptionType } = {} } = {} } = place;
  const { about: { settings: { displayReviews } = {} } = {} } = employee;
  const [reviews, setReviews] = useState([]);
  const [nextReviewPageData, setNextReviewPageData] = useState({});
  const [haveMoreReviews, setHaveMoreReviews] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [ratingFilter, setRatingFilter] = useState(0);

  useEffect(() => {
    if (displayReviews) {
      fetchReviews();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ratingFilter]);

  function fetchReviewsByRating(rating = 0) {
    setRatingFilter(rating);
    if (rating !== ratingFilter) {
      setReviews([]);
      setNextReviewPageData({});
      setHaveMoreReviews(true);
    }
  }

  async function fetchReviews() {
    if (!haveMoreReviews) {
      return;
    }
    setIsLoading(true);
    const result = await server.request
      .get('/places/getEmployeeReviews/' + employee.id, {
        params: { page: nextReviewPageData.page, limit: nextReviewPageData.limit, 'mp-reviews': isMpReviews, rating: ratingFilter },
      })
      .then(server.handleSuccess)
      .catch(server.handleError);

    setReviews(reviews.concat(result.items));
    setNextReviewPageData(result.nextPage || {});
    setHaveMoreReviews(
      result.nextPage && result.nextPage && result.nextPage.limit && result.nextPage.limit > 0 ? true : false,
    );
    setIsLoading(false);
  }

  const bookClicked = (e) => {
    pickService();
  };

  return (
    <ModalDialog isOpen={isOpen} appElement={document.getElementById('root')} onRequestClose={close}>
      <ModalContent size="md">
        <ModalHeader
          action={close}
          cancel
          top={
            <h3 className="font-semibold sm:hidden">
              {(displayReviews ? __('reviewsFor') + ' ' : '') + capitalizeFirstLetter(employee.about.name)}
            </h3>
          }
        />

        <div className="container bg-white py-6 sm:bg-transparent">
          <Employee employee={employee} place={place} rating={rating} inModal showMoreInfo={showMoreInfo} />
          {reviews.length > 0 && <h2 className="text-lg font-semibold">{__('reviewsTitle')}</h2>}
          {displayReviews && 
            <ReviewStats 
              stats={stats} 
              ratingCounts={ratingCounts} 
              reviewCounts={reviewCounts} 
              onRatingClick={fetchReviewsByRating} 
              ratingFilter={ratingFilter} 
              showScore={false}
            />
          }
          <div className="flex flex-row flex-wrap items-stretch justify-between gap-4 pb-16 sm:pb-4">
            {reviews.map((review, key) => (
              <ReviewCard review={review} key={key} full showReplies={subscriptionType !== 'essential'} />
            ))}
            {isLoading && (
              <div className="flex w-full justify-center">
                <LoadingIcon className="h-8 w-8" />
              </div>
            )}
            {reviews.length > 0 && haveMoreReviews && (
              <Button variant="secondary" className="mx-auto px-4" onClick={fetchReviews}>
                {__('showMore')}
              </Button>
            )}
          </div>

          <div className="hidden sm:block">
            {pickService && (
              <Button block onClick={bookClicked}>
                {__('book')}
              </Button>
            )}
          </div>
        </div>
        <div className="sm:hidden">
          {pickService && (
            <ModalActionbar>
              <Button block onClick={bookClicked}>
                {__('book')}
              </Button>
            </ModalActionbar>
          )}
        </div>
      </ModalContent>
    </ModalDialog>
  );
}

export default EmployeeReviews;
