import Avatar, { AvatarSize } from '@/components/elements/Avatar';
import { Fab } from '@/components/elements/forms/buttons';
import { SuccessRingIcon } from '@/components/icons';
import Icon from '@/components/icons/Icon';
import { ReadMore } from '@/components/modules/readmore';

type EmployeeInfo = {
  id: number;
  about: {
    avatar: string;
    name: string;
    jobTitle: string;
    info: string;
  };
};

type Props = {
  employee: EmployeeInfo;
  avatarSize?: AvatarSize;
  showNote?: boolean;
  showChecked?: boolean;
  showEdit?: () => void | null;
};

const EmployeeBox = ({ employee, avatarSize = '60', showNote = false, showChecked = false, showEdit }: Props) => (
  <>
    {employee && employee.about && (
      <div className="relative" key={employee.id}>
        <div className="flex items-center justify-center">
          {showChecked && <SuccessRingIcon className="mr-3 h-4 w-4 md:mr-4" />}
          <Avatar className="mr-2" src={employee.about.avatar} size={avatarSize} />
          <div className="flex flex-1 flex-col" style={showEdit ? { paddingRight: '36px' } : {}}>
            <span>{employee.about.name}</span>
            <span className="text-black-600 text-sm">{employee.about.jobTitle}</span>
          </div>
          {showEdit && <Fab icon={<Icon variant="edit" />} onClick={showEdit} size="md" variant="link" />}
        </div>
        {showNote && employee.about.info && (
          <div className="text-black-600 mt-3 text-sm">
            <ReadMore text={employee.about.info} />
          </div>
        )}
      </div>
    )}
  </>
);

export default EmployeeBox;
