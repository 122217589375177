import { server } from './serverRequests';
import { promiseWrapper } from './utils';

export const getServices = ({ serviceIds, place }: { serviceIds: Array<number>; place: any }) => {
  const services = [];

  if (!Array.isArray(place.services)) {
    return null;
  }

  place.services.forEach((category) => {
    if (!Array.isArray(category.services)) {
      return;
    }

    category.services.forEach((service) => {
      if (!service.id) {
        return;
      }

      serviceIds.forEach((serviceId) => {
        if (serviceId === service.id) {
          services.push(service);
        }
      });
    });
  });

  return services.length > 0 ? services : null;
};

export const getEmployee = ({ employeeId, place }: { employeeId: number; place: any }) => {
  if (!Array.isArray(place.employees)) {
    return null;
  }
  return place.employees.filter((employee) => employee.id === employeeId)[0];
};

export const fetchPlace = async ({ id, slug, abortSignal }: { id: number; slug: string; abortSignal: AbortSignal }) => {
  if (!id || !slug) {
    return null;
  }

  const { data } = await promiseWrapper(
    server.request.get(`/findPlace/${id}/${encodeURIComponent(slug)}?slug=${encodeURIComponent(slug)}`, {
      abortSignal,
    }),
  );

  const fetchedPlace = data.data.place;

  return fetchedPlace;
};
