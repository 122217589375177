import { isServer } from '@/helpers';
import { useEffect } from 'react';

const useScrollToTop = () => {
  useEffect(() => {
    if (isServer) return;
    window.scrollTo(0, 0);
  }, []);
};

export default useScrollToTop;
