import { config } from '@/config';
import { allowTracking, isSistaminuten } from '@/helpers';
import { FlagsAction } from '@/reducers/flagsReducer';
import { Experiment, ExperimentClient } from '@amplitude/experiment-js-client';
import * as Sentry from '@sentry/react';
import { createContext, Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import { useAppDispatch } from '.';

export type AmplitudeExperimentContextType = ReturnType<typeof useAmplitudeExperimentManager>;
export const AmplitudeExperimentContext = createContext<AmplitudeExperimentContextType>(null);

type Variant = {
  value?: string;
  payload?: any;
};

const amplitudeExperimentKey = (() => {
  if (isSistaminuten()) {
    return config.amplitudeExperimentSistaminuten;
  }
  return config.amplitudeExperiment;
})();

function initializeAndSetAmplitudeExperiment(
  setAmplitudeExperimentInstance: Dispatch<SetStateAction<ExperimentClient>>,
  dispatch?: Dispatch<FlagsAction>,
) {
  const experiment = Experiment.initializeWithAmplitudeAnalytics(amplitudeExperimentKey, {
    pollOnStart: false,
  });

  experiment
    .start()
    .then(async () => {
      setAmplitudeExperimentInstance(experiment);

      if (dispatch) dispatch({ type: 'flags/SET_FLAGS', payload: experiment.all() });
    })
    .catch((err) => {
      const requestTimeout = err.message.startsWith('Request timeout');
      const failedToFetch =
        err instanceof TypeError &&
        (err.message === 'Failed to fetch' || err.message === 'Load failed' || err.message === 'NetworkError');
      const isNetworkError = requestTimeout || failedToFetch;
      if (!isNetworkError) {
        Sentry.captureException(err);
      }
    });
}

const useAmplitudeExperimentManager = (): {
  getVariant: (exposureEvent: string) => Variant;
  initialize: () => void;
} => {
  const [amplitudeExperimentInstance, setAmplitudeExperimentInstance] = useState<ExperimentClient>(null);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (allowTracking() && config.amplitudeExperiment) {
      initializeAndSetAmplitudeExperiment(setAmplitudeExperimentInstance);
    }
    return () => {
      if (amplitudeExperimentInstance) {
        amplitudeExperimentInstance.stop();
      }
    };
  }, []);

  /**
   * Currently, the amplitude experiment is only initialized once on the first render.
   * if the user has not allowed tracking, the amplitude experiment will not be initialized.
   * This function can be called to initialize the amplitude experiment after the user has allowed tracking.
   */
  const initialize = () => {
    if (allowTracking() && config.amplitudeExperiment && amplitudeExperimentInstance === null) {
      initializeAndSetAmplitudeExperiment(setAmplitudeExperimentInstance, dispatch);
    }
  };

  const getVariant = (exposureEvent: string): Variant => {
    const { value, payload } = amplitudeExperimentInstance
      ? amplitudeExperimentInstance.variant(exposureEvent)
      : { value: null, payload: null };

    return {
      value,
      payload,
    };
  };

  return { getVariant, initialize };
};

export const useGetAmplitudeExperimentVariant = (): AmplitudeExperimentContextType['getVariant'] => {
  const { getVariant } = useContext(AmplitudeExperimentContext);
  return getVariant;
};

export const AmplitudeExperimentProvider = ({ children }) => {
  return (
    <AmplitudeExperimentContext.Provider value={useAmplitudeExperimentManager()}>
      {children}
    </AmplitudeExperimentContext.Provider>
  );
};
