import Icon from '@/components/icons/Icon';
import { AVATAR_ICON_SIZE, AVATAR_SIZE } from '@/constants/AvatarConstants';
import { classnames } from '@/helpers';
import { AvatarVariantIcon } from '../AvatarVariantIcon';
import type { AvatarProps } from '../types';

const PersonAvatar = ({ size = 'xl', imgUrl, alt, variant = 'default' }: AvatarProps) => {
  return (
    <div className="relative inline-block">
      <div
        className={classnames(
          AVATAR_SIZE[size],
          'bg-black-100 relative flex items-center justify-center overflow-hidden rounded-full',
        )}>
        <span className="border-black-100 absolute inset-0 z-10 rounded-full border bg-transparent"></span>
        {imgUrl ? (
          <img className="absolute inset-0 h-full w-full object-cover object-center" src={imgUrl} alt={alt} />
        ) : (
          <Icon color="black-500" size={AVATAR_ICON_SIZE[size]} variant="user" alt={alt} />
        )}
      </div>
      {variant !== 'default' && <AvatarVariantIcon size={size} variant={variant} />}
    </div>
  );
};

export default PersonAvatar;
