import BorderLabelTextInput from '@/components/elements/forms/BorderLabelTextInput';
import { isMinLen, isNotEmptyStr, isZipcode, validate } from '@/helpers';
import { __ } from '@/locale';
import { useEffect, useState } from 'react';

const formFields = [
  {
    label: __('buyGiftcard.shipping.name'),
    name: 'name',
    type: 'text',
    placeholder: __('buyGiftcard.shipping.namePlaceholder'),
  },
  {
    label: __('buyGiftcard.shipping.address'),
    name: 'address',
    type: 'text',
    placeholder: __('buyGiftcard.shipping.addressPlaceholder'),
  },
  {
    label: __('buyGiftcard.shipping.zipcode'),
    name: 'zipcode',
    type: 'text',
    half: true,
    placeholder: __('buyGiftcard.shipping.zipcodePlaceholder'),
    format: (value) => {
      const numVal = value.replace(/\D/g, '').slice(0, 5);
      return numVal.length > 3 ? numVal.slice(0, 3) + ' ' + numVal.slice(3) : numVal;
    },
  },
  {
    label: __('buyGiftcard.shipping.city'),
    name: 'city',
    type: 'text',
    half: true,
    placeholder: __('buyGiftcard.shipping.cityPlaceholder'),
  },
];

const validations = {
  name: validate([isNotEmptyStr, isMinLen(3)], __('buyGiftcard.shipping.nameError')),
  address: validate([isNotEmptyStr, isMinLen(3)], __('buyGiftcard.shipping.addressError')),
  city: validate([isNotEmptyStr, isMinLen(3)], __('buyGiftcard.shipping.cityError')),
  zipcode: validate([isNotEmptyStr, isZipcode], __('buyGiftcard.shipping.zipcodeError')),
};

/**
 * @typedef {Object} ShippingFormValues
 * @prop {string} [name]
 * @prop {string} [address]
 * @prop {string} [city]
 * @prop {string} [zipcode]
 *
 * @typedef {Object} ShippingFormProps
 * @prop {ShippingFormValues} values
 * @prop {Function} setValues
 * @prop {boolean} shouldValidate
 * @prop {Function} validationCompleted
 *
 * @param {ShippingFormProps} param0
 * @returns
 */
const ShippingForm = ({ values, setValues, shouldValidate, validationCompleted }) => {
  const [validationsResult, setValidationsResult] = useState([]);
  const setValue = (name) => (value) => {
    setValues({ ...values, [name]: value ? value : null });

    const valIndex = validationsResult.findIndex((res) => res.name === name);
    if (valIndex === -1) return;

    setValidationsResult([...validationsResult.slice(0, valIndex), { name }, ...validationsResult.slice(valIndex + 1)]);
  };

  const onBlur = (name) => () => {
    const res = { name, error: validations[name](values[name]) };

    setValidationsResult([...validationsResult.filter((v) => v.name !== name), res]);
  };

  useEffect(() => {
    if (!shouldValidate) return;

    const result = Object.keys(validations).map((name) => ({ name, error: validations[name](values[name]) }));

    setValidationsResult(result);
    // Has no errors
    validationCompleted(result.find((v) => v.error) === undefined);
  }, [shouldValidate]);

  const errorFor = (name) => {
    const entry = validationsResult.find((obj) => obj.name === name);
    return entry?.error ?? false;
  };

  return (
    <div className="gift-card-shipping-form">
      <div className="colgap-4 flex flex-wrap">
        {formFields.map((field, i) => (
          <BorderLabelTextInput
            key={i}
            className="my-2"
            half={field.half ?? field.half}
            label={field.label}
            placeholder={field.placeholder ?? ''}
            value={values[field.name] ?? ''}
            setValue={setValue(field.name)}
            error={errorFor(field.name)}
            onBlur={onBlur(field.name)}
            format={field.format ?? ((val) => val)}
          />
        ))}
      </div>
    </div>
  );
};

export default ShippingForm;
