import { Photos } from './';

function EmployeeGallery(props) {
  const { place, employee } = props;
  const {
    about: { photos },
  } = place;
  const employeePhotos = photos
    ? photos.filter((p) => p.type === 'calendar' && p.objectKey === employee.about.externalId).map((p) => p.url)
    : [];
  return <Photos photos={employeePhotos} place={place} isEmployee={true} />;
}

export default EmployeeGallery;
