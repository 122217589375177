import { IAlert } from '@/components/modules/alert/Alert';
import { useAddAlert } from '@/hooks/useAlert';

type AlertProps = {
  addAlert: (alert: IAlert) => void;
};

export function withAlert<P>(Component: React.ComponentType<P & AlertProps>) {
  return function WrappedComponent(props: P) {
    const addAlert = useAddAlert();
    return <Component {...props} addAlert={addAlert} />;
  };
}
