import { isSistaminuten } from '@/helpers';
import { _s } from '@/locale';
import { BottomNavBarItem, NavItemIdentifier } from '@/types/navigation';

const searchItem: BottomNavBarItem = {
  id: 'tab-nav-search',
  icon: 'search',
  label: _s('search'),
  to: '/vad/var',
  identifier: NavItemIdentifier.Search,
};

const bookingsItem: BottomNavBarItem = {
  id: 'tab-nav-bookings',
  icon: 'calendar',
  label: _s('bookings'),
  to: '/bokningar',
  identifier: NavItemIdentifier.Bookings,
};

const dealsItem: BottomNavBarItem = {
  id: 'tab-nav-deals',
  icon: 'deal',
  label: 'Deals',
  to: '/deals',
  identifier: NavItemIdentifier.Deals,
};

const favoritesItem: BottomNavBarItem = {
  id: 'tab-nav-favourites',
  icon: 'favorite',
  label: _s('favorites'),
  to: '/favoriter',
  identifier: NavItemIdentifier.Favorites,
};

const moreItem: BottomNavBarItem = {
  id: 'tab-nav-more',
  icon: 'more',
  label: _s('More'),
  identifier: NavItemIdentifier.More,
};

const homeItem: BottomNavBarItem = {
  id: 'tab-nav-home',
  icon: 'home',
  label: _s('Home'),
  to: '/',
  identifier: NavItemIdentifier.Home,
};

const bottomNavBarNavigationItemsSistaminuten: BottomNavBarItem[] = [homeItem, searchItem, moreItem];
const bottomNavBarNavigationItemsBokadirekt: BottomNavBarItem[] = [
  searchItem,
  bookingsItem,
  dealsItem,
  favoritesItem,
  moreItem,
];

export const bottomNavBarNavigationItems = isSistaminuten()
  ? bottomNavBarNavigationItemsSistaminuten
  : bottomNavBarNavigationItemsBokadirekt;
