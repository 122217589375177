import { bookActions } from '@/actions';
import LoadingDotsWhite from '@/components/animations/LoadingDotsWhite/LoadingDotsWhite';
import Header from '@/components/elements/Header/Header';
import { Button } from '@/components/elements/forms/buttons';
import ListInput from '@/components/elements/forms/listInput/ListInput/ListInput';
import Alert from '@/components/elements/notifications/Alert/Alert';
import Icon from '@/components/icons/Icon';
import { useAppDispatch } from '@/hooks';
import { _s } from '@/locale';
import { ChangeEvent, useState } from 'react';

const baseTranslationKey = 'components.modules.checkout.ApplyGiftcard';

const getTranslationKey = (type: GiftcardType) => `${baseTranslationKey}.${type}`;

type GiftcardType = 'giftcard' | 'valuecard' | 'wellnesscard';

const ApplyGiftcard = ({
  type,
  validating,
  error,
  onApplyGiftcard,
}: {
  type: GiftcardType;
  validating: boolean;
  error?: string;
  onApplyGiftcard: (code: string) => void;
}) => {
  const [code, setCode] = useState('');
  const dispatch = useAppDispatch();

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    setCode(e.target.value);
    dispatch(bookActions.clearGiftCardMessages());
  };

  return (
    <div className="space-y-lg flex flex-col">
      <Alert
        leftSlot={
          <div className="flex items-start justify-start">
            <Icon variant="info-circle" color="information-900" />
          </div>
        }
        verticalAlign="top"
        variant="information"
        body={_s(`${getTranslationKey(type)}.alert`)}
      />
      <div>
        <div className="my-md">
          <Header label={_s(`${getTranslationKey(type)}.header`)} size="md" />
        </div>
        <ListInput
          label={_s(`${getTranslationKey(type)}.input.label`)}
          id="giftcard"
          placeholder={_s(`${getTranslationKey(type)}.input.placeholder`)}
          value={code}
          onChange={handleOnChange}
          error={error}
        />
      </div>
      <div className="py-lg space-y-lg">
        <Button block size="md" onClick={() => onApplyGiftcard(code)} disabled={validating}>
          {validating && <LoadingDotsWhite style={{ height: '100%', width: 48 }} loop />}
          {!validating && _s(`${getTranslationKey(type)}.cta`)}
        </Button>
      </div>
    </div>
  );
};

export default ApplyGiftcard;
