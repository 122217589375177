import Header from '@/components/elements/Header/Header';
import { ListItem, ListItemContent } from '@/components/elements/lists';
import Icon from '@/components/icons/Icon';
import { _s } from '@/locale';
import { PlaceEmployee, PlaceService } from '@/types/state/shared';
import { Fragment } from 'react/jsx-runtime';
import EmployeeItem from './EmployeeItem';
import PickedService from './PickedService';

const baseTranslationKey = 'components.modules.modals.BookAppointment.shared.Summary';

export type SummaryProps = {
  pickedEmployee: PlaceEmployee;
  pickedServices: PlaceService[];
  showAddAdditionalService?: boolean;
  onRemoveService?: (serviceId: number) => void;
  onRemoveEmployee?: () => void;
  onShowAvailableServices?: () => void;
};

const Summary = ({
  pickedEmployee,
  pickedServices,
  showAddAdditionalService = true,
  onRemoveService,
  onRemoveEmployee,
  onShowAvailableServices,
}: SummaryProps) => {
  return (
    <Fragment>
      <div className="border-black-100 pb-xs border-b">
        <Header label={_s(`${baseTranslationKey}.header`)} h="span" size="sm" />
      </div>
      <ul>
        {pickedEmployee && <EmployeeItem employee={pickedEmployee} onRemove={onRemoveEmployee} />}
        {pickedServices.map((service) => {
          /**
           * if only one non-addon service is picked, do not allow removal of the non-addon service
           */
          const mainServices = pickedServices.filter((service) => !service?.about?.settings?.isAddOn);
          const isAddon = service?.about?.settings?.isAddOn;
          const canRemove = onRemoveService && (mainServices.length > 1 || isAddon);
          return (
            <PickedService
              key={service.id}
              service={service}
              {...(canRemove && { onRemove: () => onRemoveService(service.id) })}
            />
          );
        })}
        {showAddAdditionalService && (
          <ListItem
            leftPadding={false}
            rightPadding={false}
            onClick={() => onShowAvailableServices?.()}
            leftSlot={<Icon variant="plus-circle" color="black-900" />}>
            <ListItemContent
              content={_s(`${baseTranslationKey}.link.add`)}
              contentClassName="text-information-700 underline"
            />
          </ListItem>
        )}
      </ul>
    </Fragment>
  );
};

export default Summary;
