import { blog } from '@/helpers';
import { useEffect, useState } from 'react';

const useBlogPageContent = (pageId): string => {
  const [content, setContent] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      try {
        const page = await blog.getPage(pageId);
        if (page && page.content && page.content.rendered) {
          setContent(page.content.rendered.replace(/srcset=".*?\/>/g, '').replace(/(width|height)="\d+"/g, ''));
        }
        setLoading(false);
      } catch (ex) {
        setLoading(false);
      }
    })();
  }, []);

  return content;
};

export default useBlogPageContent;
