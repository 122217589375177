import BorderLabelTextInput from '@/components/elements/forms/BorderLabelTextInput';
import { Button } from '@/components/elements/forms/buttons';
import RadioButton from '@/components/elements/forms/RadioButton';
import { ShadowModal } from '@/components/modules/modals';
import { GIFTCARD_DEFAULT_VALUES } from '@/constants/giftcardConstants';
import { __, _s } from '@/locale';
import { useEffect, useState } from 'react';
import css from './ValueSelector.module.scss';
/**
 * @typedef {object} ValueSelectorProps
 * @prop {number} value
 * @prop {boolean} isCustom
 * @prop {Function} valueUpdate
 * @prop {number} [minValue=600]
 * @prop {number[]} pricingValues
 * @prop {number} popular
 *
 * @param {ValueSelectorProps} param0
 * @returns
 */
const ValueSelector = ({ value, valueUpdate, minValue = 600, pricingValues = GIFTCARD_DEFAULT_VALUES }) => {
  const [customInputValue, setCustomInputValue] = useState(0);
  const [amt, setAmt] = useState(0);
  const [error, setError] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const errorText = __('buyGiftcard.selectCustomValue.error', { minValue });
  const popularValue = pricingValues?.[1] ?? pricingValues[0];
  const onConfirmCustomInputValue = () => {
    if (customInputValue < minValue) {
      setError(true);
      return;
    }
    setShowModal(false);
    if (pricingValues.includes(customInputValue)) {
      onSelect(customInputValue);
      return;
    }
    setAmt(customInputValue);
  };

  const onCustomInputChange = (newAmt) => {
    if (newAmt >= minValue) {
      setError(false);
    }
  };

  const onAbort = () => {
    setShowModal(false);
  };

  const format = (str) => {
    if (isNaN(str)) {
      return customInputValue;
    }
    while (str.startsWith('0')) {
      str = str.slice(1);
    }

    const newValue = str ? str.replaceAll('.', '').replaceAll(' ', '') : 0;
    return parseInt(newValue);
  };

  useEffect(() => onSelect(amt), [amt]);

  const onSelect = (newValue) => {
    if (newValue < minValue) return;
    valueUpdate(newValue);
  };
  return (
    <>
      <div className="gift-card-value-selector mb-6 flex flex-wrap gap-x-2">
        {pricingValues.map((valueOpt, i) => (
          <RadioButton
            style={{ marginBottom: 0 }}
            className="!mb-0 !mr-0"
            onClick={() => onSelect(valueOpt)}
            checked={valueOpt === value}
            key={i}
            variant="small">
            {valueOpt === popularValue && (
              <span className={css.floatingTag}>{_s('buyGiftcard.selectValue.popular')}</span>
            )}
            <span className="ml-2 ">{valueOpt}:-</span>
          </RadioButton>
        ))}
        {amt >= minValue && !pricingValues.includes(amt) && (
          <RadioButton onClick={() => onSelect(amt)} checked={amt === value} variant="small">
            <span className="ml-2">{amt}:-</span>
          </RadioButton>
        )}
      </div>
      <div className="gift-card-value-custom-container mb-6">
        <Button className="text-primary px-0" variant="link" onClick={() => setShowModal(true)}>
          {_s('buyGiftcard.selectCustomValue')}
        </Button>
      </div>
      {showModal && (
        <ShadowModal isOpen={showModal} onRequestClose={onAbort}>
          <h1 className="mb-6 font-semibold">{_s('buyGiftcard.selectCustomValue')}</h1>
          <BorderLabelTextInput
            name={`${customInputValue}`}
            label={__('buyGiftcard.selectCustomValue.placeholder', { minValue })}
            format={format}
            value={customInputValue ? `${customInputValue}` : ''}
            error={error && errorText}
            onChange={onCustomInputChange}
            setValue={setCustomInputValue}
            className="mb-10 text-left"
          />
          <button className="button button-primary button-block mb-2" onClick={onConfirmCustomInputValue}>
            {_s('buyGiftcard.selectCustomValue.select')}
          </button>
          <button
            className="text-primary button-block button-focus button-focus-primary unstyled-button text-sm font-semibold uppercase"
            onClick={onAbort}>
            {_s('abort')}
          </button>
        </ShadowModal>
      )}
    </>
  );
};

export default ValueSelector;
