import BottomNavBar from '@/components/modules/navigation/BottomNavBar/BottomNavBar';
import FooterNavigation from '@/components/modules/navigation/FooterNavigation';
import FooterNavigationContainer from '@/components/modules/navigation/FooterNavigationContainer';
import NavBar from '@/components/modules/navigation/navbar/NavBar/NavBar';
import { classnames } from '@/helpers';
import useMobileView from '@/hooks/useMobileView';
import { getFooterDisplayOption } from '../helpers';
import { PageViewLayout as PageViewLayoutProps } from '../types';

const PageViewLayout = ({
  children,
  footerDisplayOption,
  wrapperClass,
  headerNavigationContainerClassName,
  type,
  hideBottomNavBar = false,
  ...navbarProps
}: PageViewLayoutProps) => {
  const { isMobileView } = useMobileView();
  const showBottomNavBar = type === 'mainView' && !hideBottomNavBar && isMobileView;
  return (
    <div className={wrapperClass}>
      <NavBar navClass={classnames(headerNavigationContainerClassName)} {...navbarProps} type={type} />
      {children}
      {type === 'mainView' && (
        <div
          className={`${
            footerDisplayOption?.from && footerDisplayOption.to ? getFooterDisplayOption(footerDisplayOption) : ''
          }`}>
          <FooterNavigationContainer>
            <FooterNavigation />
          </FooterNavigationContainer>
        </div>
      )}
      {showBottomNavBar && <BottomNavBar source={navbarProps.source} />}
    </div>
  );
};

export default PageViewLayout;
