import { LinkButton } from '@/components/elements/forms/buttons';
import CertificateIcon from '@/components/icons/CertificateIcon';
import { _s } from '@/locale';

export type TCertificate = {
  url: string;
  name: string;
  title: string;
};

const Certificate = (certificate: TCertificate) => {
  return (
    <div className="flex items-center">
      <CertificateIcon className="text-black-900 mr-2 h-6 w-6 self-start" />
      <LinkButton to={certificate.url} target="_blank" rel="noreferrer" label={certificate.title} className="!p-0" />
    </div>
  );
};

const Certificates = ({
  employees,
}: {
  employees: { id: number; about: { name: string; certificates?: TCertificate[] } }[];
}) => {
  return (
    <div id="certificates" className="details-section scroll-mt-[160px] md:scroll-mt-[120px]">
      <h2 className="mb-2 text-2xl font-semibold">{_s('certificates')}</h2>
      <p className="text-black-600 text-sm">{_s('certificatesDescription')}</p>
      <div className="mt-6 grid grid-cols-1 gap-x-2 gap-y-6 md:grid-cols-2">
        {employees.map((employee) => {
          if (!employee.about?.certificates?.length) return null;
          return (
            <div key={employee.id}>
              <h3 className="mb-2 text-base font-semibold">
                {_s('practitioner')}: {employee.about.name}
              </h3>
              <div className="space-y-3">
                {[...employee.about.certificates].map((certificate) => (
                  <Certificate key={certificate.url} {...certificate} />
                ))}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Certificates;
