import { getCompanyType, shouldDisplayReviews } from './';
import { __ } from './../locale';

export function getFiltersTrackingLabel(filterId) {
  switch (filterId) {
    case 1:
      return 'accepts_klarna';
    case 2:
      return 'has_discount';
    case 3:
      return 'accepts_giftcard';
    case 4:
      return 'accepts_klarna_or_qliro';
    case 5:
      return 'has_discount_or_campaigns';
    default:
      return '';
  }
}

export function getTrackingPlace(place, screen) {
  let trackingProps = {};
  if (place && place.id) {
    trackingProps.company_id = place.id;
    trackingProps.company_type = getCompanyType(place);

    if (shouldDisplayReviews(place) && place.rating && place.rating.count > 4) {
      trackingProps.ratings_average = place.rating.score.toFixed(1);
      trackingProps.number_of_ratings = place.rating.count;
    }
  }

  if (screen) {
    trackingProps.screen_name = screen;
  }
  return trackingProps;
}

export function getTrackingSearchFilters(filters) {
  const searchedFilters = [];

  if (filters) {
    const { prefs } = filters;
    const checked = (prefs && prefs.split(',')) || [];

    if (checked.indexOf('1') > -1) {
      // Klarna
      searchedFilters.push(getFiltersTrackingLabel(1));
    }
    if (checked.indexOf('2') > -1) {
      // Top of search
      searchedFilters.push(getFiltersTrackingLabel(2));
    }
    if (checked.indexOf('3') > -1) {
      // Giftcard
      searchedFilters.push(getFiltersTrackingLabel(3));
    }
    if (checked.indexOf('4') > -1) {
      // Klarna or Qliro
      searchedFilters.push(getFiltersTrackingLabel(4));
    }
    if (checked.indexOf('5') > -1) {
      // Top of search & Campaigns
      searchedFilters.push(getFiltersTrackingLabel(5));
    }
  }

  return searchedFilters;
}

export function getTrackingSearch(filters, hits) {
  let trackingProps = {};

  if (filters) {
    const { location, q } = filters;

    const activeFilters = getTrackingSearchFilters(filters);
    if (activeFilters.length > 0) {
      trackingProps.search_filter = activeFilters;
    }

    if (q) {
      trackingProps.query = q;
    }

    if (location) {
      if (location === __('currentLocation')) {
        trackingProps.search_location_type = 'nearby';
      } else {
        trackingProps.search_location_city = location;
        trackingProps.search_location_type = 'user_input';
      }
    } else {
      trackingProps.search_location_type = 'default';
    }
  }

  if (hits || hits === 0) {
    trackingProps.number_of_search_results = parseInt(hits, 10);
  }

  return trackingProps;
}
