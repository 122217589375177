import { bookConstants } from '@/constants';
import { mergeMultidimensionalObj } from '@/helpers';
import React from 'react';
import { Dropdown, GiftCard, TextAreaInput, TextInput } from './index';

class EmployeeFrom extends React.Component {
  constructor(props) {
    super(props);

    this.loopFields();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.fields.length !== this.props.fields.length) {
      this.loopFields();
    }
  }

  loopFields() {
    this.props.fields.forEach((field, key) => {
      if (typeof this.props.data[field.id] !== 'undefined') {
        field.value = this.props.data[field.id];
      }
      if (field && field.id && !field.value) {
        // Call when empty
        this.props.changeInput(field.id, field.value || '', this.props.index);
      }
    });
  }

  handleApplyGiftCard = (code) => {
    this.props.applyGiftCard(code, this.props.index);
  };

  handleInputChange = (e) => {
    const { value, name } = e.target;
    this.props.changeInput(name, value, this.props.index);
  };

  handleError = (name, value) => {
    this.props.setError(name, value, this.props.index);
  };

  handleGetError = (name) => {
    return this.props.getError(name, this.props.index);
  };

  render() {
    const { fields, data, submitted, index, showAllFields, hideFields, canApplyGiftcard, revalidate, notesOnKeyPress } =
      this.props;
    if (!fields || !fields.length) {
      return null;
    }
    const form = fields.map((field, key) => {
      const fieldCopy = mergeMultidimensionalObj(field, {});
      if (typeof data[fieldCopy.id] !== 'undefined') {
        fieldCopy.value = data[fieldCopy.id];
      }

      if (showAllFields) {
        switch (fieldCopy.type) {
          case 'dropdown':
            return (
              <div className="w-full px-2 md:w-1/2" key={'field_' + key + '_' + index + '_' + field.id}>
                <Dropdown
                  submitted={submitted}
                  data={fieldCopy}
                  changeInput={this.handleInputChange}
                  setError={this.handleError}
                  getError={this.handleGetError}
                  revalidate={revalidate}
                />
              </div>
            );
          case 'text':
            switch (field.id) {
              case bookConstants.GIFTCARD_FIELD:
                return hideFields && canApplyGiftcard ? (
                  <div className="w-full px-2" key={'field_' + key + '_' + index + '_' + field.id}>
                    <GiftCard
                      submitted={submitted}
                      data={fieldCopy}
                      setError={this.handleError}
                      getError={this.handleGetError}
                      applyGiftCard={this.handleApplyGiftCard}
                      changeInput={this.handleInputChange}
                    />
                  </div>
                ) : null;
              case bookConstants.BOOKING_NOTES_FIELD:
                return hideFields ? (
                  <div className="w-full px-2" key={'field_' + key + '_' + index + '_' + field.id}>
                    <TextAreaInput
                      submitted={submitted}
                      data={fieldCopy}
                      changeInput={this.handleInputChange}
                      setError={this.handleError}
                      getError={this.handleGetError}
                      notesOnKeyPress={notesOnKeyPress}
                      revalidate={revalidate}
                    />
                  </div>
                ) : null;
              default:
                return (
                  <div className="w-full px-2 md:w-1/2" key={'field_' + key + '_' + index + '_' + field.id}>
                    <TextInput
                      submitted={submitted}
                      data={fieldCopy}
                      changeInput={this.handleInputChange}
                      setError={this.handleError}
                      getError={this.handleGetError}
                      revalidate={revalidate}
                    />
                  </div>
                );
            }
          default:
            return null;
        }
      } else {
        switch (field.id) {
          case bookConstants.GIFTCARD_FIELD:
            return canApplyGiftcard ? (
              <div className="w-full px-2" key={'field_' + key + '_' + index + '_' + field.id}>
                <GiftCard
                  submitted={submitted}
                  data={fieldCopy}
                  setError={this.handleError}
                  getError={this.handleGetError}
                  applyGiftCard={this.handleApplyGiftCard}
                  changeInput={this.handleInputChange}
                />
              </div>
            ) : null;
          case bookConstants.BOOKING_NOTES_FIELD:
            return (
              <div className="mb-0 w-full px-2" key={'field_' + key + '_' + index + '_' + field.id}>
                <TextAreaInput
                  submitted={submitted}
                  data={fieldCopy}
                  changeInput={this.handleInputChange}
                  setError={this.handleError}
                  getError={this.handleGetError}
                  notesOnKeyPress={notesOnKeyPress}
                  revalidate={revalidate}
                />
              </div>
            );
          default:
            return null;
        }
      }
    });

    if (!form.filter((a) => a).length) {
      return null;
    }

    return <div className="form -mx-2 flex flex-wrap">{form}</div>;
  }
}

export default EmployeeFrom;
