import { Card } from '@/components/elements/cards';
import { Button, LinkButton } from '@/components/elements/forms/buttons';
import { trackMpEvent, url } from '@/helpers';
import { _s } from '@/locale';
import { LPCTABoxField } from '@/types/landingPages';
import { useState } from 'react';

const trackCTABoxClick = () => {
  trackMpEvent('book_time_now_click', {
    screen_name: url.getScreenNameFromUrl(window.location.href),
  });
};

type CTABoxProps = LPCTABoxField & {
  landingPageID: number;
};

const CTABox = ({ buttonLabel, buttonSearchQuery, description, landingPageID }: CTABoxProps) => {
  const [open, setOpen] = useState<boolean>(true);

  return (
    <Card
      className={`fixed bottom-0 left-0 z-[1] mb-0 w-full rounded-none bg-white text-center md:relative md:rounded-lg ${
        !open ? 'hidden md:block' : ''
      }`}>
      <p className="mb-6">{description}</p>
      <LinkButton block variant="primary" to={`/${buttonSearchQuery}/var`} onClick={trackCTABoxClick}>
        {buttonLabel}
      </LinkButton>
      <div className="mt-2 md:hidden">
        <Button variant="tertiary" block onClick={() => setOpen(false)}>
          {_s('close')}
        </Button>
      </div>
    </Card>
  );
};

export default CTABox;
