import { AnchorHTMLAttributes, HTMLAttributes } from 'react';
import type { LinkProps } from 'react-router-dom';
import { Link as DomLink } from 'react-router-dom';
import { classnames } from '../../helpers';
import css from './content.module.scss';

export const h1Class = `${css.content} ${css.h1}`;
export const h2Class = `${css.content} ${css.h2}`;
export const h3Class = `${css.content} ${css.h3}`;
export const h4Class = `${css.content} ${css.h4}`;
export const h5Class = `${css.content} ${css.h5}`;
export const h6Class = `${css.content} ${css.h6}`;
export const pClass = `${css.content} ${css.p}`;
export const xlClass = `${css.content} ${css.xl}`;
export const lgClass = `${css.content} ${css.lg}`;
export const mdClass = `${css.content} ${css.md}`;
export const baseClass = `${css.content} ${css.base}`;
export const smClass = `${css.content} ${css.sm}`;
export const xsClass = `${css.content} ${css.xs}`;
export const xxsClass = `${css.content} ${css.xxs}`;
export const linkClass = `${css.content} ${css.a}`;
export const contentClass = css.content;

type HProps = HTMLAttributes<HTMLHeadingElement> & HStyle;
type PProps = HTMLAttributes<HTMLParagraphElement>;
type SpanProps = HTMLAttributes<HTMLSpanElement>;
type DivProps = HTMLAttributes<HTMLDivElement>;
type AnchorProps = AnchorHTMLAttributes<HTMLAnchorElement>;
type ULProps = HTMLAttributes<HTMLUListElement>;
type LIProps = HTMLAttributes<HTMLLIElement>;

export type HStyle = {
  styleAs?:
    | 'xxs'
    | 'xs'
    | 's'
    | 'm'
    | 'l'
    | 'xl'
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
    | 'xxs-bold'
    | 'xs-bold'
    | 'sm-bold'
    | 'md-bold'
    | 'lg-bold'
    | 'xl-bold'
    | 'h1-bold'
    | 'h2-bold'
    | 'h3-bold'
    | 'h4-bold'
    | 'h5-bold'
    | 'h6-bold';
};

const styleMapping = {
  xxs: xxsClass,
  xs: xsClass,
  sm: smClass,
  md: mdClass,
  lg: lgClass,
  xl: xlClass,
  h1: h1Class,
  h2: h2Class,
  h3: h3Class,
  h4: h4Class,
  h5: h5Class,
  h6: h6Class,
};

const getStyle = (styleAs: string) => {
  const newStyles = styleAs.split('-');

  if (!newStyles.length) return '';
  return classnames(styleMapping[newStyles[0]], newStyles.includes('bold') ? 'font-bold' : '');
};

export const H1 = ({ children = null, className, styleAs, ...props }: HProps) => (
  <h1 className={classnames(h1Class, className, styleAs ? getStyle(styleAs) : '')} {...props}>
    {children ?? ''}
  </h1>
);
export const H2 = ({ children = null, className, styleAs, ...props }: HProps) => (
  <h2 className={classnames(h2Class, className, styleAs ? getStyle(styleAs) : '')} {...props}>
    {children ?? ''}
  </h2>
);
export const H3 = ({ children = null, className, styleAs, ...props }: HProps) => (
  <h3 className={classnames(h3Class, className, styleAs ? getStyle(styleAs) : '')} {...props}>
    {children ?? ''}
  </h3>
);
export const H4 = ({ children = null, className, styleAs, ...props }: HProps) => (
  <h4 className={classnames(h4Class, className, styleAs ? getStyle(styleAs) : '')} {...props}>
    {children ?? ''}
  </h4>
);
export const H5 = ({ children = null, className, styleAs, ...props }: HProps) => (
  <h5 className={classnames(h5Class, className, styleAs ? getStyle(styleAs) : '')} {...props}>
    {children ?? ''}
  </h5>
);
export const H6 = ({ children = null, className, styleAs, ...props }: HProps) => (
  <h6 className={classnames(h6Class, className, styleAs ? getStyle(styleAs) : '')} {...props}>
    {children ?? ''}
  </h6>
);

export const P = ({ children = null, className, ...props }: PProps) => (
  <p className={classnames(pClass, className)} {...props}>
    {children}
  </p>
);

export const Span = ({ children = null, className, ...props }: SpanProps) => (
  <span className={classnames(baseClass, className)} {...props}>
    {children}
  </span>
);

export const Div = ({ children = null, className, ...props }: DivProps) => (
  <div className={classnames(baseClass, className)} {...props}>
    {children}
  </div>
);

export const Anchor = ({ children = null, className, ...props }: AnchorProps) => (
  <a className={classnames(linkClass, className)} {...props}>
    {children}
  </a>
);

export const Link = ({ children = null, className, ...props }: LinkProps) => (
  <DomLink className={classnames(linkClass, className)} {...props}>
    {children}
  </DomLink>
);

export const Small = ({ children = null, className, ...props }: SpanProps) => (
  <small className={classnames(xsClass, className)} {...props}>
    {children}
  </small>
);

export const XS = ({ children = null, className, ...props }: SpanProps) => (
  <small className={classnames(xsClass, className)} {...props}>
    {children}
  </small>
);

const Content = ({ children = null, className, ...props }: DivProps) => (
  <div className={classnames(css.content, className)} {...props}>
    {children}
  </div>
);

export const UL = ({ children = null, className, ...props }: ULProps) => <ul className="ml-4 list-disc">{children}</ul>;

export const LI = ({ children = null, className, ...props }: LIProps) => (
  <li className={classnames('mb-2', className)}>{children}</li>
);

export default Content;
