import { ReactNode } from 'react';
import BundleCardListItem, { BundleListItemProps } from '../../lists/BundleCardListItem/BundleCardListItem';
import Card from '../Card';

const BundleCard = ({ bottomSlot, ...bundleListItemProps }: BundleListItemProps & { bottomSlot?: ReactNode }) => {
  return (
    <Card>
      <BundleCardListItem {...bundleListItemProps} leftPadding={false} rightPadding={false} />
      {bottomSlot && <div className="pt-md w-full">{bottomSlot}</div>}
    </Card>
  );
};

export default BundleCard;
