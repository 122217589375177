import LoadingPlaceHolder from '@/components/elements/LoadingPlaceholder';
import PageViewLayout from '@/components/layouts/PageViewLayout/PageViewLayout';
import { _s } from '@/locale';
import { Redirect } from 'react-router-dom';
import useValidateCofPaymentRedirect from './ValidateCofPaymentRedirect.hooks';

export const baseTranslationKey = 'pages.validate-cof-payment-redirect.ValidateCofPaymentRedirect';

const ValidateCofPaymentRedirect = () => {
  const { redirect } = useValidateCofPaymentRedirect();

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  return (
    <PageViewLayout type="subView">
      <LoadingPlaceHolder text={_s(`${baseTranslationKey}.loadingLabel`)} />
    </PageViewLayout>
  );
};

export default ValidateCofPaymentRedirect;
