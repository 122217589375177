import { userActions } from '@/actions';
import LoadingPlaceHolder from '@/components/elements/LoadingPlaceholder';
import { Button } from '@/components/elements/forms/buttons';
import Snackbar from '@/components/elements/notifications/Snackbar/Snackbar';
import Icon from '@/components/icons/Icon';
import PageViewLayout from '@/components/layouts/PageViewLayout/PageViewLayout';
import SEO, { seoPropsFromBaseString } from '@/components/modules/SEO';
import { ThreeDSComponent } from '@/components/modules/adyen';
import AddCard from '@/components/modules/adyen/AddCard';
import { ModalActionbar } from '@/components/modules/modals/Modal';
import CardOptionsModal from '@/components/modules/pages/betalningsmetoder/CardOptionsModal';
import PaymentCardListItem from '@/components/modules/pages/betalningsmetoder/PaymentCardListItem';
import EmptyState from '@/components/templates/EmptyState';
import { EVENT_NAME, SCREEN_NAME } from '@/constants/analytics';
import { promiseWrapper, trackMpEvent, trackPage, url } from '@/helpers';
import { getCheckoutClientErrorMessage } from '@/helpers/adyen';
import { buildBrowserInfo } from '@/helpers/googlepay';
import withWalletPayment from '@/hoc/withWalletPayment';
import { useAppSelector } from '@/hooks';
import { UseCardsProvider, useCardsContext, useCardsManager } from '@/hooks/adyen/useCards';
import { useAddAlert } from '@/hooks/useAlert';
import useTrackScreenView, { trackScreenView } from '@/hooks/useTrackScreenView';
import { _s } from '@/locale';
import { adyenServices } from '@/services';
import { getScreenName, handleLoginClick } from '@/services/navigationServices';
import { CheckoutError, CofPaymentData, NewOnlineCardPaymentData } from '@/types/adyen';
import { NavItemIdentifier } from '@/types/navigation';
import { PaymentCard } from '@/types/paymentcards';
import { PaymentData } from '@adyen/adyen-web/dist/types/components/types';
import { Fragment, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

const PaymentMethods = () => {
  const user = useAppSelector((state) => state.users?.user);
  const [showRemoveCardModal, setShowRemoveCardModal] = useState(false);
  const [isAddingCard, setIsAddingCard] = useState(false);
  const [selectedCard, setSelectedCard] = useState<PaymentCard>(null);
  const [threeDSAction, setThreeDSAction] = useState<string>(null);
  const history = useHistory();
  const location = useLocation();
  const addAlert = useAddAlert();
  const dispatch = useDispatch();
  const { loading: cardLoading, error: cardError, loadCards, removeCard, cards, waitForCard } = useCardsContext();

  useEffect(() => {
    trackPage();
    trackScreenView({ name: 'screen_view_payment_methods' });
  }, []);

  useTrackScreenView(
    { name: 'screen_view_add_payment_card_details', properties: { trigger_source: 'payment_methods' } },
    [isAddingCard],
    [isAddingCard],
  );

  useTrackScreenView(
    { name: 'screen_view_delete_payment_card', properties: { trigger_source: 'payment_methods' } },
    [showRemoveCardModal],
    [showRemoveCardModal],
  );

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    if (queryParams.has('redirectResult')) {
      adyenServices.validate3DS({ details: { redirectResult: queryParams.get('redirectResult') } }).then((res) => {
        trackMpEvent('payment_method_3DS1_redirect', { screen_name: 'payment_methods', result: res.resultCode });
        if (res.resultCode !== 'Authorised') {
          addAlert({ message: _s('addedNewCardFailed'), type: 'error', autoClose: true });
          return;
        }
        addAlert({ message: _s('addedNewCard'), type: 'success', autoClose: true });
      });
      queryParams.delete('sessionId');
      queryParams.delete('redirectResult');
      history.replace({
        search: queryParams.toString(),
      });
    }
  }, [history, location.search]);

  useEffect(() => {
    if (!cardLoading && cardError) {
      toast(({ closeToast }) => <Snackbar type="danger" label={_s('getStoredCardsError')} onClose={closeToast} />);
    }
  }, [cardLoading, cardError]);

  const handleRemoveCard = async (id: string) => {
    trackMpEvent(EVENT_NAME.CONFIRM_DELETE_PAYMENT_CARD_CLICK, {
      trigger_source: SCREEN_NAME.PAYMENT_METHODS,
      delete_payment_card_state: true,
    });

    setShowRemoveCardModal(false);
    const isCardRemoved = await removeCard(id);
    if (!isCardRemoved) {
      toast(({ closeToast }) => <Snackbar type="danger" label={_s('removeCardError')} onClose={closeToast} />);
      return;
    }
    loadCards();
    toast(({ closeToast }) => (
      <Snackbar type="success" label={_s('cardRemoved')} action={<button onClick={closeToast}>OK</button>} />
    ));
  };

  const handleCloseRemoveCardModal = () => {
    trackMpEvent(EVENT_NAME.CONFIRM_DELETE_PAYMENT_CARD_CLICK, {
      screen_name: 'payment_methods',
      delete_payment_card_state: false,
    });
    setShowRemoveCardModal(false);
  };

  const handleOnSelect = (card: PaymentCard) => {
    setSelectedCard(card);
    setShowRemoveCardModal(true);
  };

  const handleOnAdditionalDetails = async (response) => {
    const { data, error } = await promiseWrapper(adyenServices.validate3DS({ details: response.data.details }));
    if (error || data?.resultCode !== 'Authorised') {
      toast(({ closeToast }) => (
        <Snackbar type="success" label={_s('checkoutError.StoreTokenError')} onClose={closeToast} />
      ));
    }
    setIsAddingCard(false);
    setThreeDSAction(null);
    waitForCard(data?.additionalData?.['recurring.recurringDetailReference']);
  };

  const handleOnAuthorize = async (paymentData: PaymentData) => {
    trackMpEvent(EVENT_NAME.SAVE_PAYMENT_CARD_DETAILS_CLICK, {
      trigger_source: SCREEN_NAME.PAYMENT_METHODS,
    });

    const paymentMethod = paymentData.paymentMethod;
    const parsedPaymentData: NewOnlineCardPaymentData = {
      type: 'newCard',
      encryptedCardNumber: paymentMethod.encryptedCardNumber,
      encryptedExpiryMonth: paymentMethod.encryptedExpiryMonth,
      encryptedExpiryYear: paymentMethod.encryptedExpiryYear,
      encryptedSecurityCode: paymentMethod.encryptedSecurityCode,
      holderName: paymentMethod.holderName,
    };

    const saveUserCardData: CofPaymentData = {
      paymentMethod: parsedPaymentData,
      redirectInfo: { returnPath: '/betalningsmetoder' },
      browserInfo: paymentData.browserInfo || buildBrowserInfo(),
    };

    const { data, error } = await promiseWrapper(adyenServices.saveUserCard({ paymentData: saveUserCardData }));

    if (data && data.requiresAction) {
      setThreeDSAction(data.adyenActionJson);
      return;
    }

    setIsAddingCard(false);

    if (error) {
      toast(({ closeToast }) => <Snackbar type="success" label={_s('serverError')} onClose={closeToast} />);
      return;
    }

    trackMpEvent('payment_method_added', { screen_name: 'payment_methods' });
    await waitForCard(data?.paymentDetails?.additionalData?.['recurring.recurringDetailReference']);
    toast(
      ({ closeToast }) => (
        <div className="absolute bottom-[94px] left-0 right-0 w-full">
          <Snackbar type="success" label={_s('addedNewCard')} action={<button onClick={closeToast}>OK</button>} />
        </div>
      ),
      {
        autoClose: false,
      },
    );
    // @ts-ignore
    dispatch(userActions.updateUser({ ...user, activeCoF: true }, true));
  };

  const handleStoreCardError = (error: CheckoutError) => {
    trackMpEvent('payment_method_add_error', { screen_name: 'payment_methods', error });
    setIsAddingCard(false);
    addAlert({ message: getCheckoutClientErrorMessage(error), type: 'error', autoClose: true });
  };

  const handleAddNewCardClick = () => {
    trackMpEvent(EVENT_NAME.ADD_PAYMENT_CARD_CLICK, {
      trigger_source: SCREEN_NAME.PAYMENT_METHODS,
    });
    setIsAddingCard(true);
  };

  const handleStoreCardDismiss = () => {
    trackMpEvent('payment_method_add_new_card_dismissed', { screen_name: 'payment_methods' });
    setIsAddingCard(false);
  };

  const handleOnBackButtonClick = () => {
    if (isAddingCard) {
      handleStoreCardDismiss();
      return;
    }
    history.goBack();
  };

  if (!user) {
    return (
      <PageViewLayout type="subView" title={_s('paymentMethods')}>
        <EmptyState
          title={_s('paymentMethods.emptyState.title')}
          body={_s('paymentMethods.emptyState.body')}
          icon={<img src="/images/illustrations/payment-methods2.svg" alt="" />}
          cta={
            <Button
              label={_s('paymentMethods.emptyState.button')}
              size="sm"
              onClick={() => handleLoginClick(dispatch, NavItemIdentifier.LogIn, getScreenName('betalningsmetoder'))}
            />
          }
        />
        <SEO {...seoPropsFromBaseString('paymentMethods', url.getBaseUrl() + 'betalningsmetoder')} />
      </PageViewLayout>
    );
  }

  const hasStoredCards = cards.length > 0;

  return (
    <PageViewLayout
      type="subView"
      back
      onBackButtonClick={handleOnBackButtonClick}
      title={isAddingCard ? _s('addNewPaymentCard') : _s('paymentMethods')}
      source="betalningsmetoder"
      wrapperClass="min-h-screen bg-brown-50">
      {cardLoading && <LoadingPlaceHolder />}

      {!cardLoading && (
        <Fragment>
          {selectedCard && (
            <CardOptionsModal
              isOpen={showRemoveCardModal}
              onClose={handleCloseRemoveCardModal}
              onRemove={handleRemoveCard}
              selectedCard={selectedCard}
            />
          )}

          <Fragment>
            {threeDSAction && isAddingCard && (
              <div className="h-[calc(100vh-64px)]">
                <ThreeDSComponent
                  action={JSON.parse(threeDSAction)}
                  handleOnAdditionalDetails={handleOnAdditionalDetails}
                />
              </div>
            )}

            {isAddingCard && !threeDSAction && (
              <div className="px-lg pt-lg bg-white">
                <div className="mx-auto w-full max-w-screen-md">
                  <AddCard
                    onAuthorize={handleOnAuthorize}
                    onError={handleStoreCardError}
                    submitLabel={_s('addCard')}
                    disclaimerLabel={_s('pages.betalningsmetoder.addCardDisclaimer')}
                  />
                </div>
              </div>
            )}

            {!isAddingCard && !threeDSAction && (
              <Fragment>
                {hasStoredCards ? (
                  <Fragment>
                    <div className="bg-white">
                      <div className="mx-auto w-full max-w-screen-md">
                        {cards.map((card) => {
                          return <PaymentCardListItem key={card.id} card={card} onSelect={handleOnSelect} />;
                        })}
                      </div>
                    </div>
                    <ModalActionbar className="!z-10">
                      <div className="mx-auto w-full max-w-screen-md">
                        <Button
                          label={_s('addNewCard')}
                          leftIcon={<Icon variant="plus" />}
                          onClick={handleAddNewCardClick}
                          size="md"
                          variant="primary"
                          block
                        />
                      </div>
                    </ModalActionbar>
                  </Fragment>
                ) : (
                  <div className="flex min-h-[calc(100vh-64px)] flex-col items-center justify-center">
                    <EmptyState
                      title={_s('pages.betalningsmetoder.noCardState.title')}
                      body={_s('pages.betalningsmetoder.noCardState.subTitle')}
                      icon={<img src="/images/illustrations/payment-methods2.svg" alt="" />}
                      cta={
                        <Button
                          label={_s('pages.betalningsmetoder.noCardState.cta')}
                          size="sm"
                          onClick={handleAddNewCardClick}
                          leftIcon={<Icon variant="plus" />}
                          data-cy="add-stored-payment-card-button"
                        />
                      }
                    />
                  </div>
                )}
              </Fragment>
            )}
          </Fragment>
        </Fragment>
      )}

      <SEO {...seoPropsFromBaseString('paymentMethods', url.getBaseUrl() + 'betalningsmetoder')} />
    </PageViewLayout>
  );
};

const PaymentMethodsWithProviders = () => {
  const user = useAppSelector((state) => state.users?.user);
  const { cards, loading: cardLoading } = useCardsManager([], !!user);

  if (cardLoading) return <LoadingPlaceHolder />;

  return (
    <UseCardsProvider initialCards={cards}>
      <PaymentMethods></PaymentMethods>
    </UseCardsProvider>
  );
};

export default withWalletPayment(PaymentMethodsWithProviders);
