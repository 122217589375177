import { ReactNode } from 'react';
import DataGridCell from './DataGridCell/DataGridCell';
import DataGridRow from './DataGridRow/DataGridRow';

const DataGrid = ({ children, ariaLabel }: { children: ReactNode; ariaLabel: string }) => {
  return (
    <div className="flex flex-col" role="grid" aria-label={ariaLabel}>
      {children}
    </div>
  );
};

DataGrid.Row = DataGridRow;
DataGrid.Cell = DataGridCell;

export default DataGrid;
