import LoadingPlaceHolder from '@/components/elements/LoadingPlaceholder';
import GoBack from '@/components/modules/pages/bokningar/GoBack';
import useBundleCheckoutFallback from '@/hooks/checkout/useBundleCheckoutFallback';

function withBundleCheckoutFallback<P>(WrappedComponent: React.ComponentType<P>) {
  const WithPlaceDetails = (props: P) => {
    const { status } = useBundleCheckoutFallback();

    if (status === 'loading') {
      return <LoadingPlaceHolder />;
    }

    if (status === 'error') {
      return <GoBack />;
    }

    return <WrappedComponent {...props} />;
  };
  return WithPlaceDetails;
}

export default withBundleCheckoutFallback;
