import { HTMLAttributes, ReactNode } from 'react';
import css from './Checkbox.module.scss';

type Props = HTMLAttributes<HTMLInputElement> & {
  checked: boolean;
  label: ReactNode;
  disabled?: boolean;
  onClick?: () => void;
};

const Checkbox = ({ checked, label, disabled = false, ...props }: Props) => {
  return (
    <>
      <input
        type="checkbox"
        disabled={disabled}
        className={css.checkbox}
        checked={checked}
        onClick={props.onClick}
        {...props}
      />
      {label}
    </>
  );
};

export default Checkbox;
