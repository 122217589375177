import { z } from 'zod';

export const paymentCardBrandSchema = z.union([
  z.literal('visa'),
  z.literal('mc'),
  z.literal('amex'),
  z.literal('diners'),
  z.literal('discover'),
  z.literal('maestro'),
  z.literal('amex_googlepay'),
  z.literal('discover_googlepay'),
  z.literal('mc_googlepay'),
  z.literal('visa_googlepay'),
  z.literal('maestro_googlepay'),
  z.literal('amex_applepay'),
  z.literal('discover_applepay'),
  z.literal('mc_applepay'),
  z.literal('visa_applepay'),
  z.literal('maestro_applepay'),
  z.literal('unknown'),
]);

const paymentCardSchema = z.object({
  brand: paymentCardBrandSchema,
  expiryMonth: z.string(),
  expiryYear: z.string(),
  holderName: z.string(),
  id: z.string(),
  lastFour: z.string(),
  name: z.string(),
  networkTxReference: z.string().optional(),
  supportedShopperInteractions: z.array(z.string()).optional(),
  type: z.string().optional(),
  /**
   * If true card cannot be disabled
   */
  preventDisable: z.boolean().optional(),
});

export type PaymentCardBrand = z.infer<typeof paymentCardBrandSchema>;
export type PaymentCard = z.infer<typeof paymentCardSchema>;
