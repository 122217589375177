import {
  getDateFormattedForFirstAvailableTime,
  getTrackingPlace,
  getTrackingSearch,
  isServer,
  mergeMultidimensionalObj,
  roundRating,
  trackMpEvent,
} from '@/helpers';

import Card from '@/components/elements/Card/Card';
import withMobileView from '@/hoc/withMobileView';
import React from 'react';
import { connect } from 'react-redux';
import pinStyles from './Markers.module.scss';

let Marker = false;
let Circle = false;
let Polygon = false;
let Rectangle = false;
let Popup = false;
let FeatureGroup = false;
let DivIcon = false;
if (!isServer) {
  let RL = require('react-leaflet');
  let L = require('leaflet');
  Marker = RL.Marker;
  Circle = RL.Circle;
  Polygon = RL.Polygon;
  Rectangle = RL.Rectangle;
  Popup = RL.Popup;
  FeatureGroup = RL.FeatureGroup;
  DivIcon = L.DivIcon;
}

class Markers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.setHover = this.setHover.bind(this);
    this.setHoverOut = this.setHoverOut.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.position && nextProps.position !== this.state.queryParameters.center) {
      const center = nextProps.position.lat.toString() + ',' + nextProps.position.lon.toString();
      const newParams = mergeMultidimensionalObj(this.state.queryParameters, {
        center: center,
        mc: center,
        mt: 'Your Location',
      });
      this.setState({ queryParameters: newParams });
    }
    if (nextProps.placeHover !== this.state.placeHover) {
      this.setState({ placeHover: nextProps.placeHover });
    }
    if (nextProps.places !== this.state.localPlaces) {
      this.setState({ localPlaces: nextProps.places });
    }
  }

  setHover(placeId) {
    this.setState({ placeHover: placeId });
  }
  setHoverOut(placeId) {
    this.setState({ placeHover: -1 });
  }

  handleTrackPlace = (event, place) => {
    const trackingProps = {
      screen_name: 'search_results_map',
      company_id: place.id,
    };
    trackMpEvent(event, { ...trackingProps, ...getTrackingPlace(place), ...getTrackingSearch(this.props.search) });
  };

  getFirstAvailableTime(place) {
    const matchedService = place.matchedServices && place.matchedServices[0] ? place.matchedServices[0] : null;
    const availability =
      matchedService && matchedService.availability && matchedService.availability[0]
        ? matchedService.availability[0]
        : null;
    if (!availability) {
      return null;
    }

    return (
      <span className="text-primary-500 block text-sm">
        {getDateFormattedForFirstAvailableTime(availability, place.timezone)}
      </span>
    );
  }

  displayPlaceDetails = (place, key) => (e) => {
    this.handleTrackPlace('map_pin_clicked', place);

    if (!isServer && this.props.isMobileView && this.props.setCarouselIndex) {
      this.props.setCarouselIndex(key);
    }
  };

  getPinSrc(active, rating) {
    if (active) {
      return rating ? '/images/map-pin-selected.svg' : '/images/map-pin-selected-empty.svg';
    }

    return rating ? '/images/map-pin.svg' : '/images/map-pin-empty.svg';
  }

  render() {
    const { places, search } = this.props;
    const { placeHover } = this.state;
    const markers = places
      ? places.map((place, key) => {
          const lat =
            place.contact && place.contact.position && place.contact.position.lat
              ? parseFloat(place.contact.position.lat)
              : 0;
          const lon =
            place.contact && place.contact.position && place.contact.position.lon
              ? parseFloat(place.contact.position.lon)
              : 0;
          const coordinates = [lat, lon];
          const onClick = this.displayPlaceDetails(place, key).bind(this);

          const active =
            parseInt(placeHover, 10) === parseInt(place.id, 10) ||
            (this.props.isMobileView && key === this.props.carouselIndex);
          const rating = place?.rating?.score ? roundRating(place.rating.score) : false;
          const pinSrc = this.getPinSrc(active, rating);
          const ic = new DivIcon({
            iconSize: active ? [60, 67] : [40, 40],
            iconAnchor: active ? [30, 67] : [20, 40],
            className: 'place-pin ' + (active ? pinStyles.placePinActive : ''),
            html:
              '<img class="' +
              pinStyles.placePinImage +
              '" src="' +
              pinSrc +
              '"/>' +
              '<span class="' +
              pinStyles.placePinRating +
              '">' +
              (rating ? rating : '') +
              '</span>',
          });
          return (
            <Marker
              key={key}
              position={coordinates}
              icon={ic}
              zIndexOffset={parseInt(placeHover, 10) === parseInt(place.id, 10) ? 100 : 3}
              onMouseOver={() => {
                this.setHover(place.id);
              }}
              onMouseOut={() => {
                this.setHoverOut(place.id);
              }}
              eventHandlers={{
                click: onClick,
              }}>
              {!this.props.isMobileView && this.props?.getPlaceDetails && (
                <Popup offset={[0, active ? -60 : -40]} minWidth={300}>
                  <div className="w-full">
                    <Card>{this.props.getPlaceDetails(place)}</Card>
                  </div>
                </Popup>
              )}
            </Marker>
          );
        })
      : false;

    if (search?.debugLocation?.length > 0) {
      search.debugLocation?.forEach((locationGroup, index) => {
        locationGroup.forEach((loc, key) => {
          if (loc.type === 'polygon') {
            markers.push(
              <Polygon
                key={`${index}_${key}`}
                positions={loc.coordinates[0]}
                color={loc?.must_not ? 'red' : 'green'}
                fillColor={loc?.must_not ? 'red' : 'green'}
                fillOpacity={0.2}></Polygon>,
            );
          } else if (loc.type === 'geo_distance') {
            markers.push(
              <Circle
                key={`${index}_${key}`}
                center={loc.position}
                radius={loc.distance}
                color={loc?.must_not ? 'red' : 'green'}
                fillColor={loc?.must_not ? 'red' : 'green'}
                fillOpacity={0.2}></Circle>,
            );
          } else if (loc.type === 'rectangle') {
            markers.push(
              <Rectangle
                key={`${index}_${key}`}
                bounds={loc.position}
                color={loc?.must_not ? 'red' : 'green'}
                fillOpacity={0.2}
              />,
            );
          }
        });
      });
    }
    return <FeatureGroup>{markers}</FeatureGroup>;
  }
}

function mapStateToProps(state) {
  const { placeHover } = state.place;
  const { search } = state;
  return {
    placeHover,
    search,
  };
}

const MarkersConnected = withMobileView(connect(mapStateToProps)(Markers));

export { MarkersConnected as Markers };
