import { getCookie, removeCookie, setCookie, url } from '.';
import { config } from '../config';
const isServer = typeof window === 'undefined';

const isOldIE = !isServer && window.navigator.userAgent.indexOf('MSIE ') !== -1;
const isIE =
  !isServer &&
  (window.navigator.userAgent.indexOf('MSIE ') !== -1 || window.navigator.userAgent.indexOf('Trident/') !== -1);

const isSentry = config.sentry && !isOldIE;

const isSistaminuten = () =>
  process.env.REACT_APP_INSTANCE_TYPE === 'sistaminuten' || (!isServer && window._INSTANCE_TYPE === 'sistaminuten');

function allowTracking() {
  if (!isServer) {
    const cookiePreferences = getCookie('BokadirektCookiePreferencesMP');
    if (cookiePreferences === 'Analytics') {
      return true;
    }
  }

  return false;
}

function renameCookie(oldName: string, newName: string) {
  const oldCookieValue = getCookie(oldName);
  if (oldCookieValue) {
    // Set the new cookie with the old value
    setCookie(newName, oldCookieValue);
    // Delete the old cookie
    removeCookie(oldName);
  }
}

enum ConsentValue {
  Denied = 'denied',
  Granted = 'granted',
}

function updateConsent(value: ConsentValue) {
  window.gtag('consent', 'update', {
    ad_user_data: value,
    ad_personalization: value,
    ad_storage: value,
    analytics_storage: value
  });
}

function setCookiePreferences(value = 'Analytics') {
  if (value !== 'Analytics') {
    setCookie('BokadirektCookiePreferencesMP', value, {
      path: '/',
      domain: url.getDomain(),
      expires: new Date(new Date().setDate(new Date().getDate() + 1)),
    });  
    updateConsent(ConsentValue.Denied);
  } else {
    setCookie('BokadirektCookiePreferencesMP', value, {
      path: '/',
      domain: url.getDomain(),
      expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
    });
    updateConsent(ConsentValue.Granted);
  }
}

function classnames(...classes) {
  return classes.filter((c) => c).join(' ');
}

var isMobileVar = false;

function setMobile() {
  isMobileVar = true;
}
function unsetMobile() {
  isMobileVar = false;
}
function isMobile() {
  if (isServer) {
    return isMobileVar;
  }
  const result = JSON.parse(sessionStorage.getItem('isMobile'));
  return result || false;
}

const isIOS = () => {
  if (!isServer && navigator && /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream) {
    return true;
  }
  return false;
};

const isIPhone = () => {
  if (!isServer && navigator && /iPhone/.test(navigator.userAgent) && !window.MSStream) {
    return true;
  }
  return false;
};

const isSafari = (userAgent) => {
  const chromeAgent = userAgent.indexOf('CriOS') > -1;
  const safariAgent = userAgent.indexOf('Safari') > -1;

  return !chromeAgent && safariAgent;
};

const showIphoneTopBanner = () => {
  if (!isIPhone() || isSafari(navigator.userAgent)) return false;

  let closedIPhoneTopBanner = localStorage.getItem('closedIPhoneTopBanner') || 0;
  if (+closedIPhoneTopBanner >= 3) return false;
  let showIPhoneTopBanner = getCookie('showIPhoneTopBanner');
  if (showIPhoneTopBanner === 'false') return false;
  return true;
};

const showAndroidTopBanner = () => isAndroidPhone() && !getCookie('closedTopBanner');

const isAndroid = () => {
  if (!isServer && navigator && /Android/i.test(navigator.userAgent) && !window.MSStream) {
    return true;
  }
  return false;
};

const isAndroidPhone = () => {
  if (
    !isServer &&
    navigator &&
    /Android/i.test(navigator.userAgent) &&
    !/Tablet/i.test(navigator.userAgent) &&
    /Mobile/i.test(navigator.userAgent) &&
    !window.MSStream
  ) {
    return true;
  }
  return false;
};

function isLanding() {
  if (isServer) {
    return false;
  }
  return localStorage.getItem('isLanding') || false;
}

export {
  allowTracking,
  classnames,
  isAndroid,
  isAndroidPhone,
  isIE,
  isIOS,
  isIPhone,
  isLanding,
  isMobile,
  isOldIE,
  isSentry,
  isServer,
  isSistaminuten,
  renameCookie,
  setCookiePreferences,
  setMobile,
  showAndroidTopBanner,
  showIphoneTopBanner,
  unsetMobile,
  updateConsent,
  ConsentValue,
};
