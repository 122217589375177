import { h2Class } from '@/components/elements/content';
import { Section } from '@/constants/faq';
import { classnames } from '@/helpers';
import { Link } from 'react-router-dom';

type Props = { sections: Section[]; selectedPath: string };

const NavLink = ({ section, selected }: { section: Section; selected: boolean }) => (
  <Link
    to={section.path}
    className={classnames(
      'flex h-10 items-center justify-start pl-4 text-base font-semibold',
      selected ? 'text-primary border-primary border-l-2' : 'text-black-900',
    )}>
    {section.title}
  </Link>
);

const Navigation = ({ sections, selectedPath }: Props) => {
  return (
    <div>
      <h1 className={`${h2Class} !mb-4`}>FAQ</h1>
      {sections.map((section) => (
        <NavLink section={section} selected={selectedPath === section.path} key={section.path} />
      ))}
    </div>
  );
};

export default Navigation;
