import { Button } from '@/components/elements/forms/buttons';
import ListInput from '@/components/elements/forms/listInput/ListInput/ListInput';
import Icon from '@/components/icons/Icon';
import { EVENT_NAME, SCREEN_NAME } from '@/constants/analytics';
import { trackMpEvent } from '@/helpers';
import { LoginContextType } from '@/hooks/useLogin';
import { _s } from '@/locale';
import { LoginTriggerSource } from '@/types/analytics';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

type ProtectedUserLoginProps = Omit<
  LoginContextType,
  'handleLogin' | 'updateView' | 'view' | 'user' | 'clearLoginState' | 'handleEmailResendClicked'
> & { triggerSource: LoginTriggerSource };

const baseTranslationKey = `components.templates.checkout.ProtectedUserLogin`;

const ProtectedUserLogin = ({ email, updateEmail, handleEmailSubmit, triggerSource }: ProtectedUserLoginProps) => {
  const { pathname } = useLocation();

  const handleLoginClick = () => {
    trackMpEvent(EVENT_NAME.LOGIN_CONTINUE_CLICK, { screen_name: SCREEN_NAME.LOGIN_START });
    handleEmailSubmit({ triggerSource: triggerSource, redirectUrl: pathname });
  };

  useEffect(() => {
    updateEmail(email.value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="gap-md flex flex-col">
      <p className="text-black-600">
        {_s(`${baseTranslationKey}.body`)} <span className="text-black-900">{email.value}</span>
      </p>
      <div className="gap-md flex flex-col">
        <ListInput
          disabled
          id="email"
          type="email"
          value={email.value}
          leftIcon={<Icon variant="email" />}
          error={email.error && email.submitting ? _s(`RequestToken.input.error`) : undefined}
        />
        <Button
          disabled={email.error || email.submitting}
          label={_s(`${baseTranslationKey}.cta`)}
          onClick={handleLoginClick}
          size="md"
          variant="primary"
        />

        <p className="text-black-600 text-m">
          {_s(`RequestToken.terms.text`)}
          <br />
          <a href="/articles/terms-booking-consumer" target="_blank" className="underline">
            {_s(`RequestToken.terms.link`)}
          </a>
        </p>
      </div>
    </div>
  );
};

export default ProtectedUserLogin;
