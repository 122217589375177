import { LoginSuccess } from '@/components/animations';
import { LinkButton } from '@/components/elements/forms/buttons';
import LoadingPlaceHolder from '@/components/elements/LoadingPlaceholder';
import Icon from '@/components/icons/Icon';
import PageViewLayout from '@/components/layouts/PageViewLayout/PageViewLayout';
import CardWrapper from '@/components/modules/CardWrapper';
import EmptyState from '@/components/templates/EmptyState';
import CheckoutDetails from '@/components/templates/giftCards/CheckoutDetails/CheckoutDetails';
import { mapKlarnaOrderToGiftcardOrder, trackKlarnaGiftcardPurchase } from '@/helpers/giftcards';
import useMobileView from '@/hooks/useMobileView';
import { _s } from '@/locale';
import { giftCardServices, Order } from '@/services';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, Redirect, useLocation } from 'react-router-dom';

const baseTranslationsKey = 'pages.giftcard.confirmation';

const GiftcardConfirmation = () => {
  const { isMobileView } = useMobileView();
  const location = useLocation();
  const [order, setOrder] = useState<Order>(null);
  const selectedPaymentMethod = location.state?.selectedPaymentMethod;
  const guestEmail = location.state?.guestEmail;
  const isGuest = Boolean(guestEmail);

  const queryParams = new URLSearchParams(location.search);
  const klarnaID = queryParams.get('klarna_order_id');
  const sessionID = queryParams.get('session_id');
  const [response, loading, error] = giftCardServices.useConfirmGiftCardOrder(klarnaID, sessionID);

  useEffect(() => {
    if (!response || response.error) return;
    setOrder(mapKlarnaOrderToGiftcardOrder(response));

    if (!response.alreadyCreated) {
      trackKlarnaGiftcardPurchase(response, klarnaID, sessionID);
    }
  }, [response, klarnaID]);

  if (loading) return <LoadingPlaceHolder text={_s(`${baseTranslationsKey}.loading`)} />;

  if (error) {
    return <Redirect to="/" />;
  }

  return (
    <PageViewLayout
      type="subView"
      wrapperClass={`min-h-screen ${isMobileView ? 'bg-brown-50' : 'bg-gradient'}`}
      rightSlot={
        <Link to={'/'}>
          <Icon variant="close-alt" alt={_s('close')} size="sm" />
        </Link>
      }>
      {order && (
        <div className="bg-brown-50 flex justify-center lg:bg-transparent">
          <div className="lg:py-xxl w-full lg:container">
            <div className="gap-xxl flex justify-center">
              <div className={`gap-lg flex grow-[9999] flex-col lg:max-w-[600px]`}>
                <CardWrapper className="w-full">
                  <EmptyState
                    icon={<LoginSuccess style={{ width: 196, height: 154 }} />}
                    title={_s(`${baseTranslationsKey}.title`)}
                    {...(order.email && {
                      body: _s(`${baseTranslationsKey}.body`, { email: isGuest ? guestEmail : order.email }),
                    })}
                  />
                </CardWrapper>
                <div className="lg:hidden">
                  <CardWrapper className="w-full bg-white">
                    <CheckoutDetails
                      discount={order.discount}
                      deliveryType={order.deliveryType}
                      productType={order.productType === 1 ? 'giftcard' : 'wellnesscard'}
                      selectedAmount={order.price}
                      selectedQuantity={order.quantity}
                      paymentMethod={selectedPaymentMethod ?? { type: order.paymentMethod }}
                    />
                  </CardWrapper>
                </div>
                <CardWrapper className="w-full bg-white">
                  <div className="p-lg gap-lg flex flex-col">
                    <LinkButton to={'/'} block variant="primary" label={_s(`done`)} size="md" />
                  </div>
                </CardWrapper>
              </div>
              <div className="sticky hidden w-full lg:flex lg:max-w-[390px] lg:grow-[100] lg:flex-col">
                <CardWrapper className="w-full bg-white">
                  <CheckoutDetails
                    discount={order.discount}
                    deliveryType={order.deliveryType}
                    productType={order.productType === 1 ? 'giftcard' : 'wellnesscard'}
                    selectedAmount={order.price}
                    selectedQuantity={order.quantity}
                    paymentMethod={selectedPaymentMethod ?? { type: order.paymentMethod }}
                  />
                </CardWrapper>
              </div>
            </div>
          </div>
        </div>
      )}
      <Helmet>
        <title>{`Bekräftelse av ${order?.productType === 1 ? 'presentkorts' : 'friskvårdskorts'}köp`}</title>
        <meta name="robots" content="noindex" />
      </Helmet>
    </PageViewLayout>
  );
};

export default GiftcardConfirmation;
