import { Card, CardHeader } from '@/components/elements/cards';
import { Button } from '@/components/elements/forms/buttons';
import KlarnaPaymentMethodCategories from '@/components/modules/klarna/KlarnaPaymentMethodCategories';
import { getWellnessCardExpiryDateFromToday } from '@/helpers';
import { __ } from '@/locale';
import { Link } from 'react-router-dom';
import GiftCardsTerms from './GiftCardsTerms';
import { SSNFormCard, SelectDigitalShippingMethodCard, SelectValueCard } from './cards';

type GiftCardsBuyWellnessProps = {
  css: object;
  ssn: string;
  setSSN: Function;
  setSelectedServiceType: Function;
  minValue: number;
  value: number;
  valueUpdate: Function;
  quantity: number;
  setQuantity: Function;
  validationCompleted: Function;
  sessionID: any;
  authorize: any;
  paymentMethodCategories: any;
  pricingValues: number[];
};

const GiftCardsBuyWellness = ({
  css,
  ssn,
  setSSN,
  setSelectedServiceType,
  minValue,
  value,
  valueUpdate,
  quantity,
  setQuantity,
  sessionID,
  authorize,
  paymentMethodCategories,
  pricingValues,
}: GiftCardsBuyWellnessProps) => {
  return (
    <>
      <Card className="hidden lg:block">
        <p className="whitespace-pre-wrap">
          {`${__('buyGiftcard.wellness.info.body1')}`}{' '}
          <span className="font-bold">
            {`${__('buyGiftcard.wellness.info.body2', {
              expire: getWellnessCardExpiryDateFromToday(),
            })}`}
          </span>
          <br />
          {`${__('buyGiftcard.wellness.info.body3')}`}{' '}
          <Link to="/giftcards" className="text-information-700">
            {__('here')}
          </Link>
        </p>
      </Card>
      <SelectDigitalShippingMethodCard css={css} isWellness={true} />
      <SelectValueCard {...{ value, valueUpdate, quantity, setQuantity, minValue, pricingValues }} />
      <SSNFormCard ssn={ssn} setSSN={setSSN} setSelectedServiceType={setSelectedServiceType} />
      <Card>
        <CardHeader>{`${__('buyGiftcard.payment')}`}</CardHeader>
        <KlarnaPaymentMethodCategories {...{ paymentMethodCategories }} />
        <div id="klarna-payment"></div>
        {sessionID && (
          <div className="gap-lg pt-md flex flex-col">
            <Button size="lg" block onClick={authorize}>
              {`${__('buy')}`}
            </Button>
            <GiftCardsTerms />
          </div>
        )}
      </Card>
    </>
  );
};

export default GiftCardsBuyWellness;
