import { bookActions } from '@/actions';
import BottomCard from '@/components/elements/Card/Card';
import { Button } from '@/components/elements/forms/buttons';
import Label from '@/components/elements/Label/Label';
import { ListItem } from '@/components/elements/lists';
import {
  capitalizeFirstLetter,
  classnames,
  getEmployeesWhoPerformServices,
  getServiceCampaignIfAny,
  getServiceDuration,
  getServicePrice,
  isSistaminuten,
} from '@/helpers';
import { useAppDispatch, useAppSelector } from '@/hooks';
import useMobileView from '@/hooks/useMobileView';
import { _s } from '@/locale';
import { BookState } from '@/types/state/book';
import { PlaceService } from '@/types/state/shared';
import { Fragment, useEffect, useRef, useState } from 'react';
import Modal from '../../redesign/Modal/Modal';
import { getPickedEmployee } from '../BookAppointment.helpers';
import { useBookAppointmentContext } from '../BookAppointment.hooks';

const baseTranslationKey = 'components.modules.modals.BookAppointment.shared.ServiceItem';

const ServiceItem = ({
  service,
  isRecommended,
  underline = false,
}: {
  service: PlaceService;
  isRecommended: boolean;
  underline?: boolean;
}) => {
  const { isMobileView } = useMobileView();
  const dispatch = useAppDispatch();
  const context = useBookAppointmentContext();
  const { place, services: pickedServices, employee } = useAppSelector((state) => state.book) as BookState;

  const pickedEmployee = getPickedEmployee(employee, place.employees);
  const priceListId = (() => {
    if (pickedEmployee?.about?.priceListId) {
      return pickedEmployee.about.priceListId;
    }

    return getEmployeesWhoPerformServices(
      place.employees,
      place.services.map((s) => s.id),
    )?.[0]?.about?.priceListId;
  })();

  const price = getServicePrice(service, priceListId, place);
  const { campaignService } = getServiceCampaignIfAny(service, place.campaigns, [service.id]);
  const offerPrice = campaignService ? getServicePrice(service, priceListId, place, campaignService) : price;
  const serviceDuration = getServiceDuration(service, priceListId);
  const hasCampaignPrice = price !== offerPrice;
  const serviceDescription = service.about?.description;
  const showWellnesscardBadge = (() => {
    const placeAcceptsGiftcard = Boolean(place?.about?.settings?.acceptsGiftCard);
    const acceptsWellnesscard = placeAcceptsGiftcard && service.about?.settings?.healthCareCard;

    if (!acceptsWellnesscard || isSistaminuten()) return false;

    if (offerPrice === '') return false;

    if (offerPrice === _s('freePrice')) return false;

    if (offerPrice === _s('variablePrice')) return false;

    return true;
  })();

  const hasTags = campaignService || showWellnesscardBadge;
  const ctaLabel = _s(`${baseTranslationKey}.cta.label.${isRecommended ? 'add' : 'book'}`);

  const handleCtaClick = () => {
    if (isRecommended) {
      dispatch(bookActions.appendExtraService(service, pickedServices));
    } else {
      // @ts-ignore
      dispatch(bookActions.appendService(service, place, undefined, pickedServices, employee, false));
      context.updateView(context.view.previous);
    }
  };

  return (
    <ListItem
      type="li"
      rightSlot={<Button label={ctaLabel} onClick={handleCtaClick} />}
      leftPadding={false}
      rightPadding={false}
      verticalAlign={serviceDescription ? 'top' : 'center'}
      underline={underline}>
      <div className="gap-md flex w-full flex-col">
        <div className={classnames('gap-md !flex justify-between', hasTags ? 'items-start' : 'items-start')}>
          <div className="space-y-xxs flex max-w-sm flex-col">
            {!isSistaminuten() && (
              <div className="space-x-sm mb-xs">
                {campaignService && <Label variant="campaign" />}
                {showWellnesscardBadge && (
                  <Label variant="wellness" label={_s('wellness')} icon={{ variant: 'wellness' }} />
                )}
              </div>
            )}
            <span>{capitalizeFirstLetter(isMobileView ? service.name.split('.').join('. ') : service.name)}</span>
            <div className="gap-xs flex flex-col">
              <span className="text-black-900 text-m">
                {serviceDuration && <span>{serviceDuration}</span>}
                {price && serviceDuration && ', '}
                {hasCampaignPrice && (
                  <span className="text-danger-500">
                    {offerPrice} <span className="text-black-600 line-through">({price})</span>
                  </span>
                )}
                {!hasCampaignPrice && price}
              </span>
            </div>
            {serviceDescription && <ServiceDescription service={service} />}
          </div>
        </div>
      </div>
    </ListItem>
  );
};

const ServiceDescription = ({ service }: { service: PlaceService }) => {
  const { place, employee, services: pickedServices } = useAppSelector((state) => state.book) as BookState;
  const dispatch = useAppDispatch();
  const props = useBookAppointmentContext();
  const [isTruncated, setIsTruncated] = useState(false);
  const { isMobileView } = useMobileView();
  const [showFullDescription, setShowFullDescription] = useState(false);
  const textRef = useRef(null);

  // show a fadeout effect on the last line of the text
  const background = 'linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #ffffff 50%, #ffffff 100%)';
  const pickedEmployee = getPickedEmployee(employee, place.employees);
  const priceListId = (() => {
    if (pickedEmployee?.about?.priceListId) {
      return pickedEmployee.about.priceListId;
    }

    return getEmployeesWhoPerformServices(
      place.employees,
      place.services.map((s) => s.id),
    )?.[0]?.about?.priceListId;
  })();
  const serviceDuration = getServiceDuration(service, priceListId);
  const price = getServicePrice(service, priceListId, place);
  const { campaignService } = getServiceCampaignIfAny(service, place.campaigns, [service.id]);
  const offerPrice = campaignService ? getServicePrice(service, priceListId, place, campaignService) : price;
  const hasCampaignPrice = price !== offerPrice;

  useEffect(() => {
    // Check if the text is spanning more than 3 columns, if so show the readmore button
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          if (!textRef.current) return;
          const isOverflowing = textRef.current.scrollHeight > textRef.current.clientHeight;
          setIsTruncated(isOverflowing);
        }
      });
    });

    observer.observe(textRef.current);
  }, []);

  const handlePickServiceClick = () => {
    // @ts-ignore
    dispatch(bookActions.appendService(service, place, undefined, pickedServices, employee));
    setShowFullDescription(false);
    if (props.view.current === 'summary') return;
    props.updateView(props.view.previous);
  };

  return (
    <Fragment>
      <Modal isOpen={showFullDescription} className="md:max-w-[500px]">
        <Modal.Content floating={!isMobileView} contentMaxWidth={500}>
          <Modal.Header title={service.name} onClose={() => setShowFullDescription(false)} />
          <div className="px-lg">
            <div className="space-y-xxs flex max-w-sm flex-col">
              <span className="">
                {capitalizeFirstLetter(isMobileView ? service.name.split('.').join('. ') : service.name)}
              </span>
              <div className="gap-xs flex flex-col">
                <span className="text-black-900 text-m">
                  {serviceDuration && <span>{serviceDuration}</span>}
                  {price && serviceDuration && ', '}
                  {hasCampaignPrice && (
                    <span className="text-danger-500">
                      {offerPrice} <span className="text-black-600 line-through">({price})</span>
                    </span>
                  )}
                  {!hasCampaignPrice && price}
                </span>
              </div>
              <div className="pt-md text-black-600">{service.about.description}</div>
            </div>
            {!isMobileView && (
              <Modal.Footer atBottom>
                <Button onClick={handlePickServiceClick} variant="primary" size="md">
                  {_s(`${baseTranslationKey}.description.cta.label`)}
                </Button>
              </Modal.Footer>
            )}
          </div>
          {isMobileView && (
            <Fragment>
              {/* spacer to avoid elements going under fixed bottom card */}
              <div className="h-[128px]" />
              {/* spacer to avoid elements going under fixed bottom card */}
              <div className="fixed bottom-0 left-0 right-0 mt-auto">
                <BottomCard className="pb-lg h-auto w-full bg-white">
                  <Button block onClick={handlePickServiceClick} variant="primary" size="md">
                    {_s(`${baseTranslationKey}.description.cta.label`)}
                  </Button>
                </BottomCard>
              </div>
            </Fragment>
          )}
        </Modal.Content>
      </Modal>
      <div className="relative">
        <div
          ref={textRef}
          className="pt-xs text-black-600 text-m line-clamp-[3] max-w-[420px] overflow-hidden text-ellipsis"
          style={{ display: '-webkit-box', WebkitLineClamp: '3', WebkitBoxOrient: 'vertical' }}>
          {service.about.description}
        </div>
        {isTruncated && (
          <Fragment>
            <div className="absolute bottom-0 right-0" style={{ width: 100, height: 20, background }} />
            <button
              className="px-xs py-xxs absolute -bottom-[4px] right-0 outline-none"
              onClick={() => setShowFullDescription(true)}>
              <span className="text-information-700 text-m underline">
                {_s(`${baseTranslationKey}.description.read-more`)}
              </span>
            </button>
          </Fragment>
        )}
      </div>
    </Fragment>
  );
};

export default ServiceItem;
