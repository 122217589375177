import * as moment from 'moment';
import 'moment-timezone';
import { useEffect, useState } from 'react';

export const useCountDown = (targetDateTimestamp: number) => {
  const countDownDate = new Date(targetDateTimestamp).getTime();
  const [countDown, setCountDown] = useState(countDownDate - moment().tz('Europe/Stockholm').valueOf());

  useEffect(() => {
    const interval = setInterval(() => {
      if (countDownDate - moment().tz('Europe/Stockholm').valueOf() <= 1000) {
        clearInterval(interval);
      }
      const duration = countDownDate - moment().tz('Europe/Stockholm').valueOf();
      setCountDown(duration);
    }, 1000);

    return () => clearInterval(interval);
  }, [countDownDate]);

  return getReturnValues(countDown);
};

const getReturnValues = (duration: number) => {
  // check duration greater than 1
  if (duration < 1) {
    return [
      { key: 'days', short: 'd', value: 0 },
      { key: 'hours', short: 'h', value: 0 },
      { key: 'minutes', short: 'm', value: 0 },
      { key: 'seconds', short: 's', value: 0 },
    ];
  }

  // calculate time left
  const days = Math.floor(duration / (1000 * 60 * 60 * 24));
  const hours = Math.floor((duration % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((duration % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((duration % (1000 * 60)) / 1000);

  return [
    { key: 'days', short: 'd', value: days },
    { key: 'hours', short: 'h', value: hours },
    { key: 'minutes', short: 'm', value: minutes },
    { key: 'seconds', short: 's', value: seconds },
  ];
};
