import { blogConstants } from '../constants';
export function blog(state = {}, action) {
  switch (action.type) {
    case blogConstants.list:
    case blogConstants.category:
    case blogConstants.author:
    case blogConstants.featured:
    case blogConstants.latest:
      return { ...state, ...action.data };
    default:
      return state;
  }
}
