/* eslint jsx-a11y/anchor-is-valid: 0 */
import { listActions } from '@/actions';
import { DropdownButton } from '@/components/elements/forms/DropdownButton';
import { ListIcon, MapIcon } from '@/components/icons';
import { trackMpEvent } from '@/helpers';
import { isSistaminuten } from '@/helpers/general';
import withMobileView from '@/hoc/withMobileView';
import { __ } from '@/locale';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FilterBy } from './FilterBy';
import { Sorting } from './Sorting';

class Filters extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleClick = (name) => (e) => {};

  showMap = () => {
    const { dispatch } = this.props;
    trackMpEvent('view_results_on_map_clicked', {
      screen_name: 'search_results',
    });
    dispatch(listActions.mobileMap());
  };

  showList = () => {
    const { dispatch } = this.props;
    trackMpEvent('view_results_on_list_clicked', {
      screen_name: 'search_results_map',
    });
    dispatch(listActions.mobileList());
  };

  render() {
    const isMap = Boolean(this.props.listType === 'map');

    return (
      !isSistaminuten() && (
        <div id="filters" className="!relative top-auto z-10 border-none px-0 shadow-none">
          <div className={this.props.overlay ? 'fixed top-[150px] z-[1] h-screen w-full' : ''}></div>
          <ul
            className={`${
              this.props.isMobileView ? 'h-[46px] gap-1 overflow-x-auto overflow-y-hidden pb-2' : 'gap-2 py-4'
            } flex`}>
            <Sorting />
            <FilterBy
              trackPage={isMap ? 'search_results_map' : 'search_results'}
              trackName="filters"
              name="prefs"
              title={__('filters')}
              optionsTitle={__('filterBy')}
            />
            {this.props.isMobileView && (
              <li>
                <DropdownButton
                  toggleOpen={isMap ? this.showList : this.showMap}
                  icon={
                    isMap ? <ListIcon className="text-primary h-3 w-3" /> : <MapIcon className="h-3 w-3 text-black" />
                  }
                  newDesign
                  className="pl-2">
                  <span className="text-m whitespace-nowrap">{__(isMap ? 'listView' : 'mapView')}</span>
                </DropdownButton>
              </li>
            )}
          </ul>
        </div>
      )
    );
  }
}

function mapStateToProps(state) {
  const { search, listType } = state;
  const { overlay } = state.modal;

  return {
    search,
    overlay,
    listType,
  };
}

const connectedFilters = withMobileView(withRouter(connect(mapStateToProps)(Filters)));
export default connectedFilters;
