import { history, isServer } from '@/helpers';
import { BrowserRouter as Router, StaticRouter } from 'react-router-dom';

const AppRouter = ({ children, context = null, location = null }) =>
  isServer ? (
    <StaticRouter {...{ history, context, location: location || '/' }}>{children}</StaticRouter>
  ) : (
    <Router basename="/">{children}</Router>
  );

export default AppRouter;
