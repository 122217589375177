import { WarningIcon } from '@/components/icons';
import { __ } from '@/locale';
import { withRouter } from 'react-router-dom';

function ReviewUserCard(props) {
  const { name, email, isAdmin } = props.user;

  const { searchEmail } = props;

  const getStatusLabel = (isAdmin) => {
    switch (isAdmin) {
      case 1:
        return 'Admin';
      case 2:
        return 'Manager';
      default:
        return 'None';
    }
  };

  return (
    <div className="bg-black-50 block w-full rounded-lg p-6">
      <div className="flex">
        {!!email ? (
          <div className="flex flex-col">
            <span className="font-semibold">{name || __('Anonymous')}</span>
            <span>email: {email}</span>
            <span>role: {getStatusLabel(isAdmin)}</span>
          </div>
        ) : (
          <div className="flex flex-row">
            <WarningIcon className="relative right-3 z-10 h-5 w-5" />
            <span>
              User: '<b>{searchEmail}</b>' not found !!!
            </span>
          </div>
        )}
      </div>
    </div>
  );
}
const ReviewUserCardWithRouter = withRouter(ReviewUserCard);
export default ReviewUserCardWithRouter;
