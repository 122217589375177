import { modalActions /*userActions*/ } from '../actions';
import { isServer } from '../helpers';

export const hash = {
  check,
};

function check(dispatch) {
  if (isServer || !window.location.hash) {
    return;
  }

  if (checkRedirect(window.location.hash, dispatch)) {
    return;
  }

  switch (window.location.hash) {
    // case '#ZmV0Y2h1c2Vy':
    //   dispatch(userActions.profile(null, null, true));
    //   trackMpEvent('user_account_changes_saved', {
    //     'screen_name': url.getScreenNameFromUrl(window.location.href)
    //   });
    //   window.location.hash = '';
    //   break;
    default:
      break;
  }
}

function checkRedirect(str, dispatch) {
  const parts = str.split('-');
  if (parts[0] === '#details') {
    parts.shift();
    dispatch(modalActions.redirect('details', '/places/' + parts.join('-')));
    return true;
  }
}
