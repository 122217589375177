import { Card, CardHeader } from '@/components/elements/cards';
import { DiscountIcon } from '@/components/icons';
import { GiftCard } from '@/components/modules/pages/booking-checkout/EmployeeForm';
import { __ } from '@/locale';
import { giftCardServices } from '@/services/giftCardServices';
import { useState } from 'react';

const handleInputChange = () => {};

const GiftCardDiscount = ({ setDiscount, discount, setDiscountCode, value, quantity, code }) => {
  const [error, setError] = useState(null);
  const [localCode, setCode] = useState('');
  const handleApplyDiscount = async (discountCode) => {
    const response = await giftCardServices.checkDiscountCode(discountCode, value * quantity);
    // Giftcard applyed
    if (response.discount) {
      setDiscount(response.discount / 100 || 0);
      setDiscountCode(discountCode);
      setError(null);
      setCode(discountCode);
    } else {
      removeDiscount();
      setError(__('ogiltig kod'));
    }
  };

  const removeDiscount = () => {
    // Set error
    setDiscount(0);
    setDiscountCode('');
    setCode('');
  };

  return (
    <Card>
      <CardHeader>{__('buyGiftcard.campaigns')}</CardHeader>
      <div className="flex gap-3">
        {localCode && (
          <div className="flex w-full max-w-sm justify-between">
            <div className="flex">
              <DiscountIcon className="mr-3" />
              <div className="flex-none">
                {__('buyGiftcard.campaignCode')} {'"' + code + '"'} <br />
                <button onClick={removeDiscount} className="unstyled-button text-danger text-sm outline-none">
                  {__('buyGiftcard.cancel')}
                </button>
              </div>
            </div>
            <div className="">{'-' + discount}</div>
          </div>
        )}
        {!localCode && (
          <GiftCard
            data={{
              visible: true,
              label: __('buyGiftcard.campaignCode.placeholder'),
            }}
            manualErrorGiftcard={error}
            getError={() => {}}
            applyGiftCard={handleApplyDiscount}
            changeInput={handleInputChange}
          />
        )}
      </div>
    </Card>
  );
};

export default GiftCardDiscount;
