import LoadingPlaceHolder from '@/components/elements/LoadingPlaceholder';
import usePlaceDetailsFallback from '@/hooks/usePlaceDetailsFallback';

function withPlaceDetailsFallback<P>(WrappedComponent: React.ComponentType<P>) {
  const WithPlaceDetails = (props: P) => {
    const { status } = usePlaceDetailsFallback();

    if (status === 'loading') {
      return <LoadingPlaceHolder />;
    }

    return <WrappedComponent {...props} />;
  };
  return WithPlaceDetails;
}

export default withPlaceDetailsFallback;
