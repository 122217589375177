import { BookState } from '@/types/state/book';
import { bookConstants } from '../constants';
import { isServer } from '../helpers';

export type BookStateFromStorage = {
  services: BookState['services'];
  employee: BookState['employee'];
  usageReqId: BookState['usageReqId'];
  time: BookState['time'];
  campaigns: BookState['campaigns'];
  appliedBundle: BookState['appliedBundle'];
  changeTimeBookingId: BookState['changeTimeBookingId'];
};

function getBookStateFromSessionStorage(): BookStateFromStorage {
  const employee = !isServer && JSON.parse(sessionStorage.getItem('bookEmployee'));
  const services = !isServer && JSON.parse(sessionStorage.getItem('service'));
  const bookDate = !isServer && JSON.parse(sessionStorage.getItem('bookDate'));
  const usageReqId = !isServer && JSON.parse(sessionStorage.getItem('bookUUID'));
  const campaigns = !isServer && JSON.parse(sessionStorage.getItem('bookCampaigns'));
  const appliedBundle = !isServer && JSON.parse(sessionStorage.getItem('bookBundle'));
  const changeTimeBookingId = !isServer && JSON.parse(sessionStorage.getItem('bookChangeTime'));

  const time = bookDate ?? { timestamp: null, selected: null };

  return {
    employee,
    campaigns: campaigns ? Object.keys(campaigns).map((id) => campaigns[id]) : [],
    services,
    usageReqId,
    time,
    appliedBundle,
    changeTimeBookingId,
  };
}

const {
  campaigns = [],
  services = [],
  usageReqId = null,
  employee,
  time,
  changeTimeBookingId,
} = getBookStateFromSessionStorage();

const initialState: BookState = {
  services,
  employee,
  time,
  usageReqId,
  campaigns,
  availability: [],
  place: undefined,
  saved: false,
  saving: false,
  failure: undefined,
  errorGiftCard: undefined,
  validateGiftCard: false,
  successGiftCard: undefined,
  appliedBundle: undefined,
  changeTimeBookingId: changeTimeBookingId ?? undefined,
};

const mergeServices = (initialServices = [], services = []) => {
  const initialServicesMap = {},
    initialServicesIds = [];
  initialServices.forEach((service) => {
    initialServicesMap[service.id] = service;
    initialServicesIds.push(service.id);
  });

  let shouldMerge = true;
  for (let i = 0; i < services.length; i++) {
    const service = services[i];
    if (initialServicesIds.indexOf(service.id) === -1) {
      shouldMerge = false;
      return;
    }

    initialServicesMap[service.id] = service;
  }

  if (shouldMerge) {
    return Object.keys(initialServicesMap).map((id) => initialServicesMap[id]);
  } else {
    return services;
  }
};

const prepareErpSlots = (fromErp = []) => {
  const groupedDates = fromErp.reduce((r, a) => {
    r[a.start] = [...(r[a.start] || []), a];
    return r;
  }, {});

  const randomSlotsAtSameHour = {};
  Object.keys(groupedDates).forEach((date) => {
    const slotsAtSameHourCount = groupedDates[date].length;
    randomSlotsAtSameHour[date] = groupedDates[date][Math.floor(Math.random() * slotsAtSameHourCount)];
  });

  return randomSlotsAtSameHour;
};

export function book(state = initialState, action) {
  if (!isServer && state.fromSsr) {
    if (state.services && state.services.length) {
      const serviceState = mergeServices([...(initialState.services || [])], [...state.services]);
      state.services = serviceState;
    }

    state = { ...initialState, ...state };
    delete state.fromSsr;
  }

  switch (action.type) {
    case bookConstants.ADD_SERVICE: {
      delete state.saved;

      if (!state.services) {
        state.services = [];
      }

      const servicesMap = [...state.services, action.service].reduce((map, item) => {
        map[item.id] = item;
        return map;
      }, {});

      return {
        ...state,
        availability: {},
        services: Object.keys(servicesMap).map((id) => servicesMap[id]),
        failure: undefined,
        set: false,
      };
    }
    case bookConstants.BOOK_AGAIN:
      return {
        availability: {},
        place: action.place,
        services: action.services,
        employee: action.employee,
        priceId: action.priceId,
        isNotAvailable: false,
        failure: undefined,
        errorGiftCard: undefined,
        validateGiftCard: false,
        successGiftCard: undefined,
        set: false,
      };

    case bookConstants.CHANGE_BOOKING_TIME:
      return {
        ...state,
        changeTimeBookingId: action.bookingId,
      };

    case bookConstants.APPEND_SERVICE: {
      delete state.saved;

      if (!state.services) {
        state.services = [];
      }

      const servicesMap = Object.values(
        [...state.services, ...action.services].reduce((map, item) => {
          map[item.id] = item;
          return map;
        }, {}),
      );

      return {
        ...state,
        services: sortServices(servicesMap),
      };
    }
    case bookConstants.POP_SERVICE: {
      let serviceList = [];

      for (let i = 0; i < state.services.length; i++) {
        if (state.services[i].id !== action.service) {
          serviceList.push(state.services[i]);
        }
      }
      sessionStorage.setItem('service', JSON.stringify(serviceList));

      return {
        ...state,
        services: sortServices(serviceList),
      };
    }
    case bookConstants.SERVICE_PROGRAM:
      /*
        We don't wont to override old times when we fetch a new overview since
        this would mean that the current week would disapear
      */
      const availability = action.params.program.availability;
      if (state.availability && state.availability.fromErp) {
        let serviceIds = action.params.services.map((s) => s.id);
        availability.fromErp = [
          ...state.availability.fromErp.filter((fe) => {
            let employeeOk = false;
            if (action.params.employee) {
              employeeOk = fe.employeeId === action.params.employee;
            } else {
              employeeOk = true;
            }

            let idx = 0;
            let servicesOk = true;
            for (const id of serviceIds) {
              if ('' + fe.serviceIds[idx] !== '' + id) {
                servicesOk = false;
              }
              idx++;
            }
            return employeeOk && servicesOk;
          }),
          ...(availability.fromErp || []),
        ].sort((a, b) => (a.start > b.start ? 1 : -1));
      }

      if (state.availability && state.availability.fromErpOverview && availability.fromErpOverview) {
        availability.fromErpOverview = {
          ...availability.fromErpOverview,
          minDateChecked: state.availability.fromErpOverview.minDateChecked,
        };
      }

      if (availability?.fromErp) {
        availability.calendarTimeSlots = prepareErpSlots(availability.fromErp);
      }

      return {
        ...state,
        services: sortServices(action.params.services),
        availability: availability,
        time: action.params.changeTime ? action.params.time : state.time,
        set: true,
        failure: undefined,
      };

    case bookConstants.RESTORE_BOOK_STATE:
      if (!isServer) {
        if (action.bookState.employee) {
          sessionStorage.setItem('bookEmployee', JSON.stringify(action.bookState.employee));
        }
        sessionStorage.setItem('service', JSON.stringify(action.bookState.services));
        sessionStorage.setItem('bookDate', JSON.stringify(action.bookState.time));
        sessionStorage.setItem('bookCampaigns', JSON.stringify(action.bookState.campaigns));
      }

      return {
        ...state,
        ...(action.bookState.employee ? { employee: action.bookState.employee } : {}),
        services: action.bookState.services,
        time: action.bookState.time,
        campaigns: action.bookState.campaigns,
      };

    case bookConstants.ADD_PLACE:
      return {
        ...state,
        place: action.place,
        set: false,
        failure: undefined,
      };
    case bookConstants.REFETCH_PLACE:
      return {
        ...state,
        fetchError: action.error,
      };

    case bookConstants.SET_CAMPAIGN:
      return {
        ...state,
        campaigns: action.campaigns,
      };
    case bookConstants.ADD_UUID:
      return {
        ...state,
        usageReqId: action.uuidSession,
      };
    case bookConstants.SET_GIFTCARD:
      const newState = { ...state };
      if (action.payload.added) {
        delete newState.errorGiftCard;
        newState.successGiftCard = true;
      }
      return {
        ...newState,
        usageReqId: action.payload.uuidSession,
        validateGiftCard: false,
      };
    case bookConstants.ERROR_GIFTCARD:
      delete state.successGiftCard;
      return {
        ...state,
        errorGiftCard: action.error,
        validateGiftCard: false,
      };
    case bookConstants.CLEAR_GIFTCARD_MSG:
      delete state.errorGiftCard;
      delete state.successGiftCard;
      return {
        ...state,
      };
    case bookConstants.VALIDATE_GIFTCARD:
      return {
        ...state,
        validateGiftCard: true,
      };
    case bookConstants.APPLY_BUNDLE:
      return {
        ...state,
        appliedBundle: action.payload,
      };
    case bookConstants.REMOVE_BUNDLE:
      return {
        ...state,
        appliedBundle: undefined,
      };
    case bookConstants.PICK_EMPLOYEE:
      return {
        ...state,
        employee: action.employee,
        priceId: action.priceId,
        isNotAvailable: false,
        set: false,
        failure: undefined,
      };
    case bookConstants.ADD_EMPLOYEE:
      return {
        ...state,
        employee: action.employee,
      };
    case bookConstants.KEEP_EMPLOYEE:
      return {
        ...state,
        keepEmployee: true,
        failure: undefined,
      };

    case bookConstants.FIRST_DAY:
      if (action.data.firstDay) {
        state.availability.firstDay = action.data.firstDay;
      }

      if (action.data.usedEmployees) {
        state.availability.usedEmployees = action.data.usedEmployees;
      }

      return { ...state };

    case bookConstants.REMOVE_EMPLOYEE:
      delete state.employee;
      delete state.priceId;

      return { ...state, set: false, isNotAvailable: false };

    case bookConstants.PICK_DATE:
      let weeksAvailability = { ...state.availability };
      const hadTimeData = action.time && action.time.timestamp;
      const hadWeeksData = action.weeks && action.weeks[action.time.timestamp];
      const hadErpData = action.fromErp && action.fromErp.length > 0;
      if (hadTimeData && (hadWeeksData || hadErpData)) {
        if (hadWeeksData) {
          weeksAvailability = action.weeks[action.time.timestamp];
        }
        if (hadErpData) {
          weeksAvailability.fromErp = action.fromErp;
          weeksAvailability.calendarTimeSlots = prepareErpSlots(action.fromErp);
        }
        if (state.availability.firstDay) {
          weeksAvailability.firstDay = state.availability.firstDay;
        }
        if (state.availability.usedEmployees) {
          weeksAvailability.usedEmployees = state.availability.usedEmployees;
        }
        weeksAvailability.weekDays = action.weeks;
      }
      if (state.availability && state.availability.fromErpOverview) {
        if (action.fromErpOverview) {
          weeksAvailability.fromErpOverview = { ...state.availability.fromErpOverview, ...action.fromErpOverview };
        } else {
          weeksAvailability.fromErpOverview = { ...state.availability.fromErpOverview };
        }
      }

      return {
        ...state,
        time: action.time,
        availability: weeksAvailability,
        set: false,
        failure: undefined,
      };

    case bookConstants.REMOVE_HOUR:
      state.time.selected = null;
      delete state.time.dynamicPriceListIdKey;
      delete state.time.employees;
      sessionStorage.setItem('bookDate', JSON.stringify(state.time));
      return {
        ...state,
        set: true,
        failure: undefined,
      };
    case bookConstants.SAVING:
      return {
        ...state,
        saving: true,
        set: false,
        failure: undefined,
        validateField: undefined,
      };
    case bookConstants.SAVED:
      return {
        saved: true,
        saving: false,
        responseData: action.response.message,
        set: false,
        failure: undefined,
        validateField: undefined,
      };
    case bookConstants.FAILURE:
      return {
        ...state,
        saving: false,
        failure: action.response,
      };
    case bookConstants.VALIDATE_FIELD:
      return {
        ...state,
        saving: false,
        validateField: action.response,
      };
    case bookConstants.REMOVE_SERVICE:
      return {};
    case bookConstants.CLEAR_BOOK:
      if (state.keepEmployee && state.employee && state.priceId) {
        return {
          employee: state.employee,
          priceId: state.priceId,
          set: false,
        };
      }
      return {};
    case bookConstants.SET_TIME:
      return {
        ...state,
        time: action.params.time,
      };
    case bookConstants.SELECT_CAPACITY:
      return {
        ...state,
        time: {
          ...state.time,
          selectedCapacity: action.payload,
        },
      };
    default:
      return { ...state };
  }
}

const sortServices = (services) => {
  const result = [];
  const addOnServices = services
    .filter((s) => s.about && s.about.settings && s.about.settings.isAddOn)
    .map((s) => s.id);

  services
    .filter((s) => !(s.about && s.about.settings && s.about.settings.isAddOn))
    .forEach((service) => {
      result.push(service);
      if (service.extra && service.extra.addOnServices) {
        service.extra.addOnServices.forEach((addOnService) => {
          if (addOnServices.indexOf(addOnService.id) !== -1) {
            result.push(addOnService);
          }
        });
      }
    });

  return result;
};
