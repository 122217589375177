import { ReactNode, useEffect, useState } from 'react';

type ReadMorePreviewProps = {
  text: string;
  htmlId?: string;
  lineHeight?: number;
  maxLines?: number;
  lines?: number;
  button: ReactNode;
  expanded?: Boolean;
};

const ReadMorePreview = ({
  text = '',
  htmlId = 'read-more-review',
  lineHeight = 20,
  maxLines = 2,
  lines = 2,
  button,
  expanded = false
}: ReadMorePreviewProps) => {
  const [height, setHeight] = useState(0);
  const computedHeight = expanded ? height : lineHeight * lines;
  const maxHeight = lineHeight * maxLines;
  const cropHeight = height <= maxHeight ? maxHeight : computedHeight;
  const className = `text-sm text-black-600 overflow-hidden text-ellipsis ${
    height <= maxHeight || expanded ? ' whitespace-pre-wrap' : ''
  }`;

  useEffect(() => {
    const container = document.getElementById(htmlId || 'read-more-preview');
    if (container) {
      setHeight(container.scrollHeight);
    }
  }, [htmlId]);

  return text ? (
    <div className="relative" style={{ lineHeight: lineHeight + 'px' }}>
      <div
        id={htmlId}
        className={className}
        dangerouslySetInnerHTML={{ __html: text.trim() }}
        style={{ maxHeight: cropHeight }}></div>
      {height > cropHeight && button}
    </div>
  ) : null;
};

export default ReadMorePreview;
