import { bookActions } from '@/actions';
import PageViewLayout from '@/components/layouts/PageViewLayout/PageViewLayout';
import CardWrapper from '@/components/modules/CardWrapper';
import SEO, { seoPropsFromBaseString } from '@/components/modules/SEO';
import CheckoutDetails, { CheckoutDetailsProps } from '@/components/modules/checkout/CheckoutDetails/CheckoutDetails';
import QliroCheckout from '@/components/modules/checkout/QliroCheckout/QliroCheckout';
import GoBack from '@/components/modules/pages/bokningar/GoBack';
import { CHECKOUT_PAYMENT_METHOD } from '@/constants/checkout';
import { isSistaminuten, url } from '@/helpers';
import {
  getBookingCheckoutEmployee,
  getBookingCheckoutSummary,
  mapBookStateToCheckoutDetailsServices,
  mapBookingCheckoutToCheckoutDetailsProps,
} from '@/helpers/checkout';
import withPlaceDetailsFallback from '@/hoc/withPlaceDetailsFallback';
import { useAppSelector } from '@/hooks';
import useMobileView from '@/hooks/useMobileView';
import useTrackScreenView from '@/hooks/useTrackScreenView';
import { _s } from '@/locale';
import { InitQliroResponse, initQliroResponseSchema } from '@/types/api/services/booking';
import { BookState, bookStateSchema } from '@/types/state/book';
import { useHistory, useLocation } from 'react-router-dom';

const baseTranslationKey = 'pages.booking.checkout.QliroBookingCheckout';

const QliroBookingCheckout = ({ booking, qliroOrder }: { booking: BookState; qliroOrder: InitQliroResponse }) => {
  const location = useLocation();
  const history = useHistory();
  const employee = getBookingCheckoutEmployee(location.state, booking);
  const selectedCapacity = booking.time?.selectedCapacity || 1;
  const summary = getBookingCheckoutSummary({ booking, employee, selectedCapacity });

  const { isMobileView } = useMobileView();
  const bookingTrackingProps = bookActions.getTrackingProps();

  const services = mapBookStateToCheckoutDetailsServices(booking, employee.shown);
  const { giftcards, payLater, ...rest } = mapBookingCheckoutToCheckoutDetailsProps(
    summary,
    { type: CHECKOUT_PAYMENT_METHOD.QLIRO },
    employee.shown,
    booking,
  );

  const checkoutDetailsProps: CheckoutDetailsProps = {
    ...rest,
    payLater,
    services,
    giftcards: giftcards.map((giftcard) => ({
      ...giftcard,
    })),
  };

  const handleOnBackButtonClick = () => {
    history.replace({
      pathname: '/booking/checkout',
      state: { savedCheckoutState: location.state?.savedCheckoutState, employee: employee.actual },
    });
  };

  useTrackScreenView({
    name: 'screen_view_checkout_booking_qliro_summary',
    properties: {
      ...bookingTrackingProps.bookingProps,
      ...bookingTrackingProps.paymentMethodsProps,
      ...bookingTrackingProps.extraProps,
    },
  });

  return (
    <PageViewLayout
      type="subView"
      title={_s(`${baseTranslationKey}.page-title`)}
      back
      onBackButtonClick={handleOnBackButtonClick}
      wrapperClass={isMobileView ? 'bg-brown-50' : 'bg-gradient'}>
      <div className="bg-brown-50 lg:bg-transparent">
        <div className="lg:py-xxl lg:container">
          <div className="gap-xxl flex items-start">
            <div className="gap-lg flex grow-[9999] basis-[600px] flex-col">
              <CardWrapper className="w-full lg:hidden">
                <CheckoutDetails {...checkoutDetailsProps} />
              </CardWrapper>
              <CardWrapper>
                <div className="p-lg">
                  <QliroCheckout snippet={qliroOrder.snippet} />
                </div>
              </CardWrapper>
            </div>
            <div className="sticky top-20 hidden lg:flex lg:grow-[100] lg:basis-[390px]">
              <CardWrapper className="w-full">
                <CheckoutDetails key={2} {...checkoutDetailsProps} />
              </CardWrapper>
            </div>
          </div>
        </div>
      </div>

      <SEO
        {...seoPropsFromBaseString('qliro', `${url.getBaseUrl()}booking/checkout/qliro`)}
        noindex={isSistaminuten()}
        image={url.getBaseImageUrl()}
      />
    </PageViewLayout>
  );
};

export default withPlaceDetailsFallback(() => {
  const location = useLocation();
  const bookState = useAppSelector((state) => state.book);
  const parsedBookState = bookStateSchema.safeParse(bookState);
  const parsedQliroOrder = initQliroResponseSchema.safeParse(location?.state?.qliroOrder);

  if (!parsedBookState.success) return <GoBack />;

  const { saved, services, time, place } = parsedBookState.data;
  const invalidState =
    !parsedQliroOrder.success || saved || !services || !services.length || !place || !time || !time.timestamp;

  if (invalidState) return <GoBack />;

  return <QliroBookingCheckout booking={parsedBookState.data} qliroOrder={parsedQliroOrder.data} />;
});
