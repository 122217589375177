import { searchActions } from '@/actions';
import LoadingDotsBlack from '@/components/animations/LoadingDotsBlack/LoadingDotsBlack';
import PlaceItemCard from '@/components/elements/Card/PlaceItemCard/PlaceItemCard';
import SearchInput from '@/components/elements/forms/SearchInput/SearchInput';
import { Fab } from '@/components/elements/forms/buttons';
import Icon from '@/components/icons/Icon';
import { Map } from '@/components/modules/map';
import SearchModal from '@/components/modules/modals/SearchModal';
import NavBar from '@/components/modules/navigation/navbar/NavBar/NavBar';
import EmptyState from '@/components/templates/EmptyState';
import { Z_INDEX } from '@/constants/styleConstants';
import {
  getPlaceImage,
  hasFeatureSetting,
  isPlaceInBounds,
  isPlaceOpenToday,
  isServer,
  isSistaminuten,
  salonTracking,
  trackPage,
} from '@/helpers';
import { useAppSelector } from '@/hooks';
import useMobileView from '@/hooks/useMobileView';
import { _s } from '@/locale';
import { getPortalPlaces } from '@/services/portalPlaceServices';
import { PortalPlace } from '@/types/portal';
import { Dispatch, ReactNode, createContext, useContext, useEffect, useReducer, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';

type SearchState = {
  places: PortalPlace[];
  filteredPlaces: PortalPlace[];
  loadingPlaces: boolean;
  initialLoad: boolean;
  suggestions: string[];
  searchValue: string;
};

type SearchAction =
  | { type: 'SET_PLACES'; payload: PortalPlace[] }
  | { type: 'SET_FILTERED_PLACES'; payload: PortalPlace[] }
  | { type: 'SET_LOADING_PLACES'; payload: boolean }
  | { type: 'SET_SEARCH_VALUE'; payload: string };

function getFilteredPlacesFromSearch(places: PortalPlace[], searchValue: string, bounds?: string) {
  if (!Array.isArray(places)) {
    return [];
  }

  return places.filter((place) => {
    const positionValid = isPlaceInBounds(place?.contact?.position, bounds);
    const nameMatches = place?.about?.name.toLowerCase().includes(searchValue.toLowerCase());
    const cityMatches = place?.contact?.address?.city.toLowerCase().includes(searchValue.toLowerCase());
    return positionValid && (nameMatches || cityMatches);
  });
}

async function fetchAndSetPlaces({ id, group }: { id: number; group?: string }, dispatch: Dispatch<SearchAction>) {
  const { places } = await getPortalPlaces({ id, group });
  dispatch({ type: 'SET_PLACES', payload: places });
  (places || []).forEach((place) => {
    salonTracking(place);
  });

  return places;
}

const searchReducer = (state: SearchState, action: SearchAction): SearchState => {
  switch (action.type) {
    case 'SET_PLACES':
      const suggestions = (action.payload || [])
        .filter((place) => place?.contact?.address?.city)
        .map((place) => place.contact.address.city)
        .map((city) =>
          city
            .split(' ')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
            .join(' '),
        )
        .filter((city) => city !== '')
        .filter((city, index, self) => self.indexOf(city) === index);

      return { ...state, places: action.payload, suggestions, loadingPlaces: false, initialLoad: false };
    case 'SET_FILTERED_PLACES':
      return { ...state, filteredPlaces: action.payload, loadingPlaces: false };
    case 'SET_LOADING_PLACES':
      return { ...state, loadingPlaces: action.payload };
    case 'SET_SEARCH_VALUE':
      return { ...state, searchValue: action.payload };
    default:
      return state;
  }
};

const usePortalPageManager = (): {
  search: SearchState;
  handleSearchChange: (value: string) => void;
  setLoadingPlaces: (loading: boolean) => void;
} => {
  const [search, searchDispatch] = useReducer(searchReducer, {
    places: [],
    filteredPlaces: [],
    suggestions: [],
    loadingPlaces: false,
    initialLoad: true,
    searchValue: '',
  });

  const { bounds } = useAppSelector((state) => state.search);
  const { params } = useRouteMatch();
  const { id, group } = params;

  useEffect(() => {
    fetchAndSetPlaces({ id, group }, searchDispatch);
  }, [id, group]);

  useEffect(() => {
    searchDispatch({
      type: 'SET_FILTERED_PLACES',
      payload: getFilteredPlacesFromSearch(search.places, search.searchValue, bounds),
    });
  }, [search.searchValue, search.places, bounds]);

  function handleSearchChange(value: string) {
    searchDispatch({ type: 'SET_SEARCH_VALUE', payload: value });
  }

  function setLoadingPlaces(loading: boolean) {
    searchDispatch({ type: 'SET_LOADING_PLACES', payload: loading });
  }

  return {
    search,
    handleSearchChange,
    setLoadingPlaces,
  };
};

export type PortalPageContextType = ReturnType<typeof usePortalPageManager>;

export const PortalPageContext = createContext<PortalPageContextType>({
  search: {
    initialLoad: true,
    places: [],
    filteredPlaces: [],
    loadingPlaces: false,
    suggestions: [],
    searchValue: '',
  },
  handleSearchChange: () => null,
  setLoadingPlaces: () => null,
});

export const PortalPageProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  return <PortalPageContext.Provider value={usePortalPageManager()}>{children}</PortalPageContext.Provider>;
};

const Portal = () => {
  const { search, handleSearchChange } = useContext(PortalPageContext);
  const [mobileSearch, setMobileSearch] = useState(false);
  const [showMap, setShowMap] = useState(false);
  const { isMobileView } = useMobileView();
  const history = useHistory();
  const { params } = useRouteMatch();

  useEffect(() => {
    trackPage();
    localStorage.setItem('isLanding', params.id);
    localStorage.setItem('slug', params.slug);
    if (isServer) return;
    window.scrollTo(0, 0);
  }, []);

  const seo = () => {
    const isNoIndex = hasFeatureSetting(search?.filteredPlaces?.[0], 'seo_noindex_portal') || isSistaminuten();
    return <Helmet>{isNoIndex && <meta name="robots" content="noindex" />}</Helmet>;
  };

  return (
    <>
      <NavBar back onBackButtonClick={() => history.goBack()} title={_s('portalPage.title')} type="subView" />
      <div className="bg-gradient flex min-h-[calc(100vh-64px)]">
        {isMobileView ? (
          <>
            {showMap ? (
              <Map
                places={search.filteredPlaces}
                fetching={search.loadingPlaces}
                mapTopPos="64px"
                carouselClassname="!bottom-[94px]"
                mapWidth="!w-full"
              />
            ) : (
              <PlacesList setMobileSearch={setMobileSearch} />
            )}
            <SearchModal
              isOpen={mobileSearch}
              suggestions={search.suggestions}
              onChange={(_, { newValue }) => {
                handleSearchChange(newValue);
              }}
              placeholder={_s('portalPage.searchModal.placeholder')}
              title={_s('portalPage.searchModal.title')}
              value={search.searchValue}
              onSuggestionSelected={() => setMobileSearch(false)}
              onClose={() => setMobileSearch(false)}
            />
          </>
        ) : (
          <>
            <PlacesList setMobileSearch={setMobileSearch} />
            <Map
              places={search.filteredPlaces}
              fetching={search.loadingPlaces}
              mapTopPos="64px"
              mapWidth="!w-[calc(100%-390px)]"
            />
          </>
        )}
        {isMobileView && (
          <div className="fixed bottom-0 flex w-full justify-center pb-[30px]" style={{ zIndex: Z_INDEX.FAB_PORTAL }}>
            <Fab
              onClick={() => setShowMap((prev) => !prev)}
              size="md"
              rightIcon={<Icon variant={showMap ? 'list-ul' : 'map'} />}
              label={showMap ? _s('portalPage.showList') : _s('portalPage.showMap')}
              floating
            />
          </div>
        )}
      </div>
      {seo()}
    </>
  );
};

const PlacesList = ({ setMobileSearch }: { setMobileSearch: Dispatch<React.SetStateAction<boolean>> }) => {
  const { search, handleSearchChange, setLoadingPlaces } = useContext(PortalPageContext);
  const dispatch = useDispatch();
  const { isMobileView } = useMobileView();
  const location = useLocation();
  const whitelabelQuery = new URLSearchParams(location.search)?.get('whitelabel') === 'true';
  if (!isServer && whitelabelQuery) {
    sessionStorage.setItem('whitelabel', whitelabelQuery.toString());
  }

  const handleSearchInputChange = (_, { newValue }) => {
    setLoadingPlaces(true);
    handleSearchChange(newValue);
    // @ts-ignore
    dispatch(searchActions.removeParameter('bounds'));
  };

  const handleSearchInputRemove = () => {
    setLoadingPlaces(true);
    handleSearchChange('');
    // @ts-ignore
    dispatch(searchActions.removeParameter('bounds'));
  };

  const handleSearchInputFocus = (e) => {
    if (!isMobileView) return;
    e.target.blur() || setMobileSearch(true);
  };

  const handleSearchInputSuggestionSelected = () => {
    // @ts-ignore
    dispatch(searchActions.removeParameter('bounds'));
  };

  return (
    <div className={`gap-md p-lg flex w-full flex-col ${isMobileView ? '' : 'max-w-[390px]'}`}>
      <SearchInput
        suggestions={search.suggestions}
        onChange={handleSearchInputChange}
        onRemove={handleSearchInputRemove}
        onFocus={handleSearchInputFocus}
        onSuggestionSelected={handleSearchInputSuggestionSelected}
        placeholder={_s('portalPage.searchInput')}
        value={search.searchValue}
      />
      {search.filteredPlaces.length ? (
        search.filteredPlaces.map((place) => {
          if (place && place.about) {
            const imageUrl = getPlaceImage(place);
            return (
              <PlaceItemCard
                key={place.id}
                src={{
                  pathname: `/places/${place.about?.slug}-${place.id}`,
                  state: { fromList: true },
                }}
                address={{
                  city: place.contact?.address?.city,
                  postal: place.contact?.address?.zipcode,
                  street: place.contact?.address?.street,
                }}
                businessHours={isPlaceOpenToday(place)}
                rating={place?.rating?.score}
                ratingCount={place?.rating?.count}
                title={place.about?.name}
                image={{ src: imageUrl }}
              />
            );
          }
          return null;
        })
      ) : !search.loadingPlaces && !search.initialLoad ? (
        <div className="flex-1">
          <div>
            <EmptyState
              body={_s('portalPage.emptyState.content')}
              icon={<Icon color="black-200" style={{ width: 128 }} variant="search" />}
              title={_s('portalPage.emptyState.title')}
              containerClassName="!min-h-full"
            />
          </div>
        </div>
      ) : (
        <div className="flex flex-1 items-center justify-center">
          <LoadingDotsBlack loop onAnimationComplete={() => {}} />{' '}
        </div>
      )}
    </div>
  );
};

export default function PortalPage(props) {
  return (
    <PortalPageProvider>
      <Portal {...props} />
    </PortalPageProvider>
  );
}
