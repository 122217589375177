import Header from '@/components/elements/Header/Header';
import { ListItem, ListItemContent } from '@/components/elements/lists';
import { InitialsAvatar } from '@/components/elements/redesign/Avatar';
import Icon from '@/components/icons/Icon';
import { _s } from '@/locale';

type UserInfoProps = EmailRequiredProps | NameRequiredProps | PhoneRequiredProps;

type EmailRequiredProps = {
  name?: string;
  phone?: string;
  email: string;
  onClick?: () => void;
  initials?: string;
};

type NameRequiredProps = {
  name: string;
  phone?: string;
  email?: string;
  onClick?: () => void;
  initials?: string;
};

type PhoneRequiredProps = {
  name?: string;
  phone: string;
  email?: string;
  onClick?: () => void;
  initials?: string;
};

const UserInfo = ({ name, phone, email, onClick, initials = '' }: UserInfoProps) => {
  const titleTranslation = _s('components.templates.checkout.UserInfo.title');

  return (
    <div className="pb-lg flex flex-col">
      <div className="pl-lg py-md">
        <Header size="md" label={titleTranslation} />
      </div>
      <div className="pb-lg">
        <ListItem
          leftSlot={<InitialsAvatar size="md" variant="default" initials={initials} />}
          onClick={onClick}
          rightSlot={<Icon variant="chevron-s-right" />}
          underline>
          <ListItemContent content={email} subTitle={phone} title={name} />
        </ListItem>
      </div>
    </div>
  );
};

export default UserInfo;
