import { CARD_BRAND_MAP } from '@/constants/onlinePaymentConstants';
import { getCardName } from '@/helpers';
import { PaymentCard } from '@/types/paymentcards';
import { GenericCardIcon, UnknownCardIcon } from '../icons';

type CardIconProps = {
  card: PaymentCard | null;
  size?: 'small' | 'medium';
};

const iconSizes = {
  small: { width: 32, height: 'auto' },
  medium: { width: 40, height: 'auto' },
};

const CardIcon: React.FC<CardIconProps> = ({ card, size = 'medium' }) => {
  return (
    <div className="border-black-100 overflow-hidden rounded-sm border">
      {card ? (
        <>
          {CARD_BRAND_MAP[card.brand] ? (
            <img
              src={`https://checkoutshopper-live.adyen.com/checkoutshopper/images/logos/small/${card.brand}-xhdpi.png`}
              width={iconSizes[size].width}
              height={iconSizes[size].height}
              alt={getCardName(card.brand)}
            />
          ) : (
            <UnknownCardIcon width={iconSizes[size].width} height={iconSizes[size].height} />
          )}
        </>
      ) : (
        <GenericCardIcon stroke="none" width={iconSizes[size].width} height={iconSizes[size].height} />
      )}
    </div>
  );
};

export default CardIcon;
