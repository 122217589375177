import { bookActions } from '@/actions';
import {
  getGroupBookingParticipantSession,
  getUserProfileInfoFields,
  saveGroupBookingParticipantSession,
} from '@/helpers/checkout';
import { BookState } from '@/types/state/book';
import { ReactNode, createContext, useContext, useEffect, useReducer } from 'react';
import { useDispatch } from 'react-redux';
import { z } from 'zod';
import { useAppSelector } from '.';

export const GROUP_BOOKING_PARTICIPANTS_STORAGE_KEY = 'group-booking-participants';

type UseCheckoutFormDataContext = ReturnType<typeof useCheckoutFormDataManager>;

const CheckoutFormDataContext = createContext<UseCheckoutFormDataContext>(null);

type CheckoutFormDataState = { formData: CheckoutFormData; shouldStoreInSession: boolean };

type CheckoutFormDataAction =
  | {
      type: 'UPDATE_FORM_DATA';
      payload: { formData: UpdateFormData; shouldStoreInSession: boolean };
    }
  | {
      type: 'REMOVE_PARTICIPANT_FORM_DATA';
      payload: { id: string; shouldStoreInSession: boolean };
    };

export const bookingCheckoutFormDataSchema = z.record(z.record(z.string()));

export type CheckoutFormData = z.infer<typeof bookingCheckoutFormDataSchema>;

const updateFormDataSchema = z.object({
  id: z.string(),
  data: z.record(z.string()),
});

export type UpdateFormData = z.infer<typeof updateFormDataSchema>;

function handlePrefillParticipantsInfo(
  participants: Record<string, Record<string, string>>,
  updateFormData: (formId: string, shouldStoreInSession: boolean, value?: Record<string, string>) => void,
): void {
  Object.keys(participants).forEach((participantId) =>
    updateFormData(participantId, false, participants[participantId]),
  );
}

const checkoutFormDataReducer = (state: CheckoutFormDataState, action: CheckoutFormDataAction) => {
  switch (action.type) {
    case 'UPDATE_FORM_DATA':
      const newFormData = {
        ...state.formData,
        [action.payload.formData.id]: {
          ...state.formData[action.payload.formData.id],
          ...action.payload.formData.data,
        },
      };

      return {
        ...state,
        formData: newFormData,
        shouldStoreInSession: action.payload.shouldStoreInSession,
      };
    case 'REMOVE_PARTICIPANT_FORM_DATA':
      const { [action.payload.id]: _, ...rest } = state.formData;

      const newValues = Object.values(rest);
      const newState: Record<string, Record<string, string>> = newValues.reduce((acc, currentValue, index) => {
        acc[String(index + 1)] = currentValue;
        return acc;
      }, {} as Record<string, Record<string, string>>);

      return {
        ...state,
        formData: {
          ...newState,
        },
        shouldStoreInSession: action.payload.shouldStoreInSession,
      };
  }
};

type UseCheckoutFormDataProps = {
  employeeId: string;
  initialFormData?: CheckoutFormData;
};

const useCheckoutFormDataManager = ({ employeeId, initialFormData }: UseCheckoutFormDataProps) => {
  const user = useAppSelector((state) => state.users.user);
  const booking: BookState = useAppSelector((state) => state.book);
  const appDispatch = useDispatch();

  const [state, dispatch] = useReducer(checkoutFormDataReducer, {
    formData: initialFormData ? initialFormData : { '1': {} },
    shouldStoreInSession: false,
  });
  const isGroupBooking = booking.time.capacity > 1;

  useEffect(() => {
    if (!user) return;
    const savedParticipants = getGroupBookingParticipantSession(booking, employeeId);
    const userProfileInfoFields = getUserProfileInfoFields(user).reduce((acc, item) => {
      acc[item.id] = item.value;
      return acc;
    }, {});

    handlePrefillParticipantsInfo(
      savedParticipants && isGroupBooking ? savedParticipants : { '1': userProfileInfoFields },
      handleUpdateFormData,
    );
  }, [user]);

  useEffect(() => {
    const selectedCapacity = Object.keys(state.formData).length;
    appDispatch(bookActions.selectCapacity(selectedCapacity));
    if (isGroupBooking && state.shouldStoreInSession) {
      saveGroupBookingParticipantSession({ booking, formData: state.formData, employeeId });
    }
  }, [state.formData]);

  const handleUpdateFormData = (formId: string, shouldStoreInSession: boolean, value?: Record<string, string>) => {
    if (value) {
      dispatch({
        type: 'UPDATE_FORM_DATA',
        payload: { formData: { id: formId, data: value }, shouldStoreInSession },
      });
    } else {
      dispatch({
        type: 'REMOVE_PARTICIPANT_FORM_DATA',
        payload: { id: formId, shouldStoreInSession },
      });
    }
  };

  return {
    formData: state.formData,
    onUpdateFormData: handleUpdateFormData,
  };
};

export const CheckoutFormDataProvider = ({
  children,
  employeeId,
  initialFormData,
}: { children: ReactNode } & UseCheckoutFormDataProps) => {
  const checkoutForm = useCheckoutFormDataManager({ employeeId, initialFormData });
  return <CheckoutFormDataContext.Provider value={checkoutForm}>{children}</CheckoutFormDataContext.Provider>;
};

export const useCheckoutFormData = () => {
  const context = useContext(CheckoutFormDataContext);

  if (!context) {
    throw new Error('useCheckoutFormData must be used within a CheckoutFormDataProvider');
  }

  return context;
};
