import colors from '@/colors';
import { trackMpEvent } from '@/helpers';
import { getScreenName } from '@/services/navigationServices';
import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { BannerConfig, BannerType } from './types';

export const BannerLinkWrapper = ({
  type,
  position,
  cta,
  children,
}: {
  type: BannerType;
  position?: string;
  cta: BannerConfig['cta'];
  children: ReactNode;
}) => {
  if (!cta || !cta.url) {
    return <>{children}</>;
  }

  const handleOnClick = () => {
    if (!cta.amplitude_event) return;
    trackMpEvent(cta.amplitude_event, {
      screen_name: getScreenName(),
      banner_type: type,
      banner_position: position,
    });
  };

  const internalLink = !cta.url.startsWith('http');
  return internalLink ? (
    <Link to={cta.url} onClick={handleOnClick} title={cta.title}>
      {children}
    </Link>
  ) : (
    <a href={cta.url} onClick={handleOnClick} title={cta.title}>
      {children}
    </a>
  );
};

export const getBannerStyles = (backgroundColor: string, textColor?: string): React.CSSProperties => ({
  backgroundColor: backgroundColor || '#FFFFFF',
  color: textColor || colors['black'][900],
});

const HeaderFontSizeWrapper = ({ children }: { children: ReactNode }) => (
  <span className="sm:text-md lg:text-h-m text-base md:text-lg">{children}</span>
);
const H1 = ({ children }) => (
  <HeaderFontSizeWrapper>
    <h1 style={{ fontSize: '1.6em', lineHeight: 1.125 }}>{children}</h1>
  </HeaderFontSizeWrapper>
);
const H2 = ({ children }) => (
  <HeaderFontSizeWrapper>
    <h2 style={{ fontSize: '1.5em', lineHeight: 1.125 }}>{children}</h2>
  </HeaderFontSizeWrapper>
);
const H3 = ({ children }) => (
  <HeaderFontSizeWrapper>
    <h3 style={{ fontSize: '1.4em', lineHeight: 1.125 }}>{children}</h3>
  </HeaderFontSizeWrapper>
);
const H4 = ({ children }) => (
  <HeaderFontSizeWrapper>
    <h4 style={{ fontSize: '1.3em', lineHeight: 1.125 }}>{children}</h4>
  </HeaderFontSizeWrapper>
);
const H5 = ({ children }) => (
  <HeaderFontSizeWrapper>
    <h5 style={{ fontSize: '1.2em', lineHeight: 1.125 }}>{children}</h5>
  </HeaderFontSizeWrapper>
);
const H6 = ({ children }) => (
  <HeaderFontSizeWrapper>
    <h6 style={{ fontSize: '1.1em', lineHeight: 1.125 }}>{children}</h6>
  </HeaderFontSizeWrapper>
);

const SpanStyledAsAnchor = ({ children, style, className }) => {
  return (
    <span className={className} style={style}>
      {children}
    </span>
  );
};

export const markdownOverrides = (linkColor: string) => ({
  a: { props: { style: { color: linkColor }, className: 'underline' }, component: SpanStyledAsAnchor },
  strong: { props: { className: 'font-bold text-inherit' } },
  p: { props: { className: 'md:text-md' } },
  h1: { component: H1 },
  h2: { component: H2 },
  h3: { component: H3 },
  h4: { component: H4 },
  h5: { component: H5 },
  h6: { component: H6 },
});
