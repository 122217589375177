import { Card, CardHeader } from '@/components/elements/cards';
import QuantitySelector from '@/components/modules/pages/giftCards/QuantitySelector';
import ValueSelector from '@/components/modules/pages/giftCards/ValueSelector';
import { __ } from '@/locale';

/**
 * @typedef {object} SelectValueCardProps
 * @prop {Function} valueUpdate
 * @prop {number} value
 * @prop {number} quantity
 * @prop {Function} setQuantity
 * @prop {number} minValue
 * @prop {number[]} pricingValues
 *
 * @param {SelectValueCardProps} param0
 * @returns
 */
const SelectValueCard = ({ valueUpdate, value, quantity, setQuantity, minValue, pricingValues }) => (
  <Card id="selectValueCard">
    <CardHeader>{__('buyGiftcard.selectValue')}</CardHeader>
    <ValueSelector {...{ valueUpdate, value, pricingValues, minValue }} />
    <label>{__('buyGiftcard.selectQuantity')}</label>
    <QuantitySelector {...{ quantity, setQuantity }} />
  </Card>
);

export default SelectValueCard;
