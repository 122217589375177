/* eslint-disable */
import { LoginSuccess } from '@/components/animations';
import Snackbar from '@/components/elements/notifications/Snackbar/Snackbar';
import { LogoIcon } from '@/components/icons';
import { WelcomeText } from '@/components/modules/salon';
import { getHomeDescription, isSistaminuten, trackMpEvent, trackPage, url } from '@/helpers';
import useScrollToTop from '@/hooks/useScrollToTop';
import { _s } from '@/locale';
import { reviewsServices } from '@/services';
import { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

function AddReview(props) {
  useScrollToTop();

  const parsed = url.getParamsFromGET();
  const { userId, bookingId } = props.match.params;
  const [bookingData, setBookingData] = useState('');
  const [user, setUser] = useState({ about: { name: 'Anonym', avatar: '' } });
  const intStars = parseInt(parsed['star']);
  const defaultStarValue = (intStars && intStars > 5 ? 5 : intStars < 0 ? 1 : intStars) || 0;
  const [active, setActive] = useState(true);
  const [saved, setSaved] = useState(false);
  const [loading, setLoading] = useState(false);
  const LIMIT = 1000;
  const [charCount, setCharCount] = useState(LIMIT); // Initialize with maximum limit
  const [formData, saveFormData] = useState({
    stars: defaultStarValue,
    message: '',
    tempError: undefined,
    userId,
    bookingId,
  });

  const cursor = active ? (loading ? 'cursor-progress opacity-50' : 'cursor-pointer') : '';
  const canVote = active && !loading;

  useEffect(() => {
    // Track page
    trackPageVisit();
    //  Get Data
    reviewsServices
      .getReviewData(userId, bookingId, parsed['reviewId'])
      .then((response) => {
        const { employee, booking, expired, client, review = {} } = response || {};

        if (expired) {
          saveFormData({
            ...formData,
            stars: 0,
          });
          toast(
            ({ closeToast }) => (
              <Snackbar
                type="danger"
                label={_s('reviews.expired')}
                action={<button onClick={closeToast}>{_s('Alright')}</button>}
              />
            ),
            {
              autoClose: false,
            },
          );
          setActive(false);
          return;
        }
        setUser(client);
        const { text, attachment } = review.message || {};
        saveFormData({
          ...formData,
          stars: review.stars || defaultStarValue,
          message: text || '',
          bookingId,
          userId,
        });

        const anyEmployee = booking.extra && booking.extra.anyEmployee;
        const chosenEmployee = anyEmployee ? null : employee?.about?.name ? employee?.about?.name : null;

        const placeName = booking.place && booking.place?.about?.name;

        setBookingData(_s('employeeAtPlace', { employee: chosenEmployee, place: placeName }));

        if (defaultStarValue && !text) {
          saveFormData({
            ...formData,
            stars: defaultStarValue,
          });
          saveReview({ preventDefault: () => {} }, true);
        }
      })
      .catch((error) => {
        const errorLabel = error === 'Not found' ? 'reviews.noBooking' : 'serverError';
        saveFormData({
          ...formData,
          stars: 0,
        });
        toast(
          ({ closeToast }) => (
            <Snackbar
              type="danger"
              label={_s(errorLabel)}
              action={<button onClick={closeToast}>{_s('Alright')}</button>}
            />
          ),
          {
            autoClose: false,
          },
        );
        setActive(false);
      });
  }, []);

  const fileInput = useRef(null);

  const seo = () => {
    const title = _s('postReview');
    const description = getHomeDescription();
    const pageUrl = `${url.getBaseUrl()}/addReview`;
    const imageUrl = url.getBaseImageUrl();
    const isNoIndex = isSistaminuten();

    return (
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <link rel="canonical" href={pageUrl} />

        <meta property="og:url" content={pageUrl} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={imageUrl} />
        {isNoIndex && <meta name="robots" content="noindex" />}
      </Helmet>
    );
  };

  const trackPageVisit = () => {
    trackPage();
    // trackMpEvent('screen_shown', { screen_name: 'addReview' });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    // submitForm(addReview);
  };

  const getStarType = (nr) => {
    if (nr > formData.stars) {
      // empty
      return (
        <img src="/images/reviews/star-empty.svg" className={`mr-2 block`} width={48} height={48} alt={_s('ratings')} />
      );
    } else {
      // full
      return (
        <img src="/images/reviews/star-full.svg" className={`mr-2 block`} width={48} height={48} alt={_s('ratings')} />
      );
    }
  };

  const voteStars = (value, e) => {
    if (formData.stars === value) {
      return;
    }
    formData.stars = value;
    saveFormData({ ...formData });
    // setSaved(false);
    saveReview(e, true);
  };

  const getStars = (withNames) => {
    return (
      <>
        <div className="flex max-w-[400px] flex-row justify-center">
          <a className={`m-2 ${cursor}`} onClick={(e) => canVote && voteStars(1, e)}>
            {getStarType(1)}
          </a>
          <a className={`m-2 ${cursor}`} onClick={(e) => canVote && voteStars(2, e)}>
            {getStarType(2)}
          </a>
          <a className={`m-2 ${cursor}`} onClick={(e) => canVote && voteStars(3, e)}>
            {getStarType(3)}
          </a>
          <a className={`m-2 ${cursor}`} onClick={(e) => canVote && voteStars(4, e)}>
            {getStarType(4)}
          </a>
          <a className={`m-2 ${cursor}`} onClick={(e) => canVote && voteStars(5, e)}>
            {getStarType(5)}
          </a>
        </div>
        {withNames && (
          <div className="text-black-900 flex flex-row justify-between">
            <span>{_s('reviews.hemsk')}</span>
            <span>{_s('reviews.fantastisk')}</span>
          </div>
        )}
      </>
    );
  };

  const isFileSizeValid = (currentSize) => {
    if (currentSize <= 15) {
      return true;
    }

    return false;
  };

  const saveReview = (e, skipSaved = false) => {
    e.preventDefault();
    if (!active) {
      return;
    }

    setLoading(true);
    reviewsServices.save(formData).then((response) => {
      const { error, saved } = response || {};

      setLoading(false);
      if (!saved) {
        setActive(false);

        const errorList = ['noShow', 'noBooking', 'expired'];
        const errorLabel = errorList.includes(error) ? `reviews.${error}` : 'serverError';

        toast(
          ({ closeToast }) => (
            <Snackbar
              type="danger"
              label={_s(errorLabel)}
              action={<button onClick={closeToast}>{_s('Alright')}</button>}
            />
          ),
          {
            autoClose: false,
          },
        );
        return;
      }

      !skipSaved && setSaved(true);
    });
  };

  return (
    <>
      <div className="z-9999 pl-md fixed top-0 flex h-[64px] w-full items-center bg-white shadow-sm">
        <Link to="/" aria-label="Bokadirekt">
          <LogoIcon className="h-5 sm:h-6" />
        </Link>
      </div>
      <div>
        <div
          className="
        border-[rgba(0,
        0,
        0,
        0.125)]
        sm:hover:shadow-cardHover
        group
        m-6
        mx-auto
        mt-[80px]
        grid
        w-auto
        max-w-[536px]
        break-words rounded-lg border-[1px]
        border-none
        bg-white
        p-4
        shadow
        duration-200">
          <div className="center border-black-100 max-w-[324px] items-center justify-self-center border-b-[1px] py-4 text-center">
            <div className="text-black-900 mb-5 font-semibold">{bookingData || _s('reviews.letAReview')}</div>
            {getStars(1)}
            {!!formData.stars && (
              <span className="text-black-900 font-semibold">🙏 {_s('reviews.thanksForBooking')}</span>
            )}
          </div>
          <div className="my-2 text-lg font-semibold">{_s('reviews.describeExperience')}</div>
          {!!formData.stars && !saved && (
            <div className="mt-4">
              <span>{_s('reviews.comments.label')}</span>
              <textarea
                disabled={!active}
                rows={3}
                onChange={(e) => {
                  const { value } = e.target;
                  setSaved(false);
                  if (value.length <= LIMIT) {
                    formData.message = value;
                    saveFormData({ ...formData });
                    const remainingChars = LIMIT - value.length;
                    setCharCount(remainingChars); // update character count
                  }
                }}
                maxLength={LIMIT} // limit the nr of characters
                name="bookingNotes"
                className="form-control my-3"
                placeholder={_s('reviews.textPlaceholder')}
                value={formData.message}
              />
              <div className="right text-black-400 mb-4 text-right text-sm">
                {charCount} / {LIMIT}
              </div>
              <div className="flex-col items-center text-center">
                {active && (
                  <a
                    onClick={(e) => canVote && saveReview(e)}
                    className={`
                      border-primary
                      focus:!ring-primary-200
                      bg-primary
                      mb-3
                      inline-flex
                      h-12
                      w-full
                      flex-1
                      items-center
                      justify-center
                      rounded-full
                      border
                      px-4
                      ${cursor}
                      uppercase !leading-5 text-white transition-colors duration-300 hover:text-white hover:opacity-90 focus:!outline-none focus:!ring-2`}>
                    {_s('send')}
                  </a>
                )}
              </div>
            </div>
          )}
          {!!formData.stars && saved && (
            <div className="mb-4 flex flex-col items-center">
              <LoginSuccess style={{ width: 240, height: 240 }} onAnimationComplete={() => {}} />
              <span>{_s('reviews.commentsaved')}</span>
            </div>
          )}
          {!!formData.stars && <WelcomeText style="mt-4 text-[12px] text-black-400" text={_s('reviews.bottomNote')} />}
        </div>
        {seo()}
      </div>
    </>
  );
}

export default AddReview;
