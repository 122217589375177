import { Card, CardHeader } from '@/components/elements/cards';
import BorderLabelDropdown from '@/components/elements/forms/BorderLabelDropdown/BorderLabelDropdown';
import { Button } from '@/components/elements/forms/buttons';
import KlarnaPaymentMethodCategories from '@/components/modules/klarna/KlarnaPaymentMethodCategories';
import GiftCardsTerms from '@/components/modules/pages/giftCards/buy/GiftCardsTerms';
import QuantitySelector from '@/components/modules/pages/giftCards/QuantitySelector';
import ValueSelector from '@/components/modules/pages/giftCards/ValueSelector';
import { __, _s } from '@/locale';

const base = 'buyPlaceGiftCard.checkout';

const CheckoutColumn = ({
  valueUpdate,
  value,
  quantity,
  setQuantity,
  minValue,
  employees,
  employee,
  setEmployee,
  placeName,
  authorize,
  paymentMethodCategories,
}) => {
  return (
    <>
      <Card>
        <CardHeader>{_s(`${base}.selectEmployee.title`)}</CardHeader>
        <BorderLabelDropdown
          selected={employee ?? null}
          label={__(`${base}.selectEmployee.label`)}
          setSelected={setEmployee}
          options={
            employees && employees.length > 0
              ? employees?.map((e) => ({
                  value: e.id,
                  label: e.name,
                }))
              : []
          }
        />
      </Card>
      <Card>
        <CardHeader>{_s(`${base}.selectValue`)}</CardHeader>
        <ValueSelector {...{ valueUpdate, value, minValue }} />
        <label>{_s(`${base}.selectQuantity`)}</label>
        <QuantitySelector {...{ quantity, setQuantity }} />
      </Card>
      <Card>
        <CardHeader>{_s(`${base}.payment`)}</CardHeader>
        <KlarnaPaymentMethodCategories paymentMethodCategories={paymentMethodCategories} />
        <div id="klarna-payment"></div>
        <div className="gap-lg flex flex-col">
          <Button size="md" block onClick={authorize}>
            {_s('buy')}
          </Button>
          <GiftCardsTerms />
        </div>
      </Card>
    </>
  );
};

export default CheckoutColumn;
