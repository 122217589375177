import { isServer, promiseWrapper, server } from '@/helpers';
import { BundleFetchStatus } from '@/hooks/checkout/useBundleCheckoutFallback';
import { BUNDLE_CHECKOUT_SESSION_STORAGE_KEY } from '@/reducers/bundleReducer';
import { BundleState } from '@/types/state/bundle';
import * as Sentry from '@sentry/react';
import { Dispatch, SetStateAction } from 'react';

export type BundleAction =
  | {
      type: typeof BUNDLE_ACTIONS.PICK_BUNDLE_TO_CHECKOUT;
      payload: BundleState;
    }
  | {
      type: typeof BUNDLE_ACTIONS.CLEAR_BUNDLE_CHECKOUT;
    };

const BUNDLE_ACTIONS = {
  PICK_BUNDLE_TO_CHECKOUT: 'PICK_BUNDLE_TO_CHECKOUT',
  CLEAR_BUNDLE_CHECKOUT: 'CLEAR_BUNDLE_CHECKOUT',
} as const;

function pickBundleToCheckout(payload: BundleState) {
  if (!isServer) {
    sessionStorage.setItem(BUNDLE_CHECKOUT_SESSION_STORAGE_KEY, JSON.stringify(payload));
  }
  return { type: BUNDLE_ACTIONS.PICK_BUNDLE_TO_CHECKOUT, payload };
}

function clearBundleCheckout() {
  if (!isServer) {
    sessionStorage.removeItem(BUNDLE_CHECKOUT_SESSION_STORAGE_KEY);
  }
  return { type: BUNDLE_ACTIONS.CLEAR_BUNDLE_CHECKOUT };
}

function fetchAndSetPlaceBundle(
  placeId: number,
  bundleId: number,
  abortSignal: AbortSignal,
  setStatus: Dispatch<SetStateAction<BundleFetchStatus>>,
) {
  return async (dispatch: Dispatch<BundleAction>) => {
    const { data, error } = await promiseWrapper(server.request.get(`/findPlace/${placeId}`, { abortSignal }));

    if (error) {
      setStatus('error');
      return;
    }

    {
      const { redirect } = data?.data || {};

      if (!redirect) {
        setStatus('error');
        return undefined;
      }

      const pathname = new URL(redirect).pathname;

      const parts = pathname.replace('/places/', '');
      const placeIdIndex = parts.lastIndexOf('-');

      const slug = parts.slice(0, placeIdIndex);
      const placeId = parts.slice(placeIdIndex + 1);

      const uri = `/findPlace/${placeId}/${encodeURIComponent(slug)}?slug=${encodeURIComponent(slug)}`;

      const { data: placeData, error } = await promiseWrapper(server.request.get(uri));

      if (error || !placeData.data.place) {
        Sentry.captureMessage(`User failed to fetch place bundle on place ${placeId} and bundle ${bundleId}`);
        setStatus('error');
        return;
      }

      const place = placeData.data.place;
      const bundle = place?.bookingBundles?.find((bundle) => bundle.id === bundleId);

      if (!bundle) {
        setStatus('error');
        return;
      }

      const payload = { place, bundle } as BundleState;

      dispatch(pickBundleToCheckout(payload));
    }
  };
}

export const bundleActions = {
  pickBundleToCheckout,
  clearBundleCheckout,
  fetchAndSetPlaceBundle,
};
