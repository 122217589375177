import { loadingActions } from '@/actions';
import { searchActions } from '@/actions/index';
import { Button } from '@/components/elements/forms/buttons';
import { DropdownButton } from '@/components/elements/forms/DropdownButton';
import { CloseIcon } from '@/components/icons';
import Icon from '@/components/icons/Icon';
import { Dropdown } from '@/components/modules/dropdown';
import { getGeoLocation, trackMpEvent, url } from '@/helpers';
import withMobileView from '@/hoc/withMobileView';
import { __ } from '@/locale/index';
import { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { ModalContent, ModalDialog } from '../../modals';

export const sortingOptions = [
  { value: 'popular', label: __('sorting_popular') },
  { value: 'closest', label: __('sorting_closest') },
  { value: 'availability', label: __('sorting_availability') },
  { value: 'reviews_score', label: __('sorting_rating_score') },
  // { value: 'new_salon', label: __('sorting_new_salon') },
];

const Sorting = (props) => {
  const { sort: sortFromProps, lat: latFromProps, lon: lonFromProps } = url.getUrlParameters(props);
  const sort = sortingOptions.find((option) => option.value === sortFromProps) || {
    value: 'popular',
    label: __('sort'),
  };

  const [open, setOpen] = useState(false);
  const [position, setPosition] = useState({
    lat: latFromProps,
    lon: lonFromProps,
  });

  const toggleOptions = (e) => {
    setOpen(!open);
  };

  const hideOptions = (e) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    setOpen(false);
  };

  const getOptions = () => {
    return (
      <>
        <span className="text-capitalize text-xxs text-black-400 block px-4">{__('sortBy')}</span>
        <ul className="navbar-nav">
          {sortingOptions.map((item, index) => {
            const isActive = item.value === sort?.value;
            return (
              <li key={index} className={`flex cursor-pointer items-center px-4 py-3`} onClick={onChange(item)}>
                <span className="flex-1">{__(item.label)}</span>
                {isActive && <Icon variant="check" className="h-5 w-5 flex-shrink-0" />}
              </li>
            );
          })}
        </ul>
      </>
    );
  };

  const getFilterOptions = () => {
    if (!open) {
      return;
    }

    return !props.isMobileView ? (
      <Dropdown
        className={`
            left-[-0.05rem]
            top-7
            m-0 min-w-[20rem] rounded-b-2xl rounded-t-none
            p-0
          `}
        allowClick={open}
        onClickOutside={hideOptions}
        children={<div className="pt-2">{getOptions()}</div>}
      />
    ) : (
      <ModalDialog isOpen={open} appElement={document.getElementById('root')} showOverlay onRequestClose={hideOptions}>
        <ModalContent size="md" stickToBottom>
          <div className="relative flex h-[50px] items-center justify-between pl-4 shadow">
            {__('sortBy')}
            <Button variant="link" onClick={hideOptions} leftIcon={<CloseIcon className="text-black-900 h-3 w-3" />} />
          </div>
          <div className="py-4">{getOptions()}</div>
        </ModalContent>
      </ModalDialog>
    );
  };

  const onChangeSort = (sorting, positionParams) => {
    const { dispatch, history } = props;
    setPosition(positionParams);
    const params = { ...positionParams, sort: sorting.label !== __('sort') ? sorting.value : undefined };
    const newSearchQuery = url.addSortingFiltersWithValue(params);
    history.push({ search: newSearchQuery });
    dispatch(searchActions.setParameter(params));
    trackMpEvent('search_sorting', { sorting_option: sorting?.value });
    setOpen(false);
  };

  const onClose = (e) => {
    onChange()(e);
  };

  const onChange = (sorting) => (e) => {
    const { dispatch } = props;

    let positionParams = {
      lat: undefined,
      lon: undefined,
    };

    if (!sorting) {
      sorting = {
        value: 'popular',
        label: __('sort'),
      };
    }

    if (sorting?.value === 'closest') {
      getGeoLocation(
        (lat, lon) => {
          dispatch(loadingActions.hide());

          positionParams = {
            lat: position?.lat || undefined,
            lon: position?.lon || undefined,
          };

          if (lat && lon) {
            positionParams = { lat, lon };
          }

          onChangeSort(sorting, positionParams);
        },
        () => {},
        () => {
          url.fakeLoading(dispatch, 'loadLocation', 120000);
        },
        () => {
          dispatch(loadingActions.hide());
          setOpen(false);
          // onChangeSort(sorting, positionParams);
        },
      );
    } else {
      onChangeSort(sorting, positionParams);
    }
  };

  return (
    <li>
      <DropdownButton
        toggleOpen={toggleOptions}
        isToggleOpen={!props.isMobileView ? open : false}
        isActive={sort?.label !== __('sort')}
        icon={<Icon variant="arrow-up-down" className="h-4 w-4 flex-shrink-0" />}
        newDesign
        className={`${props.isMobileView ? 'max-w-[120px]' : ''} pl-2`}
        close={onClose}>
        <span className="text-m overflow-hidden overflow-ellipsis whitespace-nowrap">{sort.label}</span>
        {getFilterOptions()}
      </DropdownButton>
    </li>
  );
};

function mapStateToProps(state) {
  const { search, loading } = state;
  const { show } = loading;
  return { search, show };
}

const connectedSort = withMobileView(withRouter(connect(mapStateToProps)(Sorting)));
export { connectedSort as Sorting };
