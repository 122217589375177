import { SMART_LINKS } from '@/constants/smartLinkConstants';
import { _s } from '@/locale';
import QRCode from 'react-qr-code';

const baseTranslationKey = 'pages.bransch-organisationer.shared.AppPromotion';

const AppPromotion = () => {
  return (
    <section className="space-y-md md:space-y-lg flex flex-col items-center text-center">
      <h3 className="max-w-xl text-lg font-semibold md:text-2xl">{_s(`${baseTranslationKey}.title`)}</h3>
      <p className="text-black-600">{_s(`${baseTranslationKey}.body`)}</p>
      <div className="flex items-center justify-center">
        <a className="align-center justify-left flex flex-wrap" href={SMART_LINKS.HOME}>
          <img src="/images/download-app-store.svg" className="mr-3 h-[40px]" alt={_s('availableAppStore')} />
          <img src="/images/download-google-play.svg" className="h-[40px]" alt={_s('availablePlayStore')} />
        </a>
      </div>
      <QRCode
        size={110}
        style={{ height: 'auto', maxWidth: '100px', width: '100px' }}
        value={SMART_LINKS.HOME}
        viewBox={`0 0 110 110`}
      />
    </section>
  );
};

export default AppPromotion;
