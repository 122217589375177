import { getLang } from '../locale';

export const formatPrice = (val) => {
  if (!val || isNaN(Number(val))) {
    val = 0;
  }

  return formatMoney(val, 2, '.', ' ');
};

export const formatMoney = (number, decPlaces, decSep, thouSep) => {
  decPlaces = isNaN((decPlaces = Math.abs(decPlaces))) ? 2 : decPlaces;
  decSep = typeof decSep === 'undefined' ? '.' : decSep;
  thouSep = typeof thouSep === 'undefined' ? ',' : thouSep;
  var sign = number < 0 ? '-' : '';
  var i = String(parseInt((number = Math.abs(Number(number) || 0).toFixed(decPlaces))));
  let j = i.length;
  j = j > 3 ? j % 3 : 0;

  return (
    sign +
    (j ? i.substr(0, j) + thouSep : '') +
    i.substr(j).replace(/(\decSep{3})(?=\decSep)/g, '$1' + thouSep) +
    (decPlaces &&
    Math.abs(number - i)
      .toFixed(decPlaces)
      .slice(2) !== '00'
      ? decSep +
        Math.abs(number - i)
          .toFixed(decPlaces)
          .slice(2)
          .replace(/\.0+$/, '')
      : '')
  );
};

export const moneyToDecimal = (number) => {
  const decSep = getLang() === 'en' ? '.' : ',';
  const thouSep = getLang() === 'en' ? ',' : ' ';
  return formatMoney(parseFloat(number) / 100, 2, decSep, thouSep);
};
