import { classnames } from '@/helpers';

const SPACING = {
  '0': '',
  xs: 'py-xxs',
  sm: 'py-md',
  md: 'py-lg',
  lg: 'py-xxl',
};

const HR = ({ verticalSpacing = '0' }: { verticalSpacing?: '0' | 'xs' | 'sm' | 'md' | 'lg' }) => {
  return (
    <span className={classnames('inline-flex w-full', SPACING[verticalSpacing])}>
      <span className="bg-black-100 my-auto h-[1px] w-full" />
    </span>
  );
};

export default HR;
