import Header from '@/components/elements/Header/Header';
import { useAppSelector } from '@/hooks';
import useMobileView from '@/hooks/useMobileView';
import { _s } from '@/locale';
import { BookState } from '@/types/state/book';
import Modal from '../../redesign/Modal/Modal';
import { getPickedEmployee, ScrollToTopElement } from '../BookAppointment.helpers';
import { useBookAppointmentContext } from '../BookAppointment.hooks';
import ServiceCategoryList from '../shared/ServiceCategoryList';
import Summary from '../shared/Summary';

const baseTranslationKey = 'components.modules.modals.BookAppointment.views.PickServiceView';

const PickServiceView = () => {
  const { isMobileView } = useMobileView();
  const context = useBookAppointmentContext();
  const { employee, place, services: pickedServices } = useAppSelector((state) => state.book) as BookState;
  const pickedEmployee = getPickedEmployee(employee, place.employees);

  return (
    <Modal.Content floating={!isMobileView}>
      <ScrollToTopElement />
      <Modal.Header title={_s(`${baseTranslationKey}.title`)} onBack={context.close} />
      <div className={`pb-lg flex flex-col ${isMobileView ? 'px-lg' : ''}`}>
        <div className="p-md">
          <Summary pickedEmployee={pickedEmployee} pickedServices={pickedServices} showAddAdditionalService={false} />
        </div>
        <div className="pt-xxl">
          <Header label={_s(`${baseTranslationKey}.title`)} size="md" />
        </div>
        <ul>
          <ServiceCategoryList />
        </ul>
      </div>
    </Modal.Content>
  );
};

export default PickServiceView;
