import { classnames } from '@/helpers';
import { ReactNode } from 'react';

const DataGridRow = ({
  children,
  hasPaddingTop,
  undeline,
}: {
  children: ReactNode;
  hasPaddingTop?: boolean;
  undeline?: boolean;
}) => {
  return (
    <div
      role="row"
      className={classnames(
        'pb-sm flex items-center justify-between',
        hasPaddingTop && 'pt-sm',
        undeline && 'border-black-100 border-b',
      )}>
      {children}
    </div>
  );
};

export default DataGridRow;
