import { giftcardSchema } from '@/types/state/book';
import { z } from 'zod';

export const swishBookingPaymentRequestSchema = z.object({
  type: z.literal('booking'),
  id: z.number(),
  callbackUrl: z.string().optional(),
  giftcards: z.array(giftcardSchema).optional(),
});

export const swishBundlePaymentRequestSchema = z.object({
  type: z.literal('bundle'),
  id: z.number(),
  placeId: z.number(),
  callbackUrl: z.string().optional(),
  formValues: z.object({ 1: z.object({ nationalId: z.string() }) }).optional(),
});

const swishPaymentRequestSchema = z.union([swishBookingPaymentRequestSchema, swishBundlePaymentRequestSchema]);

export type SwishPaymentRequest = z.infer<typeof swishPaymentRequestSchema>;

export const swishPaymentResponseSchema = z.object({
  paymentId: z.string(),
  url: z.string(),
  qrCode: z.string(),
  channel: z.string(),
  id: z.number(),
});

export type SwishPaymentResponse = z.infer<typeof swishPaymentResponseSchema>;

export const swishPaymentStatusResponseSchema = z.object({
  amount: z.number(),
  status: z.union([z.literal('success'), z.literal('inProgress'), z.literal('failed'), z.literal('unknown')]),
  errorCode: z.union([z.string(), z.null()]),
  errorReason: z.union([z.string(), z.null()]),
});

export type SwishPaymentStatusResponse = z.infer<typeof swishPaymentStatusResponseSchema>;

export const swishNotificationSchema = z.object({
  success: z.boolean(),
  paymentId: z.string(),
  id: z.number(),
  clientError: z.string().nullable().optional(),
});

export const keepAliveNotificationSchema = z.object({ keepAlive: z.boolean() });
