import { LinkButton } from '@/components/elements/forms/buttons';
import Header from '@/components/elements/Header/Header';
import Alert from '@/components/elements/notifications/Alert/Alert';
import { LoadingIcon } from '@/components/icons';
import Icon from '@/components/icons/Icon';
import PageViewLayout from '@/components/layouts/PageViewLayout/PageViewLayout';
import SEO from '@/components/modules/SEO';
import { APP_ROUTES } from '@/constants/pages';
import { url } from '@/helpers';
import { _s } from '@/locale';
import { Association } from '@/types/api/services/associationPages';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Fragment } from 'react/jsx-runtime';
import { useAssociationsArchive } from './index.hooks';
import AppPromotion from './shared/AppPromotion';
import Stats from './shared/Stats';

const baseTranslationKey = 'pages.bransch-organisationer.AssociationsArchive';

const AssociationsArchive = ({ associationPages: ssrPages }) => {
  const { pages, error } = useAssociationsArchive(ssrPages);
  const title = _s(`${baseTranslationKey}.page-title`);
  const description = _s(`${baseTranslationKey}.page-description`);

  if (error) {
    return <Redirect to="404" />;
  }

  if (!pages) {
    return (
      <PageViewLayout type="mainView" title={title} wrapperClass="bg-gradient" hideBottomNavBar>
        <LoadingIcon className="mx-auto h-8 w-8" />
      </PageViewLayout>
    );
  }

  return (
    <PageViewLayout type="mainView" title={title} wrapperClass="bg-gradient" hideBottomNavBar>
      <div>
        <div className="space-y-7xl md:space-y-huge pb-7xl container max-w-[800px]">
          {/* Hero */}
          <div className="pt-lg md:pt-7xl flex flex-col">
            <div className="space-y-md">
              <h1 className="text-2xl font-semibold md:text-3xl">{title}</h1>
              <p className="text-black-800 text-md md:text-lg">{description}</p>
            </div>
          </div>

          <div className="space-y-7xl">
            {pages.map((association, idx) => (
              <Fragment key={idx}>
                <AssociationsSection {...association} />
                {idx === 0 && (
                  <Alert
                    variant="information"
                    body={
                      <>
                        <span className="text-black-900 font-semibold">Tips:</span> När du söker efter en tjänst på
                        Bokadirekt kan du filtrera på “Branschorganisation” för att se de anslutna företagen.
                      </>
                    }
                  />
                )}
                {idx === 4 && (
                  <Alert
                    variant="information"
                    title={_s(`${baseTranslationKey}.Alert.title`)}
                    body={
                      <>
                        {_s(`${baseTranslationKey}.Alert.body`)}{' '}
                        <a className="text-black-900 font-semibold" href="mailto:partner@bokadirekt.se">
                          partner@bokadirekt.se
                        </a>
                      </>
                    }
                  />
                )}
              </Fragment>
            ))}
          </div>

          <Stats />
          <AppPromotion />
        </div>
      </div>

      <SEO title={title} description={description} url={`${url.getBaseUrl()}${APP_ROUTES.ASSOCIATION_PAGES}`} />
    </PageViewLayout>
  );
};

const AssociationsSection = ({ m3Id, name, summary, slug }: Association) => {
  return (
    <section className="gap-lg flex flex-col-reverse items-center md:flex-row even:md:flex-row-reverse">
      <div className="space-y-xl md:px-lg flex-1">
        <div className="space-y-md">
          <Header h="h2" label={name} size="xl" />
          <p className="text-black-600 text-md cols line-clamp-[3] overflow-hidden">{summary}</p>
        </div>
        <LinkButton
          to={{ pathname: `${APP_ROUTES.ASSOCIATION_PAGES}/${slug}` }}
          variant="primary"
          label={_s(`${baseTranslationKey}.AssociationsSection.cta`)}
          size="sm"
          rightIcon={<Icon variant="arrow-right" />}
        />
      </div>
      {/* Association Image */}
      <div className="bg-highlight-50 grid h-[240px] w-full place-items-center rounded-lg md:w-[390px] md:flex-1">
        <img src={`/images/associations-${m3Id}.png`} alt="" width={128} height={128} />
      </div>
    </section>
  );
};

const mapStateToProps = ({ associationPages }) => ({ associationPages });

export default connect(mapStateToProps)(AssociationsArchive);
