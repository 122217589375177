import useWalletPayment from '@/hooks/useWalletPayment';

function withWalletPayment<P>(WrappedComponent: React.ComponentType<P & ReturnType<typeof useWalletPayment>>) {
  const WithWalletPayment = (props: P) => {
    const { isLoading, hasActiveApplePayPaymentMethod, hasActiveGooglePayPaymentMethod } = useWalletPayment();
    return (
      <WrappedComponent
        {...props}
        hasActiveGooglePayPaymentMethod={hasActiveGooglePayPaymentMethod}
        hasActiveApplePayPaymentMethod={hasActiveApplePayPaymentMethod}
        isLoading={isLoading}
      />
    );
  };
  return WithWalletPayment;
}

export default withWalletPayment;
