export const userConstants = {
  REGISTER_REQUEST: 'USERS_REGISTER_REQUEST',
  REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
  REGISTER_FAILURE: 'USERS_REGISTER_FAILURE',

  LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
  LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
  LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

  LOGOUT: 'USERS_LOGOUT',
  DELETE_USER: 'DELETE_USER',

  GETALL_REQUEST: 'USERS_GETALL_REQUEST',
  GETALL_SUCCESS: 'USERS_GETALL_SUCCESS',
  GETALL_FAILURE: 'USERS_GETALL_FAILURE',

  DELETE_REQUEST: 'USERS_DELETE_REQUEST',
  DELETE_SUCCESS: 'USERS_DELETE_SUCCESS',
  DELETE_FAILURE: 'USERS_DELETE_FAILURE',

  RESET_PASSWORD_REQUEST: 'RESET_PASS_REQUEST',
  RESET_PASSWORD_SUCCESS: 'RESET_PASS_SUCCESS',
  RESET_PASSWORD_FAILURE: 'RESET_PASS_FAILURE',

  CHECK_TOKEN_REQUEST: 'TOKEN_REQUEST',
  CHECK_TOKEN_SUCCESS: 'TOKEN_SUCCESS',
  CHECK_TOKEN_FAILURE: 'TOKEN_FAILURE',

  NEW_PASSWORD_REQUEST: 'NEW_PASSWORD_REQUEST',
  NEW_PASSWORD_SUCCESS: 'NEW_PASSWORD_SUCCESS',
  NEW_PASSWORD_FAILURE: 'NEW_PASSWORD_FAILURE',

  UPDATE_USER_SUCCESS: 'UPDATE_USER_SUCCESS',
  UPDATE_USER_FAILURE: 'UPDATE_USER_FAILURE',

  ACCOUNT_CONFIRMATION: 'ACCOUNT_CONFIRMATION',
  CONFIRMATION_FAIL: 'CONFIRMATION_FAIL',
} as const;
