import Icon from '@/components/icons/Icon';
import { _s } from '@/locale';
import { Link } from 'react-router-dom';

const Breadcrumbs = ({ title }: { title: string }) => {
  return (
    <div className=" flex h-10 items-center">
      <Link to={'/'} className="text-information-700">
        {_s('home')}
      </Link>
      <Icon variant="chevron-right" color="information-700" />
      <Link to={'/fakta-och-rad'} className="text-information-700">
        {_s('landingPages.archiveTitle')}
      </Link>
      <Icon variant="chevron-right" color="information-700" />
      <span className="text-black-900">{title}</span>
    </div>
  );
};

export default Breadcrumbs;
