import React from 'react';

class ServicesAtHome extends React.Component {
  componentDidMount() {
    const link = document.createElement('link');
    link.href = 'https://bokadirekt.bokahem.se/css/app.css';
    link.type = 'text/css';
    link.rel = 'stylesheet';
    var head = document.getElementsByTagName('head')[0];
    head.appendChild(link);

    const script = document.createElement('script');
    script.src = 'https://bokadirekt.bokahem.se/js/app.js';
    var body = document.getElementsByTagName('body')[0];
    body.appendChild(script);
  }

  render() {
    return (
      <div style={{ minHeight: '100vh' }}>
        <bokahem-widget></bokahem-widget>
      </div>
    );
  }
}

export default ServicesAtHome;
