import useMobileView from '@/hooks/useMobileView';
import { _s } from '@/locale';
import Modal from '../../redesign/Modal/Modal';
import { ScrollToTopElement } from '../BookAppointment.helpers';
import { useBookAppointmentContext } from '../BookAppointment.hooks';
import ServiceCategoryList from '../shared/ServiceCategoryList';

const baseTranslationKey = 'components.modules.modals.BookAppointment.views.PickServicesView';

const PickServicesView = () => {
  const { isMobileView } = useMobileView();
  const context = useBookAppointmentContext();

  const handleOnCloseClick = () => {
    const view = context.view.previous;
    context.updateView(view);
  };

  return (
    <Modal.Content floating={!isMobileView} gradientBackground>
      <ScrollToTopElement />
      <Modal.Header title={_s(`${baseTranslationKey}.title`)} onClose={handleOnCloseClick} />
      <div className={`flex flex-col ${isMobileView ? 'px-lg' : ''}`}>
        <ServiceCategoryList />
      </div>
    </Modal.Content>
  );
};

export default PickServicesView;
