import { ReadMoreModal } from '@/components/modules/modals';
import { capitalizeFirstLetter, getServiceDuration, getServicePrice, isMobile } from '@/helpers';
import React from 'react';

export default class CampaignService extends React.Component {
  render() {
    const { service, campaignService, place, isPackage, priceListId } = this.props;

    const ordPrice = isPackage ? '' : getServicePrice(service, priceListId, place);
    const offerPrice = isPackage ? '' : getServicePrice(service, priceListId, place, campaignService);
    const price = isPackage ? (
      ''
    ) : offerPrice && offerPrice !== ordPrice ? (
      <span className="text-information">
        {offerPrice + ' '}
        <s className="text-black-600">{'(ord. ' + ordPrice + ')'}</s>
      </span>
    ) : (
      ordPrice
    );

    const serviceDuration = getServiceDuration(service, priceListId);
    const name = isMobile()
      ? capitalizeFirstLetter(service.name.split('.').join('. '))
      : capitalizeFirstLetter(service.name);

    let moreInfoProps = undefined;
    if (service.about && service.about.description) {
      moreInfoProps = {
        title: service.name,
        info: service.about.description,
      };
      if (this.props.readMoreButton) {
        moreInfoProps.button = this.props.readMoreButton;
      }
    }

    return (
      <div className="mt-1 flex flex-col">
        <span className="mb-1 block">{name}</span>
        <div className="flex flex-col sm:flex-row">
          <span className="text-black-600 mb-1 block text-sm">
            {serviceDuration}
            {price && serviceDuration && ', '}
            {price && <span className="text-inherit">{price}</span>}
          </span>
          {!isMobile() && moreInfoProps && (price || serviceDuration) && <span>&nbsp;&middot;&nbsp;</span>}
          {moreInfoProps && <ReadMoreModal className="text-sm" modalProps={{ ...moreInfoProps }} />}
        </div>
      </div>
    );
  }
}
