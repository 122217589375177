import { _s } from '@/locale';
import { AppointmentActionIdentifier, AppointmentActionItem } from '@/types/appointment';

export const APPOINTMENT_STATUS_ACTIVE = '1';
export const APPOINTMENT_STATUS_CANCELLED = '2';

export type AppointmentStatus = typeof APPOINTMENT_STATUS_ACTIVE | typeof APPOINTMENT_STATUS_CANCELLED;

export const statuses = {
  0: { className: 'awaiting-confirmation', label: _s('awaitingConfirmation') },
  100: { className: 'canceled', label: _s('notConfirmed') },
  1: { className: 'confirmed', label: _s('booked') },
  101: { className: 'approved', label: _s('confirmed') },
  2: { className: 'canceled', label: _s('canceled') },
  102: { className: 'canceled', label: _s('canceled') },
  3: { className: 'no-show', label: _s('notShow') },
  103: { className: 'no-show', label: _s('notShow') },
} as const;

export const APPOINTMENT_STATUS = {
  CONFIRMED: 1,
  COMPLETED: 101,
  CANCELED: 2,
};

export const APPOINTMENT_PAYMENT_METHOD = {
  PAY_AT_PLACE: 0,
  KLARNA: 1,
  QLIRO: 3,
  COF: 4,
  GOOGLE_PAY: 6,
  APPLE_PAY: 7,
  SWISH: 8,
} as const;

const baseTranslationKey = 'constants.appointment';

export const PAY_ONLINE_ACTION_ITEM: AppointmentActionItem = {
  variant: 'primary',
  icon: 'pre-pay',
  label: _s(`${baseTranslationKey}.PAY_ONLINE_ACTION_ITEM.label`),
  identifier: AppointmentActionIdentifier.PAY_ONLINE,
};

export const CHANGE_BOOKING_TIME_ACTION_ITEM: AppointmentActionItem = {
  variant: 'primary',
  icon: 'clock',
  label: _s(`${baseTranslationKey}.CHANGE_BOOKING_TIME_ACTION_ITEM.label`),
  identifier: AppointmentActionIdentifier.CHANGE_BOOKING_TIME,
};

export const ADD_TO_CALENDAR_ACTION_ITEM: AppointmentActionItem = {
  variant: 'secondary',
  icon: 'calendar-plus',
  label: _s(`${baseTranslationKey}.ADD_TO_CALENDAR_ACTION_ITEM.label`),
  identifier: AppointmentActionIdentifier.ADD_TO_CALENDAR,
};

export const BOOK_AGAIN_ACTION_ITEM: AppointmentActionItem = {
  variant: 'secondary',
  icon: 'calendar-plus',
  label: _s(`${baseTranslationKey}.BOOK_AGAIN_ACTION_ITEM.label`),
  identifier: AppointmentActionIdentifier.BOOK_AGAIN,
};

export const CANCEL_BOOKING_ACTION_ITEM: AppointmentActionItem = {
  variant: 'link',
  icon: 'cancel',
  iconColor: 'information-700',
  label: _s(`${baseTranslationKey}.CANCEL_BOOKING_ACTION_ITEM.label`),
  identifier: AppointmentActionIdentifier.CANCEL_BOOKING,
};
