import { server } from '@/helpers';
import { LandingPageArchive } from '@/types/landingPages';
import { Dispatch, useEffect, useState } from 'react';

export const useLandingPageArchive = (ssrArchive: LandingPageArchive) => {
  const [archive, setArchive] = useState<LandingPageArchive>(ssrArchive ? ssrArchive : null);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (ssrArchive) return;
    fetchLandingPages(setError, setArchive);
  }, []);

  return { archive, error };
};

const fetchLandingPages = async (setError: Dispatch<any>, setArchive: Dispatch<LandingPageArchive>) => {
  const res = await server.request.get(`/landing-pages/list`).then(server.handleSuccess).catch(server.handleError);

  if ('code' in res) return setError(res);

  setArchive(res);
};
