import { Button } from '@/components/elements/forms/buttons';
import { _s } from '@/locale';
import Modal from './redesign/Modal/Modal';

const baseTranslation = 'components.modules.modals.SwishModal';

const SwishModal = ({ onClose, qrCode }: { onClose: () => void; qrCode: string }) => {
  return (
    <Modal isOpen>
      <Modal.Content floating>
        <Modal.Header title={_s(`${baseTranslation}.title`)} />
        <div className="gap-xxl flex flex-col">
          <div className="p-xxl bg-swish-gradient flex justify-center rounded-lg">
            <div className="p-xxl inline-flex rounded-lg bg-white">
              <img src={qrCode} alt="swish-qrcode" style={{ width: '125px' }} />
            </div>
          </div>
          <div className="flex justify-center">
            <img src="/images/logos/swish-logo.svg" alt="swish-logo" />
          </div>
          <div className="text-center">
            <p>{_s(`${baseTranslation}.body`)}</p>
          </div>
          <div className="text-center">
            <Button label={_s(`${baseTranslation}.close`)} variant="link" onClick={onClose} />
          </div>
        </div>
      </Modal.Content>
    </Modal>
  );
};

export default SwishModal;
