import { Button } from '@/components/elements/forms/buttons';
import Header from '@/components/elements/Header/Header';
import { NpsContextType } from '@/hooks/useNps';
import { _s } from '@/locale';

type NpsCommentProps = Pick<NpsContextType, 'nps' | 'updateView' | 'handleCommentSubmit' | 'updateComment'> & {};

const translationKey = 'npsModal.step2';
const charsLimit = 1000;

const NpsComment = ({ nps, updateView, handleCommentSubmit, updateComment }: NpsCommentProps) => {
  let scoreBasedTranslationKey = 'Passive';
  if (nps.score <= 6) scoreBasedTranslationKey = 'Detractor';
  else if (nps.score > 8) scoreBasedTranslationKey = 'Promoter';

  return (
    <>
      <Header
        className="pt-xl sm:pt-lg"
        size="xl"
        label={_s(`${translationKey}.subtitle${scoreBasedTranslationKey}`)}
      />
      <Header
        size="md"
        className="text-black-600 font-normal"
        label={_s(`${translationKey}.question${scoreBasedTranslationKey}`)}
      />
      <textarea
        className={`border-black-200 rounded-xs placeholder:text-black-600 text-black-900 p-sm min-h-[160px] w-full resize-none border-[1px] text-sm outline-none placeholder:italic sm:min-h-[89px]`}
        placeholder={_s(`${translationKey}.inputPlaceholder`)}
        maxLength={charsLimit}
        value={nps.comment}
        onChange={(e) => updateComment(e.target.value)}
      />
      <div className="mb-sm flex justify-end">
        <button className="mr-4 inline text-[#336CA8] underline" onClick={() => updateView('step1')}>
          {_s(`${translationKey}.cta.back`)}
        </button>
        <Button
          disabled={!nps.comment || nps.error || nps.submitting}
          variant="primary"
          size="sm"
          onClick={handleCommentSubmit}>
          {_s(`${translationKey}.cta.save`)}
        </Button>
      </div>
    </>
  );
};

export default NpsComment;
