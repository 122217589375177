import { LinkButton } from '@/components/elements/forms/buttons';
import { trackMpEvent, url as urlHelper } from '@/helpers';
import { LPCTAButtonField } from '@/types/landingPages';

type CTAButtonProps = LPCTAButtonField & {
  landingPageID: number;
};

const trackCTAButtonClicked = () => {
  trackMpEvent('search_treatment_today_click', {
    screen_name: urlHelper.getScreenNameFromUrl(window.location.href),
  });
};

const CTAButton = ({ url, label, landingPageID }: CTAButtonProps) => (
  <LinkButton variant="primary" to={url} onClick={trackCTAButtonClicked}>
    {label}
  </LinkButton>
);

export default CTAButton;
