import { capitalizeFirstLetter } from '@/helpers';
import { __ } from '@/locale';
import Parent from './Parent';

class TextInput extends Parent {
  constructor(props) {
    super(props);
    this.state = { changed: false };
  }

  handleChange = (e) => {
    this.props.changeInput(e);
    this.setState({ changed: true });
  };

  handleBlur = (e) => {
    this.setState({ changed: true });
  };

  render() {
    const { data, submitted } = this.props;
    if (!data.visible) {
      return null;
    }

    const placeholder = data.label ? data.label : __(capitalizeFirstLetter(data.id));
    const hasError =
      (submitted || this.state.changed || data.value) && this.props.getError && this.props.getError(data.id);
    const autocomplete = data.id !== 'discountCode' ? 'on' : 'off';
    return (
      <div className={'form-group field-' + data.id + (hasError ? ' has-error' : '')}>
        <div className="field">
          <label htmlFor={data.id}>{`${data.required ? '* ' : ''}${capitalizeFirstLetter(placeholder)}`}</label>
          <input
            type="text"
            placeholder={`${__('Enter')} ${placeholder}`}
            autoComplete={autocomplete}
            name={data.id}
            className={`form-control field-${data.id} ${hasError ? 'border-danger focus:border-danger' : ''}`}
            value={data.value || ''}
            onChange={this.handleChange}
            onBlur={this.handleBlur}
          />
          {hasError && <div className="text-danger text-m">{__(hasError)}</div>}
        </div>
      </div>
    );
  }
}

export default TextInput;
