import { IStatusMessage, StatusMessage } from '@/components/elements/StatusMessage';
import { CloseIcon } from '@/components/icons';
import { useAlerts, useRemoveAlert } from '@/hooks/useAlert';
import styles from './Alert.module.scss';

export interface IAlert extends IStatusMessage {
  id?: string;
  autoClose?: boolean;
  notClose?: boolean;
  autoCloseTimer?: number;
}

export const Alert: React.FC<IAlert> = ({ id, type = 'default', title, message, link, notClose }) => {
  const alerts = useAlerts();
  const removeAlert = useRemoveAlert();
  return (
    <div className={styles.alert}>
      <StatusMessage type={type} title={title} message={message} icon={true} link={link}>
        {alerts?.length > 1 && <span className={`${styles.count}`} data-alert-count={`+${alerts?.length}`}></span>}
        {!notClose && <button
          className="text-black-900 active:bg-black-300 flex h-7 w-7 items-center justify-center rounded-full outline-none duration-75"
          onClick={() => removeAlert(id)}>
          <CloseIcon className="h-3 w-3" />
        </button>}
      </StatusMessage>
    </div>
  );
};
