import { useRef, useState } from 'react';
import css from './BorderLabelTextInput.module.scss';

type Props = {
  label: string;
  name: string;
  error?: string;
  placeholder?: string;
  format?: (value: any) => any;
  value: any;
  setValue: (value: any) => any;
  onFocus?: () => void;
  onBlur?: () => void;
  onChange?: (value: any) => void;
  half?: boolean;
  className?: string;
  disabled?: boolean;
};
const BorderLabelTextInput = ({
  label,
  name,
  error,
  placeholder,
  value,
  setValue,
  format = (value) => value,
  onFocus = null,
  onBlur = null,
  onChange = null,
  half = false,
  className = '',
  disabled = false,
}: Props) => {
  const [active, setActive] = useState(false);
  const inputRef = useRef(null);

  const onChangeCombined = () => {
    if (onChange) onChange(format(inputRef.current?.value));
    setValue(format(inputRef.current.value));
  };

  const onBlurCombined = () => {
    setActive(false);
    if (onBlur) onBlur();
  };

  const onFocusCombined = () => {
    setActive(true);
    focus();
    if (onFocus) onFocus();
  };

  const focus = () => {
    if (!active) {
      inputRef.current.focus();
    }
  };

  return (
    <div className={`${css.fieldContainer} ${className} ${half ? css.half : css.full}`}>
      <div className={`${css.field} `}>
        <label className={`${css.label} ${active || value ? css.active : ''}`} htmlFor={name} onClick={focus}>
          {label}
        </label>
        <input
          type="text"
          className={`form-control color-gray-900 padded-input ${value ? css.hasValue : ''} ${error ? css.error : ''} ${
            active ? css.active : ''
          }`}
          onFocus={onFocusCombined}
          onBlur={onBlurCombined}
          ref={inputRef}
          value={value}
          placeholder={placeholder}
          onChange={onChangeCombined}
          onClick={focus}
          disabled={disabled}
        />
      </div>
      {error && <span className={css.error}>{error}</span>}
    </div>
  );
};

export default BorderLabelTextInput;
