import { BlogLayout } from '@/components/layouts/blog';
import { ArchiveCategoryNav, BackButton, CategoryArchive, Heading } from '@/components/modules/pages/blog';
import useScrollToTop from '@/hooks/useScrollToTop';

const CategoryArchivePage = () => {
  useScrollToTop();
  return (
    <BlogLayout>
      <div className="container mt-10">
        <BackButton />
        <Heading />
        <ArchiveCategoryNav />
        <CategoryArchive />
      </div>
    </BlogLayout>
  );
};

export default CategoryArchivePage;
