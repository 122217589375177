import { LinkButton } from '@/components/elements/forms/buttons';
import { LoadingIcon } from '@/components/icons';
import Icon from '@/components/icons/Icon';
import PageViewLayout from '@/components/layouts/PageViewLayout/PageViewLayout';
import SEO from '@/components/modules/SEO';
import { APP_ROUTES } from '@/constants/pages';
import { url } from '@/helpers';
import useMobileView from '@/hooks/useMobileView';
import useScrollToTop from '@/hooks/useScrollToTop';
import { _s } from '@/locale';
import Markdown from 'markdown-to-jsx';
import { connect } from 'react-redux';
import { Redirect, useParams } from 'react-router-dom';
import { MarkdownContentWrapper, markdownOverrides } from './$slug.helpers';
import { useAssociationPage } from './$slug.hooks';
import AppPromotion from './shared/AppPromotion';
import Breadcrumbs from './shared/Breadcrumbs';
import Stats from './shared/Stats';

const baseTranslationKey = 'pages.bransch-organisationer.$slug.AssociationsLanding';

const AssociationsLanding = ({ associationPage: ssrPage }) => {
  const { slugID } = useParams();
  const { page, error, loading } = useAssociationPage(ssrPage, slugID);
  const { isMobileView } = useMobileView();

  useScrollToTop();

  if (error) {
    return <Redirect to="/404" />;
  }

  if (loading || !page) {
    return (
      <PageViewLayout type="mainView" title={page?.name || slugID} wrapperClass="bg-gradient" hideBottomNavBar>
        <LoadingIcon className="mx-auto h-8 w-8" />
      </PageViewLayout>
    );
  }

  return (
    <PageViewLayout
      type={isMobileView ? 'subView' : 'mainView'}
      back={isMobileView}
      title={page.name}
      wrapperClass="bg-gradient">
      <div>
        <div className="space-y-7xl md:space-y-huge pb-7xl container max-w-[800px]">
          {/* Hero */}
          <div className="pt-lg md:py-5xl">
            <div className="pb-lg">
              <Breadcrumbs title={page.name} />
            </div>
            <div className="gap-xxl grid place-items-center md:grid-cols-2">
              <div className="space-y-xl md:pr-lg flex-1">
                <div className="space-y-md">
                  <h1 className="text-2xl font-semibold">{page.name}</h1>
                  <p className="text-black-800 text-md">{page.summary}</p>
                </div>
              </div>
              {/* Association Image */}
              <div className="bg-highlight-50 grid h-[240px] w-full place-items-center rounded-lg md:w-[390px] md:flex-1">
                <img src={`/images/associations-${page.m3Id}.png`} alt="" width={128} height={128} />
              </div>
            </div>
          </div>
          <div className="space-y-md">
            <div className="text-black-600 text-md space-y-md">
              <Markdown options={{ wrapper: MarkdownContentWrapper, overrides: markdownOverrides('') }}>
                {page?.content}
              </Markdown>
            </div>
          </div>

          {page.readMoreUrl && (
            <LinkButton
              to={page.readMoreUrl}
              variant="primary"
              label={_s(`${baseTranslationKey}.readMoreUrl`)}
              size="md"
              rightIcon={<Icon color="white" variant="arrow-right" />}
            />
          )}

          <Stats />
          <AppPromotion />
        </div>
      </div>
      <SEO
        {...{ description: '', title: page.name }}
        url={`${url.getBaseUrl()}${APP_ROUTES.ASSOCIATION_PAGES}/${slugID}`}
      />
    </PageViewLayout>
  );
};

const mapStateToProps = ({ associationPage }) => ({ associationPage });

export default connect(mapStateToProps)(AssociationsLanding);
