import { Card } from '@/components/elements/cards';
import { supportFaq } from '@/constants/faq';
import { classnames, isServer } from '@/helpers';
import { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

type Props = { title?: string };

const Navigation = ({ title }: Props) => {
  const location = useLocation();
  const selectedSection = supportFaq.find((section) => location.pathname === section.path);

  useEffect(() => {
    if (isServer) return;
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <Card style={{ marginTop: 0 }} className="flex flex-col bg-white">
      {title && <h3 className="splitter lg:text-2xl">{title}</h3>}
      {supportFaq.map((section, key) => {
        const selected = section.path === (selectedSection || {}).path;
        return (
          <Link
            to={section.path}
            key={key}
            className={classnames(
              `${
                key < supportFaq.length - 1 ? 'splitter' : ''
              } text-black-900 lg:text-h-m flex h-10 items-center justify-start gap-x-4 pl-4 text-base lg:h-12`,
              selected ? 'font-semibold' : '',
            )}>
            <section.icon className="h-4" />
            {section.title}
          </Link>
        );
      })}
    </Card>
  );
};

export default Navigation;
