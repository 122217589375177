import { roundRating } from '@/helpers';
import { _s } from '@/locale';
import { SearchPlace } from '@/types/api/services/search';
import { Place } from '@/types/state/shared';
import * as sanitizeHtml from 'sanitize-html';
import {
  AggregateRating,
  BreadcrumbList,
  GeoCoordinates,
  LocalBusiness,
  PostalAddress,
  Review,
  SearchResultsPage,
  Thing,
  WithContext,
} from 'schema-dts';

const stripHTML = (html: string): string => {
  return sanitizeHtml(html, { allowedTags: [], allowedAttributes: {} });
};

function jsonLd<T extends Thing>(json: WithContext<T>): string {
  return JSON.stringify(json);
}

function buildGeo(position: Place['contact']['position']): GeoCoordinates {
  if (!position?.lat && !position?.lon) {
    return undefined;
  }

  return {
    '@type': 'GeoCoordinates',
    latitude: position.lat,
    longitude: position.lon,
  };
}

function buildPostalAddress(address: Place['contact']['address']): PostalAddress {
  const schema: PostalAddress = {
    '@type': 'PostalAddress',
  };

  if (address.street) {
    schema.streetAddress = address.street;
  }

  if (address.zipcode) {
    schema.postalCode = address.zipcode;
  }

  if (address.city) {
    schema.addressLocality = address.city;
  }

  return schema;
}

function buildAggregateRating(reviews: Place['reviews']): AggregateRating {
  const { reviewCount = 0 } = reviews || {};

  if (reviewCount < 1) {
    return undefined;
  }

  const schema: AggregateRating = {
    '@type': 'AggregateRating',
    reviewCount,
    bestRating: 5,
    worstRating: 1,
  };

  if (reviews?.stats?.score) {
    schema.ratingValue = roundRating(reviews.stats.score);
  }

  return schema;
}

function buildReview(reviews: Place['reviews']): Review[] {
  const schema: Review[] = [];

  if (!reviews?.topReviews?.items) {
    return schema;
  }

  reviews.topReviews.items.forEach((item) => {
    const review: Review = {
      '@type': 'Review',
      author: {
        '@type': 'Person',
        name: item.author?.name || _s('Anonymous'),
      },
      publisher: {
        '@type': 'Organization',
        name: 'Bokadirekt',
        url: 'https://www.bokadirekt.se',
      },
      datePublished: item.createdAt,
      reviewBody: item.review?.text || '',
      reviewRating: {
        '@type': 'Rating',
        ratingValue: item.review?.score || 0,
        bestRating: 5,
        worstRating: 1,
      },
    };

    schema.push(review);
  });

  return schema;
}

function buildLocalBusinessSchema(place: Place) {
  const { address = {}, phone, email, website, position = {} } = place.contact || {};
  const { reviews = {}, about = {} } = place || {};
  const geo = buildGeo(position);
  const review = buildReview(reviews);

  const schema: LocalBusiness = {
    '@type': 'LocalBusiness',
    name: about.name,
    address: buildPostalAddress(address),
    aggregateRating: buildAggregateRating(reviews),
  };

  if (review.length) {
    schema.review = review;
  }

  if (geo) {
    schema.geo = geo;
  }

  if (website) {
    schema.url = website;
  }

  if (email) {
    schema.email = email;
  }

  if (phone) {
    schema.telephone = phone;
  }

  if (about?.description) {
    const description = stripHTML(about.description);
    if (description) {
      schema.description = description;
    }
  }

  return schema;
}

function searchPlaceAdapter(place: SearchPlace): Place {
  const out: Place = {
    about: { name: place.name },
    contact: place.contact,
    reviews: {
      reviewCount: place?.rating?.count,
      stats: { score: place?.rating?.score },
      topReviews: undefined,
    },
  };

  return out;
}

function buildSearchResultsPageSchema(places: SearchPlace[]) {
  const schema: SearchResultsPage = {
    '@type': 'SearchResultsPage',
    name: 'Local Business Search Results',
    about: 'Search results for local businesses in your area',
    mainEntity: {
      '@type': 'ItemList',
      itemListElement: places.map((place, index) => ({
        '@type': 'ListItem',
        position: index + 1,
        item: buildLocalBusinessSchema(searchPlaceAdapter(place)),
      })),
    },
  };

  return schema;
}

export function getBreadcrumbsSchema(items: { title: string; url: string }[]) {
  const schema: BreadcrumbList = {
    '@type': 'BreadcrumbList',
    itemListElement: items.map((item, index) => ({
      '@type': 'ListItem',
      position: index + 1,
      name: item.title,
      item: item.url,
    })),
  };

  return jsonLd({
    '@context': 'https://schema.org',
    ...schema,
  });
}

export function getSearchResultsPageSchema(places: SearchPlace[]) {
  const schema = buildSearchResultsPageSchema(places);

  return jsonLd({
    '@context': 'https://schema.org',
    ...schema,
  });
}

export function getLocalBusinessSchema(place: Place) {
  return jsonLd({
    '@context': 'https://schema.org',
    ...buildLocalBusinessSchema(place),
  });
}
