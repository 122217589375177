import Snackbar from '@/components/elements/notifications/Snackbar/Snackbar';
import { _s } from '@/locale';
import { createKlarnaMerchantOrder } from '@/services/klarnaServices';
import { InitKlarnaAfterBookingResponse } from '@/types/api/services/booking';
import { KPAuthorizationResponse } from '@/types/klarnaPayments';
import { Dispatch, useState } from 'react';
import { toast } from 'react-toastify';

type Status = 'SUBMITTING' | 'ERROR' | 'INITIAL';

type SubmitKlarnaAfterBookingContext = {
  klarnaOrder: InitKlarnaAfterBookingResponse['order'];
  employeeId: number;
  successCallback: ({ redirectURL }: { redirectURL: string }) => void;
  errorCallback: () => void;
};

type KlarnaBookingCheckoutAPI = {
  onSubmitKlarna: (res: KPAuthorizationResponse) => Promise<void>;
};

type KlarnaBookingCheckoutManager = {
  klarnaBookingCheckoutAPI: () => KlarnaBookingCheckoutAPI;
  status: Status;
};

function handleOnSubmitKlarna(context: SubmitKlarnaAfterBookingContext, dispatch: Dispatch<Status>) {
  return async (res: KPAuthorizationResponse) => {
    dispatch('SUBMITTING');
    const createRes = await createKlarnaMerchantOrder(
      context.klarnaOrder.sessionID,
      res.authorization_token,
      `${context.employeeId}`,
    );

    if (!createRes.redirectURL) {
      context.errorCallback();
      return;
    }
    context.successCallback({ redirectURL: createRes.redirectURL });
  };
}

const usePrePayKlarnaCheckoutManager = ({
  order,
  employeeId,
}: {
  order: InitKlarnaAfterBookingResponse['order'];
  employeeId: number;
}): KlarnaBookingCheckoutManager => {
  const [status, setStatus] = useState<Status>('INITIAL');

  const handleKlarnaError = () => {
    setStatus('ERROR');
    toast(({ closeToast }) => <Snackbar label={_s('serverError')} type="danger" onClose={closeToast} />, {
      autoClose: false,
    });
  };

  const handleKlarnaSuccess = ({ redirectURL }) => {
    window.location.href = redirectURL;
  };

  const klarnaBookingCheckoutAPI = (): KlarnaBookingCheckoutAPI => {
    const errorCallback = handleKlarnaError;
    const successCallback = handleKlarnaSuccess;
    const context = {
      klarnaOrder: order,
      employeeId,
      errorCallback,
      successCallback,
    };

    return {
      onSubmitKlarna: handleOnSubmitKlarna(context, setStatus),
    };
  };

  return {
    klarnaBookingCheckoutAPI,
    status,
  };
};

export default usePrePayKlarnaCheckoutManager;
