import { _s } from '@/locale';
import { CheckoutError } from '@/types/adyen';
import { CheckoutSessionPaymentResponse } from '@adyen/adyen-web/dist/types/types';

export const checkPaymentConfirmed = (paymentResponse: CheckoutSessionPaymentResponse) => {
  switch (paymentResponse.resultCode) {
    case 'Authorised':
    case 'Pending':
    case 'Received':
      return true;
    default:
      return false;
  }
};

export const getCheckoutClientErrorMessage = (error: CheckoutError) => {
  switch (error) {
    case 'PaymentError':
    case 'MountError':
    case 'NotAvailableError':
    case 'StoreTokenError':
      return _s(`checkoutError.${error}`);
    default:
      const never: never = error;
      throw new Error(`Unhandled error type: ${never}`);
  }
};

export function getThreeDSecureRedirectResultIfAny(locationState: any, history: any): boolean | undefined {
  const queryParams = new URLSearchParams(locationState?.search);
  const redirectResult = queryParams.has('threeDSValidateSuccess')
    ? queryParams.get('threeDSValidateSuccess')
    : undefined;
  queryParams.delete('threeDSValidateSuccess');
  history?.replace?.({ search: queryParams.toString(), state: locationState?.state });
  if (redirectResult === undefined) return undefined;
  return redirectResult === 'true';
}

export function getThreeDSecureRedirectedIfAny(locationState: any): string | false {
  const queryParams = new URLSearchParams(locationState?.search);
  const redirectResult = queryParams.has('redirectResult') ? queryParams.get('redirectResult') : null;
  queryParams.delete('redirectResult');
  return redirectResult ?? false;
}
