import { Button } from '@/components/elements/forms/buttons';
import { ListItem } from '@/components/elements/lists';
import { ChevronIcon, CloseIconThin, TrashIcon } from '@/components/icons';
import Icon from '@/components/icons/Icon';
import PageViewLayout from '@/components/layouts/PageViewLayout/PageViewLayout';
import { ReviewCard } from '@/components/modules/review';
import { history, url, roundRating, ratingText } from '@/helpers';
import { _s } from '@/locale';
import { supportServices } from '@/services';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';

type Review = {
  text: string;
  score: number;
};

type Target = {
  employeeId: string;
  transactionId: string;
};

type ReviewItem = {
  id: number;
  userId: string;
  userName: string;
  salonName: string;
  score: number;
  reply: any;
  review?: Review;
  target?: Target;
  createdAt: string;
};

const SupportReviews = () => {
  const [formData, setFormData] = useState<any>({ value: url.returnGetParameter('value') || '' });
  const [pagination, setPagination] = useState<any>(url.returnGetParameter('page') || 1);
  const [withComments, setWithComments] = useState<any>(url.returnGetParameter('withComments'));
  const [list, setFormList] = useState<ReviewItem[]>([]);
  const [isLastPage, setIsLastPage] = useState(true);
  const [stars, setStars] = useState(null);
  const [stats, setStats] = useState(null);

  const searchReviews = (resetPagination) => {
    if (resetPagination) {
      setPagination(1);
      setIsLastPage(true);
    }
    
    supportServices.getReviews(window.location.search).then((data) => {
      const { reviews } = data;
      if (reviews && reviews.items) {
        setFormList(reviews.items);
      }
      
      if (reviews && reviews.nextPage) {
        const currPage = reviews.nextPage.page - 1; // curr page
        setPagination(currPage);
        setIsLastPage(false);
      } else {
        setIsLastPage(true);
      }

      setStars(reviews?.stars);
      setStats(reviews?.stats); 
    });
  };

  useEffect(() => {
    searchReviews(0);
  }, []);

  return (
    <PageViewLayout type="mainView" title={'Reviews'}>
      <div className="container">
        <div className={`gap-xxs flex w-full flex-col p-5`}>
          <label htmlFor="search-input" className="text-md text-black-900">
            Search company reviews
          </label>
          <div className="flex w-full">
            <div className="border-black-200 gap-sm relative w-full flex rounded-l-lg border bg-white pl-3">
              <Icon variant="search" size="sm" className="mt-3" />
              <input
                id="search-input"
                placeholder="enter company id"
                className="w-full"
                value={formData?.value || ''}
                onChange={(e) => {
                  setFormData({ value: e.target.value });
                  history.push({ search: url.changeGetParameter('value', e.target.value) });
                }}
                onKeyDown={(e)=>{
                  if (e.key === 'Enter') {
                    history.push({ search: url.changeGetParameter('page', 0) });
                    searchReviews(1);
                  }
                }}
              />
              {!!formData?.value && (
                <button
                  onClick={() => {
                    setFormData({ value: '' });
                    history.push({ search: url.changeGetParameter('value', '') });
                    history.push({ search: url.changeGetParameter('page', 0) });
                  }}
                  className="min-h-[24px] min-w-[24px] outline-none">
                  <Icon size="sm" variant="close-circle" />
                </button>
              )}
            </div>
            <Button
                disabled={!formData?.value}
                size="lg"
                className="!h-[52px] !w-[74px] !rounded-bl-none !rounded-tl-none sm:!w-28 rounded-r-lg"
                onMouseDown={() => {
                  history.push({ search: url.changeGetParameter('page', 0) });
                  searchReviews(1);
                }}>
                {_s('search')}
            </Button>
          </div>
          <div className="py-4">
            <input
              type="checkbox"
              checked={withComments}
              onChange={(e) => {
                history.push({ search: url.changeGetParameter('withComments', e.target.checked ? 1 : 0) });
                setWithComments(e.target.checked ? 1 : 0);
                searchReviews(1);
              }}
            />{' '}
            Only with comments
          </div>
          <ul>
            <li>
              <strong>Legend: </strong>
            </li>
            <li>
              <strong>By company:</strong> itsp_[companyId] | bd_[companyId] eg: itsp_100
            </li>
            <li>
              <strong>By email:</strong> test@bokadirekt.se
            </li>
            <li>
              <strong>By MP salonId:</strong> 35737. get the id from MP url:
              https://www.bokadirekt.se/places/bokadirekt-studio-demo-obs-ingen-riktig-salong-35737
            </li>
          </ul>
        </div>
        {!!list && !!list.length ? (
          <div>
            {stats && stats.score && stars && (
            <div className="gap-xxs flex p-lg justify-end">
              <div>
                <span className="text-black-700 text-6xl font-semibold">{roundRating(stats.score, 1)}</span>
                <span className="text-black-700 block font-semibold">{ratingText(roundRating(stats.score, 1))}</span>
              </div>
              <div className="flex flex-col pr-lg">
                {Object.keys(stars)
                  .sort((a, b) => Number(b) - Number(a))
                  .map((key, i) => (
                    <div key={i} className="mt-[2px] flex">
                      <div className="flex-1 md:min-w-[150px] text-right text-black-600 text-sm">
                        <span>{stars[key]}</span> {_s('ratings')}{' '}
                      </div>
                      <div className="mr-3 flex w-[50px] md:w-[90px] px-3">
                        {[...Array(parseInt(key, 10))].map((e, j) => (
                          <img key={j} src="/images/review-stats-star.svg" className="mx-[2px]" width="7" height="7" alt="" />
                        ))}
                      </div>
                    </div>
                ))}
              </div>
            </div>
            )}
            {list.map((item, key) => (
              <ListItem
                key={key}
                className="relative"
                rightSlot={
                  <div className="absolute right-[50px] top-[20px] flex-column">
                    <button
                      className="bg-primary-500 !outline-primary-500 overflow-hidden flex flex-row rounded-full p-2 mb-2 duration-200 group-focus-within:block group-hover:block w-full"
                      onClick={() => {
                        supportServices.deleteReview(item.id).then((response) => {});
                      }}
                      aria-label={_s('removedFrom') + ' ' + _s('reviews')}>
                      <div className="!outline-primary-500 h-8 w-8 overflow-hidden rounded-full p-2 duration-200 group-focus-within:block group-hover:block">
                        <CloseIconThin className="relative z-10 h-full w-full text-white" />
                      </div>
                      <span className="p-1 pl-0 pr-3 font-bold text-white">DELETE REVIEW</span>
                    </button>
                    
                    {item.review.text && (
                    <button
                      className="bg-primary-500 !outline-primary-500 overflow-hidden flex flex-row rounded-full mb-2 p-2 duration-200 group-focus-within:block group-hover:block w-full"
                      onClick={() => {
                        supportServices.deleteReviewText(item.id).then((response) => {
                          searchReviews(0);
                        });
                      }}
                      aria-label={_s('reviews.removecomment.label')}
                      >
                      <div className="!outline-primary-500 h-8 w-8 overflow-hidden rounded-full p-2 duration-200 group-focus-within:block group-hover:block">
                        <TrashIcon className="relative z-10 h-full w-full text-white" />
                      </div>
                      <span className="p-1 pl-0 pr-3 font-bold text-white">REMOVE TEXT</span>
                    </button>
                    )}
                  </div>
                }>
                <ReviewCard
                  showPlainDate={true}
                  showCompany={true}
                  review={{
                    author: {
                      name: (item.userName || _s('Anonymous')) + ' - ' + item.userId,
                    },
                    companyName: item.salonName,
                    createdAt: item.createdAt,
                    review: item.review,
                    subject: {
                      employee: {
                        name: item.target.employeeId,
                      },
                    },
                    reply: item.reply,
                  }}
                  key={key}
                  full
                  showReplies={true}
                />
              </ListItem>
            ))}
            <nav className="m-6">
              <ul className="flex list-none items-center rounded">
                <li>
                  <button disabled={pagination <= 1}
                    className={`border-black-200 flex h-8 w-9 items-center justify-center rounded border-[1px] outline-none ${
                      pagination > 1 ? 'bg-white shadow' : 'bg-black-200'
                    }`}
                    onClick={() => {
                      setPagination(pagination - 1);
                      history.push({ search: url.changeGetParameter('page', pagination - 1) });
                      searchReviews(0);
                      window.scrollTo(0, 0);
                    }}>
                    <ChevronIcon
                      className={`h-4 w-4 rotate-90 ${pagination > 1 ? 'text-black-900' : 'text-black-400'}`}
                    />
                  </button>
                </li>
                <li><div className="h-8 px-5 items-center flex">PAGE: {pagination}</div></li>
                <li>
                  <button disabled={isLastPage}
                    className={`border-black-200 flex h-8 w-9 items-center justify-center rounded border-[1px] outline-none ${
                      !isLastPage ? 'bg-white shadow' : 'bg-black-200'
                    }`}
                    onClick={() => {
                      setPagination(pagination + 1);
                      history.push({ search: url.changeGetParameter('page', pagination + 1) });
                      searchReviews(0);
                      window.scrollTo(0, 0);
                    }}>
                    <ChevronIcon 
                      className={`h-4 w-4 -rotate-90 ${isLastPage ? 'text-black-400' : 'text-black-900'}`} 
                    />
                  </button>
                </li>
              </ul>
            </nav>
          </div>
        ) : (
          <div>
            <ListItem>
              <div className="bg-black-50 block w-full rounded-lg p-6 mb-6 flex gap-sm">
                <span className="py-1">No reviews found</span> 
              </div>
            </ListItem>
          </div>
        )}
      </div>
    </PageViewLayout>
  );
};

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps)(SupportReviews);
