import colors from '@/colors';
import Countdown from '@/components/elements/Countdown/Countdown';
import { classnames } from '@/helpers';
import '@/styles/modules/banner.scss';
import Markdown from 'markdown-to-jsx';
import { useEffect, useState } from 'react';
import { Button } from '../../forms/buttons';
import { BannerLinkWrapper, getBannerStyles, markdownOverrides } from '../helpers';
import { BannerConfig } from '../types';

type BannerType5Props = {
  position?: string; // only used for analytics
} & Omit<BannerConfig, 'type' | 'active'>;

const BannerType5 = ({
  title = '',
  message = '',
  background_color,
  text_color,
  text_link_color = colors.information[700],
  cta,
  countdownData,
  background_image_lg,
  background_image_md,
  background_image_sm,
  background_alt_text,
  position,
}: BannerType5Props) => {
  const [isCountdownActive, setIsCountdownActive] = useState(true);
  const hasBackgroundImage = background_image_sm && background_image_md && background_image_lg;
  const styles = {
    ...getBannerStyles(background_color, text_color),
  } as React.CSSProperties;

  useEffect(() => setIsCountdownActive(true), [countdownData?.endDate]);

  if (!isCountdownActive || !countdownData?.endDate) return null;

  return (
    <BannerLinkWrapper cta={cta} type="type_5" position={position}>
      <div
        className={classnames('py-xxl px-lg pointer-events-none relative flex flex-col overflow-hidden')}
        style={styles}>
        {hasBackgroundImage && (
          <div className="absolute inset-0">
            <picture>
              <source media="(max-width: 340px)" srcSet={background_image_sm} />
              <source media="(max-width: 767px)" srcSet={background_image_md} />
              <source media="(min-width: 767px)" srcSet={background_image_lg} />
              <img
                className="h-full max-h-full w-full object-cover"
                src={background_image_sm}
                alt={background_alt_text ?? ''}
              />
            </picture>
          </div>
        )}
        <div className="space-y-lg relative flex flex-col md:items-center md:text-center">
          <Markdown options={{ overrides: markdownOverrides(text_link_color) }} key={4}>
            {title}
          </Markdown>

          <Countdown
            type="large"
            handleCountdownComplete={() => setIsCountdownActive(false)}
            endDate={countdownData.endDate}
            countdownColor={countdownData.countdown_color}
            labelColor={countdownData.countdown_description_color}
          />

          <Markdown options={{ overrides: markdownOverrides(text_link_color) }} key={3}>
            {message}
          </Markdown>

          {cta && (
            <div className="pointer-events-auto mt-auto">
              <Button variant={cta.variant || 'primary'} label={cta.title} size="md" />
            </div>
          )}
        </div>
      </div>
    </BannerLinkWrapper>
  );
};

export default BannerType5;
