import { Button } from '@/components/elements/forms/buttons';
import Label from '@/components/elements/Label/Label';
import { ListItem } from '@/components/elements/lists';
import { CompanyAvatar } from '@/components/elements/redesign/Avatar';
import Icon from '@/components/icons/Icon';
import { isSistaminuten } from '@/helpers';
import { _s } from '@/locale';
import { PlaceEmployee } from '@/types/state/shared';

const baseTranslationKey = 'components.modules.modals.BookAppointment.shared.EmployeeItem';

const EmployeeItem = ({
  employee,
  underline = false,
  onSelect = undefined,
  onRemove = undefined,
}: {
  employee?: PlaceEmployee;
  underline?: boolean;
  onSelect?: (employee?: PlaceEmployee) => void;
  onRemove?: () => void;
}) => {
  const employeeName = employee ? employee.about.name : _s('anyEmployee');
  const employeeJobTitle = employee ? employee.about.jobTitle : _s('availableAllEmployees');
  const employeeAvatar = employee ? employee.about.avatar : undefined;
  const acceptsKlarna = employee?.about?.settings?.hasKlarna;
  const acceptsQliro = employee?.about?.settings?.useQliro;
  const hasCertificate = employee?.about?.certificates?.length || false;
  const acceptsGiftcard = !isSistaminuten() && employee?.about?.settings?.acceptsGiftCard;
  const hasBadges = acceptsGiftcard || acceptsKlarna || acceptsQliro || hasCertificate;

  const handleOnSelect = () => onSelect?.(employee);

  const rightSlot = (() => {
    if (onSelect) {
      return <Button label={_s(`${baseTranslationKey}.cta.label`)} size="sm" onClick={handleOnSelect} />;
    }
    if (onRemove) {
      return (
        <button onClick={onRemove}>
          <Icon variant="close" />
        </button>
      );
    }
    return null;
  })();

  return (
    <ListItem
      leftPadding={false}
      rightPadding={false}
      verticalAlign={hasBadges ? 'top' : 'center'}
      leftSlot={<CompanyAvatar alt="" size="lg" variant="default" imgUrl={employeeAvatar} icon="user" />}
      rightSlot={rightSlot}
      underline={underline}>
      <div className="space-x-sm flex w-full items-center justify-between">
        <div className="gap-xxs flex flex-col">
          <span className="text-sm font-semibold">{employeeName}</span>
          <span className="text-black-600 text-m">{employeeJobTitle}</span>
          {hasBadges && (
            <div className="mt-1 flex flex-wrap items-center gap-1">
              {acceptsKlarna && <Label variant="klarna" />}
              {acceptsQliro && <Label variant="qliro" />}
              {hasCertificate && <Label variant="certificate" />}
              {acceptsGiftcard && <Label variant="giftcard" />}
            </div>
          )}
        </div>
      </div>
    </ListItem>
  );
};

export default EmployeeItem;
