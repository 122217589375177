import Icon from '@/components/icons/Icon';
import { IconVariant } from '@/components/icons/types';
import { AVATAR_ICON_SIZE, AVATAR_SIZE } from '@/constants/AvatarConstants';
import { classnames } from '@/helpers';
import type { AvatarProps } from '../types';

type CustomAvatarProps = Omit<AvatarProps, 'imgUrl' | 'variant'> & { iconVariant: IconVariant };

const CustomAvatar = ({ size = 'xl', alt, iconVariant }: CustomAvatarProps) => {
  return (
    <div className="relative inline-block">
      <div
        className={classnames(
          AVATAR_SIZE[size],
          'bg-black-100 relative flex items-center justify-center overflow-hidden rounded-full',
        )}>
        <span className="border-black-100 absolute inset-0 z-10 rounded-full border bg-transparent"></span>
        <Icon color="black-500" size={AVATAR_ICON_SIZE[size]} variant={iconVariant} alt={alt} />
      </div>
    </div>
  );
};

export default CustomAvatar;
