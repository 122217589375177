import { Card, CardHeader } from '@/components/elements/cards';
import { H2 } from '@/components/elements/content';
import { Button } from '@/components/elements/forms/buttons';
import KlarnaPaymentMethodCategories from '@/components/modules/klarna/KlarnaPaymentMethodCategories';
import StepNavBar from '@/components/modules/pages/giftCards/StepNavBar';
import GiftCardsTerms from '@/components/modules/pages/giftCards/buy/GiftCardsTerms';
import { getGiftCardExpiryDateFromToday, moneyToDecimal } from '@/helpers';
import { __, _s } from '@/locale';

const base = `buyValueCard`;
const ConfirmValueCard = ({ goBack, valueCard, employee, goBackPath, authorize, paymentMethodCategories }) => (
  <>
    <StepNavBar backAction={goBack} backSteps={[1]} steps={2} currentStep={2} title={__('buyGiftcard.completeOrder')} />
    <H2 className="my-2" styleAs="h2-bold">
      {__(`${base}.confirm.title`) as string}
    </H2>
    <Card noShadow>
      <CardHeader className="flex justify-between">
        {valueCard?.name}
        <button onClick={goBack} className="text-primary text-base">
          {__(`${base}.summary.edit`) as string}
        </button>
      </CardHeader>
      <div>
        <strong className="font-semibold">{__(`${base}.summary.validFor`) as string}</strong> {employee?.name}
      </div>
      <div>
        <strong className="font-semibold">{__(`${base}.summary.value`) as string}</strong>{' '}
        {moneyToDecimal(valueCard?.value)} :-
      </div>
      <div>
        <strong className="font-semibold">{__(`${base}.summary.price`) as string}</strong>{' '}
        {moneyToDecimal(valueCard?.price)} :-
      </div>
      <div>
        <strong className="font-semibold">{__(`${base}.summary.validUntil`) as string}</strong>{' '}
        {getGiftCardExpiryDateFromToday(valueCard?.daysOfValidity)}
      </div>
      <div>
        <strong className="font-semibold">{__(`${base}.summary.description`) as string}</strong>{' '}
        {valueCard?.description ?? valueCard?.name}
      </div>
    </Card>
    <Card>
      <CardHeader>{__(`${base}.confirm.paymentTitle`) as string}</CardHeader>
      <KlarnaPaymentMethodCategories {...{ paymentMethodCategories }} />
      <div id="klarna-payment"></div>
      <div className="gap-lg pt-md flex flex-col">
        <Button size="md" block onClick={authorize}>
          {_s('buy')}
        </Button>
        <GiftCardsTerms />
      </div>
    </Card>
  </>
);

export default ConfirmValueCard;
