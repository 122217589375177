export function trimObjectValuesWhitespace<T>(obj: T): T {
  if (typeof obj === 'string') {
    return obj.trim() as any;
  }

  if (typeof obj === 'object' && obj !== null) {
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        obj[key] = trimObjectValuesWhitespace(obj[key]);
      }
    }
  }

  return obj;
}

export function openIosKeyboard() {
  const input = document.createElement('input');
  input.setAttribute('type', 'text');
  input.setAttribute('style', 'position: fixed; top: -100px; left: -100px;');
  document.body.appendChild(input);
  input.focus();
  setTimeout(() => {
    document.body.removeChild(input);
  }, 30 * 1000);
}

export function arrayOfStrings(value: any): string[] {
  if (Array.isArray(value) && value.every((item: any) => typeof item === 'string')) {
    return value;
  }
  return [];
}

export function isNationalId(value: string) {
  const cleanValue = value.replace(/[-\s]/g, '');

  const isValidFormat = cleanValue.match(
    /^(19|20)?\d{2}(01|02|03|04|05|06|07|08|09|10|11|12)((0[1-9])|(1|2)[0-9]|(30|31))\d{4}$/,
  );
  if (!isValidFormat) return false;

  const digits = cleanValue.length === 12 ? cleanValue.slice(2) : cleanValue;

  let sum = 0;
  for (let i = 0; i < 9; i++) {
    let num = parseInt(digits[i], 10);
    if (i % 2 === 0) num *= 2;
    if (num > 9) num -= 9;
    sum += num;
  }
  const controlDigit = (10 - (sum % 10)) % 10;

  return controlDigit === parseInt(digits[9], 10);
}
