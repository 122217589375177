import LoadingPlaceHolder from '@/components/elements/LoadingPlaceholder';
import PageViewLayout from '@/components/layouts/PageViewLayout/PageViewLayout';
import { _s } from '@/locale';
import { Redirect } from 'react-router-dom';
import useValidateThreeDSecureRedirect from './ValidateThreeDSecureRedirect.hooks';

const baseTranslationKey = 'pages.booking.checkout.ValidateThreeDSecureRedirect';

const ValidateThreeDSecureRedirect = () => {
  const { redirect } = useValidateThreeDSecureRedirect();

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  return (
    <PageViewLayout type="subView">
      <LoadingPlaceHolder text={_s(`${baseTranslationKey}.loadingLabel`)} />
    </PageViewLayout>
  );
};

export default ValidateThreeDSecureRedirect;
