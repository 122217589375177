// import { __ } from '@/locale';

export type Banner = {
  desktop: string;
  mobile: string;
  event: string;
  mpEvent: string;
  url: string;
  title?: string;
};

export const homeBanners: Record<string, Banner[]> = {
  bokadirekt: [
    {
      desktop: '/images/Bokadirekt-giftcard-desktop-banner.png',
      mobile: '/images/Bokadirekt-banner-buy-giftcard-svart.png',
      event: 'banner-general-clicked',
      mpEvent: 'banner_general_clicked',
      url: '/giftcards/buy',
    },
  ],
  sistaminuten: [],
};

export const blogBanners: Banner[] = [
  {
    desktop: '/images/Bokadirekt-giftcard-desktop-banner.png',
    mobile: '/images/Bokadirekt-Generell-Mobile-banner-new.jpg',
    event: 'blog-banner-general-clicked',
    mpEvent: 'blog_banner_general_clicked',
    url: '/giftcards/buy',
  },
];
