import { UpdateProfileRequest, UpdateProfileResponse } from '@/types/api/services/user';
import { ClientBundle, GetBundlesByTabResponse } from '@/types/api/services/users/schema';
import { server } from '../helpers';
import { loggedUser, removeUser, storeUser } from '../helpers/userHelper';

export const userService = {
  logout,
  getProfile,
  declineTracking,
  deleteProfile,
  updateProfile,
  getBundleById,
  getBundlesByTab,
  isEmailProtected,
};

function logout() {
  // remove user from local storage to log user out
  const token = loggedUser();
  if (token) {
    removeUser();
    server.request.post('/users/logout', { returnUrl: window.location.href }).then(function (response) {
      server.removeHeader('MpAuthorization');
      server.setHeaders();
      if (response.data.logoutUrl) {
        window.location.href = '/';
      }
    });
  }
}

function getProfile(token, forceFetch) {
  let url = '/users/profile/' + (token ? token : '') + (forceFetch ? '?fetch=1' : '');

  return server.request
    .get(url)
    .then(function (response) {
      const user = response.data.user;
      handleResponse(user);
      return user;
    })
    .catch(server.handleError);
}

function updateProfile(params: UpdateProfileRequest): Promise<UpdateProfileResponse> {
  return server.request
    .put('/users/profile', params)
    .then((response) => response.data)
    .catch(server.handleError);
}

function declineTracking() {
  let url = '/decline';

  return server.request
    .get(url)
    .then(function (response) {
      return response;
    })
    .catch(server.handleError);
}

function handleResponse(user) {
  if (user && user.token) {
    storeUser(user);
    server.setHeaders();
  }
}

function deleteProfile() {
  return server.request
    .delete('/users/profile')
    .then((response) => response.data)
    .then(() => {
      server.removeHeader('MpAuthorization');
      server.setHeaders();
    })
    .catch(server.handleError);
}

function getBundleById(id: number): Promise<ClientBundle> {
  return server.request
    .get(`/my-booking-bundle/${id}`)
    .then((response) => response.data)
    .catch(server.handleErrorObject);
}

export type BundleTab = 'valid' | 'used' | 'expired';

function getBundlesByTab(selectedTab: BundleTab): Promise<GetBundlesByTabResponse> {
  return server.request.get(`/my-booking-bundles/${selectedTab}`).then(server.handleSuccess).catch(server.handleError);
}

function isEmailProtected(email: string): Promise<any> {
  return server.request
    .post('/users/email-protected', { email })
    .then((response) => response.data)
    .catch(server.handleErrorObject);
}
