import * as React from 'react';
import { FocusEventHandler, InputHTMLAttributes } from 'react';

type GenericInputProps = {
  onBlur?: FocusEventHandler<HTMLInputElement>;
  error?: string;
  description?: string;
  leftSlot?: React.ReactNode;
  rightSlot?: React.ReactNode;
  placeholder: string;
  title?: string;
  type?: InputHTMLAttributes<HTMLInputElement>['type'];
  onFocus?: FocusEventHandler<HTMLInputElement>;
};

type InputProps = InputHTMLAttributes<HTMLInputElement> & GenericInputProps;

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      onBlur = null,
      error = null,
      description = null,
      leftSlot = null,
      rightSlot = null,
      placeholder,
      title = null,
      type = 'text',
      ...props
    },
    ref,
  ) => {
    return (
      <div className="flex">
        <div className={`gap-xs flex w-full flex-col`}>
          {title && (
            <label htmlFor="text-input" className="text-md text-black-900">
              {title}
            </label>
          )}
          <div
            className={`relative flex
          `}>
            {leftSlot && <div className="absolute left-3 flex h-full flex-col justify-center">{leftSlot}</div>}
            <input
              id="text-input"
              ref={ref}
              type={type}
              className={`placeholder:text-black-600 border-black-200 text-black-900 px-lg py-md w-full rounded border text-sm outline-none ${
                error ? 'focus:border-danger' : 'focus:border-black-900'
              } ${leftSlot ? 'pl-[43px]' : ''}  ${rightSlot ? 'pr-[43px]' : ''}`}
              onBlur={onBlur}
              placeholder={placeholder}
              {...props}
            />
            {rightSlot && <div className="absolute right-3 flex h-full flex-col justify-center">{rightSlot}</div>}
          </div>
          {description && <div className="text-black-600 text-xxs flex justify-start italic">{description}</div>}
          {error && <div className="text-danger text-xxs flex justify-start italic">{error}</div>}
        </div>
      </div>
    );
  },
);

export default Input;
