import { __ } from '../locale';

export function ratingText(rating) {
  if (rating >= 4.6) {
    return __('rating-5');
  } else if (rating >= 4 && rating < 4.6) {
    return __('rating-4');
  } else if (rating >= 3.5 && rating < 4) {
    return __('rating-3');
  } else if (rating >= 3 && rating < 3.5) {
    return __('rating-2');
  } else {
    return __('rating-1');
  }
}

export function reviewText(reviewCount = 0) {
  if (reviewCount === 0) {
    return __('noReviews');
  } else {
    return __('fewReviews', { count: reviewCount });
  }
}

export const roundRating = (rating, decimals = 1) => {
  const multiplier = Math.pow(10, decimals);
  return Math.round(rating * multiplier) / multiplier;
};
