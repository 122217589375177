import { Button } from '@/components/elements/forms/buttons';
import { CloseIcon } from '@/components/icons';
import { SMART_LINKS } from '@/constants/smartLinkConstants';
import { isServer, setCookie, showAndroidTopBanner, trackMpEvent } from '@/helpers';
import { _s } from '@/locale';
import * as moment from 'moment';

function AndroidTopBanner({ source }: { source: string }) {
  if (isServer) return null;

  const showBanner = showAndroidTopBanner();
  if (!showBanner) return null;

  const closeBanner = () => {
    setCookie('closedTopBanner', 'true', { path: '/', expires: moment().add(14, 'days').toDate() });
    window.location.reload();
  };

  const handleClick = () => {
    trackMpEvent('download_android_app_banner_clicked', {
      screen_name: source,
    });
  };

  let downloadUrl = 'https://gm9v.adj.st/?adjust_t=5zk0f0m&adjust_campaign={$source}&adjust_adgroup=top-banner';
  switch (source) {
    case 'home':
      downloadUrl = SMART_LINKS.HOME;
      break;
    case 'search_results':
      downloadUrl = SMART_LINKS.SERP;
      break;
    case 'company_details':
      downloadUrl = SMART_LINKS.PLACE_TOP;
      break;
    default:
      break;
  }

  return (
    <div className="bg-black-100 border-black-300 flex items-center border-y-[1px] py-2 pl-2 pr-4">
      <Button
        variant="link"
        className="!mr-2 !p-0"
        onClick={closeBanner}
        leftIcon={<CloseIcon className="text-black-900 h-4 w-4 p-[2px]" />}></Button>
      <img src="/images/top-banner-logo.svg" className="mr-2" alt="bokadirekt logo" width="30" height="30" />
      <div className="mr-2 flex flex-col">
        <span className="text-black-900 text-sm leading-[16px]">{_s('bookBeautyServicesEasy')}</span>
        <img src="/images/top-banner-stars-android.svg" className="mb-[3px]" alt="4,9 stars" width="59" height="9" />
        <span className="text-black-600 text-m">{_s('playStore')}</span>
      </div>
      <a
        href={downloadUrl}
        className="ml-auto rounded bg-[#00855e] px-3 py-1 text-sm uppercase text-white"
        onClick={handleClick}>
        {_s('download')}
      </a>
    </div>
  );
}

export default AndroidTopBanner;
