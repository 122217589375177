import { CloseIconThin } from '@/components/icons';
import { _s } from '@/locale';

type NewCustomerDiscountProps = {
  onRemove: () => void;
  variant: 'simple' | 'extended';
};

const NewCustomerDiscount = ({ onRemove, variant }: NewCustomerDiscountProps) => {
  return (
    <div
      className="relative rounded-lg bg-[#f8dde7] p-4 text-sm"
      style={{ background: 'linear-gradient(180deg, #fff4f8 0%, #fbf5f8 100%)' }}>
      {variant === 'extended' && <h4 className="text-black-900 font-semibold">{_s('newCustomerHeader')}</h4>}
      <div className={`block ${variant === 'simple' ? 'text-center' : 'text-left'}`}>
        {variant === 'extended' ? (
          <>
            {_s('newCustomerMessagepart1')} <span className="text-[#ea5786]">{_s('newCustomerMessageDiscount')}</span>{' '}
            {_s('newCustomerMessagepart2')}
          </>
        ) : (
          <>
            <span className="text-[#ea5786]">{_s('compactDiscountMessagePink')}</span> {_s('compactDiscountMessage')}
          </>
        )}
      </div>
      <div
        className={`text-black-600 absolute right-[20px] flex h-[10px] cursor-pointer items-center overflow-hidden text-sm ${
          variant === 'extended' ? 'top-[20px] h-[10px]' : 'top-1/2 h-full -translate-y-1/2'
        }`}
        onClick={onRemove}>
        <span className="text-link hidden lg:block">{_s('alreadyACustomer')}&nbsp;</span>
        <CloseIconThin className="h-2 w-2" />
      </div>
    </div>
  );
};

export default NewCustomerDiscount;
