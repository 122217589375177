import { LoginSuccess } from '@/components/animations';
import { AnimationProps } from '@/components/animations/types';
import { LoginContextType } from '@/hooks/useLogin';
import { _s } from '@/locale';

type VerifySuccessProps = Pick<LoginContextType, 'email'> & Pick<AnimationProps, 'onAnimationComplete'>;
const translationKey = 'VerifyOTP';

const VerifySuccess = ({ email, onAnimationComplete }: VerifySuccessProps) => {
  return (
    <>
      <p className="text-black-600">
        {_s(`${translationKey}.checkInbox`)}
        <br /> {_s(`${translationKey}.instructions`)}
        <span className="text-black-900 break-words font-semibold">{email.value}</span>
      </p>
      <div className="py-lg flex flex-col items-center justify-center">
        <LoginSuccess onAnimationComplete={onAnimationComplete} />
        <p className="text-h-m font-bold">{_s(`${translationKey}.success`)}</p>
      </div>
    </>
  );
};

export default VerifySuccess;
