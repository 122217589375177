import Header from '@/components/elements/Header/Header';
import ListInput from '@/components/elements/forms/listInput/ListInput/ListInput';
import ListInputTextArea from '@/components/elements/forms/listInput/ListInputTextArea/ListInputTextArea';
import CardWrapper from '@/components/modules/CardWrapper';
import { useGiftcardCheckoutFormData } from '@/hooks/useGiftcardCheckoutFormData';
import { _s } from '@/locale';

const baseTranslationKey = 'components.modules.checkout.giftcards.PersonalGreeting';

const PersonalGreeting = () => {
  const { register } = useGiftcardCheckoutFormData();
  return (
    <CardWrapper>
      <div className="pl-lg">
        <div className="pb-lg pt-md flex flex-col">
          <Header size="md" label={_s(`${baseTranslationKey}.title`)} />
        </div>
        <div className="pb-xs">
          <ListInput
            {...register('recipientName')}
            label={_s(`${baseTranslationKey}.inputLabel`)}
            placeholder={_s(`${baseTranslationKey}.recipientNamePlaceholder`)}
            leftPadding={false}
            id="recipient-name"
          />
        </div>
        <ListInputTextArea
          {...register('message')}
          placeholder={_s(`${baseTranslationKey}.messagePlaceholder`)}
          leftPadding={false}
        />

        <div className="pt-xs">
          <ListInput
            {...register('giverName')}
            placeholder={_s(`${baseTranslationKey}.giverNamePlaceholder`)}
            leftPadding={false}
            id="giver-name"
          />
        </div>
      </div>
    </CardWrapper>
  );
};

export default PersonalGreeting;
