import { Button, LinkButton } from '@/components/elements/forms/buttons';
import { RocketIcon } from '@/components/icons';
import { config } from '@/config';
import { getCookie, isServer, setCookie, trackMpEvent } from '@/helpers';
import { _s } from '@/locale';
import * as moment from 'moment';

const SERPMerchantBanner = () => {
  // Hide it until it works well on ERP side
  return null;

  if (isServer) return null;

  if (getCookie('closedTosMerchantBanner')) return null;

  const handleClick = (e) => {
    trackMpEvent('top_of_search_merchant_serp_clicked', {
      screen_name: 'search_results',
    });
  };

  const closeBanner = () => {
    // do not show banner for 14 days if closed once
    setCookie('closedTosMerchantBanner', 'true', { path: '/', expires: moment().add(14, 'days').toDate() });
    window.location.reload();
  };

  const url = `${config.merchantUrl}/Beta/Marketing/Marketing.aspx?utm_source=marketplace&utm_medium=banner&utm_campaign=raketen-top-of-serp`;

  return (
    <div className="flex flex-col justify-between bg-yellow-200 px-6 py-4 text-sm sm:rounded-lg lg:flex-row">
      <div className="mb-3 flex flex-1 items-center lg:mb-0">
        <RocketIcon className="text-black-900 mr-2 h-6 w-6" />
        <p className="flex-1">
          <span>
            {_s('merchantBanner')}
            <span className="font-semibold">{_s('merchantBanner2')}</span>
            {_s('merchantBanner3')}
          </span>
        </p>
      </div>
      <div className="flex justify-between">
        <Button onClick={closeBanner} variant="link" className="text-black-900 underline">
          {_s('noThanks')}
        </Button>
        <LinkButton to={url} onClick={handleClick} target="_blank" rel="noopener noreferrer" className="font-semibold">
          {_s('readMore')}
        </LinkButton>
      </div>
    </div>
  );
};

export default SERPMerchantBanner;
