import { __ } from '@/locale';
import { BDKPInitiationResponse, BDKPInitiationSuccessResponse, KPOrderLine } from '@/types/klarnaPayments';
import { useEffect, useState } from 'react';
import { server } from '../helpers';
import { createKlarnaOrder } from './klarnaServices';

type PGCPaymentRequest = {
  placeId: number;
  value: number;
  quantity: number;
  employee: number;
  slug: string;
  slugId: string;
  email?: string;
  newConfirmationPage?: boolean;
};

export const initiatePlaceGiftCardPayment = (initiationRequest: PGCPaymentRequest): Promise<BDKPInitiationResponse> =>
  server.request
    .post(`/klarna/places/${initiationRequest.placeId}/giftcards/payments`, initiationRequest)
    .then(server.handleSuccess)
    .catch(server.handleError);

export const updatePlaceGiftCardPayment = (sessionID: string, updateRequest: PGCPaymentRequest) =>
  server.request
    .post(`/klarna/places/${updateRequest.placeId}/giftcards/payments/${sessionID}`, updateRequest)
    .then(server.handleSuccess)
    .catch(server.handleError);

export const initiateOrUpdatePGCPayment = async (
  sessionID: string | null,
  request: PGCPaymentRequest,
): Promise<{ sessionID: string } | BDKPInitiationSuccessResponse> => {
  const res = sessionID
    ? await updatePlaceGiftCardPayment(sessionID, request)
    : await initiatePlaceGiftCardPayment(request);

  return sessionID ? { sessionID, ...res } : (res as BDKPInitiationSuccessResponse);
};

export const getPlaceGiftCardData = (placeId: number, slug: string) => {
  const uriSlug = encodeURIComponent(slug);
  return server.request
    .get(`/places/${placeId}/giftcarddata?slug=${uriSlug}`)
    .then(server.handleSuccess)
    .catch(server.handleError);
};

export const confirmPlaceGiftCardOrder = (placeID, klarnaID) =>
  server.request
    .post(`/klarna/places/${placeID}/giftcards/confirmation`, { klarnaID })
    .then(server.handleSuccess)
    .catch(server.handleError);

export const useConfirmPlaceGiftCardOrder = (placeID, klarnaID) => {
  const [data, setData] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (!klarnaID || String(klarnaID).length < 5) return;
    const confirmOrder = async () => {
      setLoading(true);
      try {
        const order = await confirmPlaceGiftCardOrder(placeID, klarnaID);
        setLoading(false);
        setData(order);
      } catch (ex) {
        console.log(ex);
        setError(true);
        setLoading(false);
      }
    };
    confirmOrder();
  }, [klarnaID]);

  return [data, loading, error];
};

type Employee = {
  name: string;
  id: number;
};

type CreatePlaceGiftCardOrder = {
  quantity: number;
  value: number;
  employee: Employee;
};

export const getPlaceGiftCardOrderLines = ({ quantity, value, employee }: CreatePlaceGiftCardOrder): KPOrderLine[] => {
  const unitPrice = Math.round(value * 100);

  return [
    {
      type: 'gift_card',
      reference: `MGC_${unitPrice}`,
      name: __('giftcardsBuyPlace.orderLineName', employee.name),
      quantity,
      unit_price: unitPrice,
      total_amount: unitPrice * quantity,
      total_tax_amount: 0,
      tax_rate: 0,
    },
  ];
};

export const createPlaceGiftCardOrder = (sessionID: string, authorizationToken: string) =>
  createKlarnaOrder(sessionID, authorizationToken);
