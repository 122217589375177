import { blogActions } from '@/actions';
import { Fab } from '@/components/elements/forms/buttons';
import { ArrowIcon } from '@/components/icons';
import { ArchiveLayout } from '@/components/layouts/blog';
import { isServer } from '@/helpers';
import { useEffect, useRef, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import ArchivePost from './ArchivePost';
import LoadingPosts from './LoadingPosts';

const DCArchive = ({ page: ssrPage, posts: ssrPosts }) => {
  const { page: urlPage } = useParams();
  const [page, setPage] = useState(ssrPage || { current: 1, total: 0 });
  const [posts, setPosts] = useState(ssrPosts || []);

  const [loading, setLoading] = useState(false);

  const mounted = useRef(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const fetchPosts = (page) => {
    const numPage = page && page > 1 ? parseInt(page) : 1;
    history.push(`/blogg/list/${numPage > 1 ? numPage : ''}`);
    setLoading(true);
    // @ts-ignore
    dispatch(blogActions.list(page));
  };

  useEffect(() => {
    if (!mounted.current) {
      if (!ssrPosts) {
        fetchPosts(urlPage);
      }
      mounted.current = true;
    } else {
      if (posts !== ssrPosts) {
        setPosts(ssrPosts);
        setPage(ssrPage ? { ...ssrPage, current: ssrPage.current ?? 1 } : { current: 1, total: 0 });
        setLoading(false);
      }
    }
  });

  useEffect(() => {
    if (isServer) return;
    window.scrollTo(0, 0);
  }, [page]);

  const nextPage = () => parseInt(page.total) >= page.current + 1 && fetchPosts(page.current + 1);
  const prevPage = () => parseInt(page.current) > 1 && fetchPosts(page.current - 1);

  return (
    <div className="mb-32">
      <ArchiveLayout>
        {loading ? (
          <LoadingPosts />
        ) : (
          posts && posts.length && posts.map((post, i) => <ArchivePost post={post} key={i} />)
        )}
      </ArchiveLayout>
      {!loading && posts && posts.length && (
        <div className="flex justify-center">
          <Fab
            icon={<ArrowIcon className="h-5 w-5 rotate-180" />}
            onClick={prevPage}
            size="md"
            variant="secondary"
            disabled={page.current <= 1}
            className="hover:bg-primary !mr-10 border bg-transparent hover:text-white"
          />
          <Fab
            icon={<ArrowIcon className="h-5 w-5" />}
            onClick={nextPage}
            size="md"
            variant="secondary"
            disabled={page.current + 1 > page.total}
            className="hover:bg-primary border bg-transparent hover:text-white"
          />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  const { page, posts } = state.blog;

  return {
    page,
    posts,
  };
};

const Archive = connect(mapStateToProps)(DCArchive);

export default Archive;
