import { ReactNode, useEffect, useRef } from 'react';

type Props = {
  className?: string;
  allowClick: boolean;
  onClickOutside: (e: any) => void;
  children: ReactNode;
};

const dropDownBaseClass = `
absolute
top-0
left-0
z-[200]
min-w-[10rem]
py-2
text-black-900
text-left
list-none
bg-white
bg-clip-padding
rounded-lg
border-[1px]
border-black-300
`;

const Dropdown = ({ className = '', allowClick, onClickOutside, children }: Props) => {
  const wrapperRef = useRef(null);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleClickOutside = (e: MouseEvent) => {
    if (allowClick && wrapperRef && !wrapperRef?.current?.contains(e.target)) {
      if (onClickOutside) {
        onClickOutside(e);
      }
    }
  };

  return (
    <div className={`${className || ''} ${dropDownBaseClass}`} ref={wrapperRef}>
      {children}
    </div>
  );
};

export default Dropdown;
