import { promiseWrapper } from '@/helpers';
import * as associationPagesServices from '@/services/associationPagesServices';
import { Associations, associationsSchema } from '@/types/api/services/associationPages';
import { captureException } from '@sentry/react';
import { Dispatch, useEffect, useState } from 'react';

export const useAssociationsArchive = (ssrPages: Associations) => {
  const [pages, setPages] = useState<Associations>(ssrPages ?? null);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (ssrPages) return;
    fetchArchive(setError, setPages);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { pages, error };
};

const fetchArchive = async (setError: Dispatch<any>, setPages: Dispatch<Associations>) => {
  const { data, error } = await promiseWrapper(associationPagesServices.getAssociations());

  const validated = associationsSchema.safeParse(data);

  if (!validated.success) {
    captureException('fetch associations archive error ', error ?? validated.error);
    return setError(error);
  }

  setPages(validated.data);
};
