import { useState } from 'react';

/**
 *
 * @param {*} [defaultState]
 * @param {Function} onSelectedChanged
 * @returns {array} The selected radioButtonGroup
 */
const useRadioButtonGroup = (defaultState = null, onSelectedChanged = () => {}) => {
  const [selected, setSelected] = useState(defaultState);

  const onSelect = (name) => () => {
    setSelected(name);
    onSelectedChanged(name);
  };

  return [selected, onSelect];
};

export default useRadioButtonGroup;
