import { bookActions } from '@/actions';
import { isServer } from '@/helpers';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

/**
 * Since place details object can be too large to be stored in session storage,
 * we store only id and slug of the place in session storage and fetch the place details
 * when not available in book state (for example when user hard refreshes page).
 */
const usePlaceDetailsFallback = () => {
  const place = useSelector((state: any) => state.book?.place);
  const dispatch = useDispatch();
  const initialStatus = place?.id !== undefined ? 'success' : 'loading';
  const [state, setState] = useState<'loading' | 'error' | 'success'>(initialStatus);

  const bookPlace = !isServer && JSON.parse(sessionStorage.getItem('bookPlace'));

  useEffect(() => {
    if (state === 'success') return;
    const controller = new AbortController();
    const { signal } = controller;

    if (!bookPlace?.id || !bookPlace?.slug) {
      setState('error');
      return;
    }
    // @ts-ignore
    dispatch(bookActions.fetchAndSetPlace(bookPlace.id, bookPlace.slug, signal));

    return () => controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (place?.id) setState('success');
    if (place?.fetchError) setState('error');
  }, [place]);

  return { status: state };
};

export default usePlaceDetailsFallback;
