import { bookActions } from '@/actions';
import LoadingPlaceHolder from '@/components/elements/LoadingPlaceholder';
import PageViewLayout from '@/components/layouts/PageViewLayout/PageViewLayout';
import CardWrapper from '@/components/modules/CardWrapper';
import { CheckoutDetailsProps } from '@/components/modules/checkout/CheckoutDetails/CheckoutDetails';
import KlarnaCheckout from '@/components/modules/checkout/KlarnaCheckout/KlarnaCheckout';
import GoBack from '@/components/modules/pages/bokningar/GoBack';
import { CHECKOUT_PAYMENT_METHOD } from '@/constants/checkout';
import useMobileView from '@/hooks/useMobileView';
import useTrackScreenView from '@/hooks/useTrackScreenView';
import { _s } from '@/locale';
import { InitKlarnaAfterBookingResponse, initKlarnaAfterBookingResponseSchema } from '@/types/api/services/booking';
import { Helmet } from 'react-helmet';
import { useHistory, useLocation } from 'react-router-dom';

import CheckoutDetails from '@/components/modules/checkout/CheckoutDetails/CheckoutDetails';
import { url } from '@/helpers';
import {
  mapConfirmedBookingInfoGiftcards,
  mapConfirmedBookingInfoServices,
  mapConfirmedBookingToCheckoutDetailsProps,
} from '@/helpers/confirmation';
import { useAppSelector } from '@/hooks';
import { BookState } from '@/types/state/book';
import usePrePayKlarnaCheckoutManager from './PrePayKlarnaCheckout.hooks';

const baseTranslationKey = 'pages.booking.checkout.KlarnaBookingCheckout';

const PrePayKlarnaCheckout = (props: InitKlarnaAfterBookingResponse) => {
  const history = useHistory();
  const bookState = useAppSelector((state) => state.book) as BookState;
  const addedGiftcards = bookState.usageReqId?.giftcards;
  const { booking, order } = props;
  const employee = booking.services[0].employee;
  const pageUrl = `${url.getBaseUrl()}/booking/checkout/${booking.id}/klarna`;
  const pageTitle = _s(`seo.activateOnlinePaymentKlarnaTitle`);
  const pageDescription = _s(`seo.activateOnlinePaymentKlarnaDescription`);

  const { klarnaBookingCheckoutAPI, status } = usePrePayKlarnaCheckoutManager({
    employeeId: employee.id,
    order,
  });
  const { isMobileView } = useMobileView();
  const paymentTrackingProps = bookActions.getTrackingProps();

  const { ...infoProps } = mapConfirmedBookingToCheckoutDetailsProps({
    booking,
    selectedPaymentMethod: { type: CHECKOUT_PAYMENT_METHOD.KLARNA },
    addedGiftcards,
  });
  const services = mapConfirmedBookingInfoServices(booking);
  const giftcards = mapConfirmedBookingInfoGiftcards(booking, addedGiftcards);

  const checkoutDetailsProps: CheckoutDetailsProps = {
    ...infoProps,
    services,
    giftcards,
  };

  const handleOnBackButtonClick = () => {
    history.replace({
      pathname: `/booking/checkout/${booking.id}`,
    });
  };

  useTrackScreenView({
    name: 'screen_view_checkout_booking_klarna_update_payment_method',
    properties: paymentTrackingProps,
  });

  return (
    <PageViewLayout
      type="subView"
      title={_s(`${baseTranslationKey}.page-title`)}
      back
      onBackButtonClick={handleOnBackButtonClick}
      wrapperClass={isMobileView ? 'bg-brown-50' : 'bg-gradient'}>
      {status === 'SUBMITTING' && <LoadingPlaceHolder className="fixed h-screen" />}
      <div className="bg-brown-50 lg:bg-transparent">
        <div className="lg:py-xxl lg:container">
          <div className="gap-xxl flex items-start">
            <div className="gap-lg flex grow-[9999] basis-[600px] flex-col">
              <CardWrapper className="w-full lg:hidden">
                <CheckoutDetails {...checkoutDetailsProps} />
              </CardWrapper>
              <CardWrapper>
                <div className="p-lg">
                  <KlarnaCheckout
                    clientToken={order.clientToken}
                    orderLines={order.orderLines}
                    handleCreateOrder={klarnaBookingCheckoutAPI().onSubmitKlarna}
                  />
                </div>
              </CardWrapper>
            </div>
            <div className="sticky top-20 hidden lg:flex lg:grow-[100] lg:basis-[390px]">
              <CardWrapper className="w-full">
                <CheckoutDetails key={2} {...checkoutDetailsProps} />
              </CardWrapper>
            </div>
          </div>
        </div>
      </div>

      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <link rel="canonical" href={pageUrl} />

        <meta property="og:url" content={pageUrl} />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        <meta name="robots" content="noindex" />
      </Helmet>
    </PageViewLayout>
  );
};

export default function Wrapper() {
  const location = useLocation();

  const parsedKlarnaOrder = initKlarnaAfterBookingResponseSchema.safeParse(location?.state?.klarnaOrder);

  if (!parsedKlarnaOrder.success) return <GoBack />;

  return <PrePayKlarnaCheckout {...parsedKlarnaOrder.data} />;
}
