/* eslint jsx-a11y/anchor-is-valid: 0 */
import { ArrowIcon } from '@/components/icons';
import { twTextPrimary } from '@/helpers/theme';
import { _s } from '@/locale';
import { useHistory } from 'react-router-dom';

type Props = {
  title: string;
  rightButtonData?: {
    title: string;
    action: () => void;
  };
  trackBackButton?: () => void;
  onBackButtonClick?: () => void;
};

const HeaderWithTitle = ({ title, rightButtonData, trackBackButton, onBackButtonClick = null }: Props) => {
  const history = useHistory();

  const backButtonAction = (e) => {
    e.preventDefault();
    if (trackBackButton) trackBackButton();
    if (onBackButtonClick) return onBackButtonClick();
    history.goBack();
  };
  const rightButton = rightButtonData ? (
    <button
      className={`!text-lg ${twTextPrimary()} absolute right-0 top-1/2 -translate-y-1/2 cursor-pointer`}
      onClick={rightButtonData.action}>
      {rightButtonData.title}
    </button>
  ) : null;

  return (
    <header className="relative flex h-8 items-center justify-center">
      <a className="absolute left-0 top-1/2 -translate-y-1/2" onClick={backButtonAction} aria-label={_s('back')}>
        <ArrowIcon className="text-black-900 h-5 w-5 rotate-180" />
      </a>
      <h2 className="text-sm font-semibold">{title || ''}</h2>
      {rightButton}
    </header>
  );
};

export default HeaderWithTitle;
