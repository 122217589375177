import useMobileView from '@/hooks/useMobileView';
import { twMerge } from 'tailwind-merge';
import { Card } from '../elements/cards';

/**
 * wraps content in a card on desktop breakpoints and without a card on mobile breakpoints
 */
const CardWrapper = ({ children, className }: { children: React.ReactNode; className?: string }) => {
  const { isMobileView } = useMobileView();
  if (isMobileView) {
    return <div className={twMerge('bg-white', className)}>{children}</div>;
  }
  return (
    <Card
      className={twMerge(
        'lg:!p-lg !m-0 !rounded-none !border-0 bg-white !p-0 !shadow-none lg:!rounded-lg lg:!shadow-lg',
        className,
      )}>
      {children}
    </Card>
  );
};

export default CardWrapper;
