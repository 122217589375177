import { config } from '../config';
import { isServer, isSistaminuten } from './general';
import { loggedUser } from './userHelper';

const axios = require('axios');

let request = {};

function setHeaders() {
  const headers = getHeaders();
  for (const header in headers) {
    request.defaults.headers.common[header] = headers[header];
  }
  if (isServer) {
    request.defaults.headers.common['cookie'] = 'BokadirektExperiment=1;';
  }
}

function removeHeader(header) {
  delete request.defaults.headers.common[header];
}
/**
 * Get instance
 */
function getInstance() {
  const baseUrl = isSistaminuten() ? config.lastMinuteServerUrl : config.serverUrl;
  const axiosInstance = axios.create({ baseURL: baseUrl });

  request = axiosInstance;
  setHeaders();
  return request;
}

/**
 * Handle success
 */
function handleSuccess(response) {
  if (isServer) {
    return response.data;
  }
  const data = response.data;
  const lastDeployTimestamp = response.headers['last-deploy-timestamp'];
  const savedLastDeployTimestamp = localStorage.getItem('last-deploy-timestamp') || 0;

  if (savedLastDeployTimestamp === 0) {
    localStorage.setItem('last-deploy-timestamp', lastDeployTimestamp);
  } else {
    if (lastDeployTimestamp && lastDeployTimestamp !== savedLastDeployTimestamp) {
      localStorage.setItem('last-deploy-timestamp', lastDeployTimestamp);
      window.location.reload(true);
    }
  }

  return data;
}

/**
 * Handle errors, getting an error message
 */
function handleError(error) {
  if (error.response) {
    if (error.response.data) {
      return Promise.reject(error.response.data.error || 'error4sentry2');
    }
  }

  return Promise.reject('error4sentry ' + error.message);
}

/**
 * Handle axios errors, getting the error object.
 */
function handleErrorObject(error) {
  return Promise.reject({
    ...error.response.data,
    ...(error.response?.status ? { status: error.response.status } : {}),
  });
}

/**
 * Get Header
 */
function getHeaders() {
  const token = loggedUser();

  const headers = {};
  // User is logged in
  if (token) {
    headers['MpAuthorization'] = token;
  }
  const language = !isServer && localStorage.getItem('language') ? localStorage.getItem('language') : 'se';
  headers['Accept-Language'] = language;

  return headers;
}

getInstance();

export const server = {
  request,
  setHeaders,
  handleSuccess,
  handleError,
  handleErrorObject,
  getHeaders,
  removeHeader,
};
