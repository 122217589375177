import { Card } from '@/components/elements/cards';
import { PhoneAltIcon } from '@/components/icons';
import { _s } from '@/locale';

export const ContactUsSection = (
  <Card className="text-black-900 tracking-content flex flex-col bg-white text-base lg:text-lg">
    <h3 className="splitter lg:text-h-m flex items-center justify-start gap-x-4 text-base font-bold">
      <PhoneAltIcon className="h-4" />
      {_s('contactUs')}
    </h3>
    <p className="mb-6 whitespace-pre-wrap">{_s('supportPage.contactUsText')}</p>
  </Card>
);
