import { classnames } from '@/helpers';
import { ReactNode } from 'react';
import { H2 } from '../elements/content';

type EmptyStateProps = {
  icon?: React.ReactNode;
  title?: string;
  body?: string | ReactNode;
  cta?: React.ReactNode;
  className?: string;
  containerClassName?: string;
};
const EmptyState = ({ icon, title, body, cta, className = '', containerClassName }: EmptyStateProps) => {
  return (
    <div className={`flex h-full w-full justify-center ${containerClassName ?? ''}`}>
      <div className="flex flex-col justify-center">
        <div className={classnames('p-lg gap-lg flex flex-col justify-center', className)}>
          {icon && <div className="flex justify-center">{icon}</div>}
          {title && (
            <div className="text-center">
              <H2 styleAs="xl-bold" className="!mb-0">
                {title}
              </H2>
            </div>
          )}
          {body && (
            <div className="text-center">
              <p className="text-md max-w-[50ch]">{body}</p>
            </div>
          )}
          {cta && <div className="flex justify-center">{cta}</div>}
        </div>
      </div>
    </div>
  );
};

export default EmptyState;
