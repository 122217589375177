import { server } from '@/helpers';
import {
  AddBookingGiftcardsResponse,
  AppointmentResponse,
  BookingByCodeResponse,
  CancelAppointmentResponse,
  CancelByCodeResponse,
  ChangeBookingTimeResponse,
  CofPaymentRequest,
  CofPaymentResponse,
  ConfirmCofResponse,
  ConfirmKlarnaResponse,
  ConfirmQliroResponse,
  ConfirmSwishPaymentResponse,
  InitKlarnaAfterBookingResponse,
  InitKlarnaRequest,
  InitKlarnaResponse,
  InitQliroRequest,
  InitQliroResponse,
  PaymentHistoryResponse,
  SaveBookingRequest,
  SaveBookingResponse,
  SwishPaymentBookingRequest,
  SwishPaymentBookingResponse,
} from '@/types/api/services/booking';
import { giftcardSchema } from '@/types/state/book';
import * as z from 'zod';

export const addBookingGiftcardsRequestSchema = z.object({
  bookingId: z.number(),
  giftcards: z.array(giftcardSchema).optional(),
});

export type AddBookingGiftcardsRequest = z.infer<typeof addBookingGiftcardsRequestSchema>;

export const initKlarnaAfterBookingRequestSchema = z.object({
  giftcards: z.array(giftcardSchema).optional(),
});

export type InitKlarnaAfterBookingRequest = z.infer<typeof initKlarnaAfterBookingRequestSchema>;

function cofPayment(body: CofPaymentRequest): Promise<CofPaymentResponse> {
  return server.request.post('/adyen/orders', body).then(server.handleSuccess).catch(server.handleErrorObject);
}

function createSwishOrder(body: SwishPaymentBookingRequest): Promise<SwishPaymentBookingResponse> {
  return server.request.post('/swish/orders', body).then(server.handleSuccess).catch(server.handleErrorObject);
}

function updateSwishOrder(body: SwishPaymentBookingRequest) {
  return server.request.put('/swish/orders', body).then(server.handleSuccess).catch(server.handleErrorObject);
}

function confirmSwishPayment(bookingId: string): Promise<ConfirmSwishPaymentResponse> {
  return server.request
    .get(`/swish/confirmed?bookingId=${bookingId}`)
    .then(server.handleSuccess)
    .catch(server.handleErrorObject);
}

function saveBooking(body: SaveBookingRequest): Promise<SaveBookingResponse> {
  return server.request.post('/booking', body).then(server.handleSuccess).catch(server.handleErrorObject);
}

function initKlarna(body: InitKlarnaRequest): Promise<InitKlarnaResponse> {
  return server.request.post('/klarna/payments', body).then(server.handleSuccess).catch(server.handleErrorObject);
}

function initQliro(body: InitQliroRequest): Promise<InitQliroResponse> {
  return server.request.post('/qliro/create', body).then(server.handleSuccess).catch(server.handleErrorObject);
}

function initKlarnaAfterBooking({
  bookingId,
  body,
}: {
  bookingId: number;
  body: InitKlarnaAfterBookingRequest;
}): Promise<InitKlarnaAfterBookingResponse> {
  return server.request
    .post('/klarnapayments/init/' + bookingId, body)
    .then(server.handleSuccess)
    .catch(server.handleErrorObject);
}

function completeKlarnaAfterBooking(
  klarnaID: string,
  sessionID: string,
  bookingId: number,
): Promise<ConfirmKlarnaResponse> {
  return server.request
    .post(`/klarnapayments/complete-after/${bookingId}`, { klarnaID, sessionID })
    .then(server.handleSuccess)
    .catch(server.handleError);
}

function appointment(id: number): Promise<AppointmentResponse> {
  return server.request
    .get('/appointment/' + id, {})
    .then(server.handleSuccess)
    .catch(server.handleErrorObject);
}

function confirmQliro(id: string): Promise<ConfirmQliroResponse> {
  return server.request
    .get('/qliro/confirmed?qliro_ref=' + id, {})
    .then(server.handleSuccess)
    .catch(server.handleError);
}

function confirmKlarna(klarnaID: string, sessionID: string): Promise<ConfirmKlarnaResponse> {
  return server.request
    .post('/klarna/payments/complete', { klarnaID, sessionID })
    .then(server.handleSuccess)
    .catch(server.handleError);
}

function confirmCof(id: string): ConfirmCofResponse {
  return server.request
    .get('/adyen/confirmed?bookingId=' + id, {})
    .then(server.handleSuccess)
    .catch(server.handleError);
}

function getBookingByCode(code: string): BookingByCodeResponse {
  return server.request
    .get('/booking-by-code/' + code, {})
    .then(server.handleSuccess)
    .catch(server.handleError);
}

function cancelAppointment(id: number): Promise<CancelAppointmentResponse> {
  return server.request
    .delete('/appointment/' + id, {})
    .then(server.handleSuccess)
    .catch(server.handleErrorObject);
}

function changeBookingTime(id: number, timestamp: number): Promise<ChangeBookingTimeResponse> {
  return server.request
    .post('/change-booking-time', { id, time: timestamp })
    .then(server.handleSuccess)
    .catch(server.handleErrorObject);
}

function addBookingGiftcards(body: AddBookingGiftcardsRequest): Promise<AddBookingGiftcardsResponse> {
  return server.request.post('/giftcards/payments', body).then(server.handleSuccess).catch(server.handleErrorObject);
}

function getPaymentsHistory(placeId: number | undefined): Promise<PaymentHistoryResponse> {
  return server.request
    .get(`/users/payment-history?placeId=${placeId}`, {})
    .then(server.handleSuccess)
    .catch(server.handleError);
}

function cancelByCode(code: string, placeId?: number): Promise<CancelByCodeResponse> {
  const place = placeId ? `/${placeId}` : '';
  return server.request
    .get(`/cancel-by-code/${code}${place}`, {})
    .then(server.handleSuccess)
    .catch(server.handleErrorObject);
}

export const bookServices = {
  cofPayment,
  createSwishOrder,
  cancelAppointment,
  confirmSwishPayment,
  saveBooking,
  initKlarna,
  initQliro,
  confirmKlarna,
  confirmQliro,
  confirmCof,
  getBookingByCode,
  appointment,
  initKlarnaAfterBooking,
  completeKlarnaAfterBooking,
  updateSwishOrder,
  changeBookingTime,
  addBookingGiftcards,
  getPaymentsHistory,
  cancelByCode,
};
