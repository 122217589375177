import Label from '@/components/elements/Label/Label';
import Icon from '@/components/icons/Icon';
import { _s } from '@/locale';

const baseTranslationKey = 'pages.bransch-organisationer.shared.Stats';

const Stats = () => {
  return (
    <div className="flex flex-col">
      <div className="flex flex-col md:flex-row">
        <div className="space-y-xxs px-lg py-md flex flex-1 flex-col items-center text-center">
          <span className="text-5xl font-semibold">200K</span>
          <span className="text-black-600 text-sm">{_s(`${baseTranslationKey}.1`)}</span>
        </div>
        <div className="space-y-xxs px-lg py-md flex flex-1 flex-col items-center text-center">
          <span className="text-5xl font-semibold">14K</span>
          <span className="text-black-600 text-sm">{_s(`${baseTranslationKey}.2`)}</span>
        </div>
        <div className="space-y-xxs px-lg py-md flex flex-1 flex-col items-center text-center">
          <span className="text-5xl font-semibold">40K</span>
          <span className="text-black-600 text-sm">{_s(`${baseTranslationKey}.3`)}</span>
        </div>
      </div>
      <div className="gap-md flex flex-wrap items-center justify-center md:justify-between">
        <Icon variant="payment-method-klarna-lg" noFilter />
        <Icon variant="payment-method-qliro-lg" noFilter size="lg" />
        <Icon variant="payment-method-visa-lg" noFilter size="lg" />
        <Icon variant="payment-method-mastercard-lg" noFilter size="lg" />
        <Icon variant="payment-method-apple-pay-lg" noFilter size="lg" />
        <Icon variant="payment-method-google-pay-lg" noFilter size="lg" />
        <Label variant="giftcard" className="inline-flex" />
      </div>
    </div>
  );
};

export default Stats;
