import BuyGiftCard from '@/components/modules/pages/valueCards/BuyGiftCard';
import ValueCards from '@/components/modules/pages/valueCards/ValueCards';

const CheckoutColumn = ({
  slugId,
  placeName,
  employees,
  valueCard,
  onSelectValueCard,
  showInfo,
  placeSellsGiftcard,
}) => (
  <div className="lg:mb-24">
    {placeSellsGiftcard && <BuyGiftCard slug={slugId} />}
    {employees &&
      employees.map((employee) => (
        <ValueCards
          {...{
            id: employee.id,
            header: employee.name,
            valueCards: employee.valueCards,
            onSelect: onSelectValueCard,
            showInfo,
          }}
          key={employee.id}
        />
      ))}
  </div>
);

export default CheckoutColumn;
