import { ListItem, ListItemContent } from '@/components/elements/lists';
import Icon from '@/components/icons/Icon';
import { getServiceCampaignIfAny, getServiceDuration, getServicePrice } from '@/helpers';
import { useAppSelector } from '@/hooks';
import { PlaceService } from '@/types/state/shared';
import { getPickedEmployee } from '../BookAppointment.helpers';

const PickedService = ({ service, onRemove }: { service: PlaceService; onRemove?: (serviceId: number) => void }) => {
  const { place, employee } = useAppSelector((state) => state.book);
  const pickedEmployee = getPickedEmployee(employee, place.employees);
  const priceListId = pickedEmployee?.about?.priceListId ?? 0;
  const { campaignService } = getServiceCampaignIfAny(service, place.campaigns, [service.id]);
  const price = getServicePrice(service, priceListId, place);
  const offerPrice = campaignService ? getServicePrice(service, priceListId, place, campaignService) : price;
  const hasCampaignPrice = price !== offerPrice;
  const serviceDuration = getServiceDuration(service, priceListId, 1);

  return (
    <ListItem
      leftPadding={false}
      rightPadding={false}
      leftSlot={<Icon variant="check-circle" />}
      {...(onRemove && {
        rightSlot: (
          <button onClick={() => onRemove(service.id)}>
            <Icon variant="close" />
          </button>
        ),
      })}>
      <ListItemContent
        content={
          <>
            {serviceDuration && <span>{serviceDuration}</span>}
            {price && serviceDuration && ', '}
            {hasCampaignPrice && (
              <span className="text-danger-500">
                {offerPrice} <span className="text-black-600 line-through">({price})</span>
              </span>
            )}
            {!hasCampaignPrice && price}
          </>
        }
        contentClassName="text-m"
        subTitle={service.name}
        subTitleClassName="text-black-900 text-m font-semibold"
      />
    </ListItem>
  );
};

export default PickedService;
