import { adyenServices } from '@/services';

/**
 * Minimum Apple pay version (10) is IOS 14.0 and MacOS 10.16
 */
const MINIMUM_APPLE_PAY_VERSION = 10;

const getMininumSupportedApplePayVersion = (version: number) => {
  if (window.ApplePaySession && ApplePaySession.supportsVersion(version)) {
    return version;
  }

  if (version <= MINIMUM_APPLE_PAY_VERSION) return false;

  return getMininumSupportedApplePayVersion(version - 1);
};

export const mapApplePayLineItem = ({
  amount,
  label,
  payLater,
}: {
  amount: string;
  label: string;
  payLater: boolean;
}): ApplePayJS.ApplePayLineItem => {
  return {
    label,
    amount,
    type: payLater ? 'pending' : 'final',
  };
};

export const createSession = ({
  total,
  lineItems = [],
}: {
  total: ApplePayJS.ApplePayLineItem;
  lineItems?: ApplePayJS.ApplePayLineItem[];
}): ApplePaySession => {
  const version = getMininumSupportedApplePayVersion(14);

  const session = new ApplePaySession(version, {
    countryCode: 'SE',
    currencyCode: 'SEK',
    merchantCapabilities: ['supports3DS'],
    supportedNetworks: ['visa', 'masterCard', 'amex', 'discover'],
    ...(lineItems.length && { lineItems }),
    total,
  });

  session.onvalidatemerchant = (event) => {
    const { validationURL } = event;
    adyenServices
      .validateMerchant(validationURL)
      .then((response) => session.completeMerchantValidation(response))
      .catch((error) => session.abort());
  };

  return session;
};
