import colors from '@/colors';
import { classnames } from '@/helpers';
import { useCountDown } from '@/hooks/useCountDown';
import { _s } from '@/locale';
import { useEffect } from 'react';

import { z } from 'zod';

const baseTranslationKey = 'components.elements.Countdown';

const padded = (num: number) => (num < 10 ? `0${num}` : `${num}`);

export const countdownSchema = z.object({
  endDate: z.number(), // timestamp
});

type BannerCountdownProps = z.infer<typeof countdownSchema> & {
  type: 'large' | 'small';
  labelColor?: string;
  countdownColor?: string;
  handleCountdownComplete: () => void;
};

const Countdown = ({
  type,
  endDate,
  labelColor = colors['black'][900],
  countdownColor = colors['black'][900],
  handleCountdownComplete,
}: BannerCountdownProps) => {
  const countdown = useCountDown(endDate);
  const validateEnddate = countdownSchema.safeParse({ endDate });

  const days = countdown[0];
  const hours = countdown[1];
  const minutes = countdown[2];
  const seconds = countdown[3];

  useEffect(() => {
    const done = countdown?.every((c) => c.value < 0 || c.value === 0);
    if (done) handleCountdownComplete?.();
  }, [countdown, handleCountdownComplete]);

  if (!validateEnddate.success) return null;

  switch (type) {
    case 'large': {
      return (
        <div className="flex items-center" style={{ color: countdownColor }}>
          <div className="flex h-[75px] items-stretch">
            <div className="flex flex-1 flex-col items-center justify-between">
              <span className={classnames('text-4xl font-bold md:text-[54px]')} style={{ fontFamily: 'monospace' }}>
                {padded(days.value)}
              </span>
              <span style={{ color: labelColor }} className={classnames('text-xxs flex items-end text-center')}>
                {_s(`${baseTranslationKey}.days`)}
              </span>
            </div>
            <span className="px-xs text-4xl opacity-50">:</span>
          </div>
          <div className="flex h-[75px] items-stretch">
            <div className="flex flex-1 flex-col items-center justify-between">
              <span className={classnames('text-4xl font-bold md:text-[54px]')} style={{ fontFamily: 'monospace' }}>
                {padded(hours.value)}
              </span>
              <span style={{ color: labelColor }} className={classnames('text-xxs flex items-end text-center')}>
                {_s(`${baseTranslationKey}.hours`)}
              </span>
            </div>
            <span className="px-xs text-4xl opacity-50">:</span>
          </div>
          <div className="flex h-[75px] items-stretch">
            <div className="flex flex-col items-center justify-between">
              <span className={classnames('text-4xl font-bold md:text-[54px]')} style={{ fontFamily: 'monospace' }}>
                {padded(minutes.value)}
              </span>
              <span style={{ color: labelColor }} className={classnames('text-xxs flex items-end text-center')}>
                {_s(`${baseTranslationKey}.minutes`)}
              </span>
            </div>
            <span className="px-xs text-4xl opacity-50">:</span>
          </div>
          <div className="flex h-[75px] flex-1 items-stretch ">
            <div className="flex flex-col items-center justify-between">
              <span className={classnames('text-4xl font-bold md:text-[54px]')} style={{ fontFamily: 'monospace' }}>
                {padded(seconds.value)}
              </span>
              <span style={{ color: labelColor }} className={classnames('text-xxs flex items-end text-center')}>
                {_s(`${baseTranslationKey}.seconds`)}
              </span>
            </div>
          </div>
        </div>
      );
    }
    case 'small': {
      return (
        <div className="text-sm" style={{ color: countdownColor, fontFamily: 'monospace' }}>
          {`${padded(days.value)}${days.short} : ${padded(hours.value)}${hours.short} : ${padded(minutes.value)}${
            minutes.short
          } : ${padded(seconds.value)}${seconds.short}`}
        </div>
      );
    }
    default:
      const never: never = type;
      throw new Error(`Unknown countdown type: ${never}`);
  }
};

export default Countdown;
