import { searchConstants } from '../constants';

export const searchActions = {
  setParameter,
  removeParameter,
  reset,
  startLocationLoader,
  stopLocationLoader,
  locationError,
  storeList,
};

function startLocationLoader() {
  return { type: searchConstants.START_LOCATION_LOADER };
}

function stopLocationLoader() {
  return { type: searchConstants.STOP_LOCATION_LOADER };
}

function locationError() {
  return { type: searchConstants.LOCATION_ERROR };
}

function setParameter(parameter) {
  return (dispatch) => {
    dispatch(set(parameter));
  };
  function set(parameter) {
    return { type: searchConstants.SET_PARAMS, parameter };
  }
}

function removeParameter(parameter) {
  return (dispatch) => {
    dispatch(remove(parameter));
  };
  function remove(parameter) {
    return { type: searchConstants.REMOVE_PARAMS, parameter };
  }
}

function reset() {
  return (dispatch) => {
    dispatch(reset());
  };
  function reset() {
    return { type: searchConstants.RESET };
  }
}

function storeList(params) {
  return { type: searchConstants.STORE_LIST, params };
}
