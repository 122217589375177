import { HeaderWithTitle } from '@/components/modules/mobile';
import { __ } from '@/locale';
import { FunctionComponent, ReactNode } from 'react';

type FilterTemplateProps = {
  children: ReactNode;
  title: string;
  rightButtonData: any;
};

const FilterTemplate: FunctionComponent<FilterTemplateProps> = ({ children, title, rightButtonData }) => {
  return (
    <>
      <div className="z-9999 sticky top-0 w-full bg-white pt-4">
        <div className="container mx-auto w-full">
          <HeaderWithTitle title={__(title)} rightButtonData={rightButtonData} />
        </div>
      </div>
      {children}
    </>
  );
};

export default FilterTemplate;
