import { Button } from '@/components/elements/forms/buttons';
import { __ } from '@/locale';
import React from 'react';

class BookWaitlist extends React.Component {
  render() {
    return (
      <div className="action">
        <Button
          variant="secondary"
          className={'whitespace-nowrap'}
          style={{ borderColor: '#336CA8', '--color-primary': '#336CA8' }}>
          {__('bookWaitingList')}
        </Button>
      </div>
    );
  }
}

export default BookWaitlist;
