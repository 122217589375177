import { isEmpty } from '@/helpers/objects';
import { getPlaceGiftCardData } from '@/services/pgcPaymentServices';
import { getValueCardData } from '@/services/vcPaymentServices';
import { useEffect, useState } from 'react';

const hasEmployees = (place) => {
  return !isEmpty(place) && (place.employees?.length ?? 0);
};

const findAndSetPlaceGiftCardData = async (id, slug, setPlace, setLoading) => {
  setLoading(true);
  getPlaceGiftCardData(id, slug)
    .then((place) => setPlace(place))
    .finally(() => setLoading(false));
};

const findAndSetValueCardData = async (id, slug, setPlace, setLoading) => {
  setLoading(true);
  getValueCardData(id, slug)
    .then((place) => setPlace(place))
    .finally(() => setLoading(false));
};

const isNewPlace = (place: any, id: number, dataType: string) =>
  hasEmployees(place) && (parseInt(place.placeId) !== id || (place.dataType ?? '') !== dataType);

const usePlaceData = (
  findAndSetPlace: (
    id: number,
    slug: string,
    setPlace: (place: any) => void,
    setLoading: (loading: boolean) => void,
  ) => void,
  dataType: string,
  defaultPlace: any = undefined,
  id?: number,
  slug?: string,
) => {
  const [place, setPlace] = useState(defaultPlace);
  const [loading, setLoading] = useState(Boolean(defaultPlace === undefined && id && slug));

  useEffect(() => {
    if (!hasEmployees(place) && id && slug) {
      findAndSetPlace(id, slug, setPlace, setLoading);
    }
  }, [place, id, slug, setPlace]);

  useEffect(() => {
    if (isNewPlace(place, id, dataType) && id && slug) {
      findAndSetPlace(id, slug, setPlace, setLoading);
    }
  }, [place, id, slug, setPlace]);

  return { place, loading };
};

export const usePlaceGiftCardData = (defaultPlace: any = undefined, id?: number, slug?: string) =>
  usePlaceData(findAndSetPlaceGiftCardData, 'giftCard', defaultPlace, id, slug);
export const useValueCardData = (defaultPlace: any = undefined, id: number, slug: string) =>
  usePlaceData(findAndSetValueCardData, 'valueCard', defaultPlace, id, slug);
