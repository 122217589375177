import { Card, CardImageHeader, CardShadow } from '@/components/elements/cards';
import { LinkButton } from '@/components/elements/forms/buttons';
import { indexedArrayFromLength } from '@/helpers';
import { __ } from '@/locale';

const numP = 2;

const domainBase = 'buyGiftcard.infoColumn';

const handleClick = () => {};

const InfoColumnStandardCard = () => (
  <CardShadow>
    <CardImageHeader src={'/images/product-giftcard.png'} />
    <Card noShadow attachedTop>
      <p className="text-md my-3">
        {__(`${domainBase}.preLink`)}{' '}
        <LinkButton size="md" className="!p-0" onClick={handleClick} to="/vad/var?gc=1">
          {__(`${domainBase}.link`)}
        </LinkButton>{' '}
        {__(`${domainBase}.postLink`)}
      </p>
      {indexedArrayFromLength(numP).map((i) => (
        <p className="text-md my-3" key={i}>
          {__(`${domainBase}.p${i + 1}`)}
        </p>
      ))}
      <p className="color-gray-600 my-3 text-sm">
        {__(`${domainBase}.smallPreLink`)}{' '}
        <LinkButton className="!p-0" onClick={handleClick} to="/vad/var?gc=1">
          {__(`${domainBase}.smallLink`)}
        </LinkButton>{' '}
        {__(`${domainBase}.smallPostLink`)}
      </p>
    </Card>
  </CardShadow>
);

export default InfoColumnStandardCard;
