import { showNavigationDrawer } from '@/actions';
import { Button, LinkButton } from '@/components/elements/forms/buttons';
import Modal from '@/components/modules/modals/redesign/Modal/Modal';
import { classnames, trackMpEvent } from '@/helpers';
import useMobileView from '@/hooks/useMobileView';
import { _s } from '@/locale';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

const baseTranslationKey = 'components.modules.modals.AddPaymentMethodPromotion';

const AddPaymentMethodPromotion = ({ isOpen, onClose }) => {
  const { isMobileView } = useMobileView();
  const dispatch = useDispatch();

  useEffect(() => {
    if (isOpen) {
      trackMpEvent('screen_view_add_paymentcard_overlay', {
        screen_name: 'add_paymentcard_overlay',
      });
    }
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen}>
      <Modal.Content floating={!isMobileView}>
        <div className="flex flex-col" style={isMobileView ? { minHeight: 'calc(100vh - 42px)' } : {}}>
          <div
            className={classnames('mx-auto flex-1', isMobileView ? 'min-h-[200px]' : 'min-h-[300px]')}
            style={{
              width: 'min(500px,100%)',
              backgroundImage:
                'url("https://userimg-assets-eu.customeriomail.com/images/client-env-121373/1695639852585_inapp_01HB605P792RSD11M7CK3618R2.png")',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center bottom',
              backgroundSize: 'contain',
            }}></div>
          <div
            className={classnames('space-y-lg py-lg px-lg bg-white text-center', isMobileView && 'shadow-bottom-lg')}>
            <h2 className="text-h-m font-semibold">{_s(`${baseTranslationKey}.title`)}</h2>
            <p className="text-black-600 text-md">{_s(`${baseTranslationKey}.body`)}</p>
            <div className="space-y-lg pt-lg flex flex-col">
              <LinkButton
                size="md"
                block
                onClick={() => dispatch(showNavigationDrawer({ show: false }))}
                variant="primary"
                to="/betalningsmetoder">
                {_s(`${baseTranslationKey}.ctaPrimary`)}
              </LinkButton>
              <Button size="md" block variant="link" onClick={onClose}>
                {_s(`${baseTranslationKey}.ctaSecondary`)}
              </Button>
            </div>
          </div>
        </div>
      </Modal.Content>
    </Modal>
  );
};

export default AddPaymentMethodPromotion;
