/*
  Split a string into a multidimensional object
  Can set a value on the last level, default: null

*/
export function stringToObj(str, value = null, delimiter = ':') {
  const newObj = {};
  const parts = str.split(delimiter);

  const part = parts.shift();
  if (parts.length) {
    newObj[part] = stringToObj(parts.join(delimiter), value);
  } else {
    newObj[part] = value;
  }

  return Object.assign({}, newObj);
}

export function mergeMultidimensionalObj(oldObj, newObj) {
  const result = {};

  for (let key in newObj) {
    if (typeof newObj[key] === 'object') {
      result[key] = mergeMultidimensionalObj(oldObj[key] ? oldObj[key] : {}, newObj[key]);
    } else {
      result[key] = newObj[key];
    }
  }

  return Object.assign({}, oldObj, result);
}

export function isEmpty(obj) {
  return (
    obj === null ||
    obj === undefined ||
    (obj === Object(obj) && Object.keys(obj).length === 0) ||
    (typeof obj === 'string' && 0 === obj.trim().length)
  );
}
export function jsUcfirst(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function filterEmptyValues(obj) {
  const objCopy = { ...obj };
  if (!objCopy) {
    return objCopy;
  }

  const result = {};
  for (let key in objCopy) {
    if ((objCopy[key] || objCopy[key] === false) && !isEmpty(objCopy[key])) {
      result[key] = objCopy[key];
    }
  }

  return result;
}

export function removeLabels(params) {
  const result = [];
  const whitelisted = ['category', 'q', 'location', 'locationId', 'position', 'sort', 'changedDefaultSorting'];

  for (const key in params) {
    if (whitelisted.indexOf(key) !== -1) {
      if (params[key].id) {
        result[key] = params[key].id;
      } else {
        result[key] = params[key];
      }
    }
  }
  return result;
}
export function chunk(arr, len) {
  const chunks = [],
    n = arr.length;
  let i = 0;

  while (i < n) {
    chunks.push(arr.slice(i, (i += len)));
  }

  return chunks;
}
export function isEqual(value, other) {
  // Get the value type
  var type = Object.prototype.toString.call(value);

  // If the two objects are not the same type, return false
  if (type !== Object.prototype.toString.call(other)) return false;

  // If items are not an object or array, return false
  if (['[object Array]', '[object Object]'].indexOf(type) < 0) return false;

  // Compare the length of the length of the two items
  var valueLen = type === '[object Array]' ? value.length : Object.keys(value).length;
  var otherLen = type === '[object Array]' ? other.length : Object.keys(other).length;
  if (valueLen !== otherLen) return false;

  // Compare two items
  var compare = function (item1, item2) {
    // Get the object type
    var itemType = Object.prototype.toString.call(item1);

    // If an object or array, compare recursively
    if (['[object Array]', '[object Object]'].indexOf(itemType) >= 0) {
      if (!isEqual(item1, item2)) return false;
    }

    // Otherwise, do a simple comparison
    else {
      // If the two items are not the same type, return false
      if (itemType !== Object.prototype.toString.call(item2)) return false;

      // Else if it's a function, convert to a string and compare
      // Otherwise, just compare
      if (itemType === '[object Function]') {
        if (item1.toString() !== item2.toString()) return false;
      } else {
        if (item1 !== item2) return false;
      }
    }
  };

  // Compare properties
  if (type === '[object Array]') {
    for (var i = 0; i < valueLen; i++) {
      if (compare(value[i], other[i]) === false) return false;
    }
  } else {
    for (var key in value) {
      if (value.hasOwnProperty(key)) {
        if (compare(value[key], other[key]) === false) return false;
      }
    }
  }

  // If nothing failed, return true
  return true;
}
