import { ArrowLongIcon, BeautyLabSmallIcon } from '@/components/icons';
import { _s } from '@/locale';
import { Link } from 'react-router-dom';

const BackButton = () => {
  return (
    <div>
      <BeautyLabSmallIcon className="text-secondary-200 w-36" />
      <Link to="/blogg" className="text-secondary-500 hover:text-secondary-200 my-10 inline-flex py-4">
        <ArrowLongIcon className="mr-2 w-8 rotate-180" />
        {_s('blogPage.backToStart')}
      </Link>
    </div>
  );
};

export default BackButton;
