import Header from '@/components/elements/Header/Header';
import Select from '@/components/elements/forms/Select/Select';
import { Button } from '@/components/elements/forms/buttons';
import ListInput from '@/components/elements/forms/listInput/ListInput/ListInput';
import ListInputSelect from '@/components/elements/forms/listInput/ListInputSelect/ListInputSelect';
import ListMaskedInput from '@/components/elements/forms/listInput/ListMaskedInput/ListMaskedInput';
import Icon from '@/components/icons/Icon';
import { IconProps } from '@/components/icons/types';
import { isZipcode } from '@/helpers';
import { _s } from '@/locale';
import { UpdateProfileRequest } from '@/types/api/services/user';
import { USER_GENDERS, UserAboutEditableFields } from '@/types/user';
import * as moment from 'moment';
import { UseFormRegister, UseFormWatch } from 'react-hook-form';
import isLength from 'validator/lib/isLength';
import isMobilePhone from 'validator/lib/isMobilePhone';
import { z } from 'zod';
import { PROFILE_FORM_FIELD_ID } from '../checkout/CheckoutFormFields/CheckoutFormFields';
import { UseUpdateUserInfo } from './UpdateUserInfo.hooks';
type PROFILE_FORM_FIELD_ID_KEY = (typeof PROFILE_FORM_FIELD_ID)[keyof typeof PROFILE_FORM_FIELD_ID][];

export type UpdateUserInfoFieldKey =
  | PROFILE_FORM_FIELD_ID_KEY[number]
  | keyof Omit<UserAboutEditableFields, 'accountProtected'>;

const REQUIRED_FIELDS_MAP: { [key in UpdateUserInfoFieldKey]: boolean } = {
  givenName: true,
  familyName: true,
  phone: true,
  streetAddress: true,
  postalCode: true,
  locality: true,
  email: true,
  mobile: true,
  gender: false,
  dob: false,
};

export type UpdateUserInfoFields = {
  [key in UpdateUserInfoFieldKey]?: boolean;
};

const fieldIconMap: {
  [PROFILE_FORM_FIELD_ID_KEY in UpdateUserInfoFieldKey]: IconProps['variant'];
} = {
  givenName: 'account',
  familyName: 'account',
  phone: 'phone',
  streetAddress: 'home',
  postalCode: 'home',
  locality: 'home',
  email: 'email',
  mobile: 'phone',
  gender: 'user',
  dob: 'calendar-empty',
};

const baseTranslationKey = `components.modules.UpdateUserInfo`;
const getInputLabel = (id: UpdateUserInfoFieldKey) => _s(`${baseTranslationKey}.label.${id}`);
const getInputPlaceholder = (id: UpdateUserInfoFieldKey) => _s(`${baseTranslationKey}.placeholder.${id}`);

const fieldLabelMap: {
  [Key in UpdateUserInfoFieldKey]: string;
} = {
  givenName: getInputLabel('givenName'),
  familyName: getInputLabel('familyName'),
  phone: getInputLabel('phone'),
  streetAddress: getInputLabel('streetAddress'),
  postalCode: getInputLabel('postalCode'),
  locality: getInputLabel('locality'),
  mobile: getInputLabel('mobile'),
  email: getInputLabel('email'),
  dob: getInputLabel('dob'),
  gender: getInputLabel('gender'),
};

const fieldPlaceholderMap: {
  [Key in UpdateUserInfoFieldKey]: string;
} = {
  givenName: getInputPlaceholder('givenName'),
  familyName: getInputPlaceholder('familyName'),
  phone: getInputPlaceholder('phone'),
  streetAddress: getInputPlaceholder('streetAddress'),
  postalCode: getInputPlaceholder('postalCode'),
  locality: getInputPlaceholder('locality'),
  mobile: getInputPlaceholder('mobile'),
  email: getInputPlaceholder('email'),
  dob: getInputPlaceholder('dob'),
  gender: getInputPlaceholder('gender'),
};

const fieldTypeMap: {
  [Key in UpdateUserInfoFieldKey]: 'about' | 'contact';
} = {
  givenName: 'about',
  familyName: 'about',
  phone: 'contact',
  streetAddress: 'contact',
  postalCode: 'contact',
  locality: 'contact',
  email: 'contact',
  mobile: 'contact',
  dob: 'about',
  gender: 'about',
};

type FieldSection = 'about' | 'contact' | 'email' | 'phone';

const fieldSectionMap: {
  [Key in UpdateUserInfoFieldKey]: FieldSection;
} = {
  givenName: 'about',
  familyName: 'about',
  dob: 'about',
  gender: 'about',
  phone: 'phone',
  mobile: 'phone',
  streetAddress: 'contact',
  postalCode: 'contact',
  locality: 'contact',
  email: 'email',
};

type FieldInputType = 'text' | 'email' | 'tel' | 'select' | 'date';

const fieldInputTypeMap: {
  [Key in UpdateUserInfoFieldKey]: FieldInputType;
} = {
  email: 'email',
  givenName: 'text',
  familyName: 'text',
  phone: 'tel',
  streetAddress: 'text',
  postalCode: 'text',
  locality: 'text',
  mobile: 'tel',
  gender: 'select',
  dob: 'date',
};

const fieldAutocompleteMap: {
  [Key in UpdateUserInfoFieldKey]: React.InputHTMLAttributes<HTMLInputElement>['autoComplete'];
} = {
  email: 'email',
  givenName: 'given-name',
  familyName: 'family-name',
  phone: 'tel',
  streetAddress: 'street-address',
  postalCode: 'postal-code',
  locality: 'city',
  mobile: 'tel',
  gender: 'off',
  dob: 'off',
};

const selectOptionsMap: {
  [Key in UpdateUserInfoFieldKey]?: { value: string; label: string }[];
} = {
  gender: [
    ...USER_GENDERS.map((gender) => {
      return { value: gender, label: _s(`${baseTranslationKey}.selectOptionLabel.${gender}`) };
    }),
  ],
};

const fieldValidateFnMap = {
  phone: (value: string) =>
    !isMobilePhone(value.trim()) || !isLength(value.trim(), { min: 10, max: 14 }) ? _s('invalidPhone') : true,
  mobile: (value: string) =>
    !isMobilePhone(value.trim()) || !isLength(value.trim(), { min: 10, max: 14 }) ? _s('invalidPhone') : true,
  postalCode: (value: string) => (!isZipcode(value.trim()) ? _s('invalidZipcode') : true),
  dob: (value: string) => {
    if (value === '' || value === '____-__-__') return true;

    let valid = false;

    if (/^(\d{4})-(\d{2})-(\d{2})$/.test(value)) {
      const date = new Date(value);
      valid = !!(
        z.date().safeParse(date).success &&
        moment(date).isBefore(moment()) &&
        moment(date).isAfter(moment().subtract(100, 'years'))
      );
    }

    return valid || _s('invalidDoB');
  },
};

const RenderField = ({
  field,
  errors,
  inputType,
  register,
  watch,
}: {
  field: UpdateUserInfoFieldKey;
  errors: any;
  inputType: FieldInputType;
  register: UseFormRegister<UpdateProfileRequest>;
  watch: UseFormWatch<UpdateProfileRequest>;
}) => {
  const label = `${fieldLabelMap[field]}${REQUIRED_FIELDS_MAP?.[field] ? '*' : ''}`;
  const leftIcon = <Icon variant={fieldIconMap[field]} />;
  const type = fieldTypeMap[field];
  const validateFn = fieldValidateFnMap[field];
  const id = `${type}.${field}`;

  const { about, contact } = errors;
  const error = about?.[field]?.message ?? contact?.[field]?.message;

  const isRequired = REQUIRED_FIELDS_MAP?.[field] ?? false;
  const value = watch(type)?.[field] ?? '';

  if (field === 'dob') {
    return (
      <ListMaskedInput
        id={id}
        label={label}
        type="tel"
        leftIcon={leftIcon}
        placeholder={fieldPlaceholderMap[field]}
        autoComplete={fieldAutocompleteMap[field]}
        error={error ?? ''}
        mask="9999-99-99"
        {...register(id as any, {
          ...(validateFn && { validate: validateFn }),
        })}
      />
    );
  }

  if (inputType === 'select') {
    const options = selectOptionsMap[field] || [];
    return (
      <ListInputSelect
        id={id}
        label={label}
        leftIcon={leftIcon}
        value={value}
        error={error ?? ''}
        defaultValue=""
        {...register(id as any, {
          ...(isRequired && { required: _s('requiredField') }),
          ...(validateFn && { validate: validateFn }),
        })}>
        <Select.Option value="">{fieldPlaceholderMap[field]}</Select.Option>
        {options.map((option) => (
          <Select.Option key={option.value} value={option.value}>
            {option.label}
          </Select.Option>
        ))}
      </ListInputSelect>
    );
  }

  return (
    <ListInput
      {...(inputType === 'email' && { disabled: true })}
      id={id}
      label={label}
      type={inputType}
      leftIcon={leftIcon}
      placeholder={fieldPlaceholderMap[field]}
      autoComplete={fieldAutocompleteMap[field]}
      error={error ?? ''}
      {...register(id as any, {
        ...(inputType === 'date' && { valueAsDate: true }),
        ...(isRequired && { required: _s('requiredField') }),
        ...(validateFn && { validate: validateFn }),
        maxLength: { value: 100, message: _s('invalidLength', { length: 100 }) },
      })}
    />
  );
};

const UpdateUserInfo = ({
  handleSubmit,
  register,
  watch,
  errors,
  isValid,
  loading,
  submitLabel,
  ...fields
}: UseUpdateUserInfo & UpdateUserInfoFields & { submitLabel?: string }) => {
  const formfields = Object.keys(fields).map((f) => f) as UpdateUserInfoFieldKey[];

  const formfieldSections = formfields.reduce((acc: { [key in FieldSection]?: UpdateUserInfoFieldKey[] }, curr) => {
    const section = fieldSectionMap[curr];
    return { ...acc, [section]: [...(acc[section] ?? []), curr] };
  }, {});

  return (
    <form>
      {Object.keys(formfieldSections).map((section) => (
        <div key={section} className="pb-xxl">
          <div className="p-lg">
            <Header label={_s(`${baseTranslationKey}.section.${section}`)} size="lg" />
          </div>
          {formfieldSections[section].map((field) => {
            return (
              <RenderField
                key={field}
                field={field}
                watch={watch}
                register={register}
                errors={errors}
                inputType={fieldInputTypeMap[field]}
              />
            );
          })}
        </div>
      ))}
      <div className="py-lg px-lg">
        <Button type="button" onClick={handleSubmit} variant="primary" size="md" block className="relative">
          {submitLabel ?? _s(`${baseTranslationKey}.cta`)}
        </Button>
      </div>
    </form>
  );
};

export default UpdateUserInfo;
