import { listConstants } from '../constants';
const initialState = 'list';
export function listType(state = initialState, action) {
  switch (action.type) {
    case listConstants.mobileList:
      return 'list';
    case listConstants.mobileMap:
      return 'map';
    default:
      return state;
  }
}
