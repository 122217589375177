/* eslint-disable */
import { Button } from '@/components/elements/forms/buttons';
import PageViewLayout from '@/components/layouts/PageViewLayout/PageViewLayout';
import { AndroidTopBanner, IPhoneTopBanner } from '@/components/modules/mobile';
import {
  getHomeDescription,
  isSistaminuten,
  showAndroidTopBanner,
  showIphoneTopBanner,
  trackMpEvent,
  trackPage,
  url,
} from '@/helpers';
import { _s } from '@/locale';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';

function WaitingListSuccess(props) {
  useEffect(() => {
    trackPageVisit;
  }, []);

  const seo = () => {
    const title = _s('seo.homepageTitle');
    const description = getHomeDescription();
    const pageUrl = `${url.getBaseUrl()}/waiting-list/success`;
    const imageUrl = url.getBaseImageUrl();

    return (
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <link rel="canonical" href={pageUrl} />

        <meta property="og:url" content={pageUrl} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={imageUrl} />
        <meta name="robots" content="noindex" />
      </Helmet>
    );
  };

  const trackPageVisit = () => {
    trackPage();
    // trackMpEvent('screen_shown', { screen_name: 'waiting_list_success' });
  };

  const prepend = !isSistaminuten() ? (
    showIphoneTopBanner() ? (
      <IPhoneTopBanner source="company_details" />
    ) : showAndroidTopBanner() ? (
      <AndroidTopBanner source="company_details" />
    ) : null
  ) : null;

  const history = useHistory();

  return (
    <PageViewLayout
      type="mainView"
      prepend={prepend}
      footerDisplayOption={{ from: 'md', to: 'all' }}
      source="unsubscribe">
      <div className={`pb-safe container my-10 flex flex-row flex-col items-center md:flex-row md:flex-row-reverse`}>
        <div>
          <img width="487" height="375" src="/images/subscribed-to-waiting-list.png" alt="" />
        </div>
        <div className="text-center">
          <h1 className="mb-4 text-lg font-semibold">{_s('subscribeToWaitingListSuccessTitle')}</h1>
          <span className="mb-4 block">{_s('subscribeToWaitingListSuccessText1')}</span>
          <span className="mb-4 block">{_s('subscribeToWaitingListSuccessText2')}</span>
          <Button
            className="w-full"
            style={{ maxWidth: 200 }}
            onClick={() => {
              history.goBack();
            }}>
            {_s('goBack')}
          </Button>
        </div>
      </div>
      {seo()}
    </PageViewLayout>
  );
}

export default WaitingListSuccess;
