import breakpoints from '@/breakpoints';
import { isMobile, isServer, setMobile, unsetMobile } from '@/helpers';
import { useEffect, useState } from 'react';

const getScreenSize = () => {
  if (isServer) return { width: isMobile() ? breakpoints.sm : breakpoints.md };
  const { innerWidth: width } = window;
  return { width };
};

const useMobileView = () => {
  const [windowSize, setWindowSize] = useState<{ width: number }>(getScreenSize());
  const [isMobileView, setIsMobileView] = useState<boolean>(isMobile());

  useEffect(() => {
    if (windowSize.width < breakpoints.md) {
      setMobile();
      setIsMobileView(true);
      return;
    }
    unsetMobile();
    setIsMobileView(false);
  }, [windowSize]);

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(getScreenSize());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return { isMobileView, windowSize };
};

export default useMobileView;
