import * as animationData from '@/assets/animations/loadingDotsSecondary.json';
import AnimationWrapper from '../AnimationWrapper';
import { AnimationProps } from '../types';

const LoadingDotsSecondary = (props: AnimationProps) => {
  const animationProps = { ...props, animationData };
  return <AnimationWrapper {...animationProps} />;
};

export default LoadingDotsSecondary;
