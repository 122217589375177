import colors from '@/colors';
import BannerType1 from '@/components/elements/Banner/BannerType1/BannerType1';
import BannerType2 from '@/components/elements/Banner/BannerType2/BannerType2';
import BannerType3 from '@/components/elements/Banner/BannerType3/BannerType3';
import BannerType4 from '@/components/elements/Banner/BannerType4/BannerType4';
import BannerType5 from '@/components/elements/Banner/BannerType5/BannerType5';
import { BannerConfig, bannerConfigSchema } from '@/components/elements/Banner/types';
import Input from '@/components/elements/forms/Input/Input';
import { Switch } from '@/components/elements/forms/Switch';
import { Button } from '@/components/elements/forms/buttons';
import { ListItem, ListItemContent } from '@/components/elements/lists';
import Alert from '@/components/elements/notifications/Alert/Alert';
import Snackbar from '@/components/elements/notifications/Snackbar/Snackbar';
import Icon from '@/components/icons/Icon';
import PageViewLayout from '@/components/layouts/PageViewLayout/PageViewLayout';
import { classnames } from '@/helpers';

import useMobileView from '@/hooks/useMobileView';
import { useEffect, useReducer, useRef, useState } from 'react';
import { toast } from 'react-toastify';

const Banner = ({
  config,
  imageDimensionCb,
}: {
  config: BannerConfig;
  imageDimensionCb: ({ height, width }: { height: number; width: number }) => void;
}) => {
  const { isMobileView } = useMobileView();

  /**
   * We need the image dimensions for apps to be able to calculate the correct aspect ratio
   */
  const imgRef = useRef<HTMLImageElement | null>(null);

  useEffect(() => {
    if (!imgRef.current) return;

    imgRef.current.addEventListener('load', () => {
      imageDimensionCb({
        height: imgRef.current!.naturalHeight,
        width: imgRef.current!.naturalWidth,
      });
    });
  }, [config.background_image_md]);

  switch (config.type) {
    case 'type_1':
      return <BannerType1 size={isMobileView ? 'sm' : 'lg'} {...config} />;
    case 'type_2':
      return <BannerType2 {...config} />;
    case 'type_3':
      return <BannerType3 {...config} />;
    case 'type_4':
      return (
        <>
          <BannerType4 {...config} />
          {/* Just to render image to see intrinic size */}
          <img ref={imgRef} src={config.background_image_md} alt="" className="h-0 opacity-100" />
        </>
      );
    case 'type_5':
      return <BannerType5 {...config} />;
    default:
      const never: never = config.type;
      throw new Error(`Unknown banner type: ${never}`);
  }
};

const initialConfig: BannerConfig = {
  type: 'type_1',
  active: true,
  message: '',
} as const;

type ConfigAction = {
  type: 'SET_CONFIG';
  payload: Partial<BannerConfig>;
};

const configReducer = (state: BannerConfig, action: ConfigAction) => {
  switch (action.type) {
    case 'SET_CONFIG':
      const payload = action.payload;
      if (payload.type) {
        return { ...initialConfig, ...payload };
      }

      return { ...state, ...payload };
    default:
      return state;
  }
};

const GenerateBanner = () => {
  const [bannerConfig, dispatch] = useReducer(configReducer, initialConfig);
  const [copiedToClipBoard, setCopiedToClipBoard] = useState(false);
  const [showCountdown, setShowCountdown] = useState(false);
  const { isMobileView } = useMobileView();
  const [showCTA, setShowCTA] = useState(false);
  const [useImageBackground, setUseImageBackground] = useState(false);

  const filteredBannerConfig = Object.entries(bannerConfig).reduce((acc, [key, value]) => {
    if (value !== undefined && value !== null && value !== '') {
      acc[key] = value;
    }
    return acc;
  }, {});
  const bannerJSON = JSON.stringify(filteredBannerConfig, null, 2);

  useEffect(() => {
    setCopiedToClipBoard(false);
  }, [bannerJSON]);

  const copyToClipboard = (value) => {
    if (showCTA) {
      try {
        bannerConfigSchema.parse(bannerConfig);
      } catch (error) {
        toast(({ closeToast }) => <Snackbar label={error.message} onClose={closeToast} />);
        return;
      }
    }

    if (
      useImageBackground &&
      !Boolean(bannerConfig.background_image_lg && bannerConfig.background_image_md && bannerConfig.background_image_sm)
    ) {
      toast(({ closeToast }) => (
        <Snackbar label="Alla bilder måste finnas om en bild används" type="danger" onClose={closeToast} />
      ));
      return;
    }

    if (bannerConfig.image_url || bannerConfig.image_url_xs || bannerConfig.image_url_large) {
      if (!Boolean(bannerConfig.image_url && bannerConfig.image_url_large && bannerConfig.image_url_xs)) {
        toast(({ closeToast }) => (
          <Snackbar label="Alla bilder måste finnas om en bild används" type="danger" onClose={closeToast} />
        ));
      }
      return;
    }
    window.navigator.clipboard.writeText(value);
    setCopiedToClipBoard(true);
    setTimeout(() => {
      setCopiedToClipBoard(false);
    }, 2000);
    toast(({ closeToast }) => <Snackbar label="Kopierat till urklipp" type="success" onClose={closeToast} />, {
      autoClose: 2000,
    });
  };

  const handleToggleShowCTA = () => {
    if (showCTA) {
      dispatch({ type: 'SET_CONFIG', payload: { cta: null } });
    }
    setShowCTA((prev) => !prev);
  };

  const handleCTAVariantChange = (variant: BannerConfig['cta']['variant']) => {
    dispatch({ type: 'SET_CONFIG', payload: { cta: { ...bannerConfig.cta, variant } } });
  };

  const handleBackgroundImageChange = () => {
    dispatch({
      type: 'SET_CONFIG',
      payload: { background_image_lg: '', background_image_md: '', background_image_sm: '' },
    });
    setUseImageBackground((prev) => !prev);
  };

  const handleToggleShowCountdown = () => {
    if (showCountdown) {
      dispatch({ type: 'SET_CONFIG', payload: { countdownData: null } });
    }
    setShowCountdown((prev) => !prev);
  };

  const handleBackgroundImageDimensionReference = ({ height, width }: { height: number; width: number }) => {
    dispatch({
      type: 'SET_CONFIG',
      payload: { background_image_height_reference: height, background_image_width_reference: width },
    });
  };

  return (
    <PageViewLayout type="subView" title="Generera banner config" wrapperClass="bg-brown-50 min-h-screen">
      <div className="py-lg">
        <div className="gap-sm container flex flex-col">
          <Alert
            leftSlot={<Icon variant="info-circle" color="information-900" />}
            variant="information"
            body="Använd chrome devtools för att visa hur bannern ser ut i olika skärmstorlekar"
          />
          <div className="bg-black-50 text-information-700 p-lg relative overflow-x-scroll rounded">
            <div className="right-lg top-lg absolute">
              <Button variant="secondary" onClick={() => copyToClipboard(JSON.stringify(bannerConfig))}>
                {copiedToClipBoard ? 'Kopierat' : 'Kopiera till urklipp'}
              </Button>
            </div>
            <code style={{ whiteSpace: 'pre' }}>{bannerJSON}</code>
          </div>
        </div>

        <div className={classnames('my-lg py-lg', !isMobileView && 'container')}>
          <Banner config={bannerConfig} imageDimensionCb={handleBackgroundImageDimensionReference} />
        </div>

        <div className="p-md gap-md container flex flex-col bg-white">
          <h2 className="text-h-m font-semibold">Inställningar</h2>
          <section id="type" className="my-lg flex flex-col">
            <h3 className="text-md font-semibold">Typ</h3>
            <ListItem
              onClick={() => dispatch({ type: 'SET_CONFIG', payload: { type: 'type_1' } })}
              leftSlot={<Icon variant={bannerConfig.type === 'type_1' ? 'radio-checked' : 'radio-unchecked'} />}
              underline>
              <ListItemContent title="Typ 1" />
            </ListItem>
            <ListItem
              onClick={() => dispatch({ type: 'SET_CONFIG', payload: { type: 'type_2' } })}
              leftSlot={<Icon variant={bannerConfig.type === 'type_2' ? 'radio-checked' : 'radio-unchecked'} />}
              underline>
              <ListItemContent title="Typ 2" />
            </ListItem>
            <ListItem
              onClick={() => dispatch({ type: 'SET_CONFIG', payload: { type: 'type_3' } })}
              leftSlot={<Icon variant={bannerConfig.type === 'type_3' ? 'radio-checked' : 'radio-unchecked'} />}
              underline>
              <ListItemContent title="Typ 3" />
            </ListItem>
            <ListItem
              onClick={() => dispatch({ type: 'SET_CONFIG', payload: { type: 'type_4' } })}
              leftSlot={<Icon variant={bannerConfig.type === 'type_4' ? 'radio-checked' : 'radio-unchecked'} />}
              underline>
              <ListItemContent title="Typ 4" />
            </ListItem>
            <ListItem
              onClick={() => dispatch({ type: 'SET_CONFIG', payload: { type: 'type_5' } })}
              leftSlot={<Icon variant={bannerConfig.type === 'type_5' ? 'radio-checked' : 'radio-unchecked'} />}
              underline>
              <ListItemContent title="Typ 5" />
            </ListItem>
          </section>

          {(bannerConfig.type === 'type_2' || bannerConfig.type === 'type_5') && (
            <section id="title" className="my-lg flex flex-col">
              <label htmlFor="title" className="text-md font-semibold">
                Titel
              </label>
              <textarea
                style={{ resize: 'none', minHeight: '200px' }}
                className="p-sm border-black-100 rounded border"
                id="title"
                placeholder="Titel (Markdown)"
                value={bannerConfig.title}
                onChange={(e) => dispatch({ type: 'SET_CONFIG', payload: { title: e.target.value } })}
              />
            </section>
          )}

          {bannerConfig.type !== 'type_4' && (
            <section id="text" className="my-lg flex flex-col">
              <label htmlFor="message" className="text-md font-semibold">
                Text
              </label>
              <textarea
                style={{ resize: 'none', minHeight: '200px' }}
                className="p-sm border-black-100 rounded border"
                id="message"
                placeholder="Text (Markdown)"
                value={bannerConfig.message}
                onChange={(e) => dispatch({ type: 'SET_CONFIG', payload: { message: e.target.value } })}
              />
            </section>
          )}

          {bannerConfig.type === 'type_3' && (
            <>
              <h2 className="text-md font-semibold">Använd countdown</h2>
              <ListItem
                onClick={handleToggleShowCountdown}
                leftSlot={<Switch switched={showCountdown} onChange={() => null} />}
                underline>
                <ListItemContent title="Använd countdown" />
              </ListItem>
            </>
          )}
          {(showCountdown || bannerConfig.type === 'type_5') && (
            <section id="countdown" className="my-lg flex flex-col">
              <h2 className="text-md font-semibold">Countdown</h2>
              <div className="py-md flex max-w-md flex-col">
                <label htmlFor="countdown" className="text-sm">
                  Datum/tid
                </label>
                <input
                  type="datetime-local"
                  id="countdown"
                  onChange={(e) =>
                    dispatch({
                      type: 'SET_CONFIG',
                      payload: {
                        countdownData: {
                          ...bannerConfig.countdownData,
                          endDate: (() => {
                            const date = new Date(e.target.value);
                            const timestamp = date.getTime();
                            return timestamp;
                          })(),
                        },
                      },
                    })
                  }
                />
              </div>
              <ListItem
                leftSlot={
                  <input
                    defaultValue={bannerConfig.countdownData?.countdown_color ?? colors.black[900]}
                    type="color"
                    onChange={(e) =>
                      dispatch({
                        type: 'SET_CONFIG',
                        payload: { countdownData: { ...bannerConfig.countdownData, countdown_color: e.target.value } },
                      })
                    }
                  />
                }>
                <ListItemContent title="textfärg på timer (20:03:20:37)" />
              </ListItem>
              {bannerConfig.type === 'type_5' && (
                <ListItem
                  leftSlot={
                    <input
                      defaultValue={bannerConfig.countdownData?.countdown_description_color ?? colors.black[900]}
                      type="color"
                      onChange={(e) =>
                        dispatch({
                          type: 'SET_CONFIG',
                          payload: {
                            countdownData: {
                              ...bannerConfig.countdownData,
                              countdown_description_color: e.target.value,
                            },
                          },
                        })
                      }
                    />
                  }>
                  <ListItemContent title="textfärg på (dagar, timmer, minuter, sekunder)" />
                </ListItem>
              )}
            </section>
          )}

          <section id="background" className="my-lg flex flex-col">
            {bannerConfig.type !== 'type_4' && (
              <>
                <h2 className="text-md font-semibold">Bakgrundsbild/bakgrundsfärg</h2>
                <ListItem
                  onClick={handleBackgroundImageChange}
                  leftSlot={<Switch switched={useImageBackground} onChange={() => null} />}
                  underline>
                  <ListItemContent title="Använd bild som bakgrund" />
                </ListItem>
              </>
            )}
            {bannerConfig.type === 'type_4' || useImageBackground ? (
              <div className="flex flex-col">
                <Alert
                  leftSlot={<Icon variant="info-circle" color="warning-900" />}
                  variant="cation"
                  body="Finns plats för tre olika storlekar nu men kan hända vi behöver fler för att de ska se bra ut i alla skärmstorlekar"
                />
                <ListItem>
                  <label htmlFor="image-background-url-xs" className="text-m font-semibold">
                    upp till 340px
                  </label>
                  <Input
                    id="image-background-url-sm"
                    placeholder="small url"
                    value={bannerConfig.background_image_sm}
                    onChange={(e) => dispatch({ type: 'SET_CONFIG', payload: { background_image_sm: e.target.value } })}
                  />
                </ListItem>
                <ListItem>
                  <label htmlFor="image-background-url-md" className="text-m font-semibold">
                    upp till 768px
                  </label>
                  <Input
                    id="image-background-url-md"
                    placeholder="medium url"
                    value={bannerConfig.background_image_md}
                    onChange={(e) => dispatch({ type: 'SET_CONFIG', payload: { background_image_md: e.target.value } })}
                  />
                </ListItem>
                <ListItem>
                  <label htmlFor="image-background-url-lg" className="text-m font-semibold">
                    över 768px
                  </label>
                  <Input
                    id="image-background-url-lg"
                    placeholder="large url"
                    value={bannerConfig.background_image_lg}
                    onChange={(e) => dispatch({ type: 'SET_CONFIG', payload: { background_image_lg: e.target.value } })}
                  />
                </ListItem>
                <ListItem>
                  <label htmlFor="image-background-url-lg" className="text-m font-semibold">
                    Bild text (för skärmläsare/Accesibility)
                  </label>
                  <Input
                    id="background-alt-text"
                    placeholder=""
                    value={bannerConfig.background_alt_text}
                    onChange={(e) => dispatch({ type: 'SET_CONFIG', payload: { background_alt_text: e.target.value } })}
                  />
                </ListItem>
              </div>
            ) : (
              <ListItem
                leftSlot={
                  <input
                    defaultValue={bannerConfig.background_color ?? '#FFFFFF'}
                    type="color"
                    onChange={(e) => dispatch({ type: 'SET_CONFIG', payload: { background_color: e.target.value } })}
                  />
                }>
                <ListItemContent title="Bakgrundsfärg" />
              </ListItem>
            )}
          </section>
          {bannerConfig.type !== 'type_4' && (
            <section id="text-colors" className="my-lg flex flex-col">
              <h2 className="text-md font-semibold">Textfärger</h2>
              <ListItem
                leftSlot={
                  <input
                    defaultValue={bannerConfig.text_color ?? colors['black'][900]}
                    type="color"
                    onChange={(e) => dispatch({ type: 'SET_CONFIG', payload: { text_color: e.target.value } })}
                  />
                }>
                <ListItemContent title="Textfärg" />
              </ListItem>
              <ListItem
                leftSlot={
                  <input
                    defaultValue={bannerConfig.text_link_color ?? colors['information'][700]}
                    type="color"
                    onChange={(e) => dispatch({ type: 'SET_CONFIG', payload: { text_link_color: e.target.value } })}
                  />
                }>
                <ListItemContent title="Länkfärg (i markdown)" />
              </ListItem>
            </section>
          )}
          {bannerConfig.type === 'type_1' && (
            <section id="image" className="gap-sm flex flex-col">
              <h3 className="text-md font-semibold">Kampanj Bakgrundsbild (högra bilden)</h3>
              <div>
                <label htmlFor="image-url" className="text-m font-semibold">
                  xs (132px/190px)
                </label>
                <Input
                  id="image-url-xs"
                  placeholder="xsmall url"
                  value={bannerConfig.image_url_xs}
                  onChange={(e) => dispatch({ type: 'SET_CONFIG', payload: { image_url_xs: e.target.value } })}
                />
              </div>
              <div>
                <label htmlFor="image-url" className="text-m font-semibold">
                  (small&medium) (336px/190px)
                </label>
                <Input
                  id="image-url small"
                  placeholder="medium url"
                  value={bannerConfig.image_url}
                  onChange={(e) => dispatch({ type: 'SET_CONFIG', payload: { image_url: e.target.value } })}
                />
              </div>
              <div>
                <label htmlFor="image-url-large" className="text-m font-semibold">
                  large (396px/300px)
                </label>
                <Input
                  id="image-url-large"
                  placeholder="large url"
                  value={bannerConfig.image_url_large}
                  onChange={(e) => dispatch({ type: 'SET_CONFIG', payload: { image_url_large: e.target.value } })}
                />
              </div>
            </section>
          )}
          <ListItem
            onClick={() => dispatch({ type: 'SET_CONFIG', payload: { active: !bannerConfig.active } })}
            leftSlot={<Switch onChange={() => null} switched={bannerConfig.active} />}
            underline>
            <ListItemContent title="Visa banner" />
          </ListItem>
          <section id="CTA" className="flex flex-col">
            <ListItem
              onClick={handleToggleShowCTA}
              leftSlot={<Switch onChange={() => null} switched={showCTA} />}
              underline>
              <ListItemContent title="Visa CTA" />
            </ListItem>
            {showCTA && (
              <section className="gap-sm p-lg flex flex-col">
                <h2 className="text-lg font-semibold">CTA settings</h2>
                <Input
                  id="cta-title"
                  placeholder="titel"
                  required
                  value={bannerConfig.cta?.title ?? ''}
                  onChange={(e) =>
                    dispatch({ type: 'SET_CONFIG', payload: { cta: { ...bannerConfig.cta, title: e.target.value } } })
                  }
                />
                <Input
                  id="cta-url"
                  placeholder="url"
                  required
                  value={bannerConfig.cta?.url ?? ''}
                  onChange={(e) =>
                    dispatch({ type: 'SET_CONFIG', payload: { cta: { ...bannerConfig.cta, url: e.target.value } } })
                  }
                />
                <Input
                  id="cta-amplitude-event"
                  placeholder="amplitude event"
                  required
                  value={bannerConfig.cta?.amplitude_event ?? ''}
                  onChange={(e) =>
                    dispatch({
                      type: 'SET_CONFIG',
                      payload: { cta: { ...bannerConfig.cta, amplitude_event: e.target.value } },
                    })
                  }
                />

                {bannerConfig.type !== 'type_3' && (
                  <div className="p-lg bg-white">
                    <h3 className="text-md font-semibold">CTA typ</h3>
                    <ListItem
                      onClick={() => handleCTAVariantChange('primary')}
                      leftSlot={
                        <Icon variant={bannerConfig.cta?.variant === 'primary' ? 'radio-checked' : 'radio-unchecked'} />
                      }>
                      <ListItemContent title="primary" />
                    </ListItem>
                    <ListItem
                      onClick={() => handleCTAVariantChange('secondary')}
                      leftSlot={
                        <Icon
                          variant={bannerConfig.cta?.variant === 'secondary' ? 'radio-checked' : 'radio-unchecked'}
                        />
                      }>
                      <ListItemContent title="secondary" />
                    </ListItem>
                    <ListItem
                      onClick={() => handleCTAVariantChange('tertiary')}
                      leftSlot={
                        <Icon
                          variant={bannerConfig.cta?.variant === 'tertiary' ? 'radio-checked' : 'radio-unchecked'}
                        />
                      }>
                      <ListItemContent title="tertiary" />
                    </ListItem>
                    <ListItem
                      onClick={() => handleCTAVariantChange('link')}
                      leftSlot={
                        <Icon variant={bannerConfig.cta?.variant === 'link' ? 'radio-checked' : 'radio-unchecked'} />
                      }>
                      <ListItemContent title="link" />
                    </ListItem>
                  </div>
                )}
              </section>
            )}
          </section>
        </div>
      </div>
    </PageViewLayout>
  );
};

export default GenerateBanner;
