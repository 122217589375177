/* eslint-disable */
import { Button } from '@/components/elements/forms/buttons';
import { LoadingIcon } from '@/components/icons';
import PageViewLayout from '@/components/layouts/PageViewLayout/PageViewLayout';

import { AndroidTopBanner, IPhoneTopBanner } from '@/components/modules/mobile';
import {
  getHomeDescription,
  isSistaminuten,
  showAndroidTopBanner,
  showIphoneTopBanner,
  trackPage,
  url,
  useUnsubscribeForm,
} from '@/helpers';
import useScrollToTop from '@/hooks/useScrollToTop';
import { _s } from '@/locale';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import isEmail from 'validator/lib/isEmail';

function Unsubscribe(props) {
  useScrollToTop();
  useEffect(() => {
    trackPageVisit;
  }, []);

  const seo = () => {
    const title = _s('seo.unsubscribeTitle');
    const description = getHomeDescription();
    const pageUrl = `${url.getBaseUrl()}/unsubscribe`;
    const imageUrl = url.getBaseImageUrl();
    const isNoIndex = isSistaminuten();

    return (
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <link rel="canonical" href={pageUrl} />

        <meta property="og:url" content={pageUrl} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={imageUrl} />
        {isNoIndex && <meta name="robots" content="noindex" />}
      </Helmet>
    );
  };

  const trackPageVisit = () => {
    trackPage();
    // trackMpEvent('screen_shown', { screen_name: 'unsubscribe' });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    submitForm(unsubscribe);
  };

  const [unsubscribe, setUnsubscribe] = useState(true);
  const { emailHash, deliveryId } = props.match.params;

  let email = '';
  try {
    email = atob(emailHash);
  } catch (e) {}

  const [loading, subscribeMessage, subscribeError, submitForm] = useUnsubscribeForm(email, deliveryId, setUnsubscribe);

  const domain = isSistaminuten() ? 'sistaminutentider.se' : 'bokadirekt.se';
  const description = !!unsubscribe
    ? _s('unsubscribe.confirmUnsubscribe', { domain })
    : _s('unsubscribe.unsubscribedSuccess', { domain });
  const subdescription = !!unsubscribe ? null : <p className="mb-4">{_s(`unsubscribe.unsubscribeByMistake`)}</p>;
  const button = !!unsubscribe ? _s('unsubscribe.cancelSubscription') : _s('unsubscribe.subscribe');

  const prepend = !isSistaminuten() ? (
    showIphoneTopBanner() ? (
      <IPhoneTopBanner source="company_details" />
    ) : showAndroidTopBanner() ? (
      <AndroidTopBanner source="company_details" />
    ) : null
  ) : null;

  return (
    <PageViewLayout
      type="mainView"
      prepend={prepend}
      footerDisplayOption={{ from: 'md', to: 'all' }}
      source="unsubscribe">
      <div className={`pb-safe container relative`}>
        <h1 className={`block py-6 text-2xl font-semibold`}>{_s('unsubscribe.newsletter')}</h1>
        <p className="mb-4">{_s(`${description}`)}</p>
        <form className={`${loading ? 'opacity-0' : ''} transition-opacity`} onSubmit={onSubmit}>
          <input type="hidden" id="email" name="email" value={email} />
          {isSistaminuten() && (
            <input
              type="hidden"
              id="unsubscribed_sistaminutentider"
              name="unsubscribed_sistaminutentider"
              value="true"
            />
          )}
          {!isSistaminuten() && (
            <input type="hidden" id="unsubscribed_slutkunder" name="unsubscribed_slutkunder" value="true" />
          )}
          {subdescription}
          <Button className="mb-6" disabled={loading || !isEmail(email)}>
            {button}
          </Button>
        </form>
        {loading && <LoadingIcon className="absolute left-1/2 top-1/2 h-10 w-10 -translate-x-1/2 -translate-y-1/2" />}
        {subscribeError && <div className="text-danger">{subscribeMessage}</div>}
      </div>
      {seo()}
    </PageViewLayout>
  );
}

export default Unsubscribe;
