import { Button } from '@/components/elements/forms/buttons';
import { ChevronIcon } from '@/components/icons';
import { isMobile } from '@/helpers';
import { _s } from '@/locale';

type PaginationProps = {
  results: number;
  page?: number;
  itemsPerPage: number;
  nextPage: () => void;
  prevPage: () => void;
};

const Pagination = ({ results, page = 0, itemsPerPage, prevPage, nextPage }: PaginationProps) => {
  if (!results || results <= itemsPerPage) return null;
  const total = Math.ceil(results / itemsPerPage);

  return isMobile() ? (
    <div className="pagination flex flex-row items-center justify-between px-4 py-3">
      <div className="flex w-[120px] justify-start">
        {page > 0 && <Button onClick={prevPage} leftIcon={<ChevronIcon className="h-3 w-3 rotate-90" />} />}
      </div>
      <span className="flex-1 text-center font-semibold">{page + 1 + _s('of') + total}</span>
      {hasNextPage(results, page, itemsPerPage) && (
        <div className="flex w-[120px] justify-end">
          <Button onClick={nextPage} rightIcon={<ChevronIcon className="h-3 w-3 -rotate-90" />}>
            {_s('next')}
          </Button>
        </div>
      )}
    </div>
  ) : (
    <nav>
      <ul className="flex list-none items-center rounded">
        <li>
          <button
            className={`border-black-200 flex h-8 w-9 items-center justify-center rounded border-[1px] outline-none ${
              page !== 0 ? 'bg-white shadow' : 'bg-black-200'
            }`}
            onClick={prevPage}>
            <ChevronIcon className={`h-4 w-4 rotate-90 ${page !== 0 ? 'text-black-900' : 'text-black-400'}`} />
          </button>
        </li>
        <li>
          <button
            className={`border-black-200 flex h-8 w-9 items-center justify-center rounded border-[1px] outline-none ${
              hasNextPage(results, page, itemsPerPage) ? 'bg-white shadow' : 'bg-black-200'
            }`}
            onClick={nextPage}>
            <ChevronIcon className="h-4 w-4 -rotate-90" />
          </button>
        </li>
        <li className="text-black-600 ml-3 text-sm">
          {_s('paginationInfo1')}{' '}
          <span>
            {page * itemsPerPage + 1} -{' '}
            {page * itemsPerPage + itemsPerPage > results ? results : page * itemsPerPage + itemsPerPage}
          </span>{' '}
          {_s('paginationInfo2')} <span>{results}</span>
        </li>
      </ul>
    </nav>
  );
};

export const hasNextPage = (results: number, page: number, itemsPerPage: number): boolean =>
  (+page + 1) * itemsPerPage < results;

export default Pagination;
