import Card from '@/components/elements/Card/Card';
import { Link } from '@/components/elements/content';
import { LinkButton } from '@/components/elements/forms/buttons';
import Icon from '@/components/icons/Icon';
import { moneyToDecimal } from '@/helpers';
import { _s } from '@/locale';
import { KPOrderLine } from '@/types/klarnaPayments';
import { useState } from 'react';

const base = 'klarnaPayments.confirmation';

const getPaymentMethodDescription = (paymentMethod: string) => {
  switch (paymentMethod) {
    case 'Pay later':
      return 'Klarna pay later';
    case 'Pay by card':
      return 'Klarna pay by card';
    default:
      return paymentMethod;
  }
};

const OrderLine = ({ itemName, price }: { itemName: string; price: number }) => (
  <ItemLine itemName={itemName} description={`${moneyToDecimal(price)} kr`} />
);

const ItemLine = ({ itemName, description }: { itemName: string; description: string }) => (
  <div className="py-md flex items-center justify-between">
    <span className="text-black-600">{itemName}</span>
    <span className="text-black">{description}</span>
  </div>
);

const OrderDetails = ({ orderLines, tax }: { orderLines: KPOrderLine[]; tax: number }) => {
  const [expanded, setExpanded] = useState<boolean>(false);

  const showDetailsText = _s(`${base}.${expanded ? 'hide' : 'show'}Details`);

  return (
    <div>
      <div className="py-md flex items-center justify-between">
        <span>{_s(`${base}.orderDetails`)}</span>
        <button className="underline" onClick={() => setExpanded(!expanded)}>
          {showDetailsText}
        </button>
      </div>
      {expanded &&
        orderLines.map((ol) => <OrderLine key={ol.name} itemName={ol.name} price={ol.unit_price * ol.quantity} />)}
      {expanded && <OrderLine itemName={_s(`${base}.tax`)} price={tax} />}
    </div>
  );
};

type KlarnaPaymentsConfirmationProps = {
  email: string;
  orderLines: KPOrderLine[];
  tax: number;
  totalAmount: number;
  paymentMethod: string;
  ctaButtonText?: string;
  ctaButtonLink?: string;
};

export const KlarnaPaymentsPurchaseConfirmation = ({
  orderLines,
  email,
  tax,
  totalAmount,
  paymentMethod,
  ctaButtonText,
  ctaButtonLink,
}: KlarnaPaymentsConfirmationProps) => (
  <Card className="mx-auto max-w-lg">
    <div className="flex w-full flex-col">
      <div>
        <Icon variant={'check-circle-alt'} className="h-12 w-12" />
      </div>
      <h1 className="my-4 text-lg font-bold">{_s(`${base}.title`)}</h1>
      <p className="text-black-600">{_s(`${base}.text`, { email })}</p>
      <OrderDetails orderLines={orderLines} tax={tax} />
      <OrderLine itemName={_s(`${base}.totalAmount`)} price={totalAmount} />
      <ItemLine itemName={_s(`${base}.paymentMethod`)} description={getPaymentMethodDescription(paymentMethod)} />
      {ctaButtonLink && ctaButtonText && (
        <LinkButton block size="lg" variant="primary" to={ctaButtonLink} className="mb-4">
          {ctaButtonText}
        </LinkButton>
      )}
      <p className="text-black-600">
        {_s(`${base}.contactSupport`)} <Link to="/support/betalning">Bokadirekt support</Link>
      </p>
    </div>
  </Card>
);

type KlarnaPaymentsBookingConfirmationProps = {
  orderLines: KPOrderLine[];
  tax: number;
  totalAmount: number;
  paymentMethod: string;
  email: string;
};

export const KlarnaPaymentsBookingConfirmation = ({
  orderLines,
  email,
  paymentMethod,
}: KlarnaPaymentsBookingConfirmationProps) => (
  <>
    <p className="text-black-600 py-md">{_s(`${base}.text`, { email })}</p>
    {orderLines.map((ol) => (
      <OrderLine key={ol.name} itemName={ol.name} price={ol.unit_price * ol.quantity} />
    ))}
    <ItemLine itemName={_s(`${base}.paymentMethod`)} description={getPaymentMethodDescription(paymentMethod)} />
  </>
);
