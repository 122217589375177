const colors = {
  brand: {
    50: '#E6F2F0',
    200: '#A1D6CB',
    500: '#51A492',
    DEFAULT: '#51A492',
    700: '#18735F',
    900: '#0D4035',
  },

  primary: {
    50: '#FFFFFF',
    200: '#F2F7FA',
    500: '#E6EDF2',
    DEFAULT: '#092A4E',
    700: '#1F4063',
    900: '#092A4E',
  },

  // Purple in design system
  secondary: {
    50: '#F0E6F2',
    200: '#DBB4E5',
    500: '#5C246B',
    DEFAULT: '#5C246B',
    700: '#421150',
    900: '#2A0634',
  },

  black: {
    50: '#F2F7FA',
    100: '#E6EDF2',
    200: '#D9E0E7',
    300: '#B8C3CC',
    400: '#A0A8AF',
    500: '#73828F',
    600: '#506172',
    700: '#3B4A58',
    800: '#273746',
    900: '#001A33',
    950: '#041325',
    DEFAULT: '#000000',
  },

  neutral: {
    50: '#F8F8F8',
    100: '#F2F2F2',
    200: '#E4E4E4',
    300: '#D2D2D2',
    400: '#ABABAB',
    500: '#919191',
    DEFAULT: '#D2D2D2',
  },

  brown: {
    /**
     * @deprecated do not use on new stylings
     */
    100: '#FFE3DB',
    300: '#FECABC',
    400: '#FEC5B5',
    600: '#EA8D74',
    800: '#571807',

    /**
     * Use these values
     */
    50: '#FFF7F5',
    200: '#FFD7CC',
    500: '#F9B39F',
    700: '#7D2B14',
    900: '#2C0B02',
  },

  // Blue in design system
  information: {
    50: '#EAF0F6',
    200: '#B6D6F6',
    500: '#71A4D9',
    DEFAULT: '#71A4D9',
    700: '#336CA8',
    900: '#092A4E',
  },

  // Green in design system
  success: {
    50: '#E4F0E9',
    200: '#CEEDDA',
    500: '#398758',
    DEFAULT: '#398758',
    700: '#1A6437',
    900: '#063418',
  },

  // Red in design system
  danger: {
    50: '#F0E5E4',
    200: '#D6A7A1',
    500: '#C04C3D',
    DEFAULT: '#C04C3D',
    700: '#982A1B',
    900: '#570F06',
  },

  // Salmon in design system
  highlight: {
    50: '#F8EFEE',
    200: '#F8C9C6',
    500: '#F86A61',
    DEFAULT: '#F86A61',
    700: '#A1423C',
    900: '#572522',
  },

  // Yellow in design system
  warning: {
    50: '#F9F6EC',
    200: '#F4E5B1',
    500: '#F5CC3B',
    DEFAULT: '#F5CC3B',
    700: '#C89F0C',
    900: '#846804',
  },
} as const;

export const backgroundColors = {
  bg_primary: 'var(--color-bg-primary)',
  bg_primary_hover: 'var(--color-bg-primary-hover)',
  bg_primary_pressed: 'var(--color-bg-primary-pressed)',
  bg_primary_shade: 'var(--color-bg-primary-shade)',
  bg_secondary: 'var(--color-bg-secondary)',
  bg_secondary_light: 'var(--color-bg-secondary-light)',
  bg_secondary_bold: 'var(--color-bg-secondary-bold)',
  bg_secondary_hover: 'var(--color-bg-secondary-hover)',
  bg_secondary_pressed: 'var(--color-bg-secondary-pressed)',
  bg_secondary_selected: 'var(--color-bg-secondary-selected)',
  bg_secondary_shade: 'var(--color-bg-secondary-shade)',
  bg_neutral: 'var(--color-bg-neutral)',
  bg_neutral_alwaysLight: 'var(--color-bg-neutral-alwaysLight)',
  bg_neutral_alwaysDark: 'var(--color-bg-neutral-alwaysDark)',
  bg_neutral_alwaysDark_hover: 'var(--color-bg-neutral-alwaysDark-hover)',
  bg_neutral_alwaysDark_pressed: 'var(--color-bg-neutral-alwaysDark-pressed)',
  bg_inverse: 'var(--color-bg-inverse)',
  bg_brand: 'var(--color-bg-brand)',
  bg_brand_hover: 'var(--color-bg-brand-hover)',
  bg_brand_pressed: 'var(--color-bg-brand-pressed)',
  bg_brand_shade: 'var(--color-bg-brand-shade)',
  bg_info: 'var(--color-bg-info)',
  bg_info_bold: 'var(--color-bg-info-bold)',
  bg_notice: 'var(--color-bg-notice)',
  bg_notice_bold: 'var(--color-bg-notice-bold)',
  bg_negative: 'var(--color-bg-negative)',
  bg_negative_bold: 'var(--color-bg-negative-bold)',
  bg_negative_hover: 'var(--color-bg-negative-hover)',
  bg_negative_pressed: 'var(--color-bg-negative-pressed)',
  bg_negative_shade: 'var(--color-bg-negative-shade)',
  bg_positive: 'var(--color-bg-positive)',
  bg_positive_bold: 'var(--color-bg-positive-bold)',
  bg_accent_2: 'var(--color-bg-accent-2)',
  bg_accent_3: 'var(--color-bg-accent-3)',
  bg_accent_4: 'var(--color-bg-accent-4)',
  bg_accent_5: 'var(--color-bg-accent-5)',
  bg_accent_6: 'var(--color-bg-accent-6)',
  bg_accent_7: 'var(--color-bg-accent-7)',
};

export const borderColors = {
  border_regular: 'var(--color-border-regular)',
  border_bold: 'var(--color-border-bold)',
  border_disabled: 'var(--color-border-disabled)',
  border_inverse: 'var(--color-border-inverse)',
  border_selected: 'var(--color-border-selected)',
  border_menu: 'var(--color-border-menu)',
  border_brand: 'var(--color-border-brand)',
  border_focus: 'var(--color-border-focus)',
  border_info: 'var(--color-border-info)',
  border_notice: 'var(--color-border-notice)',
  border_negative: 'var(--color-border-negative)',
  border_positive: 'var(--color-border-positive)',
};

export const foregroundColors = {
  fg_primary: 'var(--color-fg-primary)',
  fg_secondary: 'var(--color-fg-secondary)',
  fg_tertiary: 'var(--color-fg-tertiary)',
  fg_primary_inverse: 'var(--color-fg-primary-inverse)',
  fg_secondary_inverse: 'var(--color-fg-secondary-inverse)',
  fg_tertiary_inverse: 'var(--color-fg-tertiary-inverse)',
  fg_disabled: 'var(--color-fg-disabled)',
  fg_alwaysLight: 'var(--color-fg-alwaysLight)',
  fg_alwaysDark: 'var(--color-fg-alwaysDark)',
  fg_brand: 'var(--color-fg-brand)',
  fg_brand_bold: 'var(--color-fg-brand-bold)',
  fg_link_light: 'var(--color-fg-link-light)',
  fg_link: 'var(--color-fg-link)',
  fg_link_bold: 'var(--color-fg-link-bold)',
  fg_link_hover: 'var(--color-fg-link-hover)',
  fg_link_pressed: 'var(--color-fg-link-pressed)',
  fg_info: 'var(--color-fg-info)',
  fg_info_bold: 'var(--color-fg-info-bold)',
  fg_notice: 'var(--color-fg-notice)',
  fg_notice_bold: 'var(--color-fg-notice-bold)',
  fg_negative: 'var(--color-fg-negative)',
  fg_negative_bold: 'var(--color-fg-negative-bold)',
  fg_positive: 'var(--color-fg-positive)',
  fg_positive_bold: 'var(--color-fg-positive-bold)',
  fg_highlight: 'var(--color-fg-highlight)',
};

export const surfaceColors = {
  surface_l0: 'var(--color-surface-l0)',
  surface_l1: 'var(--color-surface-l1)',
  surface_l2: 'var(--color-surface-l2)',
  surface_l3: 'var(--color-surface-l3)',
  surface_l4: 'var(--color-surface-l4)',
};

type Color = typeof colors;
type ColorKey = keyof Color;
type ColorShade = {
  [K in keyof Color]: keyof Color[K];
}[keyof Color];
export type ColorNames = `${ColorKey}-${ColorShade}`;

export const colorNames = Object.keys(colors).reduce((acc, color) => {
  const colorShades = Object.keys(colors[color])
    .map((shade) => (shade !== 'DEFAULT' ? `${color}-${shade}` : null))
    .filter((v) => v) as ColorNames[];
  acc.push(...colorShades);
  return acc;
}, [] as ColorNames[]);

export default colors;
