import { hasFeatureSetting, isMobile, isSistaminuten } from '@/helpers';
import withAmplitudeExperiment from '@/hoc/withAmplitudeExperiment';
import { __ } from '@/locale';
import React from 'react';
import { connect } from 'react-redux';
import Bundles from './Bundles';
import Campaigns from './Campaigns';
import FilterServicesInput from './FilterServicesInput';
import ListView from './ListView';
import ServicesCategory from './ServicesCategory';

class BookAppointment extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      filterQuery: '',
    };

    this.filterServices = this.filterServices.bind(this);
  }

  filterServices = (query) => {
    this.setState({ filterQuery: query });
  };

  getServicesHtml(place) {
    let isOpen = isMobile() ? false : true;
    if (place.services && place.services.length === 1) {
      isOpen = true;
    }

    if (this.state.filterQuery) {
      isOpen = true;
    }

    const hasCollapsedSetting = hasFeatureSetting(place, 'collapsed_services');

    const services = place.services
      ? place.services
          .filter((category) => category.services?.length)
          .map((category, i) => (
            <ServicesCategory
              filterQuery={this.state.filterQuery}
              place={place}
              servicesCategory={category}
              key={i}
              index={i}
              isOpen={!hasCollapsedSetting && (i === 0 || isOpen)}
              source={'company_details'}
            />
          ))
      : null;

    const bokadirektPlaceUrl = `https://www.bokadirekt.se/places/${place?.about?.slug}-${place?.id}`;
    const noServices = (
      <>
        {!isSistaminuten() && <p className="no-items mb-4">{__('noServices')}</p>}
        {isSistaminuten() && (
          <p className="no-items mb-4">
            {__('notAvailableSistaminutentider')}
            <a href={bokadirektPlaceUrl} target="_blank" rel="noreferrer" className="text-black-700 underline">
              Bokadirekt
            </a>
          </p>
        )}
        <img src="/images/no-service-ph.svg" alt="" className="mx-auto" />
      </>
    );

    return (
      <div id="services" className="services" data-children=".item">
        {services && services.length > 0 ? services : noServices}
      </div>
    );
  }

  render() {
    const { place } = this.props;

    if (place && place.id) {
      const { showBundlesOnTop = true } = place.about?.settings || {};
      const hasCollapsedSetting = hasFeatureSetting(place, 'collapsed_services');
      const services =
        place.schedule && place.services && place.services.length ? (
          <ListView place={place} />
        ) : (
          this.getServicesHtml(place)
        );

      return (
        <div id="book" className="details-section scroll-mt-[120px]">
          <h2 className={`mb-4 text-2xl font-semibold ${isMobile() ? 'pl-3' : ''}`}>{__('bookServices')}</h2>
          {!place.schedule && (
            <FilterServicesInput setFilterServicesQuery={this.filterServices} source="company_details" />
          )}
          {!isSistaminuten() && showBundlesOnTop && <Bundles isOpen={!hasCollapsedSetting} place={place} />}
          {!place.schedule && !this.state.filterQuery && <Campaigns isOpen={!hasCollapsedSetting} place={place} />}
          {services}
          {!isSistaminuten() && !showBundlesOnTop && <Bundles isOpen={!hasCollapsedSetting} place={place} />}
          <span className="divider"></span>
        </div>
      );
    }

    return null;
  }
}

function mapStateToProps(state) {
  return { flags: state.flags };
}

const connectedBookAppointment = withAmplitudeExperiment(connect(mapStateToProps)(BookAppointment));
export { connectedBookAppointment as BookAppointment };
