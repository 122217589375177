import Icon from '@/components/icons/Icon';
import { IconSize } from '@/components/icons/types';
import { classnames } from '@/helpers';
import { AvatarSize, AvatarVariant } from './types';

const baseIconWrapper = 'absolute bottom-0 right-0 z-10 flex  items-center justify-center rounded-full';

const mapping: Record<AvatarSize, { iconSize: IconSize; wrapper: string }> = {
  sm: { iconSize: 'xxxs', wrapper: classnames(baseIconWrapper, 'w-md h-md') },
  md: { iconSize: 'xxs', wrapper: classnames(baseIconWrapper, 'w-[16px] h-[16px]') },
  lg: { iconSize: 'xs', wrapper: classnames(baseIconWrapper, 'w-xl h-xl') },
  xl: { iconSize: 'sm', wrapper: classnames(baseIconWrapper, 'w-[32px] h-[32px]') },
};

export const AvatarVariantIcon = ({ size, variant }: { size: AvatarSize; variant: AvatarVariant }) => {
  const { iconSize, wrapper } = mapping[size];
  switch (variant) {
    case 'edit':
      return (
        <span className={classnames(wrapper, 'bg-brand-500')}>
          <Icon color="black-50" size={iconSize} variant="pen" />
        </span>
      );
    case 'add':
      return (
        <span className={classnames(wrapper, 'bg-brand-500')}>
          <Icon color="black-50" size={iconSize} variant="plus" />
        </span>
      );
    case 'favorite':
      return (
        <span className={classnames(wrapper, 'bg-danger')}>
          <Icon color="black-50" size={iconSize} variant="heart-filled" />
        </span>
      );
    case 'check':
      return (
        <span className={classnames(wrapper, 'bg-brand-500')}>
          <Icon color="black-50" size={iconSize} variant="check" />
        </span>
      );
    case 'canceled':
      return (
        <span className={classnames(wrapper, 'bg-danger-500')}>
          <Icon color="black-50" size={iconSize} variant="close" />
        </span>
      );
    default:
      return null;
  }
};
