import { RefObject, useEffect, useState } from 'react';

const useIntersectionObserver = (targetRef: RefObject<HTMLElement>, options = { root: null, threshold: 0.5 }) => {
  const [isInView, setIsInView] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => entries.forEach((entry) => setIsInView(entry.isIntersecting)),
      options,
    );

    if (targetRef.current) {
      observer.observe(targetRef.current);
    }

    return () => observer.disconnect();
  }, [targetRef, options]);

  return isInView;
};

export default useIntersectionObserver;
