import Input from '@/components/elements/forms/Input/Input';
import { ListItem, ListItemContent } from '@/components/elements/lists';
import Icon from '@/components/icons/Icon';
import { ChangeEvent, FocusEventHandler, useState } from 'react';
import Autosuggest from 'react-autosuggest';
import Modal from './redesign/Modal/Modal';

const renderSuggestion = (suggestion) => (
  <ListItem
    type="div"
    leftSlot={<Icon variant="location" />}
    onClick={() => {}}
    underline
    className="hover:bg-black-50">
    <ListItemContent title={suggestion} />
  </ListItem>
);

const getSuggestionValue = (suggestion) => suggestion;

const renderSuggestionsContainer = ({ containerProps, children }) => {
  return (
    <>
      {children && (
        <div {...containerProps} className="mt-xl left-0 right-0">
          {children}
        </div>
      )}
    </>
  );
};

type SearchModalProps = {
  value?: string;
  onChange: (event: ChangeEvent<HTMLInputElement>, { newValue }: { newValue?: string }) => void;
  onFocus?: FocusEventHandler<HTMLInputElement>;
  error?: string;
  description?: string;
  placeholder: string;
  inputTitle?: string;
  title?: string;
  suggestions?: Array<string>;
  onSuggestionSelected: () => void;
  onClose: () => void;
  isOpen?: boolean;
};

const SearchModal = ({
  isOpen,
  onClose,
  value = '',
  onChange,
  onFocus = null,
  error = null,
  description = null,
  placeholder,
  inputTitle = null,
  suggestions = [],
  title,
  onSuggestionSelected,
}: SearchModalProps) => {
  const [currentSuggestions, setCurrentSuggestions] = useState([]);

  const getSuggestions = (value: string) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0
      ? []
      : suggestions.filter((suggestion) => suggestion.toLocaleLowerCase().slice(0, inputLength) === inputValue);
  };

  const onSuggestionsFetchRequested = ({ value }) => {
    setCurrentSuggestions(getSuggestions(value));
  };

  const onSuggestionsClearRequested = () => {
    setCurrentSuggestions([]);
  };

  const renderInputComponent = (inputProps) => {
    delete inputProps.className;

    return (
      <div className={`gap-xxs flex w-full flex-col`}>
        <div className="px-lg">
          <Input
            error={error}
            description={description}
            title={inputTitle}
            leftSlot={<Icon variant="location" />}
            {...inputProps}
          />
        </div>
      </div>
    );
  };

  const inputProps = {
    placeholder,
    value,
    onChange,
    onFocus,
  };

  return (
    <Modal isOpen={isOpen}>
      <Modal.Content floating={false}>
        <Modal.Header title={title} onClose={onClose} />

        <Autosuggest
          id="search-modal-autosuggest"
          suggestions={currentSuggestions}
          onSuggestionsFetchRequested={onSuggestionsFetchRequested}
          onSuggestionsClearRequested={onSuggestionsClearRequested}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          inputProps={inputProps}
          renderSuggestionsContainer={renderSuggestionsContainer}
          renderInputComponent={renderInputComponent}
          onSuggestionSelected={onSuggestionSelected}
        />
      </Modal.Content>
    </Modal>
  );
};

export default SearchModal;
