import { config } from '@/config';
import { server } from '@/helpers';
import { CreatePaymentRequest, CreatePaymentResponse } from '@/types/api/services/qliro';

export const qliroServices = {
  createPayment,
  completePayment,
};

function createPayment(params: CreatePaymentRequest): CreatePaymentResponse {
  return server.request.post('/qliro/payments', params).then(server.handleSuccess).catch(server.handleErrorObject);
}

function completePayment(reference: string, customerId: string): EventSource {
  const eventSource = new EventSource(
    `${config.serverUrl}qliro/payments/complete?merchantReference=${reference}&customerId=${customerId}`,
  );
  return eventSource;
}
