import { Fab } from '@/components/elements/forms/buttons';
import Icon from '@/components/icons/Icon';
import { ReactNode } from 'react';

type Props = {
  top: ReactNode;
  cancel?: boolean;
  action: (e: any) => void;
  positionFixed?: boolean;
  noShadow?: boolean;
};

const ModalHeader = ({ top, cancel, action, positionFixed = true, noShadow = false }: Props) => {
  const handleClick = (e) => {
    action(e);
  };

  return (
    <>
      <div
        className={`top-0 flex w-full bg-white px-3 py-3 sm:relative sm:px-0 sm:py-0 ${
          positionFixed ? 'z-9999 fixed' : ''
        } ${noShadow ? '' : 'shadow sm:shadow-none'}`}>
        <div className="min-h-[8px] w-full">{top}</div>
        {cancel && (
          <div className="absolute right-2 top-0 ml-auto flex h-full max-h-16 items-center justify-center sm:top-1 sm:h-auto sm:p-2">
            <Fab icon={<Icon variant="close" />} onClick={handleClick} size="md" variant="link" aria-label="Close" />
          </div>
        )}
      </div>
      {positionFixed && <div className="py-6 sm:py-0"></div>}
    </>
  );
};

export default ModalHeader;
