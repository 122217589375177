import { useEffect, useState } from 'react';

type UseExternalScriptProps = {
  src: string;
  cleanup?: boolean;
  load?: boolean;
};

const useExternalScript = ({ src, cleanup = true, load = true }: UseExternalScriptProps) => {
  const [loaded, setLoaded] = useState(false);

  const isLoaded = () => setLoaded(true);

  useEffect(() => {
    if (!load) return;
    let script = document.querySelector(`script[src="${src}"]`) as HTMLScriptElement | null;
    if (!script) {
      script = document.createElement('script');
      script.setAttribute('src', src);
      script.async = true;
      script.addEventListener('load', isLoaded);
      document.head.appendChild(script);
    } else {
      setLoaded(true);
    }

    if (cleanup) {
      return () => {
        script.removeEventListener('load', isLoaded);
        document.head.removeChild(script);
      };
    }
  }, [src, cleanup, load]);

  return [loaded];
};

export default useExternalScript;
