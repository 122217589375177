import LoadingPlaceHolder from '@/components/elements/LoadingPlaceholder';
import PageViewLayout from '@/components/layouts/PageViewLayout/PageViewLayout';
import EmptyState from '@/components/templates/EmptyState';
import { _s } from '@/locale';
import { Redirect } from 'react-router-dom';
import useValidateSwishRedirect from './ValidateSwishPaymentRedirect.hooks';

export const baseTranslationKey = 'pages.validate-swish-payment-redirect.ValidateSwishPaymentRedirect';

const ValidateSwishRedirect = () => {
  const { redirect, isNewBrowser } = useValidateSwishRedirect();

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  if (isNewBrowser) {
    return <EmptyState body={_s(`${baseTranslationKey}.newBrowserWindow.body`)} />;
  }

  return (
    <PageViewLayout type="subView">
      <LoadingPlaceHolder text={_s(`${baseTranslationKey}.loadingLabel`)} />
    </PageViewLayout>
  );
};

export default ValidateSwishRedirect;
