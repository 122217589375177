import { blogConstants } from '../constants';
import { blogServices } from '../services';

export const blogActions = {
  list,
  category,
  author,
  featured,
  latest,
};

function list(page) {
  return (dispatch) => {
    blogServices.list(page).then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        dispatch(success({}));
      },
    );
  };

  function success(data) {
    return { type: blogConstants.list, data };
  }
}

function category(slug, page) {
  return (dispatch) => {
    blogServices.category(slug, page).then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        dispatch(success({}));
      },
    );
  };

  function success(data) {
    return { type: blogConstants.category, data };
  }
}

function author(slug, page) {
  return (dispatch) => {
    blogServices.author(slug, page).then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        dispatch(success({}));
      },
    );
  };

  function success(data) {
    return { type: blogConstants.author, data };
  }
}

function latest() {
  return (dispatch) => {
    blogServices.latest().then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        dispatch(success({}));
      },
    );
  };

  function success(data) {
    return { type: blogConstants.latest, data };
  }
}

function featured() {
  return (dispatch) => {
    blogServices.featured().then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        dispatch(success({}));
      },
    );
  };

  function success(data) {
    return { type: blogConstants.featured, data };
  }
}
