import { server } from '@/helpers';

export const loginServices = {
  requestOtp,
  login,
  isEmailRegistered
};

function requestOtp({
  email,
  triggerSource,
  redirectUrl,
}: {
  email: string;
  triggerSource: string;
  redirectUrl: string;
}) {
  return server.request
    .get('/oauth/request-otp', { params: { email, triggerSource, redirectUrl } })
    .then((response) => response.data)
    .catch(server.handleError);
}

function login({ email, code }: { email: string; code: string }) {
  return server.request
    .get(`/login/otp?email=${encodeURIComponent(email)}&code=${code}`, { withCredentials: true })
    .then((response) => response.data);
}

function isEmailRegistered(email: string): {exists: boolean} {
  return server.request
    .post('/login/is-email-registered', {email})
    .then((response) => response.data)
    .catch(server.handleError);
}