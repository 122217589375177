import { server } from '@/helpers';
import { PortalPlace } from '@/types/portal';

export function getPortalPlaces({ id, group }: { id: number; group?: string }): Promise<{ places: PortalPlace[] }> {
  const groupParam = group ? `/${group}` : '/';
  return server.request
    .get('/portalPlace/' + id + groupParam)
    .then(server.handleSuccess)
    .catch(server.handleError);
}
