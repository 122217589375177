import Markdown from 'markdown-to-jsx';
import { Button } from '../../forms/buttons';
import { BannerLinkWrapper, getBannerStyles, markdownOverrides } from '../helpers';
import { BannerConfig } from '../types';

type BannerType2Props = Omit<BannerConfig, 'type' | 'active'> & {
  position?: string; // only used for analytics
};

const BannerType2 = ({
  cta,
  title,
  message,
  background_color,
  text_color,
  text_link_color,
  background_image_lg,
  background_image_md,
  background_image_sm,
  background_alt_text,
  position,
}: BannerType2Props) => {
  const hasBackgroundImage = background_image_sm && background_image_md && background_image_lg;
  const styles = { ...getBannerStyles(background_color, text_color) } as React.CSSProperties;
  return (
    <BannerLinkWrapper cta={cta} type="type_2" position={position}>
      <div style={styles} className="relative">
        {hasBackgroundImage && (
          <div className="absolute inset-0">
            <picture>
              <source media="(max-width: 340px)" srcSet={background_image_sm} />
              <source media="(max-width: 768px)" srcSet={background_image_md} />
              <source media="(min-width: 769px)" srcSet={background_image_lg} />
              <img
                className="h-full max-h-full w-full object-cover"
                src={background_image_sm}
                alt={background_alt_text ?? ''}
              />
            </picture>
          </div>
        )}
        <div className="p-lg relative md:container md:text-center">
          <div className="Banner banner__markdown space-y-xxs flex flex-col">
            {title && <Markdown options={{ overrides: markdownOverrides(text_link_color) }}>{title}</Markdown>}
            <div className="flex flex-row md:flex-col">
              <div className="flex-1">
                <Markdown options={{ overrides: markdownOverrides(text_link_color) }}>{message}</Markdown>
              </div>
              {cta && (
                <div className="ml-sm md:pt-md flex items-end justify-end md:items-center md:justify-center">
                  <Button variant={cta.variant}>{cta.title}</Button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </BannerLinkWrapper>
  );
};

export default BannerType2;
