import { Link } from '@/components/elements/content';
import { LoadingIcon } from '@/components/icons';
import PageViewLayout from '@/components/layouts/PageViewLayout/PageViewLayout';
import BlogPageHeading from '@/components/modules/pages/blog/BlogPageHeading';
import SEO from '@/components/modules/SEO';
import { trackMpEvent, trackPage, url } from '@/helpers';
import { useLandingPageArchive } from '@/hooks/useLandingPageArchive';
import useMobileView from '@/hooks/useMobileView';
import useScrollToTop from '@/hooks/useScrollToTop';
import { _s } from '@/locale';
import { LandingPageArchive, LandingPageCategory, LandingPageListItem } from '@/types/landingPages';
import { useEffect } from 'react';
import { connect } from 'react-redux';

type ArchiveProps = {
  archive?: LandingPageArchive;
};

const landingPagesForCategory = (category: LandingPageCategory, landingPages: LandingPageListItem[]) =>
  landingPages
    .filter((lp) => 'name' in lp.category && lp.category.slug === category.slug)
    .sort((lp1, lp2) => lp1.title.localeCompare(lp2.title));

const LandingPageArchivePage = ({ archive: ssrArchive }: ArchiveProps) => {
  const { archive } = useLandingPageArchive(ssrArchive);
  const title = _s('landingPages.archiveTitle');
  const { isMobileView } = useMobileView();

  useScrollToTop();

  useEffect(() => {
    trackPage();
    trackMpEvent(`screen_view_facts_and_advice`, {});
  }, []);

  if (!archive) {
    return (
      <PageViewLayout type={isMobileView ? 'subView' : 'mainView'}>
        <LoadingIcon className="mx-auto h-8 w-8" />
      </PageViewLayout>
    );
  }

  return (
    <PageViewLayout type={isMobileView ? 'subView' : 'mainView'} {...(isMobileView && { back: true })} title={title}>
      <div>
        <BlogPageHeading title={title} />
        <div className="container py-6">
          {archive.description && <p className="mb-6 text-sm">{archive.description}</p>}
          <div className="flex flex-col flex-wrap justify-between gap-6 md:flex-row">
            {archive.categories.map((cat, i) => (
              <div key={i} className="">
                <h2 className="text-lg font-bold">{'name' in cat && cat.name}</h2>
                <div>
                  {landingPagesForCategory(cat, archive.landingPages).map((lp, i) => (
                    <Link to={`/fakta-och-rad/${lp.slug}`} key={i} className="my-4 block">
                      {lp.title}
                    </Link>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <SEO
        title={archive.seo?.title || title}
        description={archive.seo?.description || ''}
        url={`${url.getBaseUrl()}/fakta-och-rad`}
      />
    </PageViewLayout>
  );
};

const mapStateToProps = ({ archive }) => ({ archive });

export default connect(mapStateToProps)(LandingPageArchivePage);
