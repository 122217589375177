import { Variants } from '@amplitude/experiment-js-client';

const initialState: Variants = {};

export type FlagsAction = {
  type: 'flags/SET_FLAGS';
  payload: Variants;
};

export function flags(state = initialState, action: FlagsAction) {
  switch (action.type) {
    case 'flags/SET_FLAGS': {
      return {
        ...state,
        ...action.payload,
      };
    }
    default: {
      return state;
    }
  }
}
