import { BundleAction } from '@/actions';
import { BundleState, bundleStateSchema } from '@/types/state/bundle';

export const BUNDLE_CHECKOUT_SESSION_STORAGE_KEY = 'bundleCheckout';

function getBundleStateFromSessionStorageIfAny(): BundleState | null {
  const item = typeof window !== 'undefined' && JSON.parse(sessionStorage.getItem(BUNDLE_CHECKOUT_SESSION_STORAGE_KEY));
  const validateBundleState = bundleStateSchema.safeParse(item);

  if (!validateBundleState.success) {
    return { bundle: null, place: null };
  }

  return validateBundleState.data;
}

const initialState: BundleState = getBundleStateFromSessionStorageIfAny();

export function bundle(state: BundleState = initialState, action: BundleAction) {
  switch (action.type) {
    case 'PICK_BUNDLE_TO_CHECKOUT':
      return { ...state, ...action.payload };
    case 'CLEAR_BUNDLE_CHECKOUT':
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
}
