import { _s } from '@/locale';
import Alert from '../elements/notifications/Alert/Alert';
import Icon from '../icons/Icon';

const baseTranslationKey = 'components.modules.BundleNoticeBanner';

const BundleNoticeBanner = () => {
  return (
    <Alert
      variant="information"
      verticalAlign="top"
      leftSlot={<Icon variant="info-circle" />}
      title={_s(`${baseTranslationKey}.title`)}
      body={_s(`${baseTranslationKey}.description`)}
    />
  );
};

export default BundleNoticeBanner;
