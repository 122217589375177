import { getLocale } from '@/locale/i18n';
import { KPAuthorizationResponse, KPOrderLine } from '@/types/klarnaPayments';
import { server } from '../helpers';

export const getInitializeKlarnaSDKOnce = () => {
  let initialized = false;
  return () => {
    if (initialized) return;
    initialized = true;

    const script = document.createElement('script');
    script.src = 'https://x.klarnacdn.net/kp/lib/v1/api.js';
    script.async = true;
    document.body.appendChild(script);
  };
};

export const loadKlarnaWidget = (container: string, clientToken: string) => {
  //@ts-ignore
  if (!window || typeof Klarna == null) return;
  window.klarnaAsyncCallback = () => {
    //@ts-ignore
    Klarna.Payments.init({
      client_token: clientToken,
    });

    //@ts-ignore
    Klarna.Payments.load({
      container,
      payment_method_category: 'pay_later',
      preffered_payment_method: 'pay_later',
    });
  };
};

export const authorizeKlarnaPurchase = (
  orderLines: KPOrderLine[],
  paymentMethodCategory: string,
  callback: (res: KPAuthorizationResponse) => void,
) => {
  //@ts-ignore
  Klarna.Payments.authorize(
    {
      payment_method_category: paymentMethodCategory,
    },
    getKlarnaAuthorizationBody(orderLines),
    (res: KPAuthorizationResponse) => callback(res),
  );
};

const getKlarnaAuthorizationBody = (orderLines: KPOrderLine[]) => ({
  purchase_country: 'SE',
  purchase_currency: 'SEK',
  locale: getLocale(),
  order_amount: orderLines.reduce((acc, curr) => acc + curr.total_amount, 0),
  order_tax_amount: orderLines.reduce((acc, curr) => acc + curr.total_tax_amount, 0),
  order_lines: orderLines,
});

export const createKlarnaOrder = (sessionID: string, authorizationToken: string) =>
  server.request
    .post(`/klarna/payments/orders/${sessionID}/${authorizationToken}`)
    .then(server.handleSuccess)
    .catch(server.handleErrorObject);

export const createKlarnaMerchantOrder = (sessionID: string, authorizationToken: string, employeeId: string) =>
  server.request
    .post(`/klarna/payments/orders/${sessionID}/${authorizationToken}/${employeeId}`)
    .then(server.handleSuccess)
    .catch(server.handleError);

export const getKlarnaSession = (sessionID: string) =>
  server.request.get(`/klarna/payments/sessions/${sessionID}`).then(server.handleSuccess).catch(server.handleError);
