import { autocompleteConstants } from '../constants';

let initialState = { suggestions: [] };

export function autocomplete(state = initialState, action) {
  switch (action.type) {
    case autocompleteConstants.SUGGEST:
      const suggestions = action.suggestions;
      return {
        ...state,
        suggestions,
      };
    case autocompleteConstants.RESET: {
      return {
        ...state,
        suggestions: [],
      };
    }
    case autocompleteConstants.START_AUTOCOMPLETE_LOADER:
      return {
        ...state,
        isBusy: true,
      };
    case autocompleteConstants.STOP_AUTOCOMPLETE_LOADER:
      return {
        ...state,
        isBusy: false,
      };
    default:
      return state;
  }
}
