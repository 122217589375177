import Navigation from '@/components/modules/pages/faq/Navigation';
import { useLocation } from 'react-router-dom';

import PageViewLayout from '@/components/layouts/PageViewLayout/PageViewLayout';
import SEO, { seoPropsFromBaseString } from '@/components/modules/SEO';
import SectionItems, { SectionNav } from '@/components/modules/pages/faq/SectionItems';
import { faq } from '@/constants/faq';
import { isServer } from '@/helpers';
import { _s } from '@/locale';
import { useEffect } from 'react';

const FAQ = () => {
  const location = useLocation();
  const selectedSection = faq.find((section) => section.path === location.pathname);

  useEffect(() => {
    if (isServer) return;
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <PageViewLayout title="" type="mainView">
      <div className="container mt-10">
        <Navigation sections={faq} selectedPath={location.pathname} />
        <h2 className="mt-10 text-2xl font-semibold">
          {selectedSection ? selectedSection.title : _s('faqBeautyTreatments')}
        </h2>
        {selectedSection ? <SectionItems section={selectedSection} /> : <SectionNav sections={faq} />}
      </div>
      <SEO
        {...seoPropsFromBaseString('faq', location.pathname ?? '/faq-vanliga-fragor')}
        image="/images/home-background.jpeg"
      />
    </PageViewLayout>
  );
};

export default FAQ;
