import { config } from '@/config';

export const environment = config.env === 'production' || config.env === 'staging' ? 'PRODUCTION' : 'TEST';

const allowedPaymentMethods: google.payments.api.PaymentDataRequest['allowedPaymentMethods'] = [
  {
    type: 'CARD',
    parameters: {
      allowedAuthMethods: ['PAN_ONLY'],
      allowedCardNetworks: ['AMEX', 'DISCOVER', 'MASTERCARD', 'VISA'],
    },
    tokenizationSpecification: {
      type: 'PAYMENT_GATEWAY',
      parameters: {
        gateway: 'adyen',
        gatewayMerchantId: config.googlepay.gatewayMerchantId,
      },
    },
  },
];

export const baseRequestWithoutTransactionInfo: Omit<google.payments.api.PaymentDataRequest, 'transactionInfo'> = {
  apiVersion: 2,
  apiVersionMinor: 0,
  allowedPaymentMethods,
  merchantInfo: { merchantId: config.googlepay.merchantId, merchantName: config.googlepay.merchantName },
};
