import LoadingDotsBlack from '@/components/animations/LoadingDotsBlack/LoadingDotsBlack';
import { Button } from '@/components/elements/forms/buttons';
import { formattedDateTimeFromTimestamp } from '@/helpers/checkout';
import { _s } from '@/locale';
import Modal from '../redesign/Modal/Modal';

const baseTranslationKey = 'components.modules.modals.ConfirmChangeBookingTime';

type ConfirmChangeBookingTimeProps = {
  isOpen: boolean;
  loading: boolean;
  newTimestamp: number;
  onConfirm: () => void;
  onClose: () => void;
};

const ConfirmChangeBookingTime = ({
  isOpen,
  loading,
  newTimestamp,
  onConfirm,
  onClose,
}: ConfirmChangeBookingTimeProps) => {
  const dateTime = formattedDateTimeFromTimestamp(newTimestamp);

  return (
    <Modal isOpen={isOpen}>
      <Modal.Content floating>
        <Modal.Header onClose={onClose} title={_s(`${baseTranslationKey}.title`)} />
        <div className="flex flex-col">
          <p className="pb-lg text-black-600">
            {_s(`${baseTranslationKey}.body`)} <span className="text-black-900">{dateTime}</span>
          </p>
          <div className="space-y-sm flex flex-col">
            <Button variant="primary" onClick={onClose} disabled={loading}>
              {_s(`${baseTranslationKey}.cta.cancel`)}
            </Button>
            <Button variant="link" onClick={onConfirm} disabled={loading}>
              {loading && <LoadingDotsBlack loop style={{ maxHeight: '100%' }} />}
              {!loading && _s(`${baseTranslationKey}.cta.confirm`)}
            </Button>
          </div>
        </div>
      </Modal.Content>
    </Modal>
  );
};

export default ConfirmChangeBookingTime;
