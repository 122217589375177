import Snackbar from '@/components/elements/notifications/Snackbar/Snackbar';
import { setCookie, isIPhone, promiseWrapper } from '@/helpers';
import { openIosKeyboard } from '@/helpers/utilsTS';
import { trackScreenView } from '@/hooks/useTrackScreenView';
import { _s } from '@/locale';
import { npsServices } from '@/services';
import { ReactNode, createContext, useReducer, useState } from 'react';
import { toast } from 'react-toastify';

export type NpsScore = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;

export type NpsModalView = 'step1' | 'step2' | 'success';

type NpsState = {
  score?: NpsScore;
  comment?: string;
  code?: string;
  error: boolean;
  submitting: boolean;
  submitted: boolean;
};

type NpsAction =
  | { type: 'SET_SCORE'; payload: { score: NpsScore } }
  | { type: 'SET_CODE'; payload: { code: string } }
  | { type: 'SET_COMMENT'; payload: { comment: string } }
  | { type: 'SET_ERROR'; payload: { error: boolean } }
  | { type: 'SET_SUBMITTING'; payload: { submitting: boolean } }
  | { type: 'SET_SUBMITTED'; payload: { submitted: boolean } };

const npsReducer = (state: NpsState, action: NpsAction): NpsState => {
  switch (action.type) {
    case 'SET_SCORE': {
      const { score } = action.payload;
      return { ...state, score };
    }
    case 'SET_ERROR': {
      const { error } = action.payload;
      return { ...state, error };
    }
    case 'SET_SUBMITTING': {
      const { submitting } = action.payload;
      return { ...state, submitting };
    }
    case 'SET_CODE': {
      const { code } = action.payload;
      return { ...state, code };
    }
    case 'SET_COMMENT': {
      const { comment } = action.payload;
      return { ...state, comment };
    }
    case 'SET_SUBMITTED': {
      const { submitted } = action.payload;
      return { ...state, submitted };
    }
  }
};

const useNpsManager = (): {
  nps: NpsState;
  view: NpsModalView;
  updateView: (view: NpsModalView) => void;
  handleCommentSubmit: () => void;
  handleScoreSubmit: (score: NpsScore) => void;
  updateComment: (comment: string) => void;
  clearNpsState: () => void;
} => {
  const [nps, dispatch] = useReducer(npsReducer, {
    error: false,
    submitting: false,
    submitted: false,
  });
  const [view, setView] = useState<NpsModalView>('step1');

  const handleScoreSubmit = async (_score: NpsScore) => {
    if (nps.error) return;

    dispatch({ type: 'SET_SCORE', payload: { score: _score } });
    dispatch({ type: 'SET_SUBMITTING', payload: { submitting: true } });

    const { data, error } = await promiseWrapper(
      npsServices.save({
        likelihoodToRecommend: _score,
        code: nps.code,
      }),
    );

    if (error || !data?.code) {
      toast(
        ({ closeToast }) => (
          <Snackbar
            type="danger"
            label={_s('npsModal.error.message')}
            action={
              <button
                onClick={() => {
                  closeToast();
                  setView('step1');
                }}>
                {_s('npsModal.error.cta')}
              </button>
            }
          />
        ),
        { autoClose: false },
      );
      dispatch({ type: 'SET_SUBMITTING', payload: { submitting: false } });
      return;
    }

    dispatch({ type: 'SET_CODE', payload: { code: data.code } });
    dispatch({ type: 'SET_SUBMITTING', payload: { submitting: false } });
    setView('step2');
  };

  const handleCommentSubmit = async () => {
    if (nps.error) return;

    dispatch({ type: 'SET_SUBMITTING', payload: { submitting: true } });

    if (isIPhone()) {
      openIosKeyboard();
    }

    const { data, error } = await promiseWrapper(
      npsServices.save({
        likelihoodToRecommend: nps.score,
        comment: nps.comment,
        code: nps.code,
      }),
    );

    if (error || !data?.code) {
      toast(
        ({ closeToast }) => (
          <Snackbar
            type="danger"
            label={_s('npsModal.error.message')}
            action={
              <button
                onClick={() => {
                  closeToast();
                  setView('step1');
                }}>
                {_s('npsModal.error.cta')}
              </button>
            }
          />
        ),
        { autoClose: false },
      );
      dispatch({ type: 'SET_SUBMITTING', payload: { submitting: false } });
      return;
    }

    toast(
      ({ closeToast }) => (
        <Snackbar
          label={_s('npsModal.success.message')}
          type="success"
          action={<button onClick={closeToast}>{_s('close')}</button>}
        />
      ),
      { autoClose: 2000 },
    );
    dispatch({ type: 'SET_SUBMITTING', payload: { submitting: false } });
    dispatch({ type: 'SET_SUBMITTED', payload: { submitted: true } });
  };

  const updateComment = (_comment: string) => {
    dispatch({ type: 'SET_COMMENT', payload: { comment: _comment } });
    dispatch({ type: 'SET_SUBMITTING', payload: { submitting: false } });
  };

  const updateView = (_view: NpsModalView) => {
    setView(_view);
  };

  const clearNpsState = async () => {
    if (!nps.error && nps.code) {
      trackScreenView({ name: 'screen_view_nps_sheet_completed', properties: { comment: Boolean(nps.comment) } });
    }
    setCookie('npsClosed', 'true', { path: '/', expires: new Date(Date.now() + 30 * 86400 * 1000) });
    dispatch({ type: 'SET_SCORE', payload: { score: undefined } });
    dispatch({ type: 'SET_COMMENT', payload: { comment: undefined } });
    dispatch({ type: 'SET_CODE', payload: { code: undefined } });
    dispatch({ type: 'SET_ERROR', payload: { error: false } });
    dispatch({ type: 'SET_SUBMITTING', payload: { submitting: false } });
    dispatch({ type: 'SET_SUBMITTED', payload: { submitted: false } });
    setView('step1');
  };

  return {
    nps,
    view,
    handleScoreSubmit,
    handleCommentSubmit,
    updateComment,
    clearNpsState,
    updateView,
  };
};

export type NpsContextType = ReturnType<typeof useNpsManager>;

export const NpsContext = createContext<NpsContextType>({
  nps: {
    error: false,
    submitting: false,
    submitted: false,
  },
  view: 'step1',
  updateView: () => {},
  handleScoreSubmit: () => {},
  handleCommentSubmit: () => {},
  updateComment: () => {},
  clearNpsState: () => {},
});

export const NpsProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  return <NpsContext.Provider value={useNpsManager()}>{children}</NpsContext.Provider>;
};
