import { SuccessThinIcon } from '@/components/icons';
import PropTypes from 'prop-types';

function Select(props) {
  const { options, value, onSelect } = props;

  function onClick(optionValue) {
    onSelect(optionValue);
  }

  return (
    <div className="flex flex-wrap gap-2 overflow-x-auto sm:overflow-x-hidden">
      {options.map((option) => {
        const isSelected = value === option.key;
        return (
          <button
            className={`flex w-auto cursor-pointer items-center rounded-full border px-2 py-1 text-sm outline-none ${
              isSelected ? 'border-primary bg-white' : 'border-black-200 bg-black-200'
            }`}
            key={`select_key_${option.key}`}
            onClick={(e) => {
              e.preventDefault();
              onClick(option.key);
            }}>
            {isSelected && <SuccessThinIcon className={`h-4 w-4`} />}
            {option.label}
          </button>
        );
      })}
    </div>
  );
}

Select.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  value: PropTypes.string,
  onSelect: PropTypes.func,
};

Select.defaultProps = {
  onSelect: () => {},
};

export default Select;
