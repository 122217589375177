import { searchActions } from '@/actions';
import Chip from '@/components/elements/Chip';
import Checkbox from '@/components/elements/forms/Checkbox/Checkbox';
import { getFiltersTrackingLabel, isSistaminuten, trackMpEvent, url } from '@/helpers';
import withAmplitudeExperiment from '@/hoc/withAmplitudeExperiment';
import withMobileView from '@/hoc/withMobileView';
import { __ } from '@/locale';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Filter from './Filter';

class FilterBy extends Filter {
  constructor(props) {
    super(props);
    this.state = {
      options: [
        { name: __('serpFilters.payLater'), id: 4 },
        { name: __('serpFilters.giftcard'), id: 3 },
        { name: __('serpFilters.wellnesscard'), id: 9 },
        { name: __('serpFilters.campaign'), id: 5 },
        { name: __('serpFilters.certified'), id: 6 },
        // { name: __('payByCard'), id: 8 },
        // { name: __('fullyBooked'), id: 7 },
      ],
    };

    if (!isSistaminuten()) {
      this.state.options.splice(3, 0, { name: __('serpFilters.bundle'), id: 10 });
    }
  }

  componentDidUpdate() {
    let { prefs } = this.props;
    const { prefs: urlPrefs } = url.getUrlParameters(this.props);

    const sortPrefs = (prefs) => {
      return prefs
        ?.split(',')
        .map(Number)
        .sort((a, b) => a - b)
        .join(',');
    };

    const urlPrefsMap = sortPrefs(urlPrefs);
    const searchPrefsMap = sortPrefs(prefs);

    /**
     * Keep url prefs in sync with search prefs
     */
    if (urlPrefsMap !== searchPrefsMap) {
      if (urlPrefs === undefined) {
        this.props.dispatch(searchActions.removeParameter(['prefs', 'page']));
      } else {
        const selected = urlPrefs.split(',').map(Number) || [];
        this.props.dispatch(searchActions.setParameter({ shouldUpdate: true, prefs: { selected } }));
      }
    }
  }

  clickAndHandle = (id) => (e) => {
    const input = e.target.parentNode.querySelector('input');
    input.checked = input.checked ? false : true;

    this.handleClick(e, id, !input.checked);
  };

  clickOption = (id, active) => (e) => {
    this.handleClick(e, id, active);
  };

  handleClick = (e, id, active) => {
    const { dispatch } = this.props;

    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }

    const elements = document.getElementsByName('prefs');
    let selected = [];
    let text = [];
    for (const element in elements) {
      if (elements[element].checked) {
        selected.push({
          id: parseInt(elements[element].getAttribute('data-id'), 10),
          label: elements[element].parentNode.innerText,
        });
        text.push(elements[element].parentNode.innerText);
      }
    }

    if (e && id) {
      this.trackClickItem(id, active, selected);
    }

    this.props.history.push({
      search: url.updateFilters(selected.map((item) => item.id)),
    });

    if (selected[0]) {
      dispatch(searchActions.setParameter({ prefs: { selected: selected, label: text.join(', ') } }));
    } else {
      dispatch(searchActions.removeParameter(['prefs', 'page']));
    }
  };

  handleRemoveSingleFilter = (id) => (e) => {
    const { dispatch } = this.props;

    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }

    if (e && id) this.trackClickItem(id, true);
    const selectedOptions = this.getSelectedValues();
    const newOptions = selectedOptions.filter((option) => option.id !== id);

    this.props.history.push({
      search: url.updateFilters(newOptions.map((item) => item.id)),
    });

    if (newOptions.length) {
      dispatch(searchActions.setParameter({ prefs: { selected: newOptions } }));
    } else {
      dispatch(searchActions.removeParameter(['prefs', 'page']));
    }
  };

  trackClickItem(id, active, selected) {
    let name = getFiltersTrackingLabel(id);
    if (!active) {
      trackMpEvent('filter_clicked', {
        screen_name: this.props.trackPage,
        search_filter: name,
      });
    } else {
      trackMpEvent('filter_removed', {
        screen_name: this.props.trackPage,
        search_filter: name,
      });
    }
  }

  getOptionsHtml() {
    const { options } = this.state;
    let { prefs = '' } = this.props;
    prefs = prefs.split(',');
    let html = options.map((item, key) => {
      let active = false;
      if (prefs && prefs.indexOf(item.id.toString()) !== -1) {
        active = true;
      }

      return (
        <span className={'flex flex-row px-4 py-4 hover:bg-gray-50' + (active ? ' active ' : '')} key={key}>
          <Checkbox
            name="prefs"
            checked={active}
            data-id={item.id}
            onClick={this.clickOption(item.id, active)}
            onChange={() => {}}
            label={
              <label className="m-0 flex text-base" onClick={this.clickAndHandle(item.id)}>
                {item.name}
              </label>
            }
          />
        </span>
      );
    });
    return html;
  }

  getSelectedValues() {
    const prefs = (this.props.prefs || '').split(',');
    const selectedOptions = [];

    this.state.options.forEach((option) => {
      if (prefs.indexOf(option.id.toString()) !== -1) {
        selectedOptions.push(option);
      }
    });

    return selectedOptions;
  }

  isActive() {
    return Boolean(this.props.prefs);
  }

  getCountBadge() {
    const prefs = (this.props.prefs || '').split(',');
    return prefs.length;
  }

  getChildren() {
    return (
      <>
        <span className="text-capitalize text-xxs text-black-400 block px-4">{__('filterBy')}</span>
        {this.getOptionsHtml()}
      </>
    );
  }

  renderChips() {
    const selectedOptions = this.getSelectedValues();

    if (!this.props.isMobileView) {
      return (
        <div className="flex flex-wrap gap-2">
          {selectedOptions.map((selectedOption) => (
            <Chip
              key={selectedOption.id}
              label={selectedOption.name}
              onClose={this.handleRemoveSingleFilter(selectedOption.id)}
            />
          ))}
        </div>
      );
    }

    return null;
  }
}

function mapStateToProps(state) {
  const { search } = state;
  const { prefs } = search;
  return {
    prefs,
    search,
    flags: state.flags,
  };
}

const connectedFilterBy = withMobileView(withRouter(connect(mapStateToProps)(withAmplitudeExperiment(FilterBy))));
export { connectedFilterBy as FilterBy };
