import { Card, CardHeader } from '@/components/elements/cards';
import RadioButton from '@/components/elements/forms/RadioButton';
import { MailIcon, PhoneIcon } from '@/components/icons';
import { ShippingMethod } from '@/constants/giftcardConstants';
import { __ } from '@/locale';
import gfCss from '@/styles/modules/giftcards.module.scss';

/**
 * @typedef {object} SelectShippingMethodCardProps
 * @prop {import('../../../../services/giftCardServices').ShippingMethod} shippingMethod
 * @prop {Function} setShippingMethod
 * @prop {object} css
 *
 * @param {SelectShippingMethodCardProps} param0
 * @returns
 */
const SelectShippingMethodCard = ({ shippingMethod, setShippingMethod, css }) => {
  const startDate = new Date('2024-07-18');
  const endDate = new Date('2024-08-16');
  const allowPhysicalGiftcard = !(Date.now() <= endDate && Date.now() >= startDate);
  return (
    <Card>
      <CardHeader>{__('buyGiftcard.selectShipping')}</CardHeader>
      <div>
        <RadioButton
          variant="large"
          className={gfCss.shippingMargin}
          checked={shippingMethod === ShippingMethod.DIGITAL}
          onClick={setShippingMethod(ShippingMethod.DIGITAL)}>
          <div className="flex items-center">
            <PhoneIcon className="mx-4 h-8 w-8" />
            <div className="pr-[60px] text-left">
              <h3 className="mg-0 text-md font-normal">{__('buyGiftcard.shippingDigital')}</h3>
              <div className={`text-sm ${shippingMethod === ShippingMethod.SHIPPING ? 'color-gray-600' : ''}`}>
                {__('buyGiftcard.shippingDigital.desc')}
              </div>
            </div>
          </div>
        </RadioButton>
        {allowPhysicalGiftcard && (
          <RadioButton
            variant="large"
            checked={shippingMethod === ShippingMethod.SHIPPING}
            onClick={setShippingMethod(ShippingMethod.SHIPPING)}>
            <div className="flex items-center">
              <MailIcon className="mx-4 w-8" style={{ width: '32px' }} />
              <div className="pr-[60px] text-left">
                <h3 className="mg-0 text-md font-normal">{__('buyGiftcard.shippingPhysical')}</h3>
                <div className={`text-sm ${shippingMethod === ShippingMethod.DIGITAL ? 'color-gray-600' : ''}`}>
                  {__('buyGiftcard.shippingPhysical.desc')}
                </div>
              </div>
            </div>
          </RadioButton>
        )}
      </div>
    </Card>
  );
};

export default SelectShippingMethodCard;
