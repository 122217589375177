import { BlogLayout } from '@/components/layouts/blog';
import { Archive, ArchiveCategoryNav, BackButton, Heading } from '@/components/modules/pages/blog';
import useScrollToTop from '@/hooks/useScrollToTop';

const ArchivePage = () => {
  useScrollToTop();
  return (
    <BlogLayout>
      <div className="container mt-10">
        <BackButton />
        <Heading />
        <ArchiveCategoryNav />
        <Archive />
      </div>
    </BlogLayout>
  );
};

export default ArchivePage;
