import { Z_INDEX } from '@/constants/styleConstants';
import { HTMLAttributes, ReactNode, UIEvent, useState } from 'react';
import * as ReactModal from 'react-modal';
import css from './Modal.module.scss';

type ModalDialogProps = HTMLAttributes<HTMLDivElement> & {
  isOpen: boolean;
  appElement: HTMLElement;
  children: ReactNode;
  className?: string;
  showOverlay?: boolean;
  onRequestClose?: () => void;
  shouldCloseOnOverlayClick?: boolean;
};

export const ModalDialog = ({
  isOpen,
  className = '',
  showOverlay = true,
  appElement,
  onRequestClose = null,
  shouldCloseOnOverlayClick = true,
  children,
}: ModalDialogProps) => {
  return (
    <ReactModal
      isOpen={isOpen}
      appElement={appElement}
      onRequestClose={onRequestClose}
      overlayClassName={`${css.overlay} ${showOverlay ? css.overlayBackground : ''}`}
      overlayElement={(props, contentElement) => {
        /**
         * we need to remove these classes from the overlay element since addblockers can block the modal
         * In this case the "shield" in Brave browser is blocking the modal because of these classes
         */
        const { className, ...rest } = props;
        const removeDefault = [
          'ReactModal__Overlay',
          'ReactModal__Overlay--after-open',
          'ReactModal__Overlay--before-close',
        ];
        const classes = className
          .split(' ')
          .filter((c) => !removeDefault.includes(c))
          .join(' ');

        return (
          <div {...rest} className={classes}>
            {contentElement}
          </div>
        );
      }}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      className={`${css.dialog} ${className}`}
      style={{ overlay: { zIndex: Z_INDEX.OLD_MODAL } }}>
      {children}
    </ReactModal>
  );
};

type ModalContentProps = HTMLAttributes<HTMLDivElement> & {
  className?: string;
  children: ReactNode;
  size?: 'sm' | 'md' | 'lg' | 'fullscreen';
  stickToBottom?: boolean;
  stickToBottomRight?: boolean;
};

export const ModalContent = ({
  className = '',
  size = 'md',
  stickToBottom = false,
  stickToBottomRight = false,
  children,
  ...props
}: ModalContentProps) => {
  const [scrollTop, setScrollTop] = useState<number>(0);

  const handleScroll = (e: UIEvent<HTMLDivElement>) => {
    setScrollTop(e.currentTarget.scrollTop);
  };

  return (
    <div
      onScroll={handleScroll}
      className={`${css.content} ${css[size]} ${className} ${stickToBottom ? css.stickToBottom : ''} ${
        stickToBottomRight ? css.stickToBottomRight : ''
      } no-scrollbar ${scrollTop > 0 ? 'scrolled' : ''}`}
      {...props}>
      {children}
    </div>
  );
};

type ModalActionbarProps = {
  className?: string;
  children: ReactNode;
};

export const ModalActionbar = ({ children, className = '' }: ModalActionbarProps) => (
  <div className={`${css.actionbar} ${className}`}>{children}</div>
);
