import { navigationDrawerConstants } from '../constants/navigationDrawerConstants';

export function navigationDrawer(state = {}, action) {
  switch (action.type) {
    case navigationDrawerConstants.NAVIGATION_DRAWER:
      return {
        ...state,
        show: action.data,
      };
    default: {
      return state;
    }
  }
}
