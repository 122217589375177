import { classnames } from '@/helpers';
import { twMerge } from 'tailwind-merge';
import HR from '../../HR/HR';
import Header, { HeaderProps } from '../../Header/Header';

type DividerSize = 'lg' | 'md' | 'sm';

type DividerProps = {
  size?: DividerSize;
  className?: string;
} & Omit<HeaderProps, 'size'>;

const Divider = ({ size = 'lg', label, className = '', ...headerProps }: DividerProps) => {
  return (
    <div className={twMerge(classnames('pl-lg pt-sm space-y-sm flex flex-col ', className))}>
      <div className="pr-lg gap-md flex justify-between">
        {label && <Header size={size} label={label} {...headerProps} />}
      </div>
      <HR />
    </div>
  );
};

export default Divider;
