import Header from '@/components/elements/Header/Header';
import { ListItem, ListItemContent } from '@/components/elements/lists';
import { PersonAvatar } from '@/components/elements/redesign/Avatar';
import CustomAvatar from '@/components/elements/redesign/Avatar/CustomAvatar/CustomAvatar';
import Icon from '@/components/icons/Icon';
import { _s } from '@/locale';

const baseTranslationKey = 'components.modules.checkout.AdditionalParticipants';

const AdditionalParticipants = ({
  participants,
  capacity,
  onAddParticipants,
  onUpdateParticipants,
}: {
  participants: { name?: string; email?: string; id: string }[];
  capacity: number;
  onAddParticipants: () => void;
  onUpdateParticipants: (id: string) => void;
}) => {
  return (
    <div className="pb-lg">
      <div className="py-md pl-lg">
        <Header size="md" label={_s(`${baseTranslationKey}.title`)} />
      </div>
      <ul>
        {participants.length > 0 &&
          participants.map((participant) => {
            return (
              <ListItem
                onClick={() => onUpdateParticipants(participant.id)}
                key={participant.id}
                leftSlot={<PersonAvatar size="md" alt="" />}
                rightSlot={<Icon variant="chevron-s-right" />}
                underline>
                <ListItemContent
                  title={participant.name ?? `${_s(`${baseTranslationKey}.listPrefix`)} ${participant.id}`}
                  subTitle={participant.email ?? ''}
                />
              </ListItem>
            );
          })}
        {participants.length + 1 < capacity && (
          <>
            <ListItem
              onClick={onAddParticipants}
              leftSlot={<CustomAvatar size="md" alt="" iconVariant="plus" />}
              rightSlot={<Icon variant="chevron-s-right" />}
              underline>
              <ListItemContent title={_s(`${baseTranslationKey}.button`)} />
            </ListItem>
          </>
        )}
      </ul>
    </div>
  );
};

export default AdditionalParticipants;
