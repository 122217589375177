import { UsersState } from '@/types/state/users';
import { userConstants } from '../constants';

const initialState: UsersState = {};

export function users(state = initialState, action) {
  switch (action.type) {
    case userConstants.UPDATE_USER_SUCCESS:
    case userConstants.LOGIN_SUCCESS:
      return {
        user: action.user,
      };
    case userConstants.LOGOUT:
      return {
        loggedOut: true,
      };
    case userConstants.LOGIN_FAILURE:
      return {
        errorLogin: action.error,
      };
    case userConstants.DELETE_USER:
      return {
        userDeleted: true,
      };
    default:
      return state;
  }
}
