import { SVGProps } from 'react';
import SolidFillSvg from './SolidFillSvg';

const ArrowLongIcon = (props: SVGProps<SVGSVGElement>) => (
  <SolidFillSvg viewBox="0 0 33 6" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M1 2.5C0.723858 2.5 0.5 2.72386 0.5 3C0.5 3.27614 0.723858 3.5 1 3.5L1 2.5ZM33 3L28 0.113249V5.88675L33 3ZM1 3.5L28.5 3.5V2.5L1 2.5L1 3.5Z"
      fill="currentColor"
    />
  </SolidFillSvg>
);

export default ArrowLongIcon;
