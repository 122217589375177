import { ColorNames } from '@/colors';
import { ButtonVariant } from '@/components/elements/forms/buttons/types';
import { IconVariant } from '@/components/icons/types';
import { APPOINTMENT_STATUS } from '@/constants/appointmentConstants';

export enum AppointmentActionIdentifier {
  PAY_ONLINE = 'pay_online',
  CHANGE_BOOKING_TIME = 'change_booking_time',
  ADD_TO_CALENDAR = 'add_to_calendar',
  BOOK_AGAIN = 'book_again',
  CANCEL_BOOKING = 'cancel_booking',
}

export type AppointmentActionItem = {
  variant: ButtonVariant;
  icon: IconVariant;
  iconColor?: ColorNames;
  to?: { pathname: string; state?: object };
  label: string;
  identifier?: AppointmentActionIdentifier;
};

export type AppointmentStatus = (typeof APPOINTMENT_STATUS)[keyof typeof APPOINTMENT_STATUS];
