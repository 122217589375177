import { turnstileConstants } from '@/constants';
import { promiseWrapper } from '@/helpers';
import { turnstileServices } from '@/services';

const { ADD_TOKEN, REMOVE_TOKEN, VERIFY_TOKEN } = turnstileConstants;

export type TurnstileAction =
  | {
      type: typeof ADD_TOKEN;
      payload: { token: string };
    }
  | {
      type: typeof REMOVE_TOKEN;
    }
  | {
      type: typeof VERIFY_TOKEN;
      payload: { success: boolean; token: string };
    };

export const turnstileActions = {
  verifyToken,
  addToken,
  removeToken,
};

async function verifyToken(token: string, onSuccessCallback?: () => void): Promise<TurnstileAction> {
  const { error } = await promiseWrapper(turnstileServices.verify(token));
  !error && onSuccessCallback?.();

  return { type: VERIFY_TOKEN, payload: { success: !error, token } };
}

function addToken(token: string): TurnstileAction {
  return { type: ADD_TOKEN, payload: { token } };
}

function removeToken(): TurnstileAction {
  return { type: REMOVE_TOKEN };
}
