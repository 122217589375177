import { MinusIcon, PlusIcon } from '@/components/icons';
import css from './QuantitySelector.module.scss';

type Props = {
  quantity: number;
  setQuantity: (quantity: number) => void;
  max?: number;
  min?: number;
};

const QuantitySelector = ({ quantity, setQuantity, max = 10, min = 1 }: Props) => {
  const incrementBy = (amount) => () => setQuantity(Math.min(max, Math.max(min, quantity + amount)));
  return (
    <>
      <div className="flex items-center justify-start gap-2">
        <button className={`${css.button} ${css.minus}`} onClick={incrementBy(-1)} disabled={quantity <= min}>
          <MinusIcon />
        </button>
        <span className={css.number}>{quantity}</span>
        <button className={`${css.button} ${css.plus}`} onClick={incrementBy(1)} disabled={quantity >= max}>
          <PlusIcon />
        </button>
      </div>
    </>
  );
};

export default QuantitySelector;
