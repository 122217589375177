import { config } from '@/config';
import { faq, supportFaq } from '@/constants/faq';
import { isSistaminuten } from '@/helpers';
import {
  About,
  AccountSettings,
  AddReview,
  AppointmentDetails,
  Appointments,
  BlogArchive,
  BlogCategory,
  BlogHome,
  BlogSinglePost,
  Book,
  BundleCheckout,
  BundleConfirmation,
  BundleDetails,
  Bundles,
  Cancel,
  CheckoutNew,
  Cookies,
  EthicalPolicy,
  FAQ,
  Favorites,
  FinishAccount,
  GenerateBanner,
  GiftCardConfirmation,
  GiftCardsBuyPlace,
  GiftCardsBuyPlaceConfirmation,
  GiftCardsUse,
  GiftcardCheckout,
  Home,
  InjectionNotice,
  KlarnaCheckout,
  LandingPage,
  LandingPageArchive,
  List,
  MobileSearch,
  NewGiftcardConfirmation,
  Newsletter,
  NotFound,
  PaymentMethods,
  PlaceRedirect,
  Portal,
  PrePayKlarnaCheckout,
  PrePayQliroCheckout,
  PrepayCheckout,
  PrivacyPolicy,
  QliroBundleCheckout,
  QliroCheckout,
  ReviewRedirect,
  SalonLanding,
  SearchNotice,
  SecurityAcknowledgements,
  SecurityPolicy,
  ServicesAtHome,
  Support,
  SupportForm,
  SupportFormSuccess,
  SupportRemindersLog,
  SupportReviews,
  SupportReviewsUsers,
  SupportSection,
  Terms,
  TermsGiftcard,
  TermsLanding,
  TermsOnlinePayment,
  Unsubscribe,
  UnsubscribeFromReviews,
  ValidateCofPaymentRedirect,
  ValidateQliroPayment,
  ValidateSwishPaymentRedirect,
  ValidateSwishRedirect,
  ValidateThreeDSecureRedirect,
  ValueCardsBuy,
  ValueCardsBuyConfirmation,
  WaitingListSuccess,
} from '@/pages/_exports';

import AddGiftcard from '@/pages/add-giftcard/AddGiftcard';
import Confirmation from '@/pages/booking/confirmation/BookingConfirmation';
import AssociationsArchive from '@/pages/bransch-organisationer';
import AssociationsLanding from '@/pages/bransch-organisationer/$slug';
import Deals from '@/pages/deals/Deals';
import { GiftCardsBuy } from '@/pages/giftcards/buy';
import MyProfile from '@/pages/my-profile/MyProfile';
import PlaceGiftcardConfirmation from '@/pages/places/$slugId/giftcard/confirmation/PlaceGiftcardConfirmation';
import { Redirect } from 'react-router-dom';
import { APP_ROUTES } from './pages';

const getFaqPaths = (): string[] => ['/faq-vanliga-fragor', ...faq.map((section) => section.path)];
const getSupportPaths = (): string[] => [...supportFaq.map((section) => section.path)];

export type AppRoute = {
  exact?: boolean;
  path?: string | string[];
  component: any;
  allowOnSistaminuten?: boolean;
};

export const getAppRoutes = (isMobileView: boolean): AppRoute[] =>
  [
    ...(isMobileView
      ? [{ path: '/search', component: MobileSearch, allowOnSistaminuten: true }]
      : [
          {
            path: '/search',
            allowOnSistaminuten: true,
            component: () => {
              return <Redirect to="vad/var" />;
            },
          },
        ]),
    { exact: true, path: '/', component: Home, allowOnSistaminuten: true },
    {
      exact: true,
      path: '/calle',
      component: (props) => {
        window.location.href =
          'https://www.bokadirekt.se/giftcards/buy?utm_source=schulman&utm_medium=podcast&utm_campaign=inflation&utm_id=schulman';
        return <span>redirecting...</span>;
      },
    },
    {
      exact: true,
      path: '/download-app',
      component: (props) => {
        window.location.href = 'https://bokadirekt.smart.link/ylc914w9w';
        return <span>redirecting...</span>;
      },
    },
    {
      exact: true,
      path: '/fredagspodden',
      component: (props) => {
        window.location.href =
          'https://www.bokadirekt.se/giftcards/buy?utm_source=podcast&utm_medium=fredagspodden&utm_campaign=fredagspodden_221003';
        return <span>redirecting...</span>;
      },
    },
    {
      exact: true,
      path: '/alexochsigge',
      component: (props) => {
        window.location.href =
          'https://www.bokadirekt.se/giftcards/buy?utm_source=podcast&utm_medium=alexochsigge&utm_campaign=alexochsigge_vecka50_2022';
        return <span>redirecting...</span>;
      },
    },
    {
      path: '/my-profile',
      component: MyProfile,
      allowOnSistaminuten: false,
    },
    { path: '/places/:slugId/giftcards/confirmation', component: GiftCardsBuyPlaceConfirmation },
    { path: '/places/:slugId/giftcard/confirmation', component: PlaceGiftcardConfirmation },
    { path: '/places/:slugId/giftcards/buy', component: GiftCardsBuyPlace },
    { path: '/places/:slugId/giftcard/checkout', component: GiftcardCheckout },
    { path: '/places/:slugId/valuecards/confirmation', component: ValueCardsBuyConfirmation },
    { path: '/places/:slugId/valuecards/buy', component: ValueCardsBuy },
    { path: '/places/:slugId', component: SalonLanding, allowOnSistaminuten: true },
    { path: '/portal/:slug/:id/:group?', component: Portal },
    { path: '/goto/:id/:group?', component: PlaceRedirect },
    { path: '/boka-tjanst/:placeSlugId/:serviceSlugId', component: Book, allowOnSistaminuten: true },
    {
      exact: true,
      path: '/booking/checkout/validate-3ds-redirect',
      component: ValidateThreeDSecureRedirect,
      allowOnSistaminuten: true,
    },
    {
      exact: true,
      path: '/booking/checkout/validate-swish-redirect',
      component: ValidateSwishRedirect,
      allowOnSistaminuten: true,
    },
    { exact: true, path: '/booking/checkout/klarna', component: KlarnaCheckout, allowOnSistaminuten: true },
    { exact: true, path: '/booking/checkout/qliro', component: QliroCheckout, allowOnSistaminuten: true },
    { exact: true, path: '/booking/checkout/:bookingId', component: PrepayCheckout, allowOnSistaminuten: false },
    {
      exact: true,
      path: '/booking/checkout/:bookingId/klarna',
      component: PrePayKlarnaCheckout,
      allowOnSistaminuten: false,
    },
    {
      exact: true,
      path: '/booking/checkout/:bookingId/qliro',
      component: PrePayQliroCheckout,
      allowOnSistaminuten: false,
    },
    {
      path: '/validate-swish-payment-redirect/:type/:id/:placeId?',
      component: ValidateSwishPaymentRedirect,
      allowOnSistaminuten: false,
    },
    { path: '/booking/checkout', component: CheckoutNew, allowOnSistaminuten: true },
    {
      path: '/validate-cof-payment-redirect/:type/:id/:placeId?',
      component: ValidateCofPaymentRedirect,
      allowOnSistaminuten: false,
    },
    { path: '/validate-qliro-payment', component: ValidateQliroPayment, allowOnSistaminuten: false },
    { exact: true, path: '/bundles/checkout/qliro', component: QliroBundleCheckout, allowOnSistaminuten: false },
    { path: '/bundles/checkout/:placeId?/:bundleId?', component: BundleCheckout, allowOnSistaminuten: false },
    { path: '/bundles/confirmation', component: BundleConfirmation, allowOnSistaminuten: false },
    { path: '/bokningar', component: Appointments },
    { path: '/deals', component: Deals },
    { path: '/klippkort/', component: Bundles, allowOnSistaminuten: false },
    { path: '/klippkortdetaljer/:id', component: BundleDetails, allowOnSistaminuten: false },
    { path: '/bokning/:id', component: AppointmentDetails, allowOnSistaminuten: true },
    { path: '/cancel', component: Cancel, allowOnSistaminuten: true },
    { path: '/list', component: List, allowOnSistaminuten: true },
    { path: '/favoriter', component: Favorites },
    { path: '/betalningsmetoder', component: PaymentMethods },
    { path: '/terms', component: Terms, allowOnSistaminuten: true },
    { path: getFaqPaths(), component: FAQ },
    { exact: true, path: '/blogg', component: BlogHome },
    { path: '/blogg/list/:page?', component: BlogArchive },
    { path: '/blogg/c/:slug/:page?', component: BlogCategory },
    { path: '/giftcard/confirmation/:id?', component: NewGiftcardConfirmation, allowOnSistaminuten: false },

    { path: APP_ROUTES.GIFTCARD_CHECKOUT + '/:campaign?', component: GiftcardCheckout, allowOnSistaminuten: false },
    { path: APP_ROUTES.WELLNESSCARD_CHECKOUT + '/:campaign?', component: GiftcardCheckout, allowOnSistaminuten: false },
    { path: '/blogg/p/:slugId/', component: BlogSinglePost },
    { path: '/fakta-och-rad/:slugID/', component: LandingPage },
    { path: '/fakta-och-rad/', component: LandingPageArchive },
    { path: APP_ROUTES.ASSOCIATION_PAGES + '/:slugID/', component: AssociationsLanding, allowOnSistaminuten: true },
    { path: APP_ROUTES.ASSOCIATION_PAGES, component: AssociationsArchive, allowOnSistaminuten: true },
    { path: '/add-giftcard/:token', component: AddGiftcard, allowOnSistaminuten: false },
    { exact: true, path: '/giftcards', component: GiftCardsUse },
    { path: '/giftcards/confirmation', component: GiftCardConfirmation },
    { path: '/giftcards/buy', component: GiftCardsBuy },
    { path: '/orders/:bookingId/booking-confirmation', component: FinishAccount, allowOnSistaminuten: true },
    { path: '/articles/cookies', component: Cookies, allowOnSistaminuten: true },
    { path: '/articles/terms', component: TermsLanding, allowOnSistaminuten: true },
    { path: '/articles/terms-booking-consumer', component: Terms, allowOnSistaminuten: true },
    { path: '/articles/terms-giftcard-consumer', component: TermsGiftcard, allowOnSistaminuten: false },
    { path: '/articles/terms-online-payment', component: TermsOnlinePayment, allowOnSistaminuten: true },
    { path: '/articles/ethical-policy', component: EthicalPolicy, allowOnSistaminuten: true },
    { path: '/articles/privacy-policy', component: PrivacyPolicy, allowOnSistaminuten: true },
    { path: '/articles/injection-notice', component: InjectionNotice, allowOnSistaminuten: true },
    { path: '/articles/search-notice', component: SearchNotice, allowOnSistaminuten: true },
    { path: '/articles/about', component: About, allowOnSistaminuten: true },
    { path: '/articles/security-policy', component: SecurityPolicy, allowOnSistaminuten: false },
    { path: '/articles/security-acknowledgements', component: SecurityAcknowledgements, allowOnSistaminuten: false },
    { path: '/reviews/unsubscribe/:emailHash', component: UnsubscribeFromReviews, allowOnSistaminuten: false },
    { path: '/reviews/:userId/:bookingId', component: AddReview, allowOnSistaminuten: true },
    { path: '/reviews-redirect', component: ReviewRedirect, allowOnSistaminuten: true },
    { exact: true, path: '/support', component: Support, allowOnSistaminuten: true },
    { path: '/support/form', component: SupportForm, allowOnSistaminuten: true },
    { exact: true, path: '/booking/confirmation', component: Confirmation, allowOnSistaminuten: true },
    { path: '/support/form-submitted', component: SupportFormSuccess, allowOnSistaminuten: true },
    ...(config.env !== 'production'
      ? [{ exact: true, path: '/generate-banner', component: GenerateBanner, allowOnSistaminuten: true }]
      : []),

    /* Temporary to prevent broken links from google */
    {
      exact: true,
      path: '/articles/support',
      component: () => {
        return <Redirect to="/support" />;
      },
      allowOnSistaminuten: true,
    },
    {
      path: '/articles/support/form',
      component: () => {
        return <Redirect to="/support/form" />;
      },
      allowOnSistaminuten: true,
    },
    { path: getSupportPaths(), component: SupportSection, allowOnSistaminuten: true },
    { path: '/newsletter', component: Newsletter },
    { path: '/waiting-list-success', component: WaitingListSuccess },
    { path: '/unsubscribe/:emailHash/:deliveryId?', component: Unsubscribe, allowOnSistaminuten: true },
    { path: '/services-at-home', component: ServicesAtHome },
    { path: '/:q/:location', component: List, allowOnSistaminuten: true },
    { path: '/account-settings', component: AccountSettings, allowOnSistaminuten: false },
    { path: '/reviews-support', component: SupportReviews, allowOnSistaminuten: false },
    { path: '/reviews-support-users', component: SupportReviewsUsers, allowOnSistaminuten: false },
    { path: '/reviews-support-reminders', component: SupportRemindersLog, allowOnSistaminuten: false },
    { component: NotFound, allowOnSistaminuten: true },
  ].filter((route) => (isSistaminuten() ? route.allowOnSistaminuten ?? false : true));
