import { _s } from '@/locale';

export type Section = {
  label: string;
  path?: string;
};

const baseTranslationKey = 'constants.terms';

export enum TermLinkIdentifier {
  PAYMENT = 'payment',
  USAGE = 'usage',
  COUPON = 'coupon',
}

export const TERMS_NAVIGATION_LINKS = [
  {
    label: _s(`${baseTranslationKey}.link.payment`),
    to: '/articles/terms-online-payment',
    identifier: TermLinkIdentifier.PAYMENT,
  },
  {
    label: _s(`${baseTranslationKey}.link.usage`),
    to: '/articles/terms-booking-consumer',
    identifier: TermLinkIdentifier.USAGE,
  },
  {
    label: _s(`${baseTranslationKey}.link.coupon`),
    to: '/articles/terms-giftcard-consumer',
    identifier: TermLinkIdentifier.COUPON,
  },
] as const;
