const CountBadge = ({ count, handleClick }: { count?: number; handleClick?: () => void }) => {
  const badgeSize = 16;
  if (!count) return null;
  return (
    <span
      onClick={handleClick}
      className="bg-black-900 rounded-xs text-xxs flex items-center justify-center font-semibold text-white"
      style={{ width: badgeSize, height: badgeSize }}>
      {count}
    </span>
  );
};

export default CountBadge;
