import { Helmet } from 'react-helmet';
import { __ } from '../../locale';

type Props = {
  title: string;
  description: string;
  url: string;
  children?: JSX.Element | string;
  image?: string;
  noindex?: boolean;
};

const SEO = ({ title, description, url, children = null, image = undefined, noindex }: Props) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <link rel="canonical" href={url} />
      <meta property="og:url" content={url} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      {image && <meta property="og:image" content={image} />}
      {noindex && <meta name="robots" content="noindex" />}
      {children}
    </Helmet>
  );
};

export const seoPropsFromBaseString = (baseString: string, url: string) => ({
  title: __(`seo.${baseString}Title`),
  description: __(`seo.${baseString}Description`),
  url,
});

export default SEO;
