import { useAppSelector } from '@/hooks';
import { BookState } from '@/types/state/book';
import Modal from '../redesign/Modal/Modal';
import { useBookAppointmentContext } from './BookAppointment.hooks';
import PickEmployeeView from './views/PickEmployeeView';
import PickServicesView from './views/PickServicesView';
import PickServiceView from './views/PickServiceView';
import SummaryView from './views/SummaryView';

const BookAppointment = () => {
  const { place } = useAppSelector((state) => state.book) as BookState;
  const { view, isOpen } = useBookAppointmentContext();

  if (!place || view.current === undefined) return null;

  return (
    <Modal isOpen={isOpen} shouldCloseOnOverlayClick={false}>
      {view.current === 'pickServices' && <PickServicesView />}
      {view.current === 'pickService' && <PickServiceView />}
      {view.current === 'pickEmployee' && <PickEmployeeView />}
      {view.current === 'summary' && <SummaryView />}
    </Modal>
  );
};

export default BookAppointment;
