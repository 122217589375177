import { searchActions } from '@/actions';
import FilterTemplate from '@/components/modules/pages/search/FilterTemplate';
import { getIpLatLon, setCookie, getGeoLocation } from '@/helpers';
import { __ } from '@/locale';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

class LocationSelection extends React.Component {
  constructor(props) {
    super(props);
    this.getContent = this.getContent.bind(this);
    this.select = this.select.bind(this);

    let categories = props.main || [];
    if (categories.count > 0 && categories[0].name !== 'All') {
      categories.unshift({ name: 'All' });
    }
    const location = props.search ? props.search.location || '' : '';
    this.submit = this.submit.bind(this);
    this.handleLocationChange = this.handleLocationChange.bind(this);
    this.state = {
      locations: ['Stockholm', 'Göteborg', 'Malmö', 'Uppsala', 'Västerås', 'Linköping', 'Norrköping', 'Jönköping'],
      location: location,
    };
  }
  componentDidMount() {
    this.searchInput.focus();
    this.isMounded = true;
  }

  componentWillUnmount() {
    this.isMounded = false;
  }

  select(location) {
    this.setState({ location: location }, () => setTimeout(this.submit, 100));
  }

  submit(e) {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    const { dispatch } = this.props;

    dispatch(searchActions.setParameter({ location: this.state.location }));
    this.props.history.goBack();
  }

  setLatitudeAndLongitude(latitude, longitude) {
    const { dispatch } = this.props;
    const pos = { position: latitude + '-' + longitude };
    setCookie('position', pos);
    dispatch(searchActions.setParameter(pos));
    if (this.isMounded) {
      this.setState(pos);
      this.handleLocationChange({ target: { value: __('currentLocation') } });
    }
    dispatch(searchActions.stopLocationLoader());
  }

  getIpBasedLocation() {
    const { dispatch } = this.props;
    getIpLatLon()
      .then((response) => {
        this.setLatitudeAndLongitude(response.lat, response.lon);
        dispatch(searchActions.stopLocationLoader());
      })
      .catch((data, status) => {
        dispatch(searchActions.stopLocationLoader());
      });
  }

  getCurrentPosition() {
    const { dispatch } = this.props;
    getGeoLocation(
      this.setLatitudeAndLongitude,
      (cached) => {},
      () => {
        dispatch(searchActions.startLocationLoader());
      },
      () => {
        dispatch(searchActions.stopLocationLoader());
      },
    );
  }

  getCurrentLocation() {
    const { dispatch } = this.props;
    return (
      <li
        key="0"
        onClick={(e) => {
          dispatch(searchActions.startLocationLoader());
          this.getCurrentPosition();
          this.setState({ location: __('currentLocation') }, () => setTimeout(this.submit, 100));
        }}
        className="category current">
        {__('currentLocation')}
      </li>
    );
  }

  handleLocationChange(e) {
    let value = e.target.value;
    this.setState({ location: value });
  }

  getContent() {
    let list = this.state.locations.map((location, id) => {
      return (
        <li key={id} onClick={() => this.select(location)} className="category">
          <span className="label">{location}</span>
        </li>
      );
    });
    return (
      <form onSubmit={this.submit}>
        <div className="filters location">
          <div className="col-12">
            <input
              type="text"
              className=" form-control location-input"
              placeholder={__('In what area?')}
              aria-label={__('In what area?')}
              ref={(input) => {
                this.searchInput = input;
              }}
              defaultValue={this.state.location}
              onChange={this.handleLocationChange}
            />
          </div>
          <div className="col-12 pl-0 pr-0">
            <ul>
              {this.getCurrentLocation()}
              <li className="category title">{__('popularSearches')}</li>
              {list}
            </ul>
          </div>
          <div className="col-12">
            <button className="btn btn-primary btn-block" style={{ height: '50px' }} type="submit">
              {__('search')}
            </button>
          </div>
        </div>
      </form>
    );
  }
  render() {
    const content = this.getContent();
    const title = __('In what area?');
    return <FilterTemplate content={content} title={title} />;
  }
}

function mapStateToProps(state) {
  const { search } = state;
  const { main } = state.category;
  return {
    main,
    search,
  };
}

const Location = withRouter(connect(mapStateToProps)(LocationSelection));
export default Location;
