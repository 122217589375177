import { LinkButton } from '@/components/elements/forms/buttons';
import { BlogLayout } from '@/components/layouts/blog';
import { CategoryNav, FeaturedPost, Heading, Hero, LatestPosts } from '@/components/modules/pages/blog';
import { url } from '@/helpers';
import useScrollToTop from '@/hooks/useScrollToTop';
import { _s } from '@/locale';
import { Helmet } from 'react-helmet';
import { BANNER_SLOT_LOCATION } from '@/constants/banner';
import BannerSlot from '@/components/modules/banner/BannerSlot';
import { useAppSelector } from '@/hooks';

const getSEO = () => {
  const title = _s('seo.blogBlogTitle');
  const description = _s('seo.blogBlogDescription');
  const pageUrl = `${url.getBaseUrl()}blogg`;
  const imageUrl = url.getBaseImageUrl();

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <link rel="canonical" href={pageUrl} />

      <meta property="og:url" content={pageUrl} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={imageUrl} />
    </Helmet>
  );
};

const BlogHome = () => {
  const flags = useAppSelector((state) => state.flags);
  useScrollToTop();

  return (
    <BlogLayout>
      <Hero />
      <div className="container mb-32 mt-10 md:mt-20">
        <Heading />
        <CategoryNav />
        <FeaturedPost />
        <BannerSlot
          config={flags[BANNER_SLOT_LOCATION.MIDDLE_BANNER_BLOG]?.payload}
          locationId={BANNER_SLOT_LOCATION.MIDDLE_BANNER_BLOG}
          wrapperClass="my-10 mb-8"
        />
        <LatestPosts />
        <LinkButton variant="primary" to="/blogg/list">
          {_s('blogPage.viewAllPosts')}
        </LinkButton>
      </div>
      {getSEO()}
    </BlogLayout>
  );
};

export default BlogHome;
