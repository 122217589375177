import { TurnstileAction } from '@/actions/turnstile';
import { TurnstileState } from '@/types/state/turnstile';

const initialState: TurnstileState = {
  token: null,
  verified: false,
};

export function turnstile(state: TurnstileState = initialState, action: TurnstileAction) {
  switch (action.type) {
    case 'TURNSTILE_ADD_TOKEN':
      return {
        ...state,
        token: action.payload.token,
        verified: false,
      };
    case 'TURNSTILE_REMOVE_TOKEN':
      return {
        ...state,
        token: null,
        verified: false,
      };
    case 'TURNSTILE_VERIFY_TOKEN':
      return {
        ...state,
        verified: action.payload.success,
        token: action.payload.token,
      };

    default: {
      return state;
    }
  }
}
