import { _s } from '@/locale';
import { useState } from 'react';
import { server } from '.';

export const useNewsLetter = (firstName, lastName, email) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [message, setMessage] = useState(null);

  const submit = async () => {
    setLoading(true);

    server.request
      .post('/newsletter/subscribers', { firstName, lastName, email })
      .then(({ data }) => {
        setLoading(false);
        const success = data.status === 'success';
        setError(!success);

        return setMessage(_s(`newsletter${success ? 'Success' : 'Error'}`));
      })
      .catch((error) => {
        setLoading(false);
        setError(true);
        setMessage(_s('newsletterError'));
      });
  };

  return [loading, message, error, submit];
};

export const useUnsubscribeForm = (email, deliveryId, setUnsubscribe) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [message, setMessage] = useState(null);

  const submit = async (unsubscribe) => {
    setLoading(true);
    const sBase = !!unsubscribe ? 'unsubscribe.unsubscribe' : 'unsubscribe.subscribe';
    server.request
      .post('/newsletter/unsubscribe', { email, deliveryId, unsubscribe })
      .then(({ data }) => {
        const success = data.status === 'success';
        if (success) {
          setUnsubscribe(!unsubscribe);
        }
        setError(!success);
        setLoading(false);
        return setMessage(_s(`${sBase}${success ? 'Success' : 'Error'}`));
      })
      .catch((error) => {
        setError(true);
        setMessage(_s(`${sBase}Error`));
        setLoading(false);
      });
  };

  return [loading, message, error, submit];
};
