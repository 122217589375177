import { useLottie } from 'lottie-react';
import { useEffect } from 'react';
import { AnimationProps } from './types';

const AnimationWrapper = ({
  loop = false,
  style,
  animationData,
  onAnimationComplete,
}: AnimationProps & { animationData: any }) => {

  const options = {
    animationData,
    loop,
  };

  const { View, animationLoaded, animationItem } = useLottie(options);

  useEffect(() => {
    if (animationLoaded && animationItem && onAnimationComplete) {
      if (!animationItem.loop && !loop) {
        animationItem?.addEventListener?.('complete', onAnimationComplete);
      }
    }
  }, [loop, animationItem, animationLoaded, onAnimationComplete]);

  return (
    <div className="relative" style={style ?? { width: 128, height: 128 }}>
      {View}
    </div>
  );
};

export default AnimationWrapper;
