import { trackMpEvent } from '@/helpers';

function handleClick() {
  trackMpEvent('buy_gift_card_clicked', {
    screen_name: 'search_results',
    trigger_source: 'top',
  });
}

function SERPGiftcardBanner() {
  return (
    <a
      href="/giftcards/buy"
      className="flex h-10 w-full items-center justify-center"
      style={{
        background: 'linear-gradient(270deg, #ffffff 0%, #f7f8fa 18.61%, #f7f8fa 76.19%, #ffffff 100%)',
      }}
      onClick={handleClick}>
      <img src="/images/banner-serp-top.svg" width="170" height="19" alt="Bokadirekts Giftcard" />
    </a>
  );
}
export default SERPGiftcardBanner;
