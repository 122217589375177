import { bookActions } from '@/actions';
import Header from '@/components/elements/Header/Header';
import { getEmployeesWhoPerformServices } from '@/helpers';
import { useAppDispatch, useAppSelector } from '@/hooks';
import useMobileView from '@/hooks/useMobileView';
import { _s } from '@/locale';
import { BookState } from '@/types/state/book';
import { PlaceEmployee } from '@/types/state/shared';
import Modal from '../../redesign/Modal/Modal';
import { ScrollToTopElement } from '../BookAppointment.helpers';
import { useBookAppointmentContext } from '../BookAppointment.hooks';
import EmployeeItem from '../shared/EmployeeItem';
import Summary, { SummaryProps } from '../shared/Summary';

const baseTranslationKey = 'components.modules.modals.BookAppointment.views.PickEmployeeView';

const PickEmployeeView = () => {
  const { isMobileView } = useMobileView();
  const dispatch = useAppDispatch();
  const context = useBookAppointmentContext();
  const { employee, place, services: pickedServices, time } = useAppSelector((state) => state.book) as BookState;
  const hasPickedAddonServices = pickedServices.some((service) => service?.about?.settings?.isAddOn);
  const employeesWhoPerformServices: PlaceEmployee[] = getEmployeesWhoPerformServices(
    place.employees,
    pickedServices.map((service) => service.id),
  );

  const summaryProps: SummaryProps = {
    pickedEmployee: undefined,
    pickedServices,
    showAddAdditionalService: !hasPickedAddonServices,
    onRemoveService: handleRemoveService,
    onShowAvailableServices: handleShowAvailableServices,
  };

  function handleShowAvailableServices() {
    context.updateView('pickServices');
  }

  function handleRemoveService(serviceId: number) {
    if (pickedServices.length === 1) {
      context.updateView('pickServices');
    }
    // @ts-ignore
    dispatch(bookActions.popService(serviceId, time && time.timestamp, pickedServices ?? [], employee, place, true));
  }

  function handleSelectEmployee(employee?: PlaceEmployee) {
    if (!employee) {
      // if user selects "any employee" we remove the picked employee
      dispatch(bookActions.removeEmployee());
      context.updateView('summary');
      return;
    }
    dispatch(bookActions.pickEmployee(employee.id, employee.about.priceListId || 0));
    context.updateView('summary');
  }

  return (
    <Modal.Content floating={!isMobileView}>
      <ScrollToTopElement />
      <Modal.Header title={_s(`${baseTranslationKey}.title`)} onBack={context.close} />
      <div className={`pb-lg flex flex-col ${isMobileView ? 'px-lg' : ''}`}>
        <div className="p-md">
          <Summary {...summaryProps} />
        </div>
        <div className="pt-xxl">
          <Header label={_s(`${baseTranslationKey}.title`)} size="md" />
        </div>
        <ul>
          <EmployeeItem onSelect={handleSelectEmployee} underline />
          {employeesWhoPerformServices.map((employee, idx) => {
            return (
              <EmployeeItem
                key={idx}
                employee={employee}
                onSelect={handleSelectEmployee}
                underline={employeesWhoPerformServices.length - 1 !== idx}
              />
            );
          })}
        </ul>
      </div>
    </Modal.Content>
  );
};

export default PickEmployeeView;
