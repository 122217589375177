import { server } from '../helpers';

export const shareServices = {
  share,
};

function share(params) {
  return server.request
    .get('/share/generate-link', { params })
    .then((data) => {
      return server.handleSuccess(data);
    })
    .catch(server.handleError);
}
