import { CHECKOUT_PAYMENT_METHOD } from '@/constants/checkout';
import { promiseWrapper } from '@/helpers';
import { getThreeDSecureRedirectedIfAny } from '@/helpers/adyen';
import { adyenServices } from '@/services';
import { SelectedPaymentMethod, SelectedStoredCofPaymentMethod } from '@/types/checkout';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  BookingCheckoutState,
  CHECKOUT_STATE_LOCALSTORAGE_KEY,
  SubmitBookingContext,
  SuccessRedirectData,
  handleSubmitThreeDSPayment,
} from '../BookingCheckout.hooks';

type SubmitCofThreeDSecureRedirectContext = SubmitBookingContext & {
  redirectResult: string;
  savedCheckoutState: BookingCheckoutState;
};

export type SavedBookingCheckoutState = Omit<
  SubmitCofThreeDSecureRedirectContext,
  'redirectResult' | 'successCallback' | 'errorCallback'
>;

type ThreeDSecureSuccessRedirect = {
  pathname: '/booking/confirmation';
  search?: string;
  state: {
    selectedPaymentMethod?: SelectedPaymentMethod;
    savedCheckoutState?: BookingCheckoutState;
  };
};

type ThreeDSecureErrorRedirect = {
  pathname: '/booking/checkout';
  search?: string;
  state: {
    savedCheckoutState?: BookingCheckoutState;
    selectedPaymentMethod?: SelectedPaymentMethod;
  };
};

function getSavedCheckoutStateFromLocalStorage(): SavedBookingCheckoutState | null {
  const savedCheckoutState: SavedBookingCheckoutState = JSON.parse(
    localStorage.getItem(CHECKOUT_STATE_LOCALSTORAGE_KEY),
  );
  savedCheckoutState && localStorage.removeItem(CHECKOUT_STATE_LOCALSTORAGE_KEY);
  return savedCheckoutState;
}

/**
 * If we are redirected from 3DSecure we need to handle the redirect and submit the booking.
 * When we mount app we check if the user comes from 3DSecure redirect and if there is a saved checkout state
 * if so, we try to submit the booking.
 */
async function handleSubmitRedirectThreeDS(context: SubmitCofThreeDSecureRedirectContext) {
  const { redirectResult, savedCheckoutState } = context;
  const { summary, cofThreeDS, selectedPaymentMethod } = savedCheckoutState;

  const { adyenPaymentData } = cofThreeDS;

  switch (selectedPaymentMethod.type) {
    case CHECKOUT_PAYMENT_METHOD.APPLE_PAY:
    case CHECKOUT_PAYMENT_METHOD.GOOGLE_PAY:
    case CHECKOUT_PAYMENT_METHOD.STORED_COF: {
      handleSubmitThreeDSPayment({ ...context, paymentMethod: selectedPaymentMethod.type, cofThreeDS })(
        {
          data: { details: { redirectResult, paymentData: adyenPaymentData } },
        },
        summary.payLater,
      );
    }
  }
}

const useValidateThreeDSecureRedirect = () => {
  const location = useLocation();
  const [redirectResult] = useState<string | false>(getThreeDSecureRedirectedIfAny(location));
  const [checkoutState] = useState<SavedBookingCheckoutState | null>(getSavedCheckoutStateFromLocalStorage());
  const [redirect, setRedirect] = useState<ThreeDSecureSuccessRedirect | ThreeDSecureErrorRedirect | false>(false);

  useEffect(() => {
    const { savedCheckoutState, bookingRequestBody, bookingTrackingProps } = checkoutState || {};

    if (!redirectResult || !savedCheckoutState) {
      setRedirect({
        pathname: '/booking/checkout',
        search: '?paymentValidateSuccessRedirect=false',
        state: { savedCheckoutState },
      });
      return;
    }

    if (redirectResult && savedCheckoutState) {
      /**
       * if we don't have a bookingRequestBody we are validating storing a card
       * and not submitting a booking, so we just validate the 3DSecure and redirect to checkout
       * with the new card set as selected payment method
       */
      if (!bookingRequestBody) {
        (async () => {
          const { data, error } = await promiseWrapper(adyenServices.validate3DS({ details: { redirectResult } }));

          const recurringDetailReference = data?.additionalData?.['recurring.recurringDetailReference'];

          if (error || !recurringDetailReference) {
            setRedirect({
              pathname: '/booking/checkout',
              search: '?paymentValidateSuccessRedirect=false',
              state: { savedCheckoutState },
            });
            return;
          }

          // build the selected payment method with the new stored card to be set as preselected payment method on redirect
          const selectedPaymentMethod: SelectedStoredCofPaymentMethod = {
            ...savedCheckoutState.selectedPaymentMethod,
            type: CHECKOUT_PAYMENT_METHOD.STORED_COF,
            id: recurringDetailReference,
            brand: 'unknown',
            lastFour: 'unknown',
          };

          setRedirect({
            pathname: `/booking/checkout`,
            search: '?paymentValidateSuccessRedirect=true',
            state: {
              savedCheckoutState: {
                ...savedCheckoutState,
                selectedPaymentMethod,
              },
            },
          });
        })();

        return;
      }

      const successCallback = ({ responseData, paymentMethod }: SuccessRedirectData) => {
        switch (paymentMethod) {
          case CHECKOUT_PAYMENT_METHOD.STORED_COF:
          case CHECKOUT_PAYMENT_METHOD.GOOGLE_PAY:
          case CHECKOUT_PAYMENT_METHOD.APPLE_PAY: {
            const selectedPaymentMethod =
              savedCheckoutState.selectedPaymentMethod.type === CHECKOUT_PAYMENT_METHOD.STORED_COF
                ? {
                    type: savedCheckoutState.selectedPaymentMethod.type,
                    id: savedCheckoutState.selectedPaymentMethod.id,
                    brand: savedCheckoutState.selectedPaymentMethod.brand,
                    lastFour: savedCheckoutState.selectedPaymentMethod.lastFour,
                  }
                : savedCheckoutState.selectedPaymentMethod;

            setRedirect({
              pathname: `/booking/confirmation`,
              search: `?bookingId=${responseData.id}&type=cof`,
              state: { ...(selectedPaymentMethod && { selectedPaymentMethod }) },
            });
          }
        }
      };
      const errorCallback = () => {
        setRedirect({
          pathname: '/booking/checkout',
          search: '?paymentValidateSuccessRedirect=false',
          state: { savedCheckoutState },
        });
      };
      const context = { savedCheckoutState, bookingRequestBody, bookingTrackingProps, successCallback, errorCallback };

      (async () => {
        handleSubmitRedirectThreeDS({
          ...context,
          redirectResult,
        });
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { redirect };
};

export default useValidateThreeDSecureRedirect;
