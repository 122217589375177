import { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

const Portal = ({ children, ...props }) => {
  const portalElement = useRef(null);

  useEffect(() => {
    const p = document.createElement('div');
    document.body.appendChild(p);
    portalElement.current = p;

    return () => {
      if (portalElement.current) {
        document.body.removeChild(portalElement.current);
      }
    };
  }, []);

  const duration = 200;
  const styles = `
    .fade-enter { opacity: 0.01; }
    .fade-enter.fade-enter-active { opacity: 1; transition: opacity ${duration}ms; }
    .fade-leave { opacity: 1; }
    .fade-leave.fade-leave-active { opacity: 0.01; transition: opacity ${duration}ms; }
  `;

  return portalElement.current
    ? createPortal(
        <div>
          <style>{styles}</style>
          <TransitionGroup {...props}>
            <CSSTransition timeout={{ enter: duration, exit: duration }} classNames="fade">
              {children}
            </CSSTransition>
          </TransitionGroup>
        </div>,
        portalElement.current,
      )
    : null;
};

export default Portal;
