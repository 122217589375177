import Icon from '@/components/icons/Icon';
import { ReactHookFormRegister } from '@/components/modules/checkout/CheckoutFormFields/CheckoutFormFields';
import { classnames } from '@/helpers';
import { InputHTMLAttributes, LegacyRef, forwardRef } from 'react';
import InputMask from 'react-input-mask';
import { twMerge } from 'tailwind-merge';

type GenericListInputProps = {
  id: string;
  label?: string;
  error?: string;
  leftIcon?: React.ReactNode;
  mask: string;
};

type ListInputProps = InputHTMLAttributes<HTMLInputElement> & GenericListInputProps & ReactHookFormRegister<any>;

const ListMaskedInput = forwardRef(
  (
    { id, label, mask, error, leftIcon, type = 'text', className, disabled, ...props }: ListInputProps,
    ref: LegacyRef<HTMLInputElement>,
  ) => {
    return (
      <div className={classnames('pl-lg flex w-full flex-col', disabled && 'opacity-40')}>
        {label && (
          <label htmlFor={id} className="text-black-900 pt-md text-m block">
            {label}
          </label>
        )}
        <div className={classnames('pr-lg relative flex border-b', error ? 'border-danger-500' : 'border-black-100')}>
          {leftIcon && <div className="pr-md flex shrink-0 grow-0">{leftIcon}</div>}
          <InputMask
            id={id}
            mask={mask}
            ref={ref}
            type={type}
            className={twMerge(
              'placeholder:text-black-600 text-black-900 py-md w-full text-sm outline-none disabled:bg-transparent',
              className,
            )}
            disabled={disabled}
            {...props}
          />
          {error && (
            <div className="flex shrink-0 grow-0">
              <Icon variant="warning" color="danger-500" />
            </div>
          )}
        </div>
        {error && <div className="text-danger text-xxs mt-xxs flex justify-start">{error}</div>}
      </div>
    );
  },
);

export default ListMaskedInput;
