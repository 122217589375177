import { loadingActions } from '@/actions';
import { Card } from '@/components/elements/cards';
import BorderLabelTextInput from '@/components/elements/forms/BorderLabelTextInput';
import Checkbox from '@/components/elements/forms/Checkbox/Checkbox';
import RadioButton from '@/components/elements/forms/RadioButton';
import { Button } from '@/components/elements/forms/buttons';
import { ArrowIcon, CloseIcon, EditIcon, SuccessIcon, TrashIcon } from '@/components/icons';
import PageViewLayout from '@/components/layouts/PageViewLayout/PageViewLayout';
import SEO from '@/components/modules/SEO';
import Breadcrumbs from '@/components/modules/breadcrumbs/Breadcrumbs';
import { ContactUsSection } from '@/components/modules/pages/support/ContactUs';
import { RandomSupportFaq } from '@/components/modules/pages/support/RandomSupportFaq';
import { supportFaq, supportFormFaq } from '@/constants/faq';
import { isNotEmptyStr, isServer, isSistaminuten, removeCookie, trackPage, url, validate } from '@/helpers';
import { __, _s } from '@/locale';
import { supportServices } from '@/services';
import { getScreenName, handleLoginClick } from '@/services/navigationServices';
import { NavItemIdentifier } from '@/types/navigation';
import * as moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import Calendar from 'react-calendar';
import { connect, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import isEmail from 'validator/lib/isEmail';
import isISO8601 from 'validator/lib/isISO8601';
import isLength from 'validator/lib/isLength';
import isMobilePhone from 'validator/lib/isMobilePhone';

const SupportForm = ({ user }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const fileInput = useRef(null);
  const formSections = supportFormFaq.map((item) => {
    const faqSection = supportFaq.find((i) => i.path === item.sectionPath);
    item.title = faqSection.title;
    item.icon = faqSection.icon;
    return item;
  });

  const getUser = () => {
    const userData = { email: {}, name: {} };
    if (user?.contact?.email) {
      userData['email'] = { value: user.contact.email };
      userData['name'] = { value: user.about?.name || user.contact.email };
    }
    return userData;
  };

  // remove old cookie
  removeCookie('bd_sf');
  const backupData = !isServer && sessionStorage.getItem('bd_sf') && JSON.parse(sessionStorage.getItem('bd_sf'));
  const initialFormData = {
    section: {},
    question: {},
    followUpQuestions: {},
    user: getUser(),
    moreInfo: { message: {}, files: [] },
  };
  const [formData, setFormData] = useState<any>(backupData ? backupData : initialFormData);

  const [selectedSectionPath, setSelectedSectionPath] = useState<string>(
    location.state && location.state.selectedSection,
  );
  const [activeQuestion, setActiveQuestion] = useState<string>(location.state && location.state.active);
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(false);

  if (!isServer) {
    window.history.replaceState({}, document.title);
  }
  const userId = (user && user.id) || null;

  useEffect(() => {
    if (isServer) return;
    window.scrollTo(0, 0);
    trackPage();
    // trackMpEvent('screen_shown', { screen_name: 'support_form' });
  }, []);

  useEffect(() => {
    if (
      (location?.state?.selectedSection && location.state.selectedSection !== selectedSectionPath) ||
      (location?.state?.active && location.state.active !== activeQuestion)
    ) {
      setSelectedSectionPath(location.state.selectedSection);
      setActiveQuestion(location.state.active);

      if (!isServer) {
        window.scrollTo(0, 0);
        window.history.replaceState({}, document.title);
      }
    }
  }, [location]);

  useEffect(() => {
    if (selectedSectionPath) {
      const supportFormSection = formSections.find((item) => item.sectionPath === selectedSectionPath);
      sectionClicked(supportFormSection);
      questionClicked(
        supportFormSection ? supportFormSection.options.find((item) => item.id === activeQuestion) : undefined,
      );
    }
  }, [selectedSectionPath, activeQuestion]);

  useEffect(() => {
    if (userId) {
      formData.user = getUser();
      saveFormData({ ...formData });
    }
  }, [userId]);

  const saveFormData = (data) => {
    setFormData(data);
    if (!isServer) {
      sessionStorage.setItem('bd_sf', JSON.stringify({ ...data }));
    }
  };

  const clearFormData = () => {
    setFormData({ ...initialFormData });
  };

  const toggleBlockOpen = (key, value = false) => {
    if (formData[key]) {
      formData[key].closed = value;
    } else {
      if (formData.followUpQuestions[key]) {
        formData.followUpQuestions[key].closed = value;
      }
    }

    saveFormData({ ...formData });
  };

  const clearUserBlock = (e) => {
    formData.user = getUser();
    saveFormData({ ...formData });
  };

  const handleSectionClick = (section) => (e) => {
    sectionClicked(section);
  };

  const handleQuestionClick = (question) => (e) => {
    questionClicked(question);
  };

  const sectionClicked = (section) => {
    if (section) {
      if (section.sectionPath !== formData.section.key) {
        setSelectedSectionPath(section.sectionPath);
        saveFormData({ ...initialFormData, user: { ...formData.user } });
      }

      formData.section = {
        selected: section,
        key: section.sectionPath,
        value: section.title,
        closed: true,
        icon: section.icon,
      };

      saveFormData({ ...formData });
    } else {
      clearFormData();
      setSelectedSectionPath(undefined);
    }
  };

  const questionClicked = (question) => {
    if (question) {
      if (question.id !== formData.question.key) {
        setActiveQuestion(question.id);
        setFollowUpQuestions(question);
      } else {
        if (!formData.followUpQuestions) {
          setFollowUpQuestions(question);
        }
      }

      formData.question = {
        selected: question,
        key: question.id,
        value: question.option,
        closed: true,
      };
    } else {
      formData.question = {};
      setActiveQuestion(undefined);
      formData.followUpQuestions = {};
    }

    delete formData.moreInfo.error;

    saveFormData({ ...formData });
  };

  const setFollowUpQuestions = (question) => {
    formData.followUpQuestions = {};
    (question.followUpQuestions || []).forEach((followUpQuestion) => {
      const formKey = question.id + '-' + followUpQuestion.id;
      formData.followUpQuestions[formKey] = {
        selected: followUpQuestion,
        key: followUpQuestion.id,
      };

      if (followUpQuestion.type === 'date') {
        formData.followUpQuestions[formKey].closed = true;
      }

      if (followUpQuestion.connected && followUpQuestion.connected.id) {
        formData.followUpQuestions[formKey].connectedKey = `${question.id}-${followUpQuestion.connected.id}`;
        formData.followUpQuestions[formKey].connectedValue = followUpQuestion.connected.value;
      }
    });
  };

  const blockButtons = (formKey) => {
    return (
      <>
        <Button
          variant="secondary"
          size="sm"
          className="ml-auto hidden !h-10 text-base sm:block"
          onClick={() => toggleBlockOpen(formKey)}>
          {_s('supportPage.change')}
        </Button>
        <Button
          variant="secondary"
          size="sm"
          leftIcon={<EditIcon className="h-3" />}
          className="ml-auto block !h-7 !px-1 sm:hidden"
          onClick={() => toggleBlockOpen(formKey)}
        />
      </>
    );
  };

  const handleInputChange = (name, value, formSectionKey, input = null) => {
    formData[formSectionKey][name].value = value;
    saveFormData({ ...formData });
  };

  const handleCheckboxChange = (input, value) => (e) => {
    const { checked } = e.target;
    const formInput = { ...formData.followUpQuestions[input] };
    if (!formInput.value) {
      formInput.value = [];
      formInput.valueOption = [];
    }

    if (checked) {
      formInput.value.push(value.id);
      formInput.valueOption.push(value.option);
      formInput.value = Array.from(new Set(formInput.value));
      formInput.valueOption = Array.from(new Set(formInput.valueOption));
    } else {
      const index = formInput.value.indexOf(value.id);
      if (index > -1) {
        formInput.value.splice(index, 1);
        formInput.valueOption.splice(index, 1);
      }
    }

    formData.followUpQuestions[input] = formInput;
    saveFormData({ ...formData });
  };

  const handleCheckboxLabelClick = (input, value) => (e) => {
    const formInput = { ...formData.followUpQuestions[input] };

    if (!formInput.value) {
      formInput.value = [];
      formInput.valueOption = [];
    }

    const index = formInput.value.indexOf(value.id);

    if (index > -1) {
      formInput.value.splice(index, 1);
      formInput.valueOption.splice(index, 1);
    } else {
      formInput.value.push(value.id);
      formInput.value = Array.from(new Set(formInput.value));

      formInput.valueOption.push(value.option);
      formInput.valueOption = Array.from(new Set(formInput.valueOption));
    }

    formData.followUpQuestions[input] = formInput;
    saveFormData({ ...formData });
  };

  const handleCalendarDateChange = (formKey, value, formSectionKey) => {
    const currentValue = { ...formData[formSectionKey][formKey] }.value;
    const date = moment(value).format('YYYY-MM-DD');
    const time = currentValue && moment(currentValue).isValid() ? moment(currentValue).format('HH:mm') : '00:00';
    formData[formSectionKey][formKey].value = `${date} ${time}`;
    formData[formSectionKey][formKey].closed = true;
    saveFormData({ ...formData });
    validateInput(formKey);
  };

  const isValid = () => {
    let valid = true;

    if (!formData.section.key) {
      formData.section.error = _s('supportPage.requiredError');
      valid = false;
    } else {
      delete formData.section.error;
    }

    if (!formData.question.key) {
      formData.question.error = _s('supportPage.requiredError');
      valid = false;
    } else {
      delete formData.question.error;
    }

    Object.keys(formData.followUpQuestions).forEach((key) => {
      const followUpQuestion = formData.followUpQuestions[key];

      // ignore connected questions if conditions are not met
      if (followUpQuestion.connectedKey) {
        const connectedInput = formData.followUpQuestions[followUpQuestion.connectedKey];
        if (
          !(
            connectedInput &&
            connectedInput.value &&
            connectedInput.value.indexOf(followUpQuestion.connectedValue) !== -1
          )
        ) {
          return;
        }
      }

      if (validateInput(key)) {
        valid = false;
      }
    });

    // validate upload
    if (formData.question?.selected?.requiresUpload) {
      if (!formData.moreInfo?.files?.length) {
        formData.moreInfo.error = _s('supportPage.requiredError');
        valid = false;
      } else {
        delete formData.moreInfo.error;
      }
    } else {
      delete formData.moreInfo.error;
    }

    // validate user data
    if (formData.question?.selected?.requiresLogin) {
      if (!user?.contact?.email) {
        formData.user.error = _s('supportPage.loginError');
        valid = false;
      } else {
        delete formData.user.error;
      }
    } else {
      if (validateUserInput('email')) {
        valid = false;
      }

      if (validateUserInput('name')) {
        valid = false;
      }
    }
    saveFormData({ ...formData });

    return valid;
  };

  const submit = (e) => {
    setButtonDisabled(true);
    if (isValid()) {
      dispatch(loadingActions.show());
      supportServices
        .submitSupportForm(prepareFormData())
        .then((response) => {
          dispatch(loadingActions.hide());
          history.push('/support/form-submitted');
          sessionStorage.removeItem('bd_sf');
          setButtonDisabled(false);
        })
        .catch((error) => {
          dispatch(loadingActions.hide());
          setButtonDisabled(false);
        });
    } else {
      setButtonDisabled(false);
    }
  };

  const prepareFormData = () => {
    const data = {
      tagId: formData.question.selected?.tag,
      user: {
        name: formData.user.name.value,
        email: formData.user.email.value,
      },
      form: {
        section: { title: formData.section.value },
        question: { title: formData.question.value },
      },
      files: [],
    };

    Object.keys(formData.followUpQuestions).forEach((key) => {
      // ignore connected questions if conditions are not met
      const followUpQuestion = formData.followUpQuestions[key];
      if (followUpQuestion.connectedKey) {
        const connectedInput = formData.followUpQuestions[followUpQuestion.connectedKey];
        if (
          !(
            connectedInput &&
            connectedInput.value &&
            connectedInput.value.indexOf(followUpQuestion.connectedValue) !== -1
          )
        ) {
          return;
        }
      }

      let value = followUpQuestion.valueOption ? followUpQuestion.valueOption : followUpQuestion.value;
      data.form[key] = {
        label: followUpQuestion.selected.question,
        value: Array.isArray(value) ? value.join(',') : value,
      };
    });

    if (formData.moreInfo?.message?.value) {
      data.form['moreInfo'] = { label: 'Övrig information', value: formData.moreInfo.message.value };
    }

    data.files = formData.moreInfo.files.map((item) => item.name);

    return data;
  };

  const validateInput = (formKey, allowClose = true) => {
    let error = null;
    const value = formData.followUpQuestions[formKey]?.value || '';
    switch (formData.followUpQuestions[formKey]?.selected?.validation) {
      case 'email':
        error = validate([isNotEmptyStr, isEmail], _s('supportPage.emailError'))(value);
        break;
      case 'phone':
        error = validate([isNotEmptyStr, isMobilePhone], _s('supportPage.phoneError'))(value);
        if (!error && !isLength(value, { min: 10, max: 14 })) {
          error = _s('supportPage.phoneError');
        }
        break;
      case 'date':
        error = validate([isNotEmptyStr, isISO8601], _s('supportPage.dateError'))(value);
        if (!error && !moment(value).isValid()) {
          error = _s('supportPage.dateError');
        }
        break;
      case 'checkbox':
        const checked = formData.followUpQuestions[formKey]?.value || [];
        if (checked.length === 0) {
          error = _s('supportPage.requiredError');
        }
        break;
      default:
        error = validate([isNotEmptyStr], _s('supportPage.requiredError'))(value);
        break;
    }

    if (!error) {
      delete formData.followUpQuestions[formKey].error;
      if (allowClose) {
        formData.followUpQuestions[formKey].closed = true;
      }
    } else {
      formData.followUpQuestions[formKey].error = error;
    }

    saveFormData({ ...formData });
    return error;
  };

  const validateUserInput = (formKey) => {
    let error = null;

    switch (formKey) {
      case 'email':
        error = validate([isNotEmptyStr, isEmail], _s('supportPage.emailError'))(formData.user[formKey]?.value || '');
        break;
      default:
        error = validate([isNotEmptyStr], _s('supportPage.requiredError'))(formData.user[formKey]?.value || '');
        break;
    }

    if (!error) {
      delete formData.user[formKey].error;
    } else {
      formData.user[formKey].error = error;
    }

    saveFormData({ ...formData });

    return error;
  };

  const formSectionError = (formSectionKey) => {
    const error = formData[formSectionKey]?.error || formData[formSectionKey]?.tempError;
    if (error) {
      return (
        <span className="text-danger before:bg-danger text-m relative mt-2 pl-[10px] before:absolute before:left-0 before:top-[7px] before:h-[6px] before:w-[6px] before:translate-y-[-50%] before:rounded-full before:content-['']">
          {error}
        </span>
      );
    }
    return null;
  };

  const sectionCard = () => {
    const content =
      formData.section && formData.section.selected && formData.section.closed ? (
        <div className="lg:text-h-m flex items-center justify-start gap-x-4 text-base">
          {formData.section.icon && <formData.section.icon className="h-5" />}
          {formData.section.value}
          {blockButtons('section')}
        </div>
      ) : (
        formSections.map((section, key) => (
          <div
            key={key}
            className={`${
              key < formSections.length - 1 ? 'splitter' : ''
            } text-black-900 lg:text-h-m flex cursor-pointer items-center justify-start gap-x-4 pl-4 text-base`}
            onClick={handleSectionClick(section)}>
            <section.icon className="h-4" />
            {section.title}
          </div>
        ))
      );

    return (
      <Card>
        {!(formData.section && formData.section.selected) && (
          <p className="text-black-600 mb-1 text-sm lg:text-base">{_s(`supportPage.about2`)}</p>
        )}
        <h3 className="splitter lg:text-h-m text-sm font-bold lg:font-normal">{_s(`supportPage.about`)}</h3>
        {content}
        {formSectionError('section')}
      </Card>
    );
  };

  const questionCard = () => {
    if (formData.section && formData.section && formData.section.selected && formData.section.selected.options) {
      const content =
        formData.question && formData.question.closed ? (
          <div className="lg:text-h-m flex items-center justify-start gap-x-4 text-base">
            {formData.question.value}
            {blockButtons('question')}
          </div>
        ) : (
          formData.section.selected.options.map((item, key) => (
            <RadioButton
              key={key}
              iconPosition="left"
              className="!w-full border-none !bg-white"
              variant="small"
              checked={formData.question ? item.id === formData.question.key : false}
              onClick={handleQuestionClick(item)}>
              <span className="text-m ml-8 text-left lg:text-base">{item.option}</span>
            </RadioButton>
          ))
        );

      return (
        <Card className="mt-0">
          <h3 className="splitter lg:text-h-m text-sm font-bold lg:font-normal">{_s(`supportPage.moreSpecific`)}</h3>
          {content}
          {formSectionError('question')}
        </Card>
      );
    }

    return null;
  };

  const followUpQuestion = (formKey) => {
    const formSectionKey = 'followUpQuestions';
    const followUpQuestion = formData[formSectionKey][formKey];
    const { selected } = followUpQuestion;
    const value = (followUpQuestion && followUpQuestion.value) || '';
    let content = null;

    switch (selected.type) {
      case 'text':
        content = (
          <div className="flex flex-col sm:flex-row">
            <BorderLabelTextInput
              label={selected.placeholder}
              name={selected.id}
              value={value}
              setValue={(value) => handleInputChange(formKey, value, formSectionKey, selected)}
              placeholder={selected.placeholder}
              className="max-w-full sm:max-w-[290px]"
              disabled={(followUpQuestion && followUpQuestion.closed) || false}
              onBlur={() => validateInput(formKey)}
              error={followUpQuestion.error}
            />
            {value && !followUpQuestion.closed && (
              <Button
                size="sm"
                className="ml-auto mt-4 !h-10 flex-shrink-0 text-base sm:mt-0"
                onClick={() => validateInput(formKey)}>
                {_s('supportPage.add')}
              </Button>
            )}
            {followUpQuestion && followUpQuestion.closed && (
              <Button
                size="sm"
                variant="secondary"
                className="ml-auto mt-4 !h-10  flex-shrink-0 text-base sm:mt-0"
                onClick={() => toggleBlockOpen(formKey)}>
                {_s('supportPage.change')}
              </Button>
            )}
          </div>
        );
        break;
      case 'date':
        const momentObject = moment(value);
        const date = momentObject.isValid() ? new Date(momentObject.format('YYYY-MM-DD')) : undefined;
        content = (
          <div className="date-time-filters flex flex-col">
            <BorderLabelTextInput
              label={selected.placeholder}
              name={selected.id}
              value={value}
              setValue={(value) => handleInputChange(formKey, value, formSectionKey, selected)}
              onFocus={() => toggleBlockOpen(formKey)}
              placeholder={selected.placeholder}
              className="mb-6 max-w-full sm:max-w-[290px]"
              onBlur={() => validateInput(formKey, false)}
              error={followUpQuestion.error}
            />
            {followUpQuestion && !followUpQuestion.closed && (
              <Calendar
                calendarType="ISO 8601"
                onChange={(value) => handleCalendarDateChange(formKey, value, formSectionKey)}
                value={date}
                prev2Label={null}
                nextLabel={<ArrowIcon className="mx-auto h-6 w-6 p-1" />}
                prevLabel={<ArrowIcon className="mx-auto h-6 w-6 rotate-180 p-1" />}
                next2Label={null}
                formatShortWeekday={(_, value) => ['Sö', 'Må', 'Ti', 'On', 'To', 'Fr', 'Lö'][value.getDay()]}
                locale="sv-SE"
                selectRange={false}
                className="w-full"
              />
            )}
          </div>
        );
        break;
      case 'checkbox':
        content = (
          <div className="flex flex-col">
            {followUpQuestion.selected.values.map((checkedValue) => {
              const label = (
                <label onClick={handleCheckboxLabelClick(formKey, checkedValue)}>{checkedValue.option}</label>
              );
              return (
                <div className="relative" key={checkedValue.id}>
                  <Checkbox
                    checked={(followUpQuestion.value || []).indexOf(checkedValue.id) > -1}
                    label={label}
                    onChange={handleCheckboxChange(formKey, checkedValue)}
                  />
                </div>
              );
            })}

            {followUpQuestion.error && (
              <span className="text-danger before:bg-danger text-m relative mt-2 pl-[10px] before:absolute before:left-0 before:top-[50%] before:h-[6px] before:w-[6px] before:translate-y-[-50%] before:rounded-full before:content-['']">
                {followUpQuestion.error}
              </span>
            )}
          </div>
        );
        break;
      default:
        content = null;
    }

    // check if connected question exists and condition is valid for it to show
    if (followUpQuestion.connectedKey) {
      const connectedInput = formData[formSectionKey][followUpQuestion.connectedKey];
      if (
        !(
          connectedInput &&
          connectedInput.value &&
          connectedInput.value.indexOf(followUpQuestion.connectedValue) !== -1
        )
      ) {
        content = null;
      }
    }

    if (content) {
      return (
        <Card className="mt-0" key={formKey}>
          <h3 className="splitter lg:text-h-m text-sm font-bold lg:font-normal">{selected.question}</h3>
          {content}
        </Card>
      );
    }

    return null;
  };

  const followUpQuestions = () => {
    if (formData.question && formData.question.selected && formData.followUpQuestions) {
      return Object.keys(formData.followUpQuestions).map((key) => followUpQuestion(key));
    }

    return null;
  };

  const userDetailsCard = () => {
    // already logged in
    if (user?.contact?.email) {
      return null;
    }

    if (!formData?.question?.selected) {
      return null;
    }

    const loginMandatory = formData.question.selected.requiresLogin || false;

    return formData.user && formData.user && !formData.user.closed ? (
      <Card className="mt-0 flex flex-col items-center justify-center">
        <h3 className="text-black-600 mb-4 text-center text-base font-bold lg:mb-8 lg:text-lg lg:font-normal">
          {_s(`supportPage.login`)}
        </h3>
        <span className="text-black-900 block text-center text-sm">{_s(`supportPage.loginExplained`)}</span>
        <Button
          label={__('supportPage.login')}
          className="mt-4 lg:mt-8"
          variant="primary"
          size="lg"
          title={_s('supportPage.login')}
          onClick={() => handleLoginClick(dispatch, NavItemIdentifier.LogIn, getScreenName())}
        />
        {!loginMandatory && (
          <Button size="sm" variant="link" className="mt-4 text-base" onClick={() => toggleBlockOpen('user', true)}>
            {_s('supportPage.fillManually')}
          </Button>
        )}
        {formSectionError('user')}
      </Card>
    ) : (
      <Card className="mt-0">
        <h3 className="splitter lg:text-h-m flex text-sm font-bold lg:font-normal">
          <span>{_s(`supportPage.myDetails`)}</span>
          <CloseIcon className="ml-auto block w-4 cursor-pointer" onClick={clearUserBlock} />
        </h3>
        <BorderLabelTextInput
          label={_s(`supportPage.name`)}
          name="name"
          value={formData.user.name?.value || ''}
          setValue={(value) => handleInputChange('name', value, 'user')}
          placeholder={_s(`supportPage.name`)}
          className="mb-4 max-w-full sm:max-w-[290px]"
          onBlur={() => validateUserInput('name')}
          error={formData.user.name?.error}
        />
        <BorderLabelTextInput
          label={_s(`supportPage.email`)}
          name="email"
          value={formData.user.email?.value || ''}
          setValue={(value) => handleInputChange('email', value, 'user')}
          placeholder={_s(`supportPage.email`)}
          className="max-w-full sm:max-w-[290px]"
          onBlur={() => validateUserInput('email')}
          error={formData.user.email?.error}
        />
      </Card>
    );
  };

  const uploadedFiles = () => {
    if (formData.moreInfo?.files?.length > 0) {
      return formData.moreInfo.files.map((file, key) => {
        if (file && file.originalName) {
          return (
            <div className="mb-6 flex w-full" key={key}>
              <span className="text-m flex items-center lg:text-sm">
                <SuccessIcon className="text-primary mr-4 w-4 lg:w-5" />
                {file.originalName}
              </span>
              <TrashIcon className="ml-auto w-4 lg:w-5" onClick={handleDeleteFile(key)} />
            </div>
          );
        }
      });
    }

    return null;
  };

  const handleDeleteFile = (key) => () => {
    const file = formData.moreInfo.files[key];
    supportServices
      .deleteSupportFile({ fileName: file.name })
      .then((response) => {
        formData.moreInfo.files.splice(key, 1);
        saveFormData({ ...formData });
      })
      .catch((e) => {
        formData.moreInfo.files.splice(key, 1);
        saveFormData({ ...formData });
      });
  };

  const setUploadError = (error) => {
    formData.moreInfo.tempError = error;
    saveFormData({ ...formData });
    setTimeout(function () {
      delete formData.moreInfo.tempError;
      saveFormData({ ...formData });
    }, 3000);
  };

  const isFileSizeValid = (currentSize) => {
    const totalSize = (formData.moreInfo?.files || []).reduce((acc, file) => acc + file.size, 0);
    if (totalSize + currentSize <= 30) {
      return true;
    }

    return false;
  };

  const handleUpload = (e) => {
    e.preventDefault();
    if (fileInput.current?.files) {
      const file = fileInput.current.files?.[0];
      const size = file.size / 1024 / 1024;
      if (isFileSizeValid(size)) {
        let data = new FormData();
        data.append('file', file);
        supportServices
          .uploadSupportFile(data)
          .then((response) => {
            if (response.uploaded) {
              formData.moreInfo.files.push({ ...response.uploaded, size });
            } else {
              setUploadError(_s('supportPage.uploadError'));
            }
            fileInput.current.value = '';
            saveFormData({ ...formData });
          })
          .catch((e) => {
            fileInput.current.value = '';
            setUploadError(_s('supportPage.uploadError'));
          });
      } else {
        fileInput.current.value = '';
        setUploadError(_s('supportPage.uploadSizeError'));
      }
    }
  };

  const uploadCard = () => {
    return (
      <Card className="mt-0">
        <h3 className="splitter lg:text-h-m text-sm font-bold lg:font-normal">{_s('supportPage.otherInfo')}</h3>
        <BorderLabelTextInput
          label={_s(`supportPage.describe`)}
          name="message"
          value={formData.moreInfo.message?.value || ''}
          setValue={(value) => handleInputChange('message', value, 'moreInfo')}
          placeholder={_s(`supportPage.describe`)}
          className="mb-4 max-w-full"
        />
        <h3 className="text-sm lg:text-lg">{_s('supportPage.appendix')}</h3>
        <p className="text-black-600 text-m my-4">{_s('supportPage.attachFileOnDemand')}</p>
        {uploadedFiles()}
        <label htmlFor="uploadBtn" className="mb-4 block" onChange={handleUpload}>
          <input
            ref={fileInput}
            hidden
            id="uploadBtn"
            type="file"
            accept=".pdf,.png,.jpg,.gif,.txt,.mp4,.mov,.csv,.doc,.docx,.ics,.odt,.rtf,.xls,.xlsx,.ods"
          />
          <span className="border-primary hover:bg-primary text-black-900 h-10 cursor-pointer rounded-full border-[1px] px-6 py-2 text-base hover:text-white">
            {_s('supportPage.chooseFile')}
          </span>
        </label>
        {formSectionError('moreInfo')}
      </Card>
    );
  };

  return (
    <PageViewLayout type="mainView" wrapperClass="bg-gradient">
      <div className="container-fixed">
        <div className="flex flex-wrap justify-between gap-8 lg:flex-nowrap">
          <div className="flex-1">
            <Breadcrumbs
              className="my-7 text-sm"
              items={[
                { title: __('Start'), url: '/' },
                { title: __('support'), url: '/support' },
                { title: _s('supportPage.supportForm'), url: '/support/form' },
              ]}
            />
            <div
              className="h-[170px] w-auto bg-cover bg-bottom bg-no-repeat md:h-[213px] lg:max-w-[730px]"
              style={{ backgroundImage: 'url(/images/hero-support-form.png)' }}>
              <div className="container mx-auto flex h-full items-center justify-center">
                <h1 className="text-lg font-bold text-white lg:text-4xl">{_s('contactUs')}</h1>
              </div>
            </div>
            <Card
              style={{ margin: 0 }}
              className="tracking-content text-black-900 flex flex-col rounded-b-lg rounded-t-none bg-white">
              <h4 className="text-black-600 flex items-center justify-center gap-x-4 text-lg tracking-normal">
                {`${_s('support')} ${_s('supportPage.form')}`}
              </h4>
              {sectionCard()}
              {questionCard()}
              {followUpQuestions()}
              {userDetailsCard()}
              {uploadCard()}
              <div className="flex flex-col sm:flex-row">
                <span className="text-sm">{_s(`supportPage.makeSure`)}</span>
                <Button
                  disabled={buttonDisabled}
                  size="sm"
                  className="ml-auto mt-4 flex-shrink-0 text-base sm:ml-6 sm:mt-0"
                  onClick={submit}>
                  {_s('supportPage.submit')}
                </Button>
              </div>
            </Card>
            <div className="lg:hidden">{ContactUsSection}</div>
            <RandomSupportFaq />
          </div>
          <div className="mt-[50px] hidden w-full lg:block lg:max-w-[300px]  xl:max-w-[400px]">{ContactUsSection}</div>
        </div>
      </div>
      <SEO
        title={_s('seo.supportFormTitle')}
        description={_s('seo.supportFormDescription')}
        url={`${url.getBaseUrl()}support/form`}
        image={url.getBaseImageUrl()}>
        {isSistaminuten() && <meta name="robots" content="noindex" />}
      </SEO>
    </PageViewLayout>
  );
};

function mapStateToProps(state) {
  const { user } = state.users;
  const { show } = state.loading;
  return {
    user,
    show,
  };
}
export default connect(mapStateToProps)(SupportForm);
