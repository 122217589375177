import { ShippingMethod } from '@/constants/giftcardConstants';
import useRadioButtonGroup from '@/helpers/useRadioButtonGroup';
import { _s } from '@/locale';
import { CheckDiscountCodeResponse } from '@/types/api/services/giftcard';
import {
  BDKPInitiationResponse,
  BDKPInitiationSuccessResponse,
  BDKPUpdateResponse,
  KPOrderLine,
  KPPaymentMethodCategory,
} from '@/types/klarnaPayments';
import { useEffect, useState } from 'react';
import { z } from 'zod';
import { server } from '../helpers';
import { createKlarnaOrder } from './klarnaServices';

type Shipping = {
  name: string;
  address: string;
  zipcode: string;
  city: string;
};

type Greeting = {
  title: string;
  message: string;
  giver: string;
};

type ShippingMethod = 'shipping' | 'digital';

const initiateGiftCardPayment = (
  value: number,
  quantity: number,
  shippingMethod: ShippingMethod,
  ssn: string | null,
  isWellness: boolean,
  code: string,
  discount: number,
  serviceType?: Service,
  shipping?: Shipping,
  greeting?: Greeting,
  email?: string,
  newConfirmationPage?: boolean,
): Promise<BDKPInitiationResponse> =>
  server.request
    .post('/klarna/giftcards/payments', {
      value,
      quantity,
      shippingMethod,
      ...(ssn && { ssn }),
      isWellness,
      code,
      discount,
      serviceType,
      ...(email && { email }),
      ...(shipping && { shipping }),
      ...(greeting && { greeting }),

      newConfirmationPage,
    })
    .then(server.handleSuccess)
    .catch(server.handleError);

const updateGiftCardPayment = (
  sessionID: string,
  value: number,
  quantity: number,
  shippingMethod: ShippingMethod,
  shipping: Shipping,
  greeting: Greeting,
  ssn: string | null,
  isWellness: boolean,
  code: string,
  discount: number,
  serviceType?: Service,
  email?: string,
): Promise<BDKPUpdateResponse> => {
  return server.request
    .post(`/klarna/giftcards/payments/${sessionID}`, {
      value,
      quantity,
      shippingMethod,
      ...(shipping && { shipping }),
      ...(greeting && { greeting }),
      ...(ssn && { ssn }),
      isWellness,
      code,
      discount,
      serviceType,
      ...(email && { email }),
    })
    .then(server.handleSuccess)
    .catch(server.handleErrorObject);
};

type Service = {
  taxRate: number;
  name: string;
};

type CreateOrUpdateOrderParams = {
  sessionID: string | null;
  value: number;
  quantity: number;
  code: string;
  discount: number;
  shippingMethod: ShippingMethod;
  shipping: Shipping | null;
  greeting: Greeting | null;
  ssn: string | null;
  isWellness: boolean;
  serviceType?: Service;
  email?: string;
  newConfirmationPage?: boolean;
};

type CreateOrUpdateResponse = {
  sessionID: string;
  clientToken?: string;
  orderLines?: KPOrderLine[];
};

const createOrUpdateOrder = async ({
  sessionID,
  value,
  quantity,
  code,
  discount,
  shippingMethod,
  shipping = null,
  greeting = null,
  ssn = null,
  isWellness,
  serviceType,
  email,
  newConfirmationPage,
}: CreateOrUpdateOrderParams): Promise<CreateOrUpdateResponse> => {
  const res = sessionID
    ? await updateGiftCardPayment(
        sessionID,
        value,
        quantity,
        shippingMethod,
        shipping,
        greeting,
        ssn,
        isWellness,
        code,
        discount,
        serviceType,
        email,
      )
    : await initiateGiftCardPayment(
        value,
        quantity,
        shippingMethod,
        ssn,
        isWellness,
        code,
        discount,
        serviceType,
        shipping,
        greeting,
        email,
        newConfirmationPage,
      );

  return sessionID ? { sessionID, ...res } : (res as BDKPInitiationSuccessResponse);
};

const isObjectFromHittaIntegration = (hitta) =>
  ['utm_medium', 'Firstname', 'Lastname', 'Address', 'Zip', 'Town']
    .map((key) => Object.prototype.hasOwnProperty.call(hitta, key))
    .every((exists) => exists);

/**
 *
 * @param {HittaQueryParameters} hitta
 * @param {HittaStateSetters} setters
 */
const populateFromHittaObject = (hitta, setters) => () => {
  if (!isObjectFromHittaIntegration(hitta)) return;
  const values = {
    shipping: {
      name: `${hitta.Firstname} ${hitta.Lastname}`,
      address: hitta.Address,
      zipcode: hitta.Zip,
      city: hitta.Town,
    },
    greeting: {
      title: hitta.Firstname,
    },
    shippingMethod: hitta.utm_medium === 'integration',
    renderingKlarna: false,
  };

  Object.keys(values).forEach((key) => {
    if (setters[key]) setters[key](values[key]);
  });
  window.setTimeout(
    () =>
      document.getElementById('selectValueCard').scrollIntoView({
        behavior: 'auto',
        block: 'center',
        inline: 'center',
      }),
    500,
  );
};

const checkDiscountCode = (code: string, orderTotal: number): Promise<CheckDiscountCodeResponse> => {
  return server.request
    .post('/giftcards/discount', { code, orderTotal })
    .then(server.handleSuccess)
    .catch(server.handleError);
};

/**
 *
 * @param {string} klarnaId
 * @returns {object}
 */
const confirmGiftCardOrder = (klarnaID, sessionID) =>
  server.request
    .post('klarna/giftcards/confirmation', { klarnaID, sessionID })
    .then(server.handleSuccess)
    .catch(server.handleError);

/**
 * @param {string} klarnaId
 * @returns {array} An array where [0] is data returned from request, undefined until success, [1] is whether the request is still loading (true until response regardless of success), [2] is whether the request failed, false until error.
 */
const useConfirmGiftCardOrder = (klarnaId, sessionID) => {
  const [data, setData] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    const confirmOrder = async () => {
      setLoading(true);
      try {
        const order = await confirmGiftCardOrder(klarnaId, sessionID);
        setLoading(false);
        setData(order);
      } catch (ex) {
        setError(true);
        setLoading(false);
      }
    };
    confirmOrder();
  }, [klarnaId]);
  return [data, loading, error];
};

const getGiftCardOrderLines = ({
  quantity,
  value,
  discount,
  isWellness,
}: {
  quantity: number;
  value: number;
  isWellness: boolean;
  discount: number;
}): KPOrderLine[] => {
  const refType = isWellness ? 'UGW' : 'UG';
  const unitPrice = Math.round(value * 100);
  return [
    {
      type: 'gift_card',
      reference: `${refType}_${unitPrice}`,
      name: 'Gift Card',
      quantity: quantity,
      unit_price: unitPrice,
      tax_rate: 0,
      total_amount: unitPrice * quantity,
      total_tax_amount: 0,
    },
    ...(discount > 0
      ? [
          {
            type: 'discount' as 'discount',
            name: _s('Discount Code'),
            unit_price: -discount,
            reference: `${refType}_${unitPrice}_discount${discount}`,
            quantity: 1,
            total_amount: -discount,
            tax_rate: 0,
            total_tax_amount: 0,
          },
        ]
      : []),
  ];
};

const createGiftCardOrder = (sessionID: string, authorizationToken: string) =>
  createKlarnaOrder(sessionID, authorizationToken);

const useGiftCardData = (
  defaultValue: number,
  minAmtDigital: number,
  minAmtShipping: number,
  isWellness: boolean,
  isFromHitta: boolean,
  isConfirmPage: boolean,
  css: any,
) => {
  const [ssn, setSSN] = useState(null);
  const [discount, setDiscount] = useState(0);
  const [code, setCode] = useState('');
  const [value, setValue] = useState(defaultValue);
  const [quantity, setQuantity] = useState(1);
  const [sessionID, setSessionID] = useState(undefined);
  const [clientToken, setClientToken] = useState(undefined);
  const [orderLines, setOrderLines] = useState<KPOrderLine[]>([]);
  const [paymentMethodCategories, setPaymentMethodCategories] = useState<KPPaymentMethodCategory[]>([]);
  const [renderingKlarna, setRenderingKlarna] = useState(false);
  const [shipping, setShipping] = useState({});
  const [greeting, setGreeting] = useState({});
  const [shouldValidate, setShouldValidate] = useState(false);
  const [showPaymentScreen, setShowPaymentScreen] = useState(false);
  const [shippingMethod, setShippingMethod] = useRadioButtonGroup(ShippingMethod.DIGITAL);
  const minValue = shippingMethod === ShippingMethod.DIGITAL ? minAmtDigital : minAmtShipping;

  const gcGet = {
    ssn,
    discount,
    code,
    value,
    quantity,
    sessionID,
    clientToken,
    renderingKlarna,
    shipping,
    greeting,
    shouldValidate,
    shippingMethod,
    showPaymentScreen,
    minValue,
    isWellness,
    isFromHitta,
    isConfirmPage,
    css,
    orderLines,
    paymentMethodCategories,
  };

  const gcSet = {
    ssn: setSSN,
    discount: setDiscount,
    code: setCode,
    value: setValue,
    quantity: setQuantity,
    sessionID: setSessionID,
    clientToken: setClientToken,
    renderingKlarna: setRenderingKlarna,
    shipping: setShipping,
    greeting: setGreeting,
    shouldValidate: setShouldValidate,
    shippingMethod: setShippingMethod,
    showPaymentScreen: setShowPaymentScreen,
    orderLines: setOrderLines,
    paymentMethodCategories: setPaymentMethodCategories,
  };

  return [gcGet, gcSet];
};

export const orderSchema = z.object({
  email: z.string(),
  price: z.number(),
  quantity: z.number(),
  paymentMethod: z.union([z.literal(1), z.literal(3), z.literal(4), z.literal(6), z.literal(7), z.literal(8)]),
  deliveryType: z.union([z.literal('digital'), z.literal('physical')]),
  status: z.union([z.literal(1), z.literal(2), z.literal(3)]),
  productType: z.union([z.literal(1), z.literal(2)]),
  discount: z
    .object({
      code: z.string(),
      amount: z.number(),
    })
    .optional(),
});

export type Order = z.infer<typeof orderSchema>;

export function saveGiftcard(token: string) {
  return server.request
    .get(`/giftcards/save?token=${token}`)
    .then(server.handleSuccess)
    .catch(server.handleErrorObject);
}

export function getGiftcardCode(token: string) {
  return server.request
    .get(`/giftcards/token?token=${token}`)
    .then(server.handleSuccess)
    .catch(server.handleErrorObject);
}

export const placeGiftcardOrderSchema = z.object({
  email: z.string(),
  price: z.number(),
  quantity: z.number(),
});

export type PlaceGiftcardOrder = z.infer<typeof placeGiftcardOrderSchema>;

export const giftCardServices = {
  useGiftCardData,
  initiateGiftCardPayment,
  updateGiftCardPayment,
  checkDiscountCode,
  createGiftCardOrder,
  confirmGiftCardOrder,
  getGiftCardOrderLines,
  useConfirmGiftCardOrder,
  createOrUpdateOrder,
  populateFromHittaObject,
  isObjectFromHittaIntegration,
};
