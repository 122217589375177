import { _s } from '@/locale';
import { LPReviewedByField } from '@/types/landingPages';

export const LastUpdated = ({ date }: { date: string }) => (
  <InfoField title={_s('landingPages.lastUpdated')} desc={date} />
);

export const ReviewedBy = ({ reviewedBy }: LPReviewedByField) => (
  <InfoField title={_s('landingPages.reviewedBy')} desc={reviewedBy} />
);

const InfoField = ({ title, desc }: { title: string; desc: string }) => (
  <div>
    <div className="text-black-900">{title}</div>
    <div>{desc}</div>
  </div>
);
