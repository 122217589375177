export function getImageSize(src, size, seo = '') {
  return getImage(src, size, 'resize', seo);
}

export function getImagePreview(src, size, seo = '') {
  return getImage(src, size, 'preview', seo);
}

function getImage(src, size, mode = 'preview', seo = '') {
  if (!size) return src;
  if (src && src.indexOf('ucdn') !== -1) {
    if (src.indexOf('-/preview/200x200/') !== -1) {
      src = src.replace('-/preview/200x200/', `-/quality/better/-/${mode}/${size}/${seo}`);
    } else {
      src += `-/quality/better/-/${mode}/${size}/${seo}`;
    }
  }
  return src;
}
