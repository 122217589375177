import { promiseWrapper } from '@/helpers';
import * as associationPagesServices from '@/services/associationPagesServices';
import { Association, associationSchema } from '@/types/api/services/associationPages';
import { captureException } from '@sentry/react';
import { Dispatch, useEffect, useState } from 'react';

export const useAssociationPage = (ssrPage: Association, slug: string) => {
  const [page, setPage] = useState<Association>(ssrPage ?? null);
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    if (ssrPage) return;
    fetchPage(slug, setError, setPage);
  }, []);

  if (ssrPage)
    return {
      page: ssrPage,
      loading: false,
      error: false,
    };

  return { page, error };
};

const fetchPage = async (slug: string, setError: Dispatch<any>, setPage: Dispatch<Association>) => {
  const { data, error } = await promiseWrapper(associationPagesServices.getAssociation(slug));

  const validated = associationSchema.safeParse(data);

  if (!validated.success) {
    captureException('fetch association page error ', error ?? validated.error);
    return setError(true);
  }

  setPage(validated.data);
};
