import { capitalizeFirstLetter, isServer, restoreBodyScroll, saveBodyScroll } from '@/helpers';
import useMobileView from '@/hooks/useMobileView';
import { __ } from '@/locale';
import React, { useEffect, useState } from 'react';
import Modal from './redesign/Modal/Modal';

type ReadMoreModalProps = {
  modalProps?: {
    title?: string;
    info?: React.ReactNode;
    button?: React.ReactNode;
    actionText?: string;
    isOpen?: boolean;
    handleOnClose?: Function;
    handleOnOpen?: Function;
  };
  className?: string;
};

const ReadMoreModal = ({ modalProps = {}, className = '' }: ReadMoreModalProps) => {
  const { isOpen, actionText = __('moreInfo'), handleOnClose, handleOnOpen } = modalProps;
  const [showModal, setShowModal] = useState(isOpen ?? false);
  const { isMobileView } = useMobileView();
  const styleClass = `cursor-pointer text-link ${className}`;

  useEffect(() => {
    setShowModal(isOpen);
  }, [isOpen]);

  const handleCloseModal = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setShowModal(false);
    if (handleOnClose) {
      handleOnClose();
    }
    restoreBodyScroll();
  };

  const handleShowModal = (e) => {
    e.preventDefault();
    e.stopPropagation();
    saveBodyScroll();
    setShowModal(!showModal);
    if (handleOnOpen) {
      handleOnOpen();
    }
  };

  const handleClickModal = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const getModal = () => {
    const { title = '', info = '', button } = modalProps;

    return !isServer ? (
      <Modal isOpen={showModal} onRequestClose={handleCloseModal}>
        <Modal.Content floating={!isMobileView}>
          <div onClick={handleClickModal}>
            <Modal.Header onClose={handleCloseModal} title={capitalizeFirstLetter(title)} />
            <div className="container py-4">
              <div className="mb-8 whitespace-pre-wrap">{info}</div>
              {button && <div className=" relative items-center justify-center sm:flex">{button}</div>}
            </div>
          </div>
        </Modal.Content>
      </Modal>
    ) : null;
  };

  return (
    <>
      <span className={styleClass} onClick={handleShowModal}>
        {actionText}
        {showModal && modalProps && getModal()}
      </span>
    </>
  );
};

export default ReadMoreModal;
