import Countdown from '@/components/elements/Countdown/Countdown';
import '@/styles/modules/banner.scss';
import Markdown from 'markdown-to-jsx';
import { useEffect, useState } from 'react';
import { BannerLinkWrapper, getBannerStyles, markdownOverrides } from '../helpers';
import { BannerConfig } from '../types';

type BannerType3Props = Omit<BannerConfig, 'type' | 'active'> & {
  position?: string; // only used for analytics
};

const markdownStyles: React.CSSProperties = {
  fontSize: 'clamp(8px, 4vw, 16px)',
  maxWidth: '100%',
  whiteSpace: 'nowrap',
};

const BannerType3 = ({
  message,
  cta,
  background_color,
  text_color,
  countdownData,
  text_link_color,
  background_image_lg,
  background_image_md,
  background_image_sm,
  background_alt_text,
  position,
}: BannerType3Props) => {
  const [isCountdownActive, setIsCountdownActive] = useState(true);
  const hasBackgroundImage = background_image_sm && background_image_md && background_image_lg;
  const styles = { ...getBannerStyles(background_color, text_color) } as React.CSSProperties;

  useEffect(() => setIsCountdownActive(true), [countdownData?.endDate]);

  if (!isCountdownActive) return null;

  return (
    <BannerLinkWrapper cta={cta} type="type_3" position={position}>
      <div className="p-md relative text-center" style={styles}>
        {hasBackgroundImage && (
          <div className="absolute inset-0">
            <picture>
              <source media="(max-width: 340px)" srcSet={background_image_sm} />
              <source media="(max-width: 768px)" srcSet={background_image_md} />
              <source media="(min-width: 769px)" srcSet={background_image_lg} />
              <img
                className="h-full max-h-full w-full object-cover"
                src={background_image_sm}
                alt={background_alt_text ?? ''}
              />
            </picture>
          </div>
        )}
        <div
          className="Banner Banner__markdown pointer-events-none relative z-10 text-center"
          style={countdownData?.endDate ? markdownStyles : {}}>
          <Markdown options={{ overrides: markdownOverrides(text_link_color) }}>{message}</Markdown>
        </div>
        {countdownData?.endDate && (
          <Countdown
            type="small"
            endDate={countdownData.endDate}
            countdownColor={countdownData.countdown_color}
            labelColor={countdownData.countdown_description_color}
            handleCountdownComplete={() => setIsCountdownActive(false)}
          />
        )}
      </div>
    </BannerLinkWrapper>
  );
};

export default BannerType3;
