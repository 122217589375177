import { modalConstants } from '../constants';

export function modal(state = {}, action) {
  switch (action.type) {
    case modalConstants.REDIRECT:
      return {
        redirect: action.data,
      };
    case modalConstants.LOGIN:
      return {
        ...state,
        login: action.data,
      };
    case modalConstants.USER_PROFILE_ONBOARDING:
      return {
        ...state,
        userProfileOnboarding: action.data,
      };
    case modalConstants.USER_LOCATION_ONBOARDING:
      return {
        ...state,
        userLocationOnboarding: action.data,
      };
    case modalConstants.NPS:
      return {
        ...state,
        nps: action.data,
      };
    case modalConstants.HIDE:
      return {};
    default:
      return state;
  }
}
