import Icon from '@/components/icons/Icon';
import { classnames } from '@/helpers';
import { _s } from '@/locale';
import { getSvgPath } from 'figma-squircle';
import { useEffect, useRef, useState } from 'react';
import ResizeObserver from 'resize-observer-polyfill';
import { v4 as uuidv4 } from 'uuid';

type CardProps = {
  children?: React.ReactNode;
  bottom?: boolean;
  closable?: boolean;
  className?: string;
};

const getCardStyle = ({ width, height, bottom }: { width: number; height: number; bottom: boolean }) => {
  const path = getSvgPath({
    width,
    height,
    ...(bottom ? { topLeftCornerRadius: 18, topRightCornerRadius: 18 } : { cornerRadius: 18 }),
    cornerSmoothing: 1,
  });
  return { width, height, clipPath: `path('${path}')` };
};

const Card = ({ children, bottom, className, closable = true }: CardProps) => {
  const cardRef = useRef<HTMLDivElement>(null);
  const [cardStyle, setCardStyle] = useState<React.CSSProperties>({});
  const [minimized, setMinimized] = useState(false);
  const contentId = useRef<string>(uuidv4());

  const handleInitialResize = () => {
    if (!cardRef.current) return;
    const { width, height } = cardRef.current.getBoundingClientRect();
    setCardStyle(getCardStyle({ width, height, bottom }));
  };

  useEffect(handleInitialResize, [bottom, cardRef, minimized]);

  // Handle resize of browser window
  useEffect(() => {
    let isMounted = true;
    const parentElement = cardRef.current.parentNode as HTMLElement;

    const resizeObserver = new ResizeObserver((entries) => {
      window.requestAnimationFrame(() => {
        if (!Array.isArray(entries) || !entries.length) {
          return;
        }
        if (isMounted) {
          entries.forEach((entry) => {
            setCardStyle(getCardStyle({ width: entry.contentRect.width, height: entry.contentRect.height, bottom }));
          });
        }
      });
    });
    if (parentElement) {
      resizeObserver.observe(parentElement);
    }

    return () => {
      if (parentElement) {
        resizeObserver.unobserve(parentElement);
      }
      isMounted = false;
    };
  }, [bottom]);

  const handleClick = () => {
    setMinimized(!minimized);
  };

  return (
    <div className={classnames('w-full drop-shadow-sm', className ?? '')}>
      <div
        ref={cardRef}
        style={{ ...cardStyle, ...(minimized ? { height: 60 } : { height: 'auto' }) }}
        className={classnames('bg-white', !bottom && 'pt-lg')}>
        {bottom && closable && (
          <button
            onClick={handleClick}
            className="p-lg m-auto flex w-full justify-center"
            aria-expanded={!minimized}
            aria-controls={contentId.current}
            aria-label={_s('close')}>
            {!minimized && <Icon size="sm" variant="chevron-down"></Icon>}
            {minimized && <Icon size="sm" variant="chevron-up"></Icon>}
          </button>
        )}
        <div
          id={contentId.current}
          hidden={minimized}
          className={`px-lg flex w-full flex-col justify-center ${closable ? 'pb-lg' : 'py-lg'}`}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default Card;
