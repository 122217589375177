import Header from '@/components/elements/Header/Header';
import RadioButton from '@/components/elements/forms/redesign/RadioButton/RadioButton';
import { ListItem, TableListItemContent } from '@/components/elements/lists';
import Icon from '@/components/icons/Icon';
import CardWrapper from '@/components/modules/CardWrapper';
import { useGiftcardCheckoutFormData } from '@/hooks/useGiftcardCheckoutFormData';
import { _s } from '@/locale';

const baseTranslationPath = 'components.modules.checkout.giftcards.SelectType';

type Props = {
  onClick: () => void;
};

const SelectType = ({ onClick }: Props) => {
  const { register, setValue, formdata } = useGiftcardCheckoutFormData();
  return (
    <CardWrapper className="w-full">
      <div className="pb-md">
        <div className="py-md px-lg">
          <Header
            label={_s(`${baseTranslationPath}.title`)}
            size="md"
            h="h2"
            rightIcon={
              <button type="button" className="flex outline-none" onClick={onClick}>
                <Icon color="information-700" variant="info-circle"></Icon>
              </button>
            }></Header>
        </div>
        <div className={`${formdata.type === 'digital' ? 'bg-black-50' : ''} pl-lg`}>
          <ListItem
            leftPadding={false}
            leftSlot={<RadioButton checked={formdata.type === 'digital'} />}
            onClick={() => setValue('type', 'digital')}
            underline>
            <TableListItemContent
              title={{ text: _s(`${baseTranslationPath}.digital.title`) }}
              subTitle={{ text: _s(`${baseTranslationPath}.digital.subTitle`) }}
              value={{ text: _s(`${baseTranslationPath}.digital.valueLabel`) }}
            />
          </ListItem>
        </div>
        <div className={`${formdata.type === 'physical' ? 'bg-black-50' : ''} pl-lg`}>
          <ListItem
            leftPadding={false}
            leftSlot={<RadioButton checked={formdata.type === 'physical'} />}
            onClick={() => setValue('type', 'physical')}
            underline>
            <TableListItemContent
              title={{ text: _s(`${baseTranslationPath}.physical.title`) }}
              subTitle={{ text: _s(`${baseTranslationPath}.physical.subTitle`) }}
              value={{ text: _s(`${baseTranslationPath}.physical.valueLabel`) }}
            />
          </ListItem>
        </div>
      </div>
      <input type="hidden" {...register('type')} value={formdata.type} />
    </CardWrapper>
  );
};

export default SelectType;
