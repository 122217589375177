import { Fab, LinkButton } from '@/components/elements/forms/buttons';
import Icon from '@/components/icons/Icon';
import { isServer, restoreBodyScroll } from '@/helpers';
import { __ } from '@/locale';
import { getScreenName, handleLoginClick } from '@/services/navigationServices';
import { NavItemIdentifier } from '@/types/navigation';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { ModalContent, ModalDialog } from '../../modals';

class LoginTeaser extends React.Component {
  handleClickModal = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  handleCloseModal = (e) => {
    e.stopPropagation();
    e.preventDefault();
    this.props.showLoginTeaserModal(false, () => {
      restoreBodyScroll();
    });
  };

  getModal() {
    return !isServer ? (
      <ModalDialog
        isOpen={this.props.showModal}
        appElement={document.getElementById('root')}
        onRequestClose={this.handleCloseModal}>
        <ModalContent size="md" className="p-6">
          <div onClick={this.handleClickModal} className="flex flex-col items-center justify-center">
            <div className="absolute right-0 top-0 flex w-full justify-end">
              <span className="mr-3 mt-3">
                <Fab icon={<Icon variant="close" />} onClick={this.handleCloseModal} size="md" variant="link" />
              </span>
            </div>
            <img src="/images/user-login.png" alt="" className="mb-4" />
            <h3 className="mb-4 text-center text-2xl">{__('loginTeaser.loginToCompleteBooking')}</h3>
            <p className="text-black-600 text-center text-sm">{__('loginTeaser.bookQuickly')}</p>
            <div className="px-6 py-6">
              <LinkButton
                variant="primary"
                block="false"
                size="lg"
                title={__('loginTeaser.loginAndBook')}
                onClick={(e) => {
                  handleLoginClick(this.props.dispatch, NavItemIdentifier.LogIn, getScreenName());
                  this.handleCloseModal(e);
                }}>
                {__('loginTeaser.loginAndBook')}
              </LinkButton>
            </div>
            <p className="text-black-400 text-m text-center">{__('loginTeaser.pstCreateAccount')}</p>
          </div>
        </ModalContent>
      </ModalDialog>
    ) : null;
  }

  render() {
    return this.getModal();
  }
}

function mapStateToProps(state) {
  const { user } = state.users;

  return {
    user,
  };
}

const connectedLogin = withRouter(connect(mapStateToProps)(LoginTeaser));
export { connectedLogin as LoginTeaser };
