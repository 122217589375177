import { bookConstants } from '@/constants';
import { capitalizeFirstLetter } from '@/helpers';
import { __ } from '@/locale';
import Parent from './Parent';

class TextAreaInput extends Parent {
  constructor(props) {
    super(props);
    this.state = { changed: false, notesTrackingSent: false };
  }

  handleChange = (e) => {
    this.props.changeInput(e);
    this.setState({ changed: true });
  };

  handleBlur = (e) => {
    this.setState({ changed: true });
  };

  handleKeyPress = (e) => {
    const { data, notesOnKeyPress } = this.props;
    if (data.id === bookConstants.BOOKING_NOTES_FIELD && !this.state.notesTrackingSent && notesOnKeyPress) {
      notesOnKeyPress();
      this.setState({ notesTrackingSent: true });
    }
  };

  render() {
    const { data, submitted } = this.props;
    if (!data.visible) {
      return null;
    }

    const hasError = (submitted || this.state.changed || data.value) && this.props.getError(data.id);
    const placeholder = data.label ? data.label : __(capitalizeFirstLetter(data.id));

    return (
      <div className={'form-group field-' + data.id + (hasError ? ' has-error' : '')}>
        <div className="field">
          <label htmlFor={data.id}>{`${data.required ? '* ' : ''}${capitalizeFirstLetter(placeholder)}`}</label>
          <textarea
            rows="3"
            name={data.id}
            className={`form-control note field-${data.id} ${hasError ? 'border-danger focus:border-danger' : ''}`}
            value={data.value || ''}
            onChange={this.handleChange}
            placeholder={`${__('Enter')} ${placeholder}`}
            onKeyPress={this.handleKeyPress}
            onBlur={this.handleBlur}
          />
          {hasError && <div className="text-danger text-m">{__(hasError)}</div>}
        </div>
      </div>
    );
  }
}

export default TextAreaInput;
