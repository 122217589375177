const Badge = ({ label }: { label?: string }) => {
  const badgeSize = label ? 16 : 10;
  return (
    <span
      className="bg-danger-700 flex items-center justify-center rounded-full text-[10px] text-white"
      style={{ width: badgeSize, height: badgeSize }}>
      {label}
    </span>
  );
};

export default Badge;
