import PageViewLayout from '@/components/layouts/PageViewLayout/PageViewLayout';
import SearchNoticeContent from '@/components/modules/pages/articles/SearchNoticeContent';
import SEO, { seoPropsFromBaseString } from '@/components/modules/SEO';
import { trackMpEvent, trackPage, url } from '@/helpers';
import { _s } from '@/locale';
import { useEffect } from 'react';

const SearchNotice = () => {
  useEffect(() => {
    trackPage();
    // trackMpEvent('screen_shown', { screen_name: 'search_notice' });
  }, []);

  return (
    <PageViewLayout type="subView" back backSrc="/" title={_s('searchNoticeTitle')}>
      <div className="container py-4">
        <SearchNoticeContent />
      </div>
      <SEO {...seoPropsFromBaseString('searchNotice', url.getBaseUrl() + 'articles/search-notice')} />
    </PageViewLayout>
  );
};

export default SearchNotice;
