import { bookActions } from '@/actions';
import { capitalizeFirstLetter, isSistaminuten } from '@/helpers';
import { __ } from '@/locale';
import { connect } from 'react-redux';
import Parent from './Parent';

class GiftCard extends Parent {
  constructor(props) {
    super(props);
    this.state = { value: '' };
  }

  applyGiftCard = (e) => {
    if (this.state.value) {
      this.props.applyGiftCard(this.state.value.trim());
    } else {
      this.setState({ errorEmpty: true });
    }
  };

  onChange = (e) => {
    const { value } = e.target;
    this.setState({ value: value, errorEmpty: undefined });
    this.props.dispatch(bookActions.clearGiftCardMessages());
    this.props.changeInput(e);
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { dispatch, successGiftCard } = this.props;
    if (!prevProps.successGiftCard && successGiftCard) {
      this.setState({ value: '' });
      setTimeout(() => {
        dispatch(bookActions.clearGiftCardMessages());
      }, 3000);
    }
  }

  getMessage() {
    const { manualErrorGiftcard, errorGiftCard, successGiftCard, isDiscount } = this.props;
    let type = '',
      text = '',
      className = '';

    if (this.state.errorEmpty) {
      className = 'text-danger text-m';
      type = 'has-error';
      text = __(isDiscount ? 'genericErrorGiftcardDiscount' : 'genericErrorGiftcard');
    }

    if (errorGiftCard) {
      className = 'text-danger text-m';
      type = 'error';
      text = errorGiftCard;
    }

    if (successGiftCard) {
      type = '';
      className = 'text-primary text-m';
      text = __('successGiftcard');
    }

    if (manualErrorGiftcard) {
      type = 'error';
      className = 'text-danger text-m';
      text = __(manualErrorGiftcard);
    }

    return { type: type, text: text, className: className };
  }

  render() {
    const { data } = this.props;
    if (!data.visible) {
      return null;
    }
    const placeholder = data.label ? data.label : 'Bokadirekt ' + __(capitalizeFirstLetter(data.id)).toLowerCase();

    const type = data.visible ? 'text' : 'hidden';
    const message = this.getMessage();

    return (
      <div className={'form-group field-' + data.id + ' ' + message.type}>
        <label htmlFor={data.id}>{`${data.required ? '* ' : ''}${placeholder}`}</label>
        <div className="field" style={{ position: 'relative' }}>
          <div
            className={`${
              isSistaminuten() ? 'bg-sm_primary-100 !text-[#333]' : 'bg-primary text-white'
            }  absolute right-[1px] top-[1px] block cursor-pointer rounded-br-sm rounded-tr-sm px-3 text-left text-sm leading-[36px] sm:px-4 `}
            onClick={this.applyGiftCard}>
            {__('add')}
          </div>
          <input
            type={type}
            name={data.id}
            className={'form-control field-' + data.id}
            style={{ paddingRight: '80px' }}
            onChange={this.onChange}
            value={this.state.value}
          />
          <div className={'text-m ' + message.className}>{message.text}</div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { errorGiftCard, successGiftCard } = state.book;

  return {
    errorGiftCard,
    successGiftCard,
  };
}

export default connect(mapStateToProps)(GiftCard);
