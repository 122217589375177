import { PlaceCard } from '.';

const PlaceCardContainer = (props) => {
  const {
    place,
    source,
    SerpPageNo,
    SerpIdx,
    onClickPlace,
    fireHover,
    fireHoverOut,
    myRef,
    removeAction,
    hasLoginForLastVisited,
    searchParams,
    onBookClick,
  } = props;

  return (
    <PlaceCard
      place={place}
      source={source}
      SerpPageNo={SerpPageNo}
      SerpIdx={SerpIdx}
      onClickPlace={onClickPlace}
      fireHover={fireHover}
      fireHoverOut={fireHoverOut}
      removeAction={removeAction}
      myRef={myRef}
      hasLoginForLastVisited={hasLoginForLastVisited}
      searchParams={searchParams}
      onBookClick={onBookClick}
    />
  );
};

export default PlaceCardContainer;
