import { isServer, trackMpEvent, trackPage } from '@/helpers';
import { useEffect } from 'react';
import { NewsletterBanner } from '../modules/pages/blog';
import PageViewLayout from './PageViewLayout/PageViewLayout';

type LayoutProps = { children?: React.ReactNode };

type TrackingData = {
  screen_name: string;
  author?: string;
  category?: string;
  post?: string;
};

const trim = (url: string) => (url.endsWith('/') ? url.slice(0, -1) : url);

const getTrackingDataForType = (url: string, separator: string, key: string, screenName): TrackingData => ({
  screen_name: screenName,
  [key]: trim(url.split(separator).pop()),
});

const getTrackingData = (url: string): TrackingData => {
  if (url.includes('/c/')) return getTrackingDataForType(url, '/c/', 'category', 'blog_category');
  if (url.includes('/a/')) return getTrackingDataForType(url, '/a/', 'author', 'blog_author');
  if (url.includes('/p/')) return getTrackingDataForType(url, '/p/', 'post', 'blog_post');
  return { screen_name: 'blog' };
};

const trackVisit = () => {
  if (isServer) return;

  // const url = window.location.href;
  // const trackingData = getTrackingData(url);
  // trackMpEvent('screen_shown', trackingData);
  trackPage();
};

export const BlogLayout = ({ children }: LayoutProps) => {
  useEffect(trackVisit, []);

  return (
    <>
      <PageViewLayout type="mainView">
        {children}
        <NewsletterBanner />
      </PageViewLayout>
      {!isServer &&
        (function (src, cb) {
          var s = document.createElement('script');
          s.setAttribute('src', src);
          s.onload = cb;
          (document.head || document.body).appendChild(s);
        })('https://ucarecdn.com/libs/blinkloader/3.x/blinkloader.min.js', function () {
          (window as any).Blinkloader.optimize({
            pubkey: '2396acc5f7f7db6b1764',
            fadeIn: true,
            lazyload: true,
            smartCompression: true,
            responsive: true,
            retina: true,
            webp: true,
          });
        })}
    </>
  );
};

export const ArchiveLayout = ({ children = null }: LayoutProps) => (
  <div className="-mx-5 flex flex-wrap justify-start">{children}</div>
);
