import colors from '@/colors';
import { classnames } from '@/helpers';
import '@/styles/modules/banner.scss';
import Markdown from 'markdown-to-jsx';
import { Button } from '../../forms/buttons';
import { BannerLinkWrapper, getBannerStyles, markdownOverrides } from '../helpers';
import { BannerConfig } from '../types';

type BannerSize = 'sm' | 'md' | 'lg';

const BANNER_BUTTON_SIZE = {
  sm: 'sm',
  md: 'sm',
  lg: 'lg',
} as const;

const BANNER_HEIGHT = {
  sm: 190,
  md: 190,
  lg: 300,
} as const;

const BANNER_BODY_HEIGHT = {
  sm: 100,
  md: 100,
  lg: 150,
} as const;

type BannerType1Props = {
  size: BannerSize;
  position?: string; // only used for analytics
  inContainer?: boolean; // if true, will have rounded corners
} & Omit<BannerConfig, 'type' | 'active'>;

const BannerType1 = ({
  size,
  message,
  background_color,
  text_color,
  text_link_color = colors.information[700],
  cta,
  image_url_xs,
  image_url,
  image_url_large,
  background_image_lg,
  background_image_md,
  background_image_sm,
  background_alt_text,
  position,
  inContainer = size === 'lg',
}: BannerType1Props) => {
  const hasBackgroundImage = background_image_sm && background_image_md && background_image_lg;

  const styles = {
    ...getBannerStyles(background_color, text_color),
    height: BANNER_HEIGHT[size],
  } as React.CSSProperties;

  return (
    <BannerLinkWrapper cta={cta} type="type_1" position={position}>
      <div
        className={classnames(
          'Banner gap-sm pointer-events-none relative flex justify-between overflow-hidden',
          inContainer && 'rounded-lg',
        )}
        style={styles}>
        {hasBackgroundImage && (
          <div className="absolute inset-0">
            <picture>
              <source media="(max-width: 340px)" srcSet={background_image_sm} />
              <source media="(max-width: 767px)" srcSet={background_image_md} />
              <source media="(min-width: 767px)" srcSet={background_image_lg} />
              <img
                className="h-full max-h-full w-full object-cover"
                src={background_image_sm}
                alt={background_alt_text ?? ''}
              />
            </picture>
          </div>
        )}
        <div className="p-lg z-10 flex flex-1 flex-col pr-0">
          <div style={{ height: BANNER_BODY_HEIGHT[size] }}>
            <div className={`Banner__markdown Banner__markdown--${size}`}>
              <Markdown options={{ overrides: markdownOverrides(text_link_color) }} key={1}>
                {message}
              </Markdown>
            </div>
          </div>
          {cta && (
            <div className="pointer-events-auto mt-auto">
              <Button variant={cta.variant || 'primary'} label={cta.title} size={BANNER_BUTTON_SIZE[size]} />
            </div>
          )}
        </div>
        {image_url && (
          <div className="Banner__image relative" style={{ height: BANNER_HEIGHT[size] }}>
            <picture>
              <source media="(max-width: 340px)" srcSet={image_url_xs} />
              <source media="(max-width: 767px)" srcSet={image_url} />
              <source media="(min-width: 767px)" srcSet={image_url_large} />
              <img className="h-full max-h-full w-full object-cover" src={image_url_xs} alt="" />
            </picture>
          </div>
        )}
      </div>
    </BannerLinkWrapper>
  );
};

export default BannerType1;
