import { ListItem } from '@/components/elements/lists';
import Icon from '@/components/icons/Icon';
import { CHECKOUT_PAYMENT_METHOD } from '@/constants/checkout';
import { getCardName, hasCardExpired } from '@/helpers';
import { getSelectedPaymentDetails } from '@/helpers/checkout';
import { _s } from '@/locale';
import { PaymentCard } from '@/types/paymentcards';
import { Fragment } from 'react';

const ManageCards = ({ cards, removeCard }: { cards: PaymentCard[]; removeCard: (card: PaymentCard) => void }) => {
  return (
    <div className="flex flex-col">
      {cards?.map((card) => {
        const isExpired = hasCardExpired(card.expiryMonth, card.expiryYear);
        const cardReferenceHasUpcomingBooking = !!card?.preventDisable;
        const { icon } = getSelectedPaymentDetails({
          type: CHECKOUT_PAYMENT_METHOD.STORED_COF,
          brand: card.brand,
          id: card.id,
          lastFour: card.lastFour,
        });

        return (
          <Fragment key={card.id}>
            <ListItem
              underline
              leftSlot={<Icon variant={icon} noFilter style={{ width: 48, height: 48 }} />}
              rightSlot={
                !cardReferenceHasUpcomingBooking && (
                  <button
                    onClick={() => removeCard(card)}
                    aria-label={_s('removeCard')}
                    className="hover:text-danger text-black-900 flex items-center justify-center border-none outline-none">
                    <Icon variant="trash" />
                  </button>
                )
              }>
              <h3 className="mb-1 font-semibold">{getCardName(card.brand)}</h3>
              <p className="relative top-[-4px] flex items-center">
                ...
                {card.lastFour}.&nbsp;
                <span className={`${isExpired ? 'text-danger' : ''}`}>
                  {`${isExpired ? _s('expired') : _s('expires')}: ${card.expiryMonth}/${card.expiryYear}`}
                </span>
              </p>
            </ListItem>
          </Fragment>
        );
      })}
    </div>
  );
};

export default ManageCards;
