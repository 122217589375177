import { BDKPInitiationResponse, BDKPInitiationSuccessResponse, BDKPUpdateResponse } from '@/types/klarnaPayments';
import { useEffect, useState } from 'react';
import { server } from '../helpers';
import { createKlarnaOrder, getKlarnaSession } from './klarnaServices';

export type VCPaymentRequest = {
  placeId: number;
  valueCard: number;
  slugId: string;
  employee: number;
};

export type ValueCard = {
  id: number;
  name: string;
  price: number;
  value: number;
  vatId?: number;
  isActive: boolean;
  isPublic: boolean;
  calendarId: number;
  description: string;
  daysOfValidity: number;
};

const initiateValueCardPayment = (paymentRequest: VCPaymentRequest): Promise<BDKPInitiationResponse> =>
  server.request
    .post(`/klarna/places/${paymentRequest.placeId}/valuecards/payments`, paymentRequest)
    .then(server.handleSuccess)
    .catch(server.handleError);

const updateValueCardPayment = (sessionID: string, paymentRequest: VCPaymentRequest): Promise<BDKPUpdateResponse> =>
  server.request
    .post(`/klarna/places/${paymentRequest.placeId}/valuecards/payments/${sessionID}`)
    .then(server.handleSuccess)
    .catch(server.handleError);

export const getValueCardData = (id: number, slug: string) => {
  const uriSlug = encodeURIComponent(slug);
  return server.request
    .get(`/places/${id}/valuecarddata?slug=${uriSlug}`)
    .then(server.handleSuccess)
    .catch(server.handleError);
};

export const initiateOrUpdateVCPayment = async (
  sessionID: string | null,
  paymentRequest: VCPaymentRequest,
): Promise<{ sessionID: string } | BDKPInitiationSuccessResponse> => {
  const res = sessionID
    ? await updateValueCardPayment(sessionID, paymentRequest)
    : await initiateValueCardPayment(paymentRequest);
  return sessionID ? { sessionID } : (res as BDKPInitiationSuccessResponse);
};

export const confirmValueCardOrder = (placeID: number, klarnaID: string) =>
  server.request
    .post(`/klarna/places/${placeID}/valuecards/confirmation`, { klarnaID })
    .then(server.handleSuccess)
    .catch(server.handleError);

export const useConfirmValueCardOrder = (placeId, klarnaId) => {
  const [data, setData] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (!klarnaId || String(klarnaId).length < 5) return;
    const confirmOrder = async () => {
      setLoading(true);
      try {
        const order = await confirmValueCardOrder(placeId, klarnaId);
        setLoading(false);
        setData(order);
      } catch (ex) {
        setLoading(false);
        setError(true);
      }
    };
    confirmOrder();
  }, [klarnaId]);

  return [data, loading, error];
};

export const getValueCardOrderLines = async (sessionID: string) => (await getKlarnaSession(sessionID)).order_lines;

export const createValueCardOrder = (sessionID: string, authorizationToken: string) =>
  createKlarnaOrder(sessionID, authorizationToken);
