import useThreeDS, { UseThreeDSProps } from '@/hooks/adyen/useThreeDS';
import '@adyen/adyen-web/dist/adyen.css';

const ThreeDSComponent = ({ action, handleOnAdditionalDetails }: UseThreeDSProps) => {
  const { componentRef } = useThreeDS({
    action,
    handleOnAdditionalDetails,
  });

  return (
    <div className="h-full">
      <div className="h-full" ref={componentRef}></div>
    </div>
  );
};

export default ThreeDSComponent;
