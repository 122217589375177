import { server } from '@/helpers';
import { SwishPaymentRequest, SwishPaymentResponse, SwishPaymentStatusResponse } from '@/types/api/services/swish';
import { config } from '../config';

export const swishServices = {
  swishPayment,
  getSwishPayment,
  waitForSwishNotification,
};

function swishPayment(body: SwishPaymentRequest): Promise<SwishPaymentResponse> {
  return server.request.post('/swish/payments', body).then(server.handleSuccess).catch(server.handleErrorObject);
}

function getSwishPayment(paymentId: string): Promise<SwishPaymentStatusResponse> {
  return server.request
    .get(`/swish/payments?paymentId=${paymentId}`)
    .then(server.handleSuccess)
    .catch(server.handleErrorObject);
}

function waitForSwishNotification(channel: SwishPaymentResponse['channel']): EventSource {
  const eventSource = new EventSource(`${config.serverUrl}swish/sse?channel=${channel}`);
  return eventSource;
}
