/* eslint-disable */

import { initTracking } from '@/App.hooks';
import Snackbar from '@/components/elements/notifications/Snackbar/Snackbar';
import PageViewLayout from '@/components/layouts/PageViewLayout/PageViewLayout';
import { CookiesContent } from '@/components/modules/pages/blog';
import { allowTracking, initAmplitudeAnalytics, isSistaminuten, setCookiePreferences, trackPage, url } from '@/helpers';
import withAmplitudeExperiment from '@/hoc/withAmplitudeExperiment';
import { AmplitudeExperimentContextType } from '@/hooks/useAmplitudeExperiment';
import { __, _s } from '@/locale';
import { userService } from '@/services';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Redirect, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

type CookiesProps = {} & AmplitudeExperimentContextType;

function Cookies({ initialize: initializeAmplitudeExperiment }: CookiesProps) {
  const [checked, setChecked] = useState(allowTracking());
  const [redirect, setRedirect] = useState<string>();
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
    trackPage();
  }, []);

  const seo = () => {
    const title = _s('seo.cookiesTitle');
    const description = __('seo.cookiesDescription');
    const pageUrl = `${url.getBaseUrl()}articles/cookies`;
    const imageUrl = url.getBaseImageUrl();
    const isNoIndex = isSistaminuten();

    return (
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <link rel="canonical" href={pageUrl} />

        <meta property="og:url" content={pageUrl} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={imageUrl} />
        {isNoIndex && <meta name="robots" content="noindex" />}
      </Helmet>
    );
  };

  const handleLabelClick = (e) => {
    const input = e.target.parentNode.querySelector('input');
    const value = input && input.checked ? false : true;
    setChecked(value);
  };

  const handleChange = (e) => {
    setChecked(Boolean(e.target.checked));
  };

  const save = async () => {
    if (!checked) {
      setCookiePreferences('Mandatory');
      // Count Up user tracking rejection
      await userService.declineTracking();
    } else {
      setCookiePreferences();
      initAmplitudeAnalytics();
      initTracking();
      initializeAmplitudeExperiment();
    }

    if (location?.state?.fromAllowCookiesPopup) {
      setRedirect(location.state.fromAllowCookiesPopup);
    } else {
      toast(({ closeToast }) => <Snackbar type="info" label={_s('savedCookieConsent')} onClose={closeToast} />);
    }
  };

  return redirect ? (
    <Redirect to={redirect} />
  ) : (
    <PageViewLayout
      type="subView"
      back
      title={_s('cookiesPage.cookiesTitle')}
      footerDisplayOption={{ from: 'sm', to: 'all' }}>
      <CookiesContent
        onClickSave={save}
        onTrackingCheckedChange={handleChange}
        onTrackingLabelClick={handleLabelClick}
        trackingChecked={checked}
      />
      {seo()}
    </PageViewLayout>
  );
}

export default withAmplitudeExperiment(Cookies);
