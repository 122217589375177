import { LoadingIcon } from '@/components/icons';
import PageViewLayout from '@/components/layouts/PageViewLayout/PageViewLayout';

import { KlarnaPaymentsPurchaseConfirmation } from '@/components/modules/klarna/KlarnaPaymentsConfirmation';
import { exportIdSlug, trackMpAnalyticsEvent, trackMpEvent, trackMpRevenueGiftcards, trackPage } from '@/helpers';
import { __ } from '@/locale';
import { useConfirmPlaceGiftCardOrder } from '@/services/pgcPaymentServices';
import queryString from 'query-string';
import { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';

const loc = 'buyPlaceGiftCardConfirmation';

const trackGiftCardPurchase = (response, klarnaId, placeId, slugId) => {
  if (response.giftCard[0]?.greeting === 'order_created') return;
  trackMpAnalyticsEvent('gift_card_purchase_success', {
    card_type: 'individual',
    amount: response.amount,
  });
  trackMpAnalyticsEvent('gc_purchase_success', {
    gc_type: 'individual',
  });
  trackMpEvent('place_gift_card_purchase', {
    klarna_order_id: klarnaId,
    place_id: placeId,
    order_value: response.amount,
  });

  trackPage();
  trackMpEvent('screen_shown', {
    screen_name: 'place_gift_card_confirmation',
  });
  trackMpRevenueGiftcards({
    value: response.amount || 0,
    currency: 'SEK',
    contentName: 'IGC',
    contentType: 'product',
    description: 'place_gift_card',
    orderId: klarnaId,
  });
};

const GiftCardsBuyPlaceConfirmation = () => {
  const location = useLocation();
  const query = location.search ? queryString.parse(location.search) : {};

  const { slugId } = useParams();
  const { id } = exportIdSlug(slugId);
  const klarnaId = query.klarna_order_id ?? '';

  const [response, loading, error] = useConfirmPlaceGiftCardOrder(id, klarnaId);
  const email = response?.giftCard ? response.giftCard?.[0]?.email : response?.confirmationEmail ?? '';

  useEffect(() => {
    if (!response || response.error || response.alreadyCreated) return;

    trackGiftCardPurchase(response, klarnaId, id, slugId);
  }, [response]);

  return (
    <PageViewLayout
      back
      backSrc={`/places/${slugId}`}
      backIcon="close-alt"
      type="subView"
      title={__('buyPlaceGiftCardConfirmation.title')}>
      <div className="container-fixed py-lg">
        {loading && <LoadingIcon className="mx-auto block h-10 w-10" />}{' '}
        {error && <p className="text-center">{__(`${loc}.error`)}</p>}
        {response && (
          <KlarnaPaymentsPurchaseConfirmation
            email={email}
            tax={response.tax}
            paymentMethod={response.paymentMethod}
            orderLines={response.orderLines}
            totalAmount={response.totalAmount}
            ctaButtonText={__(`${loc}.ctaButtonText`)}
            ctaButtonLink={`/places/${slugId}/giftcards/buy`}
          />
        )}
      </div>
    </PageViewLayout>
  );
};

export default GiftCardsBuyPlaceConfirmation;
