import { z } from 'zod';

export const USER_GENDERS = ['man', 'woman', 'nonbinary', 'unsure', 'reject', 'other'] as const;

const userGenderSchema = z.enum([...USER_GENDERS, '']);

export const userAboutEditableFields = z.object({
  givenName: z.string().optional(),
  familyName: z.string().optional(),
  gender: userGenderSchema.optional(),
  dob: z.string().optional(),
  accountProtected: z.boolean().optional(),
});

export type UserAboutEditableFields = z.infer<typeof userAboutEditableFields>;

export const userAboutSchema = z.object({
  ...userAboutEditableFields.shape,
  name: z.string().optional(),
  recentPlaces: z.array(z.number()).optional(),
  signUpDate: z.string().optional(),
  loginMethod: z.any(),
  isMerchant: z.boolean().optional(),
});

export const userContactEditableFields = z.object({
  postalCode: z.string().optional(),
  locality: z.string().optional(),
  streetAddress: z.string().optional(),
  phone: z.string().optional(),
  mobile: z.string().optional(),
});

export type UserContactEditableFields = z.infer<typeof userContactEditableFields>;

export const userContactSchema = z.object({
  ...userContactEditableFields.shape,
  email: z.string(),
  confirmed: z.boolean(),
});

export const userSchema = z.object({
  about: userAboutSchema,
  contact: userContactSchema,
  id: z.number(),
  emailHash: z.string(),
  token: z.string(),
  favorites: z.array(z.number()).optional(),
  activeBookings: z.array(z.number()).optional(),
  clientPlaceBookings: z.array(z.number()).optional(),
  activeCoF: z.boolean(),
  clientBooker: z.string().optional(),
});

export type User = z.infer<typeof userSchema>;
