export const Z_INDEX = {
  MAP: 10,
  FAB_PORTAL: 20,
  NAVBAR: 100,
  NAVIGATION_DRAWER: 102,
  NAVIGATION_DRAWER_OVERLAY: 101,
  BOTTOM_TAB_BAR: 103,
  OLD_MODAL: 10000,
  MODAL: 10001,
  SNACKBAR: 10002,
  APP_PROMOTION: 10003,
} as const;

export const DEFAULT_NAVBAR_HEIGHT = 64;
