import AppBanner from '@/components/modules/pages/sidor/AppBanner';
import CTABox from '@/components/modules/pages/sidor/CTABox';
import CTAButton from '@/components/modules/pages/sidor/CTAButton';
import { LastUpdated, ReviewedBy } from '@/components/modules/pages/sidor/Info';
import List from '@/components/modules/pages/sidor/List';
import Wysiwyg from '@/components/modules/pages/sidor/Wysiwyg';
import { LandingPage, LandingPageField } from '@/types/landingPages';
import { ReactNode } from 'react';

const getComponentFromField =
  ({ lastUpdated, ID }: LandingPage) =>
  (field: LandingPageField) => {
    switch (field.fieldType) {
      case 'ctaButton':
        return <CTAButton {...field} landingPageID={ID} />;
      case 'wysiwyg':
        return <Wysiwyg {...field} />;
      case 'ctaBox':
        return <CTABox {...field} landingPageID={ID} />;
      case 'horizontalLine':
        return <hr className="text-black-50 inline-block w-full" />;
      case 'lastUpdated':
        return <LastUpdated date={lastUpdated} />;
      case 'reviewedBy':
        return <ReviewedBy {...field} />;
      case 'appBanner':
        return <AppBanner {...field} landingPageID={ID} />;
      case 'list':
        return <List {...field} />;
      default:
        return <></>;
    }
  };

const wrapComponent = (component: ReactNode, i: number) => <div className={i !== 0 ? 'my-6' : ''}>{component}</div>;

export const getLandingPageComponents = (landingPage: LandingPage) => {
  const { fields } = landingPage;
  const landingPageContent = fields.landingPageContent ?? [];
  const landingPageSidebar = fields.landingPageSidebar ?? [];

  return {
    main: landingPageContent && landingPageContent.map(getComponentFromField(landingPage)).map(wrapComponent),
    sidebar: landingPageContent && landingPageSidebar.map(getComponentFromField(landingPage)).map(wrapComponent),
  };
};
