import { SearchForm } from '@/components/modules/search';
import { isSistaminuten } from '@/helpers';
const SearchBar = () => (
  <>
    <div className="md:hidden">
      <div className="search">
        <div className="search-container">
          <SearchForm source="home" />
        </div>
      </div>
    </div>
    <div className="hidden md:block">
      <div className="flex justify-center">
        <div className="search w-full">
          <div className={`search-container flex justify-center ${isSistaminuten() ? 'sistaminuten' : ''}`}>
            <SearchForm source="home" />
          </div>
        </div>
      </div>
    </div>
  </>
);
export default SearchBar;
