import { getCookie, getIpLatLon, setCookie } from '.';

const cookieName = 'BokadirektLocationData';

function setLocationInCookies(params: { location?: string; locationId?: number | undefined; position?: string }) {
  setCookie(cookieName, {
    location: params?.location || undefined,
    locationId: params?.locationId || undefined,
    position: params?.position || undefined,
    time: Math.floor(Date.now() / 1000),
  });
}

function getGeoLocationFromCookies() {
  let params = getCookie(cookieName);
  if (params?.position && (Math.floor(Date.now() / 1000) - params?.time) / 60 <= 10) {
    const position = params.position.split('-');
    if (position.length === 2) {
      return position;
    }
  }
  return undefined;
}

function getGeoLocation(
  setLatLon: Function,
  trackEvent?: Function | null,
  runningState?: Function | null,
  setFallbackState?: Function | null,
  ipCallback?: Function | null,
) {
  const cookiePos = getGeoLocationFromCookies();
  if (cookiePos) {
    runningState && runningState();
    trackEvent && trackEvent(true);
    setLatLon(cookiePos[0], cookiePos[1]);

    return {
      lat: cookiePos[0],
      lon: cookiePos[1],
    };
  } else if (navigator.geolocation) {
    runningState && runningState();
    trackEvent && trackEvent(false);
    var options = {
      enableHighAccuracy: true,
      timeout: 15000,
    };
    navigator.geolocation.getCurrentPosition(
      (position) => {
        if (position) {
          const latitude = position.coords.latitude.toFixed(5);
          const longitude = position.coords.longitude.toFixed(5);
          setLatLon(latitude, longitude);
          return {
            lat: latitude,
            lon: longitude,
          };
        }
      },
      (error) => {
        if (error.code !== 1) {
          getIpLatLon()
            .then((response) => {
              ipCallback ? ipCallback(response.lat, response.lon) : setLatLon(response.lat, response.lon);
              return {
                lat: response.lat,
                lon: response.lon,
              };
            })
            .catch(() => {
              setFallbackState && setFallbackState();
            });
        } else {
          setFallbackState && setFallbackState();
        }
      },
      options,
    );
  }
}

export { getGeoLocationFromCookies, getGeoLocation, setLocationInCookies };
