import { Button, Fab } from '@/components/elements/forms/buttons';
import Icon from '@/components/icons/Icon';
import { trackMpEvent } from '@/helpers';
import { __ } from '@/locale';
import React from 'react';
import { withRouter } from 'react-router-dom';
import { ModalContent, ModalDialog } from './Modal';

class CancelBooking extends React.Component {
  constructor(props) {
    super(props);
    this.state = { showAlert: true };
  }

  compnentWillMount() {
    this.setState({ showAlert: true });
  }

  handleClose = (e) => {
    this.setState({ showAlert: false });
    if (this.props.cancel) {
      this.props.cancel();
    }
  };

  handleAbort = (e) => {
    const { place, steps, history, source, cancelTrackingProps } = this.props;
    trackMpEvent('booking_canceled', cancelTrackingProps);
    if (!source) {
      history.replace('/places/' + place.about.slug + '-' + place.id);
      return;
    }

    if (steps && parseInt(history.length + steps, 10) >= 0) {
      history.go(steps);
    } else {
      if (place && place.id) {
        history.replace('/places/' + place.about.slug + '-' + place.id); // we can also do push instead of replace
      } else {
        history.replace('/');
      }
    }
  };

  render() {
    return (
      <ModalDialog
        isOpen={this.state.showAlert}
        bodyOpenClassName="ReactModal__Prompt__Body--open"
        className="modal-dialog modal-prompt"
        overlayClassName="modal-overlay modal-prompt-overlay"
        appElement={document.getElementById('root')}
        onRequestClose={() => this.handleClose()}>
        <ModalContent className="p-4">
          <span className="absolute right-2 top-2 flex w-full justify-end">
            <Fab icon={<Icon variant="close" />} onClick={this.handleClose} size="md" variant="link" />
          </span>
          <h3 className="font-semibold">{__('cancelCurrentBooking')}</h3>
          <p className="my-3">{__('confirmCancelCurrentBooking')}</p>
          <div className="mt-5"></div>
          <div className="flex items-center justify-end gap-2 border-t-[1px] px-3 pb-3 pt-5">
            <Button variant="link" className="text-danger font-semibold" onClick={this.handleAbort}>
              {__('confirmCancel')}
            </Button>
            <Button className="px-5 py-2" onClick={this.handleClose}>
              {__('no')}
            </Button>
          </div>
        </ModalContent>
      </ModalDialog>
    );
  }
}

export default withRouter(CancelBooking);
