import PageViewLayout from '@/components/layouts/PageViewLayout/PageViewLayout';
import CardWrapper from '@/components/modules/CardWrapper';
import CheckoutDetails, { CheckoutDetailsProps } from '@/components/modules/checkout/CheckoutDetails/CheckoutDetails';
import QliroCheckout from '@/components/modules/checkout/QliroCheckout/QliroCheckout';
import GoBack from '@/components/modules/pages/bokningar/GoBack';
import { url } from '@/helpers';
import { bundleCheckoutTrackingManager, mapBundleStateToCheckoutDetailsProps } from '@/helpers/checkout';
import withPlaceDetailsFallback from '@/hoc/withPlaceDetailsFallback';
import { useAppSelector } from '@/hooks';
import useMobileView from '@/hooks/useMobileView';
import useTrackScreenView from '@/hooks/useTrackScreenView';
import { _s } from '@/locale';
import { InitQliroResponse } from '@/types/api/services/booking';
import { createPaymentResponseSchema } from '@/types/api/services/qliro';
import { BundleState, bundleStateSchema } from '@/types/state/bundle';
import { Helmet } from 'react-helmet';
import { useHistory, useLocation } from 'react-router-dom';

const baseTranslationKey = 'pages.booking.checkout.QliroBookingCheckout';

const pageUrl = `${url.getBaseUrl()}/bundles/checkout/qliro`;
const pageTitle = _s(`seo.activateOnlinePaymentQliroTitle`);
const pageDescription = _s(`seo.activateOnlinePaymentQliroDescription`);

const QliroBundleCheckout = ({ bundle, qliroOrder }: { bundle: BundleState; qliroOrder: InitQliroResponse }) => {
  const location = useLocation();
  const history = useHistory();

  const { isMobileView } = useMobileView();
  const trackingProps = bundleCheckoutTrackingManager().getTrackingProps();

  const { giftcards, payLater, ...rest } = mapBundleStateToCheckoutDetailsProps({
    bundle: bundle.bundle,
    place: bundle.place,
  });

  const checkoutDetailsProps: CheckoutDetailsProps = {
    ...rest,
    payLater,
    giftcards: giftcards.map((giftcard) => ({
      ...giftcard,
    })),
  };

  const handleOnBackButtonClick = () => {
    history.replace({
      pathname: '/bundles/checkout',
      state: { savedCheckoutState: location.state?.savedCheckoutState },
    });
  };

  useTrackScreenView({
    name: 'screen_view_checkout_bundle_qliro_summary',
    properties: { ...trackingProps.bundleProps, ...trackingProps.paymentMethodsProps },
  });

  return (
    <PageViewLayout
      type="subView"
      title={_s(`${baseTranslationKey}.page-title`)}
      back
      onBackButtonClick={handleOnBackButtonClick}
      wrapperClass={isMobileView ? 'bg-brown-50' : 'bg-gradient'}>
      <div className="bg-brown-50 lg:bg-transparent">
        <div className="lg:py-xxl lg:container">
          <div className="gap-xxl flex items-start">
            <div className="gap-lg flex grow-[9999] basis-[600px] flex-col">
              <CardWrapper className="w-full lg:hidden">
                <CheckoutDetails {...checkoutDetailsProps} />
              </CardWrapper>
              <CardWrapper>
                <div className="p-lg">
                  <QliroCheckout snippet={qliroOrder.snippet} />
                </div>
              </CardWrapper>
            </div>
            <div className="sticky top-20 hidden lg:flex lg:grow-[100] lg:basis-[390px]">
              <CardWrapper className="w-full">
                <CheckoutDetails key={2} {...checkoutDetailsProps} />
              </CardWrapper>
            </div>
          </div>
        </div>
      </div>

      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <link rel="canonical" href={pageUrl} />
        <meta property="og:url" content={pageUrl} />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        <meta name="robots" content="noindex" />
      </Helmet>
    </PageViewLayout>
  );
};

export default withPlaceDetailsFallback(() => {
  const location = useLocation();
  const bundleState = useAppSelector((state) => state.bundle);
  const parsedBookState = bundleStateSchema.safeParse(bundleState);
  const parsedQliroOrder = createPaymentResponseSchema.safeParse(location?.state?.qliroOrder);

  if (!parsedBookState.success) return <GoBack />;

  const invalidState = !parsedQliroOrder.success;

  if (invalidState) return <GoBack />;

  return <QliroBundleCheckout bundle={parsedBookState.data} qliroOrder={parsedQliroOrder.data} />;
});
