import Icon from '@/components/icons/Icon';
import { ReactNode } from 'react';

export type SnackbarType = 'info' | 'highlight' | 'danger' | 'success';

export interface ISnackbar {
  label: string;
  type?: SnackbarType;
  action?: ReactNode;
  onClose?: () => void;
}

const snackbarTypeStyle = {
  info: 'text-information-200',
  danger: 'text-danger-200',
  success: 'text-brand-200',
  highlight: 'text-warning-200',
};

const Snackbar = ({ action, label, onClose, type = 'success' }: ISnackbar) => {
  return (
    <div className="bg-black-900 p-lg gap-md text-md shadow-bottom-md text-black-50 sm:rounded-xs flex w-full flex-wrap items-center">
      <p>{label}</p>
      <div className="gap-md ml-auto flex">
        {action && <div className={snackbarTypeStyle[type]}>{action}</div>}
        {onClose && (
          <button onClick={onClose}>
            <Icon color="black-50" variant="close" />
          </button>
        )}
      </div>
    </div>
  );
};

export default Snackbar;
