import { placeConstants } from '../constants';

export const placeActions = {
  setHover,
  removeHover,
};

function setHover(placeId) {
  return { type: placeConstants.SET_HOVER, placeId };
}

function removeHover(placeId) {
  return { type: placeConstants.REMOVE_HOVER, placeId };
}
