import { baseRequestWithoutTransactionInfo, environment } from '@/config/googlepay';

export function getGooglePayPaymentDataRequest(
  transactionInfo: google.payments.api.TransactionInfo,
): google.payments.api.PaymentDataRequest {
  const paymentDataRequest: google.payments.api.PaymentDataRequest = {
    ...baseRequestWithoutTransactionInfo,
    transactionInfo,
  };

  return paymentDataRequest;
}

export const getGoogleIsReadyToPayRequest = () => ({
  ...baseRequestWithoutTransactionInfo,
});

export const buildBrowserInfo = () => {
  const browserInfo = {
    userAgent: navigator.userAgent,
    language: navigator.language,
    colorDepth: window.screen.colorDepth,
    screenHeight: window.screen.height,
    screenWidth: window.screen.width,
    timeZoneOffset: new Date().getTimezoneOffset(),
    javaEnabled: navigator.javaEnabled(),
  };
  return browserInfo;
};

let client: google.payments.api.PaymentsClient;

const createClient = (): google.payments.api.PaymentsClient => {
  const client = new google.payments.api.PaymentsClient({
    merchantInfo: { merchantId: baseRequestWithoutTransactionInfo.merchantInfo.merchantId },
    environment,
  });

  return client;
};

export const clientInstance = (): google.payments.api.PaymentsClient => {
  return client ?? (client = createClient());
};
