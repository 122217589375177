import { SearchForm } from '@/components/modules/search';
import useMobileView from '@/hooks/useMobileView';

type SearchProps = {
  source?: string;
};

const Search = ({ source = 'outside' }: SearchProps) => {
  const { isMobileView } = useMobileView();
  return isMobileView ? (
    <SearchForm source={source} />
  ) : (
    <div className="search-container my-auto">
      <SearchForm source={source} />
    </div>
  );
};

export default Search;
