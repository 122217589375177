import BannerType1 from '@/components/elements/Banner/BannerType1/BannerType1';
import BannerType2 from '@/components/elements/Banner/BannerType2/BannerType2';
import BannerType3 from '@/components/elements/Banner/BannerType3/BannerType3';
import BannerType4 from '@/components/elements/Banner/BannerType4/BannerType4';
import BannerType5 from '@/components/elements/Banner/BannerType5/BannerType5';
import { BannerConfig, bannerConfigSchema } from '@/components/elements/Banner/types';
import { AmplitudeExperimentContext } from '@/hooks/useAmplitudeExperiment';
import useMobileView from '@/hooks/useMobileView';
import { captureMessage } from '@sentry/react';
import { useContext } from 'react';

const BannerSlot = ({
  config,
  locationId,
  inContainer,
  wrapperClass,
}: {
  config?: BannerConfig;
  locationId: string;
  inContainer?: boolean;
  wrapperClass?: string;
}) => {
  const { isMobileView } = useMobileView();
  const { getVariant } = useContext(AmplitudeExperimentContext);

  const { payload } = getVariant(locationId);
  if (!config && !payload) return null;
  const validBannerConfig = bannerConfigSchema.safeParse(config ?? payload);

  if (!validBannerConfig.success) {
    captureMessage(`Banner config is invalid for ${locationId}`);
    return null;
  }

  const position = locationId.split('_')?.[0];
  if (!validBannerConfig.data.active) return null;

  switch (validBannerConfig.data.type) {
    case 'type_1': {
      return (
        <article className={wrapperClass ?? ''}>
          <BannerType1
            inContainer={inContainer}
            position={position}
            size={isMobileView ? 'sm' : 'lg'}
            {...validBannerConfig.data}
          />
        </article>
      );
    }
    case 'type_2': {
      return (
        <article className={wrapperClass ?? ''}>
          <BannerType2 position={position} {...validBannerConfig.data} />
        </article>
      );
    }
    case 'type_3': {
      return (
        <article className={wrapperClass ?? ''}>
          <BannerType3 position={position} {...validBannerConfig.data} />
        </article>
      );
    }
    case 'type_4': {
      return (
        <article className={wrapperClass ?? ''}>
          <BannerType4 position={position} {...validBannerConfig.data} />
        </article>
      );
    }
    case 'type_5': {
      return (
        <article className={wrapperClass ?? ''}>
          <BannerType5 position={position} {...validBannerConfig.data} />
        </article>
      );
    }
    default: {
      return null;
    }
  }
};

export default BannerSlot;
