import { Card, CardHeader } from '@/components/elements/cards';
import RadioButton from '@/components/elements/forms/RadioButton';
import { PhoneIcon } from '@/components/icons';
import { __ } from '@/locale';

const SelectDigitalShippingMethodCard = ({ css, isWellness }) => (
  <Card>
    {!isWellness && <CardHeader>{__('buyGiftcard.selectShipping')}</CardHeader>}
    <div>
      <RadioButton variant="large" checked>
        <div className="flex items-center">
          <PhoneIcon className="mx-4 h-8 w-8" />
          <div className="pr-[60px] text-left ">
            <h3 className="mg-0 text-md font-normal">{__('buyGiftcard.shippingDigital' + (isWellness ? 'Fr' : ''))}</h3>
            <div className="text-sm">{__('buyGiftcard.shippingDigital.desc')}</div>
          </div>
        </div>
      </RadioButton>
    </div>
  </Card>
);

export default SelectDigitalShippingMethodCard;
