import { modalActions } from '@/actions';
import Header from '@/components/elements/Header/Header';
import Select from '@/components/elements/forms/Select/Select';
import ListInput from '@/components/elements/forms/listInput/ListInput/ListInput';
import ListInputSelect from '@/components/elements/forms/listInput/ListInputSelect/ListInputSelect';
import ListInputTextArea from '@/components/elements/forms/listInput/ListInputTextArea/ListInputTextArea';
import { ListItem, ListItemContent } from '@/components/elements/lists';
import Alert from '@/components/elements/notifications/Alert/Alert';
import Icon from '@/components/icons/Icon';
import { capitalizeFirstLetter, isZipcode } from '@/helpers';
import { guestCheckoutManager, isStandardFormField } from '@/helpers/checkout';
import { isNationalId } from '@/helpers/utilsTS';
import { useAppDispatch } from '@/hooks';
import { _s } from '@/locale';
import { FormField } from '@/types/state/shared';
import { Fragment, useEffect, useState } from 'react';
import { UseFormRegister } from 'react-hook-form';
import isEmail from 'validator/lib/isEmail';
import isLength from 'validator/lib/isLength';
import isMobilePhone from 'validator/lib/isMobilePhone';
import { UseCheckoutFormContext } from './CheckoutFormFields.hooks';

const baseTranslationKey = 'components.modules.checkout.CheckoutFormFields';

export const PROFILE_FORM_FIELD_ID = {
  STREET_ADDRESS: 'streetAddress',
  POSTAL_CODE: 'postalCode',
  LOCALITY: 'locality',
  FAMILY_NAME: 'familyName',
  GIVEN_NAME: 'givenName',
  EMAIL: 'email',
  MOBILE: 'mobile',
  PHONE: 'phone',
} as const;

export const DEFAULT_FORM_FIELD_ID = {
  BOOKING_NOTES: 'bookingNotes',
  CAPACITY: 'capacity',
  GIFTCARD: 'giftCard',
  NATIONAL_ID: 'nationalId',
  DISCOUNT_CODE: 'discountCode',
  ...PROFILE_FORM_FIELD_ID,
} as const;

export const GUEST_FORM_FIELD_ID = 'guestName';

type CheckoutFormProps = {
  formId?: string;
  formFields: FormField[];
  context: UseCheckoutFormContext;
  initialFormValues?: Record<string, string>;
  isGuestCheckout?: boolean;
  onUpdateFormField: (formId: string, shouldStoreInSession: boolean, value: Record<string, string>) => void;
};

export type ReactHookFormRegister<T> = {
  register?: UseFormRegister<T>;
};

const CheckoutFormFields = ({
  context,
  formFields,
  initialFormValues,
  isGuestCheckout = false,
  onUpdateFormField,
}: CheckoutFormProps) => {
  const { watch, setValue } = context;
  const dispatch = useAppDispatch();
  const [contactFields, extraFields] = formFields.reduce(
    (acc, field) => {
      if (isStandardFormField(field.id) && field.id !== 'bookingNotes' && field.id !== 'discountCode') {
        acc[0].push(field);
      } else {
        acc[1].push(field);
      }
      return acc;
    },
    [[], [], []],
  );
  const [isMediator, setIsMediator] = useState(guestCheckoutManager().getOptions()?.isMediator ?? false);

  const handleChangeIsMediator = (isMediator: boolean) => {
    if (isMediator) {
      setValue(GUEST_FORM_FIELD_ID, undefined);
      onUpdateFormField('1', false, { guestName: undefined });
    }
    const manager = guestCheckoutManager();
    const options = manager.getOptions();
    manager.setOptions({ ...options, isMediator });
    setIsMediator(isMediator);
  };

  useEffect(() => {
    const subscription = watch((value, e) => {
      onUpdateFormField('1', false, { [e.name]: value[e.name] });
    });
    return () => subscription.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch, onUpdateFormField]);

  useEffect(() => {
    /**
     * if we have formValues already on mount it means we have a saved checkout state
     * and we need to set the values to the form
     */
    Object.entries(initialFormValues ?? []).forEach(([key, value]) => {
      setValue(key, value);
      onUpdateFormField('1', false, { [key]: value });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isGuestCheckout) {
    return (
      <div className="pb-lg">
        <div className="pb-lg">
          <div className="px-lg">
            <div className="py-md space-y-xs flex flex-col">
              <Header label={_s(`${baseTranslationKey}.guest.title`)} size="md" />
              <p className="text-black-600 text-sm">{_s(`${baseTranslationKey}.guest.description`)}</p>
            </div>
            <Alert
              variant="information"
              body={_s(`${baseTranslationKey}.guest.alert.body`)}
              action={{
                children: _s(`${baseTranslationKey}.guest.alert.action`),
                onClick: () => dispatch(modalActions.login({ show: true, triggerSource: 'checkout_booking_summary' })),
              }}
            />
          </div>
          <ListItem
            onClick={() => handleChangeIsMediator(false)}
            leftSlot={<Icon variant={isMediator ? 'radio-unchecked' : 'radio-checked'} />}>
            <ListItemContent title={_s(`${baseTranslationKey}.guest.radio.guest`)} />
          </ListItem>
          <ListItem
            onClick={() => handleChangeIsMediator(true)}
            leftSlot={<Icon variant={isMediator ? 'radio-checked' : 'radio-unchecked'} />}>
            <ListItemContent title={_s(`${baseTranslationKey}.guest.radio.client`)} />
          </ListItem>
          {isMediator && (
            <Fragment>
              <div className="pl-lg py-md">
                <Header label={_s(`${baseTranslationKey}.guest.header.guest`)} size="md" />
              </div>
              <CheckoutFormField label="Namn" type="text" values={[]} id={GUEST_FORM_FIELD_ID} context={context} />
            </Fragment>
          )}
          {contactFields.length > 0 && (
            <Fragment>
              <div className="pl-lg py-md">
                <Header
                  label={
                    isMediator
                      ? _s(`${baseTranslationKey}.guest.header.client`)
                      : _s(`${baseTranslationKey}.header.contact-fields.guest`)
                  }
                  size="md"
                />
              </div>
              <div className="flex flex-col md:grid md:grid-cols-2">
                {contactFields.map((field) => {
                  return (
                    <Fragment key={field.id}>
                      <CheckoutFormField {...field} context={context} />
                    </Fragment>
                  );
                })}
              </div>
            </Fragment>
          )}
        </div>

        {extraFields.length > 0 && (
          <>
            <div className="pl-lg py-md">
              <Header label={_s(`${baseTranslationKey}.header.extra-fields`)} size="md" />
            </div>
            <div className="flex flex-col md:grid md:grid-cols-2">
              {extraFields.map((field) => {
                return (
                  <Fragment key={field.id}>
                    <CheckoutFormField {...field} context={context} />
                  </Fragment>
                );
              })}
            </div>
          </>
        )}
      </div>
    );
  }

  return (
    <div className="pb-lg">
      {contactFields.length > 0 && (
        <div className="pb-lg">
          <div className="pl-lg py-md">
            <Header label={_s(`${baseTranslationKey}.header.contact-fields.client`)} size="md" />
          </div>
          <div className="flex flex-col md:grid md:grid-cols-2">
            {contactFields.map((field) => {
              return (
                <Fragment key={field.id}>
                  <CheckoutFormField {...field} context={context} />
                </Fragment>
              );
            })}
          </div>
        </div>
      )}
      {extraFields.length > 0 && (
        <>
          <div className="pl-lg py-md">
            <Header label={_s(`${baseTranslationKey}.header.extra-fields`)} size="md" />
          </div>
          <div className="flex flex-col md:grid md:grid-cols-2">
            {extraFields.map((field) => {
              return (
                <Fragment key={field.id}>
                  <CheckoutFormField {...field} context={context} />
                </Fragment>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};

const CheckoutFormField = ({
  id,
  type,
  values,
  label: _label,
  required,
  context,
}: FormField & { context: UseCheckoutFormContext }) => {
  const {
    register,
    watch,
    formState: { errors },
  } = context;
  const value = watch(id, false);

  const errorMessageToString = typeof errors[id]?.message === 'string' ? (errors[id]?.message as string) : '';

  const label = _label ?? _s(capitalizeFirstLetter(id));
  const textFieldPlaceholder = id === 'bookingNotes' ? _s('BookingNotesPlaceholder') : `${_s('Enter')} ${label}`;

  switch (type) {
    case 'dropdown': {
      return (
        <ListInputSelect
          id={id}
          label={`${label}${required ? '*' : ''}`}
          error={errorMessageToString}
          value={value}
          {...register(id, {
            ...(required ? { required: _s('requiredFieldMissing', { label }) } : {}),
            validate: (value) => validateFormFields(id, value),
          })}>
          <Select.Option value="">{_s('Choose')}...</Select.Option>
          {values.map((value, key) => (
            <Select.Option key={key} value={value}>
              {value}
            </Select.Option>
          ))}
        </ListInputSelect>
      );
    }
    case 'text': {
      switch (id) {
        case DEFAULT_FORM_FIELD_ID.BOOKING_NOTES: {
          return (
            <div className="col-span-2">
              <ListInputTextArea
                error={errorMessageToString}
                id={id}
                label={`${label}${required ? '*' : ''}`}
                labelClassName="text-m"
                placeholder={textFieldPlaceholder}
                {...register(id, {
                  ...(required ? { required: _s('requiredFieldMissing', { label }) } : {}),
                  validate: (value) => validateFormFields(id, value),
                })}
              />
            </div>
          );
        }
        default: {
          return (
            <ListInput
              label={`${label}${required ? '*' : ''}`}
              placeholder={textFieldPlaceholder}
              error={errorMessageToString}
              id={id}
              {...register(id, {
                ...(required ? { required: _s('requiredFieldMissing', { label }) } : {}),
                validate: (value) => validateFormFields(id, value),
              })}
            />
          );
        }
      }
    }
    default:
      return null;
  }
};

type IsValid = true;
type IsError = string;

const validateFormFields = (id: FormField['id'], value: string): IsValid | IsError => {
  if (value) {
    switch (id) {
      case 'email':
        return !isEmail(value.trim(), { allow_utf8_local_part: false }) ? _s('invalidEmail') : true;
      case 'mobile':
      case 'phone':
        return !isMobilePhone(value.trim()) || !isLength(value.trim(), { min: 10, max: 14 })
          ? _s('invalidPhone')
          : true;
      case 'nationalId':
        return !isNationalId(value.trim()) ? _s('invalidNationalId') : true;
      case 'postalCode':
        return !isZipcode(value.trim()) ? _s('invalidZipcode') : true;
      default:
        break;
    }
  }
  return true;
};

export default CheckoutFormFields;
