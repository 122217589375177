import { AvatarSize } from '@/components/elements/redesign/Avatar/types';
import { IconSize } from '@/components/icons/types';

export const AVATAR_ICON_SIZE: Record<AvatarSize, IconSize> = {
  sm: 'xs',
  md: 'sm',
  lg: 'md',
  xl: 'lg',
};

export const AVATAR_SIZE = {
  sm: 'w-[32px] h-[32px]',
  md: 'w-5xl h-5xl',
  lg: 'w-7xl h-7xl',
  xl: 'w-huge h-huge',
};
