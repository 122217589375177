import { ListItem, ListItemContent } from '@/components/elements/lists';
import Icon from '@/components/icons/Icon';
import { LPListField } from '@/types/landingPages';

const List = ({ items }: LPListField) => (
  <>
    {items.map(({ title, content, url }, i) => (
      <ListItem
        underline
        key={i}
        rightSlot={<Icon variant="chevron-right" />}
        leftSlot={<Icon variant={'search-check'} />}
        to={url}>
        <ListItemContent title={title} subTitle={content} />
      </ListItem>
    ))}
  </>
);

export default List;
