import TopTabBarItem from '@/components/elements/tabs/TopTabBarItem/TopTabBarItem';
import type { TopTabBarItem as TopTabBarItemType } from '@/components/elements/tabs/types';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

 const getActiveHash = (items, pathname, hash) => {
  return items.findIndex((item) => {
    if (item?.submenu && getActiveHash(item.submenu, pathname, hash) !== -1) {
      return true;
    } else {
      return item.to && (pathname === item.to || (item.to.startsWith('#') && item.to === hash));
    }
  });
}

const scrollToElement = (hash, offset = 120) => {
  if (hash) {
    const targetElementId = hash.substring(1);
    const element = document.getElementById(targetElementId);

    if (element) {
      // Delay scrolling slightly to allow any layout shifts to settle
      setTimeout(() => {
        const targetPosition = element.getBoundingClientRect().top + window.scrollY - offset;

        // Perform the scroll
        window.scrollTo({
          top: targetPosition,
          behavior: 'smooth',
        });

        // Validate final position
        setTimeout(() => {
          const finalPosition = element.getBoundingClientRect().top + window.scrollY - offset;

          if (Math.abs(targetPosition - finalPosition) > 5) { // Allow small tolerance
            window.scrollTo({
              top: finalPosition,
              behavior: 'auto',
            });
          }
        }, 300); // Delay to allow smooth scroll to complete

      }, 100); // Initial delay before scrolling to allow for any layout shifts
    } else {
      console.error('Element not found:', targetElementId);
    }
  }
};

const TopTabBar = ({
  items,
  defaultSelected,
  onTabClick,
  isSubMenu,
  count,
}: {
  items: TopTabBarItemType[];
  defaultSelected: number;
  onTabClick?: (tabindex: number) => void;
  isSubMenu?: boolean;
  count?: number;
}) => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(defaultSelected);
  const { pathname, hash } = useLocation();

  useEffect(() => {
    const activeHash = getActiveHash(items, pathname, hash);
    setSelectedTabIndex(activeHash ?? defaultSelected);

    // Scroll on hash change or initial load
    if (hash) {
      scrollToElement(hash);
    }

    // Handle scroll on window load
    const handleLoad = () => scrollToElement(hash);
    window.addEventListener('load', handleLoad);

    return () => {
      window.removeEventListener('load', handleLoad);
    };

  }, [defaultSelected, hash]);

  useEffect(() => {
    onTabClick?.(selectedTabIndex);
  }, [selectedTabIndex]);

  const handleOnClick = (index: number) => {
    // @TODO: need some identifer here, lets wait until implement in header
    setSelectedTabIndex(index);
  };

  return (
    <div className={`flex gap-3 ${isSubMenu ? 'h-[36px] mb-lg justify-start items-center' : 'h-[40px] px-lg items-end'}`}>
      {items.map((item: TopTabBarItemType, index: number) => (
        <div key={index} className={`flex ${isSubMenu ? '' : 'flex-1 justify-center'}`}>
          <TopTabBarItem isSubMenu={isSubMenu} count={count} {...item} selected={index === selectedTabIndex} onClick={() => handleOnClick(index)} />
        </div>
      ))}
    </div>
  );
};

export default TopTabBar;
