import { ReactNode } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { TabBase } from './types';

export const TabAction = (
  props: Pick<TabBase, 'id' | 'onClick' | 'to'> & { children: ReactNode; className: string },
) => {
  const location = useLocation();

  if (!props.to) {
    return (
      <button id={props.id} onClick={props.onClick} className={props.className}>
        {props.children}
      </button>
    );
  } else {
    if (props.to && props.to.startsWith('/')) {
      return (
        <Link
          id={props.id}
          to={{ pathname: props.to, state: { from: location.pathname } }}
          onClick={props.onClick}
          className={props.className}>
          {props.children}
        </Link>
      );
    } else {
      return (
        <a id={props.id} href={props.to} onClick={props.onClick} className={props.className}>
          {props.children}
        </a>
      );
    }
  }
};
