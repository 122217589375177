import { listConstants } from '../constants';

export const listActions = {
  mobileList,
  mobileMap,
};

function mobileList(data) {
  return { type: listConstants.mobileList, data };
}

function mobileMap(data) {
  return { type: listConstants.mobileMap, data };
}
